import React, { useState, useEffect } from "react";
import {
  Div,
  Card,
  Avatar,
  Text,
  H6,
  Image,
  Divider,
} from "qdm-component-library";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EditIcon from "@mui/icons-material/Edit";
import { Grid } from "@material-ui/core";
import moment from "moment";
import styles from "../patientDashboard/components/styles";
// import IdentificationDocuments from "../../../assets/img/svg/icons8-identification-documents.svg";
import IdentificationDocuments from "../../assets/img/svg/icons8-identification-documents.svg";
import groupIcon from "../../assets/img/svg/Group 90503.svg";
import phoneIcon from "../../assets/img/svg/icons8-phone.svg";

const StaticPatientCard = (props) => {
  const {
    rowFlex,
    columnFlex,
    h6,
    span,
    cardStyle,
    avatar,
    img,
    status,
    status1,
    dot,
    editIcon,
    inactiveDot,
    inHoldDot,
    status2,
    cardStyle1,
  } = styles;
  const cardClick = () => {
    props?.setopenSearchModal(true);
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <Card
        style={{
          padding: "10px",
          width: "100%",
          boxShadow: "0px 10px 25px #0000000A",
        }}
        onClick={cardClick}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              src="https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
              variant="rounded"
              inLineStyles={avatar}
            />
            <Div style={columnFlex}>
              <Text style={rowFlex}>
                <H6 className="pc_semibold" style={h6}>
                  Search Patient
                </H6>
              </Text>
              <Div style={{ display: "flex", marginTop: "8px" }}>
                <Image style={img} src={IdentificationDocuments} alt="id" />- -
                <Image
                  style={{
                    width: 16,
                    height: 16,
                    marginRight: 10,
                    marginLeft: "7px",
                  }}
                  src={groupIcon}
                  alt="id"
                />
                - -
                <Image
                  style={{
                    width: 16,
                    height: 16,
                    marginRight: 10,
                    marginLeft: "7px",
                  }}
                  src={phoneIcon}
                  alt="id"
                />
                - -
              </Div>
            </Div>
          </Div>
        </div>
      </Card>
    </div>
  );
};

export default StaticPatientCard;
