import React from 'react';
import { RadioGroup, FormControlLabel, FormControl, FormLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';

// const SmallRadio = withStyles({
//   root: {
//     padding: 5, // Reduce padding to make it appear smaller
//     '& .MuiSvgIcon-root': {
//       fontSize: '1rem', // Set font size of the icon
//     },
//   },
// })(Radio);

function RadioGrp({ types = [], name = "", value = {}, valuefn = () => null,disabled=false }) {
  const [RadioValue, setRadioValue] = React.useState(value);

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    const selectedObj = types.find((item) => item.value === selectedValue);
    setRadioValue(selectedValue);
    valuefn(selectedObj); 
  };

  React.useEffect(()=>{        
    setRadioValue(value?.value)
  },[value])

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{name}</FormLabel>
      <RadioGroup  aria-label="option" name={name} value={RadioValue}  onChange={handleChange}>
        {types.map((v) => (
          <FormControlLabel 
            disabled={disabled}
            key={v.value} 
            value={v.value} 
            control={<Radio color='primary'/>}  // Use SmallRadio here
            label={v.label} 
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default RadioGrp;
