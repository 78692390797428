import { Chip, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import DotIcon from "../../../assets/svgComponents/dotIcon";

const InfoSingleDiagnosis = ({data}) => {
  const theme = useTheme();
  return (
    <Grid
    container
    justifyContent={"space-between"}
    alignItems={"center"}
    gap={1}
    py={0.5}
    px={2}
    wrap="nowrap"
    xs={12}
  >
    <Grid item xs={10}>
      <Grid container direction={"column"} gap={1} >
        <Grid item container gap={1} alignItems={"center"} >
          <DotIcon />
          <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "80%" }}>
            <Typography variant="content1" color={theme?.palette?.primaryText} marginLeft={1} >
              {data?.diagnosisdescription}          
            </Typography>
          </div>
        </Grid>
        <Grid item >
        <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis",maxWidth: "80%" }}>
          <Typography variant="content2" color={theme?.palette?.tertieryText}>
              {data?.use} - {data?.onsetdate} - {data?.verificationstatus} 
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={2}>
      <Chip
        label={"new"}
        sx={{
          borderRadius: 1,
          backgroundColor: theme?.palette?.tertiaryText,
          color: theme?.palette?.primaryText,
        }}
        size="small"
      />
    </Grid>
  </Grid>
  );
};

export default InfoSingleDiagnosis;
