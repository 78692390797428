import * as React from "react"

function AddIcon({height,width,color,...props}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={height ? height : 24}
      height={width ? width : 24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M12 2.25c-5.376 0-9.75 4.374-9.75 9.75s4.374 9.75 9.75 9.75 9.75-4.374 9.75-9.75S17.376 2.25 12 2.25zm0 1.5A8.239 8.239 0 0120.25 12 8.239 8.239 0 0112 20.25 8.239 8.239 0 013.75 12 8.239 8.239 0 0112 3.75zm-.75 3.75v3.75H7.5v1.5h3.75v3.75h1.5v-3.75h3.75v-1.5h-3.75V7.5h-1.5z"
        fill={color?color: "#EC6A49"}
      />
    </svg>
  )
}

export default AddIcon
