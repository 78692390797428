import React from "react";
import { Avatar, colors, Divider, IconButton, makeStyles, Typography } from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles(() => ({
  root: {
    borderLeft: (props) => `3px solid ${colors?.[props?.cardData?.color]?.[800]}`,
    paddingLeft: "10px",
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "3px",
    justifyContent: "flex-start",
  },
  avatar: {
    border: "3px solid #DEE5EC",
    borderRadius: 8,
    height: "30px",
    width: "30px",
    marginRight: "5px",
  },
  avatarPlaceholder: {
    backgroundColor: "#DEE5EC",
    height: "30px",
    width: "30px",
  },
  eventTitle: {
    marginLeft:"3px",
    fontSize: "14px",
    color: '#333',
    lineHeight: "20px",
    margin: "0px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontWeight: "bold",
    textTransform: "capitalize",
    fontFamily: "'Roboto', sans-serif",
  },
  detailsContainer: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
  cardText: {
    fontSize: "10px",
    color: "grey",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontWeight: "bold",
    marginLeft: "5px",
  },
  icon: {
    color: (props) => colors?.[props?.cardData?.color]?.[900],
    borderRadius: "100%",
  },
  divider: {
    height: "14px",
    width: "2px",
    backgroundColor: "grey",
    margin: "0 5px",
    display: "inline-block",
    verticalAlign: "middle",
  },
  minWidthContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "5px",
  }
}));

const truncate = (value, length = 10) => {
  if (typeof value !== 'string') return value;
  return value.length > length ? `${value.substring(0, length)}...` : value;
};

const SelectCard = ({ eventInfo, handleClick = () => null, calendarApi = {}, transformToResources = [] }) => {
  const [width, setWidth] = React.useState(0);
  const divRef = React.useRef(null);
  const [view, setView] = React.useState("day");

  const classes = useStyles({ eventInfo }); // Pass eventInfo as props to useStyles

  React.useEffect(() => {
    const handleResize = () => {
      const divElement = document?.getElementById('slotcardid'); // Replace 'divId' with your element's actual ID
      if (divElement) {
        const rect = divElement?.getBoundingClientRect();
        setWidth(rect.width.toFixed(0));
      }
    };

    let whView = calendarApi?.view?.type.replace(/.*Grid/i, "").toLowerCase();
    setView(whView);

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [transformToResources]);
  const cardData = eventInfo?.event?.extendedProps
  console.log("width", width * 0.28, width);

  return (
    <div  className={classes.root} id={"slotcardid"} backgroundColor={cardData?.backgroundColor}>
      {/* {view === "day" ? cardData?.photo ? (
        <div id={`_avatar_div`}>
          <Avatar id={`_avatar_image`} src={cardData?.photo} alt={eventInfo?.event?.title ?? "profilePic"} className={classes.avatar} />
        </div>
      ) : (
        <div id={`_avatar_div`}>
          <Avatar id={`_patientinfocard_avatar`} variant="rounded" className={classes.avatarPlaceholder}>
            <Typography variant="h6" color="White">
              {truncate(eventInfo?.event?.title?.[0])}
            </Typography>
          </Avatar>
        </div>
      ) : null} */}

      <p className={classes.eventTitle} style={{  width: "100%" }}>
        {eventInfo?.event?.title}
      </p>

      {/* {(view === "day" && (width > 460)) ? (
        <span className={classes.detailsContainer} style={{ minWidth: "300px", width: "100%", maxWidth: width > 1000 ? 0.28 * width : "unset" }}>
          <div className={classes.minWidthContainer}>
            {truncate(cardData?.age) && (
              <Typography className={classes.cardText} style={{ maxWidth: "42px", width: "100%",minWidth: "42px" }}>
                {truncate(cardData?.age)}
              </Typography>
            )}
          </div>
          {(cardData?.gender || cardData?.mrnid || cardData?.state?.states) && (
            <Divider className={classes.divider} orientation="vertical" flexItem />
          )}
          <div className={classes.minWidthContainer}>
            {cardData?.gender && cardData.gender !== "" && (
              <Typography className={classes.cardText} style={{ maxWidth: "42px", width: "100%",minWidth: "42px" }}>
                {truncate(cardData?.gender)}
              </Typography>
            )}
            {(cardData?.mrnid || cardData?.state?.states) && (
              <Divider className={classes.divider} orientation="vertical" flexItem />
            )}
          </div>
          <div className={classes.minWidthContainer}>
            {cardData?.mrnid && cardData.mrnid !== "" && (
              <Typography className={classes.cardText} style={{ maxWidth: "75px", width: "100%",minWidth: "62px" }}>
                {truncate(cardData?.mrnid)}
              </Typography>
            )}
            {cardData?.state?.states && cardData.state.states !== "" && (
              <Divider className={classes.divider} orientation="vertical" flexItem />
            )}
          </div>
          <div className={classes.minWidthContainer}>
            {cardData?.state?.states && cardData.state.states !== "" && (
              <Typography className={classes.cardText} style={{ color: cardData?.state?.textcolor, maxWidth: "135px", width: "100%",minWidth: "130px" }}>
                {truncate(cardData?.state?.states)}
              </Typography>
            )}
          </div>
          </span>
        ) : null}    
        {cardData?.state?.states && cardData.state.states !== "" && (width < 460 && width >300) && (
              <Typography className={classes.cardText} style={{ color: cardData?.state?.textcolor, maxWidth: "135px", width: "100%",minWidth: "130px" }}>
                {truncate(cardData?.state?.states)}
              </Typography>
            )} */}
      {/* <IconButton size="small" onClick={(e) => { e.stopPropagation(); handleClick(e, eventInfo); }}>
        <VisibilityIcon className={classes.icon} fontSize={ width < 300 ?"small":"medium"}/>
      </IconButton> */}
    </div>
  );
};

export default SelectCard;
