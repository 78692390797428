import * as React from "react";

export const NurseStation = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="34"
			height="34"
			viewBox="0 0 32 32"
		>
			<g
				id="Nurse_Station"
				data-name="Nurse Station"
				transform="translate(-172 -119)"
			>
				<g id="Group_92522" data-name="Group 92522" transform="translate(0 -8)">
					<g
						id="Group_92520"
						data-name="Group 92520"
						transform="translate(1 1)"
					>
						<g
							id="Ellipse_129342"
							data-name="Ellipse 129342"
							transform="translate(171 126)"
							stroke={
								!props?.active && !props?.vist
									? "#B6B6B6"
									: props?.active
									? "#4bc0c0"
									: "#4bc0c0"
							}
							fill={
								!props?.active && !props?.vist
									? "#fff"
									: props?.active
									? "#4bc0c0"
									: "#fff"
							}
							stroke-width="1"
						>
							<circle cx="16" cy="16" r="16" stroke="none" />
							<circle cx="16" cy="16" r="15.5" fill="none" />
						</g>
						<path
							id="icons8-nurse"
							d="M9,2A2,2,0,0,0,7,4V6.036c-2.551.342-4,3.136-4,7.783v1.818a2,2,0,0,0,2,2H8.636a.538.538,0,0,0,.126-.016,5.345,5.345,0,0,0,1.874.38,4.817,4.817,0,0,0,1.787-.364h3.849a2,2,0,0,0,2-2V13.818c0-2.986-.427-6.969-4-7.734V4a2,2,0,0,0-2-2ZM9,3.091h3.273A.91.91,0,0,1,13.182,4V6.545c0,.351-.783,1.273-2.545,1.273-2.024,0-2.473-1.112-2.545-1.344V4A.91.91,0,0,1,9,3.091Zm1.636.727a.546.546,0,0,0-.545.545v.545H9.364A.545.545,0,0,0,9.364,6h.727v.545a.545.545,0,0,0,1.091,0V6h.727a.545.545,0,1,0,0-1.091h-.727V4.364A.546.546,0,0,0,10.636,3.818Zm2.142,5.133a2.3,2.3,0,0,1,1.866,2.228l0,1.914a3.942,3.942,0,0,1-2.55,3.486.5.5,0,0,0-.069.03,3.749,3.749,0,0,1-1.387.3c-1.737,0-4.364-1.524-4.364-3.821V10.909A.91.91,0,0,1,7.182,10h.337C7.882,10.035,11,10.287,12.778,8.951Zm-4.324,2.14a.727.727,0,1,0,.727.727A.727.727,0,0,0,8.455,11.091Zm4,0a.727.727,0,1,0,.727.727A.727.727,0,0,0,12.455,11.091Z"
							transform="translate(176 132)"
							fill={
								!props?.active && !props?.vist
									? "#B6B6B6"
									: props?.active
									? "#fff"
									: "#4bc0c0"
							}
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};
