import React from "react";
import theme from "../../theme";
export const VitalSigns = (props) => {
  let width = props.width ? props.width : "40.8";
  let height = props.height ? props.height : "40.8";
  let color = props.color ? props.color : theme.palette.secondary.main;
  return (
    <svg
      id="icons8-heart-with-pulse"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 13"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.3"
          y1="-0.061"
          x2="0.77"
          y2="0.808"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stop-color={props?.color ?? "#f44f5a"} />
          <stop offset="0.443" stop-color={props?.color ?? "#ee3d4a"} />
          <stop offset="1" stop-color={props?.color ?? "#e52030"} />
        </linearGradient>
      </defs>
      <g id="icons8-heart-with-pulse" transform="translate(-3 -7)">
        <path
          id="Path_92655"
          data-name="Path 92655"
          d="M11,8.95A6.28,6.28,0,0,1,15,7a3.967,3.967,0,0,1,4,3.9c0,3.667-5.892,7.853-7.563,8.967a.787.787,0,0,1-.874,0C8.892,18.754,3,14.568,3,10.9A3.967,3.967,0,0,1,7,7,6.28,6.28,0,0,1,11,8.95Z"
          fill="url(#linear-gradient)"
        />
        <path
          id="Path_92656"
          data-name="Path 92656"
          d="M13.586,19.368a.386.386,0,0,0,.316.241.433.433,0,0,0,.388-.14l.728-.88H16.71a.352.352,0,1,0,0-.7H14.815a.42.42,0,0,0-.324.145l-.379.459-.711-2.01a.394.394,0,0,0-.371-.245.4.4,0,0,0-.385.228l-1.273,3.017-.75-4.561a.38.38,0,0,0-.355-.3.4.4,0,0,0-.416.23L8.569,18.008,7.516,16.651a.419.419,0,0,0-.352-.153.407.407,0,0,0-.331.184L6.1,17.891H4.9c.146.234.308.466.485.7h.95A.409.409,0,0,0,6.69,18.4l.537-.883,1.124,1.449a.418.418,0,0,0,.381.15.391.391,0,0,0,.325-.229l1-2.471.744,4.527a.38.38,0,0,0,.353.3l.042,0a.4.4,0,0,0,.374-.228l1.411-3.344Z"
          transform="translate(-1.144 -4.583)"
          fill="#ffebee"
        />
        <path
          id="Path_92657"
          data-name="Path 92657"
          d="M33.8,23a.8.8,0,1,0,.8.8A.8.8,0,0,0,33.8,23Z"
          transform="translate(-18.628 -10.112)"
          fill="#ffebee"
        />
      </g>
    </svg>
  );
};
