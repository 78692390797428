/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date 2020-12-01
 * @modify date 2021-04-27
 * @desc Material UI MuIAlert and Snackbar is used to show the temporary message Component
 */
import React, { useState } from "react";
import { Row, Col, Text, Div, Image, Divider, Upload } from "qdm-component-library";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  TextField,
  Typography,
  Button,
  Tooltip,
  InputAdornment,
  Switch,
  makeStyles,
  Popover,
  Dialog, 
  DialogTitle, 
  DialogContent,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { CalendarIcon, InsightsIcon, Recurrent } from "../../../components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import  actions  from "../../../redux/actions"
import { AlertProps, calculateAgeInDays, getCurrentMinTime, getData, getFacilityId, getGlobalValueFrom_LclStrg_And_QryStrg, getTenantId, getUserInfo, getUtcTime, ToBase64 } from "../../../utils";
import { UIColor } from "../../../themes/theme";
import { DialogContext } from "../../../context";
import withAllContexts from "../../../hoc/withAllContexts";
import { RadiologyList } from "./radiologyList";
import Tick from "../../../assets/img/icons8-double-tick.svg";
import Addicon from "../../../assets/img/icons8-add.svg";
import Cancel from "../../../assets/img/icons8-multiply.svg";
import AlertsRestriction from "../alertsRestriction";
import CustomDatePicker from "../../../components/customDatePicker";
import {setHours, setMinutes} from "date-fns";
import { Drawer, Grid } from "@mui/material";
import SummerNote from "../../../components/richTextEditor/richtext";
import AddResult from "../labOrderFlow/addResult";
import CloseIcon from "@material-ui/icons/Close";
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import SaveIcon from "@mui/icons-material/Save";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import FileViewer from 'react-file-viewer';

const useStyles = makeStyles({
  // autocomplete: {
  //   width: 120,
  //   "& input": {
  //     fontFamily: "pc_regular",
  //     fontSize: 14,
  //     color: UIColor.secondaryText.color,
  //     "&::placeholder": {
  //       fontSize: 14,
  //       fontFamily: "pc_regular !important",
  //     },
  //   },
  // },

  autocomplete: {
    "& .MuiInputBase-input": {
      color: UIColor.secondaryText.color,
      fontSize: "12px !important",
      fontFamily: "pc_regular!important",
    },
    "& .MuiInputBase-root": {
      //height: 40,
      color: UIColor.secondaryText.color,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
        borderRadius: 8,
      },
      "&:hover fieldset": {
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 1,
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
      },
    },
    "& .MuiAutocomplete-popupIndicator": {
      transform: "none !important",
    },
  },
  input: {
    fontFamily: "pc_regular",
    color: UIColor.error.color,
    fontSize: 14,
    "&::placeholder": {
      fontSize: 14,
      fontFamily: "pc_regular !important",
    },
  },
  muiPopovers: {
    boxShadow: "0px 4px 24px #00000029",
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 8,
    backgroundColor: "#fff",
    minWidth: 240,
    overflow: "visible",
  },
  switch: {
    "& .Mui-checked": {
      color: "#fff",
    },
    "& .Mui-checked + .MuiSwitch-track": {
      backgroundColor: UIColor.primaryColor.color,
      opacity: 1,
    },
  },
});

const styles = {
  vitalsHead: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fff",
    borderRadius: 8,
    padding: "0px 0px 0px 10px",
  },
  vitalsHead1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fff",
    borderRadius: 8,
    padding: "10px 10px 12px 20px",
  },
  vitalsfieldHead: {
    padding: "6px 10px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    background: "#fff",
    borderRadius: 8,
  },
  vitailsCard: {
    //border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 10,
    width: "100%",
    // marginBottom: "24px !important",
    backgroundColor: "#fff",
  },
  vitailsCarderr: {
    fontSize: "12px",
    textAlign: "right",
    justifyContent: "right",
    float: "right",
    color: UIColor.error.color,
    marginBottom: "24px !important",
  },
  list: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  infoBell: {
    width: "50px",
    display: "flex",
    padding: "12px",
    borderLeft: `1px solid ${UIColor.lineBorderFill.color}`,
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 10,
  },
  icons: {
    cursor: "pointer",
  },
  flexFlow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  floatingLable: {
    backgroundImage: `linear-gradient(#fff, ${UIColor.greyBackground.color})`,
    position: "absolute",
    top: "-8px",
    fontSize: 10,
  },
};

const initialState = {
  orderCatalog: {},
  priority: {},
  nature: {},
  dateTime: "",
  headerShow: true,
  recurrent: false,
  recurrentData: {
    every: {
      value: "",
      periodicity: {},
    },
    startsOn: {
      dateTime: "",
    },
    endsAfter: {
      endsAfter: "",
      endsBy: "",
    },
  },
  orders: [],
  error: false,
};
let HeadData = [
  // {
  //   field: 'id',
  //   headerName: 'SNo',
  //   width: 50,
  //   align: "center",
  // },
  {
    field: 'Test',
    headerName: 'Test',
    width: 250,
    editable: false,
    align: "left",
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'Result',
    headerName: 'Result',
    width: 125,
    editable: true,
    // type: 'number',
    align: "center",
    headerAlign:"center",
    headerClassName: 'super-app-theme--header',
    renderCell: (params) => {
      const { row } = params;
      return row.resultreport === 'number' ? (
        <input
          type="number"
          value={params.value}
          style={{outline:"none",border:"none",width: 125,height:40,textAlign:"center"}}
          // onChange={(e) => handleCellChange(e.target.value, row.id, 'Result')}
        />
      ) : row.resultreport === 'combo' ? (
        // <select
        //   value={params.value}
        //   style={{
        //     outline: "none",
        //     border: "none",
        //     width: 125,
        //     height: 40,
        //     textAlign: "center",
        //     appearance: "none", // Removes the default browser styling
        //     background: "white",
        //     padding: "0 8px",
        //     cursor: "pointer",
        //   }}
        //   // onChange={(e) => props?.handleCellChange(e.target.value, row.id, 'Result')}
        // >
        //   <option value="">Select</option>
        //   <option value="Option 1">Option 1</option>
        //   <option value="Option 2">Option 2</option>
        //   <option value="Option 3">Option 3</option>
        // </select>
        <input
          type="text"
          value={params.value}
          style={{outline:"none",border:"none",width: 125,height:40,textAlign:"center"}}
          // onChange={(e) => handleCellChange(e.target.value, row.id, 'Result')}
        />
      ) : (
        <input
          type="text"
          value={params.value}
          style={{outline:"none",border:"none",width: 125,height:40,textAlign:"center"}}
          // onChange={(e) => handleCellChange(e.target.value, row.id, 'Result')}
        />
      );
    },
  },
  {
    field: 'Unit',
    headerName: 'Unit',
    width: 125,
    editable: false,
    align: "center",
    headerAlign:"center",
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'NormalRange',
    headerName: 'Normal Range',
    width: 200,
    editable: false,
    align: "center",
    headerAlign:"center",
    headerClassName: 'super-app-theme--header',
  },
  {
    field: 'Notes',
    headerName: 'Notes',
    width: 200,
    editable: true,
    align: "left",
    headerClassName: 'super-app-theme--header',
  },
  ]

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const Radiology = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const dialog = React.useContext(DialogContext);

  const { list, setRadiology } = props.radiology;
  // const [list, setList] = useState();
  const [resultOpen, setResultOpen] = useState(false);
  const [tableBody, settableBody] = React.useState([]);
  const [compData, setcompData] = React.useState([]);
  const[BodyData , setBodyData] = React.useState([]);
  const [buttonShow, setButtonShow] = useState(true);
  const [drawer, setDrawer] = useState(false);
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [viewFile, setViewFile] = useState([]);
  const [enableFields,setenableFields] = useState({});
  const [radLabForm,setRadLabForm] = useState();
  const [labResult, setlabResult] = React.useState([]);
  const [isEdit, setisEdit] = React.useState(false);
  const [editId, setEditId] = React.useState(false);
  const [richText, setRichText] = useState("")
  const [tableHeader, settableHeader] = React.useState([
    // {
    //   field: 'id',
    //   headerName: 'SNo',
    //   width: 50,
    //   align: "center",
      
    // },
    {
      field: 'Test',
      headerName: 'Test',
      width: 250,
      editable: false,
      align: "left",
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'Result',
      headerName: 'Result',
      width: 125,
      editable: true,
      type: 'number',
      align: "center",
      headerAlign:"center",
      headerClassName: 'super-app-theme--header',
  
    },
    {
      field: 'Unit',
      headerName: 'Unit',
      width: 125,
      editable: false,
      align: "center",
      headerAlign:"center",
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'NormalRange',
      headerName: 'Normal Range',
      width: 200,
      editable: false,
      align: "center",
      headerAlign:"center",
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'Notes',
      headerName: 'Notes',
      width: 200,
      editable: true,
      align: "left",
      headerClassName: 'super-app-theme--header',
    },
  ]);
  const info = getUserInfo();
  const [alertData, setAlertData] = useState({
    open: false,
    data: null,
  });
  const storeData = useSelector((state) => state?.RadiologySlice);
  const [treatmentProcedureEntry, setTreatmentProcedureEntry] = useState({
    ...initialState,
    // headerShow:
    //   list?.length > 0
    //     ? list?.filter((val) => val.isNew)?.length > 0
    //       ? false
    //       : true
    //     : true,
  });

  // const radRead = async () => {
  //   let datatradiology = await dispatch(
  //     actions.RAD_READ({
  //       encounter_id: encId || encounterData?.payload?.data?.[0]?._id,
  //       order_id: "RAD",
  //       patientid:
  //           encounterData?.payload?.data?.[0]?.patient_id ||
  //           encounterData?.payload?.data?.[0]?.Patient?.[0]?._id ||
  //           location?.state?.patientId,
  //       startdate:encStartDate ? encStartDate : getUtcTime(moment().startOf("day")),
  //     })
  //   );
  //   setList(datatradiology?.payload?.data)
  // }
  React.useEffect(() => {
    dispatch(actions.RAD_GETCATEGORY());
    dispatch(actions.RAD_PRIORITY());
    dispatch(actions.RAD_NATURE());
    dispatch(actions.RAD_ORDER_TYPE());
    dispatch(actions.RAD_ORDER_CATALOG());
  }, []);
  React.useEffect(() => {
    if(props?.patientgetData?.encounterId){
      getRadiologyRead();
    }
  }, [props?.patientgetData?.encounterId]);

  React.useEffect(() => {
    setTreatmentProcedureEntry({
      ...treatmentProcedureEntry,
      headerShow: list?.length > 0 ? false : true,
    });
  }, [storeData]);

  const handleInputChange = (e, name) => {
    if (name === "dateTime") {
      let Data = {
        every: {
          value: "",
          periodicity: {},
        },
        startsOn: {
          dateTime: e,
        },
        endsAfter: {
          endsAfter: "",
          endsBy: "",
        },
      };
      setTreatmentProcedureEntry({
        ...treatmentProcedureEntry,
        [name]: e,
        recurrentData: Data,
        error: false,
      });
      // setIndex(null);
      // handleClose();
    } else {
      setTreatmentProcedureEntry({
        ...treatmentProcedureEntry,
        [name]: e,
        error: false,
      });
      setIndex(null);
      handleClose();
    }
  };
  const closeAlertModal = (data) => {
    setAlertData({
      open: false,
      data: null,
    });

    if (data.type === 1) {
      setTreatmentProcedureEntry({
        ...treatmentProcedureEntry,
        orderCatalog: {},
        priority: {},
        nature: {},
        dateTime: "",
        recurrent: false,
        headerShow: !treatmentProcedureEntry.headerShow,
        recurrentData: {
          every: {
            value: "",
            periodicity: {},
          },
          startsOn: {
            dateTime: "",
          },
          endsAfter: {
            endsAfter: "",
            endsBy: "",
          },
        },
        orders: [],
      });
    } else if (data.type) {
      AddTreatmentProcedure(true, data.type);
    }
  };
  const AddTreatmentProcedure = async (ruleValidated) => {
    if (
      treatmentProcedureEntry.orderCatalog.label &&
      treatmentProcedureEntry.dateTime &&
      treatmentProcedureEntry.nature.label &&
      treatmentProcedureEntry.priority.label &&
      (!treatmentProcedureEntry.recurrent ||
        (treatmentProcedureEntry.recurrentData?.every?.value &&
          treatmentProcedureEntry.recurrentData?.every?.periodicity?.value &&
          (treatmentProcedureEntry.recurrentData?.endsAfter?.endsAfter ||
            treatmentProcedureEntry.recurrentData?.endsAfter?.endsBy) &&
          treatmentProcedureEntry.recurrentData?.startsOn.dateTime))
    ) {
      let datalist = {
        ...treatmentProcedureEntry,
        createdName: info.name,
        createdAt: getData(),
        currentStatus: "New",
      };
      let alertText = null;
      // if (!ruleValidated) {
      //   let alertData = await dispatch(
      //     actions.GETALERTWITHLABORDER({
      //       orgId: props.propsData.loggedUserInfo.data.org_id,
      //       rulePayload: { ...datalist, Oltype: "TREAT" },
      //       pracId: props.propsData.loggedUserInfo.data.practioner_id,
      //       patId: props.patientgetData.pID,
      //     })
      //   );
      //   let alertdetails = alertData.payload.data;
      //   if (
      //     alertdetails.type &&
      //     typeof alertdetails.type === "number" &&
      //     alertdetails.type !== 2
      //   ) {
      //     setAlertData({
      //       open: true,
      //       data: alertdetails,
      //     });
      //     return false;
      //   } else if (alertdetails.type === 2) {
      //     alertText = alertdetails.alertText;
      //   }
      // }
      datalist.infoAlertText = alertText;
      await props.saveRadiology([datalist], null);

      setTreatmentProcedureEntry({
        ...treatmentProcedureEntry,
        orderCatalog: {},
        priority: {},
        nature: {},
        dateTime: "",
        recurrent: false,
        headerShow: !treatmentProcedureEntry.headerShow,
        recurrentData: {
          every: {
            value: "",
            periodicity: {},
          },
          startsOn: {
            dateTime: "",
          },
          endsAfter: {
            endsAfter: "",
            endsBy: "",
          },
        },
        orders: [],
      });
      getRadiologyRead();
    } else {
      setTreatmentProcedureEntry({
        ...treatmentProcedureEntry,
        recurrent: false,
        headerShow: true,
        error: true,
      });
      handleClose();
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [index, setIndex] = useState(null);

  const handleClick = (index, event) => {
    setAnchorEl(event.currentTarget);
    setIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose1 = () => {
    setTreatmentProcedureEntry({
      ...treatmentProcedureEntry,
      recurrent: false,
    });
    setAnchorEl(null);
  };

  const saveRecurrentData = (data) => {
    setTreatmentProcedureEntry({
      ...treatmentProcedureEntry,
      recurrentData: data,
      dateTime: data.startsOn.dateTime,
    });
    handleClose();
  };

  const deleteList = async (i, data) => {
    if (list?.length > 0) {
      setTreatmentProcedureEntry({
        ...treatmentProcedureEntry,
        headerShow: false,
      });
    }
    dialog.setDialog({
      ...dialog,
      open: false,
    });
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "Deleting Radiology",
    });
    await dispatch(actions.RAD_DELETE({ id: data.editData }));
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: false,
      message: "",
    });
    getRadiologyRead();
    // list.splice(i, 1);
  };

  const editFun = (i, childIndex, parentOrderId, data, dates) => {
    // setRadiology({
    //   ...props.radiology,
    //   editIndex: i,
    //   // editData: data.editData,
    //   child: {
    //     index: childIndex,
    //     parentOrderId: parentOrderId,
    //     IsChild: !parentOrderId ? true : false,
    //     dates: dates,
    //     editData: data?.editData,
    //   },
    // });
  };

  const updatefuncy = (val) => {
    // setRadiology({
    //   ...props.radiology,
    //   ...val,
    // });
  };

  const handleCloseForm = () => {
    if (list.length > 0) {
      setTreatmentProcedureEntry({
        ...treatmentProcedureEntry,
        orderCatalog: {},
        priority: {},
        nature: {},
        dateTime: "",
        recurrent: false,
        headerShow: !treatmentProcedureEntry.headerShow,
        recurrentData: {
          every: {
            value: "",
            periodicity: {},
          },
          startsOn: {
            dateTime: "",
          },
          endsAfter: {
            endsAfter: "",
            endsBy: "",
          },
        },
        orders: [],
      });
    } else {
      props.handleClose("radiology");
    }
  };

  const getSingleRadiologyForm = async (isLoad,index,radData) => {
    let { patientgetData } = props
    let HeaderData = []
    let componentdata = []
    let isImage = false;
    let isRichText = false;
    const data = await dispatch(actions.GET_RESULT_DATA({
      patientid: patientgetData?.patientDetails?.patientId,
      encounterid: patientgetData?.encounterId,
      gender: patientgetData?.patientDetails?.gender,
      Oltype: "RAD",
      orderlineid: radData?.editData,
      ageindays: calculateAgeInDays(moment(patientgetData?.patientDetails?.birthdate, "DD/MM/YYYY").format("MM/DD/YYYY"))
    }))
    const tenantId = await getTenantId();
    const facilityId = await getFacilityId();
    const Resultdata = await dispatch(actions.GET_ALL_LAB_RESULT({
      patientid:patientgetData?.patientDetails?.patientId,
      encounterid: patientgetData?.encounterId,// ?? location?.state?.encounterId ?? location?.state?.encounter_id
      orderlineid:radData?.editData,
      oltype:"RAD",
      tenantId,
      facilityId,
    }))
    if(Resultdata?.payload?.data?.length > 0){
      Resultdata?.payload?.data?.map(val => {
          setRadLabForm(val?.components)
          val?.components.map(li => {
            if(li?.ordercatlogid?.vitalscode?.toLowerCase()?.trim() !== "richtext" && li?.ordercatlogid?.vitalscode?.toLowerCase().trim() !== "image"){
              let obj = {
                id: HeaderData?.length + 1,
                Test: li?.ordercatlogid?.vitalscode ? li?.ordercatlogid?.vitalscode : li?.ordercatlogid?.longdesc, 
                Result: li?.ordervalue,
                Unit: li?.orderuom?.UOM,
                NormalRange: `${li?.ordermin}-${li?.ordermax}`,
                Notes: li?.remarks,
                ordercatlogid: li?.ordercatlogid?._id,
                parentordercatid: li?.parentordercatid?._id,
                parentordercatname: li?.parentordercatid?.longdesc,
                resultappl:li?.resultappl ?? true,
                resultreport:li?.resultreport ?? "string",
                resultcodeset:li?.resultcodeset,
              }
              HeaderData.push(obj)
              let Obj1 = {
                orderlineid: val?.orderlineid	 ?? "",//li?.orderlineid ? li?.orderlineid : "",
                ordervalue: li?.ordervalue ? li?.ordervalue : "",
                flag: li?.flag ? li?.flag : "",
                ordermax: li?.ordermax ? li?.ordermax : 0,
                method: li?.method ? li?.method : "",
                ordermin: li?.ordermin ? li?.ordermin : 0,
                orderuom: li?.orderuom?._id ? li?.orderuom?._id : "",
                parentordercatid: li?.parentordercatid?._id ? li?.parentordercatid?._id : "",
                ordercatlogid: li?.ordercatlogid?._id ? li?.ordercatlogid?._id : "",
                remarks: li?.remarks ? li?.remarks : "",
                vitalrefid:li?.ordercatlogid?._id ?? "",
                resultappl:li?.resultappl ?? true,
                resultreport:li?.resultreport ?? "string",
                resultcodeset:li?.resultcodeset,
                type: ""
      
              }
              componentdata.push(Obj1)
            } else {
              if(li?.ordercatlogid?.vitalscode?.toLowerCase()?.trim() === "richtext"){
                  setRichText(li?.richtext)
                  isRichText = true
              } else if(li?.ordercatlogid?.vitalscode?.toLowerCase()?.trim() === "image") {
                  let imgArr = []
                  let attach = []
                  li?.attachments?.map((v,i)=>{
                    let obj = {
                      _id:v?._id,
                      _key:v?._key,
                      name:v?.fileName,
                      type:v?.filetype,
                      reftype: v?.reftype,
                      url:v?.url,
                    }
                    let obj1 = {
                      _id:v?._id,
                      _key:v?._key,
                      fileName: v?.fileName ?? "",
                      fileid: v?.fileid ?? "",
                      filetype: v?.filetype ?? "",
                      objectid: v?.objectid ?? "",
                      url: v?.url ?? "",
                      reftype: v?.reftype,
                      // base64string:cleanBase64
                    }
                    attach.push(obj1)
                    imgArr.push(obj)
                  })
                  setViewFile(imgArr)
                  setFiles(attach);
                  isImage = true
              }
            }
          })
        })
        setenableFields({
          ...enableFields,
          isRichText: isRichText,
          isImage: isImage,
        });
        setlabResult(Resultdata?.payload?.data?.[0]?.components)
        setBodyData(Resultdata?.payload?.data)
        setcompData(componentdata)
        settableBody(HeaderData)
        setEditId(Resultdata?.payload?.data?.[0]?._id)
        setResultOpen(true);
        setisEdit(true)
    }else {
      setRadLabForm(data?.payload?.data)
      data?.payload?.data?.map(li => {
        if(li?.parentordercatname !== null){
          if(li?.ordercatlogname?.toLowerCase()?.trim() !== "richtext" && li?.ordercatlogname?.toLowerCase().trim() !== "image"){
            let obj = {
              id: HeaderData?.length + 1,
              Test: li?.ordercatlogname,
              Result: null,
              Unit: li?.unitdesc,
              NormalRange: `${li?.ordermin}-${li?.ordermax}`,
              Notes: null,
              ordercatlogid: li?.ordercatlogid ? li?.ordercatlogid : "",
              parentordercatid: li?.parentordercatid ? li?.parentordercatid : "",
              parentordercatname:li?.parentordercatname ? li?.parentordercatname : li?.ordercatlogname ?? "",
              resultappl:true,//li?.resultappl,
              resultreport:li?.resultreport ?? "string",
              resultcodeset:li?.resultcodeset,
            }
            let Obj1 = {
              orderlineid: li?.orderlineid,
              ordervalue: null,
              flag: "",
              ordermax: li?.ordermax,
              method: "",
              ordermin: li?.ordermin,
              orderuom: li?.orderuom,
              parentordercatid: li?.parentordercatid,
              ordercatlogid: li?.ordercatlogid,
              vitalrefid: li?.ordercatlogid,
              resultappl:true,//li?.resultappl,
              resultreport:li?.resultreport ?? "string",
              resultcodeset:li?.resultcodeset,
              remarks: "",
            }
            componentdata.push(Obj1)
            HeaderData.push(obj)
          } else {
            if(li?.ordercatlogname?.toLowerCase()?.trim() === "richtext"){
                isRichText = true
            } else if(li?.ordercatlogname?.toLowerCase()?.trim() === "image") {
                isImage = true
            }
          }
        }
      })
      setenableFields({
        ...enableFields,
        isRichText: isRichText,
        isImage: isImage,
      });
      setBodyData(data?.payload?.data)
      setcompData(componentdata)
      settableBody(HeaderData)
      settableHeader(HeadData)
      setButtonShow(true)
      setResultOpen(true);
    }
  }

  const getRadiologyRead = async () => {
    if(props?.patientgetData?.encounterId){
      let RadiologyData = await dispatch(
        actions.LABORDER_READ({
          encounter_id:  props?.patientgetData?.encounterId, //?? location?.state?.encounterId ?? location?.state?.encounter_id,
          order_id: "RAD",
          patientid:props?.patientgetData?.patientDetails?.patientId,
          startdate: props?.patientgetData?.encounterStart ? props?.patientgetData?.encounterStart : getUtcTime(moment().startOf("day")),
        })
      )
      setRadiology({
        ...props?.radiology,
        list:RadiologyData?.payload?.data
      })
      let defaultDate = new Date(props?.patientgetData?.encounterStart * 1000);
      setTreatmentProcedureEntry({
        ...treatmentProcedureEntry,
        headerShow: RadiologyData?.payload?.data?.length > 0 ? false : true ,
        orderCatalog:{},
        recurrentData:{
          every: {
            value: "",
            periodicity: {},
          },
          startsOn: {
            dateTime: defaultDate,
          },
          endsAfter: {
            endsAfter: "",
            endsBy: "",
          },
          preferrence: {
            preferredDays: [],
            preferredWeeks: [],
          }
        },
        dateTime: defaultDate,
      });
    }
  }

  const handleResultClose = () => {
    // getAllLabResult()
    setButtonShow(true)
    setResultOpen(false)
    // setlabResult([])
    setBodyData([])
    setcompData([])
    settableBody([])
  }

  const handleFileChange = async(event) => {
    if (event.target.files) {
      let attachment = files ?? [];
      const newFiles = Array.from(event.target.files);
      const allowedFileTypes = [
        'application/pdf', 
        'application/msword',     // .doc
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' // .docx
      ];
      const validFiles = newFiles.filter(file => allowedFileTypes.includes(file.type));
      if(validFiles?.length === 0){
        props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.warning,
          msg: "Accepted File Formats .pdf/.doc/.docx ",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
        return;
      }
      let fileData = await ToBase64(validFiles[0]);
      if(fileData){
        const cleanBase64 = fileData.replace(/^data:application\/pdf;base64,/, '');
        let obj = {
          fileName: validFiles[0]?.name,
          fileid: "",
          filetype: validFiles[0]?.type,
          base64string:cleanBase64
        }
        attachment.push(obj)
        setFiles(attachment);
      }
      
    //       files?.map((v,i)=>{
    //   let fileData = ""
    //   const cnvtB64 = async () => {
    //     fileData = await ToBase64(v);
    //     if(fileData){
    //       let obj = {
    //         fileName: v?.name,
    //         fileid: "",
    //         filetype: v?.type,
    //         base64string:fileData
    //       }
    //       attachment.push(obj)
    //     }
    //   }
    //   cnvtB64();     
    // })
    setViewFile((prevFiles) => [...prevFiles, ...validFiles]);
    }
  };

  const handleDelete = (index,file) => {
    if(file?._id){
      let delFiles = files
      let obj = {
        _id:file?._id,
        _key:file?._key,
        fileName: file?.fileName ?? "",
        fileid: file?.fileid ?? "",
        filetype: file?.filetype ?? "",
        objectid: file?.objectid ?? "",
        url: file?.url ?? "",
        reftype: file?.reftype ?? "",
        is_deleted: true,
        // base64string:cleanBase64
      }
      delFiles.splice(index, 1);
      delFiles.push(obj)
      setViewFile((prevFiles) => prevFiles?.filter((_, i) => i !== index));
      setFiles(delFiles);
      return
    }
    setViewFile((prevFiles) => prevFiles?.filter((_, i) => i !== index));
    setFiles((prevFiles) => prevFiles?.filter((_, i) => i !== index));
  };

  const handleView = (file) => {
    if(file?.url){
      const fileExtension = file.name.split('.').pop();
    setSelectedFile({ type: fileExtension, url: file?.url });
    return
    }
    const blobUrl = URL.createObjectURL(file);
    const fileExtension = file.name.split('.').pop();
    setSelectedFile({ type: fileExtension, url: blobUrl });
    // setViewFile(true);
  };

  const handleEdit = (index) => {
    // setEditIndex(index);
    // setFileName(files[index].name);
  };
  const saveRadiologyResult = async (components,richtext) => {
    let componentsData = components
    // const filteredData = radLabForm?.filter(li => 
    //   editId ? li?.ordercatlogid?.vitalscode?.toLowerCase()?.trim() : li?.ordercatlogname?.toLowerCase()?.trim() === "richtext" || 
    //   editId ? li?.ordercatlogid?.vitalscode?.toLowerCase()?.trim() : li?.ordercatlogname?.toLowerCase()?.trim() === "image"
    // );
    const filteredData = radLabForm?.filter(li => {
      if (editId) {
        return li?.ordercatlogid?.vitalscode?.toLowerCase()?.trim()  === "richtext" || 
               li?.ordercatlogid?.vitalscode?.toLowerCase()?.trim()  === "image";
      } else {
        return li?.ordercatlogname?.toLowerCase()?.trim() === "richtext" || 
               li?.ordercatlogname?.toLowerCase()?.trim() === "image";
      }
    });
    filteredData?.map((v,i)=>{
        let obj = {
          orderlineid: editId ? v?.orderlineid?._id : v?.orderlineid,
          resultvalue: "",
          flag: "",
          ordermax: null,
          ordermin: null,
          resultuom: "",
          parentordercatid: editId ? v?.parentordercatid?._id : v?.parentordercatid ? v?.parentordercatid : "",
          ordercatlogid: editId ? v?.ordercatlogid?._id : v?.ordercatlogid,
          remarks: v?.Notes ?? "",
          vitalrefid: editId ? v?.ordercatlogid?._id : v?.ordercatlogid,
          method: "",
          richtext: editId 
          ? (v?.ordercatlogid?.vitalscode?.toLowerCase()?.trim() === "richtext" 
              ?  richtext?.toString()
              : "")
          : (v?.ordercatlogname?.toLowerCase()?.trim() === "richtext" 
              ? richtext?.toString() 
              : ""),
          attachments: editId 
          ? (v?.ordercatlogid?.vitalscode?.toLowerCase()?.trim() === "image" 
              ? files 
              : [])
          : (v?.ordercatlogname?.toLowerCase()?.trim() === "image" 
              ? files 
              : [])
        }
        componentsData.push(obj);
    })
    let doc = {
      tenantid: process.env.REACT_APP_TENANTID,
      encounterid: props?.patientgetData?.encounterId,// ?? location?.state?.encounterId ?? location?.state?.encounter_id,
      facilityid: getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"facilityid"}),
      patientid: props?.patientgetData?.patientDetails?.patientId ?? props?.patientgetData?.patientDetails?.patientId,
      components: componentsData,
    }
    props?.backDrop?.setBackDrop({
      ...props?.backDrop,
      open: true,
      message: "Adding Radiology Result",
    });
    let payloadObj = editId ? { doc: doc, _id: editId } : { doc: doc }
    let data = await dispatch(actions.SAVE_RESULT_DATA(payloadObj))
    props?.backDrop?.setBackDrop({
      ...props?.backDrop,
      open: false,
      message: "",
    });

    if (data?.payload?.data?.error) {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Failed Added Radiology Result",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
    else if (!data?.payload?.data?.error) {
      setFiles([])
      setViewFile([])
      setenableFields({
        ...enableFields,
        isRichText: false,
        isImage: false,
      });
      setButtonShow(true)
      setResultOpen(false);
      setEditId("")
      setisEdit(false)
      getRadiologyRead()
      // const tenantId = await getTenantId();
      // const facilityId = await getFacilityId();
      // const datass = await dispatch(actions.GET_ALL_LAB_RESULT({
      //   patientid: location?.state?.patientId ?? location?.state?.pId,
      //   encounterid: props?.patientgetData?.encounterId, //?? location?.state?.encounterId ?? location?.state?.encounter_id
      //   orderlineid:"",
      //   tenantId,
      //   facilityId,
      // }))

      // if (datass?.payload?.data?.length > 0) {
      //   setlabResult(datass?.payload?.data)
      //   setBodyData(datass?.payload?.data)
      //   setResultOpen(false)
      //   setEditId("")
      //   getAllLabResult(true)
      //   getLabOrderRead()
      // }
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.success,
        // msg: `Please fill the ${err[Object.keys(state?.error)?.[0]]} !`,
        msg: "Radiology Result Added Succesfully",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
    settableBody([])
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const { parent_id } = props;
  return (
    <div
      id={`${parent_id}_radiology_parent_div`}
      style={{ width: "100%", marginBottom: 0 }}
    >
      <Row
        id={`${parent_id}_radiology_parent_row`}
        inLineStyles={styles.vitailsCard}
      >
        {/* Add a new treatment procedures data's */}
        <Col
          id={`${parent_id}_radiology_parent_col`}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          inLineStyles={
            treatmentProcedureEntry.headerShow
              ? styles.vitalsHead
              : styles.vitalsHead1
          }
        >
          {treatmentProcedureEntry.headerShow ? (
            <div
              id={`${parent_id}_radiology_order_div`}
              style={styles.vitalsfieldHead}
            >
              <div
                id={`${parent_id}_radiology_order_sub_div`}
                style={{ marginRight: 16, width: "100%" }}
              >
               <div
                id={`${parent_id}_medication_order_headerShow_div`}
                style={{
                  display: "flex",
                  width: "99%",
                  justifyContent: "space-between",
                   padding:"12px"
                }}
              >
                <Text
                  id={`${parent_id}_medication_order_headerShow_text`}
                  // onClick={() =>
                  //   handleInputChange(!entry.headerShow, "headerShow")
                  // }
                  inLineStyles={{
                    fontWeight: 600,
                    fontSize: "14px",
                    cursor: "pointer",
                    color: UIColor.primaryColor.color,
                    alignItems: "center",
                    marginLeft: "-7px",

                  }}
                >
                  {"Radiology Order"}
                </Text>
              </div>
              <Divider orientation="horizontal" variant="fullWidth" style={{ margin: "5px -10px", height: "0.1px",width: "28.7cm" }} />
              <Grid style={{display:"flex",gap:"10px", padding:"15px"}}>
                  <div
                    id={`${parent_id}_medication_order_title_div`}
                    style={{ marginRight: 16 }}
                  >
                <Autocomplete
                  className={classes.autocomplete}
                  style={{ width: "200px" }}
                  fullWidth
                  id="size-small-standard"
                  size="small"
                  options={storeData?.radiology_getcategory?.data ?? []}
                  popupIcon={
                    <ExpandMoreIcon
                      fontSize="small"
                      style={{ color: "#b4aeae" }}
                    />
                  }
                  disableClearable
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      id={`${parent_id}_radiology_order_Search_textfield`}
                      {...params}
                      variant="standard"
                      placeholder="Search Radiology orders"
                    />
                  )}
                  renderOption={(option) => (
                    <Typography
                      id={`${parent_id}_radiology_${option.label}_typography`}
                      className="pc_regular"
                      style={{
                        width: "100%",
                        fontSize: 14,
                        color: UIColor.secondaryText.color,
                      }}
                      noWrap
                    >
                      {option.label}
                    </Typography>
                  )}
                  classes={{ popper: "muiPopperDropdown" }}
                  value={treatmentProcedureEntry.orderCatalog}
                  onChange={(e, newValue) =>
                    handleInputChange(newValue, "orderCatalog")
                  }
                />
              </div>
              <div
                id={`${parent_id}_radiology_Priority_div`}
                style={{ marginRight: 16 }}
              >
                <Autocomplete
                  className={classes.autocomplete}
                  style={{ width: "120px" }}
                  id="size-small-standard"
                  size="small"
                  options={storeData?.radiology_priority?.data ?? []}
                  popupIcon={
                    <ExpandMoreIcon
                      fontSize="small"
                      style={{ color: "#b4aeae" }}
                    />
                  }
                  disableClearable
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      id={`${parent_id}_radiology_Priority_textfield`}
                      {...params}
                      variant="standard"
                      placeholder="Priority"
                    />
                  )}
                  renderOption={(option) => (
                    <Typography
                      id={`${parent_id}_radiology_Priority_${option.label}_typography`}
                      className="pc_regular"
                      style={{
                        width: "100%",
                        fontSize: 14,
                        color: UIColor.secondaryText.color,
                      }}
                      noWrap
                    >
                      {option.label}
                    </Typography>
                  )}
                  // classes={{popper: "muiPopperDropdown"}}
                  value={treatmentProcedureEntry.priority}
                  onChange={(e, newValue) =>
                    handleInputChange(newValue, "priority")
                  }
                />
              </div>
              <div
                id={`${parent_id}_radiology_Nature_div`}
                style={{ marginRight: 16,}}
              >
                <Autocomplete
                  className={classes.autocomplete}
                  style={{ width: "120px" }}
                  id="size-small-standard"
                  size="small"
                  options={storeData?.radiology_nature?.data ?? []}
                  popupIcon={
                    <ExpandMoreIcon
                      fontSize="small"
                      style={{ color: "#b4aeae" }}
                    />
                  }
                  disableClearable
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      id={`${parent_id}_radiology_Nature_textfield`}
                      {...params}
                      variant="standard"
                      placeholder="Nature"
                    />
                  )}
                  renderOption={(option) => (
                    <Typography
                      id={`${parent_id}_radiology_Nature_${option.label}_typography`}
                      className="pc_regular"
                      style={{
                        width: "100%",
                        fontSize: 14,
                        color: UIColor.secondaryText.color,
                      }}
                      noWrap
                    >
                      {option.label}
                    </Typography>
                  )}
                  // classes={{popper: "muiPopperDropdown"}}
                  value={treatmentProcedureEntry.nature}
                  onChange={(e, newValue) =>
                    handleInputChange(newValue, "nature")
                  }
                />
              </div>
              <div
                id={`${parent_id}_radiology_calender_div`}
                style={{ marginRight: 16}}
              >
                <TextField
                  autoComplete={"off"}
                  style={{ width: 170 }}
                  className={classes.autocomplete}
                  value={treatmentProcedureEntry?.dateTime ? moment(treatmentProcedureEntry?.dateTime).format("YYYY-MM-DD, hh:mm A") : ""}
                  onClick={(event) => handleClick(1, event)}
                  onChange={(e, newValue) =>
                    handleInputChange(newValue, "dateTime")
                  }
                  id="standard-basic-date"
                  placeholder={"Start Date"}
                  InputProps={{
                    classes: { input: classes.input },
                    endAdornment: (
                      <InputAdornment
                        id={`${parent_id}_radiology_calender_inputadornment`}
                        position="end"
                      >
                        <Div
                          id={`${parent_id}_radiology_calender_date_div`}
                          aria-describedby={id}
                          style={{ cursor: "pointer" }}
                          onClick={(event) => handleClick(1, event)}
                        >
                          <CalendarIcon
                            id={`${parent_id}_radiology_calender_icon`}
                            color={UIColor.secondaryText.color}
                            width={"16"}
                            height={"16"}
                          />
                        </Div>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div
                id={`${parent_id}_radiology_Recurrent_div`}
                style={{ marginRight: 16}}
              >
                {treatmentProcedureEntry.orderCatalog?.value && (
                  <Div
                    id={`${parent_id}_radiology_Recurrent_sub_div`}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Text
                      id={`${parent_id}_radiology_Recurrent_text`}
                      className="pc_regular"
                      inLineStyles={{
                        color: UIColor.secondaryText.color,
                        fontSize: 12,
                      }}
                    >
                      {"Recurrent"}
                    </Text>
                    <Switch
                      id={`${parent_id}_radiology_Recurrent_switch`}
                      aria-describedby={id}
                      checked={treatmentProcedureEntry.recurrent}
                      onChange={(event) => {
                        handleInputChange(event.target.checked, "recurrent");
                        event.target.checked && handleClick(0, event);
                      }}
                      className={classes.switch}
                    />
                  </Div>
                )}
              </div>
              <div id={`${parent_id}_radiology_button_div`} style={styles.flexFlow}>
            {treatmentProcedureEntry.headerShow && (
              <>
                {treatmentProcedureEntry.orderCatalog.label &&
                treatmentProcedureEntry.dateTime &&
                treatmentProcedureEntry.nature.label &&
                treatmentProcedureEntry.priority.label ? (
                  <div
                    id={`${parent_id}_radiology_button_sub_div`}
                    style={{
                      width: "100%",
                      display: "flex",
                      marginRight: "10px",
                    }}
                  >
                    <Button
                      id={`${parent_id}_radiology_Add_button`}
                      onClick={() => AddTreatmentProcedure()}
                      variant={"text"}
                      style={{
                        color: UIColor.primaryColor.color,
                        fontWeight: 600,
                        minWidth: "30%",
                        padding: "5px",
                        marginRight: "10px",
                      }}
                      size={"small"}
                    >
                      <img src={Tick} alt="Add" />
                    </Button>
                    <Button
                      id={`${parent_id}_allergy_add_button`}
                      onClick={() => handleCloseForm()}
                      variant={"text"}
                      style={{
                        color: UIColor.primaryColor.color,
                        fontWeight: 600,
                        minWidth: "30%",
                        padding: "5px",
                      }}
                      size={"small"}
                    >
                      <img src={Cancel} alt="Cancel" />
                    </Button>
                  </div>
                ) : (
                  <div
                    id={`${parent_id}_radiology_Add_div`}
                    style={{
                      width: "100%",
                      display: "flex",
                      marginRight: "10px",
                    }}
                  >
                    <Button
                      id={`${parent_id}_allergy_add_button`}
                      onClick={() => handleCloseForm()}
                      variant={"text"}
                      style={{
                        color: UIColor.primaryColor.color,
                        fontWeight: 600,
                        minWidth: "30%",
                        padding: "5px",
                      }}
                      size={"small"}
                    >
                      <img src={Cancel} alt="Cancel" />
                    </Button>
                  </div>
                )}
              </>
            )}
            {/* <Tooltip
              id={`${parent_id}_radiology_Insights_title_tooltip`}
              title={"Insights"}
            >
              <div
                id={`${parent_id}_radiology_Insights_div`}
                style={{ ...styles.infoBell, ...styles.icons }}
              >
                <InsightsIcon
                  id={`${parent_id}_radiology_Insights_icon`}
                  style={{ marginLeft: 5 }}
                  // color={UIColor.primaryColor.color}
                />
              </div>
            </Tooltip> */}
          </div>
              </Grid>
              </div>
            </div>
          ) : (
            <div
              id={`${parent_id}_radiology_Order_div`}
              style={{
                display: "flex",
                width: "99%",
                justifyContent: "space-between",
              }}
            >
              <Text
                id={`${parent_id}_radiology_Order_text`}
                onClick={() =>
                  handleInputChange(
                    !treatmentProcedureEntry.headerShow,
                    "headerShow"
                  )
                }
                inLineStyles={{
                  fontWeight: 600,
                  fontSize: "14px",
                  cursor: "pointer",
                  color: UIColor.primaryColor.color,
                }}
              >
                {"Radiology Order"}
              </Text>
              <div
                onClick={() =>
                  handleInputChange(
                    !treatmentProcedureEntry.headerShow,
                    "headerShow"
                  )
                }
                style={{ cursor: "pointer", display: "flex" }}
              >
                <img src={Addicon} alt="Add" />
              </div>
            </div>
          )}{" "}
        </Col>

        {/* Treatment procedure list */}
        {list?.length > 0 &&
          list?.map((val, i) => {
            // if (!val.isNew) {
            //   return <React.Fragment />;
            // }
            return (
              <Col
                id={`${parent_id}_radiology_${i}_list_col`}
                key={i}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                inLineStyles={{
                  padding: "10px 8px",
                  borderTop: `1px solid ${UIColor.lineBorderFill.color}`,
                  overflow: "hidden",
                }}
                className={"allergyListHover__radiology"}
              >
                <RadiologyList
                  parent_id={"patientchart_radiologylist" + i}
                  treatment_order={val?.orderCatalog?.label}
                  priority={val?.priority?.label}
                  nature={val?.nature?.label}
                  dateTime={val?.dateTime}
                  ordersData={treatmentProcedureEntry?.orders}
                  onEditChart={props.onEditChart}
                  val={val}
                  infoAlertText={val.infoAlertText}
                  status={
                    val?.childRecurrent?.[0]?.statushistory?.length > 0
                      ? val?.childRecurrent?.[0]?.statushistory?.[
                          val?.childRecurrent?.[0]?.statushistory?.length - 1
                        ]?.new_status ?? "Draft"
                      : "Draft"
                  }
                  IndexValue={i}
                  deleteList={deleteList}
                  editFun={editFun}
                  data={val}
                  updatefuncy={updatefuncy}
                  info={info}
                  getData={getData}
                  patientgetData={props?.patientgetData}
                  recurrentData={val?.recurrentData}
                  saveRadiology={props.saveRadiology}
                  getSingleRadiologyForm={getSingleRadiologyForm}
                  isResulted={val?.isResulted}
                />
              </Col>
            );
          })}
      </Row>
      {treatmentProcedureEntry.error && (
        <span
          id={`${parent_id}_radiology_filled_Recurrent_span`}
          style={styles.vitailsCarderr}
        >
          Please fill all the mandatory fields!
        </span>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{ paper: classes.muiPopovers }}
      >
        {index === 0 && (
          <Div
            id={`${parent_id}_radiology_Recurrent_div`}
            style={{ padding: 15 }}
          >
            <Recurrent
              parent_id={"radiology"}
              recurrentData={treatmentProcedureEntry?.recurrentData}
              saveButtonAction={saveRecurrentData}
              deleteButtonAction={handleClose1}
              width={500}
            />
          </Div>
        )}
        {index === 1 && (
          // <DatePicker
          //   id={`${parent_id}_radiology_DatePicker`}
          //   timeInputLabel="Time:"
          //   dateFormat="MM/dd/yyyy h:mm aa"
          //   format="MM/dd/yyyy h:mm aa"
          //   showTimeInput
          //   // selected={this.state.dob.value}
          //   selected={new Date()}
          //   // showPopperArrow={false}
          //   // showYearDropdown
          //   minDate={new Date()}
          //   minTime={new Date()}
          //   inline
          //   onChange={(date) => {
          //     handleInputChange(
          //       moment(date).format("YYYY-MM-DD,hh:mm A"),
          //       "dateTime"
          //     );
          //     handleClose();
          //   }}
          // // placeholderText="DD-MM-YYYY"
          // // maxDate={new Date()}
          // // className={"pc_regular"}
          // />
          <CustomDatePicker
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            selectedDate={treatmentProcedureEntry?.dateTime ? new Date(treatmentProcedureEntry?.dateTime) : null }
            minDate={new Date()}
            minTime = {getCurrentMinTime(treatmentProcedureEntry?.dateTime ? new Date(treatmentProcedureEntry?.dateTime) : null)}
            maxTime = {setHours(setMinutes(new Date(), 59), 23)}
            handleChange={(date, e) => {
              handleInputChange(
                date,
                "dateTime"
              );
              if(e?.target === undefined)
              {
                setIndex(null)
                handleClose();
              }
            }}
          />
        )}
      </Popover>
      {alertData.open && (
        <AlertsRestriction {...alertData} handleClose={closeAlertModal} />
      )}
    <Dialog
        fullWidth
        maxWidth={"lg"}
        open={resultOpen}
        onClose={()=>setResultOpen(false)}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title"> Radiology Result</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <AddResult
                TableBody={tableBody}
                TableHeader={tableHeader}
                BodyData={BodyData}
                parent_id={parent_id}
                handleResultClose={handleResultClose}
                saveLabResult={saveRadiologyResult}
                componentdata={compData}
                ButtonShow={buttonShow}
                isRichText={enableFields?.isRichText}
                isuploadDoc={enableFields?.isImage}
                setDrawer={setDrawer}
                labResult={labResult}
                isEdit={isEdit}
                setRichText={setRichText}
                richText={richText}
                files={files}
              />
            </Grid>
          </Grid>

          <Drawer
            anchor="right"
            open={drawer}
            onClose={() => setDrawer(false)}
            container={document.querySelector(".MuiDialog-root")} // Scope the drawer to the dialog
          >
            <Box sx={{ 
              width: 500, 
              padding: 2, 
              display: "flex",
              flexDirection: "column",
              height: "100%",
              position: "relative", 
            }}>
              <Grid>
                <IconButton
                  onClick={() => {
                    selectedFile ?  setSelectedFile() : setDrawer(false) 
                  }}
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    zIndex: 1,
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              {!selectedFile ? (
              <Grid style={{padding:"10px",width:"400px"}}>
                <Button
                  component="label"
                  role={undefined}
                  variant="outlined"
                  tabIndex={-1}
                  color="primary"
                  fullWidth
                  startIcon={<CloudUploadIcon />}
                >
                  Upload Documents
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(event) => handleFileChange(event)}
                    multiple
                  />
                </Button>
                <List>
                  {viewFile.map((file, index) => (
                    <ListItem key={index} divider>
                    <Grid container>
                      <Grid item style={{marginTop:"13px",marginRight:"10px"}}>
                        <DescriptionOutlinedIcon/>
                      </Grid>
                      <Grid item>
                        <ListItemText
                          style={{fontFamily:"inherit"}}
                          primary={file.name}
                          secondary={`Size: ${(file.size / 1024).toFixed(2)} KB`}
                        />
                      </Grid>
                    </Grid>
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="view"
                          onClick={() => handleView(file)}
                          color="primary"
                        >
                          <VisibilityOutlinedIcon />
                        </IconButton>
                        {/* <IconButton
                          edge="end"
                          aria-label="edit"
                          onClick={() => handleEdit(index)}
                          color="default"
                        >
                          <DriveFileRenameOutlineOutlinedIcon />
                        </IconButton> */}
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleDelete(index,file)}
                          color="secondary"
                        >
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Grid>
              ) : (
              <Grid style={{padding:"10px",width:"600px"}}>
              <FileViewer id="UMHit_16" fileType={selectedFile?.type} filePath={selectedFile?.url} />
              </Grid>
              )}
            </Box>
          </Drawer>
        </DialogContent>

        {/* <DialogActions>
          <Button onClick={()=>setResultOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
};

export default withAllContexts(Radiology);
