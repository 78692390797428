import { Row, Col, Divider, Card } from "qdm-component-library";
import DoctorDetailsCard from "./components/doctorDetailsCard";
import SlotCalandar from "./components/slotCalandar";
import { useSelector } from "react-redux";

const AvailableDoctorsWithSlots = (props) => {
  const SaveCNLoading = useSelector(
    (state) => state?.appointment?.readClinicInfo
  );
  const { parent_id } = props;
  return (
    <div
      id={`${parent_id}_doctorcard_parent_div`}
      style={{
        backgroundColor: "#fff",
        padding:
          props.totlenght === 1
            ? "10px"
            : props.keydata === 0
            ? "12px 12px 0px"
            : props.keydata + 1 === props.totlenght
            ? "12px 12px 20px"
            : "12px 12px 0px",
        // padding: "12px",
        borderRadius:
          props.totlenght === 1
            ? "10px"
            : props.keydata === 0
            ? "10px 10px 0px 0px"
            : props.keydata + 1 === props.totlenght
            ? "0px 0px 10px 10px"
            : 0,
        boxShadow: "0px 10px 25px #0000000A",
      }}
    >
      <Card
        id={`${parent_id}_doctorcard_parent_card`}
        key={"0"}
        children="Card"
        style={{
          padding: "16px",
          // marginBottom: "16px",
          backgroundColor: "#fff",
          borderRadius: "8px",
          width: "100%",
          border: "1px solid #e0e0e0",
          boxShadow: "none",
        }}
      >
        <Row id={`${parent_id}_doctorcard_parent_row`} key={"0"}>
          {/* Doctor/Clinic Details */}
          <Col
            id={`${parent_id}_doctorcard_parent_col`}
            key={"0"}
            name="qdm-col-12"
            alignItems="stretch"
            direction="row"
            justify="flex-start"
            textAlign="left"
            lg="5"
            xs="12"
            md="5"
            sm="12"
            style={{ padding: "0px", display: "flex" }}
          >
            <DoctorDetailsCard 
            data={props?.data}
            changeState={props?.handleChange}
            state = {props?.values}
            profileData={props?.profileData}
            clinicData={SaveCNLoading?.data?.[0]}
            ></DoctorDetailsCard>

            <Divider
              id={`${parent_id}_doctorcard_view_clinix_divider`}
              key={"1"}
              textWidth="50"
              textColor=""
              borderColor=""
              borderWidth="1"
              orientation="vertical"
              variant="middle"
              className=""
              label=""
              style={{ height: "100%" }}
            ></Divider>
          </Col>

          {/* Slot Calandar */}
          <Col
            id={`${parent_id}_doctorcard_weekcalender_col`}
            key={"1"}
            name="qdm-col-12"
            alignItems="stretch"
            direction="row"
            justify="flex-start"
            textAlign="left"
            lg="7"
            xs="12"
            md="7"
            sm="12"
            style={{ padding: "0px" }}
          >
            <SlotCalandar props={props}></SlotCalandar>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default AvailableDoctorsWithSlots;
