import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  defaultReject,
  defaultState,
  fetchData,
  urls,
  dbName,
  query_ids,
  queries,
  getFacilityId,
  getGlobalValueFrom_LclStrg_And_QryStrg,
} from "../../../utils";
//import jwtDecode from "jwt-decode";

const GET_ALERT_CODES = createAsyncThunk(
  `medicationsSlice/get_alert_codes`,
  async ({ patientId }, { rejectWithValue }) => {
    try {
      // const data = await fetchData(
      //   {
      //     body: JSON.stringify(queries.getAlertCode(patientId)),
      //   },

      // );

      const body = {
        db_name: dbName,
        queryid: "6385b198-8765-49b3-a5f4-0faac3e30fdd",
        filter: {
          patientID: patientId,
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      //converting profile code to number then removing the NaN
      let profileDrugCode = data?.[0]?.SavedDrugCodes?.map((val) =>
        parseInt(val)
      )?.filter((val) => !Number.isNaN(val));
      //converting allergy code to number then removing the NaN
      let allergyCode = data?.[0]?.SavedAllergyCodes?.map((val) =>
        parseInt(val)
      )?.filter((val) => !Number.isNaN(val));
      let object = {
        ProfileDrugCodes: [...new Set(profileDrugCode)], //removing duplicate values
        DiseaseCodes: [...data?.[0]?.SavedDiseaseCodes],
        AllergyCodes: [...new Set(allergyCode)], //removing duplicate values
      };
      return {
        ...defaultState.Info,
        data: object,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const REASON_DROPDOWN_MASTER = createAsyncThunk(
  `medicationsSlice/reason_dropdown_master`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        queryid: query_ids.reasonDropdownMaster,
        // filter: {},
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const REASON_TYPE_CODE_READ = createAsyncThunk(
  `medicationsSlice/reason_type_code_read`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const {display} = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids.reasonTypeCodeRead,
        filter: {
          display: display,
        },
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const GET_ORDER_DATE = createAsyncThunk(
  `medicationsSlice/get_order_date`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        { body: JSON.stringify(payload) },
        urls.orderStartDate
      );
      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const GET_ALLERGY_INTERACTION = createAsyncThunk(
  `medicationsSlice/get_allergy_interaction`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const {input} = payload
      const data = await fetchData(
        { body: JSON.stringify(input) },
        urls.clinicalScreening
      );
      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const GET_DRUG_DUPLICATION = createAsyncThunk(
  `medicationsSlice/get_drug_duplication`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const {pdd} = payload
      const data = await fetchData(
        { body: JSON.stringify(pdd) },
        urls.clinicalScreening
      );
      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const GET_MEDICATIONS = createAsyncThunk(
  `medicationsSlice/medication_list`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { id, tenantid = "", facilityid = "" } = payload;
      const body = {
        db_name: dbName,
        queryid: "932a33c0-40ed-4f32-8b03-957e005fe509",
        filter: {
          encounterid: id,
          tenantid: tenantid,
          facilityid: facilityid,
        },
      };

      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      // const data = await fetchData(
      //   { body: queries.getMedications(id) },
      //   __readDocumentUrl__
      // );
      return {
        ...defaultState.List,
        // data: data?.result,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const ALERT_CODES = createAsyncThunk(
  `medicationsSlice/alert_codes`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { list, tenantid = "", facilityid = "" } = payload;
      const body = {
        db_name: dbName,
        queryid: "65d36782-4399-487f-90b4-6e53ca8464e2",
        filter: {
          encounterid: list,
          tenantid: tenantid,
          facilityid: facilityid,
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      // const data = await fetchData(
      //   {
      //     body: JSON.stringify(codeQuery.AllergyCodes(list)),
      //   },
      //   __readDocumentUrl__
      // );
      const code = data.map((val) => {
        return {
          codes: val?.code?.longdesc,
        };
      });
      return {
        ...defaultState.Info,
        data: code,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const DRUG_CODES = createAsyncThunk(
  `medicationsSlice/drug_codes`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      //   const { list } = payload;
      //   const data = await fetchData(
      //     {
      //       body: JSON.stringify(codeQuery.DrugCodes(list)),
      //     },
      //     __readDocumentUrl__
      //   );
      const { list, tenantid = "", facilityid = "" } = payload;
      const body = {
        db_name: dbName,
        queryid: "b92c85e8-a43b-481d-b43b-332a82c2c961",
        filter: {
          encounterid: list,
          tenantid: tenantid,
          facilityid: facilityid,
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      const code = data.map((val) => {
        return {
          codes: val?.diagcode?.icdcode,
        };
      });
      return {
        ...defaultState.Info,
        data: code,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const GET_ORDERED_STATUS_ID = createAsyncThunk(
  `medicationsSlice/get_ordered_status_id`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { tenantid = "", facilityid = "", lang = "" } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["GetAllMasters"],
        filter: {
          type: ["MEDRECORDSTATUS"],
          tenantid: tenantid,
          facilityid: facilityid,
          lang: lang,
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let returnData = [];
      if (Array.isArray(data)) {
        data?.[0]?.MEDRECORDSTATUS?.Value?.map((val, i) => {
          returnData.push({
            value: val?._id,
            label: val?.display,
          });
        });
      }
      return {
        ...defaultState.Info,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const SAVE_MEDICATIONS = createAsyncThunk(
  `medicationsSlice/save_medication`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { isEdit = false, ...rest } = payload;
      let body = generate_SAVE_MED_JSON(rest, isEdit);

      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.insertMedicationOrder
      );

      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
export const generateAlertPayload = (data, roleid, practitionerid) => {
  let AlertTypes = [];
  data.forEach((val) => {
    AlertTypes = [...AlertTypes, ...Object.keys(val)];
  });

  let OverAllAlerts = [];
  data.forEach((val, i) => {
    OverAllAlerts = [...OverAllAlerts, ...val[AlertTypes[i]]];
  });
  return OverAllAlerts?.map((val, i) => {
    let filter = {};
    if (val._key) {
      filter = { filter: { _key: val?._key } };
    }
    return {
        orderlineid: val?.orderLineId ?? "",
        seqno: i,
        reactiontypecode: val?.alertType,
        alerttitle: val?.Message ?? val?.alerttitle ?? "",
        alertmessage: val?.IAMTEXTN ?? val?.alertmessage ?? "",
        overridedetails:
          val?.overridedetails?.length > 0
            ? [
                {
                  ...(val.overridedetails?.[0] ?? {}),
                  // overridereasoncode: "",
                  // overridenotes: "",
                  overridebyid: practitionerid ?? "",
                  overrideroleid: roleid ?? "",
                },
              ]
            : [],
      
    };
  });
};
const getrequestor = (list) => {
  let arr = [];
  // list.map(val => {
  arr.push({
    organization: list.organization?.value ?? "",
    practRole: list.practitionerRole?._id ?? "",
    practId: list.orderBy?.value ?? "",
  });
  // })
  return arr;
};
const getPerformer = (list) => {
  let arr = [];
  arr = list.map((val) => {
    arr.push({
      organization: val.organization ?? "",
      actionType: val.actionType ?? "",
      practType: val.practType ?? "",
      practRole: val.practRole ?? "",
      practId: val.practId ?? "",
      locationRoleType: val.locationRoleType ?? "",
      locationCode: val.locationCode ?? "",
      storeCode: val.storeCode ?? "",
    });
  });
  return arr;
};
const getComponent = (list) => {
  let arr = [];
  list.map((val, i) => {
    arr.push({
      seqno: i + 1,
      drugcode: val.description?.value ?? "",
      formcode: val.dosage?.dosageForm ?? "",
      dosageQty: val.dosage?.quantity ?? 0,
      dosageQtyUOM: val.dosage?.unit?.value ?? "",
    });
  });
  return arr;
};
const DELETEMEDICATION_MASTER = createAsyncThunk(
  "MedicationMastersSlice/DeleteMedication_masters",
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        entity: "PH_OrderLine",
        filter: `PH_OrderLine._id =='${payload.id}'`,
        metadataId: process.env.REACT_APP_METADATAID,
        metadata_dbname: process.env.REACT_APP_METADATA_DBNAME,
      };
      // const data = await fetchData(
      //   {
      //     body: queries.DeleteMedication(payload.id),
      //   },
      //   urls.deleteMedicationOrder
      // );
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.deleteMedicationOrder
      );
      return {
        ...defaultState.List,
        data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
export const generate_SAVE_MED_JSON = (payload, isEdit) => {
  let getAlertPayload = [];
  if (payload?.alertData?.length > 0) {
    getAlertPayload = generateAlertPayload(
      payload?.alertData,
      payload?.roleid,
      payload?.practitionerid
    );
  }
  return [
    {
      db_name: dbName,
      entity: "PH_OrderLine",
      is_metadata: true,
      metadataId: process.env.REACT_APP_METADATAID,
      metadata_dbname: process.env.REACT_APP_METADATA_DBNAME,
      ...(isEdit && { filter: { _id: payload?.editId } }),
      doc: {
        isfirststatorder: false,
        SlidingScaleDose: "",
        org_Id: getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"facilityid"}),
        facilityid: getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"facilityid"}),
        tenantid: "1234",
        addtictive: [],
        id: 0,
        orgEntityType: "",
        patient_Id: payload?.patientid ?? "",
        encountertype: payload?.encountertype ?? "",
        encounter_Id: payload?.encounterid ?? "",
        prescriptionType: "", //"CodingMaster/11035"
        prescriptionId: payload?.prescriptionId ?? "",
        PrescriptionDate: payload?.PrescriptionDate ?? 0,
        drugType: payload?.name?.drugTypeId ?? "",
        presNature: payload?.presNature?.value ?? "",
        presMode: payload?.presMode?.value ?? "",
        priority: payload?.priority?.value ?? "",
        status: payload?.status ?? "",
        statusreason: payload?.statusreason ?? "",
        intent: payload?.intent ?? "",
        orderPrivilegeLevel: payload?.orderPrivilegeLevel ?? "",
        privilegeAlertMessageTxt: payload?.privilegeAlertMessageTxt ?? "",
        workFlowStatus: payload?.workFlowStatus ?? "",
        drugCode: payload?.name?.value ?? "",
        formCode: payload?.formCode ?? "",
        routeCode: payload?.DefaultRouteCode?.value ?? "",
        dosageTypeIndicator: payload?.dosageTypeIndicator ?? "",
        dosageValue: payload?.doseMeasurementValue
          ? parseInt(payload?.doseMeasurementValue)
          : 0,
        dosageUOM: payload?.unitValue?.value ?? "",
        drug_category: payload?.drug_category?.value ?? "",
        dispensing_pharmacy: payload?.dispensing_pharmacy?.value ?? "",
        IVDosageDurationValue: payload?.IVDosageDurationValue
          ? parseInt(payload?.IVDosageDurationValue)
          : 0,
        IVDosageDurationUOM: payload?.IVDosageDurationUOM?.value ?? "",
        dosageFreqCode: payload?.unitsTypes?.value ?? "",
        ivInfusionRateValue: payload?.ivInfusionRateValue
          ? parseInt(payload?.ivInfusionRateValue)
          : 0,
        ivInfusionRateUOM: payload?.ivInfusionRateUOM?.value ?? "",
        dosageDurationValue: payload?.dosetime
          ? parseInt(payload?.dosetime)
          : 0,
        dosageDurationUOM: payload?.doseOption?.value ?? "",
        orderdescription: payload?.name?.nameLabel ?? "",
        ivInfusionTime: 0,
        ivInfusionTimeUom: "",
        useAsSlidingScaleDose: [],
        occurStartDateTime: payload?.occurStartDateTime ?? 0,
        occurEndDateTime: payload?.occurEndDateTime ?? 0,
        totPresQty: 0,
        totPresQtyUOM: "",
        totDispQty: 0,
        totDispQtyUOM: "",
        totBilledQty: 0,
        totBilledQtyUOM: "",
        totReturnedQty: 0,
        PRNYN: false,
        prnComment: "",
        direction: "",
        diagnosis: [],
        reasonCode: "",
        indication: payload?.indication?.value ?? "", //"CodingMaster/10794",
        patientInstruction: payload?.patientInstruction?.value ?? "", //"CodingMaster/10700"
        patientInstructiondesc: payload?.patientInstructiondesc ?? "",
        notes: payload?.notes ?? "",
        parentPresId: payload?.parentPresId ?? "",
        presGroupRefId: payload?.presGroupRefId ?? "",
        requestor: payload?.requestor ? getrequestor(payload?.requestor) : [],
        performer: payload?.performer ? getPerformer(payload?.performer) : [],
        noofRefillsAllowed: payload?.noofRefillsAllowed ?? 0,
        initialDispenseDurn: payload?.initialDispenseDurn ?? 0,
        refillDispenseIntervalDays: payload?.refillDispenseIntervalDays ?? 0,
        maxRefillDispenseDurn: payload?.maxRefillDispenseDurn ?? 0,
        pendingRefillCount: payload?.pendingRefillCount ?? "",
        prescriptionValidityPeriodDays:
          payload?.prescriptionValidityPeriodDays ?? 0,
        substitutionAllowed: payload?.substitutionAllowed ?? false,
        substitutionAllowedTypes: payload?.substitutionAllowedTypes ?? "",
        substituteReasonCode: payload?.substituteReasonCode ?? "",
        broughtFromHome: payload?.broughtFromHome ?? false,
        IVIngredientDescription: payload?.IVIngredientDescription ?? "",
        statushistory: [],
        statusperiod: payload?.statusperiod ?? 0,
        components: payload?.additiveDetails
          ? getComponent(payload?.additiveDetails)
          : [],
        // "FreqDetls": [
        //     {
        //         "remarks": "Morning",
        //         "dose": "",
        //         "time": "08:00",
        //         "baseFreq": "",
        //         "weekDay": "",
        //         "day": 0,
        //         "fromDay": 0,
        //         "toDay": 0,
        //         "fromDate": 0,
        //         "toDate": 0,
        //         "adminDosageInstruction": ""
        //     },
        //     {
        //         "remarks": "Noon",
        //         "dose": "",
        //         "time": "12:00",
        //         "baseFreq": "",
        //         "weekDay": "",
        //         "day": 0,
        //         "fromDay": 0,
        //         "toDay": 0,
        //         "fromDate": 0,
        //         "toDate": 0,
        //         "adminDosageInstruction": ""
        //     },
        //     {
        //         "remarks": "Night",
        //         "dose": "",
        //         "time": "20:00",
        //         "baseFreq": "",
        //         "weekDay": "",
        //         "day": 0,
        //         "fromDay": 0,
        //         "toDay": 0,
        //         "fromDate": 0,
        //         "toDate": 0,
        //         "adminDosageInstruction": ""
        //     }
        // ],
        FreqDetls: payload?.unitsTypes?.FreqDetls ?? [],
        nextrefilldate: 0,
        PH_OrderLineAlertLogs:getAlertPayload
      }
    }
  ];
};
const DRUGSTOREDISPLOC_MASTER = createAsyncThunk(
  "MedicationMastersSlice/DrugStoreDispLoc_masters",
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        {
          body: JSON.stringify(queries.DrugStoreDispLoc()),
        },
        urls.readDocuments
      );
      let arr = [];
      data.result.forEach((val) => {
        arr.push({
          value: val._id,
          label: val.DispLocCode ?? "",
          id: val.id,
        });
      });
      return {
        ...defaultState.List,
        data: arr,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const INFUSIONRATEUOM_MASTER = createAsyncThunk(
  "MedicationMastersSlice/InfusionRateUoM_masters",
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        queryid: "16ccea8f-08f6-4134-a5c9-c9ab3e5c04d3",
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let arr = [];
      data.map((val) => {
        arr.push({
          value: val._id,
          label: val.shortdesc ?? "",
          id: val.id,
        });
      });
      return {
        ...defaultState.List,
        data: arr,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const DRUGTYPE_MASTER = createAsyncThunk(
  `MedicationMastersSlice/drugType_masters`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        queryid: "36c55e4b-2052-4c7b-883c-d302abdcc414",
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let arr = [];
      data.map((val) => {
        arr.push({
          value: val._id,
          label: val.shortdesc ?? "",
          id: val.id,
        });
      });
      return {
        ...defaultState.List,
        data: arr,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const SINGLE_READ_EDIT_INFO = createAsyncThunk(
  `MedicationMastersSlice/single_read_edit_info`,
  async (payload = {}, { rejectWithValue }) => {
    const { key } = payload;
    try {
      const data = await fetchData(
        {
          body: JSON.stringify(queries.singleReadEditInfo(key)),
        },
        urls.readQdmQueries
      );
      console.log("singleread", data);
      return {
        ...defaultState.List,
        data: data || [],
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const DOSAGEVOLUMEUOM_MASTER = createAsyncThunk(
  "MedicationMastersSlice/DosageVolumeUom_masters",
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        queryid: "6c033b06-3407-4d12-af84-af6fb3ba4f2c",
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let arr = [];
      data.map((val) => {
        arr.push({
          value: val._id,
          label: val.shortdesc ?? "",
          id: val.id,
        });
      });
      return {
        ...defaultState.List,
        data: arr,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const DRUGROUTES_MASTER = createAsyncThunk(
  "MedicationMastersSlice/DrugRoutes_masters",
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        queryid: "b54da513-2e28-4da0-835b-37282ef4a855",
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let arr = [];
      data.map((val) => {
        arr.push({
          value: val._id,
          label: val.shortdesc ?? "",
          id: val.id,
        });
      });
      return {
        ...defaultState.List,
        data: arr,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const DRUGCATEGORY_MASTER = createAsyncThunk(
  "MedicationMastersSlice/DrugCategory_masters",
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        queryid: "6a8f4b6d-f8f6-4e42-b98b-08a9e5321612",
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let arr = [];
      data.map((val) => {
        arr.push({
          value: val._id,
          label: val.shortdesc ?? "",
          id: val.id,
        });
      });
      return {
        ...defaultState.List,
        data: arr,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const EditNewFrequency_orgbase_frequency_dropdown = createAsyncThunk(
  // "EditNewFrequency_orgbase_frequency_dropdownReducer/base_frequency_dropdown",
  "MedicationMastersSlice/base_frequency_dropdown",
  async (payload = {}, { rejectWithValue }) => {
    try {
      // let body = {};
      // body = JSON.stringify({
      //   db_name: dbName,
      //   entity: "FrequencyMaster",
      //   filter:
      //     "FrequencyMaster.IsBaseFreq==true && FrequencyMaster.status==true && FrequencyMaster.activestatus==true ",
      //   return_fields:
      //     "KEEP(FrequencyMaster,'_id','id','_key','freqcode','shortdesc','IsBaseFreq','status')",
      // });
      // let options = {
      //   method: "Post",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Accept: "application/json",
      //   },
      //   body: body,
      // };
      // let _res = await fetch(__readDocumentUrl__, options);
      const body = {
        db_name: dbName,
        queryid: "b4e53b34-4aea-424d-a8f2-f9cd2a4c74a6",
      };
      const _res = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      if (!_res.ok) {
        return rejectWithValue({
          ...defaultReject,
          message: _res?.statusText,
          Code: _res.status,
        });
      }
      let response = await _res.json();
      return { ...response, Code: _res.status };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const httpMessage = {
  OkMessage: "200 Successful",
  ErroMessage: "500 Internal server error",
};
const INFUSIONTIMEUOM_MASTER = createAsyncThunk(
  "MedicationMastersSlice/InfusionTimeUoM_masters",
  async (payload = {}, { rejectWithValue }) => {
    try {
      const data = await fetchData(
        {
          body: JSON.stringify(queries.InfusionTimeUoM()),
        },
        urls.readDocuments
      );
      let arr = [];
      data.result.map((val) => {
        arr.push({
          value: val._id,
          label: val.UOM ?? "",
          id: val.id,
        });
      });
      return {
        ...defaultState.List,
        data: arr,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const PATIENT_BANNER = createAsyncThunk(
  `pharmacyFrameworkApiSlice/patient_banner`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { id } = payload;
      const data = await fetchData(
        {
          body: JSON.stringify(queries.PharmacyFramework_Patientbanner(id)),
        },
        urls.readDocuments
      );
      return {
        ...defaultState.List,
        data: data.result,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const ENCOUNTER_BANNER = createAsyncThunk(
  `pharmacyFrameworkApiSlice/encounter_banner`,
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      const { id } = payload;
      let state = getState();
      //let loggedUserInfo = state?.signInReducerApiSlice?.loggedUserInfo?.data; state?.authSlice?.loggedUserInfo
      let loggedUserInfo = state?.authSlice?.loggedUserInfo?.data;
      const data = await fetchData(
        {
          body: JSON.stringify(
            queries.PharmacyFramework_Encounterbanner(id, loggedUserInfo)
          ),
        },
        urls.readDocuments
      );
      return {
        ...defaultState.List,
        data: data.result,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const medicationsSlice = createSlice({
  name: "medicationsSlice",
  initialState: {
    get_alert_codes: {
      ...defaultState.Info,
    },
    REASON_DROPDOWN_MASTER: {
      ...defaultState.Info,
    },
    reason_type_code_read: {
      ...defaultState.Info,
    },
    get_order_date: {
      ...defaultState.Info,
    },
    get_allergy_interaction: {
      ...defaultState.Info,
    },
    get_drug_duplication: {
      ...defaultState.Info,
    },
    medication_list: {
      ...defaultState.List,
    },
    alert_codes: {
      ...defaultState.List,
    },
    drug_codes: {
      ...defaultState.List,
    },
    get_ordered_status_id: {
      ...defaultState.List,
    },
    save_medication: {
      ...defaultState.Info,
    },
    DeleteMedication_masters: { ...defaultState.List },
    DrugStoreDispLoc_masters: { ...defaultState.List },
    InfusionRateUoM_masters: { ...defaultState.List },
    drugType_masters: { ...defaultState.List },
    single_read_edit_info: { ...defaultState.List },
    DosageVolumeUom_masters: { ...defaultState.List },
    DrugRoutes_masters: { ...defaultState.List },
    DrugCategory_masters: { ...defaultState.List },
    base_frequency_dropdown: { ...defaultState.Info },
    InfusionTimeUoM_masters: { ...defaultState.List },
    patientBanner: {
      ...defaultState.List,
    },
    Encounterbanner: {
      ...defaultState.List,
    },
  },
  extraReducers: {
    /* GET_ALERT_CODES */
    [GET_ALERT_CODES.fulfilled]: (state, action) => {
      state.get_alert_codes = action?.payload ?? [];
    },
    [GET_ALERT_CODES.pending]: (state, action) => {
      state.get_alert_codes.loading = true;
    },
    [GET_ALERT_CODES.rejected]: (state, action) => {
      state.get_alert_codes = action?.payload ?? [];
    },
    
    /* GET_DRUG_DUPLICATION */
    [GET_DRUG_DUPLICATION.fulfilled]: (state, action) => {
      state.get_drug_duplication = action?.payload ?? [];
    },
    [GET_DRUG_DUPLICATION.pending]: (state, action) => {
      state.get_drug_duplication.loading = true;
    },
    [GET_DRUG_DUPLICATION.rejected]: (state, action) => {
      state.get_drug_duplication = action?.payload ?? [];
    },
    
    /* REASON_DROPDOWN_MASTER */
    [REASON_DROPDOWN_MASTER.fulfilled]: (state, action) => {
      state.REASON_DROPDOWN_MASTER = action?.payload ?? [];
    },
    [REASON_DROPDOWN_MASTER.pending]: (state, action) => {
      state.REASON_DROPDOWN_MASTER.loading = true;
    },
    [REASON_DROPDOWN_MASTER.rejected]: (state, action) => {
      state.REASON_DROPDOWN_MASTER = action?.payload ?? [];
    },
    
    /* REASON_TYPE_CODE_READ */
    [REASON_TYPE_CODE_READ.fulfilled]: (state, action) => {
      state.reason_type_code_read = action?.payload ?? [];
    },
    [REASON_TYPE_CODE_READ.pending]: (state, action) => {
      state.reason_type_code_read.loading = true;
    },
    [REASON_TYPE_CODE_READ.rejected]: (state, action) => {
      state.reason_type_code_read = action?.payload ?? [];
    },
    
    /* GET_ORDER_DATE */
    [GET_ORDER_DATE.fulfilled]: (state, action) => {
      state.get_order_date = action?.payload ?? [];
    },
    [GET_ORDER_DATE.pending]: (state, action) => {
      state.get_order_date.loading = true;
    },
    [GET_ORDER_DATE.rejected]: (state, action) => {
      state.get_order_date = action?.payload ?? [];
    },
    
    /* GET_ALLERGY_INTERACTION */
    [GET_ALLERGY_INTERACTION.fulfilled]: (state, action) => {
      state.get_allergy_interaction = action?.payload ?? [];
    },
    [GET_ALLERGY_INTERACTION.pending]: (state, action) => {
      state.get_allergy_interaction.loading = true;
    },
    [GET_ALLERGY_INTERACTION.rejected]: (state, action) => {
      state.get_allergy_interaction = action?.payload ?? [];
    },

    /* GET_MEDICATIONS */
    [GET_MEDICATIONS.fulfilled]: (state, action) => {
      state.medication_list = action?.payload ?? [];
    },
    [GET_MEDICATIONS.pending]: (state, action) => {
      state.medication_list.loading = true;
    },
    [GET_MEDICATIONS.rejected]: (state, action) => {
      state.medication_list = action?.payload ?? [];
    },
    /* ALERT_CODES */
    [ALERT_CODES.fulfilled]: (state, action) => {
      state.alert_codes = action?.payload ?? [];
    },
    [ALERT_CODES.pending]: (state, action) => {
      state.alert_codes.loading = true;
    },
    [ALERT_CODES.rejected]: (state, action) => {
      state.alert_codes = action?.payload ?? [];
    },

    /* DRUG_CODES */
    [DRUG_CODES.fulfilled]: (state, action) => {
      state.drug_codes = action?.payload ?? [];
    },
    [DRUG_CODES.pending]: (state, action) => {
      state.drug_codes.loading = true;
    },
    [DRUG_CODES.rejected]: (state, action) => {
      state.drug_codes = action?.payload ?? [];
    },
    /* GET_ORDERED_STATUS_ID */
    [GET_ORDERED_STATUS_ID.fulfilled]: (state, action) => {
      state.get_ordered_status_id = action?.payload ?? [];
    },
    [GET_ORDERED_STATUS_ID.pending]: (state, action) => {
      state.get_ordered_status_id.loading = true;
    },
    [GET_ORDERED_STATUS_ID.rejected]: (state, action) => {
      state.get_ordered_status_id = action?.payload ?? [];
    },
    /* SAVE_MEDICATIONS */
    [SAVE_MEDICATIONS.fulfilled]: (state, action) => {
      state.save_medication = action?.payload ?? [];
    },
    [SAVE_MEDICATIONS.pending]: (state, action) => {
      state.save_medication.loading = true;
    },
    [SAVE_MEDICATIONS.rejected]: (state, action) => {
      state.save_medication = action?.payload ?? [];
    },

    /* DELETEMEDICATION_MASTER */
    [DELETEMEDICATION_MASTER.fulfilled]: (state, action) => {
      state.DeleteMedication_masters = action?.payload ?? [];
    },
    [DELETEMEDICATION_MASTER.pending]: (state, action) => {
      state.DeleteMedication_masters.loading = true;
    },
    [DELETEMEDICATION_MASTER.rejected]: (state, action) => {
      state.DeleteMedication_masters = action?.payload ?? [];
    },

    /* DRUGSTOREDISPLOC_MASTER */
    [DRUGSTOREDISPLOC_MASTER.fulfilled]: (state, action) => {
      state.DrugStoreDispLoc_masters = action?.payload ?? [];
    },
    [DRUGSTOREDISPLOC_MASTER.pending]: (state, action) => {
      state.DrugStoreDispLoc_masters.loading = true;
    },
    [DRUGSTOREDISPLOC_MASTER.rejected]: (state, action) => {
      state.DrugStoreDispLoc_masters = action?.payload ?? [];
    },

    /* INFUSIONRATEUOM_MASTER */
    [INFUSIONRATEUOM_MASTER.fulfilled]: (state, action) => {
      state.InfusionRateUoM_masters = action?.payload ?? [];
    },
    [INFUSIONRATEUOM_MASTER.pending]: (state, action) => {
      state.InfusionRateUoM_masters.loading = true;
    },
    [INFUSIONRATEUOM_MASTER.rejected]: (state, action) => {
      state.InfusionRateUoM_masters = action?.payload ?? [];
    },

    /* DRUGTYPE_MASTER */
    [DRUGTYPE_MASTER.fulfilled]: (state, action) => {
      state.drugType_masters = action?.payload ?? [];
    },
    [DRUGTYPE_MASTER.pending]: (state, action) => {
      state.drugType_masters.loading = true;
    },
    [DRUGTYPE_MASTER.rejected]: (state, action) => {
      state.drugType_masters = action?.payload ?? [];
    },

    /* SINGLE_READ_EDIT_INFO */
    [SINGLE_READ_EDIT_INFO.fulfilled]: (state, action) => {
      state.single_read_edit_info = action?.payload ?? [];
    },
    [SINGLE_READ_EDIT_INFO.pending]: (state, action) => {
      state.single_read_edit_info.loading = true;
    },
    [SINGLE_READ_EDIT_INFO.rejected]: (state, action) => {
      state.single_read_edit_info = action?.payload ?? [];
    },

    /* DOSAGEVOLUMEUOM_MASTER */
    [DOSAGEVOLUMEUOM_MASTER.fulfilled]: (state, action) => {
      state.DosageVolumeUom_masters = action?.payload ?? [];
    },
    [DOSAGEVOLUMEUOM_MASTER.pending]: (state, action) => {
      state.DosageVolumeUom_masters.loading = true;
    },
    [DOSAGEVOLUMEUOM_MASTER.rejected]: (state, action) => {
      state.DosageVolumeUom_masters = action?.payload ?? [];
    },

    /* DRUGROUTES_MASTER */
    [DRUGROUTES_MASTER.fulfilled]: (state, action) => {
      state.DrugRoutes_masters = action?.payload ?? [];
    },
    [DRUGROUTES_MASTER.pending]: (state, action) => {
      state.DrugRoutes_masters.loading = true;
    },
    [DRUGROUTES_MASTER.rejected]: (state, action) => {
      state.DrugRoutes_masters = action?.payload ?? [];
    },
    /* DRUGCATEGORY_MASTER */
    [DRUGCATEGORY_MASTER.fulfilled]: (state, action) => {
      state.DrugCategory_masters = action?.payload ?? [];
    },
    [DRUGCATEGORY_MASTER.pending]: (state, action) => {
      state.DrugCategory_masters.loading = true;
    },
    [DRUGCATEGORY_MASTER.rejected]: (state, action) => {
      state.DrugCategory_masters = action?.payload ?? [];
    },

    /* EditNewFrequency_orgbase_frequency_dropdown */
    [EditNewFrequency_orgbase_frequency_dropdown.fulfilled]: (
      state,
      action
    ) => {
      state.base_frequency_dropdown.loading = false;
      state.base_frequency_dropdown.data =
        action?.payload?.data ?? action?.payload;
      state.base_frequency_dropdown.error = false;
      state.base_frequency_dropdown.message = httpMessage.OkMessage;
    },
    [EditNewFrequency_orgbase_frequency_dropdown.rejected]: (state, action) => {
      state.base_frequency_dropdown = action?.payload ?? {};
    },
    [EditNewFrequency_orgbase_frequency_dropdown.pending]: (state, action) => {
      state.base_frequency_dropdown.loading = true;
    },

    /* INFUSIONTIMEUOM_MASTER */
    [INFUSIONTIMEUOM_MASTER.fulfilled]: (state, action) => {
      state.InfusionTimeUoM_masters = action?.payload ?? [];
    },
    [INFUSIONTIMEUOM_MASTER.pending]: (state, action) => {
      state.InfusionTimeUoM_masters.loading = true;
    },
    [INFUSIONTIMEUOM_MASTER.rejected]: (state, action) => {
      state.InfusionTimeUoM_masters = action?.payload ?? [];
    },
    /* PATIENT_BANNER */
    [PATIENT_BANNER.fulfilled]: (state, action) => {
      state.patientBanner = action?.payload ?? {};
    },
    [PATIENT_BANNER.pending]: (state, action) => {
      state.patientBanner.loading = true;
    },
    [PATIENT_BANNER.rejected]: (state, action) => {
      state.patientBanner = action?.payload ?? {};
    },
    /* PharmacyFramework_Encounterbanner */
    [ENCOUNTER_BANNER.fulfilled]: (state, action) => {
      state.Encounterbanner = action?.payload ?? {};
    },
    [ENCOUNTER_BANNER.pending]: (state, action) => {
      state.Encounterbanner.loading = true;
    },
    [ENCOUNTER_BANNER.rejected]: (state, action) => {
      state.Encounterbanner = action?.payload ?? {};
    },
  },
});

export const medicationsActions = {
  GET_ALERT_CODES,
  REASON_DROPDOWN_MASTER,
  REASON_TYPE_CODE_READ,
  GET_ORDER_DATE,
  GET_ALLERGY_INTERACTION,
  GET_DRUG_DUPLICATION,
  GET_MEDICATIONS,
  ALERT_CODES,
  DRUG_CODES,
  GET_ORDERED_STATUS_ID,
  SAVE_MEDICATIONS,
  DELETEMEDICATION_MASTER,
  DRUGSTOREDISPLOC_MASTER,
  INFUSIONRATEUOM_MASTER,
  DRUGTYPE_MASTER,
  SINGLE_READ_EDIT_INFO,
  DOSAGEVOLUMEUOM_MASTER,
  DRUGROUTES_MASTER,
  DRUGCATEGORY_MASTER,
  EditNewFrequency_orgbase_frequency_dropdown,
  INFUSIONTIMEUOM_MASTER,
  PATIENT_BANNER,
  ENCOUNTER_BANNER,
};

export default medicationsSlice;
