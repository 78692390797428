import React, { useState, useEffect } from "react";
import {
  Div,
  Card,
  Avatar,
  Text,
  H6,
  Image,
  Divider,
} from "qdm-component-library";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EditIcon from "@mui/icons-material/Edit";
import { Grid } from "@material-ui/core";
import moment from "moment";

const ServiceCard = (props) => {
  const editPatient = () => {
    props?.setshowcontents(false);
  };
  let date = new Date(props?.formData?.proposedDate);
  let dateMDY = `${String(date.getDate()).padStart(2, "0")}-${String(
    date.getMonth() + 1
  ).padStart(2, "0")}-${date.getFullYear()}`;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <Card
        style={{
          padding: "10px",
          width: "100%",
          boxShadow: "0px 10px 25px #0000000A",
        }}
      >
        <Grid container>
          <Grid item sm={6}>
            <Text style={{ display: "flex", alignItems: "center" }}>
              <H6
                className="pc_semibold"
                style={{ fontSize: 14, color: "#101010", margin: 0 }}
              >
                {props?.formData?.service?.display}
              </H6>
            </Text>
          </Grid>
          <Grid item sm={6} style={{ display: "flex", justifyContent: "end" }}>
            {!props?.readonly && (<Div onClick={editPatient}>
              <EditIcon />
            </Div>)}
          </Grid>
        </Grid>
        <Div style={{ display: "flex", flexDirection: "row" }}></Div>
        <Div style={{ display: "flex" }}>
          <AccessAlarmIcon style={{ fontSize: "medium", marginTop: "4px" }} />
          <Div style={{ marginLeft: "6px" }}>
            <Text
              className="pc_regular"
              style={{ fontSize: 15, color: "#6F6F6F", margin: 0 }}
            >
              {props?.formData?.duration}hr Duration
            </Text>
          </Div>
          <Div style={{ marginLeft: "10px", display: "flex" }}>
            <CalendarMonthIcon
              style={{ fontSize: "medium", marginTop: "4px" }}
            />
            <Div style={{ marginLeft: "6px" }}>
              <Text
                className="pc_regular"
                style={{ fontSize: 15, color: "#6F6F6F", margin: 0 }}
              >
                {dateMDY}
              </Text>
            </Div>
          </Div>
        </Div>
      </Card>
    </div>
  );
};

export default ServiceCard;
