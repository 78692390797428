import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import moment from "moment";
import { useDispatch } from "react-redux";
import CustomInputDatePicker from "../../../components/customDatePicker/inputDatePicker";
import actions from "../../../redux/actions";
import { SelectBox } from "../../../components";
import ToggleButtonsMultiple from "../../../components/toggleComponent";

const useStyles = makeStyles((theme) => ({
  root: {},
  label: {
    fontSize: "12px",
    color: "#6F6F6F",
    marginBottom: 8,
  },
  grid: {
    marginTop: 6,
    paddingLeft: 12,
    marginBottom: 6,
  },
  toggleGrid: {
    marginTop: 15,
  },
  timegrid: {
    padding: "0px !important",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    "& .MuiOutlinedInput-input": {
      padding: "10.5px 14px",
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogPaper:{
    "& ..MuiDialog-paper":{
      height: "70vh",
    }
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    minWidth: "600px",
    "& .MuiOutlinedInput-input": {
      padding: "10.5px 14px",
    },
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: "center",
  },
}))(MuiDialogActions);

function GenerateBlock(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const reasonOptionList = useSelector(
  //   (state) => state.appointmentApiSlice.reasonForApptCancelModify
  // );
  const [generatereason, setGeneratereason] = React.useState([])

  const handleClose = () => {
    props?.modalOpen("generate", "false");
  };

  const generateBlockFuunction = async () => {
    await props.generateBlockFunc();
    // props.modalOpen("generate", "false");
  };

  React.useEffect(() => {
    fetchInfo();
  }, []);

  const fetchInfo = async () => {
    let releaseReason = await dispatch(
      actions.REASON_FOR_APPT_CANCEL_MODIFY({ type: "REASONSCHBLK" })
    );
    setGeneratereason(releaseReason?.payload?.data);
  };
  const {
    handleFormState,
    startOn,
    endOn,
    day,
    startTime,
    endTime,
    partialEndDate,
    partialStartDate,
    generateMode,
    generate_Reason,
    parent_id,
    error
  } = props;
  return (
    <div id={`${parent_id}-parent-div`}>
      <Dialog
        id={`${parent_id}-parent-dialog`}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props?.generateOpen}
      >
        <DialogTitle
          id={`${parent_id}-title-dialogtitle`}
           onClose={handleClose}
        >
          Generate Blocks
        </DialogTitle>
        <DialogContent id={`${parent_id}-divider-dialogcontent`} dividers>
          <RadioGroup
            id={`${parent_id}-generateMode-radiogroup`}
            aria-label="gender"
            name="gender1"
            value={generateMode}
            onChange={(e) => handleFormState("generateMode", e.target.value)}
          >
            <FormControlLabel
              id={`${parent_id}-fullAbsence-formcontrolLabel`}
              style={{ width: "35%" }}
              value="fullAbsence"
              control={<Radio color="primary" />}
              label="Full Absence"
            />
            {generateMode === "fullAbsence" && (
              <Grid
                id={`${parent_id}-datetime-local-grid`}
                className={classes.grid}
                container
                spacing={2}
              >
                <Grid id={`${parent_id}-Starts-on-grid`} item xs={6}>
                  <Typography
                    id={`${parent_id}-Starts-on-title-typography`}
                    className={classes.label}
                  >
                    Starts on
                  </Typography>
                  {/* <TextField
                    id={`${parent_id}-Starts-on-datetime-textfield`}
                    variant="outlined"
                    type="datetime-local"
                    defaultValue={moment(new Date()).format("YYYY-MM-DDTHH:mm")}
                    value={startOn}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => handleFormState("startOn", e.target.value)}
                    error={error?.startOn}
                  /> */}
                  <CustomInputDatePicker
                    placeholderText="dd / mm / yyyy"
                    showTimeSelect
                    timeIntervals={15}
                    selectedDate={startOn ? new Date(startOn) : null}
                    handleChange={(value) => handleFormState("startOn", value)}
                    inputField={
                      <TextField
                        id={`${parent_id}-Starts-on-datetime-textfield`}
                        fullWidth
                        variant="outlined"
                        value={
                          startOn
                            ? moment(new Date(startOn)).format(
                              "DD-MM-YYYY h:mm a"
                            )
                            : null
                        }
                        inputProps={{
                          style: {
                            padding: "10.5px 14px",
                            fontFamily: "poppin",
                            fontSize: "12px",
                            // backgroundColor: data?.disabled ? "#E0E0E0" : "#fff",
                          },
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid id={`${parent_id}-datetime-Ends-on-grid`} item xs={6}>
                  <Typography
                    id={`${parent_id}-datetime-Ends-on-title-typography`}
                    className={classes.label}
                  >
                    Ends on
                  </Typography>
                  {/* <TextField
                    id={`${parent_id}-datetime-Ends-on-textfield`}
                    variant="outlined"
                    type="datetime-local"
                    value={endOn}
                    defaultValue={moment(new Date()).format("YYYY-MM-DDTHH:mm")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => handleFormState("endOn", e.target.value)}
                    error={error?.endOn}
                  /> */}
                  <CustomInputDatePicker
                    placeholderText="dd / mm / yyyy"
                    showTimeSelect
                    timeIntervals={15}
                    selectedDate={endOn ? new Date(endOn) : null}
                    handleChange={(value) => handleFormState("endOn", value)}
                    inputField={
                      <TextField
                        id={`${parent_id}-datetime-Ends-on-textfield`}
                        fullWidth
                        variant="outlined"
                        value={
                          endOn
                            ? moment(new Date(endOn)).format(
                              "DD-MM-YYYY h:mm a"
                            )
                            : null
                        }
                        inputProps={{
                          style: {
                            padding: "10.5px 14px",
                            fontFamily: "poppin",
                            fontSize: "12px",
                            // backgroundColor: data?.disabled ? "#E0E0E0" : "#fff",
                          },
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
            )}
            <FormControlLabel
              id={`${parent_id}-partialAbsence-formcontrolabel`}
              style={{ width: "50%" }}
              value="partialAbsence"
              control={<Radio color="primary" />}
              label="For Every (Partly Absent)"
            />
            {generateMode === "partialAbsence" && (
              <Grid
                id={`${parent_id}-partialAbsence-container-grid`}
                container
                spacing={2}
              >
                <Grid
                  id={`${parent_id}-partialAbsence-day-grid`}
                  className={classes.toggleGrid}
                  item
                  xs={6}
                >
                   <ToggleButtonsMultiple
                    id={`${parent_id}-partialAbsence-day-togglebuttonsmultiple`}
                    name="day"
                    values={day}
                    handleToggle={handleFormState}
                    data={[
                      { label: "S", value: "sun" },
                      { label: "M", value: "mon" },
                      { label: "T", value: "tue" },
                      { label: "W", value: "wed" },
                      { label: "T", value: "thu" },
                      { label: "F", value: "fri" },
                      { label: "S", value: "sat" },
                    ]}
                  /> 
                </Grid>
                <Grid
                  id={`${parent_id}-partialAbsence-day-grid`}
                  className={classes.timegrid}
                  item
                  xs={6}
                >
                  <Typography
                    id={`${parent_id}-partialAbsence-time-typography`}
                    className={classes.label}
                  >
                    Time
                  </Typography>
                  <div
                    id={`${parent_id}-startTime-div`}
                    style={{ display: "flex" }}
                  >
                    {/* <TextField
                      id={`${parent_id}-startTime-textfield`}
                      variant="outlined"
                      type="time"
                      value={startTime}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        handleFormState("startTime", e.target.value)
                      }
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      error={error?.startTime}
                    /> */}
                    <CustomInputDatePicker
                      placeholderText="dd / mm / yyyy"
                      showTimeSelect
                      showTimeSelectOnly
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      selectedDate={null}
                      handleChange={(value) =>
                        // alert(moment(value).format("hh: mm a"))
                        handleFormState("startTime", moment(value).format("HH: mm"))
                      }
                      inputField={
                        <TextField
                          id={`${parent_id}-startTime-textfield`}
                          fullWidth
                          variant="outlined"
                          value={
                            startTime
                          }
                          inputProps={{
                            style: {
                              padding: "10.5px 14px",
                              fontFamily: "poppin",
                              fontSize: "12px",
                              // backgroundColor: data?.disabled ? "#E0E0E0" : "#fff",
                            },
                          }}
                        />
                      }
                    />

                    <Typography
                      id={`${parent_id}-To-typography`}
                      style={{
                        marginTop: "10px",
                        paddingLeft: "2px",
                        paddingRight: "2px",
                      }}
                    >
                      To
                    </Typography>
                    {/* <TextField
                      id={`${parent_id}-endTime-textfield`}
                      variant="outlined"
                      type="time"
                      className={classes.textField}
                      value={endTime}
                      onChange={(e) =>
                        handleFormState("endTime", e.target.value)
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      error={error?.endTime}
                    /> */}

                    <CustomInputDatePicker
                      placeholderText="dd / mm / yyyy"
                      showTimeSelect
                      showTimeSelectOnly
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      selectedDate={null}
                      handleChange={(value) =>
                        // alert(moment(value).format("hh: mm a"))
                        handleFormState("endTime", moment(value).format("HH: mm"))
                      }
                      inputField={
                        <TextField
                          id={`${parent_id}-endTime-textfield`}
                          fullWidth
                          variant="outlined"
                          value={
                            endTime
                          }
                          inputProps={{
                            style: {
                              padding: "10.5px 14px",
                              fontFamily: "poppin",
                              fontSize: "12px",
                              // backgroundColor: data?.disabled ? "#E0E0E0" : "#fff",
                            },
                          }}
                        />
                      }
                    />
                  </div>
                </Grid>

                <Grid id={`${parent_id}-partialStartDate-grid`} item xs={5}>
                  <Typography
                    id={`${parent_id}-Starts-on-typography`}
                    className={classes.label}
                  >
                    Starts on
                  </Typography>
                  {/* <TextField
                    id={`${parent_id}-Starts-on-textfield`}
                    variant="outlined"
                    type="date"
                    defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                    value={partialStartDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) =>
                      handleFormState("partialStartDate", e.target.value)
                    }
                    error={error?.partialStartDate}
                  /> */}
                  <CustomInputDatePicker
                    placeholderText="dd / mm / yyyy"
                    // showTimeSelect
                    // timeIntervals={15}
                    selectedDate={
                      partialStartDate ? new Date(partialStartDate) : new Date()
                    }
                    handleChange={(value) =>
                      handleFormState("partialStartDate", value)
                    }
                    inputField={
                      <TextField
                        id={`${parent_id}-Starts-on-textfield`}
                        fullWidth
                        variant="outlined"
                        value={
                          partialStartDate
                            ? moment(new Date(partialStartDate)).format(
                              "DD-MM-YYYY"
                            )
                            : moment(new Date()).format("DD-MM-YYYY")
                        }
                        inputProps={{
                          style: {
                            padding: "10.5px 14px",
                            fontFamily: "poppin",
                            fontSize: "12px",
                            // backgroundColor: data?.disabled ? "#E0E0E0" : "#fff",
                          },
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid id={`${parent_id}-Ends-on-grid`} item xs={5}>
                  <Typography
                    id={`${parent_id}-Ends-on-typography`}
                    className={classes.label}
                  >
                    Ends on
                  </Typography>
                  {/* <TextField
                    id={`${parent_id}-Ends-on-textfield`}
                    variant="outlined"
                    type="date"
                    defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                    value={partialEndDate}
                    onChange={(e) =>
                      handleFormState("partialEndDate", e.target.value)
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={error?.partialEndDate}
                  /> */}
                  <CustomInputDatePicker
                    placeholderText="dd / mm / yyyy"
                    // showTimeSelect
                    // timeIntervals={15}
                    selectedDate={
                      partialEndDate ? new Date(partialEndDate) : new Date()
                    }
                    handleChange={(value) =>
                      handleFormState("partialEndDate", value)
                    }
                    inputField={
                      <TextField
                        id={`${parent_id}-Ends-on-textfield`}
                        fullWidth
                        variant="outlined"
                        value={
                          partialEndDate
                            ? moment(new Date(partialEndDate)).format(
                              "DD-MM-YYYY"
                            )
                            : moment(new Date()).format("DD-MM-YYYY")
                        }
                        inputProps={{
                          style: {
                            padding: "10.5px 14px",
                            fontFamily: "poppin",
                            fontSize: "12px",
                            // backgroundColor: data?.disabled ? "#E0E0E0" : "#fff",
                          },
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
            )}
          </RadioGroup>
          <SelectBox
            id={`${parent_id}-generate-Reason-selectbox`}
            list={ 
            [
              {
                  "Type": "REASONSCHBLK",
                  "_id": "CodingMaster/10849",
                  "_key": "10849",
                  "code": "CHBLOCK",
                  "label": "Emergency",
                  "id": 836
              }
          ]
          }
            
            top_title={"Reason"}
            placeholder={"Select"}
            requireStart
            value={generate_Reason}
            onchange={(e, value) => handleFormState("generate_Reason", value)}
            error={error?.generate_Reason}
          />
        </DialogContent>
        <DialogActions id={`${parent_id}-generate-Reason-dialogactions`}>
          <Button
            id={`${parent_id}-generate-button`}
            autoFocus
            variant="contained"
            onClick={() => generateBlockFuunction()}
            color="primary"
          >
            Generate
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default GenerateBlock;
