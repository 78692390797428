import React, { useContext, useEffect, useState } from "react";
import FinanceInputs from "./components/financeInputs";
import withAppBar from "../../hoc/withAppBar";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../router/routes";
import { TopNavbar, InfoIcon } from "../../components";
import jwtDecode from "jwt-decode";
import {
  AlertProps, getGlobalValueFrom_LclStrg_And_QryStrg,
  FnExecutorBasedOnFLow,
  getTenantId,
  readFileAsBase64,
} from "../../utils";
import dayjs from "dayjs";
import actions from "../../redux/actions";
import { AlertContext } from "../../context";
import { Grid, Paper, Skeleton, Typography } from "@mui/material";
import { PatientListView, PatientStatusCard } from "./components";
import moment from "moment";
import PayerDetailCard from "./components/payerDetailCard";
import { UIColor } from "../../themes/theme";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";

function FinanceRegistration(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const alert = useContext(AlertContext);
  const [Files, setFiles] = useState();
  const [viewFiles, setViewFiles] = useState();
  const [savedFinData, setSavedFinData] = useState([]);
  const [exceptSelfPayData, setExceptSelfPayData] = useState([]);
  const [selfPayData, setSelfPayData] = useState([]);
  const [generalSelfPay, setGeneralSelfPay] = useState({});
  const [personData, setPersonData] = useState({});
  const [editData, setEditData] = useState({});
  const [defData, setDefData] = useState({});
  const [allMasters, setAllMasters] = useState([]);

  const finLoading = useSelector(
    (state) => state?.FinanceSlice?.savefinance?.loading
  );
  const getPatinetLoading = useSelector(
    (state) => state?.patient?.PatientInfo?.loading
  );

  // const masterLoading = useSelector(
  //   (state) => state?.CommonSlice?.someMasters?.loading
  // );
  //const [getPatientRegistration, setGetPatientRegistration] = useState({});
  const EncounterDetails = location?.state?.encounterDetails?.response?.[0];
  const EncounterHeader = props?.encounterHeader?.data?.[0]; //FIXME -
  function syncWithSavedFinData() {
    let sp = [];
    let exsp = [];
    savedFinData.forEach((v) => {
      // //
      if (v?.is_deleted !== true) {
        // filtering Deleted
        if (process.env.REACT_APP_SELF_PAYER_TYPE === v?.payertype?._id) {
          // self pay
          sp.push({
            ...v,
            deleteIcon: true,
            editIcon: true,
            isDraggable: true,
          });
        } else {
          exsp.push({
            ...v,
            deleteIcon: true,
            editIcon: true,
            isDraggable: true,
          });
        }
      }
    });
    setExceptSelfPayData(exsp);
    setSelfPayData(sp);
  }
  const goBackFn = () => {
    if (location?.state?.isRevise) {
      navigate(AppRoutes.visitRegistration, {
        state: {
          personId: location?.state?.personId,
          from: 1,
          patientId: location?.state?.patientId,
          appointmentId: location?.state?.appointmentId,
          Ispatient: true,
          isedit: true, //action?.action == "VISIT REGISTER"? false : true,
          isRevise: true, //action?.action == "REVISE VISIT"? true : false,
          patientData: "", //mobileNo, allPatientDetails.patientdetails.telecom
          stateAction: "REVISE VISIT", //action?.action,
          stateEndPoint: "", //action?.apiendpoint,
          stateBackEndCall: "", //action?.backEndCall,
          stateTicketid: "",
        },
      });
    } else {
      navigate(
        location?.state?.appointmentId
          ? AppRoutes.visitRegistration
          : AppRoutes.patientRegistration,
        {
          state: {
            from: location?.state?.from,
            visit: true,
            nextRoute: AppRoutes.referralInRegistration,
            prevRoute: AppRoutes.visitRegistration,
            mobileNo: location?.state?.mobileNumber,
            mobileNumber: location?.state?.mobileNumber,
            appointmentId: location?.state?.appointmentId,
            isViewMode: location?.state?.IsViewMode,
            patientId: location?.state?.data?._id ?? location?.state?.patientId,
            data: location?.state?.data,
            //FIXME - || getPatientRegistration,
            patientdetails: location?.state?.patientdetails,
            addtionaldetails: location?.state?.addtionaldetails,
            nextofkin: location?.state?.nextofkin,
            totalData: location?.state?.totalData,
            isModify: location?.state?.isModify,
            backMode: location?.state?.backMode || "true",
            stateAction: location?.state?.stateAction,
            stateEndPoint: location?.state?.stateEndPoint,
            stateBackEndCall: location?.state?.stateBackEndCall,
            stateTicketid: location?.state?.stateTicketid,
            visitData: location?.state?.visitData,
            isedited: location?.state?.appointmentId ? true : false,
            isFinanceBack: location?.state?.appointmentId ? true : false,
          },
        }
      );
    }
  };
  const viewModeNavigateToReferal = (patientid) => {
    navigate(AppRoutes.referralInRegistration, {
      state: {
        IsViewMode: location?.state?.IsViewMode,
        isEditedMode: location?.state?.isEditedMode,
        patientId: location?.state?.data?._id ?? "",
        data: location?.state?.data,
        referraldetails: location?.state?.data?.referraldetails,
        totalData: location?.state?.totalData,
      },
    });
  };
  const Handlesave = (finData, editid, mode) => {
    let data = [...savedFinData];
    if (editid) {
      let index = data.findIndex((v) => v?.id === editid);
      finData.mode = mode;
      data[index] = Object.assign({}, data[index], finData);

      setSavedFinData(data);
      setEditData({});
      return;
    }
    setSavedFinData((prev) => [...prev, finData]);
  };

  const Tendigits = (isodate) => {
    return isodate ? Math.floor(dayjs(isodate).valueOf() / 1000) : 0;
  };
  const navigateToReferralInRegistration = async () => {
    if (location?.state?.isVisit) {
      navigate(AppRoutes.dashboard);
    } else {
      navigate(AppRoutes.referralInRegistration, {
        state: {
          from: location?.state?.from,
          visit: true,
          nextRoute: AppRoutes.referralInRegistration,
          prevRoute: AppRoutes.visitRegistration,
          mobileNo: location?.state?.mobileNumber,
          mobileNumber: location?.state?.mobileNumber,
          appointmentId: location?.state?.appointmentId,
          patientId: location.state?.patientId,
          //NOTE - ?? props?.patientId,
          referraldetails:
            location?.state?.referraldetails ||
            location?.state?.data?.referraldetails,
          //FIXME - || getReferralData,
          addtionaldetails: location?.state?.addtionaldetails,
          nextofkin: location?.state?.nextofkin,
          patientdetails: location?.state?.patientdetails,
          isModify: location?.state?.isModify ?? false,
          personid: location?.state?.personId || location?.state?.personid,
          userId: location?.state?.personId || location?.state?.personid,
          appointmentInfo: {},
          stateAction: location?.state?.stateAction,
          stateEndPoint: location?.state?.stateEndPoint,
          stateBackEndCall: location?.state?.stateBackEndCall,
          stateTicketid: location?.state?.stateTicketid,
          data: location?.state?.data,
          backMode: location?.state?.backMode,
          isEditedMode: location?.state?.isEditedMode,
        },
      });
    }
  };
  const PayloadGenerator = async (data) => {
    const userInfo = localStorage?.getItem("UserInfo");
    const decodedUserinfo = userInfo && jwtDecode(userInfo);
    let attachments = [];
    const convertedFiles = Files
      ? Files?.map((val) => readFileAsBase64(val))
      : [];
    const base64Results = await Promise.all(convertedFiles);
    Files?.forEach((val, i) => {
      let obj = {
        fileName: val?.name,
        fileid: "",
        filetype: val?.type,
        base64string: base64Results[i],
      };
      attachments.push(obj);
    });
    if (Array.isArray(viewFiles) && viewFiles?.length > 0) {
      attachments.concat(viewFiles);
    }

    let priorityFinData = [];
    let deletedFinData = savedFinData.filter((v) => !v?.isNew && v?.is_deleted);
    priorityFinData = [...exceptSelfPayData, ...selfPayData, ...deletedFinData];
    let codingPriority = {};
    allMasters?.PRIORITY?.forEach((v) => (codingPriority[v?.value] = v?._id)); // top priority   //less priority
    let doc = priorityFinData.map((v, i) => {
      return {
        patientaccount_id: v?.patientaccount_id || "",
        is_deleted: v?.is_deleted,
        mode: v?.mode,
        tenantid: decodedUserinfo?.tenantid,
        facilityid: decodedUserinfo?.facilityid,
        privilage: "yes",
        patientid: location.state?.patientId,
        priority: codingPriority[i + 1],
        trantype: location?.state?.appointmentId ? "encounter" : "patient",
        payertype: v?.payertype?._id || "",
        payer: v?.payer?._id || "",
        guarantortype: v?.guarantorType?._id || "",
        guarantorid: v?.guarantor?._id || "",
        policyid: v?.policyPlan?._id || "",
        policystart: Tendigits(v?.policyStart),
        policyexpiry: Tendigits(v?.policyEnd),
        sponsorname: v?.sponserName?._id || "",
        membershipid: v?.membershipId || "",
        membertype: v?.membershipType?._id || "",
        memberrelid: v?.memberRelationship?._id || "",
        primemembershipid: v?.primeMemberid || "",
        applencounter: "",
        applencountertype: "",
        applspecialty: "",
        financialclass: [
          {
            classtype: v?.finClassVal?._id || "",
            classval: v?.finClasstype?._id || "",
          },
        ],
        documentref: attachments, //FIXME -
        encounteraccount_id: "",
        encounterid: location?.state?.encounterDetails?.response?.[0]?._id,
        eligiblestatus: "",
        eligverifreqdyn: false, //FIXME -
        approvallimit: 0,
        copayperc: 0,
        copayamt: 0,
        glnumber: v?.glNumber || "",
        glissuedate: Tendigits(v?.glIssue),
        servicestart: Tendigits(v?.serviceStart),
        serviceend: Tendigits(v?.serviceEnd),
        status: "CodingMaster/10191", //FIXME -
        referenceid: [
          {
            refsource: "",
            refidtype: "",
            refid: "",
          },
        ],
      };
    });

    let payload = {
      dbName: process.env.REACT_APP_DB, //"primarycareng",
      metadatadbname: process.env.REACT_APP_METADATA_DBNAME,
      patientid: location.state?.patientId,
      doc: doc,
    };

    return payload;
  };
  function SaveFInSideEffects() {
    FnExecutorBasedOnFLow({
      AppoitmentFlowFn: () => {
        if(location?.state?.isVisit ||location?.state?.isRevise){
          navigate(AppRoutes.confirmBooking,{state:{visit:true,appointmentId: location?.state?.appointmentId,  
            stateAction: location?.state?.stateAction,
            stateEndPoint: location?.state?.stateEndPoint,
            stateBackEndCall: location?.state?.stateBackEndCall,
            stateTicketid:location?.state?.stateTicketid}})
      }
  
      else{
        navigate(AppRoutes.PatientDashBoard,{state:{patientId:location.state?.patientId}});
      }
      },
      DialysisFlowFn: () => {
        if (location?.state?.isRevise) {
          navigate(AppRoutes.dashboard);
        } else {
          navigateToReferralInRegistration();
        }
      },
    });
  }
    const handleBookyTpe = (_id,patientid) => {
       if(_id === process.env.REACT_APP_FREQUENCY_APPMNT_TYPE_OP_FLOW){
        navigate(AppRoutes.searchFacility, {
          state: {
                   patientDetails: location?.state?.data,
                   apptypecode : _id,
                   patientId:patientid,
                   _id:patientid,
                   isPerson:location?.state?.isPerson,
          },
        });
      }
    };
  const HandleFinRegister = async () => {
    try {
      let payload = await PayloadGenerator();
      let saveFin = await dispatch(actions.SAVE_FINANCE(payload));
      let saveFinance = saveFin?.payload?.data?.[0];

      //FIXME -
      if (saveFinance && saveFinance?.response && !saveFinance?.error) {
        if (!location.state?.isVisit)
          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg:
              location?.state?.backMode ||
                location?.state?.isEditedMode ||
                location?.state?.isRevise
                ? "Updated successfully"
                : "Added successfully",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
        SaveFInSideEffects();
        setSavedFinData([]);
        if (location?.state?.isPerson){
          handleBookyTpe(location?.state?.bookAppTypeId,saveFinance?.response?.[0]?._id)
        }
      } else {
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: saveFinance?.message || "something went wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      }
    } catch (error) { }
  };

  const NegativeCheck = (epoch,object) => {
    if (epoch > 0) {
      return epoch
    }
    else {
      console.error("screen default is giving negative value",epoch,object)
      return ""
    }
  }
  const getPatientInfo = async () => {
    let data = await dispatch(
      actions.PATIENT_INFO({
        patientid: location.state?.patientId,
        tenantid: "",
        facilityid: "",
        lang: "",
        encounterid: location.state?.isRevise ? EncounterDetails?._id : ""
      })
    );
    data = data?.payload?.data;
    let finSavedData = [];
    data?.financialdetails?.forEach((v) => {
      let obj = {
        patientaccount_id: v?._id,
        mode: "edit",
        is_deleted: false,
        id: uuidv4(),
        payertype: v?.payertype || null,
        payer: v?.payer || null,
        guarantorType: v.guarantortype || null,
        guarantor: v.guarantorid || null,
        policyPlan: v?.policyid || null,
        policyStart: v?.policystart ? dayjs.unix(v?.policystart) : "",
        policyEnd: v?.policyexpiry ? dayjs.unix(v?.policyexpiry) : "",
        membershipId: v?.membershipid || "",
        membershipType: v?.membertype
          ? { _id: v?.membertype?._id, value: v?.membertype?.display }
          : null,
        sponserName: v?.sponsorname
          ? { _id: v?.sponsorname?._id, name: v?.sponsorname?.name }
          : null,
        memberRelationship: v.memberrelid
          ? { _id: v.memberrelid?._id, value: v.memberrelid?.display }
          : null,
        primeMemberid: v?.primemembershipid || "",
        glNumber: v?.glnumber,
        glIssue: v?.policystart ? dayjs.unix(v?.policystart) : "",
        serviceStart: v?.policystart ? dayjs.unix(v?.policystart) : "",
        serviceEnd: v?.policystart ? dayjs.unix(v?.policystart) : "",
        finClasstype: v?.financialclass?.classtype
          ? {
            _id: v?.financialclass?.classtype?._id,
            value: v?.financialclass?.classtype?.display,
          }
          : null,
        finClassVal: v?.financialclass?.classtype
          ? {
            _id: v?.financialclass?.classval?._id,
            value: v?.financialclass?.classval?.display,
          }
          : null,
      };
      if (
        v?.payertype?._id === process.env.REACT_APP_SELF_PAYER_TYPE &&
        v?.policyid?._id === process.env.REACT_APP_SELF_PAYER_GENRERAL_PLAN
      ) {
        setGeneralSelfPay(obj);
      } else {
        finSavedData.push(obj);
      }
    });
    setSavedFinData(finSavedData);
    setPersonData(data?.patientdetails);
  };
  async function GetDefaultValue(params) {
    let payload = {
      screenid: process.env.REACT_APP_FINANCE_REGISTRATION_SCREEN_ID,
      tenantid:  getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"tenantid"}),
    };
    let defaultValue = await dispatch(
      actions.GET_SCREEN_DEFAULT_VALUE(payload)
    );

    if (
      defaultValue?.payload?.data?.response?.screenId ===
      process.env.REACT_APP_FINANCE_REGISTRATION_SCREEN_ID
    ) {
      defaultValue = defaultValue?.payload?.data?.response?.defaultValues;
      let obj = {};
      defaultValue.forEach((v) => {
        obj[v?.stateName] = v?.value;
      });
      let totalObj = {
        payertype: {
          _id: obj.payer_type?._id,
          longdesc: obj?.payer_type?.value,
        },
        policyStart: NegativeCheck(dayjs.unix(obj?.policy_edate),obj),
        policyEnd:NegativeCheck(dayjs.unix(obj?.policy_edate),obj),
        membershipType: obj?.membership_type,
      };

      setDefData(totalObj);
    }
  }
  async function getAllData() {
    const allMasters = await dispatch(
      actions.GET_SOME_MASTER({
        type: [
          "MEMBERTYPE",
          "PRIORITY",
          "RELATIONSHIP",
          "FINANCIALCLASSTYPE",
          "FINANCIALCLASS",
        ],
      })
    );
    setAllMasters(allMasters?.payload?.data);
  }
  useEffect(() => {
    getPatientInfo();
    GetDefaultValue();
    getAllData();
  }, []);
  useEffect(() => {
    if (savedFinData?.length > 0) {
      syncWithSavedFinData();
    }
    // finance screen is based on patient Id
    if (!location?.state?.patientId) {
      navigate(-1);
    }
  }, [savedFinData]);
  const handleDragEnd = (dragIndex, dragY) => {
    const destinationIndex = Math.round(dragY / 55);
    const newDestinationIndex = Math.min(
      Math.max(destinationIndex, 0),
      exceptSelfPayData.length - 1
    );
    if (newDestinationIndex !== dragIndex) {
      const newItems = [...exceptSelfPayData];
      const draggedItem = newItems.splice(dragIndex, 1)[0];
      newItems.splice(newDestinationIndex, 0, draggedItem);
      let getdragdata = [];
      newItems?.forEach((data, index) => {
        if (data?.is_deleted !== true) {
          getdragdata.push(data);
        }
      });
      setExceptSelfPayData(getdragdata);
    }
  };
  const handleDragEndForSelfpay = (dragIndex, dragY, isSelfPay) => {
    const destinationIndex = Math.round(dragY / 55);
    const newDestinationIndex = Math.min(
      Math.max(destinationIndex, 0),
      selfPayData.length - 1
    );
    if (newDestinationIndex !== dragIndex) {
      const newItems = [...selfPayData];
      const draggedItem = newItems.splice(dragIndex, 1)[0];
      newItems.splice(newDestinationIndex, 0, draggedItem);
      let getdragdata = [];
      newItems?.forEach((data, index) => {
        if (data?.is_deleted !== true) {
          getdragdata.push(data);
        }
      });
      setSelfPayData(getdragdata);
    }
  };

  const onEdit = (v, i) => {
    const isEditMode = location?.state?.isVisit || location?.state?.isRevise || location?.state?.isModify || location?.state?.isEditedMode || location?.state?.backMode ? true : false;
    const updatedV = { ...v, mode: isEditMode ? "edit" : v?.mode };
    setEditData(updatedV);
  };

  const onDelete = (delcard, i) => {
    let data = [...savedFinData];

    let index = data.findIndex((v) => v?.id === delcard?.id);
    data[index]["is_deleted"] = true;
    data[index]["mode"] = "delete";

    setSavedFinData(data);
    alert.setSnack({
      open: true,
      severity: AlertProps.severity.success,
      msg: "payer deleted added !",
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.right,
    });
  };

  return (
    <div
      id="finance_registration_parent_div"
      className="light-background-color"
    >
      <TopNavbar
        goBack={goBackFn}
        parent_id={"Financial_Registration"}
        newUser={location?.state?.newUser}
        successfun={HandleFinRegister}
        title={"Financial Registration"}
        btn={
          location?.state?.IsViewMode
            ? "Continue"
            : finLoading
              ? "Registering"
              : "Register & continue"
        }
        successBtnLoading={finLoading}
        parent_View_id={location?.state?.IsViewMode}
        isViewMode={location?.state?.IsViewMode}
        navigateToNextScreen={viewModeNavigateToReferal}
        setFiles={setFiles}
        setviewFiles={setViewFiles}
        initialFiles={viewFiles}
        isBackMode={
          location?.state?.isModify ||
          location?.state?.backMode ||
          location?.state?.isEditedMode
        }
      />
      <div
        style={{
          height: "calc(100vh - 128px)",
          overflow: "auto",
          display: personData?.loading ? "flex" : "block",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          id="financial_parent_sub_div"
          style={{
            margin: "4px 15px",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          {" "}
          {getPatinetLoading ? (
            <Skeleton
              variant="rectangular"
              animation="wave"
              // width={}
              height={65}
              sx={{ borderRadius: "8px" }}
            />
          ) : EncounterDetails?._id ? (
            <Grid container className="pv-root">
              <Grid
                item
                id="financial_patientlist_col"
                lg={6}
                md={6}
                inLineStyles={{ padding: 5 }}
              >
                <PatientStatusCard
                  parent_id={"Financial_Registration"}
                  EncounterDetails={EncounterDetails}
                  pic={
                    "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
                  }
                  // titles={[
                  //   `${EncounterHeader?._id ?? ""}`,
                  //   `${EncounterHeader?.type?.[0] ?? ""}`,
                  //   `${EncounterHeader?.visit_type ?? ""}`,
                  //   `${practitioner?.speciality?.value ?? ""}`,
                  // ]}
                  titles={[
                    `${EncounterDetails?.encounter_no ?? ""}`,
                    `${EncounterDetails?.typevalue ?? ""}`,
                    `${EncounterDetails?.visit_typevalue ?? ""}`,
                    `${EncounterDetails?.participants?.[0]?.specialitydisplay ??
                    ""
                    }`,
                  ]}
                  date={moment
                    .unix(EncounterDetails?.encounterdate)
                    .format("MMMM Do YYYY, h:mm a")}
                  languages={EncounterHeader}
                  padding
                  IsViewMode={location?.state?.IsViewMode}
                />
              </Grid>
              <Grid
                item
                id="financial_patientstatus_col"
                lg={6}
                md={6}
                inLineStyles={{ padding: 5 }}
              >
                <PatientListView
                  parent_id={"Financial_Registration"}
                  noright={true}
                  // Persondata={state.Persondata?.[0]}
                  Persondata={personData}
                  // patientId={state.patientId}
                  patientId={personData?.identifier?.[0]?.value}
                  IsViewMode={location?.state?.IsViewMode}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              {" "}
              <Grid
                item
                id="financial_patientist_col_one"
                lg={12}
                md={12}
                inLineStyles={{ padding: 5 }}
              >
                <PatientListView
                  parent_id={"Financial_Registration"}
                  noright={true}
                  // Persondata={state.Persondata?.[0]}
                  Persondata={personData}
                  // patientId={state.patientId}
                  patientId={personData?.identifier?.[0]?.value}
                  IsViewMode={location?.state?.IsViewMode}
                />
              </Grid>
            </Grid>
          )}
        </div>
        {exceptSelfPayData?.length > 0 && (
          <Grid
            className="pv-root"
            style={{
              margin: "3px 15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Paper id="financial_applicable_paper" className="pl-root">
              <Typography
                id="financial_applicable_label_h6"
                className="en-title space-padding-bottom"
              >
                APPLICABLE CRITERIA
              </Typography>
              <Grid
                container
                style={{ alignItems: "center", justifyContent: "flex-start" }}
              >
                <InfoIcon
                  width={12}
                  style={{ marginRight: 6 }}
                  color={UIColor.primaryColor.color}
                />
                <span
                  style={{ fontSize: "12px" }}
                  color={UIColor.primaryText.color}
                >
                  Use drag and drop to change the priority
                </span>
              </Grid>

              {exceptSelfPayData?.map((v, i) => (
                <PayerDetailCard
                  key={v?.id} // You might need to set a unique key for each card
                  parent_id={"financial_showlist"}
                  onedit={() => onEdit(v, i)}
                  ondelete={() => onDelete(v, i)}
                  isDelete={v?.deleteIcon}
                  isEdit={v?.editIcon}
                  data={v}
                  index={i}
                  //FIXME -  cleared={cleared}
                  IsViewMode={location?.state?.IsViewMode}
                  keys={v}
                  values={v}
                  priority={i + 1}
                  handleDragEnd={handleDragEnd}
                //FIXME - newpriority={i+ parseInt(v?.payer?.priority?.value)}
                />
              ))}
            </Paper>
          </Grid>
        )}
        {selfPayData?.length > 0 && (
          <Grid
            className="pv-root"
            style={{
              margin: "3px 15px",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <Paper
              id="financial_applicable_paper"
              className="pl-root"
              sx={{ display: "flex", flexDirection: "column", gap: "12px" }}
            >
              {selfPayData?.map((v, i) => (
                <PayerDetailCard
                  key={v?.id} // You might need to set a unique key for each card
                  parent_id={"financial_showlist"}
                  onedit={() => onEdit(v, i)}
                  ondelete={() => onDelete(v, i)}
                  isDelete={v?.deleteIcon}
                  isEdit={v?.editIcon}
                  data={v}
                  index={i}
                  //FIXME -  cleared={cleared}
                  IsViewMode={location?.state?.IsViewMode}
                  keys={v}
                  values={v}
                  priority={i + exceptSelfPayData?.length + 1}
                  handleDragEnd={handleDragEndForSelfpay}
                //istitle={true}
                // newpriority={i+ exceptSelfPayData?.length+1}
                />
              ))}
            </Paper>
          </Grid>
        )}
        <Grid
          className="pv-root"
          style={{
            margin: "3px 15px",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          {getPatinetLoading ? (
            <Skeleton
              variant="rectangular"
              animation="wave"
              // width={}
              height={55}
              sx={{ borderRadius: "8px" }}
            />
          ) : (
            <Paper id="financial_applicable_paper" className="pl-root">
              <PayerDetailCard
                key={`${1}`} // You might need to set a unique key for each card
                parent_id={"financial_showlist"}
                isDelete={false}
                isEdit={false}
                data={generalSelfPay}
                // cleared={cleared}
                IsViewMode={location?.state?.IsViewMode}
                keys={generalSelfPay}
                values={generalSelfPay}
                istitle={true}
                priority={exceptSelfPayData?.length + selfPayData?.length + 1}
              />
            </Paper>
          )}
        </Grid>
        <Grid
          className="pv-root"
          style={{
            margin: "8px 20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Paper id="financial_applicable_paper" className="pl-root">
            <FinanceInputs
              Handlesave={Handlesave}
              editData={editData}
              defData={defData}
              allMasters={allMasters}
              TotalPay={[...selfPayData, ...exceptSelfPayData, generalSelfPay]} // generalSelfPay is obj
            />
          </Paper>
        </Grid>
      </div>
    </div>
  );
}

export default withAppBar(FinanceRegistration);
