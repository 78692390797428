import { Button, Collapse, Divider, Grid, Paper, Tooltip, Typography, useTheme } from '@mui/material'
import React from 'react'
import AddIcon from '../../../assets/svgComponents/addIcon'
import ShareIcon from '@mui/icons-material/Share';
import ArrowIcon from '../../../assets/svgComponents/arrow';

const EducationDescription = ({ data }) => {
    const theme = useTheme();
    const [expand, setExpand] = React.useState(false);
    return (
        <>
            <Grid container xs={12}>
                {
                    data && data?.lifeStyleAdvices ? (
                        <Grid container xs={12} gap={2}
                            sx={{
                                border: `2px solid ${theme?.palette?.lineBorderFill}`,
                                // margin: "2px",
                                borderRadius: 1,
                                backgroundColor: "#fff"
                            }}
                        >
                            <Grid container justifyContent={"space-between"} alignItems={"center"} pt={2} px={2} onClick={() => { setExpand(!expand) }}>
                                <Grid item>
                                    <Typography
                                        variant="head1"
                                        color={theme?.palette?.primaryText}
                                        sx={{ paddingX: 1, paddingTop: 2 }}
                                        fontSize={"20px"}
                                    >
                                        Preferred Education and Lifestyle Advice
                                    </Typography>
                                </Grid>

                                <Grid item>
                                    <Grid container gap={2} alignItems={'center'}>
                                        <Tooltip title="Share with Patient" arrow   placement='top'>
                                            <ShareIcon color={theme?.palette?.secondaryButtonColor} fontSize='small' />
                                        </Tooltip>
                                        <ArrowIcon direction={expand ? "down" : "right"} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Collapse in={expand}>
                                    <Divider />
                                    <Grid container p={2}>
                                        {data?.lifeStyleAdvices?.map((d) => {
                                            return (
                                                <Grid item xs={6} px={2} py={1}>
                                                    <Typography variant="head2" color={theme?.palette?.secondaryButtonColor} fontWeight={600}> {d.adviceName} </Typography>
                                                    <Grid container>
                                                        <Grid container p={1} >
                                                                                {d.evidence ? (
                                                                                    <Grid container  >
                                                                                        <Grid item container p={0.5}>
                                                                                            <Typography variant="head2" color={theme?.palette?.tertieryText} fontWeight={600}>🔍 Evidence:</Typography>
                                                                                        </Grid>
                                                                                        <Grid item pl={2}>
                                                                                            <Typography variant="head2" color={theme?.palette?.tertieryText} > {d.evidence}</Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                ) : (<> </>)}
                                                                                {d.reference ? (
                                                                                    <Grid container pt={2} >
                                                                                        <Grid item container p={0.5}>
                                                                                            <Typography variant="head2"color={theme?.palette?.tertieryText} fontWeight={600}>📒 Reference:</Typography>
                                                                                        </Grid>
                                                                                        <Grid item pl={2}>
                                                                                            <Typography variant="head2" color={theme?.palette?.tertieryText} > {d.reference}</Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                ) : (<> </>)}
                                                                            </Grid>
                                                    </Grid>
                                                </Grid>

                                            )
                                        })}
                                    </Grid>
                                </Collapse>

                            </Grid>

                        </Grid>
                    ) : (<></>)}

            </Grid>
        </>
    )
}

export default EducationDescription