import { Collapse, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import ScoreCard from "./scoreCard";
import TrendIcon from "../../../assets/svgComponents/trendIcon";
import DotIcon from "../../../assets/svgComponents/dotIcon";
import SummaryListIcon from "../../../assets/svgComponents/summaryListIcon";
import TickIcon from "../../../assets/svgComponents/tickIcon";
import SquareTrendIcon from "../../../assets/svgComponents/squareTrendIcon";
import { HeartPluseIcon } from "../../../assets/svgComponents/heartPlusIcon";
import CautionIcon from "../../../assets/svgComponents/cautionIcon";
import RecommendationIcon from "../../../assets/svgComponents/recommendationIcon";
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ArrowIcon from "../../../assets/svgComponents/arrow";
import PathwayIcon from "../../../assets/svgComponents/pathwayIcon";
import YouTubeIcon from '@mui/icons-material/YouTube';
const Summary = ({ data }) => {
  const theme = useTheme();
  const [expand,setExpand]=React.useState(true)
  const AfKeyFactors = ({ AfKeyFactor }) => {
    return (
      <Grid container gap={1} p={1}>
        <Grid item>
          <Typography
            variant="head2"
            color={theme?.palette?.primaryText}
            fontWeight={600}
          >
            Evidence
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="content1"
            color={theme?.palette?.tertieryText}
            marginLeft={1}
          >
            {AfKeyFactor?.evidence}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="head2"
            color={theme?.palette?.primaryText}
            fontWeight={600}
          >
            Reference
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="content1"
            color={theme?.palette?.tertieryText}
            marginLeft={1}
          >
            {AfKeyFactor?.reference}
          </Typography>
        </Grid>
      </Grid>
    );
  }
  const KeyFactors = ({ KeyFactor }) => {
    return (
      <Grid container gap={1} p={1} >
        <Grid item>
          <Typography
            variant="head2"
            color={theme?.palette?.primaryText}
            fontWeight={600}
          >
            Key Factors
          </Typography>
        </Grid>
        {
          KeyFactor?.map((factor) => (
            <Grid item container gap={1} alignItems={"center"}>
              <Typography
                variant="content1"
                color={theme?.palette?.tertieryText}
                noWrap
                textOverflow={"ellipsis"}
                whiteSpace={"nowrap"}
                width={"250px"}
              >
                <TickIcon />   {`${factor?.factor} (${factor?.points})`}
              </Typography>
            </Grid>
          ))
        }

      </Grid>
    );
  }
  const scoreCardData = [
    {
      scoreName: "AF Stage",
      score: data?.afStage?.stage,
      keyFactors: <AfKeyFactors AfKeyFactor={data?.afStage} />,
      icon: <FavoriteBorderOutlinedIcon color={'success'}  />,
      xs: 2.8, 
     bgColor: 'linear-gradient(to bottom,rgba(210, 255, 200, 0.2), rgba(255, 255, 255, 0.02))'
    },
    {
      scoreName: "CHA₂DS₂-VA",
      score: data?.cha2ds2VaScore?.score,
      keyFactors: <KeyFactors KeyFactor={data?.cha2ds2VaScore?.keyContributingFactors} />,
      icon: <SquareTrendIcon color="#DECC00"/>,
      xs: 2.8,
       bgColor:'linear-gradient(to bottom, rgba(255, 254, 200, 0.2), rgba(255, 255, 255, 0.02))'
    },
    {
      scoreName: "CHARGE-AF",
      score: data?.chargeAfScore?.score,
      keyFactors: <KeyFactors KeyFactor={data?.chargeAfScore?.keyContributingFactors} />,
      icon: <SquareTrendIcon color={'red'} />,
      xs: 2.8,
       bgColor:'linear-gradient(to bottom, rgba(255, 216, 200, 0.2), rgba(255, 255, 255, 0.02))'
    },
    {
      scoreName: "C₂HEST",
      score: data?.c2hestScore?.score,
      keyFactors: <KeyFactors KeyFactor={data?.c2hestScore?.keyContributingFactors} />,
      icon: <SquareTrendIcon color="#A200FF"/>,
      xs: 2.8,
       bgColor:'linear-gradient(to bottom, rgba(255, 200, 246, 0.2), rgba(255, 255, 255, 0.02))'
    },
    
  ];

  return (
 
      <Grid
        container
        sx={{
          border: `2px solid ${theme?.palette?.lineBorderFill}`,
          margin: "2px",
          borderRadius: 1,
          backgroundColor: "#fff"
        }}
      >
       <Grid container justifyContent={"space-between"} alignItems={"center"} p={2}  onClick={()=>{setExpand(!expand)}}>
          <Grid item>
              <Typography
                    variant="head1"
                    color={theme?.palette?.primaryText}
            sx={{ paddingX: 1, paddingTop: 2 }}
            fontSize={"20px"}
                  >
                      Summary
              </Typography>
          </Grid>
        <Grid item >
          <Grid container gap={2}>
              <YouTubeIcon sx={{color:theme?.palette?.Error}} />
              <PathwayIcon height={20} width={20} color={theme?.palette?.secondaryColor} />
            <ArrowIcon direction={expand ? "down" : "right"} />
            </Grid>
          </Grid>
      </Grid>
      <Collapse in={expand} >
        <Grid container xs={12}>
          <Grid item xs={12}>
            <Grid container xs={12} gap={2} m={2}>
              {scoreCardData.map((card) => (
                <Grid item xs={card.xs} key={card.scoreName}> 
                  <ScoreCard 
                    scoreName={card.scoreName} 
                    score={card.score} 
                    keyFactors={card.keyFactors} 
                    icon={card.icon} 
                    bgColor={card.bgColor}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12} py={0}  px={2}>
                {data?.Summary_and_Clinical_Considerations && (
                <>
                    <Grid container border={`1px solid ${theme?.palette?.Success}`} borderRadius={1}>
                      <Grid container gap={2}>
                      <Grid item bgcolor={"#E0FFD8"} px={3} py={0.5} borderRadius={"3px 0 3px 0"}>
                      <Typography variant="head2" fontWeight={600} color={theme?.palette?.Success}>
                            Summary & Clinical Considerations
                          </Typography>
                        </Grid>
                  </Grid>
                    {data?.Summary_and_Clinical_Considerations?.map((summary) => (
                          <Grid container ml={2} py={1}>
                            <Grid item xs={12}  mb={1}>
                              <Typography
                                variant="head2"
                                color={theme?.palette?.secondaryText}
                                marginLeft={1}
                              >
                                {summary?.summary}
                              </Typography>
                            </Grid>
                            {summary?.clinicalConsiderations?.map((detail) => {
                              return (
                                <Grid container xs={6}>
                                  <Grid item ml={2} >
                                    <DotIcon height={6} width={6}/>
                                    <Typography
                                      variant="content1"
                                      color={theme?.palette?.tertieryText}
                                      marginLeft={1}
                                    >
                                      {detail}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              )
                            })}
                          </Grid>
                      
                      ))}
              
                    </Grid>
                  </>
                )}
            </Grid>
            <Grid container>
              <Grid item xs={9} p={2}>
                  {data?.recommendations && (
                    <Grid container border={`1px solid ${theme?.palette?.secondaryButtonColor}`} borderRadius={1}>
                      <Grid container gap={2}>
                        {/* <Grid item>
                          <RecommendationIcon height={20} width={20} color={theme?.palette?.Success} />
                        </Grid> */}
                      <Grid item bgcolor={"#E2F0FF"} px={3} py={0.5} borderRadius={"3px 0 3px 0"}>
                      <Typography variant="head2" fontWeight={600} color={theme?.palette?.secondaryButtonColor}>
                            Recommendations
                          </Typography>
                        </Grid>
                  </Grid>
                  <Grid container xs={12} >
                      {data?.recommendations?.map((recommendation) => {
                        return (
                          <Grid container xs={6} pb={2} >
                            <Grid container ml={4} pt={1}>
                              <Grid item>
                                <Typography
                                  variant="head2"
                                  color={theme?.palette?.secondaryButtonColor}
                                  marginLeft={1}
                                >
                                  {recommendation?.section}
                                </Typography>
                              </Grid>
                              {recommendation?.items?.map((detail) => {
                                return (
                                  <Grid container>
                                    <Grid item ml={4}>
                                      <DotIcon height={6} width={6} />
                                      <Typography
                                        variant="content1"
                                        color={theme?.palette?.tertieryText}
                                        marginLeft={1}
                                      >
                                        {detail}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                )
                              })}
                            </Grid>
                          </Grid>
                        );
                      })}
                  </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={3} p={2}>
                {data?.missingData && (
                        <Grid container border={`1px solid ${theme?.palette?.secondaryColor}`} borderRadius={1}>
                          <Grid container gap={2}>
                            {/* <Grid item>
                              <CautionIcon height={20} width={20} color={theme?.palette?.secondaryColor} />
                            </Grid> */}
                            <Grid item bgcolor={"#FEF1ED"} px={3} py={0.5} borderRadius={"3px 0 3px 0"}>
                              <Typography variant="head2" fontWeight={600} color={theme?.palette?.secondaryColor}>
                                Missing Data
                              </Typography>
                            </Grid>
                      </Grid>
                      <Grid container py={1}>
                          {data?.missingData?.map((data) => {
                            return (
                                <Grid container ml={3} pt={1}>
                                  <Grid item>
                                    <DotIcon color={theme?.palette?.Error} />
                                    <Typography
                                      variant="content1"
                                      color={theme?.palette?.tertieryText}
                                      marginLeft={1}
                                    >
                                      {data?.data}
                                    </Typography>
                                  </Grid>
                                </Grid>
                            );
                          })}
                      </Grid>
                        </Grid>
                  )}
              </Grid>

            </Grid>
          </Grid>
          
        </Grid>
      </Collapse>
  
      </Grid>

  );
};

export default Summary;
