import * as React from "react";

function LikeIcon({ height, width, color, direction = "up", ...props }) {
  // Determine the rotation based on the direction
  const rotation = direction === "down" ? "rotate(180deg)" : "rotate(0deg)";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 24}
      height={height ? height : 24}
      viewBox="0 0 24 24"
      fill="none"
      style={{ transform: rotation, transition: "transform 0.3s" }} // Add smooth transition
      {...props}
    >
      <path
        d="M3 10.234l.747-.064a.75.75 0 00-1.497.064H3zm17.236 1.823l-.706 4.08 1.479.256.705-4.08-1.478-.256zm-6.991 9.193H8.596v1.5h4.649v-1.5zm-5.56-.837l-.812-9.393-1.495.129.813 9.393 1.494-.13zm11.845-4.276c-.507 2.93-3.149 5.113-6.285 5.113v1.5c3.826 0 7.126-2.67 7.764-6.357l-1.479-.256zM13.255 5.1l-.663 4.045 1.48.242.663-4.044-1.48-.243zm-6.067 5.146l1.439-1.24-.98-1.136L6.21 9.11l.978 1.136zm4.056-5.274l.476-1.834-1.452-.376-.476 1.833 1.452.377zm1.194-2.194l.145.047.459-1.428-.145-.047-.459 1.428zm-1.915 4.038a8.4 8.4 0 00.721-1.844l-1.452-.377A7 7 0 019.2 6.11l1.323.706zm2.06-3.991a.89.89 0 01.596.61l1.452-.376a2.38 2.38 0 00-1.589-1.662l-.459 1.428zm-.863.313a.52.52 0 01.28-.33l-.651-1.351c-.532.256-.932.73-1.081 1.305l1.452.376zm.28-.33a.6.6 0 01.438-.03l.459-1.428a2.1 2.1 0 00-1.548.107L12 2.807zm2.154 8.176h5.18v-1.5h-5.18v1.5zM4.719 21.406L3.747 10.17l-1.494.129.97 11.236 1.496-.13zm-.969.107v-11.28h-1.5v11.28h1.5zm-.527.022a.264.264 0 01.07-.2.264.264 0 01.194-.085v1.5c.726 0 1.294-.622 1.232-1.344l-1.496.129zM14.735 5.343a5.5 5.5 0 00-.104-2.284l-1.452.377a4 4 0 01.076 1.664l1.48.243zM8.596 21.25a.916.916 0 01-.911-.837l-1.494.129a2.416 2.416 0 002.405 2.208v-1.5zm.031-12.244c.68-.586 1.412-1.283 1.897-2.19L9.2 6.11c-.346.649-.897 1.196-1.553 1.76l.98 1.136zm13.087 3.307a2.417 2.417 0 00-2.38-2.83v1.5c.567 0 1 .513.902 1.074l1.478.256zM3.487 21.25c.146 0 .263.118.263.263h-1.5c0 .682.553 1.237 1.237 1.237v-1.5zm9.105-12.105a1.583 1.583 0 001.562 1.839v-1.5a.082.082 0 01-.035-.007.082.082 0 01-.047-.09l-1.48-.242zM6.873 11.02a.92.92 0 01.315-.774l-.98-1.137a2.42 2.42 0 00-.83 2.04l1.495-.13z"
        fill={color ? color : "#6A7888"}
      />
    </svg>
  );
}

export default LikeIcon;
