import React, { useEffect } from 'react'
import JsonModal from './jsonModal'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material'
// import CopyToClipboard from 'react-copy-to-clipboard'
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ChatBot from './chatBot';
import CancelIcon from "@mui/icons-material/Cancel";
import Emr from './CpgEmr';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import Assistant from '../aiAssistant/components/AiAssistant/Assistant';
import { ThemeProvider } from "@mui/material/styles";
import theme from '../aiAssistant/theme';
import { query_ids, urls } from '../../../utils';
const CpgPackage = (props) => {
  const [openCpg, setOpenCpg] = React.useState(false);

  // Show JSON Modal
  const userInfo = localStorage?.getItem("UserInfo");
  const jwtToken = localStorage?.getItem("JwtToken");
  const decodedUserinfo =
    userInfo === null || userInfo === undefined ? "" : jwtDecode(userInfo);

  const practitionerid = decodedUserinfo?.practitionerid ?? "";
  const roleid = decodedUserinfo?.roleid ?? "";

  const [jsonResponse, setJsonResponse] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [isSeries,setisSeries] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingData, setLoadingData] = React.useState([]);

  // Get Input JSON

  const [openJsonModal, setOpenJsonModal] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("Test");
  const [simulationData, setSimulationData] = React.useState("");
  const options = ["Test", "Simulation", "Production"];

  const handleClickOpen = () => {
    setOpenJsonModal(true);
  };

  const handleCloseJsonModal = () => {
    setOpenJsonModal(false);
  };
  const getInputData = async() => { 
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "db_name": process.env.REACT_APP_DB,
      "filter": {
        "encounterid": props?.encounterid,
        "patientid":  props?.patientId,
        "practitionerid": "",
        "practroleid": ""
      },
      "queryid": query_ids?.inputDataForAi
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    try {
      const response = await fetch(urls?.readQdmQueries, requestOptions); 
      const result = await response.json(); 
      return result?.[0]; // Return the fetched data
  } catch (error) {
      console.log("Error fetching aidata:", error); 
  }
  }

  const handleSubmit = async() => {
    try {
      const analyzerInput = await getInputData(); 
      const jsonData = JSON.parse(simulationData);
      var simulationInput = {
        inputData: analyzerInput,
        modelData: jsonData,
      };
      setSimulationData(simulationInput);
      handleCloseJsonModal();
      setOpenCpg(!openCpg);
    } catch (error) {
      console.log("Invalid JSON input");
      alert("Invalid JSON input");
    }
  };


  const handleClose = () => {
    setOpen(false);
  };
  
  const handleClickAnalzye = async () => {
    setLoading(true);
    const analyzerInput = await getInputData();
    if (selectedValue === "Simulation") {
      handleClickOpen();
    } else {
      setOpenCpg(!openCpg);
      setSimulationData({inputData:analyzerInput,modelData:{}})
    }
    // let data1 = JSON.stringify({
    //   promptid: "CPG",
    //   filterValues: {
    //     patientid: props?.patientId,
    //     encounterid: props?.encounterid,
    //     practitionerid: practitionerid,
    //     practroleid: roleid,
    //   },
    // });
    
    let data = JSON.stringify({
      serviceid: "6a195c00-1af0-44d3-9fd6-c22d4e4b99b0",
      mode: selectedValue,
      sections:["summary", "diagnoses"],
      filterValues: {
        patientid: props?.patientId,
        encounterid: props?.encounterid,
        // practitionerid: practitionerid,
        // practroleid: roleid,
      },
      isincludeinput: true,
      // sections: [
      //   "summary",
      //   "diagnoses",
      //   "lab",
      //   "radiology",
      //   "nursing",
      //   // "otherinvestigation",
      //   "medications",
      //   // "referral",
      //   // "lifestyleadvices"
      // ],
    //   sections: [
    //     "summary",
    //     "diagnoses",
    //     "laboratoryOrders",
    //     "radiologyOrders",
    //     "medications"
    // ],
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_CPG_AI_API,
      headers: {
        Jwttoken: jwtToken,
        "Content-Type": "application/json",
      },
      data: data,
    };
    try {
      axios
        .request(config)
        .then((response) => {
          setJsonResponse(response?.data?.inputdata);
          //setOpen(true);
          setSimulationData({ inputData: analyzerInput, modelData: response?.data?.response?.results });
          setLoading(false);
          setisSeries(true)
          if (response?.data?.error) {
            setOpenCpg(false);
            setisSeries(false)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error fetching data", error);
    } 
  };
  function splitArraySections(array, chunkSize) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  }
  useEffect(()=>{
    if(isSeries){
      // let tempModelData = simulationData?.modelData;
      let sectionList = ["laboratoryOrders","radiologyOrders","nursingProcedures","otherInvestigations","medications","referralOrders","lifeStyleAdvices"]
      const splitSections = splitArraySections(sectionList, 2);

      const fetchData = async () => {
        for (let i = 0; i < splitSections?.length; i++) {
          let combinedData = {};
          let data = JSON.stringify({
            serviceid: "6a195c00-1af0-44d3-9fd6-c22d4e4b99b0",
            mode: selectedValue,
            sections: splitSections[i],
            filterValues: {
              patientid: props?.patientId,
              encounterid: props?.encounterid,
              // practitionerid: practitionerid,
              // practroleid: roleid,
            },
            isincludeinput: true,
          });
      
          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: process.env.REACT_APP_CPG_AI_API,
            headers: {
              Jwttoken: jwtToken,
              "Content-Type": "application/json",
            },
            data: data,
          };
          setLoadingData(splitSections[i])
          try {
            const response = await axios.request(config);
            if (response?.data?.response?.results) {
              combinedData = response?.data?.response?.results;//i === 0 ? sampledata1 : i === 1 ? sampleData2 : i === 2 ? sampleData3 : sampleData4;
              setSimulationData((prevState) => ({
                ...prevState,
                modelData: {
                  ...prevState.modelData,
                  ...combinedData,
                },
              }));
              setLoadingData([])
              // if (response?.data?.error) {
              //   setOpenCpg(false);
              //   break;
              // }
            }
          } catch (error) {
            console.error("Error fetching data", error);
            break;
          }
        }
        setisSeries(false)
      };
      fetchData();      
      // splitSections?.map((v,i)=>{
      //   let combinedData ={};
      //   let data = JSON.stringify({
      //     serviceid: "6a195c00-1af0-44d3-9fd6-c22d4e4b99b0",
      //     mode: selectedValue,
      //     sections:v,
      //     filterValues: {
      //       patientid: props?.patientId,
      //       encounterid: props?.encounterid,
      //       // practitionerid: practitionerid,
      //       // practroleid: roleid,
      //     },
      //     isincludeinput: true,
      //   });
      //   let config = {
      //     method: "post",
      //     maxBodyLength: Infinity,
      //     url: process.env.REACT_APP_CPG_AI_API,
      //     headers: {
      //       Jwttoken: jwtToken,
      //       "Content-Type": "application/json",
      //     },
      //     data: data,
      //   };
      //   try {
      //     axios
      //       .request(config)
      //       .then((response) => {
      //         if(response?.data?.response){
      //           // combinedData = Object.assign({}, simulationData?.modelData, response.data.response);
      //           combinedData = i===0 ? sampledata1 : i===1 ? sampleData2 : i===2 ? sampleData3 : sampleData4
      //           setSimulationData((prevState) => ({
      //             ...prevState,
      //             modelData: {
      //               ...prevState.modelData,
      //               ...combinedData,
      //             },
      //           }));
      //           // setSimulationData({modelData: response.data.response });
      //           if (response?.data?.error) {
      //             setOpenCpg(false);
      //           }
      //         }
      //       })
      //       .catch((error) => {
      //         console.log(error);
      //       });
      //   } catch (error) {
      //     console.error("Error fetching data", error);
      //   } 
      // })
    }
  },[isSeries])
  return (
    <div>
      <JsonModal
        open={open}
        onClose={handleClose}
        jsonResponse={jsonResponse}
      />
      <Dialog
        open={openJsonModal}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
      >
        {/* <DialogTitle>JSON Data Input</DialogTitle> */}

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box
                display="flex"
                flexDirection="column"
                height="100%"
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                >
                  <h2>Input Data</h2>
                  <Tooltip title="Copy Input Data">
                    {/* <CopyToClipboard
                        text={
                          jsonResponse
                            ? JSON.stringify(jsonResponse, null, 2)
                            : ""
                        }
                      > */}
                    <IconButton>
                      <ContentCopyIcon />
                      <Typography>Copy</Typography>
                    </IconButton>
                    {/* </CopyToClipboard> */}
                  </Tooltip>
                </Box>
                <TextField
                  multiline
                  rows={22}
                  variant="outlined"
                  fullWidth
                  value={
                    jsonResponse
                      ? JSON.stringify(jsonResponse, null, 2)
                      : ""
                  }
                //onChange={(e) => setjsonInput(e.target.value)}
                />
                {/* <Box mt={2}>
                    <Button
                      variant="contained"
                      onClick={handleFetchInput}
                    >
                      Fetch Input
                    </Button>
                  </Box> */}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                display="flex"
                flexDirection="column"
                height="100%"
              >
                <Box mb={2}>
                  <h2>Model Data</h2>
                </Box>
                <TextField
                  multiline
                  rows={22}
                  variant="outlined"
                  fullWidth
                  //value={simulationData}
                  onChange={(e) => setSimulationData(e.target.value)}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseJsonModal}>Close</Button>
          <Button variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <ChatBot
        options={options}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        onButtonClick={handleClickAnalzye}
      ></ChatBot>
      {/* <Dialog
          open={openCpg}
          onClose={() => setOpenCpg(false)}
          fullWidth
          maxWidth="xl"
        >
          <DialogTitle sx={{ m: 0, p: 2 }}>
            <Typography variant="h6">MEDAI ASSISTANT ANALYSIS</Typography>
            <IconButton
              aria-label="close"
              onClick={() => setOpenCpg(false)}
              size="large"
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
              color="primary"
            >
              <CancelIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers sx={{ padding: 0 }}>
            <div style={{ overflowX: "hidden", overflowY: "auto" }}>
              <Emr
                mode={selectedValue}
                simulationData={simulationData}
                patientid= {props?.patientId}
                encounterid= {props?.encounterid}
                setOpenCpg={setOpenCpg}
              />
            </div>
          </DialogContent>
      </Dialog> */}
      <ThemeProvider theme={theme} sx={{ height: "100vh" }}>
        <Assistant
          loading={loading}
          open={openCpg}
          onClose={() => setOpenCpg(false)}
          data={simulationData}
          setData={setSimulationData}
          loadingData={loadingData}
        />
      </ThemeProvider>
    </div>
  )
}

export default CpgPackage