import {
    createSlice,
    createAsyncThunk
} from "@reduxjs/toolkit";
import { fetchData,defaultReject,defaultState,urls } from "../../../utils";

import { query } from "./queries";
import { generateJson } from "./insertJson";



const AVC_LOGIN = createAsyncThunk(
    `avcMeetApiSlice/avc_info`,
    async (payload = {}, { rejectWithValue }) => {
        try {
            const {
                id = "",
            } = payload;
            const resp = await fetchData({ body: query.avc_login(id) }, urls.readQdmQueries);
            const data = resp?.[0] || [];
            let res_data = {
                date: data?.start??"",
                app_id: data?.appno??"",
                // doctor: {
                //     name: (data?.practitionerID?.[0]?.name?.[0]?.text)? data?.practitionerID?.[0]?.name?.[0]?.text + " " + data?.practitionerID?.[0]?.name?.[0]?.given + " " + data?.practitionerID?.[0]?.name?.[0]?.family:"-",
                //     type: data?.practitionerID?.[0]?.practitioner_role?.[0]?.role?.[0]?.coding?.[0]?.display??"",
                //     org_name: data?.practitionerID?.[0]?.practitioner_role?.[0]?.OrgID??""
                // },
                doctor: {
                    name: data?.practitionerID?.name.name,
                    type: data?.practitionerID?.practitioner_type.display,
                    org_name: data?.practitionerID?.orgname.display,
                },
                patient: {
                    name: data?.PatientID?.name.name,
                    age: data?.PatientID?.age+"Y",
                    gander: data?.PatientID?.gender.display,
                    id: data?.PatientID?.mrn,
                    mobile: data?.PatientID?.telecom.value
                },
                personID:{
                    name: data?.PersonID?.name.name,
                    age: data?.PersonID?.age+"Y",
                    gander: data?.PersonID?.gender.display,
                    id: data?.PersonID?.name._id,
                    mobile: data?.PersonID?.telecom.value
                },
                dataJson: data
            }
            return {
                ...defaultState.List,
                data: res_data,
            }
        } catch (err) {
            return rejectWithValue({
                ...defaultReject,
                message: err.message,
            })
        }
    },
);

const AVC_FORM_INSERT = createAsyncThunk(
    `avcMeetApiSlice/avc_form`,
    async (payload = {}, { rejectWithValue }) => {
        try {
            const { data, appInfo } = payload;
            let queriesjson = generateJson.insert_json(data, appInfo);
            const dataResult = await fetchData(
				{ body: JSON.stringify(queriesjson) },
				urls.upsertDocument
			);
			let result = [];
            return {
                ...defaultState.List,
                data: result,
            }
        } catch (err) {
            return rejectWithValue({
                ...defaultReject,
                message: err.message,
            })
        }
    },
);

const AVC_PARTICIPANT_JOINED = createAsyncThunk(
    `avcMeetApiSlice/avc_participant_joined`,
    async (payload = {}, { rejectWithValue }) => {
        try {
            const {
                id = "",
            } = payload;
            const resp = await fetchData({ body: query.avc_participant_joined_qu(id) }, urls.readDocuments);
            const data = resp?.result?.[0] || [];
            let res_data = {}
            return {
                ...defaultState.List,
                data: res_data,
            }
        } catch (err) {
            return rejectWithValue({
                ...defaultReject,
                message: err.message,
            })
        }
    },
);
const avcMeetApiSlice = createSlice({
    name: "avcMeetApiSlice",
    initialState: {
        avc_info: { ...defaultState.Info },
        avc_form: { ...defaultState.Info },
        avc_participant_joined: { ...defaultState.Info },
    },
    extraReducers: {

        /* AVC_LOGIN */
        [AVC_LOGIN.fulfilled]: (state, action) => {
            state.avc_info = action?.payload ?? [];
        },
        [AVC_LOGIN.pending]: (state, action) => {
            state.avc_info.loading = true;
        },
        [AVC_LOGIN.rejected]: (state, action) => {
            state.avc_info = action?.payload ?? [];
        },

        /* AVC_FORM_INSERT */
        [AVC_FORM_INSERT.fulfilled]: (state, action) => {
            state.avc_form = action?.payload ?? [];
        },
        [AVC_FORM_INSERT.pending]: (state, action) => {
            state.avc_form.loading = true;
        },
        [AVC_FORM_INSERT.rejected]: (state, action) => {
            state.avc_form = action?.payload ?? [];
        },

        /* AVC_PARTICIPANT_JOINED */
        [AVC_PARTICIPANT_JOINED.fulfilled]: (state, action) => {
            state.avc_participant_joined = action?.payload ?? [];
        },
        [AVC_PARTICIPANT_JOINED.pending]: (state, action) => {
            state.avc_participant_joined.loading = true;
        },
        [AVC_PARTICIPANT_JOINED.rejected]: (state, action) => {
            state.avc_participant_joined = action?.payload ?? [];
        },

    },
});

export const avcMeetApiActions = {
    AVC_LOGIN,
    AVC_FORM_INSERT,
    AVC_PARTICIPANT_JOINED
};


export default avcMeetApiSlice;