import * as React from "react"

function AllergyHeaderIcon({height,width,color,...props}) { 
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "24px"}
      height={height ? height : "24px"}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.479 4.644C5.67 5.46 5.148 6.64 4.937 8.083a.833.833 0 01-1.058.68c-.23-.067-.374.143-.447.338-.094.253-.122.621-.013 1.03.095.354.43 1.091.908 1.047a.834.834 0 01.866.56c.847 2.476 2.972 4.096 4.807 4.096 1.834 0 3.96-1.62 4.806-4.096a.834.834 0 01.869-.561c.477.046.814-.692.909-1.047.109-.408.08-.776-.014-1.029-.073-.196-.217-.406-.448-.338a.833.833 0 01-1.06-.68c-.21-1.442-.733-2.624-1.542-3.438-.794-.8-1.929-1.311-3.52-1.311-1.592 0-2.727.512-3.521 1.31zM5.296 3.47C6.438 2.321 8.018 1.667 10 1.667c1.981 0 3.56.654 4.703 1.803.963.97 1.567 2.242 1.875 3.644.046.009.091.02.137.032.724.193 1.19.763 1.418 1.375.227.613.249 1.338.06 2.041-.264.986-.97 1.958-2.001 2.22-1.127 2.71-3.62 4.718-6.192 4.718s-5.067-2.01-6.193-4.718c-1.03-.264-1.734-1.236-1.998-2.22-.189-.703-.167-1.428.06-2.042.228-.61.695-1.18 1.419-1.375.044-.011.089-.021.133-.03.309-1.403.912-2.676 1.875-3.646z"
        fill={color ? color : "#FF5900"}
      />
      <path
        d="M9 13.029c.02-.019.033-.02.058-.029"
        stroke={color ? color : "#FF5900"}
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path
        d="M10 10.029c.02-.019.033-.02.058-.029"
        stroke={color ? color : "#FF5900"}
        strokeWidth={1.5}
        strokeLinecap="round"
      />
      <path
        d="M7.5 10.862c.02-.019.033-.02.058-.029M12 13.029c.02-.019.033-.02.058-.029"
        stroke={color ? color : "#FF5900"}
        strokeLinecap="round"
      />
      <path
        d="M14 10.029c.02-.019.033-.02.058-.029"
        stroke={color ? color : "#FF5900"}
        strokeWidth={2}
        strokeLinecap="round"
      />
    </svg>
  )
}

export default AllergyHeaderIcon