import React from 'react';
import { TextField,makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(()=>({
    textField:{
        '& input':{   
            fontFamily:'pc_medium',
            fontSize:14
        }
    }
}))

export const Selection = (props) =>{

    const classes =  useStyles(props);

    return(
        <div>
  <Autocomplete
    id={`${props?.parent_id}_collapse_body_autocomplete`}
    size="small"
    multiple={props?.multiple}
    options={props.options}  // Ensure this is populated
    getOptionLabel={(option) => option?.label}  // Ensure correct property
    filterSelectedOptions={props?.filterSelectedOptions}
    value={props?.value}  // Ensure this matches the selected value in your state
    onChange={(event, newValue) => props.onChange(event, newValue)}  // Ensure onChange updates state
    renderInput={(params) => (
      <TextField
        id={`${props?.parent_id}_collapse_body_autocomplete_label_add_your_health`}
        {...params}
        variant="outlined"
        label={props.label ?? ''}
        className={classes.textField}
      />
    )}
    style={{
      borderColor: "#E0E0E0",
    }}
    className={"pc_regular"}
  />
</div>

    )
}

Selection.defaultProps={
    multiple:false,
    options:[],
    optionLabel:'label',
    filterSelectedOptions:false,
    value:null,
    label:"",
    parent_id:''

}