import * as React from "react"

function EditIcon({height,width,color,...props}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width?width:24}
      height={height?height:24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M14.06 9l.94.94L5.92 19H5v-.92L14.06 9zm3.6-6c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.04 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"
        fill={color?color:"#005ABB"}
      />
    </svg>
  )
}

export default EditIcon