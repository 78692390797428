/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date 2020-12-01
 * @modify date 2021-04-27
 * @desc Material UI MuIAlert and Snackbar is used to show the temporary message Component
 */
import React, { useState } from "react";
import {
  Row,
  Col,
  Text,
  H6,
  Icons,
  Div,
  TextInput,
  Modal,
  Card,
  Image,
  Divider,
} from "qdm-component-library";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { TextField, Typography, Dialog, DialogTitle, Grid } from "@material-ui/core";
import { Button, Tooltip, makeStyles } from "@material-ui/core";
import { InsightsIcon } from "../../../components";
import { Popover } from "@material-ui/core";
import { ChangeStatus } from "./components";
import { RequestorDetails } from "./requestorDetails";
import { useDispatch, useSelector, connect } from "react-redux";
// import { actions } from "primarycare-binder";
import actions from "../../../redux/actions";
import { getData, getOrderName, makeName, AlertProps } from "../../../utils";
import { UIColor } from "../../../themes/theme";
import withAllContexts from "../../../hoc/withAllContexts";
import { generateJson, getStatusHistoryList } from "./generateJson";
//import { withRouter } from "react-router-dom";
import moment from "moment";
import AlleryDrugModel from "./components/alleryDrugModel";
import Tick from "../../../assets/img/icons8-double-tick.svg";
import Addicon from "../../../assets/img/icons8-add.svg";
import Cancel from "../../../assets/img/icons8-multiply.svg";
import AlertsRestriction from "../alertsRestriction";
import AlertDialog from "./alert";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { add, getUnixTime } from "date-fns";
import icons8trash from "../../../assets/img/svg/icons8-trash_.svg";
import icons8edit from "../../../assets/img/svg/icons8-edit_.svg";

const filter = createFilterOptions();

const statusColors = {
  "On-Hold": { color: "rgb(245, 139, 0)", background: "rgb(245, 139, 0)" },
  Draft: { color: "rgb(245, 139, 0)", background: "rgb(245, 139, 0)" },
  Discontinue: { color: "#d60000", background: UIColor.lineBorderFill.color },
  Continue: {
    color: UIColor.primaryColor.color,
    background: UIColor.primaryColor.color,
  },
  //Active: { color: UIColor.success.color, background: "#D4E7FD" },
  Active: { color: UIColor.success.color, background: "#fff" },
  // "Cancel": { color: "#FF4D4A", background: "#FFE1E0" }
};

const funcList = [
  "DURATIONTYPE_MASTER",
  "PRIORITY_MASTER",
  "ORDERMODE_MASTER",
  "DRUGMASTER_MASTER",
  "STRENGTHUOM_MASTER",
  "ORGANIZATION_MASTER",
  "DIAGNOSISAGAINSTENCOUNTER_MASTER",
  "PRACTITIONERROLE_MASTER",
  "PRACTITIONER_MASTER",
  "FREQUENCYMASTER_MASTER",
  "PATIENT_INSTRUCTIONS_MASTER",
  // "DRUGTYPE_MASTER",
  // // "DRUGMASTER_MASTER",
  // "STRENGTH_MASTER",
  // "STRENGTHUOM_MASTER",
  // "STRENGTHVOLUMN_MASTER",
  // "STRENGTHVOLUMNUOM_MASTER",
  // "DOSAGETYPEINDICATOR_MASTER",
  // "DOSAGEUOM_MASTER",
  // "DOSAGEVOLUMEUOM_MASTER",
  // "DRUGROUTES_MASTER",
  // "DOSAGEFORM_MASTER",
  // "FREQUENCYMASTER_MASTER",
  // "DURATIONTYPE_MASTER",
  // "PRIORITY_MASTER",
  // "DRUGCATEGORY_MASTER",
  // "ORDERNATURE_MASTER",
  // "STATUS_MASTER",
  // "ORDERMODE_MASTER",
  // "DRUGSTOREDISPLOC_MASTER",
  // "DIAGNOSISAGAINSTENCOUNTER_MASTER",
  // "REASONCODE_MASTER",
  // "ORGANIZATION_MASTER",
  // "PRACTITIONERROLE_MASTER",
  // "PRACTITIONER_MASTER",
  // "INFUSIONRATEUOM_MASTER",
  // "TOTALVOLUMEUOM_MASTER",
  // "INDICATION_REASON_CODE_MASTER",
  // "BASESCHEDULE_MASTER",
  // "PATIENT_INSTRUCTIONS_MASTER",
];
const useStyles = makeStyles({
  //  autocomplete: {
  //     width: 120,
  //     "& input": {
  //       fontFamily: "pc_regular",
  //       fontSize: 14,
  //       color: UIColor.secondaryText.color,
  //       "&::placeholder": {
  //         fontSize: 14,
  //         fontFamily: "pc_regular !important",
  //       },
  //     },
  //   },
  autocomplete: {
    "& .MuiInputBase-input": {
      color: UIColor.secondaryText.color,
      fontSize: "12px !important",
      fontFamily: "pc_regular!important",
    },
    "& .MuiInputBase-root": {
      //height: 40,
      color: UIColor.secondaryText.color,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
        borderRadius: 8,
      },
      "&:hover fieldset": {
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 1,
        border: `1px solid ${UIColor.lineBorderFill.color} !important`,
      },
    },
    "& .MuiAutocomplete-popupIndicator": {
      transform: "none !important",
    },
  },
  muiPopovers: {
    boxShadow: "0px 4px 24px #00000029",
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 8,
    backgroundColor: "#fff",
    minWidth: 240,
    overflow: "visible",
  },
  paper: { minWidth: "800px" },
});
const styles = {
  vitalsHead: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fff",
    borderRadius: 8,
    padding: "0px 0px 0px 10px",
  },
  vitalsHead1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fff",
    borderRadius: 8,
    padding: "10px 10px 12px 20px",
  },
  vitalsfieldHead: {
    padding: "6px 10px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    background: "#fff",
    borderRadius: 8,
    flexWrap: "wrap",
  },
  vitailsCard: {
    //border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 10,
    width: "100%",
    //marginBottom: "24px !important",
    backgroundColor: "#fff",
  },
  labelStyle: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  inputStyle: {
    padding: 5,
    marginBottom: 10,
  },
  inputFiledStyle: {
    borderColor: UIColor.lineBorderFill.color,
    borderRadius: "8px",
  },
  listVitals: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tagsStyle: {
    padding: "4px 12px",
    borderRadius: "50px",
    border: `1px solid ${UIColor.primaryColor.color}`,
    color: UIColor.primaryColor.color,
    background: UIColor.differentiationBackground.color,
    fontSize: "10px",
  },
  list: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  borderStyleCal: {
    borderColor: "rgba(0, 0, 0, 0.42)",
    // backgroundColor: `${UIColor.greyBackground.color} !important`,
    marginRight: 16,
    height: 22,
    width: 55,
    padding: 0,
  },
  borderStyle: {
    borderColor: "rgba(0, 0, 0, 0.42)",
    // backgroundColor: `${UIColor.greyBackground.color} !important`,
    height: 22,
    width: 60,
    padding: 0,
  },
  infoBell: {
    width: "50px",
    display: "flex",
    padding: "12px",
    borderLeft: `1px solid ${UIColor.lineBorderFill.color}`,
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 14,
  },
  icons: {
    cursor: "pointer",
  },
  flexFlow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  noAccessTag: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "2px 6px",
    borderRadius: "4px",
    fontWeight: 400,
    marginLeft: 12,
    backgroundColor: "#FAD7D6",
    color: "#FF5654",
    fontSize: "12px",
  },
  textColor: {
    color: UIColor.tertiaryText.color,
    fontSize: 12,
    paddingLeft: 6,
  },
  statusColor: {
    fontSize: 12,
    paddingLeft: 6,
  },
  statusTag: {
    color: "#000",
    fontSize: 12,
    backgroundColor: UIColor.lineBorderFill.color,
    borderRadius: "4px",
    padding: "2px 6px",
    marginLeft: 6,
    width: "fit-content",
  },
  userInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  editAllergyIcon: {
    padding: "6px 10px",
    background: UIColor.greyBackground.color,
    borderRadius: "50px",
    cursor: "pointer",
  },
  floatingLable: {
    backgroundImage: `linear-gradient(#fff, ${UIColor.greyBackground.color})`,
    position: "absolute",
    top: "-11px",
  },
  CalendarStyle: {
    display: "flex",
    alignItems: "flex-end",
    position: "relative",
  },
  modalCard: {
    width: 250,
    padding: 20,
  },
  modalHeading: {
    fontSize: 16,
    color: UIColor.primaryText.color,
    textAlign: "center",
    marginBottom: 10,
  },
  modalSubTitle: {
    fontSize: 14,
    color: UIColor.tertiaryText.color,
    textAlign: "center",
    marginBottom: 20,
  },
  modal: {
    backgroundColor: "#fff",
    boxShadow: "0px 4px 24px #00000029",
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 8,
    overflow: "hidden",
  },
  outlinedButton: {
    borderColor: UIColor.primaryColor.color,
    color: UIColor.primaryColor.color,
    backgroundColor: "#fff",
    borderRadius: 8,
    fontSize: 14,
    fontFamily: "pc_regular !important",
    display: "block",
  },
  containedButton: {
    backgroundColor: UIColor.error.color,
    borderColor: UIColor.error.color,
    borderRadius: 8,
    fontSize: 14,
    fontFamily: "pc_semibold !important",
    marginBottom: 10,
    color: "#fff",
  },
};

const initialState = {
  name: null,
  unitValue: null,
  unitsTypes: null,
  doseMeasurementValue: null,
  dosetime: null,
  doseOption: null,
  drug_category: null,
  DefaultRouteCode: null,
  orderEndDate: null,
  orderStartDate: null,
};
const MedicationOrderFrom = (props) => {
  console.log("MedicationOrderFromProps", props);
  const classes = useStyles(props);
  const stateList = useSelector((state) => state?.MedicationMastersSlice);
  console.log("MedicationOrderFrom_stateList", stateList);

  const personData = useSelector(
    (state) => state?.personSlice?.searchPractioner?.data
  );

  const loggedUserInfo = useSelector(
    (state) => state?.authSlice?.loggedUserInfo
  );

  const encounter = useSelector(
    (state) => state?.chiefCompilenceMastersSlice?.encounter_Details
  );

  const medicationsList = useSelector(
    (state) => state.medicationsSlice?.medication_list?.data
  );

  const encounter_banner = useSelector(
    (state) => state?.medicationsSlice?.Encounterbanner?.data
  );

  const [masters, setMasters] = React.useState([]);
  const isFirstVisit =
    (encounter?.data?.type?.toLocaleLowerCase() ?? "") === "first visit";
  const dispatch = useDispatch();
  const patient_getData = props?.patientgetData || props?.propsData?.patientgetData ; //propsData.patientgetData
  console.log("patientgetData", patient_getData);
  const medicationsData = props.medicationsData;
  console.log("medicationsData", medicationsData);
  const { list, setMedication } = medicationsData;
  console.log("list", list);
  const [entry, setentry] = React.useState({
    ...initialState,
    headerShow: true,
  });

  const [alertDialogOpen, setAlertDialogOpen] = React.useState(false);
  const [alertData_, setAlertData_] = React.useState([]);

  const [modal, setModal] = React.useState(false);
  const [infoText, setInfoText] = React.useState(null);
  const [Openstatus, setOpenstatus] = React.useState(null);
  const [statusDetails, setStatusDetails] = React.useState({});
  const [interationsAlert, setInteractionsAlert] = React.useState({
    reasonCode: "",
    alertMessage: {},
  });
  const [codes, setCodes] = React.useState({
    alertCodes: [],
    diseaseCodesR: [],
  });
  const [drugGen, setDrugGen] = React.useState({
    genCOde: [],
    totalData: [],
  });

  const handleMedication = async (v) => {
    //
    if (v?.length > 3) {
      //
      let ccMaster = await dispatch(
        actions.DRUGMASTER_MASTER({ input_text: v })
      );

      let ccMasters = ccMaster?.payload?.data;
      console.log("medicationsData", medicationsData);
      let datalist = medicationsData?.list.map((val) => val?.name?.value);
      let masterData = ccMasters.filter(
        (val) => datalist.indexOf(val?.label) === -1
      );
      if (Array.isArray(masterData) && masterData?.length > 0) {
        setMasters(masterData);
      } else {
        setMasters([]);
      }
    }
    // else {
    // 	setMasters([]);
    // }
  };

  const handleOpenStatus = (e, data, index) => {
    setStatusDetails({
      status: data.status,
      editIndex: index,
      id: data.editId,
      statushistory: data.statushistory,
      statusReason: data?.statusreason ?? "",
      statusperiod: moment().format("DD-MM-YYYY,h:mm a"),
      // statusperiod: data?.statusperiod ? moment.unix(data.statusperiod).format("DD-MM-YYYY,h:mm a") : "",
    });
    setOpenstatus(e?.currentTarget);
  };
  const handleSaveStatus = async (status, reason, period) => {
    props?.backDrop?.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "Updating status",
    });
    statusDetails.status = status;
    statusDetails.statusReason = reason;
    statusDetails.statusperiod = period ? moment(period).unix() : 0;
    let statushistory = getStatusHistoryList(
      status,
      statusDetails.statushistory,
      personData?.[0]._id,
      reason
    );
    statusDetails.statushistory = statushistory;
    await dispatch(actions.UPDATE_MEDICATIONS(statusDetails));
    // const { pId } = props?.location?.state;
    let res = await dispatch(
      actions.GET_MEDICATIONS({ id: patient_getData?._id ?? patient_getData?.encounterId })
    );
    let data = generateJson(res.payload.data, stateList);
    setMedication({ ...props.medicationsData, list: data });

    setStatusDetails({});
    setOpenstatus(null);
    props?.backDrop?.setBackDrop({
      ...props?.backDrop,
      open: false,
      message: "",
    });
  };
  React.useEffect(() => {
    setentry({
      ...entry,
      headerShow: list.length > 0 ? false : true,
    });
  }, []);

  const getMasterData = React.useCallback(async () => {
    return new Promise(async (resolve, reject) => {
      let calls = funcList.map(async (val) => {
        return await dispatch(
          actions?.[val]({
            orgId: patient_getData?.facilityid, //personData?.[0]?.OrgID?.[0],
            EId: patient_getData?._id, //encounter?.data?.eId,
          })
        );
      });
      Promise.all(calls).then(() => {
        resolve(true);
      });
    });
  }, [dispatch, patient_getData?._id, personData]);
  // }, [dispatch, encounter?.data?.eId, personData]);

  const getMasters = React.useCallback(async () => {
    props?.backDrop?.setBackDrop({
      ...props?.backDrop,
      open: true,
      message: "Fetching....",
    });
    await getMasterData();
    props?.backDrop?.setBackDrop({
      ...props?.backDrop,
      open: false,
      message: "",
    });
  }, [getMasterData, props?.backDrop]);

  const getMedicationData = React.useCallback(async () => {
    //debugger
    let res_ = await dispatch(
      actions.GET_MEDICATIONS({ id: props?.encounterID ?? patient_getData?._id ?? patient_getData?.encounterId })
    );
    
    let data = generateJson(res_.payload.data, stateList);
    setMedication({ ...props.medicationsData, list: data });
  }, [props?.encounterID, patient_getData]);

  React.useEffect(() => {
    getMasters();
  //  getMedicationData();
    
  }, []);


  React.useEffect(() => {
   
    getMedicationData();
    
  },[patient_getData?.encounterId]);


  const getCodes = React.useCallback(async () => {
    const alertCodes = await dispatch(
      actions.ALERT_CODES({ list: patient_getData?._id })
    );

    const diseaseCodesR = await dispatch(
      actions.DRUG_CODES({ list: patient_getData?._id })
    );

  


    setCodes({
      ...codes,
      alertCodes: alertCodes?.payload?.data,
      diseaseCodesR: diseaseCodesR?.payload?.data,
    });
  }, [codes, dispatch, patient_getData?._id]);

  React.useEffect(
    () => {
      getCodes();
      // if (
      //   stateList?.Patient_Instruction?.data?.length > 0 &&
      //   medicationsList?.length > 0
      // ) {
      //   let data = generateJson(medicationsList, stateList);
      //   setDrugGen({
      //     ...drugGen,
      //     genCOde: data
      //       .filter((val) => val?.name?.drugroutegenid?.length > 0)
      //       .map((val) => val?.name?.drugroutegenid),
      //     totalData: data,
      //   });
      //   setMedication({ ...props?.medicationsData, list: data });
      //   setentry({ ...entry, headerShow: false });
      // }
    },
    [
      // drugGen,
      // entry,
      // getCodes,
     // medicationsList,
     // props.medicationsData,
     //  setMedication,
      // stateList,
      // stateList.Patient_Instruction.data,
    ]
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteDetails, setDeleteDetails] = React.useState(null);

  const DeleteFun = async () => {
    handleModal(false);
    props?.backDrop?.setBackDrop({
      ...props?.backDrop,
      open: true,
      message: "deleting...",
    });
    await dispatch(
      actions.DELETEMEDICATION_MASTER({ id: deleteDetails?.data?._id })
    );
    props?.backDrop?.setBackDrop({
      ...props?.backDrop,
      open: false,
      message: "",
    });
    list.splice(deleteDetails.index, 1);
    setMedication({ ...props?.medicationsData, list });

    setDeleteDetails(null);
  };

  const handleClose = (get) => {
    if (get) {
      setAnchorEl(null);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleInputChange = (e, name) => {
    if (name === "name") {
      let unitUom = null;
      if (e.StrengthUOM) {
        unitUom = stateList?.strengthUom_masters?.data.filter(
          (val) =>
            val?.label?.toLocaleLowerCase() ===
            e.StrengthUOM.toLocaleLowerCase()
        )[0];
      }
      setentry({
        ...entry,
        doseMeasurementValue: e.StrengthValues ?? "",
        unitValue: unitUom,
        // unitsTypes: natureOption[0],
        // doseOption: doseOption[0],
        // dosetime: "01",
        [name]: e,
      });
      const gencode = drugGen?.genCOde;
      gencode.push(e.drugroutegenid);
      setDrugGen({
        ...drugGen,
        genCOde: gencode,
      });
    } else {
      setentry({
        ...entry,
        [name]: e,
      });
    }
  };

  const handleFDB = async () => {
    if (
      entry.name?.drugType === "IV" ||
      (entry.name &&
        entry.unitValue &&
        entry.doseMeasurementValue &&
        entry.unitsTypes &&
        entry.doseOption &&
        entry.dosetime)
    ) {
      const dobCalculation = moment(encounter?.data?.dob, "DD.MM.YYYY").format(
        "YYYY/MM/DD"
      );
      let alertPayload = {
        patientName: encounter?.data?.name,
        age: moment().diff(dobCalculation, "years"),
        weight: 5450,
        height: 50,
        creatinineClearance: {
          Value: entry?.doseMeasurementValue,
          UOM: entry?.unitValue?.label,
        },
        drugCodes:
          drugGen?.genCOde?.map((val) => {
            return Math.floor(val);
          }) ?? [],
        diseaseCodes:
          codes?.diseaseCodesR?.map((val) => {
            return val?.codes;
          }) ?? [],
        allergyCodes:
          codes?.alertCodes?.map((val) => {
            return Math.floor(val?.codes);
          }) ?? [],
      };
      let resData = await dispatch(
        actions.ALERT_INTERACTIONS({ alertList: alertPayload })
      );
      let resDataResonCode = await dispatch(
        actions.REASON_TYPE_CODE_READ({
          display: Object.keys(resData?.payload?.data)[0],
        })
      );

      //if (resData?.payload?.data?.["Drug-AllergyIngredient-Interaction"]?.length === 0) {
      AddMedication();
      // props.alert.setSnack({
      // 	open: true,
      // 	severity: AlertProps.severity.success,
      // 	msg: "No Drug-AllergyIngredient-Interaction found",
      // 	vertical: AlertProps.vertical.top,
      // 	horizontal: AlertProps.horizontal.center,
      // 	tone: true,
      // })
      // } else {
      // 	setInteractionsAlert({
      // 		...interationsAlert,
      // 		reasonCode: resDataResonCode.payload.data.result[0]._id,
      // 		alertMessage: resData?.payload?.data
      // 	})
      // 	setDialogOpen(true)
      // }
    }
  };
  const [alertDetails, setAlertData] = useState({
    open: false,
    data: null,
  });

  const closeAlertModal = (data) => {
    setAlertData({
      open: false,
      data: null,
    });

    if (data.type === 1) {
      setentry({
        ...entry,
        name: null,
        unitValue: null,
        unitsTypes: null,
        doseMeasurementValue: null,
        doseOption: null,
        dosetime: null,
        headerShow: !entry.headerShow,
        orderEndDate: null,
        orderStartDate: null,
      });
    } else if (data.type) {
      AddMedication(true, data.type);
    }
  };

  //Alert Dialoge open
  const handleOpenAlertDialog = async (newValue) => {
    // Get drug duplication
    // let DrugCode = parseInt(
    //   newValue?.result?.aliasDtls?.find(
    //     (val) => val?.aliasType?.code === "FDBRTGEN"
    //   )?.aliasCode
    // );
    let DrugCode = parseInt(
      newValue?.fdbAliasCode
    );
    //Get Alert Code
    const Alert_Codes = await dispatch(
      actions.GET_ALERT_CODES({ patientId:patient_getData?.patientDetails?.patientId ?? patient_getData?.patient_id ?? props?.pID })
    );
    let payloadDrugDuplication = {
      DrugCodes: [DrugCode],
      ProfileDrugCodes: Alert_Codes?.payload?.data?.ProfileDrugCodes ?? [], 
      DiseaseCodes: [], 
      AllergyCodes: [] 
    };

    let DrugDup;
    if (DrugCode) { // && Alert_Codes?.payload?.data?.ProfileDrugCodes?.length > 0) {
      DrugDup = await dispatch(
        actions.GET_DRUG_DUPLICATION({ pdd: payloadDrugDuplication })
      );
    }
    if (
      (Alert_Codes?.payload?.data?.ProfileDrugCodes?.length === 0 &&
        Alert_Codes?.payload?.data?.DiseaseCodes?.length === 0 &&
        Alert_Codes?.payload?.data?.AllergyCodes?.length === 0) 
        ||
      (Alert_Codes?.payload?.data?.ProfileDrugCodes?.includes(DrugCode) &&
        !DrugDup?.payload?.data?.result?.length > 0)
    ) {
      return;
    }

    let payload = {
      DrugCodes: [DrugCode],
      ...Alert_Codes?.payload?.data,
    };
    let GET_ALLERY;
    if (DrugCode) {
      GET_ALLERY = await dispatch(actions.GET_ALLERGY_INTERACTION({ input: payload }));
    }
    if (
      !GET_ALLERY?.payload?.data?.error &&
      ValidateAlert(GET_ALLERY?.payload?.data)
    ) {
      setAlertData_(GET_ALLERY?.payload?.data.result);
      setAlertDialogOpen(true);
    }

    if (GET_ALLERY?.payload?.data?.error) {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Unable to Fetch Alert and Interaction",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
  };

  //validate alert
  const ValidateAlert = (data) => {
    let ActualData =
      Array.isArray(data?.result) &&
      data?.result?.filter((value) => value?.[Object.keys(value)?.[0]]?.length > 0);
    if (ActualData?.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleCloseAlertDialog = (Data, save) => {
    if (save === "save") {
      setAlertDialogOpen(false);
      setAlertData_(Data);
    } else if (save === "next") {
      // setAlertDialogOpen(false);
      setAlertData_(Data);
    } else {
      setAlertDialogOpen(false);
      setAlertData_(Data);
      setentry({
        ...entry,
        name: "",
        drug_category: {},
        DefaultRouteCode: {},
        doseMeasurementValue: "",
        dosetime: "",
        unitValue: null,
        unitsTypes: null,
        doseOption: null,
      });
    }
  };

  const occureStartDateEndDate = async (type, newValue) => {
    // MEDICATIION Order - name
    // DOSAGE - doseMeasurementValue
    // UNIT - unitValue
    // FREQUENCY - unitsTypes
    // DURATION - dosetime
    // MONTH - doseOption
    if (type === "name") {
      props?.backDrop?.setBackDrop({
        ...props?.backDrop,
        open: true,
        message: "Fetching",
      });
      const cal = await handleOpenAlertDialog(newValue);
      const granularity = newValue?.durationCode?.label.toLowerCase();
      const start__ = new Date();
      const end__ = ""; //FIXME -  add(start__, { [granularity]: parseInt(entry?.dosetime) });
      const payload__ = {
        FrequencyType: newValue?.freqCode?.freqCode,
        DurationType: newValue?.durationCode?.label,
        DurationValue: parseInt(newValue?.durationcode),
        //FIXME -
        OrderStartDate: start__
          ? getUnixTime(start__)
          : getUnixTime(new Date()),
        OrderEndDate: end__ ? getUnixTime(end__) : getUnixTime(new Date()),
      };
      if (
        newValue?.freqCode?.freqCode &&
        newValue?.durationCode?.label &&
        newValue?.durationcode
      ) {
        const response = await dispatch(actions.GET_ORDER_DATE(payload__));
        // const response = {};
        setentry({
          ...entry,
          orderStartDate: response?.payload?.data?.orderStartDate || 1,
          orderEndDate: response?.payload?.data?.orderEndDate || 1,
          name: newValue,
          drug_category: {
            value: newValue?.DrugCategory ?? "",
          },
          DefaultRouteCode: {
            value: newValue?.DefaultRouteCode ?? "",
          },
          doseMeasurementValue: newValue?.doseVal ?? "",
          dosetime: newValue?.durationcode ?? "",
          unitValue: newValue?.doseUOM ?? null,
          unitsTypes: newValue?.freqCode ?? null,
          doseOption: newValue?.durationCode ?? null,
        });
      } else {
        setentry({
          ...entry,
          name: newValue,
          drug_category: {
            value: newValue?.DrugCategory ?? "",
          },
          DefaultRouteCode: {
            value: newValue?.DefaultRouteCode ?? "",
          },
          doseMeasurementValue: newValue?.doseVal ?? "",
          dosetime: newValue?.durationcode ?? "",
          unitValue: newValue?.doseUOM ?? null,
          unitsTypes: newValue?.freqCode ?? null,
          doseOption: newValue?.durationCode ?? null,
        });
      }

      props?.backDrop?.setBackDrop({
        ...props?.backDrop,
        open: false,
        message: "",
      });
    } else if (type === "dosetime" && newValue !== "") {
      const granularity = entry?.doseOption?.label.toLowerCase();
      const start__ = new Date();
      const end__ = add(start__, { [granularity]: parseInt(newValue) });
      const payload__ = {
        FrequencyType: entry.unitsTypes?.freqCode,
        DurationType: entry?.doseOption?.label,
        DurationValue: parseInt(newValue),
        //FIXME -
        OrderStartDate: start__
          ? getUnixTime(start__)
          : getUnixTime(new Date()),
        OrderEndDate: end__ ? getUnixTime(end__) : getUnixTime(new Date()),
      };
      if (entry.unitsTypes?.freqCode && entry?.doseOption?.label && newValue) {
        const response = await dispatch(actions.GET_ORDER_DATE(payload__));
        // const response = {};
        setentry({
          ...entry,
          dosetime: newValue ?? "",
          orderStartDate: response?.payload?.data?.orderStartDate || 1,
          orderEndDate: response?.payload?.data?.orderEndDate || 1,
        });
      } else {
        setentry({
          ...entry,
          dosetime: newValue ?? "",
        });
      }
    } else if (type === "doseOption") {
      const granularity = newValue?.label.toLowerCase();
      const start__ = new Date();
      const end__ = add(start__, { [granularity]: parseInt(entry?.dosetime) });
      const payload__ = {
        FrequencyType: entry.unitsTypes?.freqCode,
        DurationType: newValue?.label,
        DurationValue: parseInt(entry?.dosetime),
        //FIXME -
        OrderStartDate: start__
          ? getUnixTime(start__)
          : getUnixTime(new Date()),
        OrderEndDate: end__ ? getUnixTime(end__) : getUnixTime(new Date()),
      };
      if (entry.unitsTypes?.freqCode && newValue?.label && entry?.dosetime) {
        const response = await dispatch(actions.GET_ORDER_DATE(payload__));
        // const response = {};
        setentry({
          ...entry,
          doseOption: newValue ?? "",
          orderStartDate: response?.payload?.data?.orderStartDate || 1,
          orderEndDate: response?.payload?.data?.orderEndDate || 1,
        });
      } else {
        setentry({
          ...entry,
          doseOption: newValue ?? "",
        });
      }
    } else if (type === "unitsTypes") {
      const granularity = entry?.doseOption?.label.toLowerCase();
      const start__ = new Date();
      const end__ = add(start__, { [granularity]: parseInt(entry?.dosetime) });
      const payload__ = {
        FrequencyType: newValue?.freqCode,
        DurationType: entry?.doseOption?.label,
        DurationValue: parseInt(entry?.dosetime),
        //FIXME -
        OrderStartDate: start__
          ? getUnixTime(start__)
          : getUnixTime(new Date()),
        OrderEndDate: end__ ? getUnixTime(end__) : getUnixTime(new Date()),
      };
      if (newValue?.freqCode && entry?.doseOption?.label && entry?.dosetime) {
        const response = await dispatch(actions.GET_ORDER_DATE(payload__));
        // const response = {};
        setentry({
          ...entry,
          unitsTypes: newValue ?? "",
          orderStartDate: response?.payload?.data?.orderStartDate || 1,
          orderEndDate: response?.payload?.data?.orderEndDate || 1,
        });
      } else {
        setentry({
          ...entry,
          unitsTypes: newValue ?? "",
        });
      }
    }
  };

  const AddMedication = async (ruleValidated) => {
    //debugger
    if (
      entry?.name &&
      entry?.doseMeasurementValue &&
      entry?.unitValue &&
      entry?.unitsTypes &&
      entry?.dosetime &&
      entry?.doseOption
    ) {
      const res___ = await dispatch(actions.GET_ORDERED_STATUS_ID());
      console.log("res___", res___);
      let status = stateList?.status_masters?.data.filter(
        (val) => val.label === "Draft"
      )[0];
      let payload = {
        // practitionerid: localStorage.getItem("practitionerid"),
        // roleid: localStorage.getItem("roleid"),
        // dispensing_pharmacy: localStorage.getItem("locationId"),
        name: entry?.name,
        orgId: personData?.[0]?.OrgID?.[0],
        ORGID: personData?.[0]?.org_id,
        patientid: props?.pID ?? patient_getData?.patient_id ?? patient_getData?.patientDetails?.patientId, //encounter?.data?.pID,
        confirmedby: personData?.[0]?._id,
        // encountertype: "op",
        // encountertype:
        //   encounter_banner?.filter((v) => v?._id === encounter?.data?.eId)?.[0]
        //     ?.type_id?.[0] ?? "",
        encountertype: patient_getData?.visit_type?._id ?? "",
        encounterid: props?.encounterID ?? patient_getData?._id ?? patient_getData?.encounterId, //encounter?.data?.eId,
        createdName: makeName(personData?.[0]?.name?.[0] || {}) + " (You)",
        createdAt: getData(new Date()),
        unitValue: entry?.unitValue,
        formCode: entry?.name?.FormCode ?? "",
        // status: status?.value,
        status: res___?.payload?.data?.result?.[0]?._id ?? "CodingMaster/10704", //"CodingMaster/10704",
        // workFlowStatus: status?.code,
        unitsTypes: entry?.unitsTypes,
        doseMeasurementValue: entry?.doseMeasurementValue,
        doseOption: entry?.doseOption,
        dosetime: entry?.dosetime,
        occurStartDateTime: moment().unix(),
        occurEndDateTime: moment()
          .add(parseInt(entry?.dosetime), entry?.doseOption?.label)
          .unix(),
        createdby: personData?.[0]._id,
        updatedby: personData?.[0]._id,
        id: 0,
        // order_status: "Order Pending",
        drug_category: entry?.drug_category,
        DefaultRouteCode: entry?.DefaultRouteCode,
        orderEndDate: entry?.orderEndDate,
        orderStartDate: entry?.orderStartDate,
        alertData: alertData_,
      };

      // let alertText = null;
      // if (!ruleValidated) {
      // 	let alertData = await dispatch(actions.GETALERTWITHMEDI({
      // 		orgId: props.propsData.loggedUserInfo.data.org_id,
      // 		rulePayload: payload,
      // 		pracId: props.propsData.loggedUserInfo.data.practioner_id,
      // 		patId: encounter?.data?.pID
      // 	}))
      // 	let alertdetails = alertData.payload.data;
      // 	if (alertdetails.type && typeof alertdetails.type === "number" && alertdetails.type !== 2) {
      // 		setAlertData({
      // 			open: true, data: alertdetails
      // 		})
      // 		return false;
      // 	} else if (alertdetails.type === 2) {
      // 		alertText = alertdetails.alertText
      // 	}
      // }
      // payload.infoAlertText = alertText;
      props?.backDrop?.setBackDrop({
        ...props?.backDrop,
        open: true,
        message: "Adding New Medication",
      });

      // await dispatch(actions.SAVE_MEDICATIONS(payload))
      //const res____ = await dispatch(actions.SAVE_NIFI_MEDICATIONS(payload)); //NIFI SERVICE CALL
      const res____ = await dispatch(actions.SAVE_MEDICATIONS(payload));
      // payload.editId = res.payload.data;
      // payload.status = status;
      // list.push(payload);
      //const { pId } = props?.location?.state;
      let res_ = await dispatch(
        actions.GET_MEDICATIONS({ id: props?.encounterID ?? patient_getData?._id ?? patient_getData?.encounterId })
      );
      console.log("res_", res_);
      let data = generateJson(res_.payload.data, stateList);
      setMedication({ ...props.medicationsData, list: data });
      setentry({
        ...entry,
        name: null,
        unitValue: null,
        unitsTypes: null,
        doseMeasurementValue: null,
        doseOption: null,
        dosetime: null,
        headerShow: !entry.headerShow,
        orderStartDate: null,
        orderEndDate: null,
      });
      setAddForm({
        agree: false,
      });
      props?.backDrop?.setBackDrop({
        ...props?.backDrop,
        open: false,
        message: "",
      });
    } else {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all the mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
  };
  const getPress = (value, stateName) => {
    let data = null;
    if (!data && value === "presMode") {
      data = stateList.OrderMode_masters.data.filter(
        (val) => val.label === "Regular"
      )[0];
    }
    return data;
  };

  const alertData = {
    orgId: personData?.[0]?.org_id,
    patientid: patient_getData?.patient_id, //encounter?.data?.pID,
    encounterid: patient_getData?._id, //encounter?.data?.eId,
    encountertype: patient_getData?.patient_id, //encounter?.data?.type,
    presType: getPress("presMode", "OrderMode_masters"),
    rxDate: moment().unix(),
    rxId: "",
    orgEntityType: personData?.[0]?.orgType,
    overrideById: loggedUserInfo?.data?.practioner_id,
  };

  const onEditChart = (index) => {
    setMedication({ ...props?.medicationsData, editIndex: index });
    props.onEditChart(props?.val, "medication_form");
  };

  const handleModal = (val) => {
    setModal(val);
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [alertTextValue, setAlertTextValue] = useState("");
  const [addForm, setAddForm] = useState({
    agree: false,
  });
  const handleAddForm = (name, value) => {
    setAddForm({ ...addForm, [name]: value });
  };

  // const addSecondary = () => {
  //   setDialogOpen(true)
  // }
  const closeSecondary = () => {
    setDialogOpen(false);
  };

  const handleCloseForm = () => {
    if (list.length > 0) {
      setentry({
        ...entry,
        name: null,
        unitValue: null,
        unitsTypes: null,
        doseMeasurementValue: null,
        doseOption: null,
        dosetime: null,
        headerShow: !entry.headerShow,
        orderStartDate: null,
        orderEndDate: null,
      });
    } else {
      props.handleClose("medication");
    }
  };

  const { parent_id } = props;
  return (
    <>
      <div
        id={`${parent_id}_medication_order_parent_div`}
        style={{ width: "100%" }}
      >
        <Row
          id={`${parent_id}_medication_order_parent_row`}
          inLineStyles={styles.vitailsCard}
        >
          {/* Add a new allergy data's */}
          <Col
            id={`${parent_id}_medication_order_parent_col`}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            inLineStyles={
              entry.headerShow ? styles.vitalsHead : styles.vitalsHead1
            }
          >
            {entry.headerShow ? (
              <div style={styles.vitalsfieldHead}>
                <>
                <div
                id={`${parent_id}_medication_order_headerShow_div`}
                style={{
                  display: "flex",
                  width: "99%",
                  justifyContent: "space-between",
                   padding:"12px"
                }}
              >
                <Text
                  id={`${parent_id}_medication_order_headerShow_text`}
                  // onClick={() =>
                  //   handleInputChange(!entry.headerShow, "headerShow")
                  // }
                  inLineStyles={{
                    fontWeight: 600,
                    fontSize: "14px",
                    cursor: "pointer",
                    color: UIColor.primaryColor.color,
                    alignItems: "center",
                    marginLeft: "-7px",

                  }}
                >
                  {"Medication Order"}
                </Text>
              </div>
              <Divider orientation="horizontal" variant="fullWidth" style={{ margin: "5px -10px", height: "0.1px",width: "28.7cm" }} />
              <Grid style={{display:"flex",gap:"10px", padding:"15px"}}>
                  <div
                    id={`${parent_id}_medication_order_title_div`}
                    style={{ marginRight: 16 }}
                  >
                   
                    <Autocomplete
                      id={`${parent_id}_medication_order_autocomplete`}
                      className={classes.autocomplete}
                      style={{ width: 210 }}
                      size="small"
                      // options={stateList?.drugMaster_masters.data}//
                      options={masters}
                      popupIcon={
                        <ExpandMoreIcon
                          fontSize="small"
                          style={{ color: "#b4aeae" }}
                        />
                      }
                      disableClearable
                      handleHomeEndKeys
                      selectOnFocus
                      clearOnBlur
                      freeSolo
                      getOptionLabel={(option) => option.label}
                      // getOptionLabel={(option) => {
                      // 	// Value selected with enter, right from the input
                      // 	if (typeof option === "string") {
                      // 	  return option;
                      // 	}
                      // 	// Add "xxx" option created dynamically
                      // 	if (option.inputValue) {
                      // 	  return option.inputValue;
                      // 	}
                      // 	// Regular option
                      // 	return option.label;
                      //   }}
                      renderInput={(params) => (
                        <TextField
                          id={`${parent_id}_medication_order_Search_textfield`}
                          {...params}
                          variant="standard"
                          placeholder="Search Medication order"
                        />
                      )}
                     
                      renderOption={(option) => (
                        <Typography
                          id={`${parent_id}_medication_order_Search_${(
                            option.label || ""
                          ).replaceAll(" ", "_")}_typography`}
                          style={{
                            width: "100%",
                            fontSize: 14,
                            color: UIColor.secondaryText.color,
                          }}
                          noWrap
                        >
                          {option.label}
                        </Typography>
                      )}
                      classes={{ popper: "muiPopperDropdown" }}
                      value={entry?.name}
                      // onChange={(e, newValue) =>
                      // 	handleInputChange(newValue, "name")
                      // }
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          setentry({
                            ...entry,
                            name: {
                              label: newValue,
                            },
                          });
                        } else if (newValue && newValue?.inputValue) {
                          // Create a new value from the user input
                          setentry({
                            ...entry,
                            name: {
                              label: newValue,
                            },
                          });
                        } else {
                          occureStartDateEndDate("name", newValue);
                        }
                      }}
                      onInputChange={(e) =>
                        handleMedication(e?.target?.value ?? "")
                      }
                    />
                  </div>
                  {!(entry?.name?.drugType === "IV") && (
                    <React.Fragment>
                      <div
                        id={`${parent_id}_medication_order_Dosage_div`}
                        style={{ marginRight: 14, }}
                      >
                        <TextInput 
                          id={`${parent_id}_medication_order_Dosage_textinput`}
                          type="text"
                          dateIcon="images/icons8-calendar-grey.svg"
                          label={""}
                          placeholder={"Dosage"}
                          style={styles.borderStyle}
                          inLineStyles={styles.borderStyle}
                          // hoverColor={UIColor.lineBorderFill.color}
                          value={entry.doseMeasurementValue}
                          variant={"standard"}
                          onChange={(e, newValue) => {
                            console.clear();
                            console.log(e.target.value);
                            if (
                              parseInt(e.target.value) >= 0 ||
                              e.target.value === ""
                            ) {
                              handleInputChange(
                                e.target.value,
                                "doseMeasurementValue"
                              );
                            }
                          }}
                        />
                      </div>
                      <div
                        id={`${parent_id}_medication_order_unit_div`}
                        style={{ marginRight: 16 }}
                      >
                        <Autocomplete
                          id={`${parent_id}_medication_order_unit_autocomplete`}
                          className={classes.autocomplete}
                          style={{ width: 60 }}
                          size="small"
                          options={stateList?.strengthUom_masters.data}
                          popupIcon={
                            <ExpandMoreIcon
                              fontSize="small"
                              style={{ color: "#b4aeae" }}
                            />
                          }
                          disableClearable
                          getOptionLabel={(option) => option.label}
                          renderInput={(params) => (
                            <TextField
                              id={`${parent_id}_medication_order_unit_textfield`}
                              {...params}
                              placeholder={"Unit"}
                              variant="standard"
                            />
                          )}
                          renderOption={(option) => (
                            <Typography
                              id={`${parent_id}_medication_order_${(
                                option.label || ""
                              ).replaceAll(" ", "_")}_typography`}
                              style={{
                                width: "100%",
                                fontSize: 14,
                                color: UIColor.secondaryText.color,
                              }}
                              noWrap
                            >
                              {option.label}
                            </Typography>
                          )}
                          classes={{ popper: "muiPopperDropdown" }}
                          value={entry.unitValue}
                          onChange={(e, newValue) =>
                            handleInputChange(newValue, "unitValue")
                          }
                        />
                      </div>
                      <div
                        id={`${parent_id}_medication_order_Frequency_div`}
                        style={{ marginRight: 16 }}
                      >
                        <Autocomplete
                          id={`${parent_id}_medication_order_Frequency_autocomplete`}
                          className={classes.autocomplete}
                          style={{ width: 130 }}
                          size="small"
                          options={stateList.FrequencyMaster_masters?.data}
                          popupIcon={
                            <ExpandMoreIcon
                              fontSize="small"
                              style={{ color: "#b4aeae" }}
                            />
                          }
                          disableClearable
                          getOptionLabel={(option) => option.label}
                          renderInput={(params) => (
                            <TextField
                              id={`${parent_id}_medication_order_Frequency_textfield`}
                              {...params}
                              variant="standard"
                              placeholder="Frequency"
                            />
                          )}
                          renderOption={(option) => (
                            <Typography
                              id={`${parent_id}_medication_order_${option.label.replaceAll(
                                " ",
                                "_"
                              )}_typography`}
                              style={{
                                width: "100%",
                                fontSize: 14,
                                color: UIColor.secondaryText.color,
                              }}
                              noWrap
                            >
                              {option.label}
                            </Typography>
                          )}
                          classes={{ popper: "muiPopperDropdown" }}
                          value={entry.unitsTypes}
                          onChange={(e, newValue) => {
                            handleInputChange(newValue, "unitsTypes");
                            occureStartDateEndDate("unitsTypes", newValue);
                          }}
                        />
                      </div>
                      <div
                        id={`${parent_id}_medication_order_text_div`}
                        style={styles.CalendarStyle}
                      >
                        <TextInput
                          id={`${parent_id}_medication_order_calender_textinput`}
                          type="text"
                          dateIcon="images/icons8-calendar-grey.svg"
                          label={""}
                          placeholder={"Duration"}
                          style={styles.borderStyleCal}
                          inLineStyles={styles.borderStyleCal}
                          // hoverColor={UIColor.lineBorderFill.color}
                          value={entry.dosetime}
                          variant={"standard"}
                          onChange={(e, newValue) => {
                            if (
                              parseInt(e.target.value) >= 0 ||
                              e.target.value === ""
                            ) {
                              handleInputChange(e.target.value, "dosetime");
                              occureStartDateEndDate(
                                "dosetime",
                                e.target.value
                              );
                            }
                          }}
                        />
                      </div>
                      <div
                        id={`${parent_id}_medication_order_Month_div`}
                        style={{ marginRight: 16 }}
                      >
                        <Autocomplete
                          style={{ width: 100 }}
                          className={classes.autocomplete}
                          id={`${parent_id}_medication_order_Month_autocomplete`}
                          size="small"
                          options={stateList?.DurationType_masters.data}
                          popupIcon={
                            <ExpandMoreIcon
                              fontSize="small"
                              style={{ color: "#b4aeae" }}
                            />
                          }
                          disableClearable
                          getOptionLabel={(option) => option.label}
                          renderInput={(params) => (
                            <TextField
                              id={`${parent_id}_medication_order_Month_textfield`}
                              {...params}
                              placeholder={"Month"}
                              variant="standard"
                            />
                          )}
                          renderOption={(option) => (
                            <Typography
                              id={`${parent_id}_medication_order_Month_${(
                                option.label || ""
                              ).replaceAll(" ", "_")}`}
                              style={{
                                width: "100%",
                                fontSize: 14,
                                color: UIColor.secondaryText.color,
                              }}
                              noWrap
                            >
                              {option.label}
                            </Typography>
                          )}
                          classes={{ popper: "muiPopperDropdown" }}
                          value={entry.doseOption}
                          onChange={(e, newValue) => {
                            handleInputChange(newValue, "doseOption");
                            occureStartDateEndDate("doseOption", newValue);
                          }}
                        />
                      </div>
                    </React.Fragment>
                  )}
                  <Grid>
                  <Button
                      id={`${parent_id}_medication_order_add_button`}
                      onClick={() => AddMedication()}
                      variant={"text"}
                      style={{
                        textAlign: "center",
                        color: UIColor.primaryColor.color,
                        fontWeight: 600,
                        minWidth: "1%",
                        padding: "5px",
                        marginRight: "10px",
                      }}
                      size={"small"}
                    >
                      <img src={Tick} alt="Add" />
                    </Button>
                    <Button
                      id={`${parent_id}_allergy_add_button`}
                      onClick={() => handleCloseForm()}
                      variant={"text"}
                      style={{
                        color: UIColor.primaryColor.color,
                        fontWeight: 600,
                        minWidth: "30%",
                        padding: "5px",
                      }}
                      size={"small"}
                    >
                      <img src={Cancel} alt="Cancel" />
                    </Button>
                  </Grid>
                   
              </Grid>
                </>
              
              </div>
            ) : (
              <div
                id={`${parent_id}_medication_order_headerShow_div`}
                style={{
                  display: "flex",
                  width: "99%",
                  justifyContent: "space-between",
                }}
              >
                <Text
                  id={`${parent_id}_medication_order_headerShow_text`}
                  onClick={() =>
                    handleInputChange(!entry.headerShow, "headerShow")
                  }
                  inLineStyles={{
                    fontWeight: 600,
                    fontSize: "14px",
                    cursor: "pointer",
                    color: UIColor.primaryColor.color,
                  }}
                >
                  {"Medication order"}
                </Text>
                <div
                  onClick={() =>
                    handleInputChange(!entry.headerShow, "headerShow")
                  }
                  style={{ cursor: "pointer", display: "flex" }}
                >
                  <img src={Addicon} alt="Add" />
                </div>
              </div>
            )}
            <div
              id={`${parent_id}_medication_order_add_parent_div`}
              style={styles.flexFlow}
            >
              {entry.headerShow && (
                <>
                  {/* {entry.name?.drugType === "IV" ||
								(entry.name 
									&&
									entry.unitValue &&
									entry.doseMeasurementValue &&
									entry.unitsTypes &&
									entry.doseOption &&
									entry.dosetime
									) ? ( */}
                  <div
                    id={`${parent_id}_medication_order_add_div`}
                    style={{
                      width: "100%",
                      textAlign: "center",
                      display: "flex",
                      marginRight: "10px",
                    }}
                  >
                    {/* removing add button */}
                    {/* <Button
                      id={`${parent_id}_medication_order_add_button`}
                      onClick={() => AddMedication()}
                      variant={"text"}
                      style={{
                        textAlign: "center",
                        color: UIColor.primaryColor.color,
                        fontWeight: 600,
                        minWidth: "30%",
                        padding: "5px",
                        marginRight: "10px",
                      }}
                      size={"small"}
                    >
                      <img src={Tick} alt="Add" />
                    </Button> */}
                    {/* <Button
                      id={`${parent_id}_allergy_add_button`}
                      onClick={() => handleCloseForm()}
                      variant={"text"}
                      style={{
                        color: UIColor.primaryColor.color,
                        fontWeight: 600,
                        minWidth: "30%",
                        padding: "5px",
                      }}
                      size={"small"}
                    >
                      <img src={Cancel} alt="Cancel" />
                    </Button> */}
                  </div>
                  {/* // ) : (
								// 	<div */}
                  {/* // 		id={`${parent_id}_medication_order_add_div`}
								// 		style={{ width: "100%", textAlign: "center" }}
								// 	>
								// 		<Button
								// 		id={`${parent_id}_allergy_add_button`}
								// 		onClick={() => handleCloseForm()}
								// 		variant={"text"}
								// 		style={{
								// 		color: UIColor.primaryColor.color,
								// 			fontWeight: 600,
								// 		}}
								// 		size={"small"}
								// 		>
								// 		<img src={Cancel} alt="Cancel" />
								// 		</Button>   
								// 	</div>
								// )} */}
                </>
              )}
              {/* <Tooltip
							id={`${parent_id}_medication_order_Insights_tooltip`}
							title={"Insights"}
						>
							<div
								id={`${parent_id}_medication_order_Insights_div`}
								style={{ ...styles.infoBell, ...styles.icons }}
							>
								<InsightsIcon
									id={`${parent_id}_medication_order_Insights_icon`}
									style={{ marginLeft: 5 }}
									// color={UIColor.primaryColor.color}
								/>
							</div>
						</Tooltip> */}
            </div>
          </Col>
          <Popover
            open={Boolean(infoText)}
            anchorEl={infoText}
            onClose={() => setInfoText(null)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Typography
              style={{ padding: 7, background: "lightgrey", color: "black" }}
            >
              {" "}
              {alertTextValue}
            </Typography>
          </Popover>
          {/* allergy list */}
          {list.length > 0 &&
            list.map((val, i) => {
              if (list.length - 1 === i) {
              }
              return (
                <Col
                  id={`${parent_id}_medication_order_${i}_col`}
                  key={i}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={"allergyListHover_medication"}
                  inLineStyles={{
                    padding: "10px 8px",
                    borderTop: `1px solid ${UIColor.lineBorderFill.color}`,
                  }}
                >
                  <Row id={`${parent_id}_medication_order_${i}_row`}>
                    <Col
                      id={`${parent_id}_medication_order_${i}_sub_col`}
                      xs={12}
                      sm={12}
                      md={8}
                      lg={8}
                      xl={8}
                    >
                      {val.name && (
                        <H6
                          id={`${parent_id}_medication_order_${i}_${(
                            val.name?.name || ""
                          )?.replaceAll(" ", "_")}_h6`}
                          inLineStyles={{
                            fontSize: 14,
                            paddingBottom: 6,
                            color: UIColor.secondaryButtonColor.color,
                            fontWeight: 500,
                            fontFamily: "pc_regular",
                          }}
                        >
                          {val.name?.name}
                          {val?.infoAlertText ? (
                            <ErrorOutlineIcon
                              onClick={(event) => {
                                setInfoText(event.currentTarget);
                                setAlertTextValue(val?.infoAlertText);
                              }}
                              style={{
                                width: "17px",
                                height: "14px",
                                color: "#464242",
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </H6>
                      )}
                      <div
                        id={`${parent_id}_medication_order_${i}_div`}
                        style={{ display: "flex" }}
                      >
                        {(val.name || val?.drugType) && (
                          <Text
                            id={`${parent_id}_medication_order_${i}_${(
                              val.name?.drugType || ""
                            )?.replaceAll(" ", "_")}_text`}
                            inLineStyles={styles.textColor}
                            style={{ display: "flex", padding: 0 }}
                          >
                            {val?.drugType?.shortdesc ??
                              val.name?.drugTypeShort ??
                              " "}
                          </Text>
                        )}
                        {(val.doseMeasurementValue ||
                          val.doseMeasurementValue === 0) && (
                          <Text
                            id={`${parent_id}_medication_order_${i}_${val.doseMeasurementValue}_text`}
                            inLineStyles={styles.textColor}
                          >
                            {val.name?.drugTypeShort ? " - " : ""}
                            {val.doseMeasurementValue}
                          </Text>
                        )}
                        {val.unitValue && (
                          <Text
                            id={`${parent_id}_medication_order_${i}_${(
                              val.unitValue?.label || ""
                            ).replaceAll(" ", "_")}_text`}
                            inLineStyles={styles.textColor}
                          >
                            {val.unitValue?.label}
                          </Text>
                        )}
                        {val.unitsTypes && (
                          <Text
                            id={`${parent_id}_medication_order_${i}_${(
                              val.unitsTypes?.label || ""
                            ).replaceAll(" ", "_")}_text`}
                            inLineStyles={styles.textColor}
                          >
                            {" - "}
                            {val.unitsTypes?.label}
                          </Text>
                        )}
                        {val.dosetime && (
                          <Text
                            id={`${parent_id}_medication_order_${i}_${val.dosetime}_text`}
                            inLineStyles={styles.textColor}
                          >
                            {" - "}
                            {val.dosetime}
                          </Text>
                        )}
                        {val.doseOption && (
                          <Text
                            id={`${parent_id}_medication_order_${i}_${(
                              val.doseOption?.label || ""
                            ).replaceAll(" ", "_")}_text`}
                            inLineStyles={styles.textColor}
                          >
                            {val.doseOption.label}
                          </Text>
                        )}
                        {val.status?.label && (
                          <Text
                            id={`${parent_id}_medication_order_${i}_${(
                              val.status?.label || ""
                            ).replaceAll(" ", "_")}_text`}
                            onClick={(e) => {
                              if (!isFirstVisit) {
                                handleOpenStatus(e, val, i);
                              }
                            }}
                            inLineStyles={{
                              ...styles.statusColor,
                              color: isFirstVisit
                                ? ""
                                : statusColors?.[val.status?.label]?.color,
                            }}
                          >
                            {" - "}
                            <Text
                              id={`${parent_id}_medication_order_${i}_${
                                val.status?.label ?? "".replaceAll(" ", "_")
                              }_text`}
                              inLineStyles={{
                                ...styles.statusColor,
                                color: statusColors?.[val.status?.label]?.color,
                              }}
                            >
                              {val.status?.label ?? ""}
                            </Text>
                          </Text>
                        )}
                      </div>
                    </Col>
                    <Col
                      id={`${parent_id}_medication_order_${i}_userInfoEdit_col`}
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      xl={4}
                    >
                      <Div
                        id={`${parent_id}_medication_order_${i}_userInfoEdit_div`}
                        className={"userInfoEdit"}
                        style={{ right: open ? 10 : -107 }}
                      >
                        <div
                          id={`${parent_id}_medication_order_${i}_${(
                            val.createdAt || ""
                          ).replaceAll(" ", "_")}_div`}
                          style={styles.userInfo}
                          className={"userHide"}
                        >
                          <Text
                            id={`${parent_id}_medication_order_${i}_${(
                              val.createdAt || ""
                            ).replaceAll(" ", "_")}_text`}
                            inLineStyles={styles.textColor}
                            style={{ marginBottom: 8 }}
                          >
                            {"On "}
                            {val.createdAt}
                          </Text>
                          <Text
                            id={`${parent_id}_medication_order_${i}_${(
                              val.createdName || ""
                            ).replaceAll(" ", "_")}_text`}
                            inLineStyles={styles.textColor}
                          >
                            {getOrderName(
                              { accessionNum: val?.createdName },
                              loggedUserInfo?.data?.completeName || ""
                            )}
                          </Text>
                        </div>
                        <div
                          id={`${parent_id}_medication_order_${i}_delete_edit_div`}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: 24,
                          }}
                        >
                          {/* <Div
														id={`${parent_id}_medication_order_${i}_delete_edit_sub_div`}
														style={{
															height: 20,
															width: 1,
															backgroundColor: "rgb(224, 224, 224)",
														}}
													/> */}
                          <Text
                            id={`${parent_id}_medication_order_${i}_delete_text`}
                            inLineStyles={{
                              ...styles.editAllergyIcon,
                              marginLeft: 10,
                            }}
                            onClick={() => {
                              handleModal(true);
                              setDeleteDetails({
                                index: i,
                                data: val,
                              });
                            }}
                          >
                            {/* <Icons
														id={`${parent_id}_medication_order_${i}_delete_icons`}
														fontIcon="trash"
														style={{ color: UIColor.error.color }}
													/> */}
                            <Image
                              src={icons8trash}
                              alt="delete_icon"

                              // style={{ width: 100, height: 35 }}
                            />
                          </Text>
                          <Text
                            id={`${parent_id}_medication_order_${i}_edit_text`}
                            inLineStyles={{
                              ...styles.editAllergyIcon,
                              marginLeft: 5,
                            }}
                            onClick={() => onEditChart(i)}
                          >
                            {/* <Icons
														id={`${parent_id}_medication_order_${i}_edit_icons`}
														fontIcon={"pencil"}
													/> */}

                            <Image
                              src={icons8edit}
                              alt="edit_icon"
                              // style={{ width: 100, height: 35 }}
                            />
                          </Text>{" "}
                        </div>
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                          classes={{ paper: classes.muiPopovers }}
                        >
                          <RequestorDetails
                            parent_id={"medication_order" + i}
                            name="Johnny Stewart"
                            pidNumber="PID0024"
                            role="Consultant"
                            hospital="Apollo Hospital Chennai - Gundiy."
                            profile_pic="https://thumbs.dreamstime.com/b/portrait-charming-imposing-kid-private-school-boy-feel-glad-ready-work-worker-want-be-cool-professional-look-hear-wear-blazer-152393107.jpg"
                          />
                        </Popover>
                      </Div>
                    </Col>
                  </Row>
                </Col>
              );
            })}
        </Row>
        <Modal
          id={`${parent_id}_medication_order_modal`}
          open={modal}
          onClose={() => handleModal(false)}
          width={250}
          inLineStyles={styles.modal}
        >
          <Card
            id={`${parent_id}_medication_order_parent_card`}
            style={styles.modalCard}
          >
            <H6
              id={`${parent_id}_medication_order_remove_this_h6`}
              className="pc_semibold"
              style={styles.modalHeading}
            >
              Are you sure want to remove this?
            </H6>
            <H6
              id={`${parent_id}_medication_order_the_order_h6`}
              className="pc_regular"
              style={styles.modalSubTitle}
            >
              Some content about the medication order
            </H6>
            <Button
              id={`${parent_id}_medication_order_Yes_remove_button`}
              variant="contained"
              fullWidth
              onClick={() => DeleteFun()}
              style={styles.containedButton}
            >
              Yes remove
            </Button>
            <Button
              id={`${parent_id}_medication_order_No_button`}
              variant="outlined"
              fullWidth
              onClick={() => handleModal(false)}
              style={styles.outlinedButton}
            >
              No
            </Button>
          </Card>
        </Modal>

        <Popover
          id={"calender-popover"}
          open={Openstatus}
          anchorEl={Openstatus}
          onClose={() => {
            setOpenstatus(null);
            setStatusDetails({});
          }}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          classes={{ paper: styles.muiPopovers }}
        >
          <ChangeStatus
            parent_id={"medication_order"}
            cancelButton="Cancel"
            cancelButtonAction={() => setOpenstatus(null)}
            saveButton="Save"
            saveButtonAction={(data, reason, statusperiod) => {
              handleSaveStatus(data, reason, statusperiod);
            }}
            statusOption={stateList?.status_masters?.data}
            changeStatusData={statusDetails?.status}
            statusReason={statusDetails?.statusReason}
            statusperiod={statusDetails?.statusperiod}
            statusHistory={statusDetails.statushistory ?? []}
          />
        </Popover>
        <Dialog
          onClose={closeSecondary}
          open={dialogOpen}
          classes={{ paper: classes.paper }}
        >
          <AlleryDrugModel
            AddMedication={AddMedication}
            handleFDB={AddMedication}
            closeSecondary={closeSecondary}
            handleAddForm={handleAddForm}
            data={alertData}
            addForm={addForm}
            interationsAlert={interationsAlert}
          />
        </Dialog>
        {alertDetails.open && (
          <AlertsRestriction {...alertDetails} handleClose={closeAlertModal} />
        )}
      </div>

      <AlertDialog
        alertDialogOpen={alertDialogOpen}
        handleCloseAlertDialog={handleCloseAlertDialog}
        medicationSlice={alertData_}
        isHide={false}
        selectedState={true}
      />
    </>
  );
};
export default withAllContexts(MedicationOrderFrom);
