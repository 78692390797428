import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import {
  dbName,
  defaultReject,
  defaultState,
  fetchData,
  urls,
  query_ids,
  utcTOLocal,
  timeLine_,
  getTenantId,
} from "../../utils";

const MULTIRESOURCE_SERVICE_GET = createAsyncThunk(
  `multiResourceReducer/multiresource_service_get`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const read_p = {
        db_name: dbName,
        filter: {
          _id: payload,
        },
        queryid: "866b95d4-0b4f-4f65-bd72-b30966764c11",
      };

      const data = await fetchData(
        {
          body: JSON.stringify(read_p),
        },
        process?.env?.REACT_APP_ARANGO_URL_READ_QDMQUERIES
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const RESOURCE_GET = createAsyncThunk(
  `multiResourceReducer/resource_get`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const read_p = {
        db_name: dbName,
        filter: {
          chargecodeid: payload,
        },
        queryid: "fe02df9c-24bc-4916-9746-57e6150a077c",
      };

      const data = await fetchData(
        {
          body: JSON.stringify(read_p),
        },
        process?.env?.REACT_APP_ARANGO_URL_READ_QDMQUERIES
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const SAVE_MULTIRESOURCE_BOOKING = createAsyncThunk(
  `multiResourceReducer/save_multiresource_booking`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const read_p = [
        {
          db_name: dbName,
          entity: "Appointment",
          is_metadata: true,
          metadataId: process?.env?.REACT_APP_METADATAID,
          metadata_dbname: process?.env?.REACT_APP_METADATA_DBNAME,
          filter: {},
          doc: {
            consultationMode: "CodingMaster/10167",
            status: true,
            cancelationReason: [],
            reasonCode: [],
            priority: 1,
            start: payload?.start,
            end: payload?.end,
            minutesDuration: payload?.durationInMinutes,
            comment: "",
            appointmentReason: [],
            slotID: [],
            SpecialtyID: payload?.SpecialtyID,
            appointmentTypeID: payload?.appointmentTypeID,
            resourcedetails: payload?.array,
            resourcetype: "",
            resourcerole: "",
            resourcecode: "",
            orgid: payload?.facilityId,
            orgtype: "",
            PersonID: payload?.personId,
            report_docs: [],
            appno: "",
            patient_id: payload?.patientId,
            facilityid: payload?.facilityId,
            tenantid: payload?.tenantid,
            AppointmentNotification: [
              {
                practitionerEmail: "",
                mobile:payload?.mobile,
                patientEmail: "",
              },
            ],
            bookedby: "",
            modeofappointment: "CodingMaster/11383",
          },
        },
      ];

      const data = await fetchData(
        {
          body: JSON.stringify(read_p),
        },urls?.bookAppointment
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const multiResourceSlice = createSlice({
  name: "multiResourceReducer",
  initialState: {
    serviceDetails: {
      ...defaultState.Info,
    },
    resourceGetDetails: {
      ...defaultState.Info,
    },
    saveMultiresourceDetails: {
      ...defaultState.Info,
    },
  },
  extraReducers: {
    /* PATIENT_DETAILS_READ */
    [MULTIRESOURCE_SERVICE_GET.fulfilled]: (state, action) => {
      state.serviceDetails = action?.payload ?? {};
    },
    [MULTIRESOURCE_SERVICE_GET.pending]: (state, action) => {
      state.serviceDetails.loading = true;
    },
    [MULTIRESOURCE_SERVICE_GET.rejected]: (state, action) => {
      state.serviceDetails = action?.payload ?? {};
    },

    [RESOURCE_GET.fulfilled]: (state, action) => {
      state.resourceGetDetails = action?.payload ?? {};
    },
    [RESOURCE_GET.pending]: (state, action) => {
      state.resourceGetDetails.loading = true;
    },
    [RESOURCE_GET.rejected]: (state, action) => {
      state.resourceGetDetails = action?.payload ?? {};
    },

    [SAVE_MULTIRESOURCE_BOOKING.fulfilled]: (state, action) => {
      state.saveMultiresourceDetails = action?.payload ?? {};
    },
    [SAVE_MULTIRESOURCE_BOOKING.pending]: (state, action) => {
      state.saveMultiresourceDetails.loading = true;
    },
    [SAVE_MULTIRESOURCE_BOOKING.rejected]: (state, action) => {
      state.saveMultiresourceDetails = action?.payload ?? {};
    },
  },
});
export const multiResourceActions = {
  MULTIRESOURCE_SERVICE_GET,
  RESOURCE_GET,
  SAVE_MULTIRESOURCE_BOOKING,
};
// end

export default multiResourceSlice;
