import React, { useContext } from "react";
import { connect } from "react-redux";
import qdmstyles from "./styles.json";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import { AlertProps, convertTime, fetchData, getAge, getFacilityId, getGlobalValueFrom_LclStrg_And_QryStrg, getImgUrl, makeName, mobileNumberValidation, personRequests, urls } from "../../../utils";
import {
  Container,
  H6,
  Divider,
  Text,
  Div,
  TextInput,
  Button,
  Checkbox,
  Avatar,
  Dropdown,
  MobileNumberWithCode,
} from "qdm-component-library";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useState } from "react";
import { useEffect } from "react";
import { useStyles } from "./style";
import jwtDecode from "jwt-decode";
import Logger from "qdm-log4js";
import { useDispatch } from "react-redux";
import actions from "../../../redux/actions";
import { AlertContext } from "../../../context";
import axios from "axios";

function AppointmentPageDrawer(props) {
  const alertContext = useContext(AlertContext);
  const [state, setState] = useState({
    showMembers: false,
    error: null,
    expanded1: false,
    expanded2: false,
    addmemberlist: null,
    type: "text",
    limit: 20,
    listMmbr: [],
  });
  let log = new Logger();

  const dispatch = useDispatch();
  const [callingcodes,setcallingcodes]=useState([]);
  let cCode = [];
  const decodedUserinfo = getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",isFullObject:true})
  const facilityId = decodedUserinfo?.facilityid ?? "";
  const patientPersonId = decodedUserinfo?.personid || "";
  const handleExpandClick = (val) => {
    console.log(val + ' vimport clsx from "clsx";');
    val === 1
      ? setState({
          ...state,
          expanded1: !state.expanded1,
        })
      : setState({
          expanded2: !state.expanded2,
        });
  };
  const changeState = (key, val) => {
    let value = val;
    if (key === "Aadhaar Card") {
      if (
        value &&
        value.length < state.limit &&
        value.replaceAll("-", "")?.length % 4 === 0
      ) {
        value += "-";
      }
    }

    setState({
      ...state,
      inputValue: value,
    });
  };

  const onKeyDown = (key, e) => {
    if (key === "Aadhaar Card") {
      let value = e.target.value;
      if (e.keyCode === 8) {
        if (value[value.length - 1] === "-") {
          value = value.substring(0, value.length - 2);
          e.preventDefault();
        } else if (value[value.length - 2] === "-") {
          value = value.substring(0, value.length - 1);
          e.preventDefault();
        }
        setState({
          ...state,
          [key]: value,
        });
      } else if (e.keyCode < 48 || e.keyCode > 57) {
        e.preventDefault();
      }
    }
  };
    const getCountryCode = async () => {
    const payload = personRequests.getcountrycode(facilityId);

    const getData = await fetchData(payload, urls.readQdmQueries);
    cCode = [];
    getData?.map((v, i) => {
      let setObj = {
        callingCodes: v?.GeoLocationISDCode?.replace("+", ""),
        name: v?.geogLevelName,
        _id: v?._id,
        altSpellings: "",
        flag: "",
      };
      cCode.push(setObj);
    });
    setcallingcodes(cCode);
  };

  useEffect(() => {
    (async function () {
      await props.GET_COUNTRY_CODE_LIST();
    })();
  }, []);
  useEffect(() => {
    getCountryCode();
  }, []);
  const senOtpMMr = async () => {
    debugger
  const payloadlogin = {
    username: state?.selectData?.telecom?.[0]?.value
};
  let token = localStorage.getItem("Token");
  let register = await axios({
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: `${token}`,
      tenantid: getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"tenantid"}),
    },
    url: urls.generateOtp,
    data: payloadlogin,
  })
    .then((res) => {
      if (res?.data && !res.data.error) {
        const SearchUserData = res
      //   {
      //     "type": "authSlice/generateOTP/fulfilled",
      //     "payload": {
      //         "loading": false,
      //         "error": false,
      //         "data": {
      //             "error": false,
      //             "message": "OTP has been sent successfully to your mobile.",
      //             "response": {
      //                 "request_id": "346c70745a36477a43754c7a",
      //                 "type": "success"
      //             }
      //         },
      //         "message": ""
      //     },
      //     "meta": {
      //         "arg": {
      //             "mobileNo": "+918989898681"
      //         },
      //         "requestId": "vhYPLNn0BLnvGsLpkgEp8"
      //     }
      // }
          if (SearchUserData.data.error) {
            // context.setSnack({
            //   open: true,
            //   severity: AlertProps.severity.error,
            //   msg: SearchUserData.payload.data.errorMessage,
            //   vertical: AlertProps.vertical.top,
            //   horizontal: AlertProps.horizontal.center,
            //   tone: true,
            // });
            return;
          } else {
            const telecomData = state.selectData?.telecom?.find(
              (tel) => tel.system === "CodingMaster/10473" && tel.use === "CodingMaster/10477"
            );
            
            const telecomValue = telecomData?.value || '';
            const [countryCode, phoneNumber] = telecomValue.split(' ', 2);
            
            const signUpData = {
              mobileNo: {
                mobileNo: phoneNumber,
                countryData: {
                  callingCodes: countryCode,
                },
              },
              selectData: state?.selectData,
              id: state?.selectData._id,
              SearchUserData,
              firstName:
              state?.selectData?.name || state?.selectData?.name.text || state?.selectData?.name.given,
              lastName: state?.selectData?.name.family || "",
              dob: "01/01/1990",//state?.selectData?.birthDay,
              gender: state?.selectData?.gender?.display,
              emailId:"",
              //  state?.selectData?.telecom?.find(
              //   (tel) => tel.system === "Email" && tel.use === "Work"
              // )?.value,
              middleName: "",
            };
            props.nextSection("addmemberotp", "addMember", signUpData);
          }
      } else {
        alertContext.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: res.data.message,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      }
    })
    .catch((error) => {
      alertContext.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please contact to admin",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    });
  };
  const errorMessage = (searchType, value) => {
    if (searchType === "PID Number") {
      return "Please enter valid PID Number";
    } else if (searchType === "Aadhaar Card") {
      return "Please enter valid Aadhar Number";
    } else if (searchType === "Mobile Number") {
      if (!value?.countryData?.callingCodes) {
        return "Please select country code";
      } else if (value?.mobileNo?.length !== 10) {
        return "Please enter valid Mobile Number";
      }
    } else {
      return "Please fill all the mandatory fields";
    }
  };
  const handleCheked =async (v, l) => {
    const patientLinkedMemberList = await dispatch(actions.Patient_Linked_Member_LIST({childPersonId:v?._id}));
    if(patientLinkedMemberList?.payload?.data?.length > 0){
      alertContext.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "This person Already Added to Another person ",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
      }else{
    setState({
      ...state,
      addmemberlist: l,
      selectData: v,
    });
  }
  };

  const handleChange = (e) => {
    let type, limit;
    if (e.value === "PID Number") {
      type = "text";
      limit = 20;
    } else if (e.value === "Aadhaar Card") {
      type = "text";
      limit = 14;
    }
    props.handelChangeSelect && props.handelChangeSelect(e);
    setState({
      ...state,
      type,
      limit,
      // [e.value]: "",
      idType:e.value,
    });
  };

  const onChangeStateName = () => {
    return (
      (props.chooseSearch?.label === "PID Number" && "PID Number") ||
      (props.chooseSearch?.label === "Aadhaar Card" && "Aadhaar Card") ||
      (props.chooseSearch?.label === "Emirate ID" && "Emirate ID") ||
      (props.chooseSearch?.label === "SSN Number" && "SSN Number")
    );
  };

  const classes = useStyles();
  const { addmemberlist, parent_id } = state;
  const FlterOptions = (props, type, istrue, index) => {
    let filteredArray = props.filter((obj) => obj.hasOwnProperty([type]));
    let list = [];
    let data = filteredArray[0]?.[type].Value;
    for (let index in data) {
      var datatemp = {
        isdisabled:false,
        value: data[index]?._id,
        label: data[index]?.display,
        _id: data[index]?._id,
      };
      list.push(datatemp);
    }
    return list;
  };
  const searchMember = async () => {
    debugger
    let searchType = props?.chooseSearch?.value;
    let value = state?.inputValue;
    const checkErr = (stateName, val) => {
      if (stateName === "PID Number") {
        return val?.length === 20 ? true : false;
      } else if (stateName === "Aadhaar Card") {
        return val?.length === 14 ? true : false;
      } else if (stateName === "Mobile Number") {
        return val?.countryData?.callingCodes && val?.mobileNo?.length === 10
          ? true
          : false;
      } else {
        return true;
      }
    };

    let checkErrs = checkErr(searchType, value);
    if (searchType && value && checkErrs) {
      const queryData = personRequests.getDetailsAgainstAddMemberSearch(
        value,
        searchType,
        patientPersonId,
      );
      log.event("Patient Search Input", queryData);
      const SearchUserData = await fetchData(queryData, urls.readQdmQueries);
      log.event("Patient Search Output", SearchUserData);

      if (SearchUserData) {
        setState({
          ...state,
          showMembers: true,
          error: null,
          listMmbr: SearchUserData?.[0],
        });
      }
      // setState({
      //   ...state,
      //   showMembers: true,
      //   error: null,
      // });
    } else {
      setState({
        ...state,
        showMembers: false,
        error: errorMessage(searchType, value),
      });
    }
  };
  return (
    <div id={`${parent_id}_addmember_parent_div`}>
      <Container
        id={`${parent_id}_addmember_parent_container`}
        key={"0"}
        name={"container"}
        fluid={true}
        style={qdmstyles.rpP}
      >
        <H6
          id={`${parent_id}_addmember_name_h6`}
          key={"0"}
          name={props.name}
          style={qdmstyles.egZK}
        ></H6>

        <Divider
          id={`${parent_id}_addmember_bottom_divider`}
          key={"1"}
          textWidth={"50"}
          textColor={"#000"}
          borderColor={"lightgray"}
          borderWidth={"1"}
          orientation={"horizontal"}
          variant={"middle"}
          style={qdmstyles.BKv}
        ></Divider>

        {/* <Collapse
            key={"2"}
            title={"Existing Member"}
            subtitle={""}
            body={"Collapse Body"}
            iconPosition={"left"}
            iconHeight={"20"}
            iconWidth={"20"}
            url={""}
            style={qdmstyles.aGaSE}
            openIcon={
              <svg
                width="26px"
                class="MuiSvgIcon-root"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
              </svg>
            }
            closeIcon={
              <svg
                width="26px"
                class="MuiSvgIcon-root jss107"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"></path>
              </svg>
            }
          > */}
{/*exsting member */}
        <Card
          id={`${parent_id}_addmember_parent_card`}
          style={{
            overflow: "visible",
            boxShadow: "none",
            border: "2px solid #e0e0e0",
            borderRadius: 8,
          }}
        >
          <CardActions
            id={`${parent_id}_addmember_existig_cardactions`}
            onClick={() => handleExpandClick(1)}
            disableSpacing
            style={{
              padding: 0,
              paddingLeft: 20,
              paddingBottom: 0,
              cursor: "pointer",
            }}
          >
            <Text
              id={`${parent_id}_addmember_existig_label_text`}
              key={"0"}
              name="Existing Member"
              style={{ fontSize: "16px", fontWeight: "800" }}
              className={"pc_semibold"}
            />

            <IconButton
              id={`${parent_id}_addmember_existig_iconbutton`}
              className={clsx(classes.expand, {
                [classes.expandOpen]: state.expanded1,
              })}
              aria-expanded={state.expanded1}
              aria-label="show more"
            >
              <ExpandMoreIcon
                id={`${parent_id}_addmember_existig_expandmore`}
              />
            </IconButton>
          </CardActions>
          <Collapse
            id={`${parent_id}_addmember_existig_collapse`}
            className={classes.body}
            in={state.expanded1}
            timeout="auto"
            unmountOnExit
          >
            <Text
              id={`${parent_id}_addmember_existig_registered_text`}
              key={"0"}
              name={"If the member already registered with Apollo hospital."}
              style={qdmstyles.MOH}
              className="pc_regular"
            ></Text>

            <Text
              id={`${parent_id}_addmember_existig_chooseid_text`}
              key={"1"}
              className="pc_regular"
              name={
                <span id={`${parent_id}_addmember_existig_chooseid_span`}>
                  Choose ID and enter criteria{" "}
                  <span
                    id={`${parent_id}_addmember_existig_chooseid_error_span`}
                    style={{ color: "red" }}
                  >
                    *
                  </span>
                </span>
              }
              style={qdmstyles.iJEO}
            ></Text>

            <Div
              id={`${parent_id}_addmember_existig_choose_here_div`}
              key={"2"}
              className={"qdm"}
              style={qdmstyles.DazVI}
            >
              <div style={{ width: "66%" }}>
                <Dropdown
                  id={`${parent_id}_addmember_existig_choose_here_dropdown`}
                  inLineStyles={{
                    height: "42px",
                    fontWeight: "400",
                    fontSize: "13px",
                  }}
                  name="Choose ID here"
                  onChange={(e) => handleChange(e)}
                  placeholder="Choose ID here"
                  // options={[
                  //   {
                  //     value: "Mobile Number",
                  //     label: "Mobile Number",
                  //   },
                  //   {
                  //     value: "PID Number",
                  //     label: "PID Number",
                  //   },
                  //   {
                  //     value: "Aadhaar Card",
                  //     label: "Aadhaar Card",
                  //   },
                  //   // {
                  //   //   value: 'Emirate ID',
                  //   //   label: 'Emirate ID'
                  //   // },
                  //   // {
                  //   //   value: 'SSN Number',
                  //   //   label: 'SSN Number'
                  //   // }
                  // ]}
                  options={FlterOptions(props?.allMasters, "ADDMEMBERIDTYPE")}

                />
              </div>
              &nbsp;
              {props.chooseSearch?.value === "Mobile Number" ? (
                <MobileNumberWithCode
                  id={`${parent_id}_addmember_existig_mobilenumberwithcode`}
                  key={"2"}
                  type="number"
                  label="Mobile No"
                  borderColor="#E0E0E0"
                  borderRadius="6"
                  maxLength={30}
                  // id="qdm-euu-0877"
                  name=""
                  width=""
                  // dropdownData={[
                  //   {
                  //     name: "India",
                  //     flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
                  //     altSpellings: "IND",
                  //     callingCodes: "91",
                  //   },
                  // ]}
                  dropdownData={callingcodes}
                  disabled={false}
                  size=""
                  marginAll="0"
                  marginLR="0"
                  marginTB="0"
                  borderWidth="0"
                  textWidth="0"
                  style={{
                    height: "42px",
                    borderRadius: "5px",
                    width: "110%",
                  }}
                  onChange={(e) => changeState("Mobile Number", e)}
                />
              ) : (
                <TextInput
                  id={`${parent_id}_addmember_existig_chooseid_textinput`}
                  key={"0"}
                  type={state.type}
                  // onInput={e=>e>10 && }
                  variant={"outlined"}
                  value={state[props?.chooseSearch?.value]}
                  onChange={(e) =>
                    changeState(onChangeStateName(), e.target.value)
                  }
                  onKeyDown={(e) => onKeyDown(onChangeStateName(), e)}
                  helperTextType={"error"}
                  autoFocus={false}
                  autoComplete={"off"}
                  maxLength={state.limit}
                  search={false}
                  borderColor={"#E0E0E0"}
                  elevation={"0"}
                  placeholder={
                    (props.chooseSearch?.value === "PID Number" &&
                      "PID44444") ||
                    (props.chooseSearch?.value === "Aadhaar Card" &&
                      "4444-4444-4444") ||
                    (props.chooseSearch?.value === "Emirate ID" &&
                      "989-2345-2345-1") ||
                    (props.chooseSearch?.value === "SSN Number" &&
                      "123-12-1234")
                  }
                  size={"medium"}
                  style={qdmstyles.qsyIC}
                  name={""}
                  label={""}
                ></TextInput>
              )}
            </Div>
            {state.error && (
              <div id={`${parent_id}_addmember_existig_chooseid_error_div`}>
                {" "}
                <Text
                  id={`${parent_id}_addmember_existig_chooseid_error_text`}
                  style={{ color: "red", fontSize: 12 }}
                >
                  {state.error}
                </Text>
              </div>
            )}
            <Button
              id={`${parent_id}_addmember_existig_search_buttom`}
              key={"1"}
              type={"button"}
              variant={"text"}
              name={"Search"}
              inLineStyles={{
                display: "flex",
                margin: "auto",
                marginTop: "16px !important",
                fontWeight: "bold",
                marginBottom: "20px",
              }}
              style={qdmstyles.tSDI}
              onClick={() => searchMember()}
            ></Button>
            {state?.showMembers && (
  <>
    {state?.listMmbr?.map((v, key) => {
      return (
        <Div
          id={`${parent_id}_${key}_addmember_listmember_div`}
          key={`${parent_id}_${key}_div`} // Ensure unique key
          className={"qdm"}
          style={addmemberlist !== key ? qdmstyles.htwc : qdmstyles.htwc1}
          onClick={() => handleCheked(v, key)}
        >
          {!v?.existing && (
            <Checkbox
              id={`${parent_id}_${key}_addmember_listmember_checkbox`}
              key={`${parent_id}_${key}_checkbox`} // Ensure unique key
              size={"medium"}
              color={"primary"}
              name={""}
              disabled={v?.existing}
              checked={addmemberlist === key}
              style={{ marginRight: 0 }}
            />
          )}
          <Avatar
            id={`${parent_id}_${key}_addmember_listmember_image`}
            key={`${parent_id}_${key}_avatar`} // Ensure unique key
            variant={"circle"}
            letter={"Avatar"}
            src={v?.photo?.[0]?.url}
            alt={"Image"}
            inLineStyles={{
              padding: 0,
              height: "auto",
              width: 42,
            }}
          ></Avatar>
          <Div
            id={`${parent_id}_${key}_addmember_listmember_number_div`}
            key={`${parent_id}_${key}_number_div`} // Ensure unique key
          >
            <Div
              id={`${parent_id}_${key}_addmember_listmember_number_sub_div`}
              key={`${parent_id}_${key}_number_sub_div`} // Ensure unique key
            >
              <H6
                id={`${parent_id}_${key}_addmember_listmember_h6`}
                key={`${parent_id}_${key}_h6`} // Ensure unique key
                name={(v?.name || v?.name?.[0]?.fulname) ?? " "}
                style={qdmstyles.cccb_ef_d_db_efbfbd}
              ></H6>

              <Text
                id={`${parent_id}_${key}_addmember_convert_text`}
                key={`${parent_id}_${key}_text`} // Ensure unique key
                name={`${v?.identifier?.[0]?.use?.display}  ${v?.identifier?.[0]?.value}  ${
                  v?.telecom?.find(
                    (tel) =>
                      tel.system === "CodingMaster/10473" &&
                      tel.use === "CodingMaster/10477"
                  )?.value
                }, ${v?.age} Yrs, ${v?.gender?.display}`}
                style={qdmstyles.ed_c_f_be_ebccac}
              ></Text>
            </Div>
          </Div>
          <Button
            id={`${parent_id}_${key}_addmember_added_button`}
            key={`${parent_id}_${key}_button`} // Ensure unique key
            type={"button"}
            variant={"outlined"}
            name={v?.existing ? "Added" : ""}
            style={qdmstyles.RrEm}
          ></Button>
        </Div>
      );
    })}

    {addmemberlist !== null && (
      <Button
        id={`${parent_id}_addmember_send_otp_button`}
        key={`${parent_id}_send_otp_button`} // Ensure unique key
        type={"button"}
        variant={"text"}
        name={"Send OTP & Add Member"}
        style={qdmstyles.Hdva1}
        onClick={() => {
          if (props?.memberIds?.includes(state.selectData.Id)) {
            // Show alert or error message
          } else {
            addmemberlist !== null
              ? senOtpMMr()
              : props.nextSection("addmemberotp", "addMember");
          }
        }}
      ></Button>
    )}
  </>
)}

          </Collapse>
        </Card>
{/*exsting member */}

        {/* </Collapse> */}

        {/* <Collapse
            key={"3"}
            title={"New Member"}
            subtitle={""}
            body={"Collapse Body"}
            iconPosition={"left"}
            iconHeight={"20"}
            iconWidth={"20"}
            url={""}
            style={qdmstyles.JgF}
            openIcon={
              <svg
                width="26px"
                class="MuiSvgIcon-root"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
              </svg>
            }
            closeIcon={
              <svg
                width="26px"
                class="MuiSvgIcon-root jss107"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"></path>
              </svg>
            }
          > */}
        <Card
          id={`${parent_id}_addmember_newmember_card`}
          style={{
            marginTop: "6%",
            boxShadow: "none",
            border: "2px solid #e0e0e0",
            borderRadius: 8,
          }}
        >
          <CardActions
            id={`${parent_id}_addmember_newmember_cardactions`}
            onClick={() => handleExpandClick(2)}
            disableSpacing
            style={{
              paddingLeft: 20,
              paddingBottom: 0,
              cursor: "pointer",
              paddingTop: 0,
            }}
          >
            <Text
              id={`${parent_id}_addmember_newmember_text`}
              key={"0"}
              name="New Member"
              style={{ fontSize: "16px", fontWeight: "800" }}
              className={"pc_semibold"}
            />

            <IconButton
              id={`${parent_id}_addmember_newmember_show_iconbutton`}
              className={clsx(classes.expand, {
                [classes.expandOpen]: state.expanded2,
              })}
              aria-expanded={state.expanded2}
              aria-label="show more"
            >
              <ExpandMoreIcon
                id={`${parent_id}_addmember_newmember_expandmoreicon`}
              />
            </IconButton>
          </CardActions>
          <Collapse
            id={`${parent_id}_addmember_newmember_collapse`}
            className={classes.body}
            in={state.expanded2}
            timeout="auto"
            unmountOnExit
          >
            <Text
              id={`${parent_id}_addmember_newmember_registered_text`}
              key={"0"}
              name={
                "If the member is not registered with Apollo hospital before. Please proceed with Add New Member."
              }
              style={qdmstyles.oZszx}
              className={"pc_regular"}
            ></Text>

            <Button
              id={`${parent_id}_addmember_newmember_form_button`}
              key={"1"}
              type={"button"}
              variant={"text"}
              name={props.name}
              style={qdmstyles.Hdva}
              onClick={() => props.nextSection("addmemberform")}
              className={"pc_medium"}
            ></Button>
          </Collapse>
        </Card>
        {/* </Collapse> */}
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => ({
  readPerson: state?.personApiSlice?.readPerson,
  CountryCode: state?.authSlice?.country_code_list,
});

export default connect(mapStateToProps, actions)(AppointmentPageDrawer);

// export default connect(
//   mapStateToProps,
//   actions
// )(withRouter(AppointmentPageDrawer));
