import React from "react";
//import { AlertContext, RoleContext , QueueManagementContext , VisitDetails } from "../context";
import {
  NextOfKinContext,
  AlertContext,
  DialogContext,
  BackdropContext,
  CheifComplaintsContext,
  //DrawerContext,
  PatientRegContext,
  FinancialFormContext,
  ReferralDetails,
  VitalsContext,
  VisitDetails,
  DataPayloadContext,
  QueueManagementContext,
  VisitFormContext,
  AllergyContext,
  DiagnosisContext,
  MedicationContext,
  TreatmentContext,
  LabOrderContext,
  NursingProcedureContext,
  RadiologyContext,
  RoleContext,
  DoccumentContext,
  AVCShowContext,
  DrawerMeetingContext,
  LoggerContext,
  PackageOrderContext,
  //FirebaseContext,
} from "../context/index";
const withAllContexts = (Component) => (props) => {
  const alert = React.useContext(AlertContext);
  const dialog = React.useContext(DialogContext);
  const backDrop = React.useContext(BackdropContext);
  //const drawer = React.useContext(DrawerContext);
  const patientData = React.useContext(PatientRegContext);
  const nextOfKin = React.useContext(NextOfKinContext);
  const visitData = React.useContext(VisitDetails);
  const referralData = React.useContext(ReferralDetails);
  const financialData = React.useContext(FinancialFormContext);
  const dataPayload = React.useContext(DataPayloadContext);
  const visitform = React.useContext(VisitFormContext);
  const queueManagement = React.useContext(QueueManagementContext);
  const cheifComplaints = React.useContext(CheifComplaintsContext);
  const Diagnosis = React.useContext(DiagnosisContext);
  const viatalsContext = React.useContext(VitalsContext);
  const allergy = React.useContext(AllergyContext);
  const medicationsData = React.useContext(MedicationContext);
  const treatment = React.useContext(TreatmentContext);
  const LabOrder = React.useContext(LabOrderContext);
  const NursingProcedure = React.useContext(NursingProcedureContext);
  const PackageOrder = React.useContext(PackageOrderContext);
  const radiology = React.useContext(RadiologyContext);
  const role = React.useContext(RoleContext);
  const Doccument = React.useContext(DoccumentContext);
  const AVCShowList = React.useContext(AVCShowContext);
  const AVCMeeting = React.useContext(DrawerMeetingContext);
  const logger = React.useContext(LoggerContext);

  //NOTE - const PushNotification = React.useContext(FirebaseContext);
  return (
    <Component
      {...props}
      allergy={allergy}
      Diagnosis={Diagnosis}
      cheifComplaints={cheifComplaints}
      financialData={financialData}
      referralData={referralData}
      medicationsData={medicationsData}
      visitData={visitData}
      nextOfKin={nextOfKin}
      //patientData={patientData}
      alert={alert}
      dialog={dialog}
      backDrop={backDrop}
      // drawer={drawer}
      // dataPayload={dataPayload}
      visitform={visitform}
      queueManagement={queueManagement}
      viatalsContext={viatalsContext}
      treatment={treatment}
      LabOrder={LabOrder}
      NursingProcedure={NursingProcedure}
      PackageOrder={PackageOrder}
      radiology={radiology}
      role={role}
      Doccument={Doccument}
      AVCShowList={AVCShowList}
      AVCMeeting={AVCMeeting}
      //PushNotification={PushNotification}
      logger={logger}
    >
      {props.children}
    </Component>
  );
};

export default withAllContexts;
