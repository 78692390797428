import React, { useEffect } from "react";
import {
  Tab,
  Tabpanel,
  SideMenu,
  Div,
  Row,
  Col,
  TextInput,
  Modal,
} from "qdm-component-library";
import SearchIcon from "@material-ui/icons/Search";
import { UIColor } from "../../themes/theme";
import QueueCard from "../queueCard";
import repeatIcon from "../../assets/img/svg/icons8-repeat.svg";
import changeUserIcon from "../../assets/img/svg/icons8-change-user.svg";
import FilterAltIcon from "@material-ui/icons/FilterList";

import {
  Grid,
  IconButton,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Radio,
  Divider,
  Tooltip,
  TextField,
} from "@material-ui/core";
import withAllContexts from "../../hoc/withAllContexts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "../../assets/img/svg/icons8-calendar-grey.svg";
import { getUtcTime, AlertProps, axiosCall, GetQueueData } from "../../utils";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import SampleFormPage from "../queueCard/sampleForm";
import NursingProcedure from "../queueCard/nursingProcedure";
import jwtDecode from "jwt-decode";
import { useDispatch } from "react-redux/es";
import actions from "../../redux/actions";
import { QueueManagementContext } from "../../context";
const TabList = (props) => {
  let filteredItems = [];
  const [dateType, setDateType] = React.useState("Today");
  const [selectedDate, handleDateChange] = React.useState(new Date());
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [state, setState] = React.useState();
  const [FilteredUsers, setFilteredUsers] = React.useState([]);
  const [isTrue, setisTrue] = React.useState();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dateCheck, setDateCheck] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [sampleCollectOpen, setSampleCollectOpen] = React.useState(false);
  const [nursingProcedureOpen, setnursingProcedureOpen] = React.useState(false);
  const [selectedSpeciment, setselectedSpeciment] = React.useState();
  const [selectedAdminstrator, setselectedAdminstrator] = React.useState();
  const [selectedqueuevalue, setselectedqueuevalue] = React.useState();
  const [duplicateCheck, setduplicateCheck] = React.useState("");
  const {
    setAllData,
    setDisplayData,
    setPreviousData,
    setpreviousQueue,
    setloader,
  } = React.useContext(QueueManagementContext);
  const dispatch = useDispatch();

  const handleChange = (event, ticketId) => {
    
    if (event.target.checked) {
      let result = FilteredUsers.map((li) => li.ticketId);

      props?.setSelected(result, FilteredUsers);
    } else {
      props?.setSelected([], []);
    }
    setChecked(event.target.checked);
  };

  const FilterRange = () => {
    let start = 0;
    let end = 0;
    if (dateType == "Today") {
      start = getUtcTime(moment().startOf("day"));
      end = getUtcTime(moment().endOf("day"));
      props?.FilterAppliedClicked(start, end);
      setDialogOpen(!dialogOpen);
      handleClose();
      setDateCheck(!dateCheck);
    } else if (dateType == "Tomorrow") {
      start = getUtcTime(moment().add(1, "day").startOf("day"));
      end = getUtcTime(moment().add(1, "day").endOf("day"));
      props?.FilterAppliedClicked(start, end);
      setDialogOpen(!dialogOpen);
      handleClose();
      setDateCheck(!dateCheck);
    } else if (dateType == "Next_Week") {
      start = getUtcTime(moment(new Date()).weekday(7).startOf("day"));
      end = getUtcTime(moment(new Date()).weekday(13).endOf("day"));
      props?.FilterAppliedClicked(start, end);
      setDialogOpen(!dialogOpen);
      handleClose();
      setDateCheck(!dateCheck);
    } else if (dateType == "single_date") {
      if (fromDate == undefined) {
        props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "From date cannot be Empty",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      } else {
        start = getUtcTime(moment(fromDate).startOf("day"));
        end = getUtcTime(moment(fromDate).endOf("day"));
        props?.FilterAppliedClicked(start, end);
        setDialogOpen(!dialogOpen);
        handleClose();
        setDateCheck(!dateCheck);
      }
    } else if (dateType == "range_wise") {
      if (fromDate == undefined || toDate == undefined) {
        props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "From date or to date cannot be Empty",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      } else {
        start = getUtcTime(moment(fromDate).startOf("day"));
        end = getUtcTime(moment(toDate).endOf("day"));
        props?.FilterAppliedClicked(start, end);
        setDialogOpen(!dialogOpen);
        handleClose();
        setDateCheck(!dateCheck);
      }
    }
  };

  const sampleFormOpen = async (patientData) => {
    if (patientData) {
      setselectedSpeciment(patientData);
    }

    // setState({
    //   spicemanCollectedData: {
    //     ticketId: ticketId,
    //     queueId: queueId,
    //     personId:personId,
    //     encounterId:encounterId,
    //     patientId:patientId

    //   },
    // });
    const userInfo = localStorage.getItem("UserInfo");
    const decodedUserinfo = jwtDecode(userInfo);
    const orgid = decodedUserinfo.facilityid;

    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "loading...",
    });
    Promise.all([
      dispatch(actions.REGISTERED_BY(orgid)),
      dispatch(actions.SAMPLE_COLLECTED(decodedUserinfo?.practitionerid)),
      dispatch(actions.BRANCH_CODE(orgid)),
    ])
      .then((res) => {
        props.backDrop.setBackDrop({
          ...props.backDrop,
          open: false,
          message: "",
        });

        setSampleCollectOpen(true);
      })
      .catch((err) => {
        props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something went wrong !",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      });
  };
  const sampleFormClose = () => {
    setSampleCollectOpen(false);
  };
  const stringconertedarray = (originalArray) => {
    const convertedArray = originalArray.map((element) => `'${element}'`);
    return `[${convertedArray.join(", ")}]`;
  };
  const printOrderRequestFormMultiselect = async () =>{
    const userInfo = localStorage.getItem("UserInfo");
    const decodedUserinfo = jwtDecode(userInfo);
    const orgid = decodedUserinfo.facilityid;
    let JwtToken = localStorage.getItem("JwtToken");
    let header = { JwtToken: JwtToken };
    let arraydata = props?.SelectedTicketValue;
    let payload = {
      "@patientid": "",
      "@encounterid": "",
      "@orderlineid": "",
      "@facilityid": orgid,
      "@tenantid": decodedUserinfo?.tenantid,
    };
    let orderlineid = [];
    arraydata.forEach((v) => {
      payload["@encounterid"] = v?.inputdoc?.encounterid;
      payload["@patientid"] = v?.inputdoc?.patientid;
      orderlineid.push(v?.inputdoc?.caorderlineid || "");
    });
    payload["@orderlineid"] = stringconertedarray(orderlineid);
    const data = {
      reportid: process.env.REACT_APP_PRINT_ORDER_FORM_REPORTID,
      inputparams: payload,
      result: [],
    };
    try {
      const reportGenerate = await axiosCall(
        data,
        process.env.REACT_APP_GENERATE_REPORT,
        header
      );
      if (!reportGenerate?.error) {
        window.location.href = reportGenerate?.downloadUrl;
        let data = await GetQueueData();
        setAllData(data.everyData);
        setDisplayData(data.getDisplayData);
        setPreviousData(data.getPreviousData);
        setpreviousQueue(queueId);
      }
    } catch (error) {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: error.message,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
   }
   const sentToLabMultiselect = async () => {
     setloader(true);
     let pua = await dispatch(
       actions.PERFORM_USER_ACTION({
         ticketId: props?.selectedTicket,
         userAction:"SENT TO LAB",
       })
     );
     if (pua?.payload?.data?.error) {
       props.alert.setSnack({
         open: true,
         severity: AlertProps.severity.error,
         msg: pua?.payload?.data?.message,
         vertical: AlertProps.vertical.top,
         horizontal: AlertProps.horizontal.center,
         tone: true,
       });
     } else {
       let data = await GetQueueData();
       setAllData(data.everyData);
       setDisplayData(data.getDisplayData);
       setPreviousData(data.getPreviousData);
       setpreviousQueue(queueId);
 
       props.alert.setSnack({
         open: true,
         severity: AlertProps.severity.success,
         msg: "Updated Successfully",
         vertical: AlertProps.vertical.top,
         horizontal: AlertProps.horizontal.center,
         tone: true,
       });
     }
     setloader(false);
   }
 
  const nursingProcedureClose = () => {
    setnursingProcedureOpen(false);
  };

  const NursingProcedureOpen = async (patientData , l) => {
    if (patientData) {
      setselectedAdminstrator(patientData);
      setselectedqueuevalue(l)
    }

    const userInfo = localStorage.getItem("UserInfo");
    const decodedUserinfo = jwtDecode(userInfo);
    const orgid = decodedUserinfo.facilityid;

    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "loading...",
    });
    Promise.all([
      dispatch(actions.ADMINISTRATED_BY(orgid)),
      dispatch(actions.GET_ALL_MASTERS("")),
      
    ])
      .then((res) => {
        props.backDrop.setBackDrop({
          ...props.backDrop,
          open: false,
          message: "",
        });

        setnursingProcedureOpen(true);
      })
      .catch((err) => {
        props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something went wrong !",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (state == null) {
      setFilteredUsers(PatientData.data);
    } else {
      setFilteredUsers(FilteredUsers);
    }
  }, [state, props]);

  const {
    statesname,
    statesid,
    checkName,
    PatientData,
    permission,
    selectedTicket,
    setSelected,
    scenarioId,
    queueId,
    action,
    //assignPractitioner,
    //setState,
    //filterData
  } = props;
  const excludeColumns = [];

  const searchResult = (evt) => {
    const searchTerm = evt.target.value;
    setState(searchTerm);
    const lowercasedValue = evt.target.value.toLowerCase().trim();
    const filteredData = PatientData.data.filter((item) => {
      return Object.keys(item).some((key) =>
        excludeColumns.includes(key)
          ? false
          : item[key].toString().toLowerCase().includes(lowercasedValue)
      );
    });
    setFilteredUsers(filteredData);
  };
  const clearDateFilter = () => {
    setToDate(null);
    setFromDate(null);
    handleDateChange(null);
    props?.setresetFilter(!props?.resetFilter);
  };

  const FilterDate = (date) => {
    // if (date !== null && !isNaN(date)) {
    //   let stringDate = getDateMonthYear(date);
    //   let Res = [];
    //   logState.forEach((e) => {
    //     let Convert = e.field2.includes("/")
    //       ? e.field2.split("/").reverse().join("-")
    //       : e.field2;
    //     if (stringDate === getDateMonthYear(Convert)) {
    //       Res.push(e);
    //     }
    //   });
    //   setTask(Res);
    // } else {
    //   setTask(logState);
    // }
  };
  return (
    <Div id={`${statesname}_sidemenu_search_input_div`}>
      <Row
        id="dashboard_parent_row"
        style={{
          display: "flex",
          height: "auto",
          alignItems: "center",
          justifyContent: "center",
          //backgroundColor: UIColor.differentiationBackground.color,
        }}
      >
        <Col
          id="dashboard_parent_layout_col"
          xl={11}
          lg={11}
          md={11}
          sm={11}
          xs={11}
          inLineStyles={{ padding: 0 }}
        >
          <TextInput
            id={`${statesname}_sidemenu_search_input_textinput`}
            value={state}
            onChange={searchResult}
            style={{
              margin: "20px 0px 15px",
              borderRadius: 8,
              borderColor: UIColor.lineBorderFill.color,
            }}
            // search
            variant={"outlined"}
            // id={"inputId"}
            label=""
            placeholder="Search"
            hoverColor="#0071F2"
          />
          <SearchIcon
            id={`${statesname}_sidemenu_search_searchicon`}
            style={{
              position: "absolute",
              top: 29,
              right: 20,
              color: UIColor.lineBorderFill.color,
            }}
          />
        </Col>
        {/* <Col
          id="dashboard_parent_layout_col"
          xl={1}
          lg={1}
          md={1}
          sm={1}
          xs={1}
          inLineStyles={{ padding: 0 }}
        >
          <div style={{ padding: "3px 15px"}}>
            <Tooltip title="Filter" placement="right">
              <IconButton
                size="small"
                style={{ borderRadius: "8px",  border: "1px solid #e0e0e0" , backgroundColor:"#EC6A49" , padding:"8px"}}
                onClick={() => setDialogOpen((pre) => !pre)}
              >
                <FilterAltIcon style={{color:"white"}} />
              </IconButton>
            </Tooltip>
          </div>
        </Col> */}
      </Row>
      <Row
        id="dashboard_parent_row"
        style={{
          display: "flex",
          height: "auto",
          alignItems: "center",
          justifyContent: "space-between",
          //backgroundColor: UIColor.differentiationBackground.color,
        }}
      >
        {/* {queueId === "queue/10025" &&
          ["queuestates/10088", "queuestates/10089"].includes(statesid) &&
          FilteredUsers?.length > 1 && (
            <FormControlLabel
              label="Select All"
              control={
                <Checkbox
                  checked={checked}
                  onChange={(e) => handleChange(e)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
            />
          )} */}
        <div>
          {props?.selectedTicket?.length > 0 &&
            action?.map((li) => {
              return (
                <Button
                  className={"reassign"}
                  onClick={() => { 
                    if (li.action === process.env.REACT_APP_LABORDER_SPECIMENTCOLLECTED) {
                      sampleFormOpen();
                    } 
                    else if (li.action === process.env.REACT_APP_LABORDER_PRINT_ORDER_REQUISITION_FORM){
                      printOrderRequestFormMultiselect()
                    } else if (li.action === "SENT TO LAB"){
                      sentToLabMultiselect()
                    }
                  }}
                  style={{
                    marginLeft: "5px",
                    backgroundColor:li?.bgcolor === "" ? "" : li?.bgcolor,
                    borderColor:li?.bgcolor === "" ? "#179E8E" : "",
                    color: li?.bgcolor === "" ? "#179E8E" : "white" ,
                  }}
                  variant="outlined"
                  // color={li?.bgcolor}
                  size="small"
                >
                  {li?.action}
                </Button>
              );
            })}
        </div>
      </Row>
      <div
        style={{
          height: "calc(100vh - 10.1rem)",
          overflow: "auto",
        }}
      >
        {FilteredUsers.map((l, i) => {
          
          return (
            <QueueCard
             queueCardStyle={true}
              parent_id={"dashboard_queuecard_pending" + i}
              id={l.token}
              markHighPriority={
                !(permission?.write?.indexOf("markHighPriorityQ") > -1)
              }
              assignReassignPractitioner={
                !(
                  permission?.write?.indexOf("assignReassignPractitionerQ") > -1
                )
              }
              type="All"
              showBtn={l?.showBringtoTop}
              field1={l?.field1}
              field2={l?.field2}
              field3={l?.field3}
              field4={l?.field4}
              field5={l?.field5}
              field6={l?.field6}
              field7={l?.field7}
              field8={l?.field8}
              field9={l?.field9}
              field10={l?.field10}
              field11={l?.field11}
              field12={l?.field12}
              name={l?.name}
              date={l?.date}
              date1={l?.date1}
              // field1={l?.profile_pic}
              isEmergency={l?.isEmergency}
              totalData={l}
              //action={l?.action}
              action={action}
              ticketId={l?.ticketId}
              //getTabData = {props.getTabData()}
              assignButtonAction={() => {
                let assigned =
                  l?.info?.encounter?.[0]?.participant?.length > 0
                    ? true
                    : false;
                // props.assignPractitioner(
                // 	assigned ? "reassign" : "assign",
                // 	l
                // );
              }}
              assignButtonIcon={
                l?.info?.encounter?.[0]?.participant?.length > 0
                  ? repeatIcon
                  : changeUserIcon
              }
              assigned={
                l?.info?.encounter?.[0]?.participant?.length > 0 ? true : false
              }
              role_={l.type}
              queue={l?.queueMetaData?.queuename?.queuename}
              queueId={l?.queueid}
              // setMultiBookOpen={props?.setMultiBookOpen}
              // multiBookOpen={props?.multiBookOpen}
              PatientData={l}
              queueUpdated={() => props?.queueUpdated()}
              selectedTicket={selectedTicket}
              setSelected={props?.setSelected}
              statesid={l?.statesid}
              FilteredUsers={FilteredUsers}
              SelectedTicketValue={props?.SelectedTicketValue}
              sampleCollectOpen={sampleCollectOpen}
              sampleFormOpen={(e) => sampleFormOpen(e)}
              sampleFormClose={(e) => sampleFormClose(e)}
              nursingProcedureOpen={nursingProcedureOpen}
              NursingProcedureOpen={NursingProcedureOpen}
              nursingProcedureClose={(e) => nursingProcedureClose(e)}
              queueList={props?.queueList}
              setduplicateCheck={setduplicateCheck}
              duplicateCheck={duplicateCheck}
              getTabData={props?.getTabData}
              selectedQueue={props?.selectedQueue}
              scenarioId={scenarioId}
              setOpen={props?.setOpen}
            />
          );
        })}
      </div>
      {sampleCollectOpen ? (
        <Dialog
          maxWidth="sm"
          fullWidth
          open={sampleCollectOpen}
          onClose={() => setSampleCollectOpen((pre) => !pre)}
        >
          <DialogContent>
            <SampleFormPage
              sampleFormClose={sampleFormClose}
              selectedSpeciment={selectedSpeciment}
              SelectedTicketValue={props?.SelectedTicketValue}
              selectedTicket={props?.selectedTicket}
            />
          </DialogContent>
        </Dialog>
      ) : null}
      {nursingProcedureOpen ? (
        <Dialog
          maxWidth="sm"
          fullWidth
          open={nursingProcedureOpen}
          onClose={() => setnursingProcedureOpen((pre) => !pre)}
        >
          <DialogContent>
            <NursingProcedure
              nursingProcedureClose={nursingProcedureClose}
              selectedqueuevalue={selectedqueuevalue}
              selectedAdminstrator={selectedAdminstrator}
            />
          </DialogContent>
        </Dialog>
      ) : null}
      <Dialog
        maxWidth="sm"
        fullWidth
        open={dialogOpen}
        onClose={() => setDialogOpen((pre) => !pre)}
      >
        <div>
          <DialogTitle id="alert-dialog-slide-title">
            Filter By Date
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item lg={12} sm={12} md={12}>
                {/* <Typography>Date Type</Typography> */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setDateType("Today")}
                  >
                    <Radio
                      checked={dateType === "Today"}
                      onChange={(e) => setDateType(e.target.value)}
                      value="Today"
                      color="primary"
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "E" }}
                      size="small"
                    />
                    <Typography>Today</Typography>
                  </span>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setDateType("Tomorrow")}
                  >
                    <Radio
                      checked={dateType === "Tomorrow"}
                      onChange={(e) => setDateType(e.target.value)}
                      value="Tomorrow"
                      color="primary"
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "E" }}
                      size="small"
                    />
                    <Typography>Tomorrow</Typography>
                  </span>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setDateType("Next_Week")}
                  >
                    <Radio
                      checked={dateType === "Next_Week"}
                      onChange={(e) => setDateType(e.target.value)}
                      value="Next_Week"
                      color="primary"
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "E" }}
                      size="small"
                    />
                    <Typography>Next Week</Typography>
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setDateType("single_date")}
                  >
                    <Radio
                      checked={dateType === "single_date"}
                      onChange={(e) => setDateType(e.target.value)}
                      value="single_date"
                      color="primary"
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "E" }}
                      size="small"
                    />
                    <Typography>Single Date</Typography>
                  </span>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      marginLeft: "80px",
                    }}
                    onClick={() => setDateType("range_wise")}
                  >
                    <Radio
                      checked={dateType === "range_wise"}
                      onChange={(e) => setDateType(e.target.value)}
                      value="range_wise"
                      color="primary"
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "E" }}
                      size="small"
                    />
                    <Typography>Range Wise</Typography>
                  </span>
                </div>
              </Grid>
              {(dateType === "range_wise" || dateType === "single_date") && (
                <Grid
                  item
                  lg={dateType === "range_wise" ? 6 : 12}
                  sm={dateType === "range_wise" ? 6 : 12}
                  md={dateType === "range_wise" ? 6 : 12}
                >
                  <Typography>
                    {dateType === "range_wise" ? "From" : "Custom Date"}
                  </Typography>
                  <DatePicker
                    selected={fromDate}
                    onChange={(date) => setFromDate(date)}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="DD-MM-YYYY"
                    className={"pc_regular"}
                    showIcon
                    icon="fa fa-calendar"
                    withPortal
                  />
                </Grid>
              )}
              {dateType === "range_wise" && (
                <Grid item lg={6} sm={6} md={6}>
                  <Typography>To</Typography>
                  <DatePicker
                    selected={toDate}
                    onChange={(date) => setToDate(date)}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="DD-MM-YYYY"
                    className={"pc_regular"}
                    showIcon
                    icon="fa fa-calendar"
                    withPortal
                  />
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button
              onClick={() => FilterRange()}
              color="primary"
              variant="outlined"
            >
              Apply
            </Button>
            <Button
              onClick={() => (
                setDialogOpen(false),
                dateCheck &&
                  selectedDate === null &&
                  fromDate === null &&
                  toDate == null &&
                  setDateCheck(false),
                handleClose()
              )}
              color="primary"
              variant="outlined"
            >
              Cancel
            </Button>
            {(selectedDate !== null ||
              fromDate !== null ||
              toDate !== null) && (
              <Button
                onClick={() => clearDateFilter()}
                color="primary"
                variant="outlined"
              >
                Reset
              </Button>
            )}
          </DialogActions>
        </div>
      </Dialog>
    </Div>
  );
};

export default withAllContexts(TabList);