import React, { useContext, useEffect } from "react";
import actions from "../../redux/actions";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
//import { withRouter } from "react-router-dom";
import Queue from "../../components/queue";
import PatientQueue from "../../screens/dashboard/patientQueue";
import withAllContexts from "../../hoc/withAllContexts";
import QueueCard from "../queueCard";
import CloseIcon from "@material-ui/icons/Close";
import { personRequests, fetchData, urls, getUtcTime } from "../../utils";
import moment from "moment";
import {
  Tab,
  Tabpanel,
  SideMenu,
  Div,
  Row,
  Col,
  TextInput,
  Modal,
  Image,
  CircularProgress,
} from "qdm-component-library";
import SearchIcon from "@material-ui/icons/Search";
//import AssignReassign from "../../screens/dashboard/assignReassign";
import { UIColor } from "../../themes/theme";
import TabList from "./TabList";
import Loading from "../global/loading";
import { BackdropContext, QueueManagementContext } from "../../context";
import jwtDecode from "jwt-decode";
const QueueDrawer = (props) => {
  const {
    loader,
    setloader,
    previousQueue,
    ActiveTab,
    HeaderTabActive,
    setActiveTab,
    setHeaderTabActive,
    setpreviousQueue,
    setPrevious,
    previous,
    setAttend,
    attend,
    setNext,
    next,
    setTabData,
    TabData,
    setSelectedQueue,
    selectedQueue
  } = React.useContext(QueueManagementContext);
  const dispatch = useDispatch();
  const backdrop = useContext(BackdropContext);
  const userInfo = localStorage.getItem("UserInfo");
  let permissionScenario = props?.queueList?.[0]?.scenarioChildren.filter(
    (li) => li.permsnwrite == true
  );
  const decodedUserinfo = jwtDecode(userInfo);
  // const [selectedQueue, setSelectedQueue] = React.useState({});
  const [tabActive, setTabActive] = React.useState(ActiveTab);
  const [headtabActive, setheadTabActive] = React.useState(HeaderTabActive);
  const [queueId, setqueueId] = React.useState(
    props?.queueList?.[0]?.repodetails?._id
  );
  const [resetFilter, setresetFilter] = React.useState(false);
  const [isQueueUpdate, setisQueueUpdated] = React.useState(false);
  const [scenarioId, setscenarioId] = React.useState(
    permissionScenario?.[0]?.repodetails?._id
  );
  const [selectedTicket, setselectedTicket] = React.useState([]);
  const [SelectedTicketValue, setSelectedTicketValue] = React.useState([]);

  const setSelected = (tickets, value) => {
    setselectedTicket(tickets);
    setSelectedTicketValue(value || []);
  };
  const info = {
    // orgId: 138,
    // name: "sathiya",
    practionerId: decodedUserinfo?.practitionerid,
    role: decodedUserinfo?.rolename.toLowerCase(),
    org_id: decodedUserinfo?.facilityid,
    idmroleid: decodedUserinfo.idmroleid,
  };
  useEffect(() => {
    if (queueId == undefined) {
      let Arr = [];
      let permissionScenario = props?.queueList?.[0]?.scenarioChildren.filter(
        (li) => li.permsnwrite === true
      );
      if (permissionScenario?.length > 0) {
        permissionScenario?.[0]?.stateChildren?.map((val) => {
          if (val.permsnwrite == true) {
            let action = []
            if (val?.actionChildren?.length > 0) {
              val?.actionChildren?.map(li => {
                if(li?.permsnwrite)
                {
                  let actObj = {
                    "bgcolor": li?.repodetails?.bgcolor,
                    "icon": li?.repodetails?.icon,
                    "textcolor": li?.repodetails?.textcolor,
                    "action": li?.repodetails?.action,
                    "actionid": li?.repodetails?.actionid,
                    "_id": li?.repodetails?._id,
                    "backEndCall": li?.repodetails?.backEndCall,
                    "apiendpoint": li?.repodetails?.apiendpoint,
                    "primaryaction": li?.repodetails?.primaryaction
                  }
                  action.push(actObj)
                }
              })
            }
            let obj = {
              statesid: val?.repodetails?._id,
              statesname: val?.repodetails?.states,
              icon: val?.repodetails?.icon,
              action:action,
              data: [],
            };
            Arr.push(obj);
          }
        });
        setpreviousQueue(props?.queueList?.[0]?.repodetails?._id);
        setqueueId(props?.queueList?.[0]?.repodetails?._id);
        setscenarioId(permissionScenario?.[0]?.repodetails?._id);
        setSelected([]);
        setSelectedQueue(Arr);
        getTabData(
          Arr,
          props?.queueList?.[0]?.repodetails?._id,
          permissionScenario?.[0]?.repodetails?._id
        );
      }
    } else {
      
      
      let queueIdValue = props?.queueListClick
        ? props?.selctValue
        : previousQueue;
      let selectedQueue = props?.queueList.filter(
        (li) => li?.repodetails?._id == queueIdValue
      );
      let Arr = [];
      let permissionScenario = selectedQueue?.[0]?.scenarioChildren.filter(
        (li) => li.permsnwrite == true
      );
      if (permissionScenario?.length > 0) {
        permissionScenario?.[0]?.stateChildren?.map((val) => {
          if (val.permsnwrite == true) {

            let action = []
            if (val?.actionChildren?.length > 0) {
              val?.actionChildren?.map(li => {
                if(li?.permsnwrite)
                {
                  let actObj = {
                    "bgcolor": li?.repodetails?.bgcolor,
                    "icon": li?.repodetails?.icon,
                    "textcolor": li?.repodetails?.textcolor,
                    "action": li?.repodetails?.action,
                    "actionid": li?.repodetails?.actionid,
                    "_id": li?.repodetails?._id,
                    "backEndCall": li?.repodetails?.backEndCall,
                    "apiendpoint": li?.repodetails?.apiendpoint,
                    "primaryaction": li?.repodetails?.primaryaction
                  }
                  action.push(actObj)
                }
              })
            }
            let obj = {
              statesid: val?.repodetails?._id,
              statesname: val?.repodetails?.states,
              icon: val?.repodetails?.icon,
              action:action,
              data: [],
            };
            Arr.push(obj);
          }
        });
      }
      setpreviousQueue(
        props?.queueListClick ? props?.selctValue : previousQueue
      );
      setqueueId(props?.queueListClick ? props?.selctValue : previousQueue);
      setscenarioId(permissionScenario?.[0]?.repodetails?._id);
      setSelectedQueue(Arr);
      getTabData(
        Arr,
        selectedQueue?.[0]?.repodetails?._id,
        permissionScenario?.[0]?.repodetails?._id
      );
      setSelected([]);
    }
  }, [props?.selctValue, props?.queueList, resetFilter, previousQueue]);

  const FilterAppliedClicked = (FromDate, toDate) => {
    let queueIdValue = previousQueue;
    let selectedQueue = props?.queueList.filter(
      (li) => li?.repodetails?._id === queueIdValue
    );
    let Arr = [];
    let permissionScenario = selectedQueue?.[0]?.scenarioChildren.filter(
      (li) => li.permsnwrite === true
    );
    if (permissionScenario?.length > 0) {
      permissionScenario?.[0]?.stateChildren?.map((val) => {
        if (val.permsnwrite === true) {

          let action = []
            if (val?.actionChildren?.length > 0) {
              val?.actionChildren?.map(li => {
                if(li?.permsnwrite)
                {
                  let actObj = {
                    "bgcolor": li?.repodetails?.bgcolor,
                    "icon": li?.repodetails?.icon,
                    "textcolor": li?.repodetails?.textcolor,
                    "action": li?.repodetails?.action,
                    "actionid": li?.repodetails?.actionid,
                    "_id": li?.repodetails?._id,
                    "backEndCall": li?.repodetails?.backEndCall,
                    "apiendpoint": li?.repodetails?.apiendpoint,
                    "primaryaction": li?.repodetails?.primaryaction
                  }
                  action.push(actObj)
                }
              })
            }
            let obj = {
              statesid: val?.repodetails?._id,
              statesname: val?.repodetails?.states,
              icon: val?.repodetails?.icon,
              action:action,
              data: [],
            };
          Arr.push(obj);
        }
      });
    }

    getFilteredTab(
      Arr,
      selectedQueue?.[0]?.repodetails?._id,
      permissionScenario?.[0]?.repodetails?._id,
      FromDate,
      toDate
    );
  };

  const getFilteredTab = async (Arr, queueId, scenarioId, fromDate, toDate) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading the queue.. Please wait",
    });
    setloading(true);
    const data = personRequests.getAllTab(info);
    const getData = Arr;

    const allData = await dispatch(
      actions.GET_QUEUE_TEMPLATE({
        queueIds: queueId,
        scenarioId: scenarioId,
        facilityid: decodedUserinfo?.facilityid,
        locationid: decodedUserinfo?.locationid,
        practitionerid: decodedUserinfo?.practitionerid,
        practitioneroleid: decodedUserinfo?.roleid,
        startdate: fromDate,
        enddate: toDate,
      })
    );
    const queueData = allData?.payload?.data?.response ?? [];
    getData.map((l, i) => {
      l.data = queueData.filter((x) => x?.statesid === l?.statesid);
    });

    // setState((prevState) => ({
    //   ...prevState,
    //   TabData: getData,
    // }));
    setTabData(getData);
    setloading(false);
    backdrop.setBackDrop({
      ...backdrop,
      open: false,
      message: "",
    });
  };

  const [state, setState] = React.useState({
    checkName: "",
    TabData: [],
  });

  const [loading, setloading] = React.useState(false);

  //componentDidMount() {}

  //   shouldComponentUpdate(props, state) {
  //     return true;
  //   }
  const queueUpdated = () => {
    setisQueueUpdated(!isQueueUpdate);
  };
  const getTabData = async (Arr, queueId, scenarioId) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading the queue.. Please wait",
    });
    setloading(true);
    const data = personRequests.getAllTab(info);
    const getData = Arr;

    const allData = await dispatch(
      actions.GET_QUEUE_TEMPLATE({
        queueIds: queueId,
        scenarioId: scenarioId,
        facilityid: decodedUserinfo?.facilityid,
        locationid: decodedUserinfo?.locationid,
        practitionerid: decodedUserinfo?.practitionerid,
        practitioneroleid: decodedUserinfo?.roleid,
        startdate: [
          "queue/10018",
          "queue/10022",
          "queue/10025",
          // "queue/10026",
        ].includes(queueId)
          ? getUtcTime(moment().startOf("day"))
          : 0,
        enddate: [
          "queue/10018",
          "queue/10022",
          "queue/10025",
          // "queue/10026",
        ].includes(queueId)
          ? getUtcTime(moment().endOf("day"))
          : 0,
      })
    );
    const queueData = allData?.payload?.data?.response ?? [];
    getData?.map((l, i) => {
      l.data = queueData.filter((x) => x?.statesid === l?.statesid);
    });
    let prev = queueData.filter((x) => x?.statesid === "queuestates/10055");
    if(prev.length > 0){
      setPrevious(prev.filter((x, i) => i === prev.length - 1));
    }
    else{
      setPrevious([]);
    }
    let att = queueData.filter((x) => x?.statesid === "queuestates/10051" || x?.statesid === "queuestates/10053" || x?.statesid === "queuestates/10054");
    console.log(att,"att");
    if(att.length > 0){
      let data = JSON.parse(JSON.stringify(att[0]));
      data?.action?.map((v) => {
        if(v?.action === "VISIT REGISTER" ||
          v?.action === "NURSE SIGNOFF" ||
          v?.action === "DOCTOR SIGNOFF" || v?.action === "ATTEND"){
            data["primaryAction"] = v;
          }
          else if (v?.action === "ON HOLD"){
            data.secondaryAction = v;
          }
      });
      setAttend([data]);
    }
    else{
      setAttend([]);
    }
    if(att.length > 1){
      setNext([att[1]]);
      //setNext(att.filter((x, i) => i === 1));
    }
    else{
      setNext([]);
    }

    // setState((prevState) => ({
    //   ...prevState,
    //   TabData: getData,
    // }));
    setTabData(getData);
    setloading(false);
    backdrop.setBackDrop({
      ...backdrop,
      open: false,
      message: "",
    });
  };

  const onHoldData = () => {
    if (state.checkName) {
      var arr = [];
      const data = props?.onHoldData?.filter((v) => {
        if (
          v.name
            ?.toLocaleLowerCase()
            .includes(state.checkName?.toLocaleLowerCase())
        ) {
          return arr.push(v);
        }
      });
      return arr;
    }
  };
  const filterData = () => {
    var arr = [];
    const data = props?.pendingData?.filter((v) => {
      if (
        v.name
          ?.toLocaleLowerCase()
          .includes(state.checkName?.toLocaleLowerCase()) &&
        v.showBringtoTop === true
      ) {
        return arr.push(v);
      }
    });
    return arr;
  };
  const pastData = () => {
    var arr = [];
    const data = props?.pastData?.filter((v) => {
      if (
        v.name
          ?.toLocaleLowerCase()
          .includes(state.checkName?.toLocaleLowerCase())
      ) {
        return arr.push(v);
      }
    });
    return arr;
  };

  const orderList = (a) => {
    const data = a.sort(
      (a, b) => parseFloat(b.personId) - parseFloat(a.personId)
    );
    return data;
  };
  const getSelectedQueue = (value) => {
    let list = props?.queueList[value];
    let permissionScenario = list.scenarioChildren.filter(
      (li) => li.permsnwrite == true
    );
    let Arr = [];
    if (permissionScenario?.length > 0) {
      permissionScenario?.[0]?.stateChildren?.map((val) => {
        if (val.permsnwrite == true) {
          let action = []
            if (val?.actionChildren?.length > 0) {
              val?.actionChildren?.map(li => {
                if(li?.permsnwrite)
                {
                  let actObj = {
                    "bgcolor": li?.repodetails?.bgcolor,
                    "icon": li?.repodetails?.icon,
                    "textcolor": li?.repodetails?.textcolor,
                    "action": li?.repodetails?.action,
                    "actionid": li?.repodetails?.actionid,
                    "_id": li?.repodetails?._id,
                    "backEndCall": li?.repodetails?.backEndCall,
                    "apiendpoint": li?.repodetails?.apiendpoint,
                    "primaryaction": li?.repodetails?.primaryaction
                  }
                  action.push(actObj)
                }
              })
            }
            let obj = {
              statesid: val?.repodetails?._id,
              statesname: val?.repodetails?.states,
              icon: val?.repodetails?.icon,
              action:action,
              data: [],
            };
          Arr.push(obj);
        }
      });
    }
    setSelectedQueue(Arr);
    setTabActive(0);
    setActiveTab(0);
    setHeaderTabActive(value);
    setqueueId(list?.repodetails?._id);
    setscenarioId(permissionScenario?.[0]?.repodetails?._id);
    getTabData(
      Arr,
      list?.repodetails?._id,
      permissionScenario?.[0]?.repodetails?._id
    );
  };

  const {
    pendingData,
    open,
    onClose,
    //onHoldData,
    //pastData,
    permission,
  } = props;
  return (
    <div>
      <Loading
        loading={loader}
        style={{
          margin: "auto",
          position: "absolute",
          left: "50%",
          top: "35%",
        }}
      >
			<SideMenu
				id="sidemenu_parent_sidemenu"
				// onDoubleClick={e=>console.log(e)}
				// data={drawerdata}
				open={open}
				inLineStyles={{ zIndex: 99999999, padding: 0 }}
				direction="right"
				width={940}
				color="default"
				onClose={onClose}
			// open={true}
			>
        <Row id="sidemenu_parent_row">
          <Col
							id="sidemenu_parent_col"
							xl={5}
							lg={5}
							md={5}
							sm={12}
							xs={12}
							inLineStyles={{
								padding: "20px 15px",
								backgroundColor: UIColor.differentiationBackground.color,
								height: "100vh",
								overflow: "auto",
							}}
						>
							<div id="sidemenu_parent_queue_div">
								<PatientQueue
									markHighPriority={
										!(permission?.write?.indexOf("markHighPriorityQ") > -1)
									}
									assignReassignPractitioner={
										!(
											permission?.write?.indexOf(
												"assignReassignPractitionerQ"
											) > -1
										)
									}
									parent_id={"dashboard"}
									previous={previous}
									attend={attend}
									next={next}
                  getTabData={getTabData}
                  selectedQueue={selectedQueue}
                  scenarioId={scenarioId}
								/>
							</div>
						</Col>
          <Col
            id="sidemenu_tab_col"
            xl={7}
            lg={7}
            md={7}
            sm={7}
            xs={7}
            inLineStyles={{
              padding: "0px 20px",
              height: "auto",
              overflow: "auto",
              backgroundColor: "#fff",
            }}
          >
            {/* {props?.queueList?.length > 1 && (
              <Tab
                id="sidemenu_parent_tab"
                activetabs={headtabActive}
                variant="contained"
                borderColor={UIColor.lineBorderFill.color}
                size="small"
                labelStyle={{
                  fontFamily: "pc_medium !important",
                  fontSize: 14,
                  border: "none",
                  padding: "6px 8px",
                }}
                backgroundColor={UIColor.differentiationBackground.color}
                onChange={(e) => getSelectedQueue(e)}
              >
                {props?.queueList?.map((li) => {
                  return (
                    <Tabpanel
                      title={"Registration Queue"}
                      id={`Registration_sidemenu_tab_tabpanel`}
                      //icon={<CloseIcon />}
                      label={li?.repodetails?.queuename}
                      //`${props.filterData(statesid).length}`
                      inLineStyles={{
                        border: "none",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {""}
                    </Tabpanel>
                  );
                })}
              </Tab>
            )} */}

            <div
              style={
                props?.queueList?.length > 1
                  ? { marginTop: "20px" }
                  : { marginTop: "0px" }
              }
            >
              <Tab
                id="sidemenu_parent_tab"
                activetabs={tabActive}
                variant="contained"
                borderColor={UIColor.differentiationBackground.color}
                size="small"
                activeColor={UIColor.secondaryColor.color}
                labelStyle={{
                  fontFamily: "pc_medium !important",
                  fontSize: 14,
                  border: "none",
                  padding: "6px 8px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                backgroundColor={"#FFECE8"}
                onChange={(e) => {
                  if (typeof e === "number") {
                    setTabActive(e);
                    setActiveTab(e);
                    setSelected([]);
                    setState({
                      ...state,
                      checkName: "",
                    });
                  }
                }}
              >
                {TabData?.length > 0 &&
                  TabData.map((v, i) => {

                    let svg = v?.icon;
                    let blob = new Blob([svg], { type: "image/svg+xml" });
                    let url = URL.createObjectURL(blob);
                    return (
                      <Tabpanel
                        title={v?.statesname}
                        id={`${v?.statesname}_sidemenu_tab_tabpanel`}
                        //icon={<CloseIcon />}
                        label={
                          TabData?.length <= 3 ? (
                            <>{v?.statesname}</>
                          ) : (
                            <Image
                              title={v?.statesname}
                              inLineStyles={{
                                height: "17px",
                                width: "16px",
                                marginLeft: "10px",
                              }}
                              src={url}
                              alt={v?.statesname.substring(0, 1)}
                            />
                          )
                        }
                        number={
                          TabData?.length > 0 ? `${v?.data?.length}` : "0"
                        } //`${props.filterData(statesid).length}`
                        inLineStyles={{
                          border: "none",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {loading ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "50vh",
                            }}
                          >
                            {/* <CircularProgress /> */}
                          </div>
                        ) : (
                          <TabList
                            tabcount={TabData.length}
                            statesname={v.statesname}
                            statesid={v.statesid}
                            checkName={state.checkName}
                            PatientData={v}
                            permission={permission}
                            queueUpdated={() => queueUpdated}
                            FilterAppliedClicked={FilterAppliedClicked}
                            resetFilter={resetFilter}
                            setresetFilter={setresetFilter}
                            selectedTicket={selectedTicket}
                            setSelected={setSelected}
                            queueId={queueId}
                            scenarioId={scenarioId}
                            SelectedTicketValue={SelectedTicketValue}
                            queueList={props?.queueList}
                            action={v?.action}
                            getTabData={getTabData}
                            selectedQueue={selectedQueue}
                            setOpen={props?.setOpen}
                           
                          />
                        )}
                      </Tabpanel>
                    );
                  })}

                {/* <Tabpanel
	title=""
	id="sidemenu_tab_tabpanel"
	label="Pending"
	number={`${filterData().length}`}
	inLineStyles={{
		border:"none"
	}}
>

	<Div id="sidemenu_search_input_div">
		<Div
			id="sidemenu_search_input_sub_div"
			style={{ display: "flex", position: "relative" }}
		>
			<TextInput
				id="sidemenu_search_input_textinput"
				value={state.checkName}
				onChange={(evt) => {
					setState({
						...state,
						checkName: evt.target.value,
					});
				}}
				style={{
					margin: "20px 0px 15px",
					borderRadius: 8,
					borderColor: UIColor.lineBorderFill.color,
				}}
				// search
				variant={"outlined"}
				// id={"inputId"}
				label=""
				placeholder="Search"
				hoverColor="#0071F2"
			/>
			<SearchIcon
				id="sidemenu_search_searchicon"
				style={{
					position: "absolute",
					top: 29,
					right: 20,
					color: UIColor.lineBorderFill.color,
				}}
			/>
		</Div>
		<div
			style={{
				height: "calc(100vh - 10.1rem)",
				overflow: "auto",
			}}
		>
			{(
				filterData() || [
					...orderList(props.pendingData),
				]
			).map((l, i) => {
				return (
					<QueueCard
						parent_id={"dashboard_queuecard_pending" + i}
						id={l.token}
						markHighPriority={
							!(
								permission?.write?.indexOf("markHighPriorityQ") >
								-1
							)
						}
						assignReassignPractitioner={
							!(
								permission?.write?.indexOf(
									"assignReassignPractitionerQ"
								) > -1
							)
						}
						type="All"
						showBtn={l?.showBringtoTop}
						name={l?.name}
						time={l?.start}
						token={l?.token}
						role={l?.role}
						profile_pic={l?.profile_pic}
						isEmergency={l?.isEmergency}
						totalData={l}
						assignButtonAction={() => {
							let assigned =
								l?.info?.encounter[0]?.participant?.length > 0
									? true
									: false;
							props.assignPractitioner(
								assigned ? "reassign" : "assign",
								l
							);
						}}
						assignButtonIcon={
							l?.info?.encounter?.[0]?.participant?.length > 0
								? "../../assets/img/svg/icons8-repeat.svg"
								: "../../assets/img/svg/icons8-change-user.svg"
						}
						assigned={
							l?.info?.encounter?.[0]?.participant?.length > 0
								? true
								: false
						}
						role_={l.type}
					/>
				);
			})}
		</div>
	</Div>
</Tabpanel>
<Tabpanel
	title=""
	id="sidemenu_parent_tabpanel"
	label="On Hold"
	number={`${onHoldData.length}`}
>
	<Div id="sidemenu_sub_div">
		<Div
			id="sidemenu_search_div"
			style={{ display: "flex", position: "relative" }}
		>
			<TextInput
				id="sidemenu_search_text"
				size="medium"
				value={state.checkName}
				onChange={(evt) => {
					setState({
						...state,
						checkName: evt.target.value,
					});
				}}
				// search={
				//   true
				// }
				// InputProps={{
				//   endAdornment: <SearchIcon />
				// }}
				style={{
					margin: "20px 0px 15px",
					borderRadius: 8,
					borderColor: "#E0E0E0",
				}}
				// search
				variant={"outlined"}
				// id={"inputId"}
				label=""
				placeholder="Search"
				hoverColor="#0071F2"
			/>
			<SearchIcon
				id="sidemenu_search_searchicon"
				style={{
					position: "absolute",
					top: 29,
					right: 20,
					color: "#e0e0e0",
				}}
			/>
		</Div>
		{(
			(onHoldData()?.length > 0 && onHoldData()) || [
				...orderList(props.onHoldData),
			]
		).map((l, i) => (
			<QueueCard
				parent_id={"dashboard_queuecard_hold" + i}
				isHold
				id={i}
				name={l?.name}
				time={l?.start}
				totalData={l}
				markHighPriority={!(permission?.write?.indexOf("markHighPriorityQ") > -1)}
				assignReassignPractitioner={!(permission?.write?.indexOf("assignReassignPractitionerQ") > -1)}
				token={l?.token}
				role={l?.role}
				profile_pic={l?.profile_pic}
				isEmergency={l?.isEmergency}
				assignButtonAction={() => {
						let assigned =
							l?.info?.encounter?.[0]?.participant?.length > 0
							? true
							: false;
						props.assignPractitioner(
							assigned ? "reassign" : "assign",
							l
						);
						}}
						assignButtonIcon={
						l?.info?.encounter?.[0]?.participant?.length > 0
							? "images/icons8-repeat.svg"
							: "images/icons8-change-user.svg"
						}
						assigned={
						l?.info?.encounter?.[0]?.participant?.length > 0
							? true
							: false
						}
						role_={l.type}
			/>
		))}
	</Div>
</Tabpanel>
<Tabpanel
	title=""
	id="sidemenu_parent_tabpanel"
	label="Completed"
	number={`${pastData.length}`}
>
	<Div id="sidemenu_parent_div">
		<Div
			id="sidemenu_sub_div"
			style={{ display: "flex", position: "relative" }}
		>
			<TextInput
				id="sidemenu_search_textinput"
				value={state.checkName}
				onChange={(evt) => {
					setState({
						...state,
						checkName: evt.target.value,
					});
				}}
				style={{
					margin: "20px 0px 15px",
					borderRadius: 8,
					borderColor: "#E0E0E0",
				}}
				// search
				variant={"outlined"}
				// id={"inputId"}
				label=""
				placeholder="Search"
				hoverColor="#0071F2"
			/>
			<SearchIcon
				id="sidemenu_search_searchicon"
				style={{
					position: "absolute",
					top: 29,
					right: 20,
					color: "#e0e0e0",
				}}
			/>
		</Div>
		{(
			pastData() || [
				...orderList(props.pastData),
			]
		).map((l, i) => (
			<QueueCard
				parent_id={"dashboard_queuecard_past" + i}
				id={i}
				val={l}
				name={l?.name}
				token={l?.token}
				// permission={permission}
				nocancelVisit={
					!(permission?.write?.indexOf("cancelVisit") > -1)
				}
				nomodifyVisit={
					!(permission?.write?.indexOf("modifyVisit") > -1)
				}
				noviewVisit={
					!(permission?.write?.indexOf("viewVisit") > -1)
				}
				markHighPriority
				// markHighPriority={
				// 	!(permission?.write?.indexOf("markHighPriorityQ") > -1)
				// }
				// assignReassignPractitioner={
				// 	!(
				// 		permission?.write?.indexOf(
				// 			"assignReassignPractitionerQ"
				// 		) > -1
				// 	)
				// }
				NureCloseConsultation={(permission?.write?.indexOf("closeConsultationN") > -1)}
				DoctorCloseConsultation={(permission?.write?.indexOf("closeConsultationD") > -1)}
				assignReassignPractitioner
				role={l?.role}
				completedView
				time={l?.start}
				profile_pic={l?.profile_pic}
				isEmergency={l?.isEmergency}
				closeButtonAction={() => {
					
					props.closeConsultation("close", l);
				  }}
			/>
		))}
	</Div>
</Tabpanel> */}
              </Tab>
            </div>
          </Col>
        </Row>
      </SideMenu>
      </Loading>

      {/* <Backdrop open={true} onClick={() => {}}>
        <CircularProgress color="inherit" />
      </Backdrop> */}
    </div>
  );
};

export default QueueDrawer;