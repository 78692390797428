import React, { useState, useEffect } from "react";
// import { withAllContexts } from "../../../HOCs";

export const VideoCallComponent = (props) => {
	const [room, setRoom] = useState(null);
	const [jwt, setJwt] = useState("");
	const [user, setUser] = useState({ name: props.userName ?? "" });
	const [isAudioMuted, setIsAudioMuted] = useState(false);
	const [isVideoMuted, setIsVideoMuted] = useState(true);
	let api = {};

	useEffect(() => {
		if (window.JitsiMeetExternalAPI && !room) {
			setRoom(props.RoomName);
			startMeet(props.RoomName, props.jwt, props.userName, props?.domain);
		} else {
			console.log("JitsiMeetExternalAPI not loaded");
		}
	}, [props]);

	const startMeet = (RoomName, jwt, userName, domainName) => {
		const options = {
			roomName: RoomName,
			width: "100%",
			configOverwrite: {
				prejoinPageEnabled: false,
				startWithAudioMuted: isAudioMuted,
				startWithVideoMuted: isVideoMuted,
				// startSilent:true
			},
			interfaceConfigOverwrite: {

				CSS_OVERRIDE: `
          .sideToolbarContainer {
            margin-left: 45px !important;
          }
        `,

				// overwrite interface properties
			},
			parentNode: document.querySelector("#jitsi-iframe"),
			userInfo: {
				displayName: userName,
			},
			jwt: jwt,
		};
		api = new window.JitsiMeetExternalAPI(domainName, options);

		api.addEventListeners({
			readyToClose: handleClose,
			participantLeft: handleParticipantLeft,
			participantJoined: handleParticipantJoined,
			videoConferenceJoined: handleVideoConferenceJoined,
			// videoConferenceLeft: handleVideoConferenceLeft,
			audioMuteStatusChanged: handleMuteStatus,
			videoMuteStatusChanged: handleVideoStatus,
		});

		api.addEventListener("#jitsi-iframe", () => {
			
			const iframe = document.querySelector("#jitsi-iframe");
			const iframeDocument = iframe?.contentDocument || iframe?.contentWindow?.document;
	  
			if (iframeDocument) {
			  const style = iframeDocument.createElement("style");
			  style.innerHTML = `
				.sideToolbarContainer {
				  margin-left: 45px !important;
				}
			  `;
			  iframeDocument.head.appendChild(style); // Inject custom styles into the iframe
			}
		  });

	};

	const handleClose = () => {
		console.log("handleClose");
	};

	const handleParticipantLeft = async (participant) => {
		console.log("handleParticipantLeft", participant); // { id: "2baa184e" }
		const data = await getParticipants();
	};

	const handleParticipantJoined = async (participant) => {
		console.log("handleParticipantJoined", participant); // { id: "2baa184e", displayName: "Shanu Verma", formattedDisplayName: "Shanu Verma" }
		const data = await getParticipants();
	};

	const handleVideoConferenceJoined = async (participant) => {
		console.log("handleVideoConferenceJoined", participant); // { roomName: "bwb-bfqi-vmh", id: "8c35a951", displayName: "Akash Verma", formattedDisplayName: "Akash Verma (me)"}
		const data = await getParticipants();
	};

	const handleVideoConferenceLeft = () => {
		console.log("handleVideoConferenceLeft");
		// props.closeFun();
		// return props.history.push("/avc_login");
	};

	const handleMuteStatus = (audio) => {
		console.log("handleMuteStatus", audio); // { muted: true }
	};

	const handleVideoStatus = (video) => {
		console.log("handleVideoStatus", video); // { muted: true }
	};

	const getParticipants = () => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve(api.getParticipantsInfo()); // get all participants
			}, 500);
		});
	};

	// custom events
	const executeCommand = (command) => {
		api.executeCommand(command);
		if (command === "hangup") {
			// return props.history.push("/avc_login");
		}

		if (command === "toggleAudio") {
			setIsAudioMuted(!isAudioMuted);
		}

		if (command === "toggleVideo") {
			setIsVideoMuted(!isVideoMuted);
		}
	};

	// useEffect(() => {
	// }, []);

	return (
		<div style={{ zIndex: 9999, display: props?.screenSize && "none" }}>
			<div id="jitsi-iframe" style={{ height: "100vh", width: "100%" }}></div>
		</div>
	);
};
