import React, { useContext, useEffect, useState } from "react";
import { NewCard, WorkFlowComponent } from "./src";
import {
  actionButtonClick,
  AlertProps,
  axiosCall,
  checkWithCasbin,
  epochToDatetime,
  fetchData,
  getAge,
  getCalculatedAge,
  getFacilityId,
  getGlobalValueFrom_LclStrg_And_QryStrg,
  getImgUrl,
  getUtcTime,
  makeName,
  personRequests,
  RefundQueueRejectTabs,
  urls,
} from "../../utils";
import moment from "moment";
import jwtDecode from "jwt-decode";
import { AlertContext, BackdropContext, drawerContext } from "../../context";
import { useDispatch } from "react-redux";
import actions from "../../redux/actions";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../router/routes";
import MultiBookComp from "../../components/multibook/multiBookComp";
import { SideMenu } from "qdm-component-library";
import UploadFiles from "../../components/queueCard/fileUpload";
import Commonsidebar from "../../components/commonsidebar";
import SampleFormPage from "../../components/queueCard/sampleForm";
import MuiDrawer from "../../components/drawer.js";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import withAppBar from "../../hoc/withAppBar";
import SearchPatient from "../dashboard/searchPatient/searchPatient.js";
import { getLayout } from "../dashboard/functions.js";
import NursingProcedure from "../../components/queueCard/nursingProcedure.js";
import CloseIcon from "@mui/icons-material/Close";
import { QueueFns } from "./src/queuefns.js";
import UseQueryString from "../../hooks/queryString.js";
function Queue(props) {
  const backdrop = useContext(BackdropContext);
  const alert = useContext(AlertContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location quey", location);
  const userInfo = localStorage.getItem("UserInfo");
  const decodedUserinfo = jwtDecode(userInfo);
  // work flow states
  const [QueueTypeList_wf, setQueueTypeList_wf] = React.useState([]); //qur
  const [stateList_wf, setStateList_wf] = React.useState([]);
  const [selQueueData_wf, setSelQueueData_wf] = useState([]);
  const [tabValue_wf, setTabValue] = React.useState(0);
  const [selectedQueue_wf, setSelectedQueue_wf] = React.useState({});
  const [refundDetailsOpen, setRefundDetailsOpen] = useState(false);
  const [refundValue, setRefundValue] = useState({});

  // This component states
  const [currentIds, setCurrentIds] = React.useState({
    queueId: "",
    scenarioId: "",
  });


  const [state, setState] = useState({});
  const [permission1, setPermission] = React.useState({});
  const [permissionData, setPermissionData] = React.useState([]);
  const [drawerData, setDrawerData] = React.useState([]);

  const [queueFnsData, setQueueFnsData] = useState({  
      ticketId: [],
      TotActionData: {},
      data: [],
      queueId: ""    
  });
  const handleTabChange_WF = (event, newValue) => {
    setTabValue(newValue);
  };

  const QueueTypeListDataFormatter = (AllQueueList = []) => {
    const queuetype = [];
    for (const item of AllQueueList) {
      queuetype.push({
        id: item?.repodetails?._id,
        value: item?.repodetails?.queuename,
      });
    }
    return queuetype;
  };
  const StateListDataFormatter = (selectedQueue = []) => {
    const stateList = [];
    try {
      for (
        let i = 0;
        i < selectedQueue?.scenarioChildren[0].stateChildren.length;
        i++
      ) {
        const item = selectedQueue?.scenarioChildren[0].stateChildren[i];
        if ([process.env.REACT_APP_LAB_ORDER_QUEUE_ID].includes(selectedQueue?.repodetails?._id)) {
          stateList.push({
            id: item.repoid.unique_id,
            index: i,
            value: item.repoid.rep_name,
            isMultiple: true,
            isDuplicateCheck: true
          });
        } else {
          stateList.push({
            id: item.repoid.unique_id,
            index: i,
            value: item.repoid.rep_name,

          });
        }
      }
      return stateList;
    } catch (error) {
      console.error(error);
      return stateList;
    }
  };

  const QueueDataFormatter = (datas) => {
    let list = [];
    datas?.map((item) => {
      let val = JSON.parse(JSON.stringify(item));
      if (item.data && item.data.length > 0) {
        const fieldList = {};
        let data = [];
        item.data.map((d) => {
          let cdata = JSON.parse(JSON.stringify(d));
          let action = [];
          const fields = d.fields;
          for (const field of fields || []) {
            cdata[field.name] =
              field.label === "appdate"
                ? epochToDatetime(field.value)
                : field.value;
          }
          d?.action.map((a) => {
            const actionPermi = permissionData.filter(x => x.repoid?.unique_id == a._id);
            if (actionPermi.length > 0) {
              action.push(a);
            }
          })
          cdata.action = action;
          data.push(cdata);
        })
        val.data = data;
      }
      list.push(val);
    });
    return list;
  };

  const SelQueueDatas = useSelector((state) => state?.auth?.get_queuetemplate);
  const getQueueListData = useSelector(
    (state) => state?.auth?.get_all_queue_list
  );
  const StateandQueueDateJoinerfn = (stateList, particularQueueData) => {
    let data = [];
    stateList?.forEach((l, i) => {
      let obj = l;
      const hasSpecificQueueId = particularQueueData?.some((x) => x?.queueid === process.env.REACT_APP_DIALYSIS_QUEUE_ID);
      if (hasSpecificQueueId) {
        obj.data = particularQueueData;
      } else {
        obj.data = particularQueueData?.filter((x) => x?.statesid === l?.id);
      }
      data.push(obj);
    });
    return data;
  };
  const GetSelectedQueueDataCall = async (
    queueId,
    scenarioId,
    FromDate,
    toDate
  ) => {
    return await dispatch(
      actions.GET_QUEUE_TEMPLATE({
        queueIds: queueId,
        scenarioId: scenarioId,
        facilityid: decodedUserinfo?.facilityid,
        locationid: decodedUserinfo?.locationid,
        practitionerid: decodedUserinfo?.practitionerid,
        practitioneroleid: decodedUserinfo?.roleid,
        startdate: FromDate
          ? FromDate
          : [
            "queue/10018",
            "queue/10022",
            "queue/10025",
            // "queue/10026",
          ].includes(queueId)
            ? getUtcTime(moment().startOf("day"))
            : 0,
        enddate: toDate
          ? toDate
          : [
            "queue/10018",
            "queue/10022",
            "queue/10025",
            // "queue/10026",
          ].includes(queueId)
            ? getUtcTime(moment().endOf("day"))
            : 0,
      })
    );
  };
  const GetQueueListCall = async () => {
    return await dispatch(actions.GET_ALL_QUEUE_LIST());
  };

  const loadCustomCard = (val) => {
    // console.log(val, "val");
    if (val && val.data && val.data.length > 0) {
      return <NewCard actionButtonClick={CardBtnClick_WF} patient={val} />;
    } else {
      return <></>;
    }
  };

  const RefreshLatestQueue = async (actionVal, queueVal) => {
    let fetched_SelectedQueueData = await GetSelectedQueueDataCall(
      currentIds.queueId,
      currentIds.scenarioId
    );
    let selectedQueueData = StateandQueueDateJoinerfn(
      stateList_wf,
      fetched_SelectedQueueData?.payload?.data?.response
    );
    // setTabValue((n) => n +  1);
    setTabValue((n) => {
      let actionId = ""
      if (RefundQueueRejectTabs?.rejectActionId === actionVal?._id) {
        actionId = RefundQueueRejectTabs?.rejectedTabId
      }
      const matchedState = stateList_wf?.find(
        s => s?.id === actionId
      );
      return matchedState ? matchedState.index : n + 1;
    });

    let sqdata = QueueDataFormatter(selectedQueueData);
    setSelQueueData_wf(sqdata);
  };
  const filterClick = async (val) => {
    let fetched_SelectedQueueData = await GetSelectedQueueDataCall(
      currentIds.queueId,
      currentIds.scenarioId,
      val?.fromDate,
      val?.toDate
    );
    let selectedQueueData = StateandQueueDateJoinerfn(
      stateList_wf,
      fetched_SelectedQueueData?.payload?.data?.response
    );
    let sqdata = QueueDataFormatter(selectedQueueData);
    setSelQueueData_wf(sqdata);
  };
  const alertPopup = (type, msg) => {

    alert.setSnack({
      open: true,
      severity: type,
      msg: msg,
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.center,
      tone: true,
    });
  };
  const HandleSelectQueue_WF = async (val) => {
    setSelectedQueue_wf(val);
    let selectedQueue = getQueueListData?.data?.result?.filter(
      (v) => v?.repodetails?._id === val.id
    );
    let selectedScenario = selectedQueue?.[0]?.scenarioChildren.filter(
      (li) => li.permsnwrite === true
    );
    let formted_StateList = StateListDataFormatter(selectedQueue?.[0]);
    setStateList_wf(formted_StateList);
    let fetched_SelectedQueueData = await GetSelectedQueueDataCall(
      selectedQueue?.[0]?.repodetails?._id,
      selectedScenario?.[0]?.repodetails?._id
    );
    let selectedQueueData = StateandQueueDateJoinerfn(
      formted_StateList,
      fetched_SelectedQueueData?.payload?.data?.response
    );
    setCurrentIds({
      queueId: selectedQueue?.[0]?.repodetails?._id,
      scenarioId: selectedScenario?.[0]?.repodetails?._id,
    });
    setTabValue(0);
    sessionStorage.setItem("currentQueue", JSON.stringify(selectedQueue?.[0]));

    let sqdata = QueueDataFormatter(selectedQueueData);
    setSelQueueData_wf(sqdata);
  };


  const actionPermission = async (data) => {
    let action = [];
    data?.map((d) => {
      d?.scenarioChildren?.[0]?.stateChildren?.map((v) => {
        v?.actionChildren?.map((a) => {
          action.push(a);
        })
      });
    });
    setPermissionData(action)
  };
  async function Init() {
    
    let FetchedList = await GetQueueListCall();
    let temp_QueueTypelist = FetchedList?.payload?.data?.result;
    let defaultQueueType;
    const matchedQueueType = temp_QueueTypelist?.find(
        (item) => item?.repoid?.unique_id === location?.state?.queueId?.id
    );

    if (matchedQueueType) {
        defaultQueueType = matchedQueueType;
    } else {
        defaultQueueType = temp_QueueTypelist?.[0];
    }
    let formted_queueTypeList = QueueTypeListDataFormatter(temp_QueueTypelist);
    let sessionValue = sessionStorage.getItem("currentQueue"); // string

    if (sessionValue && sessionValue !== "undefined") {
      defaultQueueType = JSON.parse(sessionValue);
    }
    let permittedScenario = defaultQueueType?.scenarioChildren.filter(
      (li) => li.permsnwrite === true
    );
    let formted_StateList = [];
    if (permittedScenario?.length > 0) {
      formted_StateList = StateListDataFormatter(defaultQueueType);
    }
    let fetched_SelectedQueueData = await GetSelectedQueueDataCall(
      defaultQueueType?.repodetails?._id,
      permittedScenario?.[0]?.repodetails?._id
    );
    let selectedQueueData = StateandQueueDateJoinerfn(
      formted_StateList,
      fetched_SelectedQueueData?.payload?.data?.response
    );
    let frmtedSelQueuetype = formted_queueTypeList.filter(
      (v) => v?.id === defaultQueueType?.repodetails?._id
    );
    sessionStorage.setItem("currentQueue", JSON.stringify(defaultQueueType));
    setQueueTypeList_wf(formted_queueTypeList);
    setStateList_wf(formted_StateList);
    let sqdata = QueueDataFormatter(selectedQueueData);
    setSelQueueData_wf(sqdata);
    // setSelQueueData_wf(QueueDataFormatter(selectedQueueData));

    setCurrentIds({
      queueId: defaultQueueType?.repodetails?._id,
      scenarioId: permittedScenario?.[0]?.repodetails?._id,
    });
    actionPermission(temp_QueueTypelist);

    setSelectedQueue_wf(frmtedSelQueuetype?.[0]);
  }

  const loadWithPermission = async () => {
    let fetched_SelectedQueueData = await GetSelectedQueueDataCall(
      currentIds.queueId,
      currentIds.scenarioId
    );
    let selectedQueueData = StateandQueueDateJoinerfn(
      stateList_wf,
      fetched_SelectedQueueData?.payload?.data?.response
    );
    let sqdata = QueueDataFormatter(selectedQueueData);
    setSelQueueData_wf(sqdata);
  }
  useEffect(() => {
    loadWithPermission();
  }, [permissionData]);
  const getDawerData = async () => {
    const response = await dispatch(
      actions.GET_DAWERDATA()
    );
    setDrawerData(response?.payload?.data?.[0]);
  };
   useEffect(() => {
    if(location?.state?.queueId){
    HandleSelectQueue_WF(location?.state?.queueId);
    }
  }, [location?.state?.queueId]);
  useEffect(() => {
    Init();
    const initFun = async () => {
      let permission = await checkWithCasbin(["queueSideNav"]);
      let dataList = await checkWithCasbin(["dashboard"]);
      let layouts = await getLayout(dataList);
      setState({
        permission: dataList,
        initialLayout: layouts,
      });
      setPermission(permission)
    };
    getDawerData();
    initFun();
  }, []);
 
  const CardBtnClick_WF = async (
    ticketId = [],
    TotActionData = "",
    data = [],
  ) => {
    debugger
      
    setQueueFnsData({
      ticketId: ticketId,
      TotActionData: TotActionData,
      data: data?.[0]?.totalData || data,
       queueId:data?.queueid
    })
  };
  const handleRefundCloseModal = () => setRefundDetailsOpen(false);
  const handleRefundInfo = async (refundId, statesid) => {
    try {
      const response = await dispatch(
        actions.REFUND_GET_DETAILS({
          refundId: refundId, statesid
        })
      );
      setRefundValue({
        refundAmount: response?.payload?.data?.[0]?.refundamt,
        rejectReason: response?.payload?.data?.[0]?.reason?.ReasonDesc ?? response?.payload?.data?.[0]?.refundreason,
        reasonCmt: response?.payload?.data?.[0]?.reason?.ReasonCmt,
      });
      setRefundDetailsOpen(true)
    } catch (error) {

    }
  };
  const { permission } = state;
  console.log(QueueTypeList_wf,"QueueTypeList_wf");
  
  return (
    <div>
      <WorkFlowComponent
        data={selQueueData_wf} //fun cal
        tabList={stateList_wf}
        queueList={QueueTypeList_wf}
        CardBtnClick={CardBtnClick_WF}
        PopupAlert={alertPopup}
        filterClick={filterClick}
        HandleSelectQueue={HandleSelectQueue_WF}
        dataName={"Patient"}
        colValue={3} //12
        avatarType="rounded" //"square"
        itemPerPage={selectedQueue_wf?.value == process.env.REACT_APP_DIALYSIS_QUEUE ? 8 : 6} //4
        loadCustomCard={loadCustomCard}
        showTabs={selectedQueue_wf?.value == process.env.REACT_APP_DIALYSIS_QUEUE ? false : true} //true
        // data={QueueList}
        isgetQueueListData={getQueueListData?.loading}
        isSelQueueDatas={SelQueueDatas?.loading || getQueueListData?.loading}
        HandleQueueSelect={HandleSelectQueue_WF}
        handleTabChange={handleTabChange_WF}
        tabValue={tabValue_wf}
        selectedQueue={selectedQueue_wf}
        handleRefundInfo={handleRefundInfo}
        handleRefundCloseModal={handleRefundCloseModal}
        refundDetailsOpen={refundDetailsOpen}
        refundValue={refundValue}
        RefreshLatestQueue={RefreshLatestQueue}
      />
      <Dialog
        open={refundDetailsOpen}
        onClose={handleRefundCloseModal}
        PaperProps={{
          style: {
            minWidth: 300,
          },
        }}
      >
        <DialogTitle>Refund details</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleRefundCloseModal}
          style={{ position: "absolute", right: 6, top: 6 }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100px',
          }}
        >
          <React.Fragment>
            <Grid>
              <div>
                <Typography
                  variant="body2"
                  style={{
                    // color: "#EC6A49",
                    fontSize: "15px",
                    margin: "5px 0",
                    fontWeight: 600,
                  }}
                >
                  {`Refund amount : ${refundValue?.refundAmount ?? ""}`}
                </Typography>
              </div>
              <div>
                <Typography
                  variant="body2"
                  style={{
                    // color: "#EC6A49",
                    fontSize: "15px",
                    margin: "10px 0",
                    fontWeight: 600,
                  }}
                >
                  {`Reason : ${refundValue?.rejectReason ?? ""
                    }`}
                </Typography>
              </div>
              {refundValue?.reasonCmt && (
                <div>
                  <Typography
                    variant="body2"
                    style={{
                      // color: "#EC6A49",
                      fontSize: "15px",
                      margin: "10px 0",
                      fontWeight: 600,
                    }}
                  >
                    {`Comments : ${refundValue?.reasonCmt ?? ""
                      }`}
                  </Typography>
                </div>
              )}
            </Grid>
          </React.Fragment>
        </DialogContent>
      </Dialog>
      <QueueFns
        SelectedTicketValue={props?.SelectedTicketValue}
        selectedTicket={props?.selectedTicket}
        queueData={queueFnsData}
        RefreshLatestQueue={RefreshLatestQueue}
      />
       {permission?.write?.indexOf("searchPatient") > -1 && (
        <SearchPatient
          noNewPatient={!(permission?.write?.indexOf("newPatient") > -1)}
          parent_id={"dashboard_search_pid"}
        />
      )}
    </div>
  );
}

export default withAppBar(Queue);
