import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  defaultReject,
  defaultState,
  fetchData,
  urls,
  dbName,
  query_ids,
  queries,
  getFacilityId,
  getTenantId,
  getGlobalValueFrom_LclStrg_And_QryStrg,
} from "../../utils";
import { childJSON } from "./orders/child";
import { generateJSON } from "./orders/saveJson";
import { generateResJson } from "./orders/generateresponse";

const GET_ORDERCATALOG = createAsyncThunk(
  `LabOrderApiSlice/get_ordercatalog`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        queryid: query_ids["get_ordercatalog"],
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let returnData = [];
      if (Array.isArray(data)) {
        data?.map((val, i) => {
          returnData.push({
            value: val?.ordercatalog,
            label: val?.longdesc,
            _id: val?._id,
          });
        });
      }
      return {
        ...defaultState.Info,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const GET_ORDERNURSECATALOG = createAsyncThunk(
  `LabOrderApiSlice/get_ordernursecatalog`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        queryid: query_ids["get_ordernursecatalog"],
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let returnData = [];
      if (Array.isArray(data)) {
        data?.map((val, i) => {
          returnData.push({
            value: val?.ordercatalog,
            label: val?.longdesc,
            _id: val?._id,
          });
        });
      }
      return {
        ...defaultState.Info,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const GET_PACKAGEORDER = createAsyncThunk(
  `LabOrderApiSlice/get_packageorder`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        queryid: query_ids["getpackageorder"],
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let returnData = [];
      if (Array.isArray(data)) {
        data?.map((val, i) => {
          returnData.push({
            value: val?.shortdesc,
            label: val?.shortdesc,
            _id: val?._id,
          });
        });
      }
      return {
        ...defaultState.Info,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const GET_PRIORITY = createAsyncThunk(
  `LabOrderApiSlice/get_priority`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { tenantid = "", facilityid = "", lang = "" } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["GetAllMasters"],
        filter: {
          type: ["LABPRIORITY"],
          tenantid: tenantid,
          facilityid: facilityid,
          lang: lang,
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let returnData = [];
      if (Array.isArray(data)) {
        data?.[0]?.LABPRIORITY?.Value?.map((val, i) => {
          returnData.push({
            value: val?._id,
            label: val?.display,
          });
        });
      }
      return {
        ...defaultState.Info,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const GET_LOCATION_ALL = createAsyncThunk(
  `LabOrderApiSlice/get_location`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { tenantid = "", facilityid = "", lang = "" } = payload;
      const body = {
        db_name: dbName,
        queryid: "80d7a491-7501-4b87-8912-b50c328b73bc",
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let returnData = [];
      if (Array.isArray(data)) {
        data?.map((val, i) => {
          returnData.push({
            value: val?.longdesc,
            label: val?._id,
            locationID: val?.locationID,
          });
        });
      }
      return {
        ...defaultState.Info,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const GET_ORDERNATURE = createAsyncThunk(
  `LabOrderApiSlice/get_ordernature`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { tenantid = "", facilityid = "", lang = "" } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["GetAllMasters"],
        filter: {
          type: ["ORDERNATURE"],
          tenantid: tenantid,
          facilityid: facilityid,
          lang: lang,
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let returnData = [];
      if (Array.isArray(data)) {
        data?.[0]?.ORDERNATURE?.Value?.map((val, i) => {
          returnData.push({
            value: val?._id,
            label: val?.display,
          });
        });
      }
      return {
        ...defaultState.Info,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const READ_LABORDER_CHILD = createAsyncThunk(
  `LabOrderApiSlice/set_lab_orders_child_data`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { list, Isparent = "", dates } = payload;

      // let body = generateJSON(list);
      const body = childJSON(list, Isparent, true, dates, "LAB");

      //   const data = await fetchData(
      //     { body: JSON.stringify(body) }
      //     // __uspsertUrl__
      //     //__insertRCMurl__
      //     urls.insertOrder
      //   );
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.insertOrder
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const READ_NURSINGPROCEDURE = createAsyncThunk(
  `LabOrderApiSlice/nursingprocedure`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { list, Isparent = "", dates } = payload;

      // let body = generateJSON(list);
      const body = childJSON(list, Isparent, true, dates, "NURSINGPROCEDURE");

      //   const data = await fetchData(
      //     { body: JSON.stringify(body) }
      //     // __uspsertUrl__
      //     //__insertRCMurl__
      //     urls.insertOrder
      //   );
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.insertOrder
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const SET_LAB_ORDERS_SERVICE = createAsyncThunk(
  `LabOrderApiSlice/set_lab_orders`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const {
        list,
        dateArr = [],
        isupdate,
        childIds,
        deleteArr = [],
      } = payload;
      let body = generateJSON(list, "LAB");

      const data = await fetchData(
        { body: JSON.stringify(body) },
        // __uspsertUrl__
        // __insertRCMurl__
        urls.insertOrder
      );

      await Promise.all(
        deleteArr?.map(async (val) => {
          return await fetchData(
            { body: queries.laborder_delete(val) },
            urls.deleteOrder
          );
        })
      );

      // var recurrentData;
      await Promise.all(
        dateArr?.reverse()?.map(async (v, i) => {
          const datas = childJSON(
            list,
            isupdate ? childIds[i] : data.Result[0].properties.doc._id,
            isupdate,
            v,
            "LAB"
          );
          return await fetchData(
            { body: JSON.stringify(datas) },
            // __uspsertUrl__
            // __insertRCMurl__
            urls.insertOrder
          );
        })
      );

      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const SET_NURSINGPROCEDURE_SERVICE = createAsyncThunk(
  `LabOrderApiSlice/set_nursingprocedure`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const {
        list,
        dateArr = [],
        isupdate,
        childIds,
        deleteArr = [],
      } = payload;
      let body = generateJSON(list, "NURSINGPROCEDURE");

      const data = await fetchData(
        { body: JSON.stringify(body) },
        // __uspsertUrl__
        // __insertRCMurl__
        urls.insertOrder
      );

      await Promise.all(
        deleteArr?.map(async (val) => {
          return await fetchData(
            { body: queries.laborder_delete(val) },
            urls.deleteOrder
          );
        })
      );

      // var recurrentData;
      await Promise.all(
        dateArr?.reverse()?.map(async (v, i) => {
          const datas = childJSON(
            list,
            isupdate ? childIds[i] : data.Result[0].properties.doc._id,
            isupdate,
            v,
            "NURSINGPROCEDURE"
          );
          return await fetchData(
            { body: JSON.stringify(datas) },
            // __uspsertUrl__
            // __insertRCMurl__
            urls.insertOrder
          );
        })
      );

      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const SET_PACKAGEORDER_SERVICE = createAsyncThunk(
  `LabOrderApiSlice/set_packageorder`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const {
        list,
        dateArr = [],
        isupdate,
        childIds,
        deleteArr = [],
      } = payload;
      let body = generateJSON(list, "PACK");

      const data = await fetchData(
        { body: JSON.stringify(body) },
        // __uspsertUrl__
        // __insertRCMurl__
        urls.insertOrder
      );

      await Promise.all(
        deleteArr?.map(async (val) => {
          return await fetchData(
            { body: queries.laborder_delete(val) },
            urls.deleteOrder
          );
        })
      );

      // var recurrentData;
      await Promise.all(
        dateArr?.reverse()?.map(async (v, i) => {
          const datas = childJSON(
            list,
            isupdate ? childIds[i] : data.Result[0].properties.doc._id,
            isupdate,
            v,
            "PACK"
          );
          return await fetchData(
            { body: JSON.stringify(datas) },
            // __uspsertUrl__
            // __insertRCMurl__
            urls.insertOrder
          );
        })
      );

      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const LABORDER_READ = createAsyncThunk(
  `LabOrderApiSlice/read_order`,
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      const { encounter_id, order_id, patientid, startdate } = payload;
      const body = {
        db_name: dbName,
        filter: {
          encounterid: encounter_id,
          ord_Category: order_id,
          facilityid: getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"facilityid"}),
          tenantid:  getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"tenantid"}),
          // CA_OrderLine: "",
          patientid:patientid,
          startdate:startdate,
        },
        queryid: "bd4de695-ff18-4054-9bf0-8b26d3ad0525",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );

      let res = generateResJson(data);

      return {
        ...defaultState.List,
        data: res,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const ORDER_TYPE_REQ_DETAILS_ROLE = createAsyncThunk(
  `LabOrderApiSlice/laborder_pract_role`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { id } = payload;
      const body = {
        db_name: dbName,
        filter: {
          orgid: id,
          tenantid: "",
          facilityid: "",
        },
        queryid: "b2457a4e-2f20-40f9-b536-90ec2625cdd4",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      let data1 = [];
      data?.map((val) => {
        data1.push({
          label: val?.role?.display ?? "",
          value: val?.role?.display ?? "",
          id: val?.id,
          _id: val?.role?._id,
        });
      });
      return {
        ...defaultState.List,
        data: data1,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const ORDER_TYPE_REQ_DETAILS_LAB = createAsyncThunk(
  `LabOrderApiSlice/order_type_req_details`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { orgId, role_id } = payload;
      const body = {
        db_name: dbName,
        filter: {
          orgid: orgId,
          roldid: role_id,
          tenantid: "",
          facilityid: "",
        },
        queryid: "f64657b9-caf2-4349-8ff9-9ddad76b5762",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );

      let arr2 = [];
      data?.map((val) => {
        arr2.push({
          label: val?.name,
          value: val?._id ?? "",
          practitioner_id: val?._id ?? "",
        });
        // }
      });
      return {
        ...defaultState.Info,
        data: arr2,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

// const FETCHPERFORMINGLOCATION = createAsyncThunk(
//   `LabOrderApiSlice/PFData`,
//   async (payload = {}, { rejectWithValue }) => {
//     try {
//       const { orgId, reqData } = payload;

//       const data = await fetchData(
//         { body: queries.serviceOrder(orgId) },
//         urls.readDocuments
//       );

//       let arr = [];
//       data.result.map((val) => {
//         arr.push({
//           rules: val?.order_criteria,
//           returnData: val?.performing_details,
//         });
//       });

//       let ruleParamJson = generateJSON(reqData);

//       let ruleResponse = await Promise.all(
//         arr.map(async (val) => {
//           let rules = val.rules.map((val) => val.rule.value);
//           return await fetchData(
//             {
//               body: JSON.stringify({
//                 ruleId: rules,
//                 params: [
//                   {
//                     CA_OrderLine: ruleParamJson,
//                   },
//                 ],
//                 returnData: val.returnData,
//               }),
//             }
//             // __ruleBuilderUrl__
//           );
//         })
//       );

//       let resData = {
//         org: [],
//         location: [],
//         roleType: [],
//       };
//       let orgLoc = {};
//       ruleResponse.map((val) => {
//         if (val.params[0].responseStatus) {
//           let returnData = val.returnData;
//           returnData.map((value) => {
//             orgLoc[value.performing_location_code] = value.performing_org;
//             resData.org.push(value.performing_org);
//             resData.location.push(value.performing_location_code);
//             resData.roleType.push(value.performing_location_role_type);
//           });
//         }
//       });

//       let orgList = [];
//       if (resData.org.length > 0) {
//         const orgRes = await fetchData(
//           { body: queries.organization(resData.org) },
//           urls.readDocuments
//         );
//         orgRes?.result?.map((val) => {
//           orgList.push({
//             label: val?._id ?? "",
//             value: val?.name ?? "",
//             id: val?.orgId,
//             _id: val?._id,
//           });
//         });
//       }

//       let locList = [];
//       if (resData.location.length > 0) {
//         const locRes = await fetchData(
//           { body: queries.locationquery(resData.location) },
//           urls.readDocuments
//         );
//         locRes?.result?.map((val) => {
//           locList.push({
//             label: val?.shortdesc ?? "",
//             value: val?.shortdesc ?? "",
//             id: val?._id ?? "",
//             ordID: orgLoc[val?._id ?? ""] ?? "",
//             locationID: val?.locationID ?? "",
//           });
//         });
//       }

//       return {
//         ...defaultState.Info,
//         data: { orgList, locList },
//       };
//     } catch (error) {
//       return rejectWithValue({
//         ...defaultReject,
//         message: error.message,
//       });
//     }
//   }
// );

const GET_ORDERTYPE = createAsyncThunk(
  `LabOrderApiSlice/get_ordertype`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        queryid: "5513d71c-34f1-4d26-a50d-1a7959f05654",
      };

      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );

      let returnData = [];
      if (Array.isArray(data)) {
        data.map((val, i) => {
          returnData.push({
            value: val?._id,
            label: val?.longdesc,
            _id: val?._id,
          });
        });
      }
      return {
        ...defaultState.Info,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const GET_ORDERMODE = createAsyncThunk(
  `LabOrderApiSlice/get_ordermode`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { tenantid = "", facilityid = "", lang = "" } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["GetAllMasters"],
        filter: {
          type: ["ORDERMODE"],
          tenantid: tenantid,
          facilityid: facilityid,
          lang: lang,
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let returnData = [];
      if (Array.isArray(data)) {
        data?.[0]?.ORDERMODE?.Value?.map((val, i) => {
          returnData.push({
            value: val?._id,
            label: val?.display,
          });
        });
      }
      return {
        ...defaultState.Info,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const GET_DIAGNOSIS = createAsyncThunk(
  `LabOrderApiSlice/get_diagnosis`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { func, input_text = "" } = payload;
      const body = {
        db_name: dbName,
        filter: {
          searchcode: `%${input_text}%`,
        },
        queryid: "865e0d7d-a5be-4da9-bcb6-75ff333d32c1",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      let returnData = [];
      if (Array.isArray(data)) {
        data.map((val, i) => {
          returnData.push({
            value: val?._id,
            label: val?.icdname,
            diagnosis: val?.icdname,
          });
        });
      }
      return {
        ...defaultState.Info,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const GET_INDICATION = createAsyncThunk(
  `LabOrderApiSlice/get_indication`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { tenantid = "", facilityid = "", lang = "" } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["GetAllMasters"],
        filter: {
          type: ["INDICATIONREASON"],
          tenantid: tenantid,
          facilityid: facilityid,
          lang: lang,
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let returnData = [];
      if (Array.isArray(data)) {
        data?.[0]?.INDICATIONREASON?.Value?.map((val, i) => {
          returnData.push({
            value: val?._id,
            label: val?.display,
          });
        });
      }
      return {
        ...defaultState.Info,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const GET_PATINSTRUCTION = createAsyncThunk(
  `LabOrderApiSlice/get_patient_instruction`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { tenantid = "", facilityid = "", lang = "" } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["GetAllMasters"],
        filter: {
          type: ["PATINSTRUCTION"],
          tenantid: tenantid,
          facilityid: facilityid,
          lang: lang,
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let returnData = [];
      if (Array.isArray(data)) {
        data?.[0]?.PATINSTRUCTION?.Value?.map((val, i) => {
          returnData.push({
            value: val?._id,
            label: val?.display,
          });
        });
      }
      return {
        ...defaultState.Info,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const GET_PRE_INSTRUCTION = createAsyncThunk(
  `LabOrderApiSlice/get_pre_instruction`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { tenantid = "", facilityid = "", lang = "" } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["GetAllMasters"],
        filter: {
          type: ["PREINSTRUCTION"],
          tenantid: tenantid,
          facilityid: facilityid,
          lang: lang,
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let returnData = [];
      if (Array.isArray(data)) {
        data?.[0]?.PREINSTRUCTION?.Value?.map((val, i) => {
          returnData.push({
            value: val?._id,
            label: val?.display,
          });
        });
      }
      return {
        ...defaultState.Info,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const GET_DEP_INSTRUCTION = createAsyncThunk(
  `LabOrderApiSlice/get_dep_instruction`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { tenantid = "", facilityid = "", lang = "" } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["GetAllMasters"],
        filter: {
          type: ["DEPINSTRUCTION"],
          tenantid: tenantid,
          facilityid: facilityid,
          lang: lang,
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let returnData = [];
      if (Array.isArray(data)) {
        data?.[0]?.DEPINSTRUCTION?.Value?.map((val, i) => {
          returnData.push({
            value: val?._id,
            label: val?.display,
          });
        });
      }
      return {
        ...defaultState.Info,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const GET_BODYSITE = createAsyncThunk(
  `LabOrderApiSlice/get_bodysite`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { tenantid = "", facilityid = "", lang = "" } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["GetAllMasters"],
        filter: {
          type: ["BODYSITE"],
          tenantid: tenantid,
          facilityid: facilityid,
          lang: lang,
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let returnData = [];
      if (Array.isArray(data)) {
        data?.[0]?.BODYSITE?.Value?.map((val, i) => {
          returnData.push({
            value: val?._id,
            label: val?.display,
          });
        });
      }
      return {
        ...defaultState.Info,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const GET_UOM = createAsyncThunk(
  `LabOrderApiSlice/get_uom`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { tenantid = "", facilityid = "", lang = "" } = payload;
      const body = {
        db_name: dbName,
        queryid: query_ids["GetAllMasters"],
        filter: {
          type: ["UOM"],
          tenantid: tenantid,
          facilityid: facilityid,
          lang: lang,
        },
      };
      const data = await fetchData(
        {
          body: JSON.stringify(body),
        },
        urls.readQdmQueries
      );
      let returnData = [];
      if (Array.isArray(data)) {
        data?.[0]?.UOM?.Value?.map((val, i) => {
          returnData.push({
            value: val?._id,
            label: val?.display,
          });
        });
      }
      return {
        ...defaultState.Info,
        data: returnData,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

// const GET_SPECIMENTYPE = createAsyncThunk(
//   `LabOrderApiSlice/get_specimentype`,
//   async (payload = {}, { rejectWithValue }) => {
//     try {
//       const body = {
//         // db_name: dbName,
//         // entity: "CodeableConceptMaster",
//         // filter: "CodeableConceptMaster.Type=='SPECIMENTYPE'",
//         // return_fields:
//         // 	"MERGE(CodeableConceptMaster,{coding:(FOR cod IN CodeableConceptMaster.coding RETURN DOCUMENT(cod))})",
//         db_name: dbName,
//         entity: "CodingMaster",
//         filter:
//           "CodingMaster.Type == 'SPECIMENTYPE' && CodingMaster.status == true && CodingMaster.activestatus == true",
//         return_fields:
//           "KEEP(CodingMaster,'_id','id','_key','code','display','Type','status','activestatus')",
//         sort: "CodingMaster.display",
//       };
//       const data = await fetchData(
//         { body: JSON.stringify(body) },
//         urls.readDocuments
//       );
//       let returnData = [];
//       if (Array.isArray(data.result)) {
//         data.result.map((val, i) => {
//           returnData.push({
//             // value: val?.coding[0]?.display,
//             // label: val?.coding[0]?.display,
//             value: val?.display,
//             label: val?._id,
//           });
//         });
//       }
//       return {
//         ...defaultState.Info,
//         data: returnData,
//       };
//     } catch (err) {
//       return rejectWithValue({
//         ...defaultReject,
//         message: err.message,
//       });
//     }
//   }
// );
// const GET_SPECCOLLMETHOD = createAsyncThunk(
//   `LabOrderApiSlice/get_speccollmethod`,
//   async (payload = {}, { rejectWithValue }) => {
//     try {
//       const body = {
//         db_name: dbName,
//         entity: "CodeableConceptMaster",
//         filter: "CodeableConceptMaster.Type=='SPECCOLLMETHOD'",
//         return_fields:
//           "MERGE(CodeableConceptMaster,{coding:(FOR cod IN CodeableConceptMaster.coding RETURN DOCUMENT(cod))})",
//       };
//       const data = await fetchData(
//         { body: JSON.stringify(body) },
//         urls.readDocuments
//       );
//       let returnData = [];
//       if (Array.isArray(data.result)) {
//         data.result.map((val, i) => {
//           returnData.push({
//             value: val?.coding[0]?.display,
//             label: val?.coding[0]?.display,
//           });
//         });
//       }
//       return {
//         ...defaultState.Info,
//         data: returnData,
//       };
//     } catch (err) {
//       return rejectWithValue({
//         ...defaultReject,
//         message: err.message,
//       });
//     }
//   }
// );
const LABORDER_ORGANIZATION = createAsyncThunk(
  `LabOrderApiSlice/laborder_orgnnization`,
  async (payload = {}, { rejectWithValue }) => {
    const { id, role_id } = payload;
    try {
      let body = {
        db_name: dbName,
        queryid: "6d18b211-1c07-4313-a8ef-3d409137c86d",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      let arr = [];
      data?.map((val) => {
        arr.push({
          label: val?._id ?? "",
          value: val?.name ?? "",
          id: val?.orgId,
        });
      });

      return {
        ...defaultState.List,
        data: {
          org: arr,
        },
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      }); 
    }
  }
);
const ORDER_DELETE = createAsyncThunk(
  `LabOrderApiSlice/order_delete`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { id, isReccDel,reason,reasondesc} = payload;
      let body = {
        orderlineId:id,
        isRecurrentDelete:isReccDel,
        reason:reason,
        reasondesc:reasondesc
      };

      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.deleteOrder
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const GET_RESULT_DATA = createAsyncThunk(
  `LabOrderApiSlice/get_resultdata`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { func, patientid = "" , encounterid="" , gender="", ageindays=0, Oltype="",orderlineid="" } = payload;
      const body = {
        db_name: dbName,
        filter: {
          patientid:patientid,
	        encounterid:encounterid,
	        tenantid: "",
	        facilityid: "",
	        gender: gender,
	        ageindays: ageindays,
          orderlineid:orderlineid,
          Oltype:Oltype,
        },
        queryid: "891bfb4c-3723-4987-bebb-961fc6e446a1",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      
      return {
        ...defaultState.Info,
        data: data?.[0],
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);
const SAVE_RESULT_DATA = createAsyncThunk(
  `LabOrderApiSlice/save_resultdata`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      let { doc, _id } = payload;
      let body = [];
      if (_id) {
        let compData = doc?.components.reduce((acc, curr) => {
          if (!acc[curr.parentordercatid]) {
            acc[curr.parentordercatid] = [];
          }
          acc[curr.parentordercatid].push(curr);
          return acc;
        }, {});
        Object.keys(compData)?.map((v,i)=>{
          // const hasResultValue = compData[v]?.some((j) => j?.resultvalue);
          // if (hasResultValue) {
            let compArr = []
            compData[v]?.map((j,k)=>{
              // if(j?.resultvalue){
                let componentObj = {
                  resultvalue:  j?.resultvalue ? j?.resultvalue : j?.ordervalue ?? "",//j?.ordervalue !== "" ? j?.resultvalue ?? j?.ordervalue : j?.resultvalue,
                  flag: j?.flag,
                  ordermax: j?.ordermax ?? null,
                  ordermin: j?.ordermin ?? null,
                  resultuom: j?.resultuom ?? j?.orderuom ?? "",
                  parentordercatid: j?.parentordercatid ?? "",
                  remarks: j?.remarks,
                  method: j?.method,
                  vitalrefid: j?.vitalrefid ?? "",
                  attachments: j?.attachments ?? [],
                  richtext: j.richtext ?? "",
                  resultreport: j.resultreport ?? "",
                }
                compArr.push(componentObj)
              // }
            })        
            let obj = {
              db_name: dbName,
              entity: "CA_Orderresults",
              is_metadata: true,
              metadata_dbname: process.env.REACT_APP_META_DB_NAME,
              metadataId: process.env.REACT_APP_METADATA_ID,
              filter: {
                _id: `${_id}`,
              },
              doc: {
                components:compArr,
                tenantid: doc?.tenantid,
                encounterid: doc?.encounterid,
                facilityid: doc?.facilityid,
                patientid: doc?.patientid,
                orderlineid: compData[v][i]?.orderlineid,
                ordercatlogid: compData[v][i]?.parentordercatid,
              },
            }
            body.push(obj);
          // }
        })
      
        // doc?.components?.map((v,i)=>{
        //   let obj = {
        //     db_name: dbName,
        //     entity: "CA_Orderresults",
        //     is_metadata: true,
        //     filter: {
        //       _id: `${_id}`,
        //     },
        //     metadata_dbname: process.env.REACT_APP_META_DB_NAME,
        //     metadataId: process.env.REACT_APP_METADATA_ID,
        //     doc: v,
        //   }
        //   body.push(obj);
        // })
        // body = [
        //   {
        //     db_name: dbName,
        //     entity: "CA_Orderresults",
        //     is_metadata: true,
        //     filter: {
        //       _id: `${_id}`,
        //     },
        //     metadata_dbname: process.env.REACT_APP_META_DB_NAME,
        //     metadataId: process.env.REACT_APP_METADATA_ID,
        //     doc: doc,
        //   },
        // ];
      } else {
        let compData = doc?.components.reduce((acc, curr) => {
          if (!acc[curr.orderlineid]) {
            acc[curr.orderlineid] = [];
          }
          acc[curr.orderlineid].push(curr);
          return acc;
        }, {});
        Object.keys(compData)?.map((v,i)=>{
          const hasResultValue = compData[v]?.some((j) => j?.resultvalue);
          if (hasResultValue) {
            let compArr = []
            compData[v]?.map((j,k)=>{
              // if(j?.resultvalue){
                let componentObj = {
                  resultvalue: j?.resultvalue ?? "",
                  flag: j?.flag,
                  ordermax: j?.ordermax ?? 0,
                  ordermin: j?.ordermin ?? 0,
                  resultuom: j?.resultuom ?? "",
                  parentordercatid: j?.parentordercatid ?? "",
                  remarks: j?.remarks,
                  method: j?.method,
                  vitalrefid: j?.vitalrefid ?? "",
                  attachments: j?.attachments ?? [],
                  richtext: j.richtext ?? "",
                  resultreport: j.resultreport ?? "",
                }
                compArr.push(componentObj)
              // }
            })   
            let obj = {
              db_name: dbName,
              entity: "CA_Orderresults",
              is_metadata: true,
              metadata_dbname: process.env.REACT_APP_META_DB_NAME,
              metadataId: process.env.REACT_APP_METADATA_ID,
              doc: {
                components:compArr,
                tenantid: doc?.tenantid,
                encounterid: doc?.encounterid,
                facilityid: doc?.facilityid,
                patientid: doc?.patientid,
                orderlineid: v,//compData[v][i]?.orderlineid,
                ordercatlogid: compData[v][i]?.parentordercatid,
              },
            }
            body.push(obj);
          }
        })
      }
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.saveLabResult
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

const GET_ALL_LAB_RESULT = createAsyncThunk(
  `LabOrderApiSlice/get_alllabresult`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { patientid = "" , encounterid="" ,orderlineid = "",oltype, tenantId, facilityId } = payload;
      const body = {
        db_name: dbName,
        filter: {
          patientid:patientid,
	        encounterid:encounterid,
          orderlineid:orderlineid,
	        tenantid: tenantId,
	        facilityid: facilityId,
          oltype:oltype,
        },
        queryid: "dce7ea92-e96d-488e-95bf-7b7261cda3e8",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );
      
      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (err) {
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);


const LabOrderApiSlice = createSlice({
  name: "LabOrderApiSlice",
  initialState: {
    getordercatalog: {
      ...defaultState.List,
    },
    getordernursecatalog: {
      ...defaultState.List,
    },
    getpackageorder:{
      ...defaultState.List
    },
    getpriority: {
      ...defaultState.List,
    },
    getordernature: {
      ...defaultState.List,
    },
    childlaborder: {
      ...defaultState.List,
    },
    nursingprocedure: {
      ...defaultState.List,
    },
    setlaborders: {
      ...defaultState.List,
    },
    set_nursingprocedure: {
      ...defaultState.List,
    },
    set_packageorder: {
      ...defaultState.List,
    },
    readorder: {
      ...defaultState.List,
    },
    getordertype: {
      ...defaultState.List,
    },
    getordermode: {
      ...defaultState.List,
    },
    getdiagnosis: {
      ...defaultState.List,
    },
    getindication: {
      ...defaultState.List,
    },
    getpatientinstruction: {
      ...defaultState.List,
    },
    getprepatientinstruction: {
      ...defaultState.List,
    },
    getdepinstruction: {
      ...defaultState.List,
    },
    getbodysite: {
      ...defaultState.List,
    },
    getuom: {
      ...defaultState.List,
    },
    // getspecimentype: {
    //   ...defaultState.List,
    // },
    // getspeccollmethod: {
    //   ...defaultState.List,
    // },
    laborderorgnnization: {
      ...defaultState.List,
    },
    orderdelete: {
      ...defaultState.List,
    },
    practioner_role: {
      ...defaultState.List,
    },
    order_type_req_details: {
      ...defaultState.List,
    },
    get_location: {
      ...defaultState.List,
    },
    get_resultdata: {
      ...defaultState.List,
    },
    save_resultdata: {
      ...defaultState.List,
    },
    get_alllabresult: {
      ...defaultState.List,
    },
  },
  extraReducers: {
    // GET ORDER CATALOG list
    [GET_ORDERCATALOG.fulfilled]: (state, action) => {
      state.getordercatalog = action?.payload ?? [];
    },
    [GET_ORDERCATALOG.pending]: (state, action) => {
      state.getordercatalog.loading = true;
    },
    [GET_ORDERCATALOG.rejected]: (state, action) => {
      state.getordercatalog = action?.payload ?? [];
    },

    // GET ORDER NURSE CATALOG list
    [GET_ORDERNURSECATALOG.fulfilled]: (state, action) => {
      state.getordernursecatalog = action?.payload ?? [];
    },
    [GET_ORDERNURSECATALOG.pending]: (state, action) => {
      state.getordernursecatalog.loading = true;
    },
    [GET_ORDERNURSECATALOG.rejected]: (state, action) => {
      state.getordernursecatalog = action?.payload ?? [];
    },

    // GET Package Order

    [GET_PACKAGEORDER.fulfilled]: (state, action) => {
      state.getpackageorder = action?.payload ?? [];
    },
    [GET_PACKAGEORDER.pending]: (state, action) => {
      state.getpackageorder.loading = true;
    },
    [GET_PACKAGEORDER.rejected]: (state, action) => {
      state.getpackageorder = action?.payload ?? [];
    },

    // GET PRIORITY List
    [GET_PRIORITY.fulfilled]: (state, action) => {
      state.getpriority = action?.payload ?? [];
    },
    [GET_PRIORITY.pending]: (state, action) => {
      state.getpriority.loading = true;
    },
    [GET_PRIORITY.rejected]: (state, action) => {
      state.getpriority = action?.payload ?? [];
    },

    // GET ORDER NATURE CATALOG list
    [GET_ORDERNATURE.fulfilled]: (state, action) => {
      state.getordernature = action?.payload ?? [];
    },
    [GET_ORDERNATURE.pending]: (state, action) => {
      state.getordernature.loading = true;
    },
    [GET_ORDERNATURE.rejected]: (state, action) => {
      state.getordernature = action?.payload ?? [];
    },
    //READ_LABORDER_CHILD
    [READ_LABORDER_CHILD.fulfilled]: (state, action) => {
      state.childlaborder = action?.payload ?? [];
    },
    [READ_LABORDER_CHILD.pending]: (state, action) => {
      state.childlaborder.loading = true;
    },
    [READ_LABORDER_CHILD.rejected]: (state, action) => {
      state.childlaborder = action?.payload ?? [];
    },

    //READ_NURSINGPROCEDURE
    [READ_NURSINGPROCEDURE.fulfilled]: (state, action) => {
      state.nursingprocedure = action?.payload ?? [];
    },
    [READ_NURSINGPROCEDURE.pending]: (state, action) => {
      state.nursingprocedure.loading = true;
    },
    [READ_NURSINGPROCEDURE.rejected]: (state, action) => {
      state.nursingprocedure = action?.payload ?? [];
    },

    // SET_LAB_ORDERS_SERVICE
    [SET_LAB_ORDERS_SERVICE.fulfilled]: (state, action) => {
      state.setlaborders = action?.payload ?? {};
    },
    [SET_LAB_ORDERS_SERVICE.pending]: (state, action) => {
      state.setlaborders.loading = true;
    },
    [SET_LAB_ORDERS_SERVICE.rejected]: (state, action) => {
      state.setlaborders = action?.payload ?? {};
    },
   // SET_NURSINGPROCEDURE_SERVICE
   [SET_NURSINGPROCEDURE_SERVICE.fulfilled]: (state, action) => {
    state.set_nursingprocedure = action?.payload ?? {};
  },
  [SET_NURSINGPROCEDURE_SERVICE.pending]: (state, action) => {
    state.set_nursingprocedure.loading = true;
  },
  [SET_NURSINGPROCEDURE_SERVICE.rejected]: (state, action) => {
    state.set_nursingprocedure = action?.payload ?? {};
  },
   // SET_PACKAGEORDER_SERVICE
   [SET_PACKAGEORDER_SERVICE.fulfilled]: (state, action) => {
    state.set_packageorder = action?.payload ?? {};
  },
  [SET_PACKAGEORDER_SERVICE.pending]: (state, action) => {
    state.set_packageorder.loading = true;
  },
  [SET_PACKAGEORDER_SERVICE.rejected]: (state, action) => {
    state.set_packageorder = action?.payload ?? {};
  },

    [LABORDER_READ.fulfilled]: (state, action) => {
      state.readorder = action?.payload ?? [];
    },
    [LABORDER_READ.pending]: (state, action) => {
      state.readorder.loading = true;
    },
    [LABORDER_READ.rejected]: (state, action) => {
      state.readorder = action?.payload ?? [];
    },

    // GET ORDER NATURE CATALOG list
    [GET_ORDERTYPE.fulfilled]: (state, action) => {
      state.getordertype = action?.payload ?? [];
    },
    [GET_ORDERTYPE.pending]: (state, action) => {
      state.getordertype.loading = true;
    },
    [GET_ORDERTYPE.rejected]: (state, action) => {
      state.getordertype = action?.payload ?? [];
    },
    // GET ORDER MODE
    [GET_ORDERMODE.fulfilled]: (state, action) => {
      state.getordermode = action?.payload ?? [];
    },
    [GET_ORDERMODE.pending]: (state, action) => {
      state.getordermode.loading = true;
    },
    [GET_ORDERMODE.rejected]: (state, action) => {
      state.getordermode = action?.payload ?? [];
    },

    [GET_DIAGNOSIS.fulfilled]: (state, action) => {
      state.getdiagnosis = action?.payload ?? [];
    },
    [GET_DIAGNOSIS.pending]: (state, action) => {
      state.getdiagnosis.loading = true;
    },
    [GET_DIAGNOSIS.rejected]: (state, action) => {
      state.getdiagnosis = action?.payload ?? [];
    },

    [GET_INDICATION.fulfilled]: (state, action) => {
      state.getindication = action?.payload ?? [];
    },
    [GET_INDICATION.pending]: (state, action) => {
      state.getindication.loading = true;
    },
    [GET_INDICATION.rejected]: (state, action) => {
      state.getindication = action?.payload ?? [];
    },

    [GET_PATINSTRUCTION.fulfilled]: (state, action) => {
      state.getpatientinstruction = action?.payload ?? [];
    },
    [GET_PATINSTRUCTION.pending]: (state, action) => {
      state.getpatientinstruction.loading = true;
    },
    [GET_PATINSTRUCTION.rejected]: (state, action) => {
      state.getpatientinstruction = action?.payload ?? [];
    },
    [GET_PRE_INSTRUCTION.fulfilled]: (state, action) => {
      state.getprepatientinstruction = action?.payload ?? [];
    },
    [GET_PRE_INSTRUCTION.pending]: (state, action) => {
      state.getprepatientinstruction.loading = true;
    },
    [GET_PRE_INSTRUCTION.rejected]: (state, action) => {
      state.getprepatientinstruction = action?.payload ?? [];
    },
    [GET_DEP_INSTRUCTION.fulfilled]: (state, action) => {
      state.getdepinstruction = action?.payload ?? [];
    },
    [GET_DEP_INSTRUCTION.pending]: (state, action) => {
      state.getdepinstruction.loading = true;
    },
    [GET_DEP_INSTRUCTION.rejected]: (state, action) => {
      state.getdepinstruction = action?.payload ?? [];
    },
    [GET_BODYSITE.fulfilled]: (state, action) => {
      state.getbodysite = action?.payload ?? [];
    },
    [GET_BODYSITE.pending]: (state, action) => {
      state.getbodysite.loading = true;
    },
    [GET_BODYSITE.rejected]: (state, action) => {
      state.getbodysite = action?.payload ?? [];
    },
    [GET_UOM.fulfilled]: (state, action) => {
      state.getuom = action?.payload ?? [];
    },
    [GET_UOM.pending]: (state, action) => {
      state.getuom.loading = true;
    },
    [GET_UOM.rejected]: (state, action) => {
      state.getuom = action?.payload ?? [];
    },
    // [GET_SPECIMENTYPE.fulfilled]: (state, action) => {
    //   state.getspecimentype = action?.payload ?? [];
    // },
    // [GET_SPECIMENTYPE.pending]: (state, action) => {
    //   state.getspecimentype.loading = true;
    // },
    // [GET_SPECIMENTYPE.rejected]: (state, action) => {
    //   state.getspecimentype = action?.payload ?? [];
    // },
    // [GET_SPECCOLLMETHOD.fulfilled]: (state, action) => {
    //   state.getspeccollmethod = action?.payload ?? [];
    // },
    // [GET_SPECCOLLMETHOD.pending]: (state, action) => {
    //   state.getspeccollmethod.loading = true;
    // },
    // [GET_SPECCOLLMETHOD.rejected]: (state, action) => {
    //   state.getspeccollmethod = action?.payload ?? [];
    // },
    [LABORDER_ORGANIZATION.fulfilled]: (state, action) => {
      state.laborderorgnnization = action?.payload ?? [];
    },
    [LABORDER_ORGANIZATION.pending]: (state, action) => {
      state.laborderorgnnization.loading = true;
    },
    [LABORDER_ORGANIZATION.rejected]: (state, action) => {
      state.laborderorgnnization = action?.payload ?? [];
    },
    [ORDER_DELETE.fulfilled]: (state, action) => {
      state.orderdelete = action?.payload ?? {};
    },
    [ORDER_DELETE.pending]: (state, action) => {
      state.orderdelete.loading = true;
    },
    [ORDER_DELETE.rejected]: (state, action) => {
      state.orderdelete = action?.payload ?? {};
    },
    // ORDER_TYPE_REQ_DETAILS_ROLE
    [ORDER_TYPE_REQ_DETAILS_ROLE.fulfilled]: (state, action) => {
      state.practioner_role = action?.payload ?? [];
    },
    [ORDER_TYPE_REQ_DETAILS_ROLE.pending]: (state, action) => {
      state.practioner_role.loading = true;
    },
    [ORDER_TYPE_REQ_DETAILS_ROLE.rejected]: (state, action) => {
      state.practioner_role = action?.payload ?? [];
    },
    [ORDER_TYPE_REQ_DETAILS_LAB.fulfilled]: (state, action) => {
      state.order_type_req_details = action?.payload ?? [];
    },
    [ORDER_TYPE_REQ_DETAILS_LAB.pending]: (state, action) => {
      state.order_type_req_details.loading = true;
    },
    [ORDER_TYPE_REQ_DETAILS_LAB.rejected]: (state, action) => {
      state.order_type_req_details = action?.payload ?? [];
    },
    [GET_LOCATION_ALL.fulfilled]: (state, action) => {
      state.get_location = action?.payload ?? [];
    },
    [GET_LOCATION_ALL.pending]: (state, action) => {
      state.get_location.loading = true;
    },
    [GET_LOCATION_ALL.rejected]: (state, action) => {
      state.get_location = action?.payload ?? [];
    },
    [GET_RESULT_DATA.fulfilled]: (state, action) => {
      state.get_resultdata = action?.payload ?? [];
    },
    [GET_RESULT_DATA.pending]: (state, action) => {
      state.get_resultdata.loading = true;
    },
    [GET_RESULT_DATA.rejected]: (state, action) => {
      state.get_resultdata = action?.payload ?? [];
    },
    [SAVE_RESULT_DATA.fulfilled]: (state, action) => {
      state.save_resultdata = action?.payload ?? [];
    },
    [SAVE_RESULT_DATA.pending]: (state, action) => {
      state.save_resultdata.loading = true;
    },
    [SAVE_RESULT_DATA.rejected]: (state, action) => {
      state.save_resultdata = action?.payload ?? [];
    },
    [GET_ALL_LAB_RESULT.fulfilled]: (state, action) => {
      state.get_alllabresult = action?.payload ?? [];
    },
    [GET_ALL_LAB_RESULT.pending]: (state, action) => {
      state.get_alllabresult.loading = true;
    },
    [GET_ALL_LAB_RESULT.rejected]: (state, action) => {
      state.get_alllabresult = action?.payload ?? [];
    },
  },
});

export const laborderActions = {
  GET_ORDERCATALOG,
  GET_ORDERNURSECATALOG,
  GET_PRIORITY,
  GET_ORDERNATURE,
  READ_LABORDER_CHILD,
  SET_LAB_ORDERS_SERVICE,
  LABORDER_READ,
  GET_ORDERTYPE,
  GET_ORDERMODE,
  GET_DIAGNOSIS,
  GET_INDICATION,
  GET_PATINSTRUCTION,
  GET_PRE_INSTRUCTION,
  GET_DEP_INSTRUCTION,
  GET_BODYSITE,
  GET_UOM,
  //GET_SPECIMENTYPE,
  //GET_SPECCOLLMETHOD,
  LABORDER_ORGANIZATION,
  ORDER_DELETE,
  ORDER_TYPE_REQ_DETAILS_LAB,
  ORDER_TYPE_REQ_DETAILS_ROLE,
  GET_LOCATION_ALL,
  GET_RESULT_DATA,
  SAVE_RESULT_DATA,
  GET_ALL_LAB_RESULT,
  READ_NURSINGPROCEDURE,
  SET_NURSINGPROCEDURE_SERVICE,
  SET_PACKAGEORDER_SERVICE,
  GET_PACKAGEORDER,
};
export default LabOrderApiSlice;
