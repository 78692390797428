import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import {
  Container,
  Div,
  Icons,
  SideMenu,
  Text,
  Image,
  Divider,
  H6,
  H1,
  Paper,
} from "qdm-component-library";
import jwtDecode from "jwt-decode";
import SearchIcon from "../../assets/img/svg/icons8-search.svg";
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Typography,
  Fab,
  CircularProgress,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { TopNavbar } from "../../components";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AppHeader from "../../components/layout/appHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../router/routes";
// import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import SearchFab from "../dashboard/searchPatient/components/searchFab";
import { Autocomplete } from "@mui/material";
import SearchForm from "../dashboard/searchPatient/components/searchForm";
import { UIColor } from "../../themes/theme";
import { PatientListView } from "../visitRegistration/components";
import actions from "../../redux/actions";
import PatientCard from "../patientDashboard/components/patientCard";
import ServiceCard from "./serviceCard";
import AppointmentBookingMultiple from "../appointmentBooking/appointmentBookingMultiple";
import StaticPatientCard from "./staticPatientCard";
import { useSelector } from "react-redux";
import { AlertProps, getUtcTime, query_ids, __tenantid__, dbName } from "../../utils";
import { AlertContext } from "../../context";
import moment from "moment";
import { json } from "body-parser";
// import { formatDate } from "@fullcalendar/core";
const MultiresourceBooking = () => {
  const userInfo = localStorage?.getItem("UserInfo");
  const jwtToken = localStorage?.getItem("JwtToken");
  const decodedUserinfo =
    userInfo === null || userInfo === undefined ? "" : jwtDecode(userInfo);
  const _facility_ = decodedUserinfo?.facilityid ?? "";
  const alert = useContext(AlertContext);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    service: "",
    duration: "",
    proposedDate: "",
    scrollTime: "8:00"
  });
  const [resource, setResource] = useState({
    resourceType: "",
    resourceRole: "",
    resourceDepartment: "",
    resourceName: "",
  });
  const [readonly, setReadonly] = useState(false);
  const [resourceData, setResourceData] = useState([]);
  const [dayEvents, setDayEvents] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [showcontents, setshowcontents] = useState(false);
  const [openSearchModal, setopenSearchModal] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [resourceTypes, setResourceTypes] = useState([]);
  const [resourceRoletypes, setresourceRoletypes] = useState([]);
  const [resourceDepartmenttypes, setresourceDepartmenttypes] = useState([]);
  const [resourceNamestypes, setresourceNamestypes] = useState([]);
  const [events, setEvents] = useState([]);
  const [slot, setSlot] = useState([]);
  const [startTimeget, setstartTimeget] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    patientDetails: {},
    newPatientDetails: {},
  });

  ////////////
  const [serviceType, setserviceType] = useState([]);

  const fetchResourceTypes = async () => {
    const payload = {
      db_name: dbName,
      filter: {
        type: ["RESOURCETYPE"],
        tenantid: "",
        facilityid: "",
        lang: "",
      },
      queryid: query_ids?.mulitiresourceDefition,
    };

    try {
      const response = await fetch(
        process?.env?.REACT_APP_ARANGO_URL_READ_QDMQUERIES,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();
      setResourceTypes(data[0].RESOURCETYPE.Value);
    } catch (error) {
      console.error("Error fetching resource types:", error);
    }
  };

  const getServiceTypes = async () => {
    const serviceval = await dispatch(
      actions.MULTIRESOURCE_SERVICE_GET(
        location?.state?.apptypecode ?? location?.state?.appointmentDetails?.appointmentTypeID?.[0]
      )
    );
    if(location?.state?.appointmentid){
      setFormData((prevState) => ({
        ...prevState,
        service: serviceval?.payload?.data?.[0]
      }));
    }
    setserviceType(serviceval?.payload?.data);
  };

  const resourceFromservice = async (id) => {
    const resourceservice = await dispatch(actions.RESOURCE_GET(id));
    const resourceDatas = resourceservice?.payload?.data.map((resource) => ({
      resourceType: resource.resourcetype || "",
      resourceRole: resource.resourcerole || "",
      resourceDepartment: resource.resourcedepartment || "",
      resourceName: resource.resourcename || "",
    }));
    setResourceData(resourceDatas);
    // const constructData = resourceservice?.payload?.data.map((resource) => ({
    //   id: resource?.resourcename?._id,
    //   title: resource?.resourcename?.display,
    // }));
    // setconstrctDatas(constructData);
  };
  
  const fetchInfo = async () => {
    
    const patientInfo = await dispatch(
      actions.PATIENT_INFO({
        patientid: location?.state?.appointmentDetails?.patient_id,
        tenantid: "",
        facilityid: "",
        lang: "",
        personid: "",
      })
    );
    resourceFromservice("RC_ChargeCode/10275");
    setState((prevState) => ({
      ...prevState,
      newPatientDetails:
      patientInfo?.payload?.data?.patientdetails || null,
    }));
  };
  useEffect(() => {
    if(location?.state?.appointmentid){
      getSelectedSlotEvent([],location?.state?.start,location?.state?.end,formData?.service?.display,formData?.duration);
    }
  }, [resourceData]);
  useEffect(() => {
    getServiceTypes();
    if(location?.state?.appointmentid){
      setFormData((prevState) => ({
        ...prevState,
        duration: convertMinutesToTime(location?.state?.appointmentDetails?.minutesDuration),
        proposedDate: location?.state?.formattedDate,
        scrollTime: location?.state?.start.split(" ")?.[1]
      }));
      setReadonly(true);
      fetchInfo();
      setshowcontents(true);
    }
    else{
      fetchResourceTypes();
      const today = new Date();
      const formattedDate = today.toISOString().split("T")[0];
      const dynamicDate = location?.state?.patientDetails?.date;
      const convertDate = dynamicDate?.toISOString()?.split("T")[0];
      setFormData((prevState) => ({
        ...prevState,
        proposedDate: !location.state ? formattedDate : convertDate, // Set today's date as the default value
      }));
      if (location.state) {
        setState((prevState) => ({
          ...prevState,
          newPatientDetails:
            location.state.data?.payload?.data?.patientdetails || null,
        }));
      }
    }
  }, []);

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };
  const handleInputChange = async (event, newValue) => {
    const { name, value } = event.target;
    if (name === "service" && newValue) {
      setFormData({
        ...formData,
        [name]: newValue,
      });
      // resourceFromservice(newValue?._id);
      // await dispatch(actions.RESOURCE_GET(newValue?._id));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
      if (name == "service") {
        resourceFromservice(value?._id);
      }
    }
    if (value?.value == "Heart Surgery") {
      const setDefautValue = [
        {
          id: "001",
          resourceType: "Practitioner",
          resourceRole: "Senior Consultant",
          resourceDepartment: "Cardiologist",
          resourceName: "Dr. Yong Quek Wei",
        },
        {
          id: "002",
          resourceType: "Practitioner",
          resourceRole: "Cardiothoracic Surgeon",
          resourceDepartment: "Cardiologist",
          resourceName: "Dr. Timothy Lee",
        },
        {
          id: "003",
          resourceType: "Practitioner",
          resourceRole: "Anesthesiologist",
          resourceDepartment: "Cardiologist",
          resourceName: "Dr. Tan Yew Oo",
        },
        {
          id: "004",
          resourceType: "Practitioner",
          resourceRole: "Surgical Assistant",
          resourceDepartment: "Cardiologist",
          resourceName: "Assoc. Prof. Low Boon Yong ",
        },
        {
          id: "005",
          resourceType: "Practitioner",
          resourceRole: "Nurse",
          resourceDepartment: "Cardiologist",
          resourceName: "Dr. Yong Enma",
        },
      ];
      setResourceData(setDefautValue);
    } else if (value?.value == "Spine Surgery") {
      const setDefautValue = [
        {
          id: "001",
          resourceType: "Practitioner",
          resourceRole: "Orthopedic Surgeon",
          resourceDepartment: "Orthopedics",
          resourceName: "Dr. Mark Tan",
        },
        {
          id: "002",
          resourceType: "Practitioner",
          resourceRole: "Perfusionist",
          resourceDepartment: "Orthopedics",
          resourceName: "Dr David Wong",
        },
        {
          id: "003",
          resourceType: "Practitioner",
          resourceRole: "Anesthesiologist",
          resourceDepartment: "Orthopedics",
          resourceName: "Dr. Lee Haw Chou ",
        },
        {
          id: "004",
          resourceType: "Practitioner",
          resourceRole: "Surgical Assistant",
          resourceDepartment: "Orthopedics",
          resourceName: "Dr. Yue Wai Mun",
        },
      ];
      setResourceData(setDefautValue);
    } else if (value?.value == "Obesity & Bariatric Surgery") {
      const setDefautValue = [
        {
          id: "001",
          resourceType: "Practitioner",
          resourceRole: "Bariatric Surgeon",
          resourceDepartment: "Laparoscopic",
          resourceName: "Dr. Lau Wen Liang Joel",
        },
        {
          id: "002",
          resourceType: "Practitioner",
          resourceRole: "Anesthesiologist",
          resourceDepartment: "Laparoscopic",
          resourceName: "Prof. Davide Lomanto",
        },
      ];
      setResourceData(setDefautValue);
    } else if (value?.value == "Vascular Surgery") {
      const setDefautValue = [
        {
          id: "001",
          resourceType: "Practitioner",
          resourceRole: "Radiologist",
          resourceDepartment: "Endovascular",
          resourceName: "Dr. Hong Qiantai ",
        },
        {
          id: "002",
          resourceType: "Practitioner",
          resourceRole: "Perfusionist",
          resourceDepartment: "Endovascular",
          resourceName: "Dr. Yong Enming",
        },
        {
          id: "003",
          resourceType: "Practitioner",
          resourceRole: "Physical Therapist",
          resourceDepartment: "Endovascular",
          resourceName: "Adj. Asso. Prof. Tan  ",
        },
      ];
      setResourceData(setDefautValue);
    }
  };
  const handleResourceSubmit = () => {
    if (editIndex !== null) {
      const updatedResourceData = [...resourceData];
      updatedResourceData[editIndex] = resource;
      setResourceData(updatedResourceData);
      setEditIndex(null);
    } else {
      setResourceData([...resourceData, resource]);
    }
    setResource({
      resourceType: "",
      resourceRole: "",
      resourceDepartment: "",
      resourceName: "",
    });
    handleModalClose();
  };

  const getSelectedSlotEvent = (eventData,start,end,display,duration) => {
    
    let eventView = JSON.parse(JSON.stringify(eventData));
    resourceData.forEach((id) => {
      eventView.push({
        start: start,
        end: end,
        // id: uuidv4(),
        resourceId: id.resourceName?._id,
        color: "grey",
        extendedProps: { color: "grey"},
        title: display + " " + duration,
      });
    });
    setEvents(eventView);
  };

  const convertTimeToMinutes = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };
  const convertMinutesToTime = (time) => {
    let Hours = Math.floor(time /60);
    let minutes = time % 60;
    
    return String(Hours).padStart(2, "0") + ":" + String(minutes).padStart(2, "0");
  };

  const durationInMinutes = convertTimeToMinutes(formData?.duration);
  const bookAppointment = async () => {
    setLoading(true);
    let appointmentTypeID = [location?.state?.apptypecode];
    let array = resourceData?.map((resource) => ({
      resourcetype: resource.resourceType?._id || "",
      resourcerole: resource.resourceRole?._id || "",
      resourcecode: resource.resourceName?._id || "",
      slotID: slot
        .filter((id) => id.resourceId == resource.resourceName?._id)
        .map((s) => s?.extendedProps?._id),
    }));
    let SpecialtyID = resourceData.map((item) => item?.resourceDepartment?._id);
    ////Start date///
    const dateObj = new Date(startTimeget);
    const start = dateObj.getTime()/1000;
    ///// end date ////
    let date = new Date(startTimeget);
    let [hours, minutes] = formData?.duration.split(":").map(Number);
    date.setTime(date.getTime() + hours * 60 * 60 * 1000 + minutes * 60 * 1000);
    let end = date.getTime()/1000;

    ////////patient Id////////
    let patientId =
      location?.state?.patientDetails?.patientId ||
      state?.newPatientDetails?.patientId;
    ///////person id ///////
    let personId =
      location?.state?.patientDetails?.userId ||
      state?.newPatientDetails?.userId;
    ///////facilityid//////
    let facilityId = _facility_;
    ////////tenenid////////
    let tenantid = __tenantid__;
    /////////mobile//////
    let mobile = location?.data?.payload?.data?.patientdetails?.telecom?.find(
      (p) => p.system.display == "Phone"
    )?.value;
    ////////duration///////
    const appointment = await dispatch(
      actions.SAVE_MULTIRESOURCE_BOOKING({
        appointmentTypeID,
        array,
        SpecialtyID,
        start,
        end,
        patientId,
        personId,
        facilityId,
        tenantid,
        mobile,
        durationInMinutes,
      })
    );
    if (appointment?.payload?.error || appointment?.payload?.data?.error) {
      setLoading(false);
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Appointment Booked failed",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    } else {
      setLoading(false);
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.success,
        msg: "Appointment Booked successfully",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
      navigate(AppRoutes.calender);
    }
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    setResource(resourceData[index]);
    handleModalOpen();
  };
  const handleDelete = (index) => {
    const updatedResourceData = resourceData.filter((_, i) => i !== index); // Remove item
    setResourceData(updatedResourceData);
  };

  const backArrow = () => {
    navigate(AppRoutes.calender);
  };
  const nextButClick = async () => {
    if (!formData?.service) {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please Select the Service",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    }
    let value = location?.state?.appointmentDetails;
    const colorCode = [
      "blue",
      "violet",
      "red",
      "orange",
      "yellow",
      "indigo",
      "green",
    ];
    let getConstructData = resourceData.map((val, i) => ({
      resourceid: val?.resourceName?._id,
      color: colorCode[i % colorCode.length],
    }));
    let convertState = new Date(formData?.proposedDate);
    const epochTimeInSeconds = Math.floor(convertState.getTime() / 1000);
    // let endDate = new Date(convertState);
    let startDate = getUtcTime(moment(formData?.proposedDate).startOf("day"));
    let endDate = getUtcTime(moment(formData?.proposedDate).endOf("day"));
    let currentdate = getUtcTime(moment());
    value.colorparams = getConstructData;
    value.resource_id = resourceData.map((item) => item?.resourceName?._id);
    value.resource_type = "";
    value.start = startDate > currentdate ? startDate : currentdate;
    // value.start = startDate;
    value.end = endDate;
    value.currentTime = currentdate;

    let appointments = await dispatch(
      actions.CALENDER_SHOW_APPOINTMENTS(value)
    );
    let eventData = JSON.parse(
      JSON.stringify(appointments?.payload?.data?.response)
    );
    setDayEvents(eventData);
    let valuee = getSlot(
      eventData,
      resourceData.map((item) => item?.resourceName?._id),
      ""
    );
    if (valuee?.error) {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg:
          valuee?.message +
          " " +
          resourceData.find((x) => x?.resourceName?._id == valuee?.resourceId)
            ?.resourceName?.display,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    } else {
    let date = new Date(valuee?.date);
    let [hours, minutes] = formData?.duration.split(":").map(Number);
    // date.setHours(hours, minutes, 0, 0);
    date.setTime(date.getTime() + hours * 60 * 60 * 1000 + minutes * 60 * 1000);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed, so add 1
    const day = String(date.getDate()).padStart(2, "0");
    const thours = String(date.getHours()).padStart(2, "0");
    const tminutes = String(date.getMinutes()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day} ${thours}:${tminutes}`;
    getSelectedSlotEvent(eventData,valuee?.date,formattedDate,formData?.service?.display,formData?.duration);
    setFormData((prevState) => ({
      ...prevState,
      scrollTime: valuee?.date.split(" ")?.[1]
    }));
    setshowcontents(true);
    setSlot(valuee?.slot);
  }
  };
  const searchClick = () => {
    setopenSearchModal(true);
  };
  const handleSearchModal = () => {
    setopenSearchModal(true);
  };
  const handleSearchClose = () => {
    setopenSearchModal(false);
  };
  const services = [
    { id: "1", label: "Heart Surgery", value: "Heart Surgery" },
    { id: "2", label: "Spine Surgery", value: "Spine Surgery" },
    {
      id: "4",
      label: "Obesity & Bariatric Surgery",
      value: "Obesity & Bariatric Surgery",
    },
    { id: "5", label: "Vascular Surgery", value: "Vascular Surgery" },
  ];

  const handleChangelist = async (key, value) => {
    setResource({
      ...resource,
      // [key]: value?.display || "",
      [key]: value || "",
    });

    if (key == "resourceType") {
      const payload = {
        db_name: dbName,
        filter: {
          resourcetype: value?._id,
        },
        queryid: query_ids?.mulitiresourceDefitionRole,
      };

      try {
        const response = await fetch(
          process?.env?.REACT_APP_ARANGO_URL_READ_QDMQUERIES,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
        const data = await response.json();
        setresourceRoletypes(data);
      } catch (error) {
        console.error("Error fetching resource types:", error);
      }
    }
    if (key == "resourceRole") {
      const payload = {
        db_name: dbName,
        filter: {
          resourcetype: resource?.resourceType?._id || "CodingMaster/10047",
          roletype: value?._id,
        },
        queryid: query_ids?.mulitiresourceDefitionDept,
      };

      try {
        const response = await fetch(
          process?.env?.REACT_APP_ARANGO_URL_READ_QDMQUERIES,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
        const data = await response.json();
        setresourceDepartmenttypes(data);
      } catch (error) {
        console.error("Error fetching resource types:", error);
      }
    }
    if (key == "resourceDepartment") {
      const payload = {
        db_name: dbName,
        filter: {
          resourcetype: resource?.resourceType?._id || "CodingMaster/10047",
          roletype: resource?.resourceRole?._id || "CodingMaster/10845",
          deptid: [value?._id],
        },
        queryid: query_ids?.mulitiresourceDefitionName,
      };

      try {
        const response = await fetch(
          process?.env?.REACT_APP_ARANGO_URL_READ_QDMQUERIES,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
        const data = await response.json();
        setresourceNamestypes(data);
      } catch (error) {
        console.error("Error fetching resource types:", error);
      }
    }
  };

  const BookFn = async (patientData) => {
    const patientInfo = await dispatch(
      actions.PATIENT_INFO({
        patientid: patientData?.patientId,
        tenantid: "",
        facilityid: "",
        lang: "",
        personid: "",
      })
    );
    setState((prevState) => ({
      ...prevState,
      newPatientDetails: patientInfo?.payload?.data?.patientdetails,
    }));
    setopenSearchModal(false);
  };
  const convertTimeToNumber = (timeStr) => {
    // Split the time into hours and minutes
    const [hours, minutes] = timeStr.split(":").map(Number);

    // Convert time to a decimal number
    const decimalTime = hours + minutes / 60;

    return decimalTime;
  };

  const time = convertTimeToNumber(formData?.duration);
  // setTime(result)

  const getSlot = (events, resourceId, t) => {
    let selectSlot = [];
    let startTime = "";
    let prvResource = "";
    if (t != "") {
      startTime = t;
    }
    let sortedEvent = events.sort((a, b) => {
      if (a.resourceId === b.resourceId) {
        // If resourceId are the same, sort by start date
        return new Date(a.start) - new Date(b.start);
      }
      // Otherwise, sort by resourceId
      return a.resourceId.localeCompare(b.resourceId);
    });
    let resLoopData = resourceId.map((id) => {
      let rEvent = sortedEvent?.filter(
        (val) =>
          val?.resourceId == id &&
          val?.extendedProps?.slotstatus == "Available" &&
          val?.start >= startTime
      );
      let duration = 0;
      // if (selectSlot.length == 0) {
      let prevEnd = "";
      let eventLoopData = rEvent.map((event) => {
        if (startTime == "") {
          startTime = event.start;
        }
        if (
          (startTime <= event.start && prvResource == id) ||
          (startTime == event.start && prvResource != id) ||
          prvResource == ""
        ) {
          prvResource = id;
          if (
            prevEnd != "" &&
            event.start != prevEnd &&
            event.start > prevEnd &&
            duration < time
          ) {
            setstartTimeget(event.start);
            return getSlot(events, resourceId, event.start);
          }
          if (startTime <= event?.start) {
            let dif = calculateTimeDifference(event.start, event.end);
            duration = duration + dif;
            if (time >= duration) {
              selectSlot.push(event);
              prevEnd = event.end;
            }
          }
        }
      });
      let eld = eventLoopData.find((x) => x?.message != undefined);
      if (eld?.message) {
        return eld;
      }
      let resDur = (selectSlot?.filter((v)=> v?.resourceId == id))?.length + (selectSlot?.find((v)=> v?.resourceId == id))?.extendedProps?.duration;
      // if ((resDur / 60) != time) {
      //   ///////  alert ////////
      //   return {
      //     error: true,
      //     message: "Solt Not Avaliable",
      //     resourceId: id,
      //   };
      // }
      // } else {
      //   rEvent.forEach((event) => {});
      // }
    });
    let rld = resLoopData.find((x) => x?.message != undefined);
    if (rld?.message) {
      return rld;
    }
    setstartTimeget(startTime);
    return { message: "sucess", slot: selectSlot, date: startTime };
  };
  function calculateTimeDifference(startStr, endStr) {
    const startTime = new Date(startStr);
    const endTime = new Date(endStr);
    const timeDiff = endTime - startTime;
    const hoursDiff = timeDiff / (1000 * 60 * 60);

    return hoursDiff;
  }

  const handleEventClick = (clickInfo) => {
    let convertDate = new Date(clickInfo.event.startStr);
    const year2 = convertDate.getFullYear();
    const month2 = String(convertDate.getMonth() + 1).padStart(2, "0");
    const day2 = String(convertDate.getDate()).padStart(2, "0");
    const hours2 = String(convertDate.getHours()).padStart(2, "0");
    const minutes2 = String(convertDate.getMinutes()).padStart(2, "0");
    const formattedDate2 = `${year2}-${month2}-${day2} ${hours2}:${minutes2}`;
    let valuee = getSlot(
      dayEvents,
      resourceData.map((item) => item?.resourceName?._id),
      formattedDate2
    );
    if (valuee?.error) {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg:
          valuee?.message +
          " " +
          resourceData.find((x) => x?.resourceName?._id == valuee?.resourceId)
            ?.resourceName?.display,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    } else {
      let date = new Date(valuee?.date);
      let [hours, minutes] = formData?.duration.split(":").map(Number);
      // date.setHours(hours, minutes, 0, 0);
      date.setTime(
        date.getTime() + hours * 60 * 60 * 1000 + minutes * 60 * 1000
      );
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed, so add 1
      const day = String(date.getDate()).padStart(2, "0");
      const thours = String(date.getHours()).padStart(2, "0");
      const tminutes = String(date.getMinutes()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day} ${thours}:${tminutes}`;
      getSelectedSlotEvent(dayEvents,valuee?.date,formattedDate,formData?.service?.display,formData?.duration);
      setshowcontents(true);
      setSlot(valuee?.slot);
    }
  };
  return (
    <Div>
      <Div>
        <AppHeader />
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          style={{ padding: "10px 10px 10px 22px" }}
        >
          <IconButton onClick={backArrow}>
            <ArrowBackIcon />
          </IconButton>
          <Typography
            style={{
              fontSize: "18px",
              color: "#001C3C",
              fontWeight: 600,
              borderRight: "1px solid #DFE5EB",
            }}
          >
            Multi Resource Appointment
          </Typography>
        </Grid>
      </Div>
      {/* Form */}

      {/* ///////////Showcontents//////// */}
      <Div>
        {!showcontents ? (
          <Div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
              alignItems: "center",
            }}
          >
            <div
              style={{ width: "100%", margin: "0 20px", maxWidth: "1000px" }}
            >
              <Paper elevation={3} style={{ padding: "33px 51px" }}>
                <Grid container spacing={3}>
                  <Grid item xs={8}>
                    {state.newPatientDetails?.patmrn && (
                      <PatientCard
                        isPatientLogin={false}
                        newPatientDetails={state?.newPatientDetails}
                        hideAmount={true}
                        noedit={false}
                      />
                    )}
                    {!state.newPatientDetails?.patmrn && (
                      <StaticPatientCard
                        setopenSearchModal={setopenSearchModal}
                      />
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{ justifyContent: "end", display: "flex" }}
                  >
                    <Fab
                      aria-label="add"
                      style={{ color: "#ffff", backgroundColor: "#EC6A49" }}
                      //   onClick={props.handleAction}
                    >
                      <Image
                        src={SearchIcon}
                        alt="search"
                        style={{ width: 15, height: 15 }}
                        onClick={searchClick}
                      />
                    </Fab>
                  </Grid>
                </Grid>

                <Div>
                  <Div style={{ marginBottom: "20px", marginTop: "20px" }}>
                    <FormControl fullWidth variant="outlined">
                      <Autocomplete
                        value={formData?.service || null}
                        onChange={(event, newValue) => {
                          handleInputChange({
                            target: { name: "service", value: newValue },
                          });
                        }}
                        options={serviceType}
                        getOptionLabel={(option) => option.display} // Assuming `label` is what you want to display
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Service"
                            variant="outlined"
                            size="large"
                          />
                        )}
                      />
                    </FormControl>
                  </Div>
                  <Div style={{ marginTop: "20px" }}>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <TextField
                          id="outlined-basic"
                          type="time"
                          name="duration"
                          label={<>Duration</>}
                          variant="outlined"
                          value={formData.duration}
                          onChange={handleInputChange}
                          // sx={{ width: 230 }}
                          fullWidth
                          //   {...register("Duration")}
                          // value={treatChart?.Duration}
                          //   onChange={(e) => {
                          //     setValue("Duration", e.target.value);
                          //     trigger("Duration");
                          //   }}
                          //   InputLabelProps={{
                          //     shrink: getValues("Duration") ? true : false,
                          //   }}
                          // disabled={open}
                          //   error={errors?.Duration?.message ? true : false}
                          //   helperText={
                          //     errors?.Duration?.type === "required" ||
                          //     errors?.Duration?.type === "optionality"
                          //       ? ""
                          //       : errors?.Duration?.message
                          //   }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                Hrs
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          label="Proposed Date"
                          type="date"
                          variant="outlined"
                          fullWidth
                          name="proposedDate"
                          min={new Date().toISOString().split("T")[0]}
                          value={formData.proposedDate}
                          onChange={handleInputChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Div>
                  <Div
                    style={{
                      marginTop: "20px",
                      justifyContent: "space-between",
                      display: "flex",
                    }}
                  >
                    <H6 style={{ margin: "0px" }}>RESOURCE DEFINITION</H6>
                    <Text
                      onClick={handleModalOpen} // Open modal on click
                      style={{
                        color: "#2A60BC",
                        cursor: "pointer",
                        fontSize: "large",
                      }}
                    >
                      + Add New
                    </Text>
                  </Div>

                  {/* Display Resource Data inside Paper Component */}
                  {formData.service ? (
                    <Div>
                      {resourceData.map((data, index) => (
                        <Paper
                          key={index}
                          elevation={3}
                          style={{
                            padding: "10px",
                            marginBottom: "10px",
                            width: "100%", // Ensure Paper takes full width
                          }}
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={1}>
                              <Div>
                                <strong>{index + 1}</strong>
                              </Div>
                            </Grid>
                            <Grid item xs={2}>
                              <Div>{data.resourceType?.display}</Div>
                            </Grid>
                            <Grid item xs={2}>
                              <Div>{data.resourceRole?.display}</Div>
                            </Grid>
                            <Grid item xs={2}>
                              <Div>{data.resourceDepartment?.display}</Div>
                            </Grid>
                            <Grid item xs={3}>
                              <Div>{data.resourceName?.display}</Div>
                            </Grid>
                            <Grid item xs={2}>
                              {/* Edit Icon */}
                              <EditIcon
                                color="primary"
                                onClick={() => handleEdit(index)} // Edit icon click handler
                                style={{
                                  cursor: "pointer",
                                  marginRight: "10px",
                                }}
                              />
                              {/* Delete Icon */}
                              <DeleteIcon
                                color="secondary"
                                onClick={() => handleDelete(index)} // Delete icon click handler
                                style={{ cursor: "pointer" }}
                              />
                            </Grid>
                          </Grid>
                        </Paper>
                      ))}
                    </Div>
                  ) : (
                    <></>
                  )}
                </Div>
                <Div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={nextButClick}
                  >
                    Next
                  </Button>
                </Div>
              </Paper>
            </div>
          </Div>
        ) : (
          <Div style={{ padding: "10px" }}>
            <Grid container spacing={3}>
              <Grid item xs={5}>
                {state.newPatientDetails?.patmrn && (
                  <PatientCard
                    isPatientLogin={false}
                    newPatientDetails={state?.newPatientDetails}
                    hideAmount={true}
                    noedit={false}
                  />
                )}
              </Grid>
              <Grid item xs={5}>
                <ServiceCard
                  setshowcontents={setshowcontents}
                  formData={formData}
                  readonly={readonly}
                />
              </Grid>
              <Grid
                item
                xs={2}
                style={{ alignContent: "center" }}
              >
                <Button
                  onClick={bookAppointment}
                  style={{
                    borderRadius: 8,
                    padding: "8px 15px",
                    minWidth: 100,
                    fontWeight: "600",
                    fontSize: 12,
                  }}
                  disabled={readonly}
                  color="primary"
                  variant="contained"
                  size="sma"
                >
                  Book Appointment
                </Button>
              </Grid>
            </Grid>
            {loading && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.3)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 9999,
                }}
              >
                <CircularProgress size={60} />
              </div>
            )}
            <Grid container>
              <Grid item xs={12}>
                <AppointmentBookingMultiple
                  formData={formData}
                  events={events}
                  resourceData={resourceData}
                  slot={slot}
                  startTimeget={startTimeget}
                  handleEventClick={handleEventClick}
                />
              </Grid>
            </Grid>
          </Div>
        )}
      </Div>

      {openSearchModal ? (
        <Div
          inLineStyles={{
            zIndex: 1,
            width: "25%",
            // right: "10px",
            // position: "absolute",
            background: UIColor.differentiationBackground.color,
            height: "calc(100vh - 9vh)",
            overflow: "auto",
            borderLeft: "none",
          }}
          style={{
            position: "absolute",
            bottom: "0px",
            right: "0px",
            borderRadius: "5px",
          }}
        >
          <SearchForm
            handleActionClose={handleSearchClose}
            parent_id={"id123"}
            noNewPatient={true}
            isCashier={false}
            isBook={false}
            isEye={false}
            isSelect={true}
            bookFn={BookFn}
          />
        </Div>
      ) : (
        <></>
      )}

      <Dialog
        open={openModal}
        onClose={handleModalClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {editIndex !== null ? "Edit Resource" : "Add New Resource"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            {/* Resource Type */}
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <Autocomplete
                  options={resourceTypes ?? []}
                  onChange={(e, v) => {
                    handleChangelist("resourceType", v);
                  }}
                  value={resource.resourceType}
                  getOptionLabel={(option) => option.display || ""} // Assuming `label` is what you want to display
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Resource Types"
                      variant="outlined"
                      size="large"
                    />
                  )}
                />
              </FormControl>
            </Grid>

            {/* Resource Role */}
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <Autocomplete
                  options={resourceRoletypes ?? []}
                  onChange={(e, v) => {
                    handleChangelist("resourceRole", v);
                  }}
                  value={resource.resourceRole}
                  getOptionLabel={(option) => option.display || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Resource Role"
                      variant="outlined"
                      size="large"
                    />
                  )}
                />
              </FormControl>
            </Grid>

            {/* Resource Department */}
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <Autocomplete
                  options={resourceDepartmenttypes ?? []}
                  onChange={(e, v) => {
                    handleChangelist("resourceDepartment", v);
                  }}
                  value={resource.resourceDepartment}
                  getOptionLabel={(option) => option.display || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Resource Department"
                      variant="outlined"
                      size="large"
                    />
                  )}
                />
              </FormControl>
            </Grid>

            {/* Resource Name */}
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <Autocomplete
                  options={resourceNamestypes ?? []}
                  onChange={(e, v) => {
                    handleChangelist("resourceName", v);
                  }}
                  value={resource.resourceName}
                  getOptionLabel={(option) => option.display || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Resource Name"
                      variant="outlined"
                      size="large"
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleModalClose}
            color="secondary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleResourceSubmit}
            color="primary"
            variant="outlined"
          >
            {editIndex !== null ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </Div>
  );
};

export default MultiresourceBooking;
