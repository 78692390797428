import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchData,
  makeName,
  getImgUrl,
  getCurrentPosition,
  getUtcTime,
  getLocationInfo,
  calculationExp,
  dbName,
  defaultReject,
  defaultState,
  urls,
  getGlobalValueFrom_LclStrg_And_QryStrg,
  isPatient,
} from "../../utils";
// import {
//   locationrawdata,
//   rawdata,
//   samplehospdata,
// } from "../../screens/appointmentBooking/mockData";
import jwtDecode from "jwt-decode";

const query_ids = {
  doctorListing: "d5f55d11-44e8-44fc-b352-5bda39bfc155",
  doctorListingByshortcut: "de600b0c-f143-4f4c-babe-73b508064e8e",
  doctorListingByResourceid:"36aebae9-ac3a-4520-a6fc-f1395f1a7a5e"
  // doctorListing_practitioner: "4034bc70-d67c-433f-95ad-ca7dc075ffd8",
};
// DOCTER_LISTING
const DOCTER_LISTING = createAsyncThunk(
  `docterListingReducer/docterListing`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      debugger
      let Sdate = new Date();
      let Edate = new Date();
      let decodedToken = jwtDecode(localStorage.getItem("UserInfo"));
      const isfrontdesk=!isPatient
      if (payload.date) {
        Sdate = new Date(payload.date);
        Edate = new Date(payload.date);
      }
      // let startTime = Sdate.setHours(0, 0, 0, 0);
      // let endTime = Sdate.setHours(23, 59, 59, 999);

      let startDate = Sdate;
      let endDate = Edate;
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);

      let {
        hospital,
        specialities,
        latitude,
        longtitude,
        doctorId = 0,
        //consultmode = ["Direct", "Video", "Both"],
        consultmode = [
          process.env.REACT_APP_CODING_APMNT_BOTH_CONSULT_MODE,
          process.env.REACT_APP_CODING_APMNT_VIDEO_CONSULT_MODE,
          process.env.REACT_APP_CODING_APMNT_DIRECT_CONSULT_MODE,
        ],
        gender = "",
        lang = "",
        apptypecode,
        resource_id="",
        skip_dashboard_hospitalSearch_screen=false
      } = payload;

      let org_id = hospital ? hospital?.length > 0 ? hospital : [decodedToken?.facilityid] :  [decodedToken?.facilityid]
      let body = {};
       if(skip_dashboard_hospitalSearch_screen){
        body = {
          db_name: dbName,
          filter:{
            "apptypecode": apptypecode,
            startdate: getUtcTime(startDate),
            enddate: getUtcTime(endDate),
            consultmode,
            lang,
            isfrontdesk,
            "resourceid":resource_id,           
            "slotid":""
          },
          queryid: query_ids.doctorListingByResourceid,
        };
      }
     else if (apptypecode) {
        body = {
          db_name: dbName,
          filter: {
            apptypecode: payload?.apptypecode,
            org_id,
            startdate: getUtcTime(startDate),
            enddate: getUtcTime(endDate),
            consultmode,
            //  gender,
            lang,
            isfrontdesk,
          },
          queryid: query_ids.doctorListingByshortcut,
        };
      } 
      else {
        body = {
          db_name: dbName,
          queryid: query_ids.doctorListing,
          filter: {
            org_id,
            speciality: specialities,
            startdate: getUtcTime(startDate),
            enddate: getUtcTime(endDate),
            consultmode,
            gender,
            lang,
            isfrontdesk,
          },
        };
      }

      let data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readQdmQueries
      );

      console.log("lop", data);
      const returnData = [];
      const allLangs = new Set();
      const allCities = new Set();
      if (Array.isArray(data) && Array.isArray(data[0])) {
        data[0].forEach((record) => {
          if (record) {
            const { OrgType, id, _id, displayresource, ...restData } = record;
            console.log({ _id });
            if (displayresource) {
              let resourceInfo = {
                resourcetype: "",
                resourcerole: "",
                resourcecode: "",
              };
              const facilityCoding = "facility";
              const isClinic =
                OrgType?.some((type) => {
                  return type?.display?.toLowerCase() === facilityCoding;
                }) ?? false;
              let healthCareId = [0];
              let slots = [];

              // resourceInfo is given to slots .so we are accessing it in slotclaendar component's filterslot function
              if (isClinic) {
                // resourceInfo = {
                //   resourcetype:
                //     restData?.clinics?.[0]?.schedules?.[0]?.resourcetype || "",
                //   resourcerole:
                //     restData?.clinics?.[0]?.schedules?.[0]?.resourcerole || "",
                //   resourcecode:
                //     restData?.clinics?.[0]?.schedules?.[0]?.resourcecode || "",
                // };
                slots = restData?.clinics?.[0]?.schedules?.[0]?.slots || [];
                healthCareId = id;
              } else {
                // resourceInfo = {
                //   resourcetype: restData?.schedule?.[0]?.resourcetype || "",
                //   resourcerole: restData?.schedule?.[0]?.resourcerole || "",
                //   resourcecode: restData?.schedule?.[0]?.resourcecode || "",
                // };
                slots = restData?.schedule?.[0]?.slots || [];
                //FIXME - hospital array to object
                healthCareId = restData?.hospital?.id;
              }
              const morning = [];
              const afternoone = [];
              const evening = [];
              const night = [];
              const allSession = [];
              //FIXME -  need address as a object
              const city =
                restData?.address?.city?.geogLevelName ||
                restData?.hospital?.address?.city?.geogLevelName ||
                "";
              allCities.add(city);
              const info = {
                clinicId: restData?.locationID?.id || 0,
                id,
                subtext: "",
                locationId: "",
                name: "",
                title: "",
                price: "",
                isDoctor: !isClinic,
                language: [],
                isClinic,
                experience: 0,
                role: "",
                mobile: "",
                memberIncludes: "0",
                hospitalName: "",
                hospitalLocation: "",
                kms: "0 Kms",
                city,
                weekCalendar:
                  (payload.date && payload.date * 1000) || Date.now(),
                isAllSession: isClinic,
                allSession,
                memberIncludesImg: [],
                morning,
                img: "",
                afternoone,
                evening,
                night,
                slots,
                resourceInfo,
                bio: "",
                specialities: [],
                healthCareId,

                orgId: isClinic ? id : restData?.hospital?.id,
                orgType: isClinic
                  ? OrgType?.[0]?._id
                  : restData?.hospital?.OrgType?.[0],
                org_Id: isClinic ? _id : restData?.hospital?._id,
                _id: _id,
                _key: restData?._key,
                resource_id: isClinic ? restData?.locationID?._id : _id,
                schedule:restData?.schedule,
              };
              if (isClinic) {
                const s = restData?.clinics?.[0]?.Specialties;
                //FIXME -  coding value function changed
                if (s?.Type?.toLowerCase() === "specialty") {
                  s?.coding?.forEach((val) => {
                    if (val?.Type?.toLowerCase() === "specialty") {
                      info.specialities.push({
                        label: val?.display,
                        id: s?.id,
                      });
                    }
                  });
                }
                info["locationId"] = restData?.locationID?._id;
                info["name"] = restData?.locationID?.shortdesc || "";
                info["hospitalName"] = restData?.name || "";
                info["hospitalLocation"] = info.city || "";
                //FIXME - address need array
                info["title"] = restData?.address?.[0]?.line || "";
                info["gender"] = null;
                info["memberIncludes"] = restData?.member_count;
                info["memberIncludesImg"] = restData?.Practitioner?.map((p) => {
                  if (p) {
                    return getImgUrl(p?.photo?.[0]?.fileid);
                  }
                  return "";
                });
                //FIXME - address need array

                info["kms"] = restData?.address?.[0]?.distance
                  ? `${restData.address[0].distance}`
                  : "0";

                info["key"] = restData?._key;
                //FIXME -  photo array need
                info["img"] = getImgUrl(
                  restData?.locationID?.photo?.[0]?.fileid
                );
              } else {
                //REVIEW -  - gender no coding sample data
                //                 {
                //     "Type": "GENDER",
                //     "activestatus": true,
                //     "display": "ANY",
                //     "gmconfigvalues": {},
                //     "id": 265,
                //     "shortdesc": "ANY",
                //     "status": true,
                //     "tenantid": "1234",
                //     "facilityid": ""
                // }
                const gender = restData?.gender?.display || "";
                let designation = "";
                //FIXME - specility data changed -sample object of speciality
                //REVIEW - function chnaged as per new data
                //   {

                //     "Type": "SPECIALTY",
                //     "code": "SP001",
                //     "display": "ANY",
                //     "gmconfigvalues": {},
                //     "id": 449,
                //     "shortdesc": "ANY",
                //     "status": false,
                //                       "tenantid": "1234",
                //     "facilityid": ""
                // }

                // restData?.Specialties?.forEach((s) => {
                //   s?.coding?.forEach((val) => {
                //     if (val?.display) {
                //       if (designation) {
                //         designation += ", ";
                //       }
                //       designation += val?.display;
                //       info.specialities.push({
                //         label: val?.display,
                //         id: s?.id,
                //       });
                //     }
                //   });
                // });
                restData?.Specialties?.forEach((s) => {
                  if (s?.display) {
                    if (designation) {
                      designation += ", ";
                    }
                    designation += s?.display;
                    info.specialities.push({
                      label: s?.display,
                      id: s?._id,
                    });
                  }
                });
                const languages = new Set();
                restData?.communication?.forEach((c) => {
                  if (c?.Type?.toLowerCase() === "language") {
                    c?.coding?.forEach((cd) => {
                      if (cd?.Type?.toLowerCase() === "language") {
                        languages.add(cd?.display);
                        allLangs.add(cd?.display);
                      }
                    });
                  }
                });
                //FIXME - role
                info["role"] = restData?.practitioner_role?.[0]?.role?.display;
                //FIXME -  hospital is array sample data
                // samplehospdata in mockData
                info["mobile"] =
                  restData?.hospital?.contact?.[0]?.telecom?.[0]?.value;
                //FIXME -  no sample data for old data ..check the calexp function
                info["experience"] = calculationExp(restData?.job_experience);
                info["title"] = `${gender}${gender && " | "}${designation}`;
                info["gender"] = gender;
                //FIXME - hos - array
                info["hospId"] = restData?.hospital?.id;
                //FIXME -  qualification old data not available
                //NOTE - function checked working
                info["subtext"] =
                  restData?.qualification
                    ?.map?.((q) => q?.name || "")
                    ?.join(", ") || "----";
                //REVIEW - work only if communication works
                info["language"] = languages.size > 0 ? [...languages] : [];
                //REVIEW - hospital array chnage requesired
                info["hospitalName"] = restData?.hospital?.name || "";
                //FIXME -  hospital address array change to object required
                info["hospitalLocation"] =
                  restData?.hospital?.address?.city?.geogLevelName || "";
                //REVIEW - working
                const nameObj = restData?.name?.[0] || {};
                //REVIEW -  working
                info["name"] = makeName(nameObj);
                //FIXME -  photo full structure needed
                //   "photo": [
                //     {
                //         "_key": "11783",
                //         "_id": "AttachmentMaster/11783",
                //         "_rev": "_gftbDXu--C",
                //         "date": "",
                //         "url": "",
                //         "fileName": "",
                //         "fileid": "",
                //         "filetype": "",
                //         "objectid": "",
                //         "id": 1294,
                //         "createdby": "",
                //         "createddate": 1692684588,
                //         "updatedby": "",
                //         "updatedate": 1692684588,
                //         "activestatus": true
                //     }
                // ],
                info["img"] = getImgUrl(restData?.photo?.[0]?.fileid);
                //FIXME - address is missing ,address is object in past data ...need discussion
                info["kms"] = restData?.address?.[0]?.distance
                  ? `${restData.address[0].distance}`
                  : "0";
                info["key"] = restData?._key;
                //NOTE - changed as per new data
                info["bio"] =
                  // restData?.biography?.[0]?.biography_description || "";
                  restData?.biography_description || "";
              }
              returnData.push(info);
            }
          }
        });
      }
      const { lat: myLat, long: myLong } = await getCurrentPosition();
      console.log(myLat, myLong);
      const locationInfo = {};
      if (myLat && myLong) {
        const promArr = [...allCities]
          .filter((c) => !!c)
          .map((city) =>
            getLocationInfo({ lat: myLat, long: myLong }, city, locationInfo)
          );
        await Promise.all(promArr);
        returnData.forEach((d) => {
          d.kms = locationInfo[d.city] || "0 Kms";
        });
      }
      return {
        ...defaultState.Info,
        data: returnData,
        general:data,
        allLangs: [...allLangs],
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
// end

const GET_ALL_LANGS = createAsyncThunk(
  `docterListingReducer/allLanguages`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const body = {
        db_name: dbName,
        entity: "CodeableConceptMaster",
        filter: "CodeableConceptMaster.Type == 'LANGUAGE'",
        return_fields:
          "{coding_id: CodeableConceptMaster._id, codeable: (let codeable = DOCUMENT(CodeableConceptMaster.coding[0]) RETURN codeable)[0]}",
      };
      const data = await fetchData(
        { body: JSON.stringify(body) },
        urls.readDocuments
      );
      let returnData = [];
      if (data.result && Array.isArray(data.result)) {
        returnData = data.result.map((d) => ({
          val: d.codeable?.code,
          title: d.codeable?.display,
        }));
      }
      return {
        ...defaultState.List,
        data: returnData,
      };
    } catch (err) {
      console.log(err);
      return rejectWithValue({
        ...defaultReject,
        message: err.message,
      });
    }
  }
);

// FUZZY SEARCH CREATE INITIALSTATE
const docterListingReducer = createSlice({
  name: "docterListingReducer",
  initialState: {
    docterListing: {
      ...defaultState.List,
    },
    allLanguages: {
      ...defaultState.List,
    },
  },
  extraReducers: {
    /* DOCTER_LISTING */
    [DOCTER_LISTING.fulfilled]: (state, action) => {
      state.docterListing = action?.payload ?? [];
    },
    [DOCTER_LISTING.pending]: (state, action) => {
      state.docterListing.loading = true;
    },
    [DOCTER_LISTING.rejected]: (state, action) => {
      state.docterListing = action?.payload ?? [];
    },

    /* GET_ALL_LANGS */
    [GET_ALL_LANGS.fulfilled]: (state, action) => {
      state.allLanguages = action?.payload ?? [];
    },
    [GET_ALL_LANGS.pending]: (state, action) => {
      state.allLanguages.loading = true;
    },
    [GET_ALL_LANGS.rejected]: (state, action) => {
      state.allLanguages = action?.payload ?? [];
    },
  },
});
// end

// DOCTER_LISTING ACTION LISTS
const docterListingActions = {
  DOCTER_LISTING,
  GET_ALL_LANGS,
};
// end

export { docterListingActions };

export default docterListingReducer;
