import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Container,
  Div,
  Icons,
  SideMenu,
  Text,
  Image,
  Divider,
  H6,
  H1,
  Paper,
} from "qdm-component-library";
import AddIcon from "@material-ui/icons/Add";
import { green } from "@material-ui/core/colors";
import { v4 as uuidv4 } from "uuid";
import SearchIcon from "../../assets/img/svg/icons8-search.svg";
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Typography,
  Fab,
  Tooltip,
  colors,
} from "@material-ui/core";
import { UIColor } from "../../themes/theme";
import FullCalendar from "@fullcalendar/react";
import { formatDate } from "@fullcalendar/core";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import { INITIAL_EVENTS, createEventId } from "./event-utils";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import SlabCard from "../../components/slabCard";
import SlotCard from "../../components/slotCard";
import SelectCard from "../../components/selectCard";

const AppointmentBookingMultiple = (props) => {
  // const RESOURCES = [
  //   { id: "a", title: "Dr. Mark Tan", eventColor: "blue" },
  //   { id: "b", title: "Dr David Wong", eventColor: "violet" },
  //   { id: "c", title: "Dr. Lee Haw Chou", eventColor: "green" },
  //   { id: "d", title: "Dr. Yue Wai Mun", eventColor: "orange" },
  // ];
  const colorCode = [
    "blue",
    "violet",
    "red",
    "orange",
    "yellow",
    "indigo",
    "green",
  ];

  const getConstructData = props?.resourceData.map((val, i) => ({
    id: val?.resourceName?._id,
    title: val?.resourceName?.display,
    color: colorCode[i % colorCode.length],
  }));

  const getstartandend = props?.events.map((val, i) => ({
    resourceId: val?.resourceId,
    start: val?.start,
    end: val?.end,
  }));
  const [state, setState] = useState({
    weekendsVisible: true,
    currentEvents: [],
  });

  const handleWeekendsToggle = () => {
    setState({
      weekendsVisible: !state.weekendsVisible,
    });
  };

  const handleDateSelect = (selectInfo) => {
    let title = "test";
    let calendarApi = selectInfo.view.calendar;

    calendarApi.unselect(); // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
      });
    }
  };

  // const handleEventClick = (clickInfo) => {
  //   // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {    
  //   clickInfo.event.remove();
  //   // }
  // };

  const handleEvents = (events) => {
    setState({
      currentEvents: events,
    });
  };

  const handlePrint = () => {
    window.print();
  };
  const getUniqueSlots = () => {
    return props?.events.filter(
      (slot1) =>
        !props?.slot.some(
          (slot2) =>
            slot1.resourceId === slot2.resourceId && slot1.start === slot2.start && slot1?.color != "grey"
        )
    );
  };

  let uniqueSlots = getUniqueSlots();
  return (
    <div>
      <Paper
        elevation={3}
        style={{
          padding: "33px 51px",
          backgroundColor: "white",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FullCalendar
              plugins={[resourceTimeGridPlugin]}
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "resourceTimeGridDay",
              }}
              initialView="resourceTimeGridDay"
              initialDate={props?.formData?.proposedDate}
              editable={true}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={true}
              events={uniqueSlots}
              height="calc(100vh - 300px)"
              // weekends={state.weekendsVisible}
              // initialEvents={INITIAL_EVENTS(props?.formData?.proposedDate)} // alternatively, use the `events` setting to fetch from a feed
              resources={getConstructData}
              select={handleDateSelect}
              eventContent={renderEventContent} // custom render function
              eventClick={props?.handleEventClick}
              eventsSet={handleEvents} // called after events are initialized/added/changed/removed
              /* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
            */
            scrollTime={props?.formData?.scrollTime}
            slotDuration={"00:15:00"}
            />
            has context menu
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default AppointmentBookingMultiple;

function renderEventContent(eventInfo) {
  return (
    <>
      {eventInfo?.event?.extendedProps?.slotslabind === "Slot" ? (
        eventInfo?.event?.extendedProps?.slotstatus === "Available" ? (
          <Tooltip title="click plus icon to book appointment" arrow>
            <div
              style={{
                border: `1px solid ${colors?.[
                  eventInfo?.event?.extendedProps?.color
                ]?.[400] || green[400]}`,
                height: "100%",
                width: "100%",
                cursor: "pointer",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(255, 0, 0, 0) !important",
              }}
            >
              <IconButton
                size="small"
                // onClick={(e) => {
                //   e.stopPropagation();
                //   setAvailable({
                //     open: true,
                //     data: eventInfo?.event?.extendedProps,
                //     date: eventInfo?.event?.start,
                //   });
                // }}
              >
                <AddIcon
                  style={{
                    // backgroundColor:"#EC6A49",
                    color:
                      colors?.[eventInfo?.event?.extendedProps?.color]?.[900],
                    borderRadius: "100%",
                  }}
                />
              </IconButton>
            </div>
          </Tooltip>
        ) : eventInfo?.event?.extendedProps?.slotstatus === "Blocked" ? (
          <div
            style={{
              height: "100%",
              width: "100%",
              cursor: "pointer",
              border: "1px solid #FF4D4A",
            }}
          >
            <p
              style={{
                fontSize: "14px",
                color: "#FF4D4A",
                cursor: "pointer",
                lineHeight: "14px",
                margin: "0px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                fontWeight: "bold",
                textTransform: "capitalize",
                // backgroundColor: "#DCDCDC",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "33px",
              }}
            >
              blocked
            </p>
          </div>
        ) : eventInfo?.event?.extendedProps?.slotstatus === "Booked" ? (
          <div
            style={{
              backgroundColor:
                colors?.[eventInfo?.event?.extendedProps?.color]?.[50],
              height: "100%",
              width: "100%",
              cursor: "pointer",
            }}
          >
            <SlotCard
              eventInfo={eventInfo}
              // handleClick={handleCalenderCardClick}
              // calendarApi={calendarRef?.current?.getApi()}
            />
          </div>
        ) : (
          <></>
        )
      ) : (
        <div
          style={{
            backgroundColor:  eventInfo?.event?.extendedProps?.color,
            height: "100%",
            width: "100%",
            cursor: "pointer",
          }}
        >
          <SelectCard
            eventInfo={eventInfo}
            // handleClick={handleCalenderCardClick}
            // setAvailable={setAvailable}
            // calendarApi={calendarRef?.current?.getApi()}
          />
        </div>
      )}
    </>
  );
}
