import { Button, Chip, Collapse, Divider, Grid, Paper, TextField, Typography, useTheme } from "@mui/material";
import React from "react";
import AddIcon from "../../../assets/svgComponents/addIcon";
import SingleLineOrderCard from "./singleLineOrderCard";
import ShareIcon from '@mui/icons-material/Share';
import ArrowIcon from "../../../assets/svgComponents/arrow";
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
const SuggestedOrders = ({ data,loadingData }) => {
  const theme = useTheme();
  const [expand, setExpand] = React.useState(false);
  const [expandDiagnosis, setExpandDiagnosis] = React.useState(false);
  const [expandLab, setExpandLab] = React.useState(false);
  const [expandRadiology, setExpandRadiology] = React.useState(false);
  const [expandNur, setExpandNur] = React.useState(false);
  const [expandInvestigation, setExpandInvestigation] = React.useState(false);
  const [expandMedication, setExpandMedication] = React.useState(false);
  const [expandReferral, setExpandReferral] = React.useState(false);
  return (
    <Grid container xs={12} gap={2}
      sx={{
        border: `2px solid ${theme?.palette?.lineBorderFill}`,
        margin: "2px",
        borderRadius: 1,
        backgroundColor: "#fff"
      }}
    >
      <Grid container justifyContent={"space-between"} alignItems={"center"} pt={2} px={2} onClick={() => { setExpand(!expand) }}>
        <Grid item>
          <Typography
            variant="head1"
            color={theme?.palette?.primaryText}
            sx={{ paddingX: 1, paddingTop: 2 }}
            fontSize={"20px"}
          >
            Suggested Actions
          </Typography>
        </Grid>
        <Grid item>
          <ArrowIcon direction={expand ? "down" : "right"} />
        </Grid>
      </Grid>
      <Collapse in={expand}>

        <Divider />

        <Grid item xs={12} p={1}>
          {
            data && data?.diagnoses ? (
              <Paper elevation={0} sx={{ borderRadius: 1 }} >
                <Grid container justifyContent={"space-between"} alignItems={"center"} py={1.5} px={2} onClick={() => { setExpandDiagnosis(!expandDiagnosis) }} >
                  <Grid item>
                    <Typography variant="head2" color={theme?.palette?.secondaryText} fontWeight={600} >
                      Suggested Diagnoses
                    </Typography>
                    {
                      !expandDiagnosis ? (
                        <Chip label={data?.diagnoses?.length} size="small" sx={{ fontSize: "12px", ml: 1, fontWeight: 600, backgroundColor: theme?.palette?.primaryColor, color: "#fff" }} />
                      ) : (<></>)
                    }
                  </Grid>
                  <Grid item>
                    <Grid container gap={2}>
                      <AddIcon />
                      <ArrowIcon direction={expandDiagnosis ? "down" : "right"} />
                    </Grid>
                  </Grid>
                </Grid>
                <Collapse in={expandDiagnosis}>
                  <Grid container xs={12} pt={1} borderTop={`1px solid ${theme?.palette?.lineBorderFill}`}>
                    {data?.diagnoses?.map((d) => (
                      <Grid item xs={12}>
                        <SingleLineOrderCard
                          orderTitle={`${d?.diagnosisCode} - ${d?.diagnosisDescription}`}
                          orderDescription={`${d?.verificationStatus ? d?.verificationStatus : " "} ${d?.verificationStatus && d?.action ? " - " : ""} ${d?.action ? d?.action : " "}`}
                          evidence={d?.evidence}
                          reference={d?.reference}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Collapse>
              </Paper>
            ) : (<></>)
          }
          {
            data && data?.laboratoryOrders ? (
              <Paper elevation={0} sx={{ borderRadius: 1 }} >
                <Grid container justifyContent={"space-between"} alignItems={"center"} py={1.5} px={2} onClick={() => { setExpandLab(!expandLab) }}>
                  <Grid item>
                    <Typography variant="head2" color={theme?.palette?.secondaryText} fontWeight={600} >
                    Suggested Lab Orders
                    </Typography>
                    {
                      !expandLab ? (
                        <Chip  label={data?.laboratoryOrders?.length} size="small" sx={{ fontSize: "12px", ml: 1, fontWeight: 600, backgroundColor: theme?.palette?.primaryColor, color: "#fff" }} />
                      ) : (<></>)
                    }
                  </Grid>
                  <Grid item>
                    <Grid container gap={2}>
                      <AddIcon />
                      <ArrowIcon direction={expandLab ? "down" : "right"} />
                    </Grid>
                  </Grid>
                </Grid>
                <Collapse in={expandLab}>
                  <Grid container xs={12} pt={1} borderTop={`1px solid ${theme?.palette?.lineBorderFill}`}>
                    {data?.laboratoryOrders?.map((d) => (
                      <Grid item xs={12}>
                        <SingleLineOrderCard
                          orderTitle={d?.orderCodeDescription}
                          orderDescription={`${d?.priority?.label ? d?.priority?.label : " "} ${d?.priority?.label && d?.nature?.label ? " - " : ""} ${d?.nature?.label ? d?.nature?.label : " "}`}
                          evidence={d?.evidence}
                          reference={d?.reference}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Collapse>
              </Paper>
            ) : (<> </>)
          }
          {
            data && data?.radiologyOrders ? (
              <Paper elevation={0} sx={{ borderRadius: 1 }} >
                <Grid container justifyContent={"space-between"} alignItems={"center"} py={1.5} px={2} onClick={() => { setExpandRadiology(!expandRadiology) }}>
                  <Grid item>
                    <Typography variant="head2" color={theme?.palette?.secondaryText} fontWeight={600}>
                    Suggested Radiology Orders
                    </Typography>
                    {
                      !expandRadiology ? (
                        <Chip  label={data?.radiologyOrders?.length} size="small" sx={{ fontSize: "12px", ml: 1, fontWeight: 600, backgroundColor: theme?.palette?.primaryColor, color: "#fff" }} />
                      ) : (<></>)
                    }
                  </Grid>
                  <Grid item>
                    <Grid container gap={2}>
                      <AddIcon />
                      <ArrowIcon direction={expandRadiology ? "down" : "right"} />
                    </Grid>
                  </Grid>
                </Grid>
                <Collapse in={expandRadiology}>
                  <Grid container xs={12} pt={1} borderTop={`1px solid ${theme?.palette?.lineBorderFill} `}>
                    {data?.radiologyOrders?.map((d) => (
                      <Grid item xs={12}>
                        <SingleLineOrderCard
                          orderTitle={d?.orderCodeDescription}
                          orderDescription={`${d?.priority?.label ? d?.priority?.label : " "} ${d?.priority?.label && d?.quantity ? " - " : ""} ${d?.quantity ? d?.quantity : " "}`}
                          evidence={d?.evidence}
                          reference={d?.reference}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Collapse>
              </Paper>
            ) : (<></>)
          }
          {
            data && data?.nursingProcedures ? (
              <Paper elevation={0} sx={{ borderRadius: 1 }} >
                <Grid container justifyContent={"space-between"} alignItems={"center"} py={1.5} px={2} onClick={() => { setExpandNur(!expandNur) }}>
                  <Grid item>
                    <Typography variant="head2" color={theme?.palette?.secondaryText} fontWeight={600}>
                    Suggested Nursing Procedures
                    </Typography>
                    {
                      !expandNur ? (
                        <Chip  label={data?.nursingProcedures?.length} size="small" sx={{ fontSize: "12px", ml: 1, fontWeight: 600, backgroundColor: theme?.palette?.primaryColor, color: "#fff" }} />
                      ) : (<></>)
                    }
                  </Grid>
                  <Grid item>
                    <Grid container gap={2}>
                      <AddIcon />
                      <ArrowIcon direction={expandNur ? "down" : "right"} />
                    </Grid>
                  </Grid>
                </Grid>
                <Collapse in={expandNur}>
                  <Grid container xs={12} pt={1} borderBottom={`1px solid ${theme?.palette?.lineBorderFill}`}>
                    {data?.nursingProcedures?.map((d) => (
                      <Grid item xs={12}>
                        <SingleLineOrderCard
                          orderTitle={d?.orderCodeDescription}
                          orderDescription={`${d?.priority?.label ? d?.priority?.label : ""}`}
                          evidence={d?.evidence}
                          reference={d?.reference}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Collapse>
              </Paper>
            ) : (<></>)
          }
          {
            data && data?.otherInvestigationOrders ? (
              <Paper elevation={0} sx={{ borderRadius: 1 }} >
                <Grid container justifyContent={"space-between"} alignItems={"center"} py={1.5} px={2} onClick={() => { setExpandInvestigation(!expandInvestigation) }} >
                  <Grid item>
                    <Typography variant="head2" color={theme?.palette?.secondaryText} fontWeight={600}>
                    Suggested Other Investigation Orders
                    </Typography>
                    {
                      !expandInvestigation ? (
                        <Chip  label={data?.otherInvestigationOrders?.length} size="small" sx={{ fontSize: "12px", ml: 1, fontWeight: 600, backgroundColor: theme?.palette?.primaryColor, color: "#fff" }} />
                      ) : (<></>)
                    }
                  </Grid>
                  <Grid item>
                    <Grid container gap={2}>
                      <AddIcon />
                      <ArrowIcon direction={expandInvestigation ? "down" : "right"} />
                    </Grid>
                  </Grid>
                </Grid>
                <Collapse in={expandInvestigation}>
                  <Grid container xs={12} pt={1} borderTop={`1px solid ${theme?.palette?.lineBorderFill}`}>
                    {data?.otherInvestigationOrders?.map((d) => (
                      <Grid item xs={12}>
                        <SingleLineOrderCard
                          orderTitle={d?.orderCodeDescription}
                          // orderDescription={`${d?.priority?.label}`}
                          evidence={d?.evidence}
                          reference={d?.reference}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Collapse>
              </Paper>
            ) : (<></>)
          }
          {
            data && data?.medications ? (
              <Paper elevation={0} sx={{ borderRadius: 1 }} >
                <Grid container justifyContent={"space-between"} alignItems={"center"} py={1.5} px={2} onClick={() => { setExpandMedication(!expandMedication) }}>
                  <Grid item>
                    <Typography variant="head2" color={theme?.palette?.secondaryText} fontWeight={600}>
                    Suggested Medication Orders
                    </Typography>
                    {
                      !expandMedication ? (
                        <Chip  label={data?.medications?.length} size="small" sx={{ fontSize: "12px", ml: 1, fontWeight: 600, backgroundColor: theme?.palette?.primaryColor, color: "#fff" }} />
                      ) : (<></>)
                    }
                  </Grid>
                  <Grid item>
                    <Grid container gap={2}>
                      <AddIcon />
                      <ArrowIcon direction={expandMedication ? "down" : "right"} />
                    </Grid>
                  </Grid>
                </Grid>
                <Collapse in={expandMedication}>
                  <Grid container xs={12} pt={1} borderTop={`1px solid ${theme?.palette?.lineBorderFill}`}>
                    {data?.medications?.map((d) => (
                      <Grid item xs={12}>
                        <SingleLineOrderCard
                          orderTitle={d?.orderCodeDescription}
                          orderDescription={`${d?.doseFrequency}`}
                          evidence={d?.evidence}
                          reference={d?.reference}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Collapse>
              </Paper>
            ) : (<></>)
          }
          {
            data && data?.referralOrders ? (
              <Paper elevation={0} sx={{ borderRadius: 1 }} >
                <Grid container justifyContent={"space-between"} alignItems={"center"} py={1.5} px={2} onClick={() => { setExpandReferral(!expandReferral) }}>
                  <Grid item>
                    <Typography variant="head2" color={theme?.palette?.secondaryText} fontWeight={600}>
                    Suggested Referral Orders
                    </Typography>
                    {
                      !expandReferral ? (
                        <Chip  label={data?.referralOrders?.length} size="small" sx={{ fontSize: "12px", ml: 1, fontWeight: 600, backgroundColor: theme?.palette?.primaryColor, color: "#fff" }} />
                      ) : (<></>)
                    }
                  </Grid>
                  <Grid item>
                    <Grid container gap={2}>
                      <AddIcon />
                      <ArrowIcon direction={expandReferral ? "down" : "right"} />
                    </Grid>
                  </Grid>
                </Grid>
                <Collapse in={expandReferral}>
                  <Grid container xs={12} pt={1} borderTop={`1px solid ${theme?.palette?.lineBorderFill}`}>
                    {data?.referralOrders?.map((d) => (
                      <Grid item xs={12}>
                        <SingleLineOrderCard
                          orderTitle={d?.specialist}
                          // orderDescription={`${d?.priority?.label}`}
                          evidence={d?.evidence}
                          reference={d?.reference}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Collapse>
              </Paper>
            ) : (<></>)
          }
          {
            loadingData?.length > 0 ? (
              loadingData?.map((v,i)=>{
                return(
                  <Paper elevation={0} sx={{ borderRadius: 1 }} >
                    <Grid container justifyContent={"space-between"} alignItems={"center"} py={1.5} px={2} onClick={() => { setExpandReferral(!expandReferral) }}>
                      <Grid item>
                      <Grid container>
                        <Grid item>
                          <Typography variant="head2" color={theme?.palette?.secondaryText} fontWeight={600}>
                          {"Generating "}{v}{"......"}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <CircularProgress size={25} style={{marginBottom:"7px",marginLeft:"6px"}} />
                        </Grid>
                      </Grid>
                      </Grid>
                      <Grid item>
                      </Grid>
                    </Grid>
                  </Paper>
                );
              })
            ) : (<></>)
          }

        </Grid>
      </Collapse>
    </Grid>
  );
};

export default SuggestedOrders;
