import { reject } from "lodash";

export const AlertProps = {
  vertical: {
    top: "top",
    bottom: "bottom",
  },
  horizontal: {
    left: "left",
    right: "right",
    center: "center",
  },
  severity: {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
  },
};

export const DrawerProps = {
  direction: {
    top: "top",
    bottom: "bottom",
    left: "left",
    right: "right",
  },
  variant: {
    permanent: "permanent",
    persistent: "persistent",
    temporary: "temporary",
  },
};

export const DrawerMeetingProps = {
  direction: {
    top: "top",
    bottom: "bottom",
    left: "left",
    right: "right",
  },
  variant: {
    permanent: "permanent",
    persistent: "persistent",
    temporary: "temporary",
  },
};
//src\router\access.js
export const UserRoles = {
  role: "role",
};
export const LocalStorageKeys = {
  authToken: "auth_token",
  version: "version",
  deviceToken: "device_token",
};
//app.auth
export const NetWorkCallMethods = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
  update: "UPDATE",
};
export const permission = {
  doctor: [
    "surgeon",
    "physician",
    "consultant",
    "assistant",
    "paediatrician",
    "nephrologist",
    "neurologist",
    "cardiologist",
    "midwife",
    "matron",
    "radiologist",
    "dermatologist",
    "anaesthetist",
    "orthopaedician",
    "urologist",
    "doctor",
  ],
  nurse: ["nursingofficer", "nurse", "studentnurse"],
  admin: ["patientEntrybtn", "admin"],
  frontdesk: ["frontdesk", "receptionist"],
  technician: ["labtechnician"],
};
///dashboard/components/statsCard.js

export const dashboardWidgets = [
  {
    width: 20,
    name: "widget 1",
    color: "red",
  },
  {
    width: 20,
    name: "widget 2",
    color: "Yellow",
  },
  {
    width: 20,
    name: "widget 3",
    color: "blue",
  },
  {
    width: 20,
    name: "widget 4",
    color: "green",
  },
  {
    width: 20,
    name: "widget 5",
    color: "lightblue",
  },
  {
    width: 100,
    name: "widget 6",
    color: "gray",
  },
  {
    width: 100,
    name: "widget 7",
    color: "orange",
  },
  {
    width: 50,
    name: "widget 8",
    color: "burlywood",
  },
  {
    width: 50,
    name: "widget 9",
    color: "aquamarine",
  },
];
export const DispenseDurationUOMOptions = {
  Days: "DrugUOM/11616",
  Months: "DrugUOM/11617",
  Weeks: "DrugUOM/11618",
  Years: "DrugUOM/11620",
  Doses: "DrugUOM/11629",
  Hours: "DrugUOM/11631",
};

export let Themes = {
  default: "default",
  dark: "dark",
};
export const AdvancePayementstatus = {
  completed:"CodingMaster/12762",
  pending:"CodingMaster/12761"
};

export const timeElapsedShowStates = ["In Progress"];

export const RefundQueueRejectTabs = {
  rejectedTabId:"queuestates/10120",
  rejectActionId:"queuestatesaction/10210"
};

export const  dialysisStates = {
  "New Appointment": "queuestates/10073",
  "Checked In": "queuestates/10074",
  "In Progress": "queuestates/10075",
  "Dialysis Completed": "queuestates/10076",
  "Billed": "queuestates/10077",
  "No Show": "queuestates/10078",
  "Pause": "queuestates/10079",
  "Check Out": "queuestates/10080",
  "Cancelled": "queuestates/10094",
};

export const fdbAliasCodeId = "CodingMaster/11241";
export const mandatoryFeildsInIntraDialysis =[  "StaffID", "NursingNotes", "AccumulatedUFV", "Conductivity", "Qd", "Temperature", "VenousPressure", "Qb", "Heparin", "Pulse", "BloodPressure", "Time"];
export const intraDialysisSteps = ["baseline", "firstHour", "secondHour", "thirdHour"];
export const intraDialysisFields = [
  { fieldName: 'Time', displayName: "Time",isRequired:true },
  { fieldName: 'BloodPressure', displayName: "Blood Pressure",isRequired:true },
  { fieldName: 'Pulse', displayName: "Pulse",isRequired:true },
  { fieldName: 'Heparin', displayName: "Heparin",isRequired:true },
  { fieldName: 'Qb', displayName: "Qb" ,isRequired:true},
  { fieldName: 'VenousPressure', displayName: "Venous Pressure",isRequired:true },
  { fieldName: 'Temperature', displayName: "Temperature" ,isRequired:true},
  { fieldName: 'Qd', displayName: "Qd",isRequired:true ,isRequired:true },
  { fieldName: 'Conductivity', displayName: "Conductivity", isRequired:true },
  { fieldName: 'AccumulatedUFV', displayName: "Accumulated UFV",isRequired:true },
  { fieldName: 'NursingNotes', displayName: "Nursing Notes",isRequired:true },
  { fieldName: 'StaffID', displayName: "Staff ID",isRequired:true },
  { fieldName: 'TransmembranePressure', displayName: "Transmembrane Pressure",isRequired:false },
];
export const waitListAppointmentId = {
  waitList:"CodingMaster/12920",
  status:"CodingMaster/12925",
  requestBooked:"CodingMaster/12927",
};

export const  allowedFileTypesOfReactFileViewer = [
  // Image Files
  'jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'webp', 'svg',

  // Document Files
  'pdf', 'docx', 'xlsx', 'pptx', 'txt', 'rtf', 'csv', 'html', 'md',

  // Audio Files
  'mp3', 'wav', 'ogg',

  // Video Files
  'mp4', 'avi', 'mov', 'mkv', 'webm',

  // Compressed Files
  'zip', 'rar', 'tar',

  // Code Files
  'js', 'html', 'css', 'json', 'ts',

  // Other Files
  'epub', 'odt', 'ppt', 'psd'
];
export const IsPersonAsRelations = {
  isPerson:"member"
}