import * as React from "react"

function SquareTrendIcon({ height, width, color, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 24}
      height={height ? height : 25}
      viewBox="0 0 24 25"
      fill="none"
      {...props}
    >
      <path
        d="M7 14.5l2.293-2.293a1 1 0 011.414 0l1.586 1.586a1 1 0 001.414 0L17 10.5m0 0V13m0-2.5h-2.5"
        stroke={color ? color : "#008ADB"}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 12.5c0 4.714 0 7.071-1.465 8.535C19.072 22.5 16.714 22.5 12 22.5s-7.071 0-8.536-1.465C2 19.572 2 17.214 2 12.5s0-7.071 1.464-8.536C4.93 2.5 7.286 2.5 12 2.5c4.714 0 7.071 0 8.535 1.464.974.974 1.3 2.343 1.41 4.536"
        stroke={color ? color : "#008ADB"}
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </svg>
  )
}

export default SquareTrendIcon