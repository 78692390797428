import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import animationData from '../../assets/annimations/loadingAI.json';
import { Typography, useTheme } from '@mui/material';

const LoadingAiOutput = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const theme = useTheme();
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % loadingTexts.length);
        }, 1500); // Change text every second

        return () => clearInterval(intervalId);
    }, []);

    const loadingTexts = [
        "Determining the atrial fibrillation stage...",
        "Analyzing patient data for CHARGE-AF score calculation...",
        "Calculating the C₂HEST score for comprehensive risk assessment...",
        "Summarizing clinical findings and considerations...",
        "Identifying missing data for accurate evaluation...",
        "Generating actionable recommendations for management...",
        "Aligning with the 2023 AF guidelines for precise diagnosis...",
        "Cross-referencing patient metrics for AF staging...",
        "Evaluating key factors to assess AF risk levels...",
        "Preparing detailed clinical insights and next steps..."
    ];
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    return (
        <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',backgroundColor: '#fff' }}>

            <div>
            <Lottie
                options={defaultOptions}
                height={400}
                width={400}
            />
            </div>
            <div
                style={{
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    animation: 'marquee 10s linear infinite',
                    paddingTop:"20px"
                }}
            >
                <Typography variant="head1" color={theme?.palette?.tertieryText} textAlign={"center"} >
                  {loadingTexts[currentIndex]}
                </Typography>
            </div>


        </div>
    );
};

export default LoadingAiOutput;