import { Grid, IconButton, Paper, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { VitalSigns } from "../../../assets/svgComponents/vitalSigns";
import ArrowIcon from "../../../assets/svgComponents/arrow";
import InfoIcon from "../../../assets/svgComponents/infoIcon";
import { TemperatureIcon } from "../../../assets/svgComponents/tempIcon";
import { BmiIcon } from "../../../assets/svgComponents/bmiIcon";
import { HeightsIcon } from "../../../assets/svgComponents/heightsIcon";
import { WeightKgIcon } from "../../../assets/svgComponents/weightKgIcon";
import { HeartPluseIcon } from "../../../assets/svgComponents/heartPlusIcon";
import { Bsa } from "../../../assets/svgComponents/bsaIcon";
import { BloodSamplesIcon } from "../../../assets/svgComponents/bloodSampleIcon";
import { Respiration } from "../../../assets/svgComponents/respirationIcon";
import { Spo2 } from "../../../assets/svgComponents/spo2Icon";

const InfoVital = ({data}) => {
  const theme = useTheme();
  const [activeDataIndex, setActiveDataIndex] = useState(0);
  const [showAll, setShowAll] = useState(false); // Controls whether all vitals or only abnormal ones are shown

  // Sort the keys in descending order (most recent first)
  const dataKeys = Object.keys(data).sort((a, b) => b - a);
  const currentDataKey = dataKeys?.[activeDataIndex];
  const currentData = data?.[currentDataKey];

  const abnormalVitals = currentData?.filter((vital) => vital?.isabnormal) || [];
  const abnormalCount = abnormalVitals?.length;

  const handlePrevData = () => {
    if (activeDataIndex > 0) setActiveDataIndex(activeDataIndex - 1);
  };

  const handleNextData = () => {
    if (activeDataIndex < dataKeys?.length - 1) setActiveDataIndex(activeDataIndex + 1);
  };

  const getVitalIcon = (vitalCode) => {
    switch (vitalCode) {
      case "WEIGHT":
        return <WeightKgIcon width={20} height={20} />;
      case "HEIGHT":
        return <HeightsIcon width={20} height={20} />;
      case "BMI":
        return <BmiIcon width={20} height={20} />;
      case "BSA":
        return <Bsa width={20} height={20} />;
      case "LOWBP":
      case "HIGHBP":
        return <BloodSamplesIcon width={20} height={20} />;
      case "TEMP":
        return <TemperatureIcon width={20} height={20} />;
      case "RESP":
        return <Respiration width={20} height={20} />;
      case "O2S":
        return <Spo2 width={20} height={20} />;
      case "PULSE":
        return <HeartPluseIcon width={20} height={20} />;
      default:
        return <Respiration width={20} height={20} />;
    }
  };

  const formatEpochDate = (epoch) => {
    const date = new Date(epoch * 1000);
    const day = date.getDate().toString().padStart(2, '0');
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  const toggleVitalsDisplay = () => {
    setShowAll((prev) => !prev);
  };

  const displayedVitals = showAll ? currentData : abnormalVitals;

  return (
    <Paper elevation={0} sx={{ borderRadius: 3 }} variant="outlined">
      {/* Header */}
      <Grid container justifyContent="space-between" alignItems="center" p={2} borderBottom={`1px solid ${theme?.palette?.lineBorderFill}`}>
        <Grid item>
          <Grid container alignItems="center" gap={1}>
            <VitalSigns width={20} height={20} />
            <Typography variant="head2" color={theme?.palette?.primaryText}>
              Vital Signs
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container alignItems="center" gap={1}>
            <Typography variant="body2" color={theme?.palette?.tertiaryText}>
              {formatEpochDate(currentDataKey)}
            </Typography>
            <IconButton size="small" onClick={handlePrevData}>
              <ArrowIcon
                direction="left"
                color={activeDataIndex === 0 ? "grey" : "black"}
              />
            </IconButton>
            <IconButton size="small" onClick={handleNextData}>
              <ArrowIcon
                direction="right"
                color={activeDataIndex === dataKeys?.length - 1 ? "grey" : "black"}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>

      {/* Abnormal Badge */}
      {abnormalCount > 0 && (
        <Grid container px={1} pt={1}>
          <Grid
            container
            justifyContent={"space-between"}
            py={1}
            px={2}
            bgcolor={"#FFEDED"}
            borderRadius={1}
          >
            <Grid item>
              <Grid container alignItems={"center"} gap={1}>
                <InfoIcon height={22} width={22} color={theme?.palette?.Error} />
                <Typography variant="content2" color={theme?.palette?.Error}>
                  {abnormalCount} Vitals abnormal
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="content2"
                color={theme?.palette?.Error}
                onClick={toggleVitalsDisplay}
                sx={{ cursor: "pointer" }}
              >
                {showAll ? "Hide" : "Show All"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}

      {/* Vitals */}
      {displayedVitals?.length > 0 ? (
        <Grid container p={2} gap={2}>
          {displayedVitals?.map((item, index) => (
            <Grid item key={index}>
              <Grid container alignItems="center" gap={1}>
                {getVitalIcon(item?.vitalcode)}
                <Typography
                  variant="caption1"
                  color={item?.isabnormal ? theme?.palette?.error?.main : theme?.palette?.primaryText}
                >
                  {item?.vitalvalue} {item?.vitalunit}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container justifyContent="center" alignItems="center" p={2}>
          <Typography variant="body1" color={theme?.palette?.primaryText}>
            No Vitals Recorded
          </Typography>
        </Grid>
      )}
    </Paper>
  );
};

export default InfoVital;



