/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date 2020-12-01
 * @modify date 2021-04-27
 * @desc Material UI MuIAlert and Snackbar is used to show the temporary message Component
 */
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Text,
  TextInput,
  TapSelect,
  Div,
} from "qdm-component-library";
import {
  TemperatureIcon,
  BmiIcon,
  HeightsIcon,
  WeightKgIcon,
  HeartPluseIcon,
  BloodSamplesIcon,
  Bsa,
  BodyLength,
  Head,
  CalendarIcon,
  Respiration,
  Spo2,
  Lmp,
  BloodGroup,
} from "../../components";
//import { BmiIcon } from "../../components/svg/bmiIcon";
//import { HeightsIcon } from "../../components/svg/heightsIcon";
//import { WeightKgIcon } from "../../components/svg/weightKgIcon";
//import { HeartPluseIcon } from "../../components/svg/heartPulseIcon";
//import { BloodSamplesIcon } from "../../components/svg/bloodSampleIcon";
//import { Bsa } from "../../components/svg/bsa";
//import { BodyLength } from "../../components/svg/bodyLength";
//import { Head } from "../../components/svg/head";
import { WSTCM } from "../../components/svg/components/wstcm";
//import { CalendarIcon } from "../../components/svg/calendarIcon";
//import { Respiration } from "../../components/svg/respiration";
//import { Spo2 } from "../../components/svg/spo2";
//import { Lmp } from "../../components/svg/lmp";
//import { BloodGroup } from "../../components/svg/bloodGroup";
import { Button, Tooltip } from "@material-ui/core";
import Calendar from "react-calendar";
import { Popover } from "@material-ui/core";
import withAllContexts from "../../hoc/withAllContexts";
import { AlertProps, getFacilityId, getGlobalValueFrom_LclStrg_And_QryStrg, isObjectEmpty } from "../../utils";
import { UIColor } from "../../themes/theme";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createVitalsJson } from "./createJson";
import moment from "moment";
import jwtDecode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../redux/actions";
import { useLocation, useSearchParams } from "react-router-dom";
const styles = {
  vitalsHead: {
    padding: "6px 14px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid ${UIColor.lineBorderFill.color}`,
    paddind: 10,
  },
  vitailsCard: {
    // border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 10,
    width: "100%",
    marginBottom: 0,
    background: "#fff",
  },
  labelStyle: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: UIColor.lineBorderFill.color,
  },
  errorborderStyle: {
    borderRadius: 8,
    borderColor: "red",
  },
  inputStyle: {
    padding: 5,
    marginBottom: 20,
    display: "flex",
    alignItems: "flex-end",
    position: "relative",
  },
  inputFiledStyle: {
    borderColor: UIColor.lineBorderFill.color,
    borderRadius: "8px",
  },
  listVitals: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tagsStyle: {
    padding: "4px 12px",
    borderRadius: "50px",
    border: "1px solid #0071F2",
    color: "#0071F2",
    background: "#F5FAFF",
    fontSize: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  list: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  tapSelectStyle: {
    padding: "4px 8px",
    borderColor: UIColor.lineBorderFill.color,
    margin: "4px 10px 6px 0px",
    borderRadius: "8px",
    minWidth: "auto",
    "& span": {
      fontSize: "12px",
    },
  },
  TapSelectcol: {
    display: "flex",
    margin: "auto 0",
    marginBottom: "12px",
    paddingLeft: "4px",
  },
  CalendarStyle: {
    padding: 5,
    marginBottom: 10,
    display: "flex",
    alignItems: "flex-end",
    position: "relative",
  },
  CalendarStyleIcon: {
    position: "absolute",
    right: "14px",
    bottom: "8px",
    cursor: "pointer",
  },
};

const vitalsListArr = [
  "WEIGHT",
  "HEIGHT",
  "LOWBP",
  "HIGHBP",
  "LDL",
  "HDL",
  "PULSE",
  "RESP",
  "TEMP",
  "WSTCM",
  "HEADC",
  "O2S",
  "LMT",
  "BG",
  "WGT",
  "BMI",
  "BSA",
];

const bloodType = [
  { value: "posstive", label: "+ ve" },
  { value: "negative", label: "- ve" },
];
const VitalSigns = (props) => {
  const dispatch = useDispatch();
  const vitalsData = useSelector((state) => state?.patientDetails);
  const templocation = useLocation();
  const [searchParams] = useSearchParams();
  const [location,setLocation] = useState({})
  const searchPractioner = useSelector(
    (state) => state?.personApiSlice?.searchPractioner
  );
  const saveVitalsLoading = useSelector(
    (state) => state?.patientDetails?.saveVitals?.loading
  );
  const readVitalsLoading = useSelector(
    (state) => state?.patientDetails?.vitalsBasedEncounter?.loading
  );
  const { Encounterdata } = props;
  var vitalsJson = vitalsData?.getvitalsjson?.data ? vitalsData?.getvitalsjson?.data : [];

  const [type, setType] = React.useState("edit");
  const [state, setState] = React.useState({});
  const [defaultState, setDefaultState] = React.useState({});
  const [calenderVal, setcalenderVal] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [editId, setEditId] = React.useState(null);
  const [errorField, setErrorFields] = React.useState({
    HEIGHT: false,
    WEIGHT: false,
    LOWBP: false,
    HIGHBP: false,
    O2S: false,
    PULSE: false,
    RESP: false,
    TEMP: false,
    BSA: false,
    BMI: false,
  });
  const [isErr, setisErr] = React.useState();
  const ischeckError = (vitalsJson, state) => {
    let iserror = false;
    vitalsJson.map((val) => {
      if (
        (val?.mandatory && !state[val.vitalscode]) ||
        state[`${val.vitalscode}error`] == "false"
      ) {
        errorField[val.vitalscode] = true;
        iserror = true;
      }
    });
    setErrorFields({ ...errorField });
    return iserror;
  };
  const bmiandbsa = (e, stateLabel) => {
    if (state["HEIGHT"] && state["WEIGHT"]) {
      let HEIGHTINMeter = state["HEIGHT"] / 100;
      HEIGHTINMeter = Math.pow(HEIGHTINMeter, 2);

      let BMIval = state["WEIGHT"] / HEIGHTINMeter;
      let BSAvalcal = (state["HEIGHT"] * state["WEIGHT"]) / 3600;
      let BSAval = Math.sqrt(BSAvalcal);
      let BMIId = vitalsJson.filter((li) => li.vitalscode == "BMI");
      let BSAId = vitalsJson.filter((li) => li.vitalscode == "BSA");
      setState({
        ...state,
        ["BMI"]: BMIval.toFixed(2),
        [`${"BMI"}error`]: "true",
        [`${"BMI"}normalorabnormal`]: "false",
        [`${"BMI"}uom`]: "",
        [`${"BMI"}uom_id`]: BMIId?.[0]?.default_unit_id,
        ["BSA"]: BSAval.toFixed(2),
        [`${"BSA"}error`]: "true",
        [`${"BSA"}uom`]: "",
        [`${"BSA"}uom_id`]: BSAId?.[0]?.default_unit_id,
        [`${"BSA"}normalorabnormal`]: "false",
        [`${"BMI"}_id`]: BMIId?.[0]?._id,
        [`${"BSA"}_id`]: BSAId?.[0]?._id,
      });
      return "";
    } else {
      setState({
        ...state,
        ["BMI"]: "",
        [`${"BMI"}error`]: "true",
        [`${"BMI"}normalorabnormal`]: "false",
        [`${"BMI"}uom`]: "",
        ["BSA"]: "",
        [`${"BSA"}error`]: "true",
        [`${"BSA"}uom`]: "",
        [`${"BSA"}normalorabnormal`]: "false",
        [`${"BMI"}_id`]: "",
        [`${"BSA"}_id`]: "",
      });
      return "";
    }
  };
  const getVitalsBasedEnc = async () => {
    let vitalsdata = await dispatch(
      actions.GET_VITALS_BASED_ENCOUNTER({ 
        PID: location?.state?.patientId,//Encounterdata?.patientDetails?.patientId || Encounterdata?.Patient?.[0]?._id ||Encounterdata?.patientId 
     encounterId:location?.state?.encounter_id
      })
    );
    return vitalsdata;
  }
  const getRegistrationVitals = ()=>{
    let vitalsList = []
    vitalsJson?.map((v)=>{
      if(v?._id === "VitalsCodes/10007" || v?._id === "VitalsCodes/10008"){
        let obj = v;
        vitalsList.push(obj);
      }
    })
    vitalsJson = [];
    vitalsJson = vitalsList;
  };
  const getVitalJson = async () => {
    const vitals = await getVitalsBasedEnc();
    if (vitals?.payload?.data?.length > 0) {
      if (!editId) {
        let data = vitals?.payload?.data;
        let _id = data?.[data.length - 1]?._id;
        setEditId(_id);
      }
      const newState = processData(
        vitals?.payload?.data?.[vitals?.payload?.data?.length - 1]
      );
      setState(newState);
      setType("save");
    }
    if(props?.onRegistration == true  ){
      if(Array.isArray(vitalsJson)){
        getRegistrationVitals();
      }
    }
  };
  const processData = (data = {}) => {
    const component = data?.component || [];
    let obj = {};
    component.forEach((c) => {
      const newObj = {
        [c.obscode]: `${c.obsvalue}`,
        [`${c.obscode}error`]: "true",
        [`${c.obscode}_id`]: `${c.obsinternalid}`,
        [`${c.obscode}uom_id`]: `${c.obsuom}`,
        [`${c.obscode}uom`]: c.abnormalmsg ? JSON.parse(c.abnormalmsg) : "",
        [`${c.obscode}normalorabnormal`]: c.isabnormal ? "true" : "false",
      };
      obj = { ...newObj, ...obj };
    });
    return obj;
  };
  const handleSave = async (val) => {
    let json = createVitalsJson(props, searchPractioner, Encounterdata);
    let error = ischeckError(vitalsJson, state);
    setisErr(error);
    let payload = {
      dynamicValue: state,
      defaultValue: defaultState,
      months: 78,
    };
    if (error) {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Mandatory Fields cannot be empty and out of range",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return false;
    }
    if(Object.keys(payload.dynamicValue).length > 0 || Object.keys(payload.defaultValue).length > 0){
      const saveResp = await dispatch(
        actions.SAVE_VITALS({ ...payload, ...json, editId: editId })
      );
      if(saveResp?.payload?.data?.error === false){
        let getData = await getVitalsBasedEnc();
        if (!editId) {
          let data = getData?.payload?.data;
          let _id = data?.[data.length - 1]?._id;
          setEditId(_id);
        }
        setType(val);
      }
    }
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (get) => {
    if (get) {
      setAnchorEl(null);
    }
  };
  
  const setVital = (
    stateLabel,
    e,
    min_range,
    max_range,
    uom,
    normal_min,
    normal_max,
    _id,
    default_unit_id
  ) => {
    setState({
      ...state,
      [stateLabel]: e.target.value,
      // [`${stateLabel}error`]:   (e.target.value - min_range) * (e.target.value - max_range) <= 0 ? "true" : "false",
      [`${stateLabel}error`]: e.target.value
        ? (e.target.value - min_range) * (e.target.value - max_range) <= 0
          ? "true"
          : "false"
        : "true",
      [`${stateLabel}uom`]: uom,
      [`${stateLabel}uom_id`]: default_unit_id,
      [`${stateLabel}normalorabnormal`]:
        (e.target.value - normal_min) * (e.target.value - normal_max) <= 0
          ? "false"
          : "true",
      [`${stateLabel}_id`]: _id,
    });
    if (e.target.value.length > 0) {
      setErrorFields({
        ...errorField,
        [stateLabel]: false,
      });
    }

    return "";
  };


  React.useEffect(() => {
    if(!(isObjectEmpty(location?.state || {}))){
      getVitalJson();
    }
  }, [location?.state?.encounterId]);
  React.useEffect(()=>{
    if(searchParams.get("data")){
      setLocation({state:JSON.parse(decodeURIComponent(searchParams.get("data")))})
    }
    else{
      setLocation(templocation)
    }
  },[])
  
  React.useEffect(()=>{
    if((Encounterdata?.patientDetails?.gender_id || Encounterdata?.patientdetails?.gender) && ( Encounterdata?.patientDetails?.birthdate || Encounterdata?.patientdetails?.birthdate)){
      dispatch(
        actions.GET_VITALS_JSON({
          gender: Encounterdata?.patientDetails?.gender_id || Encounterdata?.patientdetails?.gender || Encounterdata?.Patient?.[0]?.Gender?._id,
          organization_id: getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"facilityid"}),
          age: Math.floor(
            moment(new Date()).diff(
              moment(
                Encounterdata?.patientDetails?.birthdate || Encounterdata?.patientdetails?.birthdate || Encounterdata?.Patient?.[0]?.personInfo?.[0]?.birthdate,
                "DD/MM/YYYY"
              ),
              "days",
              true
            )
          ),
          diagcode:"", //"S62.619S",
          spltycode: "ALL",
          vitalscode: "",
        })
      );
    }
  },[Encounterdata])


  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div style={{ width: "100%", marginBottom: 0 }}>
      <Row inLineStyles={styles.vitailsCard}>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          inLineStyles={styles.vitalsHead}
        >
          <Text
            inLineStyles={{
              fontWeight: 600,
              fontSize: "14px",
              color: UIColor.primaryColor.color,
              margin:"15px 0"
            }}
          >
            {"Vitals"}
          </Text>
          {!props?.viewOnly && !Encounterdata?.IsViewMode && (
            <>
              {type === "edit" ? (
                <Button
                  variant={"text"}
                  size={"small"}
                  style={{
                    color: "#fff",
                    background: UIColor.primaryColor.color,
                    fontWeight: 500,
                    padding: "3px",
                    margin: "5px",
                  }}
                  onClick={() => saveVitalsLoading ? "" : handleSave("save")}
                  disabled={vitalsJson.length > 0 ? false : true}
                >
                  {editId ? saveVitalsLoading ? "Updating..." : "Update" : saveVitalsLoading ? "Saving..." : "Save"}
                  {saveVitalsLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                </Button>
              ) : (
                <Button
                  variant={"text"}
                  size={"small"}
                  style={{
                    color: "#fff",
                    background: UIColor.primaryColor.color,
                    fontWeight: 500,
                    padding: "2px",
                    margin: "4px",
                  }}
                  onClick={() => setType("edit")}
                  //disabled={vitalsJson.length > 0 ? false : true}
                >
                  Edit
                </Button>
              )}
            </>
          )}
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          inLineStyles={{ padding: "10px 8px" }}
        >
          {readVitalsLoading ? (
              <CircularProgress
                color="primary"
                size={20}
              />
          ) : type === "edit" ? (
            <Row>
              {vitalsJson.length > 0
                ? vitalsJson?.map((val, i) => {
                    let stateLabel = val.vitalscode;
                    return (
                      <React.Fragment>
                        {val.vitalscode !== "BG" &&
                          val.vitalscode !== "LMT" && (
                            <Col
                              xs={12}
                              sm={6}
                              md={3}
                              lg={3}
                              xl={3}
                              inLineStyles={styles.inputStyle}
                            >
                              <div
                                style={{ marginRight: 4, marginBottom: "4px" }}
                              >
                                {val.vitalscode === "HEIGHT" ? (
                                  <HeightsIcon width={"30"} height={"20"} />
                                ) : val.vitalscode === "WEIGHT" ? (
                                  <WeightKgIcon width={"30"} height={"20"} />
                                ) : val.vitalscode === "BMI" ? (
                                  <BmiIcon width={"30"} height={"20"} />
                                ) : val.vitalscode === "BSA" ? (
                                  <Bsa width={"30"} height={"20"} />
                                ) : val.vitalscode === "BodyLength" ? (
                                  <BodyLength width={"30"} height={"20"} />
                                ) : val.vitalscode === "HEADC" ? (
                                  <Head width={"30"} height={"20"} />
                                ) : val.vitalscode === "WSTCM" ? (
                                  <WSTCM width={"30"} height={"20"} />
                                ) : val.vitalscode === "TEMP" ? (
                                  <TemperatureIcon width={"30"} height={"20"} />
                                ) : val.vitalscode === "LOWBP" ? (
                                  <BloodSamplesIcon
                                    width={"30"}
                                    height={"20"}
                                  />
                                ) : val.vitalscode === "HIGHBP" ? (
                                  <BloodSamplesIcon
                                    width={"30"}
                                    height={"20"}
                                  />
                                ) : val.vitalscode === "PULSE" ? (
                                  <HeartPluseIcon width={"30"} height={"20"} />
                                ) : val.vitalscode === "RESP" ? (
                                  <Respiration width={"30"} height={"20"} />
                                ) : val.vitalscode === "O2S" ? (
                                  <Spo2 width={"30"} height={"20"} />
                                ) : (
                                  ""
                                )}
                              </div>
                              <div>
                                <TextInput
                                  type={"number"}
                                  label={val?.shortdesc}
                                  isRequired={val?.mandatory}
                                  placeholder={""}
                                  dateIcon={"cm"}
                                  labelStyle={styles.labelStyle}
                                  onChange={(e) =>
                                    setVital(
                                      stateLabel,
                                      e,
                                      val.VitalsCodesReferenceRanges[0]
                                        .validation_min,
                                      val.VitalsCodesReferenceRanges[0]
                                        .validation_max,
                                      val.default_unit,
                                      val.VitalsCodesReferenceRanges[0]
                                        .normal_min,
                                      val.VitalsCodesReferenceRanges[0]
                                        .normal_max,
                                      val._id,
                                      val?.default_unit_id
                                    )
                                  }
                                  onBlurCapture={(e) =>
                                    bmiandbsa(e, stateLabel)
                                  }
                                  value={state[stateLabel] || ""}
                                  hoverColor={UIColor.lineBorderFill.color}
                                  style={
                                    isErr && errorField[val.vitalscode]
                                      ? styles.errorborderStyle
                                      : styles.borderStyle
                                  }
                                  iconText={val.default_unit}
                                  iconTextStyle={{ color: "#B6B6B6" }}
                                  disabled={val.disabled}
                                  error={
                                    isErr && errorField[val.vitalscode]
                                      ? true
                                      : false
                                  }
                                />
                                <div
                                  style={{
                                    // marginTop: "3%",
                                    position: "absolute",
                                    // bottom: 20,
                                  }}
                                >
                                  {`${state[stateLabel + "error"]}` ===
                                  "false" ? (
                                    <span
                                      style={{ color: "red", fontSize: 12 }}
                                    >
                                      {"Value should be " +
                                        val.VitalsCodesReferenceRanges[0]
                                          .validation_min +
                                        " to " +
                                        val.VitalsCodesReferenceRanges[0]
                                          .validation_max +
                                        "."}
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                            </Col>
                          )}
                        {val.vitalscode === "LMT" && (
                          <Col
                            xs={12}
                            sm={6}
                            md={2}
                            lg={2}
                            xl={2}
                            inLineStyles={styles.CalendarStyle}
                          >
                            <div
                              style={{ marginRight: 4, marginBottom: "4px" }}
                            >
                              <Lmp width={"30"} height={"20"} />
                            </div>
                            <TextInput
                              type="text"
                              dateIcon="images/icons8-calendar-grey.svg"
                              label={"LMP Date"}
                              placeholder={""}
                              labelStyle={styles.labelStyle}
                              style={styles.borderStyle}
                              hoverColor={UIColor.lineBorderFill.color}
                              value={calenderVal}
                            />
                            <div
                              style={styles.CalendarStyleIcon}
                              onClick={handleClick}
                            >
                              <CalendarIcon
                                color={"#B6B6B6"}
                                width={"16"}
                                height={"16"}
                              />
                            </div>
                          </Col>
                        )}
                        {val.vitalscode === "BG" && (
                          <Col
                            xs={12}
                            sm={6}
                            md={2}
                            lg={2}
                            xl={2}
                            inLineStyles={styles.inputStyle}
                          >
                            <Col
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              inLineStyles={styles.TapSelectcol}
                            >
                              <div
                                style={{ marginRight: 4, marginBottom: "4px" }}
                              >
                                <BloodGroup width={"30"} height={"20"} />
                              </div>
                              <TextInput
                                label={"Blood group"}
                                size={"size"}
                                Icon={"cm"}
                                type={"text"}
                                onChange={(e) =>
                                  setDefaultState({
                                    ...defaultState,
                                    blood_group: e.target.value,
                                  })
                                }
                                value={defaultState?.blood_group}
                                labelStyle={styles.labelStyle}
                                placeholder={""}
                                style={styles.inputFiledStyle}
                                hoverColor="#0071F2"
                              />
                            </Col>
                            {false && (
                              <Col
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                xl={6}
                                inLineStyles={styles.TapSelectcol}
                              >
                                <TapSelect
                                  label={""}
                                  inLineStyles={styles.tapSelectStyle}
                                  selectedBorderColor={"#0271F2"}
                                  selectedBackgroundColor={"#0271F2"}
                                  selectedTextColor={"#fff"}
                                  textSize={"small"}
                                  options={bloodType}
                                  onClick={() => console.log("e")}
                                />
                              </Col>
                            )}
                          </Col>
                        )}
                      </React.Fragment>
                    );
                  })
                : "no data found "}

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Calendar
                  maxDate={new Date()}
                  allowPartialRange
                  onClickMonth={(value, event) => {
                    handleClose(false);
                  }}
                  onClickYear={(value, event) => {
                    handleClose(false);
                  }}
                  onClickWeekNumber={(value, event) => {
                    handleClose(false);
                  }}
                  onChange={(value, event) => {
                    setcalenderVal(moment(value).format("DD MMM"));
                  }}
                  className={"pc_regular"}
                />
              </Popover>
            </Row>
          ) : (
            <Row>
              <Col
                xs={12}
                sm={6}
                md={2}
                lg={12}
                xl={12}
                inLineStyles={styles.listVitals}
              >
                <div style={styles.list}>
                  {Object.keys(state).map((val, i) => {
                    return vitalsListArr.indexOf(val) > -1 ? (
                      <React.Fragment>
                        {val === "HEIGHT" ? (
                          <Tooltip title={"Height"}>
                            <div>
                              <HeightsIcon width={"30"} height={"20"} />
                            </div>
                          </Tooltip>
                        ) : val === "WEIGHT" ? (
                          <Tooltip title={"Weight"}>
                            <div>
                              <WeightKgIcon width={"30"} height={"20"} />
                            </div>
                          </Tooltip>
                        ) : val === "BMI" ? (
                          <Tooltip title={"BMI"}>
                            <div>
                              <BmiIcon width={"30"} height={"20"} />
                            </div>
                          </Tooltip>
                        ) : val === "BSA" ? (
                          <Tooltip title={"BSA"}>
                            <div>
                              <Bsa width={"30"} height={"20"} />
                            </div>
                          </Tooltip>
                        ) : val === "BodyLength" ? (
                          <Tooltip title={"BodyLength"}>
                            <div>
                              <BodyLength width={"30"} height={"20"} />
                            </div>
                          </Tooltip>
                        ) : val === "HEADC" ? (
                          <Tooltip title={"Head"}>
                            <div>
                              <Head width={"30"} height={"20"} />
                            </div>
                          </Tooltip>
                        ) : val === "WSTCM" ? (
                          <Tooltip title={"WSTCM"}>
                            <div>
                              <WSTCM width={"30"} height={"20"} />
                            </div>
                          </Tooltip>
                        ) : val === "TEMP" ? (
                          <Tooltip title={"Temperature"}>
                            <div>
                              <TemperatureIcon width={"30"} height={"20"} />
                            </div>
                          </Tooltip>
                        ) : val === "LOWBP" ? (
                          <Tooltip title={"BloodSamples"}>
                            <div>
                              <BloodSamplesIcon width={"30"} height={"20"} />
                            </div>
                          </Tooltip>
                        ) : val === "HIGHBP" ? (
                          <Tooltip title={"BloodSamples"}>
                            <div>
                              <BloodSamplesIcon width={"30"} height={"20"} />
                            </div>
                          </Tooltip>
                        ) : val === "PULSE" ? (
                          <Tooltip title={"HeartPluse"}>
                            <div>
                              <HeartPluseIcon width={"30"} height={"20"} />
                            </div>
                          </Tooltip>
                        ) : val === "RESP" ? (
                          <Tooltip title={"Respiration"}>
                            <div>
                              <Respiration width={"30"} height={"20"} />
                            </div>
                          </Tooltip>
                        ) : val === "O2S" ? (
                          <Tooltip title={"Spo2"}>
                            <div>
                              <Spo2 width={"30"} height={"20"} />
                            </div>
                          </Tooltip>
                        ) : (
                          ""
                        )}
                        {state[val + "uom"] ? (
                          <Text
                            key={i}
                            style={{
                              fontWeight: 500,
                              marginRight: 6,
                            }}
                          >
                            {state[val] + state[val + "uom"]}
                          </Text>
                        ) : (
                          <Text
                            key={i}
                            style={{
                              fontWeight: 500,
                              marginRight: 4,
                              marginLeft: 6,
                            }}
                          >
                            {state[val]}
                          </Text>
                        )}
                      </React.Fragment>
                    ) : (
                      <React.Fragment />
                    );
                    // return vitalsListArr.indexOf(val) > -1 ? <React.Fragment>

                    // </React.Fragment>
                    //   : <React.Fragment />
                  })}
                </div>
                {/* <div>
                        <Text inLineStyles={styles.tagsStyle}>
                          <InfoIcon width={12} style={{ marginRight: 6 }} />
                          Underweight
                        </Text>
                      </div> */}
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default withAllContexts(VitalSigns);