import React from "react";

function ArrowIcon({ height = 24, width = 24, color = "black",direction="left" }) {
  return (
    <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          transform={direction == "up" ? "rotate(90)" : direction == "right" ? "rotate(180)": direction == "down" ? "rotate(270)" : direction=="left" ? "rotate(0)" : "" }   
    >
      <path
        d="M14 7L9 12L14 17"
        stroke={color}
        strokeOpacity={0.5}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ArrowIcon;