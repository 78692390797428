import {
  WorkList,
  CloseConsultation,
  DocumentIcon,
  DashboardIcon,
} from "../../svg";
import { AssignPractitioner } from "../../svg/components/assignPractitioner";
import { ReAssignPractitioner } from "../../svg/components/reassignPractitioner";
import { AppRoutes } from "../../../router/routes";
import { checkWithCasbin, getFacilityId } from "../../../utils";
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
export const drawerData = {
  sideMenuDashboard: {
    icon: <DashboardIcon />,
    label: "Dashboard",
    // link: AppRoutes.dashboardLanding,
    children: [
      {
        icon: <DashboardIcon />,
        label: "Main Dashboard",
        backgroundColor: "#F6EFF8",
        color: "#AA62BA",
        // link:AppRoutes.dashboardLanding,
        key_: "mainMenu",
      },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "HQ Dashboard",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        ilink:`${process.env.REACT_APP_HQ_DASHBOARD}?facilityid=${getFacilityId()}`,
        key_: "HqDashboard",
        style: {
          cursor: "auto",
        },
      },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "PIC Dashboard",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        ilink:`${process.env.REACT_APP_PIC_DASHBOARD}?facilityid=${getFacilityId()}`,
        key_: "picdashboard",
        style: {
          cursor: "auto",
        },
      },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "Nurse Dashboard",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        ilink:`${process.env.REACT_APP_NURSE_DASHBOARD}?facilityid=${getFacilityId()}`,
        key_: "nursedashboard",
        style: {
          cursor: "auto",
        },
      },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "PB Nurse Dashboard",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        ilink:process.env.REACT_APP_PB_NURSE_DASHBOARD,
        key_: "pbnursedashboard",
        style: {
          cursor: "auto",
        },
      },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "Nephrologist Dashboard",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        ilink:`${process.env.REACT_APP_NEPHROLOGIST_DASHBOARD}?facilityid=${getFacilityId()}`,
        key_: "nephrologistdashboard",
        style: {
          cursor: "auto",
        },
      },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "HQ Dashboard v2.0",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        ilink:`${process.env.REACT_APP_HQ_DASHBOARD_V2}?facilityid=${getFacilityId()}`,
        key_: "HqDashboardv2.0",
        style: {
          cursor: "auto",
        },
      },
    ],
  },
  worklist: {
    icon: <WorkList />,
    label: "Work List",
    children: [
      {
        icon: <AssignPractitioner />,
        label: "Assign Practitioner",
        backgroundColor: "#F6EFF8",
        color: "#AA62BA",
        link: AppRoutes.workList,
        key_: "assignPractitionerW",
      },
      {
        icon: <ReAssignPractitioner />,
        label: "Re-Assign Practitioner",
        backgroundColor: "#EFF2F9",
        color: "#5F84C0",
        link: AppRoutes.workList,
        key_: "reassignPractitionerW",
      },
      {
        icon: <CloseConsultation />,
        label: "Close Consultation",
        backgroundColor: "#F7EFEA",
        color: "#AD6338",
        link: AppRoutes.workList,
        key_: "closeConsultationW",
      },
      {
        icon: <DocumentIcon />,
        label: "Day End Process",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: AppRoutes.dayEndProcess,
        key_: "dayEndProcessW",
      },
    ],
  },
  "Work list queue": {
    icon: <WorkList />,
    label: "Work List Queue",
    children: [
      {
        icon: <AssignPractitioner />,
        label:"Dialysis Queue",
        backgroundColor: "#F6EFF8",
        color: "#AA62BA",
        link: AppRoutes.queue,
        key_: "Dialysis Queue",
        queueId: {
          "id": "queue/10022",
          "value": "Dialysis Queue"
      },
        scenarioId: "queuescenarios/10044",
      },
      {
        icon: <ReAssignPractitioner />,
        label: "Nursing Procedure Queue",
        backgroundColor: "#EFF2F9",
        color: "#5F84C0",
        link: AppRoutes.queue,
        key_:"Nursing Procedure Queue",
        queueId: {
          "id": "queue/10026",
          "value": "Nursing Procedure Queue"
      },
        scenarioId: "queuescenarios/10048"
      },
      {
        icon: <CloseConsultation />,
        label: "Claim Queue",
        backgroundColor: "#F7EFEA",
        color: "#AD6338",
        link: AppRoutes.queue,
        key_:  "Claim Queue",
        queueId:{
          "id": "queue/10028",
          "value": "Claim Queue"
      },
        scenarioId: "queuescenarios/10050",
      },
      {
        icon: <DocumentIcon />,
        label: "Referral Queue",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: AppRoutes.queue,
        key_: "Referral Queue",
        queueId:{
          "id": "queue/10021",
          "value": "Referral Queue"
      },
        scenarioId: "queuescenarios/10043",
      },
      {
        icon: <DocumentIcon />,
        label:"Nephrologist Queue",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: AppRoutes.queue,
        key_:"Nephrologist Queue",
        queueId:{
          "id": "queue/10023",
          "value": "Nephrologist Queue"
      },
        scenarioId: "queuescenarios/10045",
      }, {
        icon: <DocumentIcon />,
        label: "Refund Queue",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: AppRoutes.queue,
        key_: "Refund Queue",
        queueId: {
          "id": "queue/10029",
          "value": "Refund Queue"
      },
        scenarioId: "queuescenarios/10052",
      }, {
        icon: <DocumentIcon />,
        label:"PIC Review Queue",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: AppRoutes.queue,
        key_: "PIC Review Queue",
        queueId: {
          "id": "queue/10024",
          "value": "PIC Review Queue"
      },  
        scenarioId: "queuescenarios/10046",
      },
      {
        icon: <DocumentIcon />,
        label:"Appointment Request Queue",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: AppRoutes.queue,
        key_:"Appointment Request Queue",
        queueId:{
          "id": "queue/10030",
          "value": "Appointment Request Queue"
      },
        scenarioId: 'queuescenarios/10053',
      },
      {
        icon: <DocumentIcon />,
        label:"Re-Schedule Work List",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: AppRoutes.queue,
        key_:"Re-Schedule Work List",
        queueId: {
          "id": "queue/10036",
          "value": "Re-Schedule Work List"
      },
         scenarioId: 'queuescenarios/10054',
      },
      {
        icon: <DocumentIcon />,
        label:"Laborder Queue",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: AppRoutes.queue,
        key_: "Laborder Queue",
        queueId:{
          "id": "queue/10025",
          "value": "Laborder Queue"
      },  
        scenarioId: "queuescenarios/10047",
      },{
        icon: <DocumentIcon />,
        label:"E-Referral Queue",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: AppRoutes.queue,
        key_: "E-Referral Queue",
        queueId: {
          "id": "queue/10027",
          "value": "E-Referral Queue"
      },
        scenarioId: "queuescenarios/10051",
      },{
        icon: <DocumentIcon />,
        label:"Appointment Queue",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: AppRoutes.queue,
        key_:"Appointment Queue",
        queueId:{
          "id": "queue/10018",
          "value": "Appointment Queue"
      },
        scenarioId: "queuescenarios/10042",
      },
    ],
  },
  billingCashier: {
    icon: <WorkList />,
    label: "Biller and Cashier",
  },
  bulkbooking: {
    icon: <WorkList />,
    label: "Bulk Booking",
    link: AppRoutes.bulkBooking,
  },
  pricedefinition: {
    icon: <WorkList />,
    label: "Price Definition",
    link: AppRoutes.payerMapping,
  },
  
  // mainMenu: {
  //   icon: <MenuIcon />,
  //   label: 'Menu name',
  //   children: [

  //   ]
  // },
  reports: {
    icon: <WorkList />,
    label: "Reports",
    children: [
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "Cash Collection",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        key_: "Cash Collection Summary report",
        style: {
          cursor: "auto",
        },
      },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "PIC Review Sheet Report",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        key_: "PIC Review Report",
        style: {
          cursor: "auto",
        },
      },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "Bill Register Report",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        ilink:
          process.env.REACT_APP_BILL_REGISTER_REPORT,
        key_: "BillRegister",
        style: {
          cursor: "auto",
        },
      },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "Nursing Procedure Summary Report",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        ilink:
         process.env.REACT_APP_NURSING_PROCEDURE_SUMMARY_REPORT,
        key_: "NursingProcedureSummary",
        style: {
          cursor: "auto",
        },
      },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "Patient Review Chart Report",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        key_: "Patient Review Chart Report",
        style: {
          cursor: "auto",
        },
      },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "Claim Summary Report",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        ilink:
          process.env.REACT_APP_CLAIM_SUMMARY_REPORT,
        key_:"Claim Summary Report",
        style: {
          cursor: "auto",
        },
      },
      // {
      //   container: {
      //     display: "flex",
      //     alignItems: "center",
      //   },
      //   icon: "",
      //   label: "Active Patient Report",
      //   backgroundColor: "#FFF2E5",
      //   color: "#FF9F40",
      //   link: "",
      //   ilink:
      //     process.env.REACT_APP_ACTIVE_PATIENT_REPORT,
      //   key_: "activePatientReport",
      //   style: {
      //     cursor: "auto",
      //   },
      // },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "Patient Attendance Report",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        ilink:
          process.env.REACT_APP_ATTENDANCE_REPORT,
        key_: "attendanceReport1",
        style: {
          cursor: "auto",
        },
      },{
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "Month wise Attendance Report",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        ilink:
          process.env.REACT_APP_MONTHWISE_ATTENDANCE_REPORT,
        key_: "Monthwise Attendance Report",
        style: {
          cursor: "auto",
        },
      },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "Patient Attendance Summary Report",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        ilink:
          process.env.REACT_APP_PATIENT_ATTENDANCE_SUMMARY_REPORT,
        key_: "PatientattendanceSummary",
        style: {
          cursor: "auto",
        },
      },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "Observation Report",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        ilink: "",
        key_: "Obervation Report",
        style: {
          cursor: "auto",
        },
      },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "EPO Consumption Report",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        ilink:"",
        key_: "EPO Consumption Report",
        style: {
          cursor: "auto",
        },
      },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "Panel Report",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        ilink:
          process.env.REACT_APP_PANEL_REPORT,
        key_: "Panel Report",
        style: {
          cursor: "auto",
        },
      },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "Patient Report",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        ilink:
          process.env.REACT_APP_PATIENT_REPORT,
        key_: "Patient Report",
        style: {
          cursor: "auto",
        },
      },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "Cancelled and No Show Report",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        ilink:
          process.env.REACT_APP_CANCEL_NOSHOW_REPORT,
        key_: "Cancelled and No Show Report",
        style: {
          cursor: "auto",
        },
      },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "Bill Cancel Report",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        ilink:"",
        key_: "Bill Cancel Report",
        style: {
          cursor: "auto",
        },
      },
      {
        container: {
          display: "flex",
          alignItems: "center",
        },
        icon: <WorkList />,
        label: "Receipt Cancel Report",
        backgroundColor: "#FFF2E5",
        color: "#FF9F40",
        link: "",
        ilink:"",
        key_: "Receipt Cancel Report",
        style: {
          cursor: "auto",
        },
      },
    ],
  },
  calendar: {
    icon: <EditCalendarIcon />,
    label: "Calendar",
    link: AppRoutes.calender,
  },
};
let workListQueue =[
      "Dialysis Queue",
      "Nursing Procedure Queue",
      "Claim Queue",
      "Referral Queue",
      "Nephrologist Queue",
      "Refund Queue",
      "PIC Review Queue",
      "Appointment Request Queue",
      "Re-Schedule Work List",
      "Laborder Queue",
      "E-Referral Queue",
      "Appointment Queue",
]
export async function getDrawerData(setState, props) {
  let permission = await checkWithCasbin(["sideMenu"]);
  let arr = [];
  
  var unique = [...permission.read, ...permission.write].filter(onlyUnique);
  unique.map((val) => {
    //WORK LIST MENU CONSTRUCT
    if (val === "worklist") {
      let obj = JSON.parse(JSON.stringify(drawerData[val]));
      obj.children = [];
      unique.map((perVal) => {
        drawerData[val].children.filter((v, i) => {
          if (v.key_ === perVal) {
            obj.children.push(drawerData[val].children[i]);
          }
        });
      });
      arr.push(obj);
    } else if (val === "reports") {
      let obj = JSON.parse(JSON.stringify(drawerData[val]));
      obj.children = [];
      unique.map((perVal) => {
        drawerData[val].children.filter((v, i) => {
          if (v.key_ === perVal) {
            obj.children.push(drawerData[val].children[i]);
          }
        });
      });
      arr.push(obj);
    } else if (val === "sideMenuDashboard") {
      let obj = JSON.parse(JSON.stringify(drawerData[val]));
      obj.children = [];
      unique.map((perVal) => {
        drawerData[val].children.filter((v, i) => {
          if (v.key_ === perVal) {
            obj.children.push(drawerData[val].children[i]);
          }
        });
      });
      arr.push(obj);
    }else if (val === "Work list queue") {
      let obj = JSON.parse(JSON.stringify(drawerData[val]));
      obj.children = [];
      workListQueue.map((perVal) => {
        drawerData[val].children.filter((v, i) => {
          if (v.key_ === perVal) {
            obj.children.push(drawerData[val].children[i]);
          }
        });
      });
      arr.push(obj);
    }
    else {
      if (drawerData[val]) {
        arr.push(drawerData[val]);
      }
    }
    // arr.push(drawerData[val])
  });
  setState({
    permissons: permission,
    drawerDataList: arr,
    pic: props?.loggedUserInfo?.data?.photo ?? "",
  });
}

export const giveMeSelectedTab = (props) => {
  let id = "";
  const { navBarArr } = props;
  navBarArr.map((nav) => {
    if (window.location.pathname === nav.link) {
      id = nav.id;
    }
    return nav;
  });
  return id;
};

export const clickHome = (props, navigate) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  //props.history.push(AppRoutes.dashboard);

  //isFullScreen();
  navigate(AppRoutes.dashboard);
};
export const handleProfileClose = (state, setState) => {
  setState({
    ...state,
    anchorEl: null,
  });
};

const redirectLink = (data, props) => {
  if (data.id !== giveMeSelectedTab()) props.history.push(data.link);
};

export const changeState = (key, value, state, setState) => {
  setState({
    ...state,
    [key]: value,
  });
};

export const handleProfileClick = (event, state, setState) => {
  setState({
    ...state,
    anchorEl: event.currentTarget,
  });
};

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
