import React, { useState } from 'react';
import { Box, Typography, IconButton, Collapse } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const ExpandableSection = ({ name, content, showBlueDot = false, isHidden = false }) => {
    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    return (
        <Box style={{ width: '100%', marginBottom: '16px', display: isHidden ? "none" : "" }}>
            {/* Header section with expand icon on left */}
            <Box
                style={{
                    backgroundColor: '#f5f5f5',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "8px 16px"
                }}
                onClick={toggleExpanded}
            >
                {/* Expand icon on the left */}
                <IconButton size='small'>
                    <ExpandMoreIcon
                        style={{
                            fontSize: '1.5rem',
                            transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'transform 0.3s ease',
                            marginRight: '4px',
                        }}
                    />
                </IconButton>
                {/* Conditionally render Blue dot if showBlueDot is true */}
                {showBlueDot && (<span style={{ fontSize: '15px', color: 'blue',padding:"5px 10px"  }}>●</span>)}
                <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: '0.9rem', flexGrow: 1,color:"#1e2b37" }}>
                    {name}
                </Typography>


            </Box>

            {/* Content to show/hide on expand */}
            <Collapse in={expanded}>
                <Box
                    
                    style={{
                        // backgroundColor: '#eeeeee',
                        borderRadius: '4px',
                        marginTop: '8px',
                        display: 'flex',
                        justifyContent: 'start',
                        width:"100%",
                        padding:"8px 24px"
                    }}
                >
                    {content}
                </Box>
            </Collapse>
        </Box>
    );
};

export default ExpandableSection;
