import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AvcIcon from "../../../assets/img/avc_vidoe_icon.svg";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Routes } from "../../../router/routes";
import withAllContexts from "../../../hoc/withAllContexts";
import { AlertProps } from "../../../utils";
// import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primarycare-binder";

const GreenRadio = withStyles({
	root: {
		color: "#6F6F6F",
		"&$checked": {
			color: "#0071F2",
		},
	},
	checked: {},
})((props) => <Radio color="default" {...props} />);
const useStyles = makeStyles((theme) => ({
	root: {
		textAlign: "center",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	title: {
		margin: 0,
		fontSize: 18,
		fontWeight: "bold",
		marginBottom: "2%",
	},
	lableName: {
		paddingBottom: "3%",
	},
	lableT: {
		fontSize: 13,
		marginBottom: 18,
	},
	LoginBtn: {
		backgroundColor: "#0065D9",
		padding: "8px 16px",
		color: "#fff",
		fontSize: 13,
		textTransform: "none",
		borderRadius: 8,
		"&:hover": {
			backgroundColor: "#0069d9",
			borderColor: "#0062cc",
			boxShadow: "none",
		},
	},
}));
const options = ["Son", "Father", "Mother", "Brother", "sister"];

function LoginComp(props) {
	const { data } = props;
	const classes = useStyles();
	let fromApp = props?.location?.state?.fromApp;
	const [value, setValue] = React.useState([]);
	const [inputValue, setInputValue] = React.useState(null);
	const [passCode, setPassCode] = React.useState("");
	const [PatORGuard, setPatORGuard] = React.useState("Patient");
	const handelChange = (event) => {
		setPatORGuard(event.target.value);
	};
	const LoginFun = () => {
		let avc = data?.dataJson?.avc;
		let passcde=avc.passCode;
		if (PatORGuard === "Patient") {
			if (fromApp || `${avc.passCode}` === `${passcde}`) {
				props.loginfun(passcde, PatORGuard)
			} else {
				props.alert.setSnack({
					open: true,
					severity: AlertProps.severity.error,
					msg: "Please enter valid passcode" + avc.passCode,
					vertical: AlertProps.vertical.top,
					horizontal: AlertProps.horizontal.center,
					tone: true,
				});
			}
		} else {
			if (inputValue && passCode) {
				if (fromApp || `${avc.passCode}` === passCode) {
					props.loginfun(passCode, PatORGuard)
				} else {
					props.alert.setSnack({
						open: true,
						severity: AlertProps.severity.error,
						msg: "Please enter valid passcode",
						vertical: AlertProps.vertical.top,
						horizontal: AlertProps.horizontal.center,
						tone: true,
					});
				}
			} else {
				props.alert.setSnack({
					open: true,
					severity: AlertProps.severity.error,
					msg: "Please fill all mandatory fields",
					vertical: AlertProps.vertical.top,
					horizontal: AlertProps.horizontal.center,
					tone: true,
				});
			}
		}
	}
	return (
		<React.Fragment>
			<Grid container spacing={3} className={classes.root}>
				<Grid item xs={12}>
					<img src={AvcIcon} />
				</Grid>
				<p className={classes.title}>Login Consultation as</p>
				<FormControl component="fieldset" style={{ marginBottom: "4%" }}>
					<RadioGroup
						row
						aria-label="position"
						name="position"
						defaultValue="top"
						value={PatORGuard}
						onChange={handelChange}
					>
						<FormControlLabel
							value="Patient"
							control={<GreenRadio />}
							style={{
								color: PatORGuard === "Patient" ? "#0071F2" : "#6F6F6F",
								display:"none",
							}}
							label="Patient"
						/>
						{/* <FormControlLabel
							value="Guardian"
							control={<GreenRadio />}
							style={{
								color: PatORGuard === "Guardian" ? "#0071F2" : "#6F6F6F",
							}}
							label="Guardian"
						/> */}
					</RadioGroup>
				</FormControl>
				<Grid item xs={12} style={{ textAlign: "left", padding: "0px 12px" }}>
					<FormLabel className={classes.lableT}>
						{PatORGuard === "Patient" ? "Patient Name" : "Guardian Name"}
					</FormLabel>
					<TextField
						id="standard-full-width"
						style={{ marginTop: "6px" }}
						placeholder="Enter Patient Name"
						fullWidth
						margin="normal"
						value={data?.patient?.name ?? ""}
						disabled={true}
						size={"small"}
						variant={"outlined"}
					/>
				</Grid>
				{PatORGuard != "Patient" ? (
					<Grid item xs={12} style={{ textAlign: "left" }}>
						<FormLabel className={classes.lableT}>
							Guardian Relationship
						</FormLabel>
						<Autocomplete
							value={value}
							onChange={(event, newValue) => {
								setValue(newValue);
							}}
							inputValue={inputValue}
							onInputChange={(event, newInputValue) => {
								setInputValue(newInputValue);
							}}
							id="controllable-states-demo"
							options={options}
							style={{ marginTop: "6px" }}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									placeholder={"Choose the relationship"}
								/>
							)}
							size={"small"}
							fullWidth
						/>
					</Grid>
				) : null}
				{!fromApp && <Grid item xs={12} style={{ textAlign: "left", padding: "0px 12px", display:"none" }}>
					<FormLabel className={classes.lableT}>Passcode</FormLabel>
					<TextField
						id="standard-full-width"
						style={{ marginTop: "6px" }}
						placeholder="Enter Passcode"
						fullWidth
						onChange={e => setPassCode(e.target.value)}
						value={passCode}
						margin="normal"
						type={"password"}
						size={"small"}
						variant={"outlined"}
					/>
				</Grid>}

				<Grid item xs={12} style={{ padding: "0px 12px", marginTop: "8%" }}>
					<Button variant="contained" className={classes.LoginBtn}
						// onClick={() => props.history.push({pathname: Routes.avcLanding})}
						onClick={() => LoginFun()}
					>
						Login in and Proceed
					</Button>
				</Grid>
			</Grid>
		</React.Fragment>
	);
}

export default (withAllContexts(LoginComp));