import React, { useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Div,
  Row,
  SideMenu,
  Button,
  Paper,
  Col,
  H1,
} from "qdm-component-library";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import {
  AlertContext,
  BackdropContext,
  SetPatientDetailsContext,
} from "../../context";
import withAppBar from "../../hoc/withAppBar";
import withAllContexts from "../../hoc/withAllContexts";
import Loading from "../../components/global/loading";
import PatientCard from "./components/patientCard";
import {
  calculateAge,
  makeName,
  getUserInfo,
  checkWithCasbin,
  AlertProps,
  checkError,
  getImgUrl,
  fetchData,
  urls,
  personRequests,
  getFacilityId,
  utcTOLocal,
  getCalculatedAge,
  getGlobalValueFrom_LclStrg_And_QryStrg,
  FnExecutorBasedOnFLow,
  isObjectEmpty,
  splitAfterUnderscore,
  IsPersonAsRelations,
} from "../../utils";
import { AppRoutes } from "../../router/routes";
import PatientCareTypeCard from "./components/patientCareTypeCard";
import actions from "../../redux/actions";
import dollarIcon from "../../assets/img/svg/Group 90507.svg";
import PatientDashboardNavBar from "./components/patientDashboardNavBar";
import CardList from "./components/cardList";
import ViewCard from "./components/viewCard";
import EmptyCard from "./components/emptyCard";
import BillNav from "./components/billNav";
import TableList from "./components/billTable";
import CancelBookingPage from "../cancelBooking";
import CancelWatingListBookingPage from "../cancelWatingListBooking";
import ConfirmationPage from "../confirmBooking";
import Modal from "../../components/modal";
import MultiBookComp from "../../components/multibook/multiBookComp";
import Viewbill from "./components/viewbill";
import ViewReceipt from "./components/viewReceipt";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import UseQueryString from "../../hooks/queryString";
import AppointmentAddMemberSuccessPage from "../../components/global/appointmentAddMemberSuccessPage";
import AddMember from "../../components/global/addMember";
import Addmemberform from "../../components/global/addmemberform";
import Addmemberotp from "../../components/global/addmemberotp";


import jwtDecode from "jwt-decode";
const casbinjs = require("casbin.js");

const tableHeader = [
  "S.No",
  "Bill.No",
  "Date",
  "Gross Amt",
  "Discount",
  "Net Payable",
  "Paid",
  "Outstanding",
  "Settle Now",
];

const PatientDashBoard = (props) => {
  const decodedUserinfo = getGlobalValueFrom_LclStrg_And_QryStrg({ localStoragekeyName: "UserInfo", isFullObject: true });
  let decodePermissionData = getGlobalValueFrom_LclStrg_And_QryStrg({ localStoragekeyName: "PermissionData", isFullObject: true });
  let userrole = decodedUserinfo?.userrole
  const facilityId = decodedUserinfo?.facilityid ?? "";
  const roleName = decodedUserinfo.rolename;
  const roleId = decodedUserinfo.idmroleid;
  const isPatient = localStorage.getItem("RoleName")==="pc_patient" ? true:false
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = UseQueryString();
  console.log("location", location);

  const setPatientDatacontext = useContext(SetPatientDetailsContext);

  const loggedUserInfo = useSelector((state) => state?.auth?.loggedUserInfo);
  const Outstanding = useSelector((state) => state?.PatientDashboard?.outstanding);
  // const props = {
  //   location: {
  //     pathname: "/patient_dashboard",
  //     state: {
  //       userId: 1370,
  //       mobileNumber: "+919944349816",
  //       patientId: 1089,
  //     },
  //     search: "",
  //     hash: "",
  //     key: "yl7ng8",
  //   },
  // };

  // State Variables
  const [personId, setPersonId] = useState("");
  const [state, setState] = useState({
    open: false,
    cancelbooking: false,
    cancelbookingreason: {},
    cancelbookings: 1,
    activeId: 1,
    addmmber: false,
    sidebar: props.location?.state?.addMember,
    memberForm: props.location?.state?.addMember,
    searchMenber: props.location?.state?.addMember,
    memberSuccessPage: props.location?.state?.addMember,
    appointmentId: 0,
    choosemember: [],
    memberIds: [],
    chooseSearch: null,
    searchmmrdata: null,
    loggedInState: {},
    patientDetails: {},
    newPatientDetails: {},
    isBookAppoinment: false,
    fin_data: [],
    selectOption: {},
    editOpen: false,
    personDetails: {},
    callcen: false,
    permission: {},
    permissionData: [],
    loading: true,
    allPatientDetails: {},
    OutstandingData: {},
    cancelBillPermission: {},
    cancelReceipt: {},
    cancelWatingListbookings: 1,
    cancelWatingListbookingreason: {},
    cancelWatingListbooking: false,

    // appointments: {},
    // loggedUserInfo: {},
  });

  const [multiBookOpen, setMultiBookOpen] = useState(false);
  // const [selectedQueueid, setSelectedQueueid] = React.useState("");
  const [mBData, setMbData] = React.useState({});
  const [viewBill, setViewBill] = React.useState(false);
  const [isTransitType, setIsTransitType] = React.useState(false);
  const [viewReceipt, setViewReceipt] = React.useState(false);
  const [billAndreceiptData, setBillANdReceiptdata] = React.useState({});
  // Use Selectors
  const appointments = useSelector(
    (state) => state?.appointment?.allAppointments
  );
  const WaitingListappointments = useSelector(
    (state) => state?.appointment?.watingListAppoinments
  );
  const typeofBookButtons = useSelector(
    (state) => state?.searchHospital.typeOfbookapmnt
  );
  let flow= FnExecutorBasedOnFLow({
    AppoitmentFlowFn: () => {
   return false
    },
    DialysisFlowFn: async () => {
   return true
    },
  });
  // Use Effect
  const getAllAppointment = () => {
    const getEpochTime = (date) => Math.floor(date.getTime() / 1000);
    const currentDate = new Date();
    const startDate = new Date(currentDate);
    startDate.setHours(0, 0, 0, 0);
    const startEpochTime = getEpochTime(startDate);

    dispatch(
      actions.ALL_APPOINTMENTS({
        patientid: location?.state?.patientId,
        tenantid: "",
        facilityid: facilityId,
        assemble: true,
        startdate: startEpochTime,
        personid: location?.state?.patientId ?"" :personId,
      })
    );
    dispatch(
      actions.WATING_LIST_APPOINMENTS({
        patientId: location?.state?.patientId,
        startDate: startEpochTime
      })
    );
  };

  React.useEffect(() => {
    if (!(isObjectEmpty(location?.state || {}))) {
      getPatientInfo();
      getAllAppointment();
      dispatch(actions.TypeOfBookAppmntButton({facilityId:facilityId,roleId:roleId}));
    }
  }, [location]);
  console.log("StateValue", state, state?.permission?.write?.includes("viewEMR"));
  // Functions

  async function getPatientInfo() {
    
    setPatientDatacontext((prevtstate) => ({
      ...prevtstate,
      patientId: location?.state?.patientId,
    }));
    //let permission_ = await checkWithCasbin(["patient_dashboard"]);
    // setState({ ...state, permissionData: permission_ });
    //let d =
    //state?.permissionData?.write?.indexOf("patientBannerStatus") > -1;

    let decodedRoleData = getGlobalValueFrom_LclStrg_And_QryStrg({ localStoragekeyName: "RoleData", isFullObject: true })


    // const accessObj = decodedRoleData?.[roleName.toLowerCase()]?.access || [];
    const accessObj = decodedRoleData?.access || [];

    // const Arr = localStorage.getItem("role_data");
    // const arr1 = JSON.parse(atob(Arr))[localStorage.getItem("role_name")]
    //   .access;
    const permission = { callcenter: accessObj };
    const authorizer = new casbinjs.Authorizer("manual", permission);
    authorizer.setPermission(permission);
    const result = await authorizer.can("callcenter", "4");
    let dataList = await checkWithCasbin(["patient_dashboard"]);

    if (dataList.read.length === 0 &&
      dataList.write.length === 0) {
      dataList.read = [
        "upcoming",
        "followUpReminder",
        "visits",
      ]
      dataList.write = [
        "viewEMR"
      ]
    }
    // New Code Block START
    let info =jwtDecode(localStorage.getItem("UserInfo"))
    const personid =info?.personid ;

    const patientInfo = await dispatch(
      actions.PATIENT_INFO({
        patientid: location?.state?.patientId,
        tenantid: "",
        facilityid: "",
        lang: "",
        personid:location?.state?.patientId ? "": personid ,
      })
    );
    await dispatch(
      actions.GET_OUTSTANDING_DATA(location?.state?.patientId)
    );
    // New Code Block END
    fetchInfo();
    //const getfetchInfo = await fetchInfo();
    const patientID = props?.location?.state?.patientId || 0;
    // const fin_data = await dispatch(
    //   actions.GET_FINANCIAL({ patientId: patientID })
    // );
    const fin_data = patientInfo;
    if (fin_data?.payload?.data) {
      const fin_DATA = fin_data?.payload?.data?.financialdetails?.map((v) => {
        //const setdata = v?.payersprioritycoverage?.[0];
        return {
          pic: dollarIcon,
          name: null,
          care_type: v?.payertype?.longdesc,
          title_name: v?.payer?.longdesc,
          priority: v?.priority,
          policyexpiry: v?.policyexpiry ? utcTOLocal(v?.policyexpiry, "DD-MM-YYYY") : "",
          policy: v?.policyid?.longdesc ?? "",
          payer: v?.payer?._id
        };
      });
      const findata = fin_DATA//fin_DATA?.reverse();

      // oustanding data
      // const Outstanding = await dispatch(
      //   actions.GET_OUTSTANDING_DATA(location?.state?.patientId)
      // );

      setState((prevState) => ({
        ...prevState,
        // ...getfetchInfo,
        //permissionData: permission_,
        callcen: result,
        permission: dataList,
        selectOption: fin_DATA?.[0] || {},
        fin_data: findata || [],
        newPatientDetails: patientInfo?.payload?.data?.patientdetails,
        loading: false,
        allPatientDetails: patientInfo?.payload?.data,
        OutstandingData: Outstanding?.data?.[0]?.outstandingamount,
        advanceAmount: Outstanding?.data?.[0]?.advanceamount,
        cancelBillPermission: decodePermissionData?.["patient_dashboard"]?.["component"]?.["viewcancelbill"],
        cancelReceipt: decodePermissionData?.["patient_dashboard"]?.["component"]?.["cancelreceipt"]
      }));

      setPersonId(patientInfo?.payload?.data?.patientdetails?.personid || personid || "");

      // setState({
      //   ...state,
      //   ...getfetchInfo,
      //   permissionData: permission_,
      //   callcen: result,
      //   permission: dataList,
      //   selectOption: fin_DATA[0],
      //   fin_data: fin_DATA,
      //   loading: false,
      // });
    }
  }

  const fetchInfo = async () => {
    // if (!location?.state?.mobileNumber) {
    //   window.location.href = "/";
    //   return;
    // }
    const info = getUserInfo();
    let mobileNo = location?.state?.mobileNumber;

    const persondata = await dispatch(
      actions.READ_PERSON_WITH_ID({
        personid: location?.state?.userId, // "Person/15162",
        tenantid: "",
        facilityid: "",
      })
    );
    const patientInfo = await dispatch(
      actions.PATIENT_INFO({
        patientid: location?.state?.patientId,
        tenantid: "",
        facilityid: "",
        lang: "",
      })
    );

    const val = await dispatch(
      actions.GET__FOLLOW_UP_APPOINTMENT({
        patientId:
          // 'Patient/10238'
          patientInfo.payload?.data?._id,
      })
    );

    console.log(val?.payload?.data);

    setState((prevState) => ({
      ...prevState,
      followUpAppointmentData: val?.payload?.data,
    }));

    if (!state.callcen && patientInfo.payload.data.length > 0) {
      if (
        patientInfo.payload?.data?.patientdetails?.isdeceased?.display === "Deceased" ||
        !patientInfo.payload?.data?.patientdetails?.active
      ) {
        // await props.UPSERT_CANCEL_APP_PATIENT({
        //   personId: props.location?.state?.userId,
        // });
        // await dispatch(
        //   actions.UPSERT_CANCEL_APP_PATIENT({
        //     personId: props.location?.state?.userId,
        //   })
        // );
        if (appointments?.data?.currentVisist?.length > 0) {
          // const dlt = appointments?.data?.currentVisist?.map(async (v) => (
          //     await props.ORGANIZATION_VISIT_REGISTRATION({
          //         payloaddata: {
          // Json['cancel_date'] = moment().unix();
          // Json['revision_date'] = 0;
          // },
          //         enId: state.encount._id
          //     })
          // ))
        }
      }
    }

    setState((prevState) => ({
      ...prevState,
      loggedInState: { ...info },
      patientDetails: persondata?.payload?.data,
      personDetails: patientInfo?.payload?.data?.patientdetails,
      primaryMember: persondata?.payload?.data ?? {},
    }));

    const userId = persondata?.payload?.data?.id;
    const data = await dispatch(actions.GET_RELATED_PERSON({ userid: userId }));
    const choosemember = [];
    const memberIds = [userId];
    if (data?.payload?.data && Array.isArray(data?.payload?.data)) {
      data.payload.data.forEach((val) => {
        choosemember.push({
          src: "",
          label: val?.name?.[0]?.text || val?.name?.[0]?.given || "",
          id: val?.PersonID,
        });
        memberIds.push(val?.PersonID);
      });
    }

    setState((prevState) => ({
      ...prevState,
      choosemember,
      memberIds,
    }));

    const getEpochTime = (date) => Math.floor(date.getTime() / 1000);
    const currentDate = new Date();
    const startDate = new Date(currentDate);
    startDate.setHours(0, 0, 0, 0);
    const startEpochTime = getEpochTime(startDate);
    let token =jwtDecode(localStorage.getItem("UserInfo"))
    const personid =token?.personid ;
    await dispatch(
      actions.ALL_APPOINTMENTS({
        patientid:
          // state?.newPatientDetails?.personid ??
          location?.state?.patientId,
        // location?.state?.userId,
        tenantid: "",
        facilityid: facilityId,
        assemble: true,
        startdate:startEpochTime,
        personid:location?.state?.patientId ?"": personid ,
      })
    );
  };

  const toggleSideBar = (boolean) => {
    setState((prevState) => ({
      ...prevState,
      sidebar: boolean,
      memberForm: false,
      searchMenber: true,
      addmmber: false,
      memberSuccessPage: false,
    }));
  };

  const handleEdit = (isEdit,_id) => {
    setState((prevState) => ({
      ...prevState,
      editOpen: isEdit,
    }));

    //setState({ ...state, editOpen: isEdit });
    // console.log("patient",state?.patientDetails);
    // return
    // props.history.push({

    // navigate({
    //   pathname: AppRoutes.assemblePatioentRegister,
    //   state: {
    //     patientData: state?.patientDetails?.username,
    //     isModify: true,
    //     key:
    //       props.location?.state?.key ||
    //       location?.state?.patientId?.split("/")?.[1],
    //       patientId: location?.state?.patientId,
    //     // state.patientDetails.patientId,
    //       ids: state.patientDetails.id,
    //       personId: state.patientDetails?.id,
    //       Ispatient: false,
    //       idedit: true,
    //   },
    // });

    navigate(AppRoutes.patientRegistration, {
      state: {
        isModify: userrole ===IsPersonAsRelations?.isPerson ? false : true,
        data: state?.allPatientDetails,
        bookAppTypeId:_id,
        isPerson :true,
      },
    });
  };

  const handelChangeSelect = (v) => {
    setState((prevState) => ({
      ...prevState,
      chooseSearch: v,
    }));
  };

  const handleSelectCancelbooking = (v) => {
    setState((prevState) => ({
      ...prevState,
      cancelbookingreason: v,
    }));
  };

  const handlecahngeCancelbooking = async (v, number) => {
    if (v === "back") {
      setState((prevState) => ({
        ...prevState,
        cancelbookings: 1,
      }));
      return;
    }
    if (!v) {
      handlesidebarCancelbooking();
      window.location.href = "/dashboard";
      return;
    }
    let mobile = number;
    if (!number) {
      const userInfo = getUserInfo();
      mobile = userInfo.mobile;
    }
    if (v === 1) {
      const data = await props.GENERATE_OTP({ mobileNo: mobile });
      const { isError, errMsg } = checkError(data?.payload);
      if (isError) {
        props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: errMsg,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        return;
      }
    } else if (v === 2) {
      fetchInfo();
    }

    setState((prevState) => ({
      ...prevState,
      cancelbookings: v,
      otpnumber: number,
    }));
  };

  const setActiveId = (Id) => {
    setState((prevState) => ({
      ...prevState,
      activeId: Id,
    }));
  };

  const btnClick = () => {
    if (state?.permission?.write?.indexOf("patientBannerEditIcon") > -1) {
      setState((prevState) => ({
        ...prevState,
        isBookAppoinment: true,
      }));
      console.log("btnClick", state.allPatientDetails);
      navigate(AppRoutes.searchFacility, {
        state: {
          // userId: userId,
          // mobileNo: location?.state?.mobileNumber,
          // appointmentId: props.location?.state?.appointmentId,
          // patientId: location?.state?.patientId,
          // walkin: props.location?.state?.guest,
          patientDetails: state.allPatientDetails,
        },
      });

      // props.history.push(Routes.doctorConsultation);
    } else {
      let userId = props.location?.state?.userId;
      // props.history.push({
      navigate(AppRoutes.searchFacility, {
        state: {
          // userId: userId,
          // mobileNo: location?.state?.mobileNumber,
          // appointmentId: props.location?.state?.appointmentId,
          // patientId: location?.state?.patientId,
          // walkin: props.location?.state?.guest,
          patientDetails: state.allPatientDetails,
        },
      });
    }
  };
  const upcomingVisitCradConstruction = () => {
    const { permission } = state;
    let constructCard = [];
    if (
      appointments?.data?.upcomingAppointmentNextMonth?.length === 0 &&
      appointments?.data?.upcomingAppointmentNextWeek?.length === 0 &&
      appointments?.data?.upcomingAppointmentThisMonth?.length === 0 &&
      appointments?.data?.upcomingAppointmentThisWeek?.length === 0 &&
      appointments?.data?.upcomingAppointmentToday?.length === 0
    ) {
      constructCard.push({
        id: 1,
        name: "Empty",
        component: <EmptyCard parent_id={"patient_dashboard"} />,
      });
    } else {
      if (appointments?.data?.upcomingAppointmentToday?.length > 0) {
        constructCard.push({
          id: 2,
          name: "Today",
          component: appointments?.data?.upcomingAppointmentToday.map(
            (val, i) => (
              <>
                {i === 0 && (
                  <H1
                    id={`${i}TODAY`}
                    key={"0"}
                    name="TODAY"
                    className="pc_medium"
                    style={{
                      color: "#6F6F6F",
                      fontSize: "14px",
                      margin: 0,
                      textTransform: "uppercase",
                      letterSpacing: 0,
                      lineHeight: 1,
                      padding: "0px 17px",
                      marginBottom: 8,
                    }}
                  ></H1>
                )}
                <ViewCard
                  parent_id={"patient_dashboard" + i}
                  patientDetails={state.allPatientDetails}
                  openView={openView}
                  val={val}
                  key={i}
                  // isDiabled={
                  //   state.personDetails?.isdeceased?.display === "Deceased" ||
                  //   !state?.personDetails?.active
                  // }
                  isBookNow={permission?.write?.includes("bookAppoinment")}
                  ismodify={permission?.write?.includes("modifyAppoinment")}
                  iscancel={permission?.write?.includes("cancelAppoinment")}
                  isTransit={permission?.write?.includes("transitAppoinment")}
                  isViewApp={permission?.write?.includes("viewAppoinment")}
                  isViewReceipt={permission?.write?.includes("viewReceipt")}
                  isViewBill={permission?.write?.includes("viewBill")}
                  isModifyVisit={permission?.write?.includes("modifyVisit")}
                  isViewVist={permission?.write?.includes("viewVisit")}
                  isViewEMR={permission?.write?.includes("viewEMR")}
                  handlesidebarCancelbooking={handlesidebarCancelbooking}
                  loggedUserInfo={loggedUserInfo}
                  cancel={true}
                  upcoming={true}
                  handleMultiBookData={handleMultiBookData}
                   avcUrl={isPatient ? val?.avc?.guestUrl: val?.avc?.moderatorUrl}
                   getAllAppointment={getAllAppointment}
                />
              </>
            )
          ),
        });
      }
      if (appointments?.data?.upcomingAppointmentThisWeek?.length > 0) {
        constructCard.push({
          id: 3,
          name: "This Week",
          component: appointments?.data?.upcomingAppointmentThisWeek.map(
            (val, i) => (
              <>
                {i === 0 && (
                  <H1
                    id={`${i}_THIS_WEEK`}
                    key={"0"}
                    name="THIS WEEK"
                    className="pc_medium"
                    style={{
                      color: "#6F6F6F",
                      fontSize: "14px",
                      margin: 0,
                      textTransform: "uppercase",
                      letterSpacing: 0,
                      lineHeight: 1,
                      padding: "0px 17px",
                      marginBottom: 8,
                    }}
                  ></H1>
                )}
                <ViewCard
                  parent_id={"patient_dashboard" + i}
                  patientDetails={state.allPatientDetails}
                  openView={openView}
                  val={val}
                  key={i}
                  isDiabled={
                    state.personDetails?.isdeceased?.display === "Deceased" ||
                    !state?.personDetails?.active
                  }
                  isBookNow={permission?.write?.includes("bookAppoinment")}
                  ismodify={permission?.write?.includes("modifyAppoinment")}
                  iscancel={permission?.write?.includes("cancelAppoinment")}
                  isTransit={permission?.write?.includes("transitAppoinment")}
                  isViewApp={permission?.write?.includes("viewAppoinment")}
                  isViewReceipt={permission?.write?.includes("viewReceipt")}
                  isViewBill={permission?.write?.includes("viewBill")}
                  isModifyVisit={permission?.write?.includes("modifyVisit")}
                  isViewVist={permission?.write?.includes("viewVisit")}
                  isViewEMR={permission?.write?.includes("viewEMR")}
                  handlesidebarCancelbooking={handlesidebarCancelbooking}
                  loggedUserInfo={loggedUserInfo}
                  cancel={true}
                  upcoming={true}
                  handleMultiBookData={handleMultiBookData}
                   avcUrl={isPatient ? val?.avc?.guestUrl: val?.avc?.moderatorUrl}
                   getAllAppointment={getAllAppointment}
                />
              </>
            )
          ),
        });
      }
      if (appointments?.data?.upcomingAppointmentNextWeek?.length > 0) {
        constructCard.push({
          id: 4,
          name: "Next Week",
          component: appointments?.data?.upcomingAppointmentNextWeek.map(
            (val, i) => (
              <>
                {i === 0 && (
                  <H1
                    id={`${i}NEXT_WEEK`}
                    key={"0"}
                    name="NEXT WEEK"
                    className="pc_medium"
                    style={{
                      color: "#6F6F6F",
                      fontSize: "14px",
                      margin: 0,
                      textTransform: "uppercase",
                      letterSpacing: 0,
                      lineHeight: 1,
                      padding: "0px 17px",
                      marginBottom: 8,
                    }}
                  ></H1>
                )}
                <ViewCard
                  parent_id={"patient_dashboard" + i}
                  patientDetails={state.allPatientDetails}
                  openView={openView}
                  val={val}
                  key={i}
                  isDiabled={
                    state.personDetails?.isdeceased?.display === "Deceased" ||
                    !state?.personDetails?.active
                  }
                  isBookNow={permission?.write?.includes("bookAppoinment")}
                  ismodify={permission?.write?.includes("modifyAppoinment")}
                  iscancel={permission?.write?.includes("cancelAppoinment")}
                  isTransit={permission?.write?.includes("transitAppoinment")}
                  isViewApp={permission?.write?.includes("viewAppoinment")}
                  isViewReceipt={permission?.write?.includes("viewReceipt")}
                  isViewBill={permission?.write?.includes("viewBill")}
                  isModifyVisit={permission?.write?.includes("modifyVisit")}
                  isViewVist={permission?.write?.includes("viewVisit")}
                  isViewEMR={permission?.write?.includes("viewEMR")}
                  handlesidebarCancelbooking={handlesidebarCancelbooking}
                  loggedUserInfo={loggedUserInfo}
                  cancel={true}
                  upcoming={true}
                  handleMultiBookData={handleMultiBookData}
                   avcUrl={isPatient ? val?.avc?.guestUrl: val?.avc?.moderatorUrl}
                   getAllAppointment={getAllAppointment}
                />
              </>
            )
          ),
        });
      }
      if (appointments?.data?.upcomingAppointmentThisMonth?.length > 0) {
        constructCard.push({
          id: 5,
          name: "This Month",
          component: appointments?.data?.upcomingAppointmentThisMonth.map(
            (val, i) => (
              <>
                {i === 0 && (
                  <H1
                    id={`${i}THIS_MONTH`}
                    key={"0"}
                    name="THIS MONTH"
                    className="pc_medium"
                    style={{
                      color: "#6F6F6F",
                      fontSize: "14px",
                      margin: 0,
                      textTransform: "uppercase",
                      letterSpacing: 0,
                      lineHeight: 1,
                      padding: "0px 17px",
                      marginBottom: 8,
                    }}
                  ></H1>
                )}
                <ViewCard
                  parent_id={"patient_dashboard" + i}
                  patientDetails={state.allPatientDetails}
                  openView={openView}
                  val={val}
                  key={i}
                  isDiabled={
                    state.personDetails?.isdeceased?.display === "Deceased" ||
                    !state?.personDetails?.active
                  }
                  isBookNow={permission?.write?.includes("bookAppoinment")}
                  ismodify={permission?.write?.includes("modifyAppoinment")}
                  iscancel={permission?.write?.includes("cancelAppoinment")}
                  isTransit={permission?.write?.includes("transitAppoinment")}
                  isViewApp={permission?.write?.includes("viewAppoinment")}
                  isViewReceipt={permission?.write?.includes("viewReceipt")}
                  isViewBill={permission?.write?.includes("viewBill")}
                  isModifyVisit={permission?.write?.includes("modifyVisit")}
                  isViewVist={permission?.write?.includes("viewVisit")}
                  isViewEMR={permission?.write?.includes("viewEMR")}
                  handlesidebarCancelbooking={handlesidebarCancelbooking}
                  loggedUserInfo={loggedUserInfo}
                  cancel={true}
                  upcoming={true}
                  handleMultiBookData={handleMultiBookData}
                   avcUrl={isPatient ? val?.avc?.guestUrl: val?.avc?.moderatorUrl}
                   getAllAppointment={getAllAppointment}
                />
              </>
            )
          ),
        });
      }
      if (appointments?.data?.upcomingAppointmentNextMonth?.length > 0) {
        constructCard.push({
          id: 6,
          name: "Next Month",
          component: appointments?.data?.upcomingAppointmentNextMonth.map(
            (val, i) => (
              <>
                {i === 0 && (
                  <H1
                    id={`${i}_NEXT_MONTH`}
                    key={"0"}
                    name="NEXT MONTH"
                    className="pc_medium"
                    style={{
                      color: "#6F6F6F",
                      fontSize: "14px",
                      margin: 0,
                      textTransform: "uppercase",
                      letterSpacing: 0,
                      lineHeight: 1,
                      padding: "0px 17px",
                      marginBottom: 8,
                    }}
                  ></H1>
                )}
                <ViewCard
                  parent_id={"patient_dashboard" + i}
                  patientDetails={state.allPatientDetails}
                  openView={openView}
                  val={val}
                  key={i}
                  isDiabled={
                    state.personDetails?.isdeceased?.display === "Deceased" ||
                    !state?.personDetails?.active
                  }
                  isBookNow={permission?.write?.includes("bookAppoinment")}
                  ismodify={permission?.write?.includes("modifyAppoinment")}
                  iscancel={permission?.write?.includes("cancelAppoinment")}
                  isTransit={permission?.write?.includes("transitAppoinment")}
                  isViewApp={permission?.write?.includes("viewAppoinment")}
                  isViewReceipt={permission?.write?.includes("viewReceipt")}
                  isViewBill={permission?.write?.includes("viewBill")}
                  isModifyVisit={permission?.write?.includes("modifyVisit")}
                  isViewVist={permission?.write?.includes("viewVisit")}
                  isViewEMR={permission?.write?.includes("viewEMR")}
                  handlesidebarCancelbooking={handlesidebarCancelbooking}
                  loggedUserInfo={loggedUserInfo}
                  cancel={true}
                  upcoming={true}
                  handleMultiBookData={handleMultiBookData}
                   avcUrl={isPatient ? val?.avc?.guestUrl: val?.avc?.moderatorUrl}
                   getAllAppointment={getAllAppointment}
                />
              </>
            )
          ),
        });
      }
    }
      if (WaitingListappointments?.data?.length > 0) {
        constructCard.push({
          id: 2,
          name: "Today",
          component: WaitingListappointments?.data?.map(
            (val, i) => (
              <>
                {i === 0 && (
                  <H1
                    id={`${i}TODAY`}
                    key={"0"}
                    name="TODAY"
                    className="pc_medium"
                    style={{
                      color: "#6F6F6F",
                      fontSize: "14px",
                      margin: 0,
                      textTransform: "uppercase",
                      letterSpacing: 0,
                      lineHeight: 1,
                      padding: "0px 17px",
                      marginBottom: 8,
                    }}
                  ></H1>
                )}
                <ViewCard
                  parent_id={"patient_dashboard" + i}
                  patientDetails={state.allPatientDetails}
                  openView={openView}
                  val={val}
                  key={i}
                  // isDiabled={
                  //   state.personDetails?.isdeceased?.display === "Deceased" ||
                  //   !state?.personDetails?.active
                  // }
                  // isBookNow={permission?.write?.includes("bookAppoinment")}
                  // ismodify={permission?.write?.includes("modifyAppoinment")}
                  // iscancel={permission?.write?.includes("cancelAppoinment")}
                  // isTransit={permission?.write?.includes("transitAppoinment")}
                  // isViewApp={permission?.write?.includes("viewAppoinment")}
                  // isViewReceipt={permission?.write?.includes("viewReceipt")}
                  // isViewBill={permission?.write?.includes("viewBill")}
                  // isModifyVisit={permission?.write?.includes("modifyVisit")}
                  // isViewVist={permission?.write?.includes("viewVisit")}
                  // isViewEMR={permission?.write?.includes("viewEMR")}
                  iscancelWaitinglist={permission?.write?.includes("cancelWaitinglist")}
                  handlesidebarCancelWatingListbooking={handlesidebarCancelWatingListbooking}
                  loggedUserInfo={loggedUserInfo}
                  cancel={true}
                  upcoming={true}
                  handleMultiBookData={handleMultiBookData}
                />
              </>
            )
          ),
        });
    }
    return constructCard;
  };

  const openView = (appointmentId) => {
    setState((prevState) => ({
      ...prevState,
      open: !state.open,
      appointmentId: appointmentId,
    }));
  };

  const handlesidebarCancelbooking = (id) => {
    if (id === "closing") {
      const getEpochTime = (date) => Math.floor(date.getTime() / 1000);
      const currentDate = new Date();
      const startDate = new Date(currentDate);
      startDate.setHours(0, 0, 0, 0);
      const startEpochTime = getEpochTime(startDate);
      dispatch(
        actions.ALL_APPOINTMENTS({
          patientid:
            // state?.newPatientDetails?.personid ??
            location?.state?.patientId,
          // location?.state?.userId,
          tenantid: "",
          facilityid: facilityId,
          assemble: true,
          startdate:startEpochTime,
          personid: location?.state?.patientId ? "" :personId
        })
      );
    }
    setState((prevState) => ({
      ...prevState,
      cancelbookings: 1,
      cancelbookingreason: {},
      cancelbooking: !state.cancelbooking,
      //appointmentId: id || state.appointmentId,
      appointmentId: id,
    }));

    console.log("cancelstate", state);
  };
  const handlesidebarCancelWaitingListbooking = (id) => {
    if (id === "closing") {
      const getEpochTime = (date) => Math.floor(date.getTime() / 1000);
      const currentDate = new Date();
      const startDate = new Date(currentDate);
      startDate.setHours(0, 0, 0, 0);
      const startEpochTime = getEpochTime(startDate);
      dispatch(
        actions.ALL_APPOINTMENTS({
          patientid:
            // state?.newPatientDetails?.personid ??
            location?.state?.patientId,
          // location?.state?.userId,
          tenantid: "",
          facilityid: facilityId,
          assemble: true,
          startdate: startEpochTime
        })
      );
    }
    setState((prevState) => ({
      ...prevState,
      cancelWatingListbookings: 1,
      cancelbookingreason: {},
      cancelWatingListbooking: false,
      //appointmentId: id || state.appointmentId,
      appointmentId: id,
    }));

    console.log("cancelstate", state);
  };
  const handlesidebarCancelWatingListbooking = (id) => {
    setState((prevState) => ({
      ...prevState,
      cancelWatingListbookings: 1,
      cancelWatingListbookingreason: {},
      cancelWatingListbooking: true,
      //appointmentId: id || state.appointmentId,
      appointmentId: id,
    }));

    console.log("cancelstate", state);
  };

  const handleVisit = async (data, role) => {
    if (role === "view") {
      navigate(AppRoutes.visitRegistration, {
        state: {
          personId: state?.allPatientDetails?.patientdetails?.personid,
          from: 1,
          patientId: state?.allPatientDetails?._id || "",
          appointmentId: data._id || data,
          Ispatient: true,
          isedit: true, //action?.action == "VISIT REGISTER"? false : true,
          isRevise: false, //action?.action == "REVISE VISIT"? true : false,
          patientData: "", //mobileNo, allPatientDetails.patientdetails.telecom
          stateAction: "VIEW VISIT", //action?.action,
          stateEndPoint: "", //action?.apiendpoint,
          stateBackEndCall: "", //action?.backEndCall,
          stateTicketid: "", // ticketid
          encounterDate: data?.start
        },
      });
    } else if (role === "modify") {
      navigate(AppRoutes.visitRegistration, {
        state: {
          personId: state?.allPatientDetails?.patientdetails?.personid,
          from: 1,
          patientId: state?.allPatientDetails?._id || "",
          appointmentId: data._id || data,
          Ispatient: true,
          isedit: true, //action?.action == "VISIT REGISTER"? false : true,
          isRevise: true, //action?.action == "REVISE VISIT"? true : false,
          patientData: "", //mobileNo, allPatientDetails.patientdetails.telecom
          stateAction: "REVISE VISIT", //action?.action,
          stateEndPoint: "", //action?.apiendpoint,
          stateBackEndCall: "", //action?.backEndCall,
          stateTicketid: "", // ticketid
          encounterDate: data?.start
        },
      });
    } else if (role === "cancel") {
    }
  };

  // const ProfileView = async (id, val) => {
  //   const appointmentData_encounter = await dispatch(
  //     actions.APPOINTMENT_ID_GET_ENCOUNTE_DETAILS({
  //       appointmentId: id,
  //     })
  //   );

  //   console.log("idddddddd", id);
  //   console.log("appointmentData_encounter", appointmentData_encounter);
  //   console.log("vallllllllll", val);
  //   if (appointmentData_encounter.payload.data.length > 0) {
  //     let datass = appointmentData_encounter.payload.data[0];

  //     // console.log("datass", datass);
  //     let encounter_id = datass?._id;
  //     let encounterId = datass?._id;
  //     let patientId = datass?.patient_id?._id;
  //     let appoinmentId = id;
  //     let type = "frontdesk";
  //     let app_id = val._id;
  //     let personId = val.person[0].Id;
  //     let pId = datass?.patient_id?._id;
  //     let isProfileView = false;
  //     let referralId = datass?.referralid;
  //     let mrnId = datass?.patient_id?.patmrn
  //     let isViewEmr = true  
  //    let data= JSON.stringify({
  //     "encounter_id": "Encounter/16294",
  //     "personId": "Person/18854",
  //     "patientId": "Patient/14547",
  //     "encounterId": "Encounter/16294",
  //     "pId": "Patient/14547",
  //     "app_id": "Encounter/16294",
  //     "referralId": "ReferralTrx/11817",
  //     "isReferral": true,
  //     "isNephrologyOrPicQueue": false
  //   }
  //     );
  //     const encodedData = encodeURIComponent(data)

  //     navigate(`${AppRoutes.patientDetail}?data=${encodedData}`, {
  //       state: {
  //         encounter_id,
  //         personId,
  //         patientId,
  //         appoinmentId,
  //         encounterId,
  //         type,
  //         pId,
  //         app_id,
  //         isProfileView,
  //         mobileNo: location?.state?.mobileNumber,
  //         referralId,
  //         mrnId,
  //         isViewEmr
  //       },
  //     });
  //   }
  // };
  const ProfileView = async (id, val) => {
    const appointmentData_encounter = await dispatch(
      actions.APPOINTMENT_ID_GET_ENCOUNTE_DETAILS({
        appointmentId: id,
      })
    );
    if (appointmentData_encounter.payload.data.length > 0) {
      let datass = appointmentData_encounter.payload.data[0];

      // console.log("datass", datass);
      let encounter_id = datass?._id;
      let encounterId = datass?._id;
      let patientId = datass?.patient_id?._id;
      let appoinmentId = id;
      let type = "frontdesk";
      let app_id = val._id;
      let personId = val.person[0].Id;
      let pId = datass?.patient_id?._id;
      let isProfileView = false;
      let referralId = datass?.referralid;
      let mrnId = datass?.patient_id?.patmrn
      let isViewEmr = true

      navigate(AppRoutes.patientDetail, {
        state: {
          encounter_id,
          personId,
          patientId,
          appoinmentId,
          encounterId,
          type,
          pId,
          app_id,
          isProfileView,
          mobileNo: location?.state?.mobileNumber,
          referralId,
          mrnId,
          isViewEmr
        },
      });
    }
  };

  const handleMultiBookData = (data, queueId) => {
    if (data) {
      setMbData(data);
    }
    setMultiBookOpen(!multiBookOpen);
    //  setSelectedQueueid(queueId);
  };
  const pastVisitCradConstruction = () => {
    //console.log("pastVisitCradConstruction",appointments)
    const { permission } = state;
    let constructCard = [];
    if (
      appointments?.data?.pastAppointmentThisWeek?.length === 0 &&
      appointments?.data?.pastAppointmentLastWeek?.length === 0 &&
      appointments?.data?.pastAppointmentThisMonth?.length === 0 &&
      appointments?.data?.pastAppointmentLastMonth?.length === 0
    ) {
      constructCard.push({
        id: 1,
        name: "Empty",
        component: <EmptyCard parent_id={"patient_dashboard"} />,
        // component: <p>Hi</p>
      });
    } else {
      // console.clear()
      if (appointments?.data?.pastAppointmentThisWeek?.length > 0) {
        constructCard.push({
          id: 2,
          name: "This Week",
          component: appointments?.data?.pastAppointmentThisWeek.map(
            (val, i) => (
              <>
                {i === 0 && (
                  <H1
                    id={`${i}THIS_WEEK`}
                    key={"0"}
                    name="THIS WEEK"
                    className="pc_medium"
                    style={{
                      color: "#6F6F6F",
                      fontSize: "14px",
                      margin: 0,
                      textTransform: "uppercase",
                      letterSpacing: 0,
                      lineHeight: 1,
                      padding: "0px 17px",
                      marginBottom: 8,
                    }}
                  ></H1>
                )}
                <ViewCard
                  parent_id={"patient_dashboard" + i}
                  patientDetails={state.allPatientDetails}
                  openView={openView}
                  val={val}
                  key={i}
                  isShowLabel
                  //isDisable
                  isBookNow={permission?.write?.includes("bookAppoinment")}
                  ismodify={permission?.write?.includes("modifyAppoinment")}
                  iscancel={permission?.write?.includes("cancelAppoinment")}
                  isTransit={permission?.write?.includes("transitAppoinment")}
                  isViewApp={permission?.write?.includes("viewAppoinment")}
                  isViewReceipt={permission?.write?.includes("viewReceipt")}
                  isViewBill={permission?.write?.includes("viewBill")}
                  isModifyVisit={permission?.write?.includes("modifyVisit")}
                  isViewVist={permission?.write?.includes("viewVisit")}
                  isViewEMR={permission?.write?.includes("viewEMR")}
                  showBtn
                  past
                  isDiabled={
                    state.personDetails?.isdeceased?.display === "Deceased" ||
                    !state?.personDetails?.active
                  }
                  handlesidebarCancelbooking={handlesidebarCancelbooking}
                  ProfileView={ProfileView}
                  cancel={true}
                  Receiptview={Receiptview}
                  Billview={Billview}
                  handleVisit={handleVisit}
                  showEncDetails={true}
                />
              </>
            )
          ),
        });
      }
      if (appointments?.data?.pastAppointmentLastWeek?.length > 0) {
        constructCard.push({
          id: 3,
          name: "Last Week",
          component: appointments?.data?.pastAppointmentLastWeek.map(
            (val, i) => (
              <>
                {i === 0 && (
                  <H1
                    id={`${i}_LAST_WEEK`}
                    key={"0"}
                    name="LAST WEEK"
                    className="pc_medium"
                    style={{
                      color: "#6F6F6F",
                      fontSize: "14px",
                      margin: 0,
                      textTransform: "uppercase",
                      letterSpacing: 0,
                      lineHeight: 1,
                      padding: "0px 17px",
                      marginBottom: 8,
                    }}
                  ></H1>
                )}
                <ViewCard
                  parent_id={"patient_dashboard" + i}
                  patientDetails={state.allPatientDetails}
                  openView={openView}
                  val={val}
                  key={i}
                  isShowLabel
                  //isDisable
                  isBookNow={permission?.write?.includes("bookAppoinment")}
                  ismodify={permission?.write?.includes("modifyAppoinment")}
                  iscancel={permission?.write?.includes("cancelAppoinment")}
                  isTransit={permission?.write?.includes("transitAppoinment")}
                  isViewApp={permission?.write?.includes("viewAppoinment")}
                  isViewReceipt={permission?.write?.includes("viewReceipt")}
                  isViewBill={permission?.write?.includes("viewBill")}
                  isModifyVisit={permission?.write?.includes("modifyVisit")}
                  isViewVist={permission?.write?.includes("viewVisit")}
                  isViewEMR={permission?.write?.includes("viewEMR")}
                  showBtn
                  past
                  isDiabled={
                    state.personDetails?.isdeceased?.display === "Deceased" ||
                    !state?.personDetails?.active
                  }
                  handlesidebarCancelbooking={handlesidebarCancelbooking}
                  cancel={true}
                  ProfileView={ProfileView}
                  Receiptview={Receiptview}
                  Billview={Billview}
                  handleVisit={handleVisit}
                  showEncDetails={true}
                />
              </>
            )
          ),
        });
      }
      if (appointments?.data?.pastAppointmentThisMonth?.length > 0) {
        constructCard.push({
          id: 4,
          name: "This Month",
          component: appointments?.data?.pastAppointmentThisMonth.map(
            (val, i) => (
              <>
                {i === 0 && (
                  <H1
                    id={`${i}THIS_MONTH`}
                    key={"0"}
                    name="THIS MONTH"
                    className="pc_medium"
                    style={{
                      color: "#6F6F6F",
                      fontSize: "14px",
                      margin: 0,
                      textTransform: "uppercase",
                      letterSpacing: 0,
                      lineHeight: 1,
                      padding: "0px 17px",
                      marginBottom: 8,
                    }}
                  ></H1>
                )}
                <ViewCard
                  parent_id={"patient_dashboard" + i}
                  patientDetails={state.allPatientDetails}
                  openView={openView}
                  val={val}
                  key={i}
                  isShowLabel
                  //isDisable
                  isBookNow={permission?.write?.includes("bookAppoinment")}
                  ismodify={permission?.write?.includes("modifyAppoinment")}
                  iscancel={permission?.write?.includes("cancelAppoinment")}
                  isTransit={permission?.write?.includes("transitAppoinment")}
                  isViewApp={permission?.write?.includes("viewAppoinment")}
                  isViewReceipt={permission?.write?.includes("viewReceipt")}
                  isViewBill={permission?.write?.includes("viewBill")}
                  isModifyVisit={permission?.write?.includes("modifyVisit")}
                  isViewVist={permission?.write?.includes("viewVisit")}
                  isViewEMR={permission?.write?.includes("viewEMR")}
                  showBtn
                  past
                  isDiabled={
                    state.personDetails?.isdeceased?.display === "Deceased" ||
                    !state?.personDetails?.active
                  }
                  handlesidebarCancelbooking={handlesidebarCancelbooking}
                  cancel={true}
                  ProfileView={ProfileView}
                  Receiptview={Receiptview}
                  Billview={Billview}
                  handleVisit={handleVisit}
                  showEncDetails={true}
                />
              </>
            )
          ),
        });
      }
      if (appointments?.data?.pastAppointmentLastMonth?.length > 0) {
        constructCard.push({
          id: 5,
          name: "Last Month",
          component: appointments?.data?.pastAppointmentLastMonth.map(
            (val, i) => (
              <>
                {i === 0 && (
                  <H1
                    id={`${i}LAST_MONTH`}
                    key={"0"}
                    name="LAST MONTH"
                    className="pc_medium"
                    style={{
                      color: "#6F6F6F",
                      fontSize: "14px",
                      margin: 0,
                      textTransform: "uppercase",
                      letterSpacing: 0,
                      lineHeight: 1,
                      padding: "0px 17px",
                      marginBottom: 8,
                    }}
                  ></H1>
                )}
                <ViewCard
                  parent_id={"patient_dashboard" + i}
                  patientDetails={state.allPatientDetails}
                  openView={openView}
                  val={val}
                  key={i}
                  isShowLabel
                  //isDisable
                  isBookNow={permission?.write?.includes("bookAppoinment")}
                  ismodify={permission?.write?.includes("modifyAppoinment")}
                  iscancel={permission?.write?.includes("cancelAppoinment")}
                  isTransit={permission?.write?.includes("transitAppoinment")}
                  isViewApp={permission?.write?.includes("viewAppoinment")}
                  isViewReceipt={permission?.write?.includes("viewReceipt")}
                  isViewBill={permission?.write?.includes("viewBill")}
                  isModifyVisit={permission?.write?.includes("modifyVisit")}
                  isViewVist={permission?.write?.includes("viewVisit")}
                  isViewEMR={permission?.write?.includes("viewEMR")}
                  showBtn
                  past
                  isDiabled={
                    state.personDetails?.isdeceased?.display === "Deceased" ||
                    !state?.personDetails?.active
                  }
                  handlesidebarCancelbooking={handlesidebarCancelbooking}
                  cancel={true}
                  ProfileView={ProfileView}
                  Receiptview={Receiptview}
                  Billview={Billview}
                  handleVisit={handleVisit}
                  showEncDetails={true}
                />
              </>
            )
          ),
        });
      }
    }
    return constructCard;
  };

  const selectPayBills = () => {
    // props.history.push(̥{
    navigate({
      pathname: AppRoutes.collection,
      state: { nextRou̥te: AppRoutes.PatientDashBoard },
    });
  };
   const handleBookyTpe = (_id) => {
    if(userrole ===IsPersonAsRelations?.isPerson){
      handleEdit(true ,_id)
    }
    else if (
      _id === process.env.REACT_APP_FREQUENCY_APPMNT_TYPE_NEPHROLOGIST ||
      _id === process.env.REACT_APP_FREQUENCY_APPMNT_TYPE_PICREVIEW
    )
      navigate(AppRoutes.appointmentBooking, {
        state: {
          skip_hospital_searcch_screen: true,
          apptypecode: _id,
          patientId: state.allPatientDetails?._id,
          userId: state.allPatientDetails?.patientdetails?.personid,
          selectedUser: {
            name:
              state?.newPatientDetails?.name?.[0]?.fulname ||
              state?.patientDetails?.name ||
              state?.patientDetails?.allData?.name?.[0]?.fulname,
            fileid: state?.newPatientDetails?.photo?.[0]?.fileid,
            url: state?.newPatientDetails?.photo?.[0]?.url,
            age: state?.newPatientDetails?.age,
            gender: state?.newPatientDetails?.gender?.display,
            birthdate: state?.newPatientDetails?.birthdate,
            modifyUser: false,
          },

          // patientDetails: state.allPatientDetails,
        },
      });
    else if (_id === process.env.REACT_APP_FREQUENCY_APPMNT_TYPE_DIALYSIS) {
      setMultiBookOpen(true);
    } else if(_id === process.env.REACT_APP_FREQUENCY_APPMNT_TYPE_OP_FLOW){
      navigate(AppRoutes.searchFacility, {
        state: {
                 patientDetails: state.allPatientDetails,
                 apptypecode : _id,
        },
      });
    }else if(_id === process.env.REACT_APP_FREQUENCY_APPMNT_TYPE_OPERATIONS_THEATER_FLOW){
      navigate(AppRoutes.searchFacility, {
        state: {
                 patientDetails: state.allPatientDetails,
                 apptypecode : _id,
        },
      });
    }else if(_id === process.env.REACT_APP_FREQUENCY_APPMNT_TYPE_SPECIALTY_TREATMENT_FLOW){
      navigate(AppRoutes.searchFacility, {
        state: {
                 patientDetails: state.allPatientDetails,
                 apptypecode : _id,
        },
      });
    }else if(_id === process.env.REACT_APP_FREQUENCY_APPMNT_TYPE_PAEDIATRIC_FLOW){
      navigate(AppRoutes.searchFacility, {
        state: {
                 patientDetails: state.allPatientDetails,
                 apptypecode : _id,
        },
      });
    }else if(_id === process.env.REACT_APP_FREQUENCY_APPMNT_TYPE_DIAGNOSTIC_APPOINTMENT){
      navigate(AppRoutes.searchFacility, {
        state: {
                 patientDetails: state.allPatientDetails,
                 apptypecode : _id,
        },
      });
    }
    else {
      btnClick();
    }
  };
  const getAge = (date) => {
    return (
      Math.floor(
        moment(new Date()).diff(moment(date, "DD/MM/YYYY"), "years", true)
      ) + " Yrs"
    );
  };
  const handleBillButton = async () => {
    try {
      let encounter = await dispatch(
        actions.GET_LASTEST_ENCOUNTER(location?.state?.patientId)
      );

      encounter = encounter.payload.data;
      let mrnno = state?.newPatientDetails?.patmrn;
      const getEpochTime = (date) => Math.floor(date.getTime() / 1000);
      const currentDate = new Date();
      const startDate = new Date(currentDate);
      startDate.setDate(currentDate.getDate() - 5);
      startDate.setHours(0, 0, 0, 0);
      const startEpochTime = getEpochTime(startDate);
      const endDate = new Date(currentDate);
      endDate.setHours(23, 59, 59, 999);
      const endEpochTime = getEpochTime(endDate);
      const queryData =
        personRequests.getDetailsAgainstPatientSearchByIsCashier(
          0,
          0,
          "",
          mrnno,
          //app?.field2,
          "",
          "",
          "",
          "",
          "",
          getGlobalValueFrom_LclStrg_And_QryStrg({ localStoragekeyName: "UserInfo", returnObjKeyName: "facilityid" }),
          0,
          10
        );
      var lista = {};
      const SearchUserData = await fetchData(queryData, urls.readQdmQueries);

      console.log("SearchUserData", SearchUserData);
      if (!SearchUserData || SearchUserData.length === 0) {
        console.log("No user data found.");
        props?.alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Billing data not avaliable",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      } else {
        SearchUserData?.forEach((v, i) => {
          Object.assign(lista, {
            idnumber: v?.identifier[0]?.value,
            idsourcecode: v?.identifier[0]?.code,
            address: v?.address,
            age: getCalculatedAge(v?.birthDate),
            gender: v?.gender,
            email:
              v?.telecom?.find((tel) => tel?.system === "CodingMaster/10469")
                ?.value ?? "",
            name: makeName(v?.name?.[0] || {}) || "",
            id: v?.patmrn ?? "",
            dis: `${(v?.gender + ", " ?? "") +
              (v?.birthDate ? getAge(v?.birthDate) : "")
              }
            ${v?.telecom[0]?.value ? " | " + v?.telecom[0]?.value : ""}`,
            encounter: v?.encounter,
            mobileNumber:
              v?.telecom?.find((tel) => tel?.system === "CodingMaster/10473")
                ?.value ||
              v.telecom?.find((tel) => tel?.system === "CodingMaster/10469")
                ?.value ||
              "",
            patientId: v?._id,
            img:
              getImgUrl(v?.photo?.[0]?.fileid) ||
              "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
          });
        });
        navigate(AppRoutes.billingDetails, {
          state: {
            // encounter_id,
            // personId,
            // patientId,
            // encounterId,
            // pId,
            ...lista,
          },
        });
      }
    } catch (error) {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: error.message,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
  };
  const getOutstanding = async () => {
    const Outstanding = await dispatch(
      actions.GET_OUTSTANDING_DATA(location?.state?.patientId)
    );
    setState({
      ...state,
      OutstandingData: Outstanding?.payload?.data?.[0]?.outstandingamount,
      advanceAmount: Outstanding?.payload?.data?.[0]?.advanceamount,
    });
  };
  const OutstandingClick = () => {
    navigate(AppRoutes.paymentCollections, {
      state: {
        patientid: location?.state?.patientId,
        encounterid: "",
        receiptno: "",
        receiptdate: "",
        IsFrom: "Outstanding",
      },
    });
  };
  const GetBillAndReceipt = async (appId) => {
    let data = await dispatch(
      actions.GET_BILL_AND_RECEIPT({
        patientId: location?.state?.patientId,
        appointmentId: appId,
      })
    );
    setBillANdReceiptdata(data?.payload?.data ?? []);
  };
  const Receiptview = async (appId, value) => {
    // it will all on both click
    await GetBillAndReceipt(appId);
    setViewReceipt(true);
  };
  const Billview = async (type,appId) => {
    // it will all on both click
    setIsTransitType(type);
    await GetBillAndReceipt(appId);
    setViewBill(true);
  };

  const handleAdvanceAPayment = () => {

    navigate(AppRoutes.advancePayment, {
      state: {
        patientid: location?.state?.patientId,
        state: state,
      },
    });
  };
  const {
    sidebar,
    activeId,
    cancelbooking,
    cancelbookingreason,
    cancelbookings,
    personDetails,
    permission,
    cancelWatingListbookings,
    cancelWatingListbooking
  } = state;
let getRoleName = splitAfterUnderscore(decodedUserinfo?.userrole);
console.log("getRoleName",decodePermissionData);

  return (
    <Div
      id="patient_dashboard_parent_div"
      style={{ backgroundColor: "#F4F5F8" }}
    >
      <div
        style={{
          display: state.loading ? "flex" : "block",
          justifyContent: "center",
          alignItems: "center",
          height: state.loading ? "calc(100vh - 176px)" : "auto",
        }}
      >
        <Loading loading={state.loading ? true : false}>
          {!state.callcen && (
            <Grid
              id="patient_dashboard_patientCardGrid"
              container
              spacing={2}
              style={{
                padding: "10px 20px",
                backgroundImage: "linear-gradient(to right, #F7ECF4, #DAEAF8)",
              }}
            >
              <Grid item xs={6} id="patient_dashboard_grid_patientCard">
                {getRoleName === "patient" ? null :
                <PatientCard
                  permissionData={state?.permission}
                  nameDetails={makeName(state.personDetails?.name?.[0] || {})}
                  parent_id={"patient_dashboard"}
                  patientDetails={state.patientDetails}
                  newPatientDetails={state.newPatientDetails}
                  mrnVal={state?.personDetails?.alias}
                  notOpen
                  info={state.loggedInState}
                  // age={
                  //   calculateAge(
                  //     new Date(
                  //       moment(state.patientDetails.birthDay, "DD/MM/YYYY")
                  //     )
                  //   ) + " Yrs"
                  // }
                  age={getCalculatedAge(state?.newPatientDetails?.birthdate)
                    // calculateAge(
                    //   new Date(
                    //     moment(
                    //       state?.newPatientDetails?.birthdate,
                    //       "DD/MM/YYYY"
                    //     )
                    //   )
                    // ) + " Yrs"
                  }
                  // isAddBtn
                  memberList={state.choosemember}
                  addMember={toggleSideBar}
                  active={state.personDetails?.status}
                  activeData={
                    state.personDetails?.isdeceased?.display &&
                    state.personDetails.status &&
                    state.personDetails?.isdeceased?.display
                  }
                  noedit={
                    !(state.permission?.write?.indexOf("modifyPatient") > -1)
                  }
                  handleEdit={handleEdit}
                  OutstandingData={state?.OutstandingData}
                  OutstandingClick={OutstandingClick}
                  walletData={state?.advanceAmount}
                />
              }
              </Grid>
              {/* {state?.OutstandingData >= 0 && (
               
              )} */}

              <Grid
                item
                // xs={state?.OutstandingData >= 0 ? 3 : 6}
                xs={6}
                id="patient_dashboard_grid_patientTypeCard"
              >
                {getRoleName === "patient" ? null :
                <PatientCareTypeCard
                  parent_id={"Referral_Registration"}
                  careList={[...(state?.fin_data || [])]}
                  selected={state?.selectOption}
                  padding
                  isDetailed={true}
                // onChange={(data) => changeState('selectOption', data)}
                /> 
               }
              </Grid>
            </Grid>
          )}
          <div
            style={{
              height: "calc(100vh - 176px)", // 176px = 72px + 104px // 72 - topheader , 104 - PatientDashboardNavBar
              overflow: "auto",
            }}
          >
            <PatientDashboardNavBar
              navBarArr={[
                { label: "Schedule & Visits", id: 1 },
                // { label: "Bill Info", id: 2 },
              ]}
              // disabled={
              //   state.patientDetails?.allData?.Patientname?.length !== 0 &&
              //   (state.personDetails?.isdeceased?.display === "Deceased" ||
              //     state?.personDetails?.active) &&
              //   state.callcen
              //   // && state.personDetails.active
              // }
              disabled={
                false
                // state.personDetails?.isdeceased
                //&& !state.callcen
                // && state.personDetails.active
              }
              permissionData={state?.permission}
              btnName={
                state?.permission?.write?.indexOf("patientBannerEditIcon") >
                  -1
                  ? "Book New Appointment / Walk-In"
                  : "Book New Appointment"
              }
              secondBtnName={permission?.write?.includes("newBilling") ? "New Billing" : ""}
              thirdBtnName={permission?.write?.includes("advancePayment") ? "Advance Payment" : ""}
              fourthBtnName={permission?.write?.includes("advancePrint") ? "Advance Print" : ""}
              fifthBtnName={permission?.write?.includes("refundprint") ? "Refund Print" : ""}
              sixthBtnName={permission?.write?.includes("calender") ? "Calender" : ""}
              handleAdvanceAPayment={handleAdvanceAPayment}
              handleBillButton={handleBillButton}
              enablebtn={permission?.write?.indexOf("bookAppoinment") > -1}
              clickNav={setActiveId}
              activeId={activeId}
              btnClick={btnClick}
              parent_id={"patient_dashboard"}
              options={typeofBookButtons?.data ?? []} //FIXME -
              handleBookType={handleBookyTpe} //FIXME -
              patientDetails={state.allPatientDetails}
            />
            {activeId === 1 && !appointments?.loading && (
              <React.Fragment>
                <div
                  style={{
                    height: "calc(100vh - 136px)",
                    // overflow:'hidden',
                    padding: "14px 37px",
                  }}
                >
                  {/* <Row
									id="patient_dashboard_upcoming_row"
									key={"0"}
									alignItems="stretch"
									direction="row"
									justify="flex-start"
									spacing="2"
									inLineStyles={{
										// margin: "14px !important",
										border: "1px solid #F0F0F0",
										borderRadius: "8px",
										// minHeight: "72vh",
										backgroundColor: "#F9F9F9",
										padding: "15px 7px",
										height: "100%",
										overflow: "hidden",
									}}
								> */}
                  <Row
                    id="patient_dashboard_upcoming_row"
                    key={"0"}
                    alignItems="stretch"
                    direction="row"
                    justify="flex-start"
                    spacing="2"
                    inLineStyles={{
                      // margin: "14px !important",
                      // border: "1px solid #F0F0F0",
                      borderRadius: "8px",
                      // minHeight: "72vh",
                      backgroundColor: "#F4F5F8",
                      // padding: "15px 7px",
                      height: "100%",
                      overflow: "auto",
                    }}
                  >
                    {
                      <Col
                        key={"0"}
                        name="qdm-col-12"
                        alignItems="stretch"
                        direction="row"
                        justify="flex-start"
                        textAlign="left"
                        lg={permission?.read?.length === 2 ? "6" : "4"}
                        xs="12"
                        md={permission?.read?.length === 2 ? "6" : "4"}
                        sm={permission?.read?.length === 2 ? "6" : "4"}
                        style={{
                          // borderRight: "1px solid #E0E0E0",
                          padding: 0,
                          display:
                            permission?.read?.indexOf("upcoming") > -1
                              ? "block"
                              : "none",
                          overflow: "auto",
                          height: "100%",
                          backgroundColor: "#fff",
                          maxWidth: "31%",
                          marginRight: "2%",
                        }}
                      >
                        <CardList
                          parent_id={"patient_dashboard"}
                          title="UPCOMING"
                          isCalender={decodePermissionData?.patient_dashboard?.component?.upcoming?.component?.calendar?.permission?.write}
                          middleComponent={
                            <>
                              {upcomingVisitCradConstruction().map((data) => {
                                return data.component;
                              })}
                            </>
                          }
                        />
                      </Col>
                    }
                    {permission?.read?.indexOf("followUpReminder") > -1 && (
                      <Col
                        key={"0"}
                        name="qdm-col-12"
                        alignItems="stretch"
                        direction="row"
                        justify="flex-start"
                        textAlign="left"
                        lg={permission?.read?.length === 2 ? "6" : "4"}
                        xs="12"
                        md={permission?.read?.length === 2 ? "6" : "4"}
                        sm={permission?.read?.length === 2 ? "6" : "4"}
                        style={{
                          // borderRight: "1px solid #E0E0E0",
                          padding: 0,
                          overflow: "auto",
                          height: "100%",
                          backgroundColor: "#fff",
                          maxWidth: "31%",
                          marginRight: "2%",
                        }}
                      >
                        <CardList
                          parent_id={"patient_dashboard"}
                          title="FOLLOW UP REMINDER"
                          middleComponent={
                            appointments?.data?.followUpAppointment?.length >
                              0 ? (
                              appointments?.data?.followUpAppointment.map(
                                (val, i) => (
                                  <ViewCard
                                    parent_id={"patient_dashboard" + i}
                                    patientDetails={state.allPatientDetails}
                                    openView={openView}
                                    showBtn={true}
                                    isFollowUp={true}
                                    val={val}
                                    isDiabled={
                                      state.personDetails?.isdeceased
                                        ?.display === "Deceased" ||
                                      !state?.personDetails?.active
                                    }
                                    isBookNow={permission?.write?.includes("bookAppoinment")}
                                    ismodify={permission?.write?.includes("modifyAppoinment")}
                                    iscancel={permission?.write?.includes("cancelAppoinment")}
                                    isTransit={permission?.write?.includes("transitAppoinment")}
                                    isViewApp={permission?.write?.includes("viewAppoinment")}
                                    isViewReceipt={permission?.write?.includes("viewReceipt")}
                                    isViewBill={permission?.write?.includes("viewBill")}
                                    isModifyVisit={permission?.write?.includes("modifyVisit")}
                                    isViewVist={permission?.write?.includes("viewVisit")}
                                    isViewEMR={permission?.write?.includes("viewEMR")}
                                    key={i}
                                    handlesidebarCancelbooking={
                                      handlesidebarCancelbooking
                                    }
                                    cancel={true}
                                  />
                                )
                              )
                            ) : (
                              <EmptyCard parent_id={"patient_dashboard"} />
                            )
                          }
                        />
                      </Col>
                    )}
                    {permission?.read?.indexOf("visits") > -1 && (
                      <Col
                        key={"0"}
                        name="qdm-col-12"
                        alignItems="stretch"
                        direction="row"
                        justify="flex-start"
                        textAlign="left"
                        lg={permission?.read?.length === 2 ? "6" : "4"}
                        xs="12"
                        md={permission?.read?.length === 2 ? "6" : "4"}
                        sm={permission?.read?.length === 2 ? "6" : "4"}
                        style={{
                          padding: 0,
                          overflow: "auto",
                          height: "100%",
                          backgroundColor: "#fff",
                        }}
                      >
                        {/* {console.log('yess',props?.appointments?.data?.currentVisist)} */}
                        <CardList
                          parent_id={"patient_dashboard"}
                          title="CURRENT VISIT"
                          middleComponent={
                            // state.personDetails?.isdeceased?.display !== "Deceased" &&
                            appointments?.data?.currentVisist?.length > 0 ? (
                              appointments?.data?.currentVisist.map(
                                (val, i) => (
                                  // <ViewCard
                                  //   parent_id={"patient_dashboard" + i}
                                  //   patientDetails={state.allPatientDetails}
                                  //   openView={openView}
                                  //   val={val}
                                  //   key={i}
                                  //   canceled={!val?.status}
                                  //   // isShowLabel
                                  //   showBtn={!val?.status}
                                  //   isDiabled={
                                  //     state.personDetails?.isdeceased
                                  //       ?.display === "Deceased" ||
                                  //     !state?.personDetails?.active
                                  //   }
                                  //   isBookNow={
                                  //     !(
                                  //       permission?.write?.indexOf(
                                  //         "bookAppoinment"
                                  //       ) > -1
                                  //     )
                                  //   }
                                  //   ismodify={
                                  //     !(
                                  //       permission?.write?.indexOf(
                                  //         "modifyAppoinment"
                                  //       ) > -1
                                  //     )
                                  //   }
                                  //   iscancel={
                                  //     !(
                                  //       permission?.write?.indexOf(
                                  //         "cancelAppoinment"
                                  //       ) > -1
                                  //     )
                                  //   }
                                  //   cancel={false}
                                  //   // past
                                  //   visitCard={true}
                                  //   handlesidebarCancelbooking={handleVisit}
                                  // />

                                  <ViewCard
                                    currentVisit={true}
                                    parent_id={"patient_dashboard" + i}
                                    patientDetails={state.allPatientDetails}
                                    openView={openView}
                                    val={val}
                                    key={i}
                                    isShowLabel
                                    isBookNow={permission?.write?.includes("bookAppoinment")}
                                    ismodify={permission?.write?.includes("modifyAppoinment")}
                                    iscancel={permission?.write?.includes("cancelAppoinment")}
                                    isTransit={permission?.write?.includes("transitAppoinment")}
                                    isViewApp={permission?.write?.includes("viewAppoinment")}
                                    isViewReceipt={permission?.write?.includes("viewReceipt")}
                                    isViewBill={permission?.write?.includes("viewBill")}
                                    isModifyVisit={permission?.write?.includes("modifyVisit")}
                                    isViewVist={permission?.write?.includes("viewVisit")}
                                    isViewEMR={permission?.write?.includes("viewEMR")}
                                    showBtn
                                    //past
                                    isDiabled={
                                      state.personDetails?.isdeceased
                                        ?.display === "Deceased" ||
                                      !state?.personDetails?.active
                                    }
                                    handlesidebarCancelbooking={
                                      handlesidebarCancelbooking
                                    }
                                    ProfileView={ProfileView}
                                    cancel={true}
                                    Receiptview={Receiptview}
                                    Billview={Billview}
                                    handleVisit={handleVisit}
                                    showEncDetails={true}
                                    avcUrl={isPatient ? val?.avc?.guestUrl: val?.avc?.moderatorUrl}
                                  />
                                )
                              )
                            ) : (
                              <EmptyCard parent_id={"patient_dashboard"} />
                              // <p>Hi</p>
                            )
                          }
                        />
                        <br />
                        <CardList
                          parent_id={"patient_dashboard"}
                          title="PAST"
                          middleComponent={
                            <>
                              {pastVisitCradConstruction().map((data) => {
                                return data.component;
                              })}
                            </>
                          }
                        />
                      </Col>
                    )}
                  </Row>
                  {/* </Row> */}
                </div>
              </React.Fragment>
            )}
            {activeId === 2 && (
              <React.Fragment>
                <Div
                  id="patient_dashboard_Pay_Selected_Bills_div"
                  // spacing="2"
                  inLineStyles={{
                    margin: "14px !important",
                    border: "1px solid #F0F0F0",
                    borderRadius: "8px",
                    minHeight: "72vh",
                    backgroundColor: "#F9F9F9",
                    padding: 20,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <BillNav
                    parent_id={"patient_dashboard"}
                    activeid={1}
                    btnList={[
                      { id: 1, label: "Outstanding" },
                      { id: 2, label: "Paid" },
                    ]}
                  />
                  <Div id="patient_dashboard_tablelist_div" style={{ flex: 1 }}>
                    <Paper
                      id="patient_dashboard_tablelist_paper"
                      style={{
                        margin: 0,
                        padding: "20px 0px",
                        boxShadow: "0px 10px 25px #0000000A",
                        borderRadius: 8,
                        border: "1px solid #F0F0F0",
                      }}
                    >
                      <TableList
                        parent_id={"patient_dashboard"}
                        tableHeader={tableHeader}
                        tableData={[]}
                        isSerialNo={true}
                        isCheckBox={true}
                        tableDataList={[]}
                        loading={false}
                        isempty={false}
                      />
                    </Paper>
                  </Div>
                  <Div
                    id="patient_dashboard_Pay_Selected_Bills_div"
                    style={{ textAlign: "center" }}
                  >
                    <Button
                      id="patient_dashboard_Pay_Selected_Bills_buttom"
                      style={{
                        backgroundColor: "#0071F2",
                        borderColor: "#0071F2",
                        borderRadius: 8,
                        padding: "8px 15px",
                        minWidth: 100,
                        fontWeight: "600",
                        fontSize: 12,
                      }}
                      onClick={() => selectPayBills()}
                    >
                      Pay Selected Bills
                    </Button>
                  </Div>
                </Div>
              </React.Fragment>
            )}
          </div>
          <Modal
            parent_id={"patient_dashboard"}
            hiddencloseIcon={true}
            open={state.open}
            onClose={openView}
            childrens={
              <ConfirmationPage
                parent_id={"patient_dashboard"}
                appointmentId={state.appointmentId}
                appointmentDetails={true}
                hidden={true}
              />
              // <ConfirmBook
              //     appointmentId={state.appointmentId}
              //     appointmentDetails={true}
              //     hidden={true}
              // />
            }
            width={530}
            backgroundColorNone
          />
          <SideMenu
            id="patient_dashboard_cancel_sidemenu"
            open={cancelbooking}
            direction="right"
            width={440}
            color="default"
            onClose={() => handlesidebarCancelbooking("closing")}
            style={{ padding: 0, zIndex: 999 }}
          >
            <CancelBookingPage
              parent_id={"patient_dashboard"}
              cancelbookingreason={cancelbookingreason}
              cancelbookings={cancelbookings}
              appointmentId={state.appointmentId}
              handleSelectCancelbooking={handleSelectCancelbooking}
              handlecahngeCancelbooking={handlecahngeCancelbooking}
              getAllAppointment={getAllAppointment}
              setState={setState}
              state={state}
            />
          </SideMenu>
          <SideMenu
            id="patient_dashboard_cancel_sidemenu"
            open={cancelWatingListbooking}
            direction="right"
            width={440}
            color="default"
            onClose={() => handlesidebarCancelWaitingListbooking("closing")}
            style={{ padding: 0, zIndex: 999 }}
          >
            <CancelWatingListBookingPage
              parent_id={"patient_dashboard"}
              cancelbookingreason={cancelbookingreason}
              cancelbookings={cancelWatingListbookings}
              appointmentId={state.appointmentId}
              handleSelectCancelbooking={handleSelectCancelbooking}
              handlesidebarCancelWaitingListbooking={handlesidebarCancelWaitingListbooking}
              getAllAppointment={getAllAppointment}
              setState={setState}
              state={state}
            />
          </SideMenu>
          <SideMenu
              id="patient_dashboard_sidemenu"
              open={sidebar}
              direction="right"
              width={445}
              color="default"
              onClose={() => toggleSideBar()}
            >
              {state.searchMenber ? (
                <AddMember
                  parent_id={"patient_dashboard"}
                  name={"Add Member"}
                  // nextSection={(member, v, l) => nextSection(member, v, l)}
                  handelChangeSelect={(v) => handelChangeSelect(v)}
                  signUpData={state.signUpData}
                  chooseSearch={state.chooseSearch}
                  addMember
                  memberIds={state.memberIds}
                />
              ) : state.memberForm ? (
                <Addmemberform
                  parent_id={"patient_dashboard"}
                  addNewMember
                  // changeSideBar={changeSideBar}
                  signUpData={state.signUpData}
                  // nextSection={(member, signUpInfo) =>
                  //   nextSection(member, signUpInfo)
                  // }
                />
              ) : state.memberOtp ? (
                <Addmemberotp
                  parent_id={"patient_dashboard"}
                  addmmber={state.addmmber}
                  // nextSection={(member, v) => nextSection(member, v)}
                  signUpData={state.signUpData}
                  appointmentDetails={true}
                />
              ) : null}
              {state.memberSuccessPage ? (
                <AppointmentAddMemberSuccessPage
                  parent_id={"patient_dashboard"}
                  addmmber={state.addmmber}
                  memeberIds={state.memberIds}
                  signUpData={state.signUpData}
                  // nextSection={(member, v, l) => nextSection(member, v, l)}
                  toggleSideBar={(member) => toggleSideBar(member)}
                />
              ) : null}
            </SideMenu>
        </Loading>
      </div>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={viewBill}
        onClose={() => setViewBill(false)}
        disableBackdropClick
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0px 24px",
            }}
          >
            <p>View/Cancel Bill</p>
            <span>
              <IconButton onClick={() => setViewBill(false)}>
                <ClearOutlinedIcon />
              </IconButton>
            </span>
          </div>
        </DialogTitle>
        <DialogContent>
          <Viewbill
            billData={billAndreceiptData?.bill}
            GetBillAndReceipt={GetBillAndReceipt}
            getOutstanding={getOutstanding}
            permissionData={state?.cancelBillPermission}
            isTransitType={isTransitType}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={viewReceipt}
        onClose={() => setViewReceipt(false)}
        disableBackdropClick
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0px 44px",
            }}
          >
            <p>View/Cancel Receipt</p>
            <IconButton onClick={() => setViewReceipt(false)}>
              <ClearOutlinedIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <ViewReceipt
            receiptData={billAndreceiptData?.receipt}
            GetBillAndReceipt={GetBillAndReceipt}
            getOutstanding={getOutstanding}
            setViewReceipt={setViewReceipt}
            cancelReceipt={state?.cancelReceipt}
          />
        </DialogContent>
      </Dialog>
      <MultiBookComp
        multiBookOpen={multiBookOpen}
        setMultiBookOpen={handleMultiBookData}
        callback={getAllAppointment}
        setMultiBookOpens ={setMultiBookOpen}
        mBData={{
          inputdoc: {
            facilityid: facilityId,
            appointmentreason: "",
            AppointmentNotification: [
              {
                practitionerEmail: "",
                mobile: state.patientDetails.mobileno,
                patientEmail: "",
              },
            ],
            specialtyid: process.env.REACT_APP_NEPHROLOGY_SPECIALITY,
            consultationmode:
              process.env.REACT_APP_CODING_APMNT_DIRECT_CONSULT_MODE,
            personid: personId,
            patientId: state?.allPatientDetails?._id,
          },
          isTransit: mBData?.isTransit,
          appid: mBData?._id,
        }}
        selectedQueueid={""}
      />
    </Div>
  );
};

export const PatientDashBoardWithoutAppBar = PatientDashBoard
export default withAllContexts(withAppBar(PatientDashBoard));