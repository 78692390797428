import React from "react";

export const HeightsIcon = (props) => {
  let width = props.width ? props.width : "13";
  let height = props.height ? props.height : "15";
  //   let color = props.color ? props.color : "#000000";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 22 22"
    >
      <path
        id="icons8-height"
        d="M18.4,4.01a3.017,3.017,0,0,0-2.14.881L4.891,16.258a3.039,3.039,0,0,0,0,4.28l4.591,4.591a3.038,3.038,0,0,0,4.28,0L25.129,13.762a3.039,3.039,0,0,0,0-4.28L20.537,4.891A3.017,3.017,0,0,0,18.4,4.01Zm0,1.64a1.371,1.371,0,0,1,.973.408l4.591,4.591a1.361,1.361,0,0,1,0,1.944L12.595,23.961a1.363,1.363,0,0,1-1.945,0L6.058,19.37a1.363,1.363,0,0,1,0-1.945l.973-.973L8.1,17.519a.825.825,0,1,0,1.167-1.167L8.2,15.285,9.782,13.7l2.168,2.168A.825.825,0,1,0,13.117,14.7l-2.168-2.168,1.584-1.584L13.6,12.017a.825.825,0,1,0,1.167-1.167L13.7,9.782,15.285,8.2l2.168,2.168A.825.825,0,1,0,18.62,9.2L16.452,7.031l.973-.973A1.371,1.371,0,0,1,18.4,5.65Z"
        transform="translate(-4.01 -4.01)"
        fill="#229f4c"
      />
    </svg>
  );
};