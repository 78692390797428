import React from 'react';
import { FormGroup, FormControlLabel, FormControl, FormLabel, Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';



function CheckboxGrp({ types = [], name = "", value = [], valuefn = () => null, disabled = false }) {
  const [checkedValues, setCheckedValues] = React.useState(value);

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    let updatedValues;

    if (event.target.checked) {
      updatedValues = [...checkedValues, selectedValue];
    } else {
      updatedValues = checkedValues.filter((val) => val !== selectedValue);
    }

    setCheckedValues(updatedValues);

    // Map selected values back to objects from `types`
    const selectedObjects = updatedValues.map((val) =>
      types.find((item) => item.value === val)
    );
    valuefn(selectedObjects);
  };

  React.useEffect(() => {
    // Update the local state when `value` prop changes
    setCheckedValues(value.map((v) => v.value));
  }, [value]);

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{name}</FormLabel>
      <FormGroup>
        {types.map((v) => (
          <FormControlLabel
            disabled={disabled}
            key={v.value}
            control={
              <Checkbox
                checked={checkedValues.includes(v.value)}
                onChange={handleChange}
                value={v.value}
                color="primary"
              />
            }
            label={v.label}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}

export default CheckboxGrp;
