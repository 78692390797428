import React from "react";
import Logo from "../../../assets/img/Logo_W.svg";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Button, Toolbar } from "@material-ui/core";
import { AppRoutes } from "../../../router/routes";
import { useNavigate } from "react-router-dom";
// import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	title: {
		flexGrow: 1,
	},
	startTourButton: {
		fontSize: "14px",
		textTransform: "none",
		borderRadius: "8px",
		backgroundColor: "#fff",
		fontFamily: "pc_medium !important",
		color: "#0071F2",
		padding: "8px 20px",
		border: "none",
		"&:hover": {
			backgroundColor: "#fff",
		},
	},
}));

function TopNavBarAvc(props) {
	const navigate = useNavigate();
	const { support } = props;
	const classes = useStyles();

	const handleSupport = () => {
		if (support) {
			navigate(AppRoutes.avcSupport);
		} else {
			navigate(AppRoutes.avcLanding);
		}
	};

	return (
		<div style={{ position: "sticky", top: 0, zIndex: 1 }}>
			<AppBar
				position="static"
				style={{ backgroundColor: "#0071F2", boxShadow: "none" }}
			>
				<Toolbar>
					<img
						key={"0"}
						variant="rounded"
						letter="Logo"
						src={Logo}
						alt="Logo"
					/>
					<div className={classes.title}></div>
					<Button className={classes.startTourButton} onClick={handleSupport}>
						{support ? "Support" : "Back to Home page"}
					</Button>
				</Toolbar>
			</AppBar>
		</div>
	);
}

export default (TopNavBarAvc);
