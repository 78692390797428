import React, { useRef } from "react";
import { connect } from "react-redux";
import qdmstyles from "./styles1.json";
import { useLocation, withRouter } from "react-router-dom";
import { AlertContext } from "../../../context";
import {
  Container,
  Div,
  H6,
  Divider,
  Upload,
  TextInput,
  TapSelect,
  Text,
  // DateTimepicker,
  MobileNumberWithCode,
  Button,
  TermsAndConditions,
  MonthCalendar,
  Popover,
  Icons,
  Avatar,
  DateTimepicker,
  Row,
  Col,
  Select,
} from "qdm-component-library";
import {
  checkError,
  AlertProps,
  ValidateEmail,
  getMobileNumber,
  getUserInfo,
  upsertPerson,
  mobileNumberValidation,
  getGlobalValueFrom_LclStrg_And_QryStrg,
} from "../../../utils";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import { UIColor } from "../../../themes/theme";
import calandarIcon from "../../../assets/img/svg/calendar.svg";
import { useDispatch } from "react-redux";
import actions from "../../../redux/actions";
import trashIcon from "../../../assets/img/svg/icons8-trash.svg";
import RegUploadIcon from "../../../assets/img/png/RegUploadIcon.png";
import { DobAge } from "../../dobAge";


function AppointmentDrawerSigningPage(props) {
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    gender: "",
    genderId :"",
    dob: {
      val: moment().format("DD-MM-YYYY"),
      milli: moment().valueOf(),
    },
    relation: {},
    emailId: "",
    mobileNo: {},
    opencalendar: false,
    loadingReadPerson: false,
    idType:{},
  });
  const [allMasters, setAllMasters] = useState([]);
  const location = useLocation();
  const myRef = useRef(null);
  const decodedUserinfo = getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",isFullObject:true})
  const patientPersonId = decodedUserinfo?.personid || "";
  const dispatch = useDispatch();
  const changeState = (key, value) => {
    let id_number = key === "idNumber" &&
      state?.idType?._id === process.env.REACT_APP_PATIENT_ID_TYPE_NRIC;
    if (id_number) {
      let formattedValue = value.replace(/\D/g, "");
      if (formattedValue.length > 12) {
        formattedValue = formattedValue.slice(0, 12);
      }
      formattedValue = formattedValue.replace(/(\d{4})(?=\d)/g, "$1-");
      value = formattedValue;
    }
    if (key === "gender") {
      setState({
        ...state,
        [key]: value?.value,
        genderId: value?._id
      });
    }else if (key === "relation") {
      setState({
        ...state,
        [key]: value?.value,
        relationId: value?._id
      });
    } else {
      setState({
        ...state,
        [key]: value,
      });
    }
  };

  useEffect(() => {
    (async function () {
      await props.GET_COUNTRY_CODE_LIST();
      if (props.signUpData) {
        const data = props.signUpData;
        await setState({
          mobileNo: props.signUpData.mobileNo,
          firstName: data?.firstName,
          lastName: data?.lastName,
          middleName: data?.middleName,
          gender: data?.gender,
          dob: {
            val: props.signUpData.dob,
            milli: moment().valueOf(),
          },
          emailId: data?.emailId,
        });
      }
    })();
    getAllMasters();
  }, []);
  const getMobileNumberWithSpace = (input) => {
    const countryCode = input?.countryData?.callingCodes ?? "00";
    const mobileNo = input.mobileNo;
    return `+${countryCode} ${mobileNo}`;
  };
  const handleSignUp = async () => {
    if (
      // state?.mobileNo?.mobileNo?.length > 9 &&
      // state?.mobileNo?.countryData?.callingCodes &&
      state?.firstName &&
      state?.gender &&
      // state?.lastName &&
      (state.emailId ? ValidateEmail(state?.emailId) : true)
    ) {
      let res__ = mobileNumberValidation(
        state?.mobileNo?.mobileNo,
        state?.mobileNo?.countryData?.callingCodes
      );
      //  ;
      if (res__ === "Invalid Country code") {
        // props.alert.setSnack({
        // 	open: true,
        // 	severity: AlertProps.severity.error,
        // 	msg: res__,
        // 	vertical: AlertProps.vertical.top,
        // 	horizontal: AlertProps.horizontal.right,
        // });
        return;
      } else if (res__ === false) {
        // props.alert.setSnack({
        // 	open: true,
        // 	severity: AlertProps.severity.error,
        // 	msg: "Please enter valid mobile number",
        // 	vertical: AlertProps.vertical.top,
        // 	horizontal: AlertProps.horizontal.right,
        // });
        return;
      }

      const {
        firstName,
        lastName,
        middleName,
        dob,
        gender,
        emailId,
        mobileNo,
        genderId,
        relationId,
      } = state;
      if (!props.addNewMember) {
        await props.REGISTER_USERS({
          mobileNo: `+${state.mobileNo?.countryData?.callingCodes ?? "00"}${
            state.mobileNo.mobileNo
          }`,
          firstName,
          lastName,
          emailId,
        });
        const status = checkError(props.registerUsers);
        if (status.isError) {
          setState({
            error: status.errorMessage,
          });
          // context.setSnack({
          //   open: true,
          //   severity: AlertProps.severity.error,
          //   msg: status.errMsg,
          //   vertical: AlertProps.vertical.top,
          //   horizontal: AlertProps.horizontal.center,
          //   tone: true
          // })
        } else {
          const signUpData = {
            mobileNo,
            firstName,
            lastName,
            middleName,
            dob: dob?.val,
            gender,
            emailId,
          };
          props.nextSection("addmemberotp", signUpData);
        }
      } else {
        const guestBookerAdd = {
          phone: getMobileNumber(state.mobileNo),
          dob: dob.val,
          firstName,
          lastName,
          gender,
          email: state?.emailId,
        };
        function formatDate(inputDate) {
          const parts = inputDate?.split("-");
          if (parts.length === 3) {
            const year = parts[2];
            const month = parts[1];
            const day = parts[0]; 
            const formattedDate = `${day}/${month}/${year}`;
            return formattedDate;
          } else {
            // Handle invalid input date format
            return "Invalid Date Format";
          }
        }
        const getEpochTime = (date) => Math.floor(date.getTime() / 1000);
        const currentDate = new Date();
        const startDate = new Date(currentDate);
        let payload = {
          db_name: process.env.REACT_APP_DB,
          is_metadata: true,
          doc: {
            _id: "",
            identifier: [
              {
                  "use":state?.idType?._id,
                  "code": "",
                  "start": getEpochTime(startDate),
                  "value": state?.idNumber,
                  "end": null,
                  "attachments": [
                      {
                          "_id": "",
                          "fileName": "",
                          "fileid": "",
                          "filetype": "",
                          "base64string": "",
                          "url": ""
                      }
                  ]
              }
          ],
            name: [
              {
                use: "CodingMaster/10490",
                title: "",
                lang: "en",
                firstname: firstName,
                mldname:middleName,
                lstname: lastName,
                fourthname: "",
                fulname: firstName +" " + middleName + " "+ lastName,
                prefix: "",
                start: 0,
                end: 0,
              },
            ],
            telecom: [
              {
                  system: "CodingMaster/10473",
                  value: getMobileNumberWithSpace(state.mobileNo),
                  use: "CodingMaster/10477",
                  rank: "1"
              },
              ...(state?.emailId ? [{
                system: "CodingMaster/10469",
                value: state.emailId,
                use: "",
                rank: null
              }] : [])
          ],
            gender: genderId,
            birthdate: formatDate(dob?.val),
            address: [],
            photo: [
              {
                fileName: state?.image?.file?.name||"",
                filetype: state?.image?.file?.type||"",
                base64string:
                  state?.image?.file?._id == null
                    ? state?.image?.url?.toString().split(",")[1]
                    : "",
                url: state?.image?.url||"",
              },
            ],
            // active: true,
            personid: patientPersonId,//pariet person id
            relationship: state?.relationId,
            facilityid: "",
            tenantid: "1234",
            attachments: [],
            existingpersonid: "",
          },
        };
        let error = "";
        let saveAddMember = await dispatch(actions.SAVE_ADDMEMBER(payload));
        if(saveAddMember){
        props?.handleAddMemberList();
        }
        // const { data: res, error } = await upsertPerson(
        //   props.READ_PERSON,
        //   props.UPSERT_PERSON,
        //   guestBookerAdd
        // );
        if (error) {
          // context.setSnack({
          //   open: true,
          //   severity: AlertProps.severity.error,
          //   msg: res,
          //   vertical: AlertProps.vertical.top,
          //   horizontal: AlertProps.horizontal.center,
          //   tone: true,
          // });
        } else {
          if (props.changeSideBar) {
            props.changeSideBar();
          }
          // const signUpData = {
          //   mobileNo,
          //   firstName,
          //   lastName,
          //   middleName,
          //   dob: dob.val,
          //   gender,
          //   emailId
          // }
          // props.nextSection('addmemberotp', signUpData)
          // alert('User Added')
        }
      }
    } else {
      setState({
        ...state,
        error: {
          mobileNo: state?.mobileNo?.mobileNo?.length > 9 ? false : true,
          callingCodes: state?.mobileNo?.countryData?.callingCodes
            ? false
            : true,
          firstName: state?.firstName?.length > 0 ? false : true,
          gender: state?.gender ? false : true,
          lastName: false,
          emailId: state?.emailId
            ? ValidateEmail(state?.emailId)
              ? false
              : true
            : false,
        },
      });
      // context.setSnack({
      //   open: true,
      //   severity: AlertProps.severity.error,
      //   msg: 'Please fill all the mandatory fields',
      //   vertical: AlertProps.vertical.top,
      //   horizontal: AlertProps.horizontal.center,
      //   tone: true
      // })
    }
  };
  const handlepapover = (e) => {
    if (state?.opencalendar) {
      handlepapoverclose();
    } else {
      setState({ opencalendar: e?.currentTarget });
    }
  };
  const handlepapoverclose = () => {
    setState({ ...state, opencalendar: null });
  };
  const getAllMasters = async () => {
    const response = await dispatch(actions.GET_ALL_MASTERS(""));
    setAllMasters(response?.payload?.data);
  };
  const { addNewMember, parent_id } = props;
  const FlterOptions = (type) => {
    let filteredArray = allMasters?.filter((obj) => obj.hasOwnProperty([type]));
    let list = [];
    let data = filteredArray[0]?.[type].Value;
    for (let index in data) {
      var datatemp = {
        value: data[index]?.display,
        _id: data[index]?._id,
      };
      list.push(datatemp);
    }

    return list;
    };
    const FlteredOptions = (type) => {
      let filteredArray = allMasters?.filter((obj) => obj.hasOwnProperty([type]));
      let list = [];
      let data = filteredArray[0]?.[type].Value;
      for (let index in data) {
        var datatemp = {
          value: data[index]?.display,
          label: data[index]?.display,
          _id: data[index]?._id,
        };
        list.push(datatemp);
      }
  
      return list;
      };
  return (
    <div id={`${parent_id}_addmember_form_parent_div`}>
      <Container
        id={`${parent_id}_addmember_form_parent_container`}
        key={"0"}
        name={"container"}
        fluid={true}
        style={qdmstyles.Bc}
      >
        <Div
          id={`${parent_id}_addmember_form_title_div`}
          key={"0"}
          className={"qdm"}
          style={qdmstyles.de_da__c_cd}
        >
          <Div id={`${parent_id}_addmember_form_signup_div`} key={"0"}>
            <H6
              id={`${parent_id}_addmember_form_signup_h6`}
              key={"0"}
              name={addNewMember ? "Add Member" : "Sign Up as New Member"}
              style={qdmstyles.a_c_c_aed_fbeabbf}
            ></H6>
          </Div>

          <Div id={`${parent_id}_addmember_form_back_div`} key={"1"}>
            <Button
              id={`${parent_id}_addmember_form_back_buttom`}
              key={"0"}
              name={"Back"}
              style={qdmstyles.b__ae_ba_fcfdfc}
              onClick={() => props.nextSection("back")}
            ></Button>
          </Div>
        </Div>

        <Divider
          id={`${parent_id}_addmember_form_bottom_divider`}
          key={"1"}
        ></Divider>

        <Div
          id={`${parent_id}_addmember_form_upload_div`}
          style={{
            background: "#E0E0E0",
            width: "200px",
            height: "180px",
            // borderRadius: "50%",
            position: "relative",
            overflow: "hidden",
          }}
        >
             <Div
              id={`${parent_id}_patient_name_upload_avatar_div`}
              style={{ position: "relative" }}
            >
              <Avatar
                id={`${parent_id}_patient_name_upload_avatar`}
                variant="square"
                src={state?.image?.url}
                className={"pn-image"}
              />
              {/* adding delete icon */}
              <Div
                id={`${parent_id}_delete_image_icon`}
                style={{
                  position: "absolute",
                  right: "5px",
                  bottom: "35px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  changeState("image",null);
                }}
              >
                <img src={trashIcon} alt="Delete" />
              </Div>
              {true && (
                <>
                  <div
                    ref={myRef}
                    id={`${parent_id}_edit_patient_name_upload_position`}
                    style={{
                      position: "absolute",
                      top: "19px",
                      right: "4%",
                      zIndex: 100,
                      padding: "10px 0px",
                      backgroundColor: "#0000006e",
                      borderRadius: "50%",
                      height: 26,
                    }}
                  >
                    <Upload
                      id={`${parent_id}_edit_patient_name_upload`}
                      handleChange={(e, value) => {
                        changeState("image", {
                          url: e,
                          file: value,
                        });
                      }}
                      label=""
                      accept="image/*"
                      labelStyle={{
                        fontSize: 14,
                        fontFamily: "pc_medium !important",
                      }}
                      className={"pn-upload-actions-btn"}
                      // list={}
                      showIcon={false}
                    />
                  </div>
                  <Div
                    id={`${parent_id}_image_camera_image`}
                    style={{
                      cursor: "pointer",
                      width: "20px",
                      position: "absolute",
                      top: "18px",
                      right: "8%",
                      textAlign: "center",
                      zIndex: 9999999,
                    }}
                  >
                    <img
                      src={RegUploadIcon}
                      style={{ width: 22, height: 22, zIndex: 99 }}
                      alt="camera"
                      onClick={() => {
                        if (!props.isViewMode) {
                        const current = myRef?.current;
                        if (current) {
                          const btn =
                            current?.querySelectorAll?.(
                              "input[type='file']"
                            )?.[0];
                          if (btn) {
                            btn.click();
                          }
                        }
                      }
                      }}
                    />
                  </Div>
                </>
              )}
            </Div>
        </Div>

        <Div
          id={`${parent_id}_addmember_form_names_div`}
          key={"3"}
          className={"qdm"}
          style={qdmstyles.aa_cbc__a_eddc}
        >
          <TextInput
            id={`${parent_id}_addmember_form_first_label_textinput`}
            key={"0"}
            type={"text"}
            name={"text"}
            variant={"outlined"}
            helperTextType={"error"}
            maxLength={20}
            label={
              <div
                id={`${parent_id}_addmember_form_first_label_div`}
                style={{ fontSize: "13px", marginBottom: "-5px" }}
              >
                First name{" "}
                <span
                  id={`${parent_id}_addmember_form_first_star_span`}
                  style={{ color: "red" }}
                >
                  *
                </span>
              </div>
            }
            autoFocus={false}
            autoComplete={"off"}
            search={false}
            borderColor={"#9b9b9b"}
            elevation={"0"}
            placeholder={"First Name"}
            size={"medium"}
            value={state?.firstName}
            onChange={(e) => changeState("firstName", e.target.value)}
            style={qdmstyles.vQU}
            error={state?.error?.firstName}
          ></TextInput>

          <TextInput
            id={`${parent_id}_addmember_form_middle_label_textinput`}
            key={"1"}
            type={"text"}
            name={"text"}
            variant={"outlined"}
            maxLength={20}
            helperTextType={"error"}
            label={
              <div
                id={`${parent_id}_addmember_form_middle_label_div`}
                style={{ fontSize: "13px", marginBottom: "-5px" }}
              >
                Middle name
              </div>
            }
            autoFocus={false}
            autoComplete={"off"}
            search={false}
            borderColor={"#9b9b9b"}
            elevation={"0"}
            placeholder={"Middle Name"}
            size={"medium"}
            value={state?.middleName}
            onChange={(e) => changeState("middleName", e.target.value)}
            style={qdmstyles.BAIIv}
          ></TextInput>

          <TextInput
            id={`${parent_id}_addmember_form_last_label_textinput`}
            key={"2"}
            type={"text"}
            name={"text"}
            maxLength={20}
            variant={"outlined"}
            helperTextType={"error"}
            label={
              <div
                id={`${parent_id}_addmember_form_last_label_div`}
                style={{ fontSize: "13px", marginBottom: "-5px" }}
              >
                Last name
                {/* <span style={{ color: 'red' }}>*</span> */}
              </div>
            }
            autoFocus={false}
            autoComplete={"off"}
            search={false}
            borderColor={"#9b9b9b"}
            elevation={"0"}
            placeholder={"Last Name"}
            size={"medium"}
            value={state?.lastName}
            onChange={(e) => changeState("lastName", e.target.value)}
            style={qdmstyles.pyYgb}
            error={state?.error?.lastName}
          ></TextInput>
        </Div>
         <Row id={`${parent_id}_patientidentification_map_row`}>
              <Col
                id={`${parent_id}_patientidentification_ip_type_col`}
                md={5}
                lg={5}
                sm={5}
                xs={5}
                className="no-padding-left"
                inLineStyles={{ padding: 10 }}
              >
                <Select
                  id={`${parent_id}_patientidentification_id_type_select`}
                  value={state?.idType?.value}
                  onChangeValue={(e) => changeState("idType", e)}
                  isRequired={true}
                  options={FlterOptions("ALTERNATEIDTYPE")}
                  label="ID Type"
                  placeholder="select..."
                  showArrow
                  labelStyle={{
                    color: "#6F6F6F",
                    fontSize: "12px",
                    marginBottom: "6px",
                    fontFamily: `pc_regular !important`,
                  }}
                  inLineStyles={
                    {
                      borderRadius: 8,
                      borderColor: "#E0E0E0",
                    }
                  }
                  hoverColor={UIColor.primaryColor.color}
                />
              </Col>
              <Col
                id={`${parent_id}_patientidentification_id_number_col`}
                md={7}
                lg={7}
                sm={7}
                xs={7}
                inLineStyles={{ padding: 10 }}
              >
                <TextInput
                  id={`${parent_id}_patientidentification_id_number_textinput`}
                  // helperText={(val?.error && val?.error['id_number']) ? 'Is required' : ''}
                  // error={val?.error && val?.error["id_number"] ? true : false}
                  value={state?.idNumber ? state?.idNumber : ""}
                  onChange={(e) => {
                    let pattern = /^[0-9]+$/i;
                    let result = pattern.test(e.target.value);
                    if (
                      state?.idType?._id ===
                      process.env.REACT_APP_PATIENT_ID_TYPE_NRIC
                    ) {
                      changeState("idNumber", e.target.value,);
                    } else {
                      if (result) {
                        changeState(
                          "idNumber",
                          e.target.value,
                        );
                      }
                    }
                  }}
                  label="ID Number"
                  isRequired={true}
                  placeholder={"Id number"}
                  labelStyle={{
                    color: "#6F6F6F",
                    fontSize: "12px",
                    marginBottom: "6px",
                    fontFamily: `pc_regular !important`,
                  }}
                  style={
                    {
                      borderRadius: 8,
                      borderColor: "#E0E0E0",
                    }
                  }
                  hoverColor={UIColor.primaryColor.color}
                />
              </Col>
            </Row>
        <div
          id={`${parent_id}_addmember_form_gender_label_div`}
          style={qdmstyles.dda_e_dc_a_afcccbd}
        >
          Gender{" "}
          <span
            id={`${parent_id}_addmember_form_gender_start_span`}
            style={{ color: "red" }}
          >
            *
          </span>
        </div>
        <TapSelect
          id={`${parent_id}_addmember_form_gender_tapselect`}
          key={"4"}
          fullWidth={false}
          label={false}
          selectedBorderColor="#0071F2"
          selectedBackgroundColor="#0071F2"
          selectedTextColor="#fff"
          textSize="16"
          iconHeight="20"
          iconWidth="20"
          textColor="#000"
          options={FlteredOptions("GENDER")}
          value={{ value: state?.gender, label: state?.gender }}
          onChange={(e) => changeState("gender", e)}
          style={{ marginTop: "", paddingTop: "" }}
          defaultSelected={{
            value: state?.gender,
            label: state?.gender,
          }}
          inLineStyles={{
            borderColor: "#E0E0E0",
            fontSize: "14px",
            fontFamily: "'pc_regular' !important",
          }}
          // style={qdmstyles.SREcn}
        ></TapSelect>
        {state.error?.gender && (
          <div id={`${parent_id}_addmember_form_pleaseselect_label_div`}>
            <Text
              id={`${parent_id}_addmember_form_pleaseselect_label_text`}
              style={{ color: "red", fontSize: 14 }}
            >
              {"Please select the gender"}
            </Text>
          </div>
        )}
       <DobAge
            parentId="addmember_form_select_dob_textinput"
            dateLabel="DOB"
            isRequired
            dateValue={state.dob?.val}
            onChange={(value) => {
              setState({
                ...state,
                error: {
                  ...state.error,
                  dob:false,
                },
              });
              if (value) {
                const milli = moment(value, "YYYY-MM-DD").valueOf();
                const date = {
                  val: moment(value, "YYYY-MM-DD").format("DD-MM-YYYY"),
                  milli,
                };
                changeState("dob", date);
              } else {
                changeState("dob", {
                  val: null,
                  milli: null,
                  value: "",
                });
              }
            }}
            maxDate={new Date()}
            minDate={(moment(new Date()).subtract(200, 'years'))._d}
            error={ (state.error?.dob ) && true }
            labelStyle={{ margin: "16px 0px 8px 0px" }}
            placeholder="dd-mm-yyyy"
            top={48}
            right={10}
          />
        {/* <Text
          id={`${parent_id}_addmember_form_dob_label_text`}
          style={{ fontSize: "12px", color: "#6f6f6f" }}
        >
          DOB <span style={{ color: "red" }}>*</span>
        </Text>
        <Div
          id={`${parent_id}_addmember_form_calender_div`}
          style={{
            border: "1px solid #E0E0E0",
            padding: "10px",
            height: "40px",
            width: "100%",
            fontSize: "14px",
            borderRadius: "4px",
            background: "transparent",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
            marginBottom: "8px",
          }}
        >
          <Text
            id={`${parent_id}_addmember_form_calender_val_text`}
            style={{ color: "#000" }}
          >
            {state?.dob?.val}
          </Text>
          <img
            id={`${parent_id}_addmember_form_calender_image`}
            alt="loding"
            src={calandarIcon}
            onClick={(e) => handlepapover(e)}
            style={{
              backgroundColor: "",
              color: "",
              cursor: "pointer",
              borderLeft: "1px solid #E0E0E0",
              paddingLeft: "10px",
            }}
          />
          <Popover
            style={{
              transform: "unset",
              position: "absolute",
              width: "320px",
              top: "30%",
              zIndex: "10",
            }}
            open={state?.opencalendar}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            onClose={() => handlepapoverclose()}
          >
            <MonthCalendar
              id={`${parent_id}_addmember_form_calender_mothcalender`}
              style={{ color: "#000" }}
              onChange={(e) => {
                const milli = e.getTime();
                const oldVal = state?.dob?.milli;
                const oldDate = moment(oldVal).format("DD");
                const newDate = moment(milli).format("DD");
                const date = {
                  val: moment(e).format("DD-MM-YYYY"),
                  milli,
                };
                changeState("dob", date);
                if (oldDate !== newDate || milli === oldVal) {
                  handlepapoverclose();
                }
              }}
            />
          </Popover>
        </Div> */}
        <Text
          id={`${parent_id}_addmember_form_mobile_number_text`}
          key={"7"}
          name={
            <span
              id={`${parent_id}_addmember_form_mobile_number_span`}
              style={{ fontSize: "13px" }}
            >
              Enter your Mobile number{" "}
              <span
                id={`${parent_id}_addmember_form_mobile_star_span`}
                style={{ color: "red" }}
              >
                *
              </span>
            </span>
          }
          style={qdmstyles.dda_e_dc_a_afcccbd}
        ></Text>

        <MobileNumberWithCode
          id={`${parent_id}_addmember_form_mobilenumberwithcode`}
          key={"8"}
          type={"number"}
          label={"Mobile Number"}
          borderColor={"#CACACA"}
          maxLength={30}
          borderRadius={"6"}
          disabled={false}
          size={"medium"}
          style={qdmstyles.a__ef__bad}
          value={state?.mobileNo?.mobileNo}
          defaultValue={state?.mobileNo?.mobileNo}
          dropdown={state?.mobileNo?.countryData}
          // dropdownData={[
          //   {
          //     name: 'India',
          //     flag:
          //       'https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg',
          //     altSpellings: 'IND',
          //     callingCodes: '91'
          //   }
          // ]}
          dropdownData={this?.props?.CountryCode?.data}
          onChange={(e) => changeState("mobileNo", e)}
          error={
            state?.error?.mobileNo || state?.error?.countryData ? true : false
          }
        ></MobileNumberWithCode>
        {(state?.error?.mobileNo || state?.error?.callingCodes) && (
          <>
            {state?.error?.mobileNo && (
              <Text
                id={`${parent_id}_addmember_form_enter_the_mobile_text`}
                style={{ color: "red", fontSize: 14 }}
              >
                {"Please enter the mobile number"}
              </Text>
            )}
            {state?.error?.callingCodes && !state?.error?.mobileNo && (
              <Text
                id={`${parent_id}_addmember_form_countrycode_text`}
                style={{ color: "red", fontSize: 14 }}
              >
                {"Please select the country code"}
              </Text>
            )}
          </>
        )}
        <TermsAndConditions
          id={`${parent_id}_addmember_form_send_appointmnet_terms`}
          value={true}
          style={{ fontSize: "1px" }}
          checkboxTickColor={"#fff"}
          activeCheckboxBorderColor={UIColor.secondaryButtonColor.color}
          checkboxBorderColor={UIColor.lineBorderFill.color}
          checkboxBackgroundColor={UIColor.differentiationBackground.color}
          activeCheckboxBackgroundColor={UIColor.secondaryButtonColor.color}
          disabled
          lable={
            <span
              id={`${parent_id}_addmember_form_send_appointmnet_span`}
              style={{ fontSize: "12px" }}
            >
              Send appointment update and notification to this Mobile number
            </span>
          }
        />
        <Text
          id={`${parent_id}_addmember_form_mail_id_text`}
          key={"13"}
          // name={'Mail Id (optional)'}
          name={
            <span
              id={`${parent_id}_addmember_form_mail_id_span`}
              style={{ fontSize: "13px" }}
            >
              Mail Id
              {/* <span style={{ color: 'red' }}>*</span> */}
            </span>
          }
          style={qdmstyles.dda_e_dc_a_afcccbd}
        ></Text>
        <TextInput
          id={`${parent_id}_addmember_form_mail_id_textinput`}
          key={"9"}
          type={"text"}
          name={""}
          variant={"outlined"}
          helperTextType={"error"}
          label={""}
          autoFocus={false}
          autoComplete={"off"}
          search={false}
          borderColor={"#9b9b9b"}
          elevation={"0"}
          placeholder={"example@gmail.com"}
          size={"medium"}
          style={qdmstyles.ed_db_d_f_e}
          value={state?.emailId}
          onChange={(e) => changeState("emailId", e.target.value)}
          error={state?.error?.emailId}
        ></TextInput>
        {state?.emailId && state?.error?.emailId && (
          <div>
            <Text
              id={`${parent_id}_addmember_form_mail_id_error_text`}
              style={{ color: "red", fontSize: 14 }}
            >
              {"Please Enter valid Email Id"}
            </Text>
          </div>
        )}
        <TermsAndConditions
          id={`${parent_id}_addmember_form_appointment_mail_id_terms`}
          inLineStyles={{
            fontFamily: "'pc_light' !important",
            maginBottom: "10px",
          }}
          checkboxTickColor={"#fff"}
          activeCheckboxBorderColor={UIColor.secondaryButtonColor.color}
          checkboxBorderColor={UIColor.lineBorderFill.color}
          checkboxBackgroundColor={UIColor.differentiationBackground.color}
          activeCheckboxBackgroundColor={UIColor.secondaryButtonColor.color}
          lable={
            <span
              id={`${parent_id}_addmember_form_appointment_mail_id_span`}
              style={{ fontSize: "12px" }}
            >
              Send appointment update and notification to this mail id
            </span>
          }
        />
        {addNewMember && (
          <>
            <br />{" "}
            <Text
              id={`${parent_id}_addmember_form_relationship_text`}
              key={"10"}
              name={
                <span id={`${parent_id}_addmember_form_relationship_span`}>
                  What's your relationship with Sudharsan Narasimhan ?{" "}
                  <span
                    id={`${parent_id}_addmember_form_relationship_star_span`}
                    style={{ color: "red" }}
                  >
                    *
                  </span>
                </span>
              }
              style={qdmstyles.efc_d__a_eea}
            ></Text>
            <TapSelect
              id={`${parent_id}_addmember_form_relationship_tapselect`}
              key={"11"}
              label={false}
              fullWidth={false}
              selectedBorderColor="#1890ff"
              selectedBackgroundColor="#1890ff"
              selectedTextColor="#fff"
              textSize="16"
              iconHeight="20"
              iconWidth="20"
              textColor="#000"
              title={false}
              name={false}
              inLineStyles={{
                borderColor: "#E0E0E0",
                fontSize: "14px",
                fontFamily: "'pc_regular' !important",
              }}
              options={FlteredOptions("RELATIONSHIP")}
              value={state?.relation}
              onChange={(e) => changeState("relation", e)}
              iconPosition={"left"}
            ></TapSelect>{" "}
          </>
        )}

        <Button
          id={`${parent_id}_addmember_form_Send_OTP_Sign_Up_buttom`}
          key={"12"}
          type={"button"}
          disabled={props?.registerUsers?.loading}
          variant={"outlined"}
          name={
            props?.registerUsers?.loading
              ? "Signing Up..."
              : addNewMember
              ? props?.addMember?.loading || props?.readPerson?.loading
                ? "Adding..."
                : "Add Member"
              : state.loadingReadPerson
              ? "Signing Up..."
              : "Send OTP & Sign Up"
          }
          style={qdmstyles.ee_ce_fe_aa_ecdaf}
          onClick={handleSignUp}
        ></Button>
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => ({
  registerUsers: state?.authSlice?.registerUsers,
  addMember: state?.personApiSlice?.upsertPerson,
  readPerson: state?.personApiSlice?.readPerson,
  CountryCode: state?.authSlice?.country_code_list,
});

export default connect(mapStateToProps, actions)(AppointmentDrawerSigningPage);
