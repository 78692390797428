import * as React from "react"

function PathwayIcon({height,width,color,...props}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width?width:24}
      height={height?height:24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M4 19L8 5m8 0l4 14M12 8V6m0 7v-2m0 7v-2"
        stroke={color ? color : "#EC6A49"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PathwayIcon