export const JSON = {
	res: [
		{
			name: "Front Office",
			vist: true,
			active: true,
			child: [
				{ name: "Virtual waiting room", active: true },
				{ name: "Mr. Ramesh (Receptionist)", active: false },
				{ name: "Visit Registration", active: false },
				{ name: "Mr. Ramesh (Receptionist)", active: false  },
			],
		},
		{
			name: "Nurse Station",
			vist: true,
			active: true,
			child: [
				{ name: "Virtual waiting room", active: true },
				{ name: "Ms. geetha", active: false },
				{ name: "Visit Registration", active: false },
				{ name: "Mr. Ramesh (Receptionist)", active: false },
			],
		},
		{
			name: "Doctor Consultaion",
			vist: true,
			active: true,
			child: [
				{ name: "Virtual waiting room", active: true },
				{ name: "Dr. Ramesh (Receptionist)", active: false },
				{ name: "Visit Registration", active: false },
				{ name: "Mr. Ramesh (Receptionist)", active: false },
			],
		},
	],
};
