import React, { useContext } from "react";
// import { actions } from "primarycare-binder";
import actions from "../../redux/actions";
//import { withRouter } from "react-router-dom";
// import { Routes } from "../../router/routes";
import html2canvas from 'html2canvas';
import { useReactToPrint } from "react-to-print";
import {
  Container,
  Div,
  Icons,
  SideMenu,
  Text,
  Image,
  Divider,
  Button,
  H6,
} from "qdm-component-library";
import CancelBookingPage from "../cancelBooking/index";
import ReactToPrint from "react-to-print";
import { AppRoutes } from "../../router/routes";
import {
  convertTime,
  getImgUrl,
  makeName,
  updateStatus,
  utcTOLocal,
  ValidateEmail,
  AlertProps,
  FnExecutorBasedOnFLow,
  actionButtonClickv2,
  actionButtonClick,
} from "../../utils";
import { UIColor } from "../../themes/theme";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { PatientDetails } from "./patientDetails";
import { AppointmentTable } from "./appointmentTable";
import { AppointmentSummaryPDF } from "../../components";
import Loading from "../../components/global/loading";
import EmailDialog from "./dialog";
import videoOutline from "./../../assets/img/svg/videoOutline.svg";
import videoFilled from "./../../assets/img/svg/videoFilled.svg";
import payment from "./../../assets/img/svg/payment.svg";
import { checkWithCasbin } from "../../utils";
import instaVerificationBadge from "../../assets/img/svg/icons8-instagram-verification-badge.svg";
import { AlertContext } from "../../context";
import jwtDecode from "jwt-decode";
// import { GoogleCalander } from "../../components/googleCalender";

const casbinjs = require("casbin.js");
// import { Gustuserconfirm } from "../../assets/img/gustuserconfirm.js";
const ConfirmationPage = (props) => {
  const location = useLocation();
  const calendarParams = location?.state?.calendarParams;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // location.state = {
  //     "userId": 1984,
  //     "mobileNo": "+917566756674",
  //     "appointmentId": "Appointment/12494",
  //     "patientId": "Patient/11146",
  //     "personId" : "Person/12177",
  //     "fromListing": true,
  //     "walkin": false,
  //     "unregister": false
  // };
  const [cancelbooking, setcancelbooking] = React.useState(false);
  const [cancelbookingreason, setcancelbookingreason] = React.useState({});
  const [cancelbookings, setcancelbookings] = React.useState(0);
  const [appointmentInfo, setappointmentInfo] = React.useState({});
  const [appArr, setappArr] = React.useState([]);
  const [modal, setmodal] = React.useState(false);
  const [err, seterr] = React.useState(false);
  const [emailId, setemailId] = React.useState("");
  const [isLoading, setisLoading] = React.useState(false);
  const [loading, setloading] = React.useState(false);
  const [pemissions, setpemissions] = React.useState([]);
  const [permission__, setpermission__] = React.useState([]);
  const [genInst, setgenInst] = React.useState({});
  const [loader, setloader] = React.useState(true);
  const [imageUrl, setImageUrl] = React.useState();
  const alert = useContext(AlertContext);
  let componentRef = React.useRef(null);

  const changeState = (key, value) => {
    setappointmentInfo(value);
  };
  const handleBeforePrint = React.useCallback(() => {
  }, []);
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);
  const handleOnBeforeGetContent = () => {
    setisLoading(true);
  };
  const handleAfterPrint = () => {
    setisLoading(false);
  };
  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "",
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });
  React.useState(async () => {
    let permiss_ = await checkWithCasbin(["appointmentconfirmation"]);
    let permission = await checkWithCasbin(["dashboard"]);

    if (!location?.state?.isMulti) {
      fetchInfo();
    } else {
      let info = {};
      const data = location?.state?.appointmentInfo?.map((app, i) => {
        if (!!app?.payload?.error) {
          return undefined;
        } else {
          info =
            app?.payload?.data?.appointment_response?.appointment?.Result?.[0]
              ?.properties?.doc || {};
          return {
            index: i,
            appointmentNumber: `${app?.payload?.data?.appointment_response?.appointment?.Result?.[0]?.properties?.doc?.appno}`,
            dateTime:
              app?.payload?.data?.appointment_response?.appointment?.Result?.[0]
                ?.properties?.doc?.start,
          };
        }
      });
      // fetchInfo(info.id, data);
      fetchInfo();
    }
    setloader(false);
    setpemissions({ ...pemissions, permiss_ });
    setpermission__({ ...permission__, permission });
  }, []);

  // const fetchInfo = async (id, data) => {
  const fetchInfo = async () => {
    // if (!id) {
    //   const data = await dispatch(actions.APPOINTMENT_READ({
    //     appointmentid: location?.state?.appointmentId,
    //     tenantid:"",
    //     facilityid:""
    //   }));
    //   changeState("appointmentInfo", data?.payload?.data || {});
    // } else {
    //   const info = await  dispatch(actions.APPOINTMENT_READ({
    //     appointmentid: id,
    //     tenantid: "",
    //     facilityid: ""
    //   }));
    //   setappointmentInfo(...appointmentInfo , info?.payload?.data || {})
    //   setappArr(...appArr , data.filter((d) => !!d))

    // }
    const data = await dispatch(
      actions.APPOINTMENT_READ({
        appointmentid: location?.state?.appointmentId || props?.appointmentId,
        tenantid: "",
        facilityid: "",
      })
    );
    //changeState("appointmentInfo", data?.payload?.data || {});
    setappointmentInfo(data?.payload?.data || {});


    const genInstruction = await dispatch(
      actions.GENERAL_INSTRUCTION({
        appoinmentid: location?.state?.appointmentId || props?.appointmentId,

      })
    );
    setgenInst(genInstruction?.payload?.data?.[0] || {});
  };
  const styles = {
    videoIcon: {
      width: 80,
      height: 80,
      background: "#73B9FF",
      marginBottom: 10,
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    container: {
      background: "#FFFFFF",
      boxShadow: "0px 4px 12px #0000001A",
      padding: 30,
      minWidth: "600px",
      borderRadius: 16,
      // height: "100vh"
    },
    tickSvg: {
      width: 32,
      height: 32,
      margin: "auto auto 10px",
    },
    appointmentBooking: {
      color: UIColor.success.color,
      fontSize: 18,
      marginBottom: 10,
      textAlign: "center",
    },
    confirmation: {
      color: UIColor.tertiaryText.color,
      fontSize: 12,
      textAlign: "center",
    },
    wrapper: {
      border: "2px dashed rgb(239 239 239)",
      borderRadius: 8,
      display: "flex",
      alignItems: "center",
      margin: "20px 0px",
    },
    h6: {
      color: "#101010",
      fontSize: 14,
      margin: 0,
    },
    span: {
      color: UIColor.tertiaryText.color,
      fontSize: 12,
    },
    barCode: {
      width: 100,
      height: 100,
      border: "2px dashed rgb(239 239 239)",
      padding: 5,
      marginBottom: 10,
      borderRadius: 8,
    },
    flexColumn: {
      display: "flex",
      flexDirection: "column",
      padding: 10,
    },
    flexRow: {
      display: "flex",
      alignItems: "center",
    },
    divider: {
      margin: "10px",
      opacity: 0.5,
    },
    iconSpan: {
      color: UIColor.secondaryButtonColor.color,
      marginLeft: "10px",
      fontSize: 12,
    },
    outlinedButton: {
      borderColor: UIColor.secondaryButtonColor.color,
      color: UIColor.secondaryButtonColor.color,
      backgroundColor: "#fff",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontSize: 12,
      marginRight: 10,
    },
    containedButton: {
      backgroundColor: UIColor.secondaryButtonColor.color,
      color: "#fff",
      borderColor: UIColor.secondaryButtonColor.color,
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontSize: 12,
    },
    clickHere: {
      margin: 0,
      marginLeft: 10,
      color: UIColor.secondaryButtonColor.color,
      cursor: "pointer",
      fontSize: 12,
    },
    gustuser: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "1px solid #38C20A",
      width: "80%",
      padding: "6px 8px",
      backgroundColor: "#F0FCEF",
      borderRadius: "8px",
      fontSize: 12,
      color: "#258007",
      marginTop: "4%",
    },
    multipleAppointmentsWrapper: {
      border: "1px solid #E0E0E0",
      borderRadius: 8,
      display: "flex",
      padding: "5px 0px",
      margin: "20px 0px",
    },
    veticalDiv: {
      width: 0.5,
      backgroundColor: "#E0E0E0",
    },
  };
  const handlesidebarCancelbooking = () => {
    setcancelbooking(!cancelbooking);
  };
  const handleSelectCancelbooking = (v) => {
    setcancelbookingreason(v);
  };
  const handlecahngeCancelbooking = (v) => {
    if (v === "back") {
      setcancelbookings(0);
      return;
    }
    if (!v) {
      handlesidebarCancelbooking();
      window.location.href = "/profile";
      // this.setState({
      //   cancelbookings: 0,
      // });
      return;
    }
    setcancelbookings(v);
  };

  // const getPractionerName = (val) => {
  //   if ((val?.resourcetype || "")?.toLowerCase() === "practitioner") {
  //     return makeName(val?.resourcecode?.name?.[0] ?? {});
  //   }
  //   return val?.resourcecode?.shortdesc;
  // }

  const tableHead = ["S.no", "appointment number", "Date & Time"];

  const appointmentSummaryPDF = async () => {
    await downloadPDF("pdf");
  };

  const downloadPDF = (divName) => {
    return new Promise(async (resolve, reject) => {
      const html2pdf = require("html2pdf.js");
      var dateObj = new Date();
      var month = dateObj.getUTCMonth() + 1; //months from 1-12
      var day = dateObj.getUTCDate();
      var year = dateObj.getUTCFullYear();
      var newdate = year + "_" + month + "_" + day;
      var printContents = document.getElementById(divName).innerHTML;
      var opt = {
        filename: `appointment_booking_${newdate}.pdf`,
        // image: { type: "jpg", quality: 1 },
        html2canvas: {
          dpi: 72,
          letterRendering: true,
          useCORS: true,
          proxy: true,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        // pdfCallback: pdfCallback
      };
      html2pdf().set(opt).from(printContents).save();
    });
  };

  const appointmentDetails = () => {
    if (location?.state?.isMulti) {
      return appArr?.map((l) => {
        return {
          ...l,
          dateTimeConverted: utcTOLocal(l?.dateTime, "DD MMM YYYY | hh:mm a"),
          appointmentId: l?.appointmentNumber,
        };
      });
    } else {
      return [
        {
          dateTimeConverted:
            appointmentInfo?.resourcetype?.display?.toLowerCase() ===
              "practitioner"
              ? utcTOLocal(appointmentInfo?.start || 0, "DD MMM YYYY | hh:mm a")
              : `${utcTOLocal(
                appointmentInfo?.start || 0,
                "DD MMM YYYY"
              )} | ${utcTOLocal(
                appointmentInfo?.start || 0,
                "hh:mm a"
              )} - ${utcTOLocal(appointmentInfo?.end || 0, "hh:mm a")}`,
          // time:'09:00 am',
          appointmentId: `${appointmentInfo?.appno || "---"}`,
        },
      ];
    }
  };

  const appointmentDownloadPDFData = () => {
    const data = {
      personDetails: {
        phoneNo: appointmentInfo?.AppointmentNotification?.[0]?.mobile ?? "",
        emailId:
          appointmentInfo?.AppointmentNotification?.[0]?.patientEmail ?? "",
        // patientName: makeName(appointmentInfo?.Person?.[0]?.name?.[0]),
        patientName: appointmentInfo?.person?.[0]?.name?.[0]?.fulname ?? "",
        // age: `${convertTime(
        //   appointmentInfo?.Person?.[0]?.birthdate,
        //   "DD/MM/YYYY",
        //   "years",
        //   "diff"
        // )} Yrs`,
        age: appointmentInfo?.person?.[0]?.age ?? "",
        gender: appointmentInfo?.person?.[0]?.gender ?? "",
        profile_pic:
          getImgUrl(appointmentInfo?.person?.[0]?.photo?.[0]?.fileid) ||
          undefined,
      },
      doctorDetails: {
        // doctorName: getPractionerName(appointmentInfo),
        doctorName:
          appointmentInfo?.resourcecode?.[0]?.name?.[0]?.fulname ?? "",
        // speciality:
        //   appointmentInfo?.Speciality?.[0]?.coding?.[0]?.display ||
        //   " ",
        speciality:
          appointmentInfo?.resourcecode?.[0]?.practitioner_role?.[0]
            ?.specialtyid?.display || " ",
        address: appointmentInfo?.orgid?.[0]?.name || "",
        profile_pic:
          getImgUrl(appointmentInfo?.resourcecode?.[0]?.photo?.[0]?.fileid) ||
          undefined,
      },
      hospitalDetails: {
        hospitalName: appointmentInfo?.orgid?.[0]?.name ?? "",
        hospitalAddress: {
          street: appointmentInfo?.orgid?.[0]?.address?.[0]?.line ?? "",
          city:
            appointmentInfo?.orgid?.[0]?.address?.[0]?.city?.geogLevelName ??
            "",
          state:
            appointmentInfo?.orgid?.[0]?.address?.[0]?.state?.geogLevelName ??
            "",
          pincode:
            appointmentInfo?.orgid?.[0]?.address?.[0]?.postalCode
              ?.geogLevelName ?? "",
          phoneNo:
            genInst?.orgContact ??
            appointmentInfo?.orgid?.[0]?.telecom?.[0]?.value ??
            "",
        },
      },
      appointmentDetails: appointmentDetails(),
      generalInstructions: genInst || [],
      // appointmentDetails:{
      //   date:'07 Apr 2021',
      //   time:'09:00 am',
      //   appointmentId:'AP-123456',
      // }
    };
    return data;
  };

  // const getBase64Url = async (divName) => {
  //   console.log(divName,"divName")

  //   return new Promise(async (resolve, reject) => {
  //     const html2pdf = require("html2pdf.js");
  //     var dateObj = new Date();
  //     var month = dateObj.getUTCMonth() + 1; //months from 1-12
  //     var day = dateObj.getUTCDate();
  //     var year = dateObj.getUTCFullYear();
  //     var newdate = year + "_" + month + "_" + day;
  //     var printContents = document.getElementById(divName).innerHTML;
  //     var opt = {
  //       filename: `appointment_booking_${newdate}.pdf`,
  //       //image: { type: "jpeg", quality: 1 },
  //       html2canvas: {
  //         dpi: 72,
  //         letterRendering: true,
  //         useCORS: true,
  //         proxy: true,
  //       },
  //       jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
  //       // pdfCallback: pdfCallback
  //     };
  //     await html2pdf()
  //       .from(printContents)
  //       .set(opt)
  //       .toPdf()
  //       .output("datauristring")
  //       .then((val) => {

  //         resolve(val);
  //         console.log(val,"valueee")
  //         dispatch(
  //           actions.SHAREEMAIL({
  //             emails: emailId,
  //             file_url: val,
  //           })
  //         );
  //         alert?.setSnack({
  //           open: true,
  //           severity: AlertProps.severity.success,
  //           msg: "Email has been sent successfully",
  //           vertical: AlertProps.vertical.top,
  //           horizontal: AlertProps.horizontal.center,
  //           tone: true,
  //         });
  //         setloading(false);
  //         setemailId("");
  //         handleClose();
  //       })
  //       .catch((err) => {
  //         resolve(null);
  //       });
  //   });
  // };
  const getBase64Url = async (divName) => {
    try {
      const canvas = await html2canvas(document.getElementById(divName), {
        useCORS: true,
        allowTaint: true,
        ignoreElements: (element) => {
          const excludedIds = [
            `${Parent_id}confirmbooking_title_div`,
            `${Parent_id}confirmbooking_invoice_div`,
            `${Parent_id}confirmbooking_print_div`,
            `${Parent_id}confirmbooking_share_div`,
            // `${Parent_id}confirmbooking_google_calender`,
            `${Parent_id}confirmbooking_button_div`
          ];
          return excludedIds.includes(element.id);
        }
      });

      const imageUrl = canvas.toDataURL("image/png");
      setImageUrl(imageUrl);
      dispatch(
        actions.SHAREEMAIL({
          emails: emailId,
          file_url: imageUrl,
        })
      );

      alert?.setSnack({
        open: true,
        severity: AlertProps.severity.success,
        msg: "Mail has been sent successfully",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });

      setloading(false);
      setemailId("");
      handleClose();
    } catch (error) {
      setloading(false);
    }
  };
  const handleClickOpen = () => {
    setmodal(true);
  };

  const handleClose = () => {
    setmodal(false);
  };

  const onChange = (v) => {
    setemailId(v);
    seterr(false);
  };
  const handleSend = () => {
    if (ValidateEmail(emailId) && emailId) {
      setloading(true);
      getBase64Url("imageForm");
    } else {
      seterr(true);
    }
  };

  const handleback2homeOrMasrkascomplete = async () => {
    //debugger
    if (
      location?.state?.modfieded ||
      location?.state?.cancel ||
      location?.state?.unregister
    ) {
      window.location.href = AppRoutes.dashboard;
    } else {
      if (location?.state?.visit) {
        await actionButtonClick(
          location?.state?.stateAction,
          location?.state?.stateEndPoint,
          location?.state?.stateBackEndCall,
          location?.state?.stateTicketid
        )
          .then((res) => {
            if (!res?.error) {
              alert?.setSnack({
                open: true,
                severity: AlertProps.severity.success,
                msg: "Marked as completed",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
                tone: true,
              });
            } else {
              alert?.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: res?.Response?.[0]?.errorMessage,
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
                tone: true,
              });
            }

            window.location.href = AppRoutes.dashboard;


          })
          .catch((error) => {
            alert?.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: "Please contact to admin",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
              tone: true,
            });
            window.location.href = AppRoutes.dashboard;
          });
      } else {
        await updateStatus(
          nurseTriagerequired === "true" ||
            permission__?.write?.indexOf("doctorQueue") > -1
            ? "completed_assemble"
            : "completed_nurse",
          location?.state?.appointmentId ||
          props.appointmentId ||
          props.appointmentId,
          dispatch
        );
        window.location.href = AppRoutes.dashboard;
      }
    }
  };

  const { parent_id } = props;
  const Parent_id = parent_id ? parent_id : "";

  const isVideo =
    appointmentInfo?.consultationModedisplay?.toLowerCase?.() ===
    "video consultation";
  const nurseTriagerequired = localStorage.getItem("nurseTriagerequired");
  return (
    <Loading loading={loader}>
      <Container
        id={`${Parent_id}confirmbooking_parent_container`}
        name="container"
        fluid={false}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height:
            location?.state?.modfieded || location?.state?.cancel
              ? "100vh"
              : "auto",
        }}
      >
        <Div id="imageForm">
          <Div
            id={`${Parent_id}confirmbooking_parent_div`}
            inLineStyles={styles.container}
          >
            <Div
              id={`${Parent_id}confirmbooking_instagram_div`}
              style={styles.tickSvg}
            >
              <Image
                id={`${Parent_id}confirmbooking_instagram_image`}
                src={instaVerificationBadge}
                alt="tick"
              />
            </Div>
            <H6
              id={`${Parent_id}confirmbooking_your_visit_label_h6`}
              className="pc_semibold"
              style={styles.appointmentBooking}
            >
              {props?.appoinmentBooking || location?.state?.fromListing ? (
                "Your Appointment Has Been Booked"
              ) : (
                <>
                  Your{" "}
                  {!location?.state?.visit
                    ? // && !this.state.pemissions?.write?.indexOf("downloadAppointment") > -1
                    "appointment"
                    : "visit"}{" "}
                  has been{" "}
                  {location?.state?.cancel
                    ? "cancelled"
                    : location?.state?.modfied || location?.state?.modfieded
                      ? "modified"
                      : !location?.state?.from
                        ? // && !this.state.pemissions?.write?.indexOf("downloadAppointment") > -1
                        "Confirmed"
                        : "registered"}{" "}
                  !
                </>
              )}
            </H6>
            <H6
              id={`${Parent_id}confirmbooking_confrimation_label_h6`}
              className="pc_regular"
              style={styles.confirmation}
            >
              Confirmation has been sent to your{" "}
              {appointmentInfo?.AppointmentNotification?.[0]?.mobile}{" "}
              {appointmentInfo?.AppointmentNotification?.[0]?.patientEmail && "&"}
              <br />
              {appointmentInfo?.AppointmentNotification?.[0]?.patientEmail}
            </H6>
            {/* <H6 className="pc_regular" style={gustuser}>
            <span style={{ marginRight: "2%" }}>
              <Gustuserconfirm />
            </span>{" "}
            You're a registered user now, you can log in using
            <br /> your mobile number (9894541371).
          </H6> */}

            {location?.state?.isMulti ? (
              <Div id={`${Parent_id}confirmbooking_patientdetails_div`}>
                <Div
                  id={`${Parent_id}confirmbooking_patientdetails_sub_div`}
                  style={styles.multipleAppointmentsWrapper}
                >
                  <PatientDetails
                    parent_id={"patient_dashboard_confirmbooking"}
                    // url="https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
                    // name={getPractionerName(appointmentInfo)}
                    name={
                      appointmentInfo?.resourcecode?.[0]?.name?.[0]?.fulname || ""
                    }
                    // title={
                    //   appointmentInfo?.Speciality?.[0]?.coding?.[0]
                    //     ?.display || " "
                    // }
                    title={
                      appointmentInfo?.resourcecode?.[0]?.practitioner_role
                        ?.specialtyid?.display || " "
                    }
                    url={getImgUrl(
                      appointmentInfo?.resourcecode?.[0]?.photo?.[0]?.fileid
                    )}
                    subTitle={appointmentInfo?.orgid?.[0]?.name || ""}
                  />
                  <Div style={styles.veticalDiv} />
                  <PatientDetails
                    parent_id={"patient_dashboard_confirmbooking"}
                    // url="https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
                    name={appointmentInfo?.person?.[0]?.name?.[0]?.fulname || ""}
                    url={getImgUrl(
                      appointmentInfo?.person?.[0]?.photo?.[0]?.fileid
                    )}
                    title={`${convertTime(
                      appointmentInfo?.person?.[0]?.birthdate,
                      "DD/MM/YYYY",
                      "years",
                      "diff"
                    )} Yrs | ${appointmentInfo?.person?.[0]?.gender}`}
                    subTitle={
                      appointmentInfo?.AppointmentNotification?.[0]?.mobile
                    }
                  />
                </Div>
                <Div style={{ marginBottom: 20 }}>
                  <AppointmentTable
                    tableHead={tableHead}
                    tableData={appArr}
                    parent_id={"patient_dashboard_confirmbooking"}
                  />
                </Div>
              </Div>
            ) : (
              <Div
                id={`${Parent_id}confirmbooking_converttime_div`}
                style={styles.wrapper}
              >
                <Div
                  id={`${Parent_id}confirmbooking_converttime_sub_div`}
                  style={{ ...styles.flexColumn, alignItems: "center" }}
                >
                  {!isVideo ? (
                    <Div
                      id={`${Parent_id}confirmbooking_upload_image_div`}
                      style={styles.barCode}
                    >
                      <Image
                        id={`${Parent_id}confirmbooking_upload_image`}
                        alt="Image"
                        // src="https://upload.wikimedia.org/wikipedia/commons/d/d0/QR_code_for_mobile_English_Wikipedia.svg"
                        src={`https://api.qrserver.com/v1/create-qr-code/?size=170x170&data=${appointmentInfo?.id}`}
                      />
                    </Div>
                  ) : (
                    <Div id="confirmbook_video_div" style={styles.videoIcon}>
                      <Image
                        id="confirmbook_video_image"
                        alt="Image"
                        inLineStyles={{
                          height: "30px",
                          width: "30px",
                        }}
                        // src="https://upload.wikimedia.org/wikipedia/commons/d/d0/QR_code_for_mobile_English_Wikipedia.svg"
                        src={videoOutline}
                      />
                    </Div>
                  )}
                  <H6
                    id={`${Parent_id}confirmbooking_datetime_format_h6`}
                    className="pc_medium"
                    style={styles.h6}
                  >
                    {
                      // convertTime(this.state.appointmentInfo?.start || 0, "DD/MM/YYYY HH:mm:ss", "DD MMM YYYY | hh:mm a")

                      appointmentInfo?.resourcetype?.display?.toLowerCase() ===
                        "practitioner"
                        ? utcTOLocal(
                          appointmentInfo?.start || 0,
                          "DD MMM YYYY | hh:mm a"
                        )
                        : `${utcTOLocal(
                          appointmentInfo?.start || 0,
                          "DD MMM YYYY"
                        )} | ${utcTOLocal(
                          appointmentInfo?.start || 0,
                          "hh:mm a"
                        )} - ${utcTOLocal(
                          appointmentInfo?.end || 0,
                          "hh:mm a"
                        )}`
                    }
                  </H6>
                  <H6
                    id={`${Parent_id}confirmbooking_AP_h6`}
                    className="pc_regular"
                    style={{ ...styles.span, margin: "5px 0px" }}
                  >
                    {appointmentInfo?.appno || "---"}
                    {!props?.hidden && isVideo && (
                      <span
                        id="confirmbook_appointmnet_info_span"
                        className="pc_regular"
                        style={{ ...styles.span, color: "#C44846" }}
                      >
                        {", Not-Paid"}
                      </span>
                    )}
                  </H6>
                  {/* Data For Price */}
                  {isVideo && (
                    <Div
                      style={{
                        background: "#FFF9F2 0% 0% no-repeat padding-box",
                        borderRadius: "20px",
                        padding: "3px 12px",
                      }}
                    >
                      <H6
                        id="confirmbook_appointmnet_info_video_h6"
                        className="pc_regular"
                        style={{
                          ...styles.span,
                          margin: "5px 0px",
                          color: "#F58B00",
                        }}
                      >
                        Passcode : {appointmentInfo?.avc?.passCode || "------"}
                      </H6>
                    </Div>
                  )}
                </Div>
                <Div
                  id={`${Parent_id}confirmbooking_details_div`}
                  style={{
                    borderLeft: "1px dashed #E0E0E0",
                    flex: 1,
                  }}
                >
                  <PatientDetails
                    url={getImgUrl(
                      appointmentInfo?.resourcecode?.[0]?.photo?.[0]?.fileid
                    )}
                    parent_id={"patient_dashboard_confirmbooking"}
                    // url="https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
                    // name={getPractionerName(appointmentInfo)}
                    name={
                      appointmentInfo?.resourcecode?.[0]?.name?.[0]?.fulname || ""
                    }
                    title={
                      appointmentInfo?.resourcecode?.[0]?.practitioner_role
                        ?.specialtyid?.display || " "
                    }
                    subTitle={appointmentInfo?.orgid?.[0]?.name || ""}
                  />
                  <Divider
                    id={`${Parent_id}confirmbooking_bottom_divider`}
                    style={styles.divider}
                  />
                  <PatientDetails
                    parent_id={"patient_dashboard_confirmbooking"}
                    url={getImgUrl(
                      appointmentInfo?.person?.[0]?.photo?.[0]?.fileid
                    )}
                    // url="https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
                    name={appointmentInfo?.person?.[0]?.name?.[0]?.fulname}
                    title={`${convertTime(
                      appointmentInfo?.person?.[0]?.birthdate,
                      "DD/MM/YYYY",
                      "years",
                      "diff"
                    )} Yrs | ${appointmentInfo?.person?.[0]?.gender}`}
                    subTitle={
                      appointmentInfo?.AppointmentNotification?.[0]?.mobile
                    }
                  />
                </Div>
              </Div>
            )}
            {location?.state?.reason && (
              <Div>
                <Div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "15px 0px 6px",
                  }}
                >
                  <Div
                    style={{ flex: 1, borderBottom: "1.9px solid #E0E0E0" }}
                  ></Div>
                  <H6
                    style={{
                      padding: "0px 10px",
                      fontSize: 14,
                      fontFamily: "pc_medium !important",
                    }}
                  >
                    Reason for{" "}
                    {location?.state?.cancel ? "cancellation" : "Revision"}
                  </H6>
                  <Div
                    style={{ flex: 1, borderBottom: "1.9px solid #E0E0E0" }}
                  ></Div>
                </Div>
                <Div
                  style={{
                    border: "1.9px solid #e0e0e0",
                    padding: 10,
                    borderRadius: 8,
                    margin: "14px 0px",
                    textAlign: "center",
                  }}
                >
                  {location?.state?.reason}
                </Div>
              </Div>
            )}
            <Div
              id={`${Parent_id}confirmbooking_bottom_sub_title_div`}
              style={{
                ...styles.flexRow,
                justifyContent:
                  location?.state?.unregister ||
                    !pemissions?.write?.indexOf("downloadAppointment") > -1
                    ? "space-between"
                    : "space-between",
              }}
            >
              {!location?.state?.unregister && (
                <>
                  {pemissions?.permiss_?.write?.indexOf("downloadAppointment") >
                    -1 && (
                      <Div
                        id={`${Parent_id}confirmbooking_title_div`}
                        style={{ ...styles.flexRow, cursor: "pointer" }}
                      >
                        <Icons
                          id={`${Parent_id}confirmbooking_download_image`}
                          fontIcon="download"
                          ariaHidden="true"
                          size="small"
                          style={{ color: UIColor.secondaryButtonColor.color }}
                        />
                        <Text
                          id={`${Parent_id}confirmbooking_summary_text`}
                          className="pc_regular"
                          style={styles.iconSpan}
                          onClick={() => appointmentSummaryPDF()}
                        >
                          Appointment Summary
                        </Text>
                      </Div>
                    )}
                  {pemissions?.permiss_?.write?.indexOf("downloadInvoice") >
                    -1 && (
                      <Div
                        id={`${Parent_id}confirmbooking_invoice_div`}
                        style={{ ...styles.flexRow, cursor: "pointer" }}
                      >
                        <Icons
                          id={`${Parent_id}confirmbooking_invoice_download_image`}
                          fontIcon="download"
                          ariaHidden="true"
                          size="small"
                          style={{ color: UIColor.secondaryButtonColor.color }}
                        />
                        <Text
                          id={`${Parent_id}confirmbooking_invoice_text`}
                          className="pc_regular"
                          style={styles.iconSpan}
                        >
                          Invoice
                        </Text>
                      </Div>
                    )}
                </>
              )}
              {pemissions?.permiss_?.write?.indexOf("print") > -1 && (
                <ReactToPrint
                  onBeforeGetContent={handleOnBeforeGetContent}
                  onAfterPrint={handleAfterPrint}
                  trigger={() => (
                    <Div
                      id={`${Parent_id}confirmbooking_print_div`}
                      style={{
                        ...styles.flexRow,
                        cursor: "pointer",
                        padding:
                          location?.state?.unregister ||
                            !pemissions?.write?.indexOf("downloadAppointment") > -1
                            ? "0px 30px"
                            : "0px 0px",
                      }}
                      onClick={() => handlePrint()}
                    >
                      <Icons
                        id={`${Parent_id}confirmbooking_print_image`}
                        fontIcon="print"
                        ariaHidden="true"
                        size="small"
                        style={{ color: UIColor.secondaryButtonColor.color }}
                      />
                      <Text
                        id={`${Parent_id}confirmbooking_print_text`}
                        className="pc_regular"
                        style={styles.iconSpan}
                      >
                        {isLoading ? "Enabling Print..." : "Print"}
                      </Text>
                    </Div>
                  )}
                  content={() => componentRef}
                />
              )}
              {pemissions?.permiss_?.write?.indexOf("share") > -1 && (
                <Div
                  id={`${Parent_id}confirmbooking_share_div`}
                  style={{ ...styles.flexRow, cursor: "pointer" }}
                  onClick={() => handleClickOpen()}
                >
                  <Icons
                    id={`${Parent_id}confirmbooking_share_image`}
                    fontIcon="share"
                    ariaHidden="true"
                    size="small"
                    style={{ color: UIColor.secondaryButtonColor.color }}
                  />
                  <Text
                    id={`${Parent_id}confirmbooking_share_text`}
                    className="pc_regular"
                    style={styles.iconSpan}
                  >
                    Share
                  </Text>
                </Div>
              )}
            </Div>
            {props?.hidden && isVideo && (
              <>
                <Divider
                  id={`video_app_payment_divder`}
                  key={"1"}
                  textWidth={"50"}
                  textColor={"#000"}
                  borderColor={"#E0E0E0"}
                  borderWidth={"1"}
                  orientation={"horizontal"}
                  variant={"middle"}
                />
                <Div
                  id={`video_app_payment_div`}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Div
                    id={`video_app_payment_sub_div`}
                    style={{
                      display: "flex",
                    }}
                  >
                    <Image
                      id="confirmbook_payment_image"
                      alt="Image"
                      inLineStyles={{
                        height: "30px",
                        width: "30px",
                      }}
                      // src="https://upload.wikimedia.org/wikipedia/commons/d/d0/QR_code_for_mobile_English_Wikipedia.svg"
                      src={payment}
                    />
                    <H6
                      id="confirmbook_video_payemtn_msg"
                      className="pc_regular"
                      style={{
                        color: "#101010",
                        fontSize: "12px",
                        width: "50%",
                        marginLeft: "15px",
                        fontWeight: 600,
                      }}
                    >
                      Would you like to pay for the appointment now?
                    </H6>
                  </Div>
                  <Button
                    id="confirmbook_video_payment_pay_now"
                    className="pc_medium"
                    inLineStyles={{
                      ...styles.containedButton,
                    }}
                  >
                    Pay Now
                  </Button>
                </Div>
              </>
            )}
            {/* {
            !this.props.hidden
            && isVideo
            && !this.props.multipleAppointments
            && (
              this.props.location?.state?.fromListing
            )
            && !this.props.location?.state?.reason
            && (
            <>
              <Divider
                id={`video_app_divder`}
                key={"1"}
                textWidth={"50"}
                textColor={"#000"}
                borderColor={"#E0E0E0"}
                borderWidth={"1"}
                orientation={"horizontal"}
                variant={"middle"}
              />
              <Div
                id="confirmbook_video_div"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  id="confirmbook_video_div_icon"
                  alt="Image"
                  inLineStyles={{
                    height: "30px",
                    width: "30px",
                    marginBottom: "10px",
                  }}
                  // src="https://upload.wikimedia.org/wikipedia/commons/d/d0/QR_code_for_mobile_English_Wikipedia.svg"
                  src={videoFilled}
                />
                <H6
                  id="confirmbook_video_message"
                  className="pc_regular"
                  style={{ ...span, ...flexRow }}
                >
                  Do you want to join consultation meeting link now?
                </H6>
                <Div
                  id="confirmbook_video_link"
                  className="pc_regular"
                  style={{
                    ...span,
                    marginTop: "10px",
                    color: UIColor.secondaryButtonColor.color,
                    fontStyle: "italic",
                    width: "60%",
                    wordWrap: "break-word",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open(this.state.appointmentInfo?.avc?.url, "_blank");
                  }}
                >
                  {this.state.appointmentInfo?.avc?.url || ""}
                </Div>
                <Div
                  id="confirmbook_video_password"
                  className="pc_regular"
                  style={{
                    ...span,
                    marginTop: "10px",
                    color: "#F58B00",
                  }}
                >
                  Passcode : ------
                </Div>
                <Button
                  id="confirmbook_video_join+now"
                  className="pc_medium"
                  onClick={() => {
                    window.open(this.state.appointmentInfo?.avc?.url, "_blank");
                  }}
                  inLineStyles={{
                    ...containedButton,
                    marginTop: "10px",
                  }}
                >
                  Join Now
                </Button>
              </Div>
              <Divider
                id={`video_app_divder`}
                key={"1"}
                textWidth={"50"}
                textColor={"#000"}
                borderColor={"#E0E0E0"}
                borderWidth={"1"}
                orientation={"horizontal"}
                variant={"middle"}
              />
            </>
          )} */}

            {/* <Div   id={`${Parent_id}confirmbooking_google_calender`} style={{marginTop:"50px"}}>
       <GoogleCalander />
       </Div> */}


            {!props?.hidden && (
              <Div
                id={`${Parent_id}confirmbooking_button_div`}
                style={{
                  ...styles.flexRow,
                  justifyContent: "center",
                  margin: "30px 0px",
                  gap: "20px",
                }}
              >
                {!location?.state?.unregister && (
                  <>
                    <Button
                      id={`${Parent_id}confirmbooking_go_profile_buttom`}
                      className="pc_medium"
                      inLineStyles={styles.containedButton}
                      onClick={() => {
                        console.log({
                          appointmentInfo
                        });
                        //debugger
                        if (localStorage.getItem("RoleName") === "pc_signupuser") {
                          navigate(AppRoutes.patientAppointmentsListingPage, {
                            state: {
                              userId:
                                appointmentInfo?.person?.[0]?.Id ||
                                location?.state?.userId ||
                                0,
                              mobileNumber:
                                location?.state?.mobileNo ||
                                appointmentInfo?.person?.[0]?.telecom?.find(
                                  (val) =>
                                    val.system === "Phone" && val.use === "Mobile"
                                )?.value,
                              unregister:
                                location?.state?.unregister ||
                                !pemissions?.write?.indexOf("downloadAppointment") >
                                -1,
                              patientId: location?.state?.patientId,
                              personId: location?.state?.personId,
                            },
                          });
                        } else {
                          let decodedToken = jwtDecode(localStorage.getItem("UserInfo"));

                          navigate(AppRoutes.PatientDashBoard, {
                            state: {
                              userId:
                                appointmentInfo?.person?.[0]?.Id ||
                                location?.state?.userId ||
                                0,
                              mobileNumber:
                                location?.state?.mobileNo ||
                                appointmentInfo?.person?.[0]?.telecom?.find(
                                  (val) =>
                                    val.system === "Phone" && val.use === "Mobile"
                                )?.value,
                              unregister:
                                location?.state?.unregister ||
                                !pemissions?.write?.indexOf("downloadAppointment") >
                                -1,
                              patientId: location?.state?.patientId || appointmentInfo?.patient_id || decodedToken?.patientid,
                              personId: location?.state?.personId,
                            },
                          });
                        }

                      }}
                    >
                      Go To Profile
                    </Button>

                    {calendarParams ? <Button id={`${Parent_id}confirmbooking_go_back_buttom`}
                      className="pc_medium"
                      inLineStyles={styles.containedButton}
                      onClick={() => {
                        navigate(AppRoutes.calender, {
                          state: {
                            calendarParams: calendarParams
                          },
                        });
                      }}
                    >
                      Go Back
                    </Button> : <></>}
                  </>
                )}
                &nbsp;&nbsp;
                {!location?.state?.fromListing && (
                  <Button
                    id={`${Parent_id}confirmbooking_mark_buttom`}
                    className="pc_medium"
                    onClick={handleback2homeOrMasrkascomplete}
                    inLineStyles={styles.containedButton}
                  >
                    {location?.state?.modfieded ||
                      location?.state?.cancel ||
                      location?.state?.unregister
                      ? "Back To Home"
                      : "Mark As Completed"}
                  </Button>
                )}
              </Div>
            )}
            {/* {!this.props.hidden && (
            <Div id={`${Parent_id}confirmbooking_click_div`}>
              <H6
                id={`${Parent_id}confirmbooking_want_cancel_h6`}
                className="pc_regular"
                style={{ ...span, ...flexRow, justifyContent: "center" }}
              >
                Want to cancel appointment ?
                <H6
                  id={`${Parent_id}confirmbooking_click_h6`}
                  style={clickHere}
                  onClick={() => this.handlesidebarCancelbooking()}
                >
                  Click here
                </H6>
              </H6>
            </Div>
          )} */}
          </Div>
        </Div>
        <SideMenu
          id={`${Parent_id}confirmbooking_sidemenu`}
          open={cancelbooking}
          direction="right"
          width={435}
          color="default"
          onClose={() => handlesidebarCancelbooking()}
        >
          <CancelBookingPage
            parent_id={"patient_dashboard"}
            cancelbookingreason={cancelbookingreason}
            appointmentId={
              location?.state?.appointmentId || props.appointmentId
            }
            cancelbookings={cancelbookings}
            handleSelectCancelbooking={handleSelectCancelbooking}
            handlecahngeCancelbooking={handlecahngeCancelbooking}
          />
        </SideMenu>
        <AppointmentSummaryPDF
          data={appointmentDownloadPDFData()}
          ref={(el) => (componentRef = el)}
        />
        <EmailDialog
          value={emailId}
          onChange={onChange}
          handleClose={handleClose}
          open={modal}
          handleSend={handleSend}
          error={err}
          loading={loading}
          helperText={"Please Enter Vaild Email ID"}
        />
      </Container>
    </Loading>
  );
};

// const mapStateToProps = (state) => ({
//   appointmentInfo: state?.appointmentReducer?.readAppointment,
// });

export default ConfirmationPage;
