import * as React from "react"

function IdIcon({height, width,color, ...props}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={height ? height : 14}
      height={width ? width : 14}
      viewBox="0 0 14 14"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_2355_10721)" fill={color?color:"#2D63BD"}>
        <path d="M2.625 6.563a.437.437 0 01.438-.438h1.75a.437.437 0 01.437.438v2.625a.437.437 0 01-.438.437h-1.75a.437.437 0 01-.437-.438V6.563zm8.75.218a.656.656 0 01-.656.657H6.78a.656.656 0 110-1.313h3.938a.656.656 0 01.656.656zM8.969 9.625a.656.656 0 100-1.313H6.78a.656.656 0 100 1.313H8.97z" />
        <path d="M6.344 0h1.312c.846 0 1.532.686 1.532 1.531v1.094h3.28c.846 0 1.532.686 1.532 1.531v7.438a1.531 1.531 0 01-1.531 1.531H1.53A1.531 1.531 0 010 11.594V4.156c0-.845.686-1.531 1.531-1.531h3.282V1.531C4.813.686 5.498 0 6.343 0zm2.828 3.938A1.531 1.531 0 017.656 5.25H6.344a1.531 1.531 0 01-1.516-1.313H1.531a.219.219 0 00-.218.22v7.437c0 .12.098.219.218.219H12.47a.218.218 0 00.219-.22V4.157a.218.218 0 00-.22-.218H9.173zM6.125 1.53V3.72c0 .12.098.219.219.219h1.312a.219.219 0 00.219-.22V1.532a.219.219 0 00-.219-.218H6.344a.219.219 0 00-.219.218z" />
      </g>
      <defs>
        <clipPath id="clip0_2355_10721">
          <path fill="#fff" d="M0 0H14V14H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IdIcon