import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
//import { generateSlots, generateSpecificSlots } from "./generateResponse";
import { calendarBlockRequests, dbName, defaultReject, defaultState, fetchData, generateSpecificSlots, query_ids, urls } from "../../utils";
const __readDocumentUrl__ = urls?.readDocuments;

const CALENDER_SHOW_APPOINTMENTS = createAsyncThunk(
  `scheduleManagementApiSlice/showAppoiments`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      let data = await fetchData(
        {
          body: JSON.stringify(payload)
        },
        urls.calender_showAppointments,
      );

      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const GET_QUEUE_DETAILS_BY_TICKETID = createAsyncThunk(
  `scheduleManagementApiSlice/queueDetails`,
  async (ticketid = "", { rejectWithValue }) => {
    try {

      let data = await fetchData(
        {
          body: JSON.stringify({
            "db_name": dbName,
            "filter": {
              "ticketid": ticketid
            },
            "queryid": "11de432f-91d3-4073-8385-06b4b3f62f8e"
          })
        },
        urls.readQdmQueries,
      );
      return {
        ...defaultState.Info,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const BLOCK_RELEASE_REASON = createAsyncThunk(
  `scheduleManagementApiSlice/block_release_reason`,
  async (payload = {}, { rejectWithValue }) => {
    // try {
    //   const data = await fetchData(
    //     { body: query.release_block_reason },
    //     __readDocumentUrl__
    //   );
    //   let arr = [];

    //   data &&
    //     data.result.map((val) => {
    //       arr.push({
    //         value: val._id,
    //         label: val.ReasonDesc,
    //         id: val.id,
    //       });
    //     });
    //   return {
    //     ...defaultState.List,
    //     data: arr,
    //   };
    // } catch (error) {
    //   return rejectWithValue({
    //     ...defaultReject,
    //     message: error.message,
    //   });
    // }
  }
);

const PRACTITIONER_SLOTS = createAsyncThunk(
  `scheduleManagementApiSlice/practitionerSlots`,
  async (payload = {}, { rejectWithValue }) => {
    // try {
    //   const { startDate, endDate, practitonerId } = payload;
    //   const data = await fetchData(
    //     {
    //       body: JSON.stringify(
    //         query.practitoner_slot(startDate, endDate, practitonerId)
    //       ),
    //     },
    //     __baseUrl__
    //   );

    //   let slots = generateSlots(data);

    //   return {
    //     ...defaultState.List,
    //     data: slots,
    //   };
    // } catch (error) {
    //   return rejectWithValue({
    //     ...defaultReject,
    //     message: error.message,
    //   });
    // }
  }
);

const RELEASE_SLOTS = createAsyncThunk(
  `scheduleManagementApiSlice/releaseSlots`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { dayStart, practitonerId, slotStart, slotEnd } = payload;
      let data = await fetchData(
        {
          body: JSON.stringify({
            "db_name": dbName,
            "filter": {
              "start": slotStart,
              "end": slotEnd,
              "practitionerid": practitonerId
            },
            "queryid": "9e24112d-d150-49de-b7c9-50fb3b1cd6f7"
          })
        },
        urls.readQdmQueries,
      );
      let slots = generateSpecificSlots(data);

      return {
        ...defaultState.Info,
        data: slots,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const RELEASE_SCHEDULE = createAsyncThunk(
  `scheduleManagementApiSlice/release_schedule`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { startDate, endDate, practitonerId, reason } = payload;
      const data = await fetchData(
        {
          body: JSON.stringify(
            calendarBlockRequests.release_schedule(startDate, endDate, practitonerId, reason)
          ),
        },
        urls?.releaseSchedule
      );

      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const BLOCK_SCHEDULE = createAsyncThunk(
  `scheduleManagementApiSlice/block_schedule`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { startDate, endDate, practitonerId, reason } = payload;
      const data = await fetchData(
        {
          body: JSON.stringify(
            calendarBlockRequests.block_schedule(startDate, endDate, practitonerId, reason)
          ),
        },
        urls?.blockSchedule
      );

      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);

const BLOCK_SCHEDULE_PARTIAL = createAsyncThunk(
  `scheduleManagementApiSlice/block_schedule_partial`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const {
        startDate,
        endDate,
        starttime,
        endtime,
        practitonerId,
        days,
        reason,
      } = payload;
      const data = await fetchData(
        {
          body: JSON.stringify(
            calendarBlockRequests.block_partial(
              startDate,
              endDate,
              starttime,
              endtime,
              practitonerId,
              days,
              reason
            )
          ),
        },
        urls?.blockSchedule
      );

      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const GET_FACT_BY_ID = createAsyncThunk(
  `getFactById/scheduleManagementApiSlice`,
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { factId = "" } = payload
      let data = await fetchData(
        {
          body: JSON.stringify({
            "db_name": dbName,
            "filter": {
              "factid": factId,
              "tenantid": "",
              "facilityid": "",
            },
            "queryid": "255b9171-6278-4c80-8e38-13a7943d34fc"
          })
        },
        urls.readQdmQueries,
      );

      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const GENERAL_MASTER = createAsyncThunk(
  `generalMaster/scheduleManagementApiSlice`,
  async (payload = {}, { rejectWithValue }) => {
    const { type = [""], tenantid = "", facilityid = "" } = payload
    try {

      const data = await fetchData(
        {
          body: JSON.stringify({
            "db_name": dbName,
            "filter": {
              type,
              tenantid,
              facilityid,
              lang: "en"
            },
            "queryid": "5c46abcb-a1d6-4c2f-ae4f-93ae551a0c6e"
          })
        },
        urls.readQdmQueries,
      );

      let obj = {};
      data?.forEach((v) => {
        let key = Object.keys(v)?.[0];
        obj[key] = v?.[key]?.Value?.map((v) => ({
          label: v?.display,
          value: v?._id,
        }));
      });
      return {
        ...defaultState.List,
        data: obj?.[type?.[0]],
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const QDM_CALL = createAsyncThunk(
  `qdmCall/scheduleManagementApiSlice`,
  async (payload = {}, { rejectWithValue }) => {
    try {

      const { tenantid = "", facilityid = "", queryId = "", filter } = payload

      const data = await fetchData(
        {
          body: JSON.stringify({
            "db_name": dbName,
            "filter": {
              tenantid,
              facilityid,
              ...(filter && filter)
            },
            "queryid": queryId
          })
        },
        urls.readQdmQueries,
      );
      return {
        ...defaultState.List,
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        ...defaultReject,
        message: error.message,
      });
    }
  }
);
const scheduleManagementSlice = createSlice({
  name: "scheduleManagementApiSlice",
  initialState: {
    showAppoiments: {
      ...defaultState.List,
    },
    queueDetails: {
      ...defaultState.Info,
    },
    practitioner_slots: {
      ...defaultState.List,
    },
    release_schedule: {
      ...defaultState.List,
    },
    block_schedule: {
      ...defaultState.List,
    },
    block_release_reason: {
      ...defaultState.List,
    },
    block_schedule_partial: {
      ...defaultState.List,
    },
    release_slots: {
      ...defaultState.List,
    },
    getFactById: {
      ...defaultState.List,
    },
    generalMaster: {
      ...defaultState.Info,
    },
    qdmCall: {
      ...defaultState.Info,
    },
  },
  extraReducers: {
    //Role type
    [CALENDER_SHOW_APPOINTMENTS.fulfilled]: (state, action) => {
      state.showAppoiments = action?.payload ?? {};
    },
    [CALENDER_SHOW_APPOINTMENTS.pending]: (state, action) => {
      state.showAppoiments.loading = true;
    },
    [CALENDER_SHOW_APPOINTMENTS.rejected]: (state, action) => {
      state.showAppoiments = action?.payload ?? {};
    },
    //practitioner
    [GET_QUEUE_DETAILS_BY_TICKETID.fulfilled]: (state, action) => {
      state.queueDetails = action?.payload ?? {};
    },
    [GET_QUEUE_DETAILS_BY_TICKETID.pending]: (state, action) => {
      state.queueDetails.loading = true;
    },
    [GET_QUEUE_DETAILS_BY_TICKETID.rejected]: (state, action) => {
      state.queueDetails = action?.payload ?? {};
    },

    // //block schedule
    // [BLOCK_RELEASE_REASON.fulfilled]: (state, action) => {
    //   (state.block_release_reason.loading = false),
    //     (state.block_release_reason.error = false),
    //     (state.block_release_reason = action.payload);
    // },
    // [BLOCK_RELEASE_REASON.pending]: (state, action) => {
    //   (state.block_release_reason.loading = true),
    //     (state.block_release_reason.error = false),
    //     (state.block_release_reason.loading = true);
    // },
    // [BLOCK_RELEASE_REASON.rejected]: (state, action) => {
    //   (state.block_release_reason.loading = false),
    //     (state.block_release_reason.error = true),
    //     (state.block_release_reason = action.payload);
    // },
    // //practitioner slots
    // [PRACTITIONER_SLOTS.fulfilled]: (state, action) => {
    //   (state.practitioner_slots.loading = false),
    //     (state.practitioner_slots.error = false),
    //     (state.practitioner_slots = action.payload);
    // },
    // [PRACTITIONER_SLOTS.pending]: (state, action) => {
    //   (state.practitioner_slots.loading = true),
    //     (state.practitioner_slots.error = false),
    //     (state.practitioner_slots.loading = true);
    // },
    // [PRACTITIONER_SLOTS.rejected]: (state, action) => {
    //   (state.practitioner_slots.loading = false),
    //     (state.practitioner_slots.error = true),
    //     (state.practitioner_slots = action.payload);
    // },

    // //release slots
    // [RELEASE_SLOTS.fulfilled]: (state, action) => {
    //   (state.release_slots.loading = false),
    //     (state.release_slots.error = false),
    //     (state.release_slots = action.payload);
    // },
    // [RELEASE_SLOTS.pending]: (state, action) => {
    //   (state.release_slots.loading = true),
    //     (state.release_slots.error = false),
    //     (state.release_slots.loading = true);
    // },
    // [RELEASE_SLOTS.rejected]: (state, action) => {
    //   (state.release_slots.loading = false),
    //     (state.release_slots.error = true),
    //     (state.release_slots = action.payload);
    // },

    // //release schedule..
    // [RELEASE_SCHEDULE.fulfilled]: (state, action) => {
    //   (state.release_schedule.loading = false),
    //     (state.release_schedule.error = false),
    //     (state.release_schedule = action.payload);
    // },
    // [RELEASE_SCHEDULE.pending]: (state, action) => {
    //   (state.release_schedule.loading = true),
    //     (state.release_schedule.error = false),
    //     (state.release_schedule.loading = true);
    // },
    // [RELEASE_SCHEDULE.rejected]: (state, action) => {
    //   (state.release_schedule.loading = false),
    //     (state.release_schedule.error = true),
    //     (state.release_schedule = action.payload);
    // },

    //block schedule

    [BLOCK_SCHEDULE.fulfilled]: (state, action) => {
      state.block_schedule = action?.payload ?? {};
    },
    [BLOCK_SCHEDULE.pending]: (state, action) => {
      state.block_schedule.loading = true;
    },
    [BLOCK_SCHEDULE.rejected]: (state, action) => {
      state.block_schedule = action?.payload ?? {};
    },
      //release_schedule

      [RELEASE_SCHEDULE.fulfilled]: (state, action) => {
        state.release_schedule = action?.payload ?? {};
      },
      [RELEASE_SCHEDULE.pending]: (state, action) => {
        state.release_schedule.loading = true;
      },
      [RELEASE_SCHEDULE.rejected]: (state, action) => {
        state.release_schedule = action?.payload ?? {};
      },
//release_schedule

[RELEASE_SLOTS.fulfilled]: (state, action) => {
  state.releaseSlots = action?.payload ?? {};
},
[RELEASE_SLOTS.pending]: (state, action) => {
  state.releaseSlots.loading = true;
},
[RELEASE_SLOTS.rejected]: (state, action) => {
  state.releaseSlots = action?.payload ?? {};
},
    //block schedule partial
    [BLOCK_SCHEDULE_PARTIAL.fulfilled]: (state, action) => {
      state.block_schedule_partial = action?.payload ?? {};
    },
    [BLOCK_SCHEDULE_PARTIAL.pending]: (state, action) => {
      state.block_schedule_partial.loading = true;
    },
    [BLOCK_SCHEDULE_PARTIAL.rejected]: (state, action) => {
      state.block_schedule_partial = action?.payload ?? {};
    },
    [GET_FACT_BY_ID.fulfilled]: (state, action) => {

      state.getFactById = action?.payload ?? {};
    },
    [GET_FACT_BY_ID.pending]: (state, action) => {
      state.getFactById.loading = true;
    },
    [GET_FACT_BY_ID.rejected]: (state, action) => {
      state.getFactById = action?.payload ?? {};
    },
    // GENERAL_MASTER
    [GENERAL_MASTER.fulfilled]: (state, action) => {
      state.generalMaster = action?.payload ?? {};
    },
    [GENERAL_MASTER.pending]: (state, action) => {
      state.generalMaster.loading = true;
    },
    [GENERAL_MASTER.rejected]: (state, action) => {
      state.generalMaster = action?.payload ?? {};
    },
    // QDM_CALL
    [QDM_CALL.fulfilled]: (state, action) => {
      state.qdmCall = action?.payload ?? {};
    },
    [QDM_CALL.pending]: (state, action) => {
      state.qdmCall.loading = true;
    },
    [QDM_CALL.rejected]: (state, action) => {
      state.qdmCall = action?.payload ?? {};
    },
  },
});

const scheduleManagementActions = {
  CALENDER_SHOW_APPOINTMENTS,
  GET_QUEUE_DETAILS_BY_TICKETID,
  // PRACTITIONER_SLOTS,
  RELEASE_SCHEDULE,
   BLOCK_SCHEDULE,
  // BLOCK_RELEASE_REASON,
  BLOCK_SCHEDULE_PARTIAL,
  RELEASE_SLOTS,
  GET_FACT_BY_ID,
  GENERAL_MASTER,
  QDM_CALL
};
export { scheduleManagementActions };
export default scheduleManagementSlice;
