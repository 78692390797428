import React from "react";

export const Spo2 = (props) => {
  let width = props.width ? props.width : "13";
  let height = props.height ? props.height : "15";
  //   let color = props.color ? props.color : "#000000";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 17.57 22.45"
    >
      <path
        id="icons8-spo2"
        d="M15.785,3c-6.563,0-8.3,5.555-8.3,8.491V13.2s0,.008,0,.012A2.143,2.143,0,0,0,7,14.616,2.346,2.346,0,0,0,8.792,16.9a13.517,13.517,0,0,0,3.123,4.979,3.514,3.514,0,0,0,2.379,2.294l.027.011V22.926l-.488-.488v-1.38a1.952,1.952,0,0,1,3.9,0v1.38l-.488.488v1.263l.025-.011a3.512,3.512,0,0,0,2.382-2.3,13.518,13.518,0,0,0,3.122-5.023,2.278,2.278,0,0,0,1.792-2.241,2.138,2.138,0,0,0-.5-1.414c0-.018.01-1.711.01-1.711C24.082,6.174,20.98,3,15.785,3ZM13.153,7.97c1.145,1.4,2.867,1.382,4.311,1.371,1.913-.015,3.018.058,3.492,1.724a3.8,3.8,0,0,0,1.927,2.374l.3.15a.475.475,0,0,0,.114.2,1.132,1.132,0,0,1,.3.827,1.326,1.326,0,0,1-1.222,1.32.486.486,0,0,0-.4.312,14.668,14.668,0,0,1-2.352,4.27.336.336,0,0,1-.123.036c-.57-1.756-2.284-4.865-3.708-4.865s-3.141,3.113-3.709,4.869a.336.336,0,0,1-.123-.036A14.636,14.636,0,0,1,9.6,16.295a.487.487,0,0,0-.4-.309,1.371,1.371,0,0,1-1.223-1.37,1.131,1.131,0,0,1,.3-.827.482.482,0,0,0,.1-.152A8.567,8.567,0,0,0,13.153,7.97Zm-.57,6.225c-.133.01-.214.072-.214.274a.732.732,0,1,0,1.464,0c0-.4-.328-.244-.732-.244C12.9,14.225,12.716,14.185,12.583,14.2Zm5.369,0c-.133.01-.214.072-.214.274a.732.732,0,1,0,1.464,0c0-.4-.328-.244-.732-.244C18.267,14.225,18.084,14.185,17.952,14.2Zm-2.167,5.886a.976.976,0,0,0-.976.976v.976l.488.488V25.45h.976V22.522l.488-.488v-.976A.976.976,0,0,0,15.785,20.082Z"
        transform="translate(-7 -3)"
        fill="#1a7aa5"
      />
    </svg>
  );
};