import * as React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Typography,
  IconButton,
  DialogContent,
  Paper,
  Grid,
  Avatar,
  Divider,
  DialogActions,
  Box,
  Chip,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  DialogTitle,
  Dialog,
  Collapse,
  Tooltip,
  TextField,
} from "@mui/material";
import {
  Cancel as CancelIcon,
  Schedule as ScheduleIcon,
  AccountBox as AccountBoxIcon,
  CalendarMonth as CalendarIcon,
  Phone as PhoneIcon,
  Person as PersonIcon,
  Height as HeightIcon,
  MonitorWeight as MonitorWeightIcon,
  Favorite as FavoriteIcon,
  MonitorHeart as PulseIcon,
  Thermostat as ThermostatIcon,
  BarChart as BarChartIcon,
  Accessibility as AccessibilityIcon,
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  ExpandCircleDown as ExpandCircleDownIcon,
  CalendarToday as CalendarTodayIcon,
  LocalHospital as LocalHospitalIcon,
  FirstPage as FirstPageIcon,
  LowPriority as LowPriorityIcon,
  MedicalServices as MedicalServicesIcon,
  Info,
} from "@mui/icons-material";

import theme from "../../theme";
import LoadingScreen from "./loader";
import AssistantSummary from "./assistantSummary";
import { AllergyIcon } from "../../assets/svgComponents/allergyIcon";
import { VitalSigns } from "../../assets/svgComponents/vitalSigns";
import { DiagnosisIcon } from "../../assets/svgComponents/diagnosisIcon";
import { ChiefComplaintsIcon } from "../../assets/svgComponents/chiefComplaintsIcon";
import { FiberManualRecord as BulletIcon } from "@mui/icons-material";
import { DateRangeIcon } from "@mui/x-date-pickers";
import AccordionTemplate from "./accordionTemplate";
import InfoIcon from "@mui/icons-material/Info";
import Summary from "./summary/summary";
import SuggestedOrders from "./summary/suggestedOrders";
import InfoVital from "./infoWidget/infoVital";
import InfoAllergies from "./infoWidget/infoAllergies";
import InfoDiagnosis from "./infoWidget/infoDiagnosis";
import PatientDetail from "./header/headerCard";
import HeaderCard from "./header/headerCard";
import ArrowIcon from "../../assets/svgComponents/arrow";
import IdIcon from "../../assets/svgComponents/idIcon";
import EducationDescription from "./summary/educationDescription";
import LoadingAiOutput from "./loadingAiOutput";
import InfoMedications from "./infoWidget/infoMedications";

const renderChip = (label, IconComponent) => (
  <Chip
    label={label}
    icon={<IconComponent color="primary" />}
    sx={{
      backgroundColor: "primary.light", // Accepts custom background color
      justifyContent: "flex-start",
    }}
  />
);

// Mapping vitalcode to labels and icons
const getVitalDetails = (vitalcode) => {
  switch (vitalcode) {
    case "HEIGHT":
      return { label: "Height", icon: HeightIcon };
    case "WEIGHT":
      return { label: "Weight", icon: MonitorWeightIcon };
    case "BMI":
      return { label: "BMI", icon: BarChartIcon };
    case "BSA":
      return {
        label: "Body Surface Area",
        icon: AccessibilityIcon,
      };
    case "HIGHBP":
      return {
        label: "Blood Pressure",
        icon: FavoriteIcon,
      };
    case "PULSE":
      return { label: "Pulse", icon: PulseIcon };
    case "RESP":
      return {
        label: "Respiratory Rate",
        icon: LocalHospitalIcon,
      };
    case "O2S":
      return {
        label: "Oxygen Saturation",
        icon: LocalHospitalIcon,
      };
    case "TEMP":
      return { label: "Temperature", icon: ThermostatIcon };
    case "HEADC":
      return {
        label: "Head Circumference",
        icon: AccessibilityIcon,
      };
    case "WSTCM":
      return {
        label: "Waist Circumference",
        icon: AccessibilityIcon,
      };
    default:
      return { label: "Unknown", icon: ErrorIcon };
  }
};


const AllergyList = ({ items, expand }) => (
  <List sx={{ paddingTop: 0 }}>
    {items?.map((item, index) => (
      <Grid container mb={1}>
        <ListItem key={index} sx={{ padding: 0 }}>
          <ListItemIcon sx={{ minWidth: "24px" }}>
            <BulletIcon style={{ fontSize: "0.6rem" }} color="primary" />
          </ListItemIcon>
          <ListItemText primary={item?.allergy} sx={{}} />
        </ListItem>
        <Collapse in={expand}>
          <Grid sx={{ display: "flex", alignItems: "center", gap: 2, marginLeft: 3 }}>
            <Typography variant="body2" color={theme?.palette?.text?.secondary}>{`▪️ ${item?.severity}`}</Typography>
            <Typography variant="body2" color={theme?.palette?.text?.secondary}>{`▪️ ${item?.type}`}</Typography>
            <Typography variant="body2" color={theme?.palette?.text?.secondary}>{`▪️ ${item?.verification}`}</Typography>
          </Grid>
        </Collapse>
      </Grid>
    ))}
  </List>
);

const DiagnosisList = ({ items, expand }) => (
  <List sx={{ paddingTop: 0 }}>
    {items?.map((item, index) => (
      <Grid container mb={1}>
        <ListItem key={index} sx={{ padding: 0 }}>
          <ListItemIcon sx={{ minWidth: "24px" }}>
            <BulletIcon style={{ fontSize: "0.6rem" }} color="primary" />
          </ListItemIcon>
          <ListItemText primary={`${item?.diagnosiscode}-${item?.diagnosisdescription}`} />
        </ListItem>
        <Collapse in={expand}>
          <Grid sx={{ display: "flex", alignItems: "center", gap: 2, marginLeft: 3 }}>
            <Typography variant="body2" color={theme?.palette?.text?.secondary}>{`▪️ ${item?.onsetdate}`}</Typography>
            <Typography variant="body2" color={theme?.palette?.text?.secondary}>{`▪️ ${item?.use}`}</Typography>
            <Typography variant="body2" color={theme?.palette?.text?.secondary}>{`▪️ ${item?.verificationstatus}`}</Typography>
          </Grid>
        </Collapse>
      </Grid>
    ))}
  </List>
);
const ChiefComplaintList = ({ items, expand }) => (

  <List sx={{ paddingTop: 0 }}>
    {items?.map((item, index) => (
      <Grid container mb={1}>
        <ListItem key={index} sx={{ padding: 0 }}>
          <ListItemIcon sx={{ minWidth: "24px" }}>
            <BulletIcon style={{ fontSize: "0.6rem" }} color="primary" />
          </ListItemIcon>
          <ListItemText primary={item?.chiefcomplaints} />
        </ListItem>
        <Collapse in={expand}>
          <Grid sx={{ display: "flex", alignItems: "center", gap: 2, marginLeft: 3 }}>
            <Typography variant="body2" color={theme?.palette?.text?.secondary}>{`▪️ ${item?.severity}`}</Typography>
            <Typography variant="body2" color={theme?.palette?.text?.secondary}>{`▪️ ${item?.bodysite}`}</Typography>
            <Typography variant="body2" color={theme?.palette?.text?.secondary}>{`▪️ ${item?.verification}`}</Typography>
          </Grid>
        </Collapse>
      </Grid>
    ))}
  </List>
);

const AccordionSection = ({ title, icon, children, expand, setExpand }) => {
  return (
    <Accordion
      defaultExpanded
      sx={{ backgroundColor: theme?.palette?.primary?.light }}
    >
      <AccordionSummary
        expandIcon={<ExpandCircleDownIcon color="primary" />}
        aria-controls={`${title}-content`}
        id={`${title}-header`}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {icon}
          <Typography variant="button">{title}</Typography>
          <Tooltip title="More Info" arrow>
            <InfoIcon
              color="info"
              onClick={(event) => {
                event?.stopPropagation();
                setExpand(!expand);
              }}
            />
          </Tooltip>
        </Box>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
const PatientDetails = ({ patient }) => (
  <Grid container spacing={2} height="100%" margin={0}>
    <Grid
      item
      xs={1}
      sx={{ display: "flex", alignItems: "center" }}
      style={{ padding: 0 }}
    >
      <Avatar
        sx={{
          bgcolor: theme?.palette?.secondary?.main,
          height: "100%",
          width: "100%",
        }}
        variant="square"
      >
        <PersonIcon />
      </Avatar>
    </Grid>
    <Grid item xs={11} style={{ padding: 0 }}>
      <Grid container spacing={1} sx={{ margin: 0 }}>
        <Grid item xs={12}>
          <Typography variant="button" display="block">
            {patient?.name ? patient?.name : ""}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ padding: 0 }}>
          {patient?.patientid ? renderChip(patient?.patientid, AccountBoxIcon) : ""}
          {/* {renderChip(patient.phone, PhoneIcon)} */}
          {patient?.age ? renderChip(patient?.age, CalendarIcon) : ""}
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const EncounterDetails = ({ encounter }) => (
  <Grid container spacing={2} height="100%" margin={0}>
    <Grid
      item
      xs={1}
      sx={{ display: "flex", alignItems: "center", padding: 0 }}
      style={{ padding: 0 }}
    >
      <Avatar
        sx={{
          bgcolor: theme?.palette?.secondary?.main,
          height: "100%",
          width: "100%",
        }}
        variant="square"
      >
        <CalendarIcon />
      </Avatar>
    </Grid>
    <Grid item xs={11} style={{ padding: 0 }}>
      <Grid container spacing={1} sx={{ margin: 0 }}>
        <Grid item xs={12}>
          <Typography variant="button">{encounter?.encounternumber ? encounter?.encounternumber : ""}</Typography>
          <Typography variant="button" ml={2}>{encounter?.inchargedoctor ? `- ${encounter?.inchargedoctor}` : ""}</Typography>
        </Grid>
        <Grid item xs={12} style={{ padding: 0 }}>
          {encounter?.visitdatetime ? renderChip(encounter?.visitdatetime, DateRangeIcon) : ""}
          {encounter?.speciality ? renderChip(encounter?.speciality, LocalHospitalIcon) : ""}
          {encounter?.status ? renderChip(encounter?.status, LowPriorityIcon) : ""}
          {encounter?.encountertype ? renderChip(encounter?.encountertype, MedicalServicesIcon) : ""}
          {encounter?.bednumber ? renderChip(encounter?.bednumber, MedicalServicesIcon) : ""}
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const VitalsSection = ({ vitals }) => {
  const renderChip = (
    label,
    IconComponent,
    color = "primary",
    width = "120px",
    backgroundColor = "primary.light"
  ) => (
    <Chip
      label={label}
      size="small"
      icon={<IconComponent color={color} />}
      sx={{
        width,
        borderRadius: "5px",
        backgroundColor,
        justifyContent: "flex-start",
        fontWeight: "bold",
      }}
    />
  );

  return (
    <AccordionTemplate
      title="Vitals"
      icon={<VitalSigns width={25} height={25} />}
    >
      <List sx={{ p: 0 }}>
        {vitals?.map((vital, index) => {
          const { label, icon } = getVitalDetails(vital?.vitalcode);
          const isNormal = !vital?.isabnormal; // Add your logic for determining normal/abnormal values
          return (
            <Grid container alignItems="center" key={index} spacing={1}>
              <Grid item>{renderChip(vital?.vitalcode, icon)}</Grid>
              <Grid item xs>
                <Typography variant="body2">
                  {vital?.vitalvalue}{" "}
                  {vital?.vitalunit ? `(${vital?.vitalunit})` : ""}
                </Typography>
              </Grid>
              <Grid item>
                {isNormal ? (
                  <CheckCircleIcon color="success" />
                ) : (
                  <ErrorIcon color="error" />
                )}
              </Grid>
            </Grid>
          );
        })}
      </List>
    </AccordionTemplate>
  );
};

export default function Assistant({ onClose, open, data,setData,loading,loadingData }) {
  
  const [expandAllergy, setExpandAllergy] = React.useState(false);
  const [expandDiagnosis, setExpandDiagnosis] = React.useState(false);
  const [expandChiefComplaints, setExpandChiefComplaints] = React.useState(false);

  console.log(data,"assistant data");
  return (
    <Dialog
      maxWidth="100%"
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { color: "black" } }}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          backgroundColor: theme?.palette?.primary?.main,
          color: "#fff",
        }}
      >
        <Typography variant="h6">MED-AI ASSISTANT</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="large"
          sx={{ position: "absolute", right: 8, top: 8 }}
          color="inherit"
        >
          <CancelIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ padding: 0, margin: 1, maxHeight: "90vh", overflowY: "auto",scrollbarWidth:"thin" }}>
        <Grid container spacing={2} sx={{ width: '100%', overflowX: 'hidden' }}>
          {/* Patient and Encounter Details: Sticky Header */}
          <Grid container p={2} borderBottom={`1px solid ${theme?.palette?.lineBorderFill}`}>
            {/* <Paper
          elevation={5}
          sx={{
            backgroundColor: theme?.palette?.primary?.light,
            position: "sticky",
            top: 0, // Stick to the top of the dialog
            zIndex: 1, // Ensure it stays above the other content
          }}
        > */}
            {/* <PatientDetails
            patient={data?.inputData?.PatientDemographic}
          /> */}
            {/* </Paper> */}
            <Grid item xs={6} >
              <HeaderCard
                avatar={"url needed"}
                title={data?.inputData?.PatientDemographic?.name}
                subTitle={<Grid container alignItems={"center"} gap={1}>
                  <IdIcon /> {data?.inputData?.PatientDemographic?.patientid}
                  &nbsp; - &nbsp;{data?.inputData?.PatientDemographic?.age}
                  &nbsp; - &nbsp;{data?.inputData?.PatientDemographic?.gender}
                </Grid>}
                splitBorder={true}
              />
            </Grid>
            <Grid item xs={6} >
            <HeaderCard
                avatar={"Hello"}
                title={data?.inputData?.VisitInformation?.[0]?.encountertype}
                subTitle={<Grid container alignItems={"center"} gap={1}>
                  <IdIcon /> {data?.inputData?.VisitInformation?.[0]?.encounternumber}
                  &nbsp; - &nbsp;{data?.inputData?.VisitInformation?.[0]?.encountertype}
                  &nbsp; - &nbsp;{data?.inputData?.VisitInformation?.[0]?.visitdatetime}
                </Grid>}
                // splitBorder={true}
              />
            </Grid>

          </Grid>
          {/* <Grid item xs={6} padding={0}>
        <Paper
          elevation={5}
          sx={{
            backgroundColor: theme?.palette?.primary?.light,
            position: "sticky",
            top: 0, // Stick to the top of the dialog
            zIndex: 1, // Ensure it stays above the other content
          }}
        >
          <EncounterDetails
            encounter={data?.inputData?.VisitInformation?.[0]}
          />
        </Paper>
        </Grid> */}
          
          {/* Main Scrolling Content */}
          <Grid item xs={9} padding={0} sx={{ overflowY: "auto", overflowX: 'hidden', maxHeight: "68vh" ,bgcolor: theme?.palette?.differentialBackground,scrollbarWidth: "thin"}}>
            {loading ? (
               <Grid m={1} height={"95%"} >
                <LoadingAiOutput />
                </Grid>
            ) : (
              // <AssistantSummary data={data} />
                <>{
                  data?.modelData?.summary && 
                <Grid m={1} >
                  <Summary data={ data?.modelData?.summary }/>
                </Grid>
              }
                <Grid m={1} >
                  <SuggestedOrders data={ data?.modelData } setData={setData} loadingData={loadingData}/>
                  </Grid>
                  {
                    data?.modelData?.lifeStyleAdvices?.length > 0 &&
                  <Grid m={1} >
                    <EducationDescription data={ data?.modelData } setData={setData}/>
                  </Grid>
                  }
                <Grid m={1}>
                  <Paper elevation={0}sx={{
                      border: `2px solid ${theme?.palette?.lineBorderFill}`,
                      margin: "2px",
                      borderRadius: 1,
                      backgroundColor: "#fff"
                    }} >
                    <Grid container p={2}>
                      <Typography variant="head2" color={theme?.palette?.secondaryText}>
                        Feedback
                      </Typography>
                      <TextField
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        margin="dense"
                        placeholder="Type your feedback here"
                      />
                    </Grid>
                  </Paper>
                </Grid>
              </>
            )}
          </Grid>

          {/* Right-hand Sidebar with Scrolling Content */}
          <Grid item xs={3} sx={{ overflowY: "auto", maxHeight: "70vh" ,scrollbarWidth: "thin"}}>
            {/* <VitalsSection vitals={data?.inputData?.Vitals} /> */}
            
            <Grid m={1} >
              <InfoVital data={data?.inputData?.Vitals} />
            </Grid>
            <Grid m={1} >
              <InfoMedications data={data?.inputData?.MedicationOrder} />
            </Grid>
            <Grid m={1} >
              <InfoDiagnosis data={data?.inputData?.Diagnosis} />
            </Grid>
            <Grid m={1} >
              <InfoAllergies data={data?.inputData?.Allergy} />
            </Grid>
            {/* <AccordionSection
          title="ALLERGIES"
          icon={<AllergyIcon width={20} height={20} />}
          expand={expandAllergy}
          setExpand={setExpandAllergy}
        >
          <AllergyList
            items={data?.inputData?.Allergy}
            expand={expandAllergy}
          />
        </AccordionSection>

        <AccordionSection
          title="DIAGNOSIS"
              icon={<DiagnosisIcon width={20} height={20} />}
              expand={expandDiagnosis}
          setExpand={setExpandDiagnosis}
            >
          <DiagnosisList
                items={data?.inputData?.Diagnosis}
                expand={expandDiagnosis}
          />
        </AccordionSection>

        <AccordionSection
          title="CHIEF COMPLAINTS"
              icon={<ChiefComplaintsIcon width={20} height={20} />}
              expand={expandChiefComplaints}
          setExpand={setExpandChiefComplaints}
            >
              <ChiefComplaintList items={data?.inputData?.ChiefComplaints}
          expand={expandChiefComplaints}/>
        </AccordionSection> */}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "flex-end", padding: "16px" }}>
        <Button onClick={onClose} variant="contained" color="primary">
          Generate Order
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Assistant.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
