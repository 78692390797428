import React from "react";
import { Div, H6, ListItem } from "qdm-component-library";
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import { IconButton } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../router/routes";
function CardList(props) {
  const { middleComponent = <></>, title,parent_id,isCalender=false } = props;
  const navigate = useNavigate()
  return (
    <Div
      id={`${parent_id}_cardlist_parent_col`}
    >
      <ListItem
        id={`${parent_id}_cardlist_listitem`}
        title={title}
        inLineStyles={{
          // marginTop: "3%",
          marginBottom: "5px",
          paddingLeft: 17,
          paddingTop: 17,
          fontSize: 12
        }}
        description={false}
        profile={false}
        action={
          <div
            id={`${parent_id}_cardlist_div`}
            style={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",              
              cursor: "pointer",
            }}
          >
          { (title === "UPCOMING" && isCalender)? <IconButton onClick={()=>{navigate(AppRoutes.calender)}}>
            <EditCalendarIcon style={{color:"black"}}/>
            </IconButton>:<></>}
          </div>
        }
      />
     
      <div
        id={`${parent_id}_cardlist_middle_div`}
        style={{ padding:'0px 17px' }}
      >
        {middleComponent}
      </div>
    </Div>
  );
}

export default CardList;
