import React from "react";
import { Card, Grid, Avatar, Typography, Divider, Button, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
// SVG Imports
import man from "../../assets/img/svg/man.svg";
import facility from "../../assets/img/svg/facility.svg";
import idcard from "../../assets/img/svg/id-card.svg";
import timehistory from "../../assets/img/svg/timehistory.svg";
import calenderoutlined from "../../assets/img/svg/calenderoutlined.svg";
import wallet from "../../assets/img/svg/wallet.svg";
import healthpay from "../../assets/img/svg/healthpay.svg";
import astriks from "../../assets/img/svg/ashtricks.svg";
import { AppRoutes } from "../../router/routes";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";


const useStyles = makeStyles(() => ({
  card: {
    maxWidth: "50vw",
    margin: "auto",
    borderRadius: 12,
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
    padding: 20,

  },
  avatar: {
    width: '110px',
    height: '100px',
    marginBottom: '5px',
    borderRadius: '0px'
  },
  header: {
    fontWeight: 600,
    marginBottom: 8,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  subText: {
    display: "flex",
    alignItems: "center",
    color: "#666",
    fontSize: 14,
    marginTop: 4,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  subTextBold: {
    display: "flex",
    alignItems: "center",
    color: "#666",
    fontSize: 14,
    marginTop: 4,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: 600,
  },
  icon: {
    height: 16,
    width: 16,
    marginRight: "10px"
  },
  imgIcon: {
    height: 24,
    width: 24,
  },
  section: {
    marginTop: 16,
    marginBottom: 16,
  },
  paymentBox: {
    borderRadius: 8,
    // textAlign: "center",
  },
  outstanding: {
    fontSize: 14,
    color: "#666",
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 16,
    gap: 8,
  },
  cancelButton: {
    backgroundColor: "#FFEBEB",
    color: "#D32F2F",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "#FFCDD2",
    },
  },
  actionsButton: {
    backgroundColor: "#01205c",
    color: "#FFF",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "#1b3e82",
    },
  },
  modifyButton: {
    backgroundColor: "#E3F2FD",
    color: "#1976D2",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "#BBDEFB",
    },
  },
  viewButton: {
    fontWeight: 600,
  },
}));

export const DetailsCard = ({ data, handleClose = () => null, actionBtnClick = () => null }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const rawRoleName = localStorage.getItem("RoleName");
  const roleName = !Boolean(rawRoleName === "null" ? null : rawRoleName);
  const userRole = jwtDecode(localStorage.getItem("UserInfo"))?.userrole;
  const Ispatient = userRole.toLowerCase().includes("patient")
  // Helper to format Unix timestamp to readable date
  const formatDate = (timestamp) => {
    if (!timestamp) return "Date not available";
    const date = new Date(timestamp * 1000);
    return date.toLocaleString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const navigateToDashboard = () => {
    navigate(AppRoutes.PatientDashBoard, {
      state: {
        "unregister": true,
        "patientId": data?.inputdoc?.patientid,
        "personId": data?.inputdoc?.personid
      }
    })
  };

  console.log("card", roleName, Ispatient);

  return (
    <Card className={classes.card}>

      {roleName && Ispatient ? (
        // if the user is patient then show the doctor card
        <Grid container spacing={2}>
          <Grid
            container
            item
            justifyContent="center"
            alignItems="stretch"
            xs={3}
          >
            <Avatar
              src={data?.doctordetails?.docimage?.url ?? man} // Use fallback avatar if no image is provided 
              alt="Profile Picture"
              className={classes.avatar}
            />
          </Grid>
          <Grid item justifyContent="space-around" xs={8}>
            <Typography variant="h5" className={classes.header}>
              {data?.resourcecode?.name ?? "No Name Available"}
            </Typography>
            <div style={{ display: "inline-flex", gap: "10px" }}>
              {/* <Typography className={classes.subText}>
                <img src={astriks} alt="ID" className={classes.icon} />
                {data.field2 ?? "Not Available"}
              </Typography> */}
              <Typography className={classes.subText} >
                <img src={idcard} alt="Age" className={classes.icon} />
                {`${data?.doctordetails?.age} Yrs` ?? "Age Unknown"}
              </Typography>
              <Typography className={classes.subText}>
                <img
                  src={calenderoutlined}
                  alt="Appointment No"
                  className={classes.icon}
                />
                {data.inputdoc?.appno ?? "N/A"}
              </Typography>
            </div>
            <Typography className={classes.subText}>
              <img src={timehistory} alt="Date" className={classes.icon} />
              {formatDate(data?.date)}
            </Typography>
            <Typography
              className={classes.subText}
            >
              <img src={man} alt="Doctor" className={classes.icon} />
              {data?.doctordetails?.speciality.map((item) => `${item}  `) ?? "No Speciality Available"}
            </Typography>
            <Typography
              className={classes.subText}
            >
              <img src={facility} alt="Doctor" className={classes.icon} />
              {data?.doctordetails?.facility ?? "No Facility Available"}
            </Typography>
          </Grid>
          <Grid item xs={1} alignItems="center" >
            <IconButton size="small" onClick={handleClose}>
              <CloseIcon style={{ color: "black", }} />
            </IconButton>
          </Grid>
        </Grid>
      ) : (
        // if the user is doctor or nurse or frontdesker then show the patient card
        <Grid container spacing={2}>
          <Grid
            container
            item
            justifyContent="center"
            alignItems="stretch"
            xs={3}
          >
            <Avatar
              src={data.field9 ?? man} // Use fallback avatar if no image is provided
              alt="Profile Picture"
              className={classes.avatar}
            />
            <Typography
              color="primary"
              variant="body2"
              component={"a"}
              style={{
                cursor: "pointer",
                marginTop: 4,
                display: "block",
                textDecoration: "underline",
                color: "#225AFB",
                fontWeight: "bold",
              }}
              onClick={navigateToDashboard}
            >
              Go to Profile
            </Typography>
          </Grid>
          <Grid item justifyContent="space-around" xs={8}>
            <Typography variant="h5" className={classes.header}>
              {data.field1 ?? "No Name Available"}
            </Typography>
            <div style={{ display: "inline-flex", gap: "10px" }}>
              <Typography className={classes.subText}>
                <img src={astriks} alt="ID" className={classes.icon} />
                {data.field2 ?? "Not Available"}
              </Typography>
              <Typography className={classes.subText}>
                <img src={idcard} alt="Age" className={classes.icon} />
                {data.field3 ?? "Age Unknown"}
              </Typography>
              <Typography className={classes.subText}>
                <img
                  src={calenderoutlined}
                  alt="Appointment No"
                  className={classes.icon}
                />
                {data.inputdoc?.appno ?? "N/A"}
              </Typography>
            </div>         
            <Typography className={classes.subText}>
              <img src={man} alt="Doctor" className={classes.icon} />
              {data?.resourcecode?.name ?? "Not Assigned"}
            </Typography>
            <Typography className={classes.subTextBold} >
              <img src={timehistory} alt="Date" className={classes.icon} />
              {formatDate(data.date)}
            </Typography>
            <Typography className={classes.subText} >
              <img src={calenderoutlined} alt="Date" className={classes.icon} />
            {data?.queueMetaData?.states?.states}
            </Typography>
          </Grid>
          <Grid item xs={1} alignItems="center">
            <IconButton onClick={handleClose}>
              <CloseIcon style={{ color: "black" }} />
            </IconButton>
          </Grid>
        </Grid>
      )}

      <Divider className={classes.section} />
      {(roleName && Ispatient) ? (
        <></>
      ) : (
        <>
          {/* <Grid
            container
            justifyContent="space-between"
            style={{ padding: "10px" }}
          >
            <Grid
              container
              xs={6}
              spacing={2}
              alignItems="center"
              style={{ backgroundColor: "#F0F8FF", padding: "15px 10px" }}
            >
              <Grid xs={3} item>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "100%",
                    backgroundColor: "#D8EEFF",
                  }}
                >
                  <img
                    src={healthpay}
                    alt="healthpay"
                    className={classes.imgIcon}
                  />
                </Grid>
              </Grid>
              <Grid item justifyContent="center">
                <div className={classes.paymentBox}>
                  <Typography variant="body1">
                    {data.field12 ?? "Shift"}
                  </Typography>
                  <Typography className={classes.outstanding}>
                    {data.consultationmode?.display ?? "Not Specified"}
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              xs={6}
              spacing={2}
              alignItems="center"
              style={{ backgroundColor: "#EBFFEC", padding: "15px 10px" }}
            >
              <Grid item xs={3}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "100%",
                    backgroundColor: "#BCFFC0",
                  }}
                >
                  <img src={wallet} alt="wallet" className={classes.imgIcon} />
                </Grid>
              </Grid>
              <Grid item justifyContent="center">
                <div className={classes.paymentBox}>
                  <Typography variant="body1">Outstanding</Typography>
                  <Typography className={classes.outstanding}>
                    {`RM ${data.field5 ?? "0.00"}`}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid> */}
          <div className={classes.actions}>
            {data.action?.map((action, index) => (
              <Button
                key={index}
                size="small"
                className={
                //  classes.cancelButton
                classes.actionsButton
                  // : action.action === "CONSULTATION IN"
                  // classes.modifyButton
                  // classes.viewButton
                }
                fullWidth
                onClick={() => {
                  actionBtnClick(action);
                }}
              >
                {action.action ?? "Unknown Action"}
              </Button>
            ))}
          </div>
        </>
      )}

    </Card>
  );
};


