import { Avatar, Grid, Paper, Typography, useTheme } from '@mui/material'
import React from 'react'

const HeaderCard = ({ avatar, title, subTitle ,splitBorder=false }) => {
    const theme = useTheme();
    return (
        <Paper elevation={0} sx={{ borderRadius: 0 }}>
            <Grid container justifyContent={"space-between"} alignItems={"center"} p={1} borderRight={splitBorder ?`2px solid ${theme?.palette?.lineBorderFill}`:{}}>
                <Grid item >
                    <Grid container alignItems={"center"} gap={2}>
                        <Grid item>
                            <Avatar alt={avatar ? avatar : ""} variant='rounded' src="/static/images/avatar/1.jpg" />
                        </Grid>
                        <Grid item >
                            <Grid container direction="column" gap={1}>
                                <Typography variant="head2" fontWeight={600} color={theme?.palette?.primaryColor} textTransform={"capitalize"} >{title ? title : "No Title Provided"}</Typography>
                                <Typography variant="content1" >{subTitle ? subTitle : "No Sub Title Provided"}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item >

                </Grid>
            </Grid></Paper>
    )
}

export default HeaderCard;