import { Grid, makeStyles } from "@material-ui/core";
import { TopNavBarAvc } from "../../../components/avc";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { ProgressSideNav } from "../../../components/avc";
import { ParticipateSide } from "../../../components/avc/participateSide";
import { TopStepperBar } from "../../../components/avc";
import  VideoCall  from "../VideoCall";
import Avcbg from "../../../assets/img/avc_top_progress_bg.png";
import  withAllContexts  from "../../../hoc/withAllContexts";
import { AVCShowContext } from "../../../context";
import { useDispatch, useSelector } from "react-redux";
import  actions  from "../../../redux/actions";
import { AppRoutes } from "../../../router/routes";
import { useNavigate,useLocation } from "react-router-dom";

import {
	Acceptance,
	LandingAVC,
	WaitingLobbyAVC,
	UserProofAVC,
	VitalAvcPage,
	EndConsult,
} from "../../../components/avc";
import { MeetingRoom } from "@material-ui/icons";
import { DrawerMeetingContext } from "../../../context";

const list = { avcList:{
	roles: [
		{
			name: "Front Office",
			vist: true,
			active: true,
			child: [
				{ name: "Virtual waiting room", active: true },
				{ name: "Receptionist", active: false },
				// { name: "Visit Registration", active: false },
				// { name: "Receptionist", active: false  },
			],
			steps: [
				{ name: "Welcome", active: true, vist: false },
				{ name: "Consent Acceptance", active: false, vist: false },
				{ name: "Documents upload", active: false, vist: false },
				{ name: "Waiting Lobby", active: false, vist: false },
			],
		},
		{
			name: "Nurse Station",
			vist: false,
			active: false,
			child: [
				{ name: "Virtual waiting room", active: true },
				{ name: "Nurse", active: false },
				// { name: "Visit Registration", active: false },
				// { name: "Mr. Ramesh (Receptionist)", active: false },
			],
			steps: [
				{ name: "Welcome", active: true, vist: false },
				{ name: "Vital Details update", active: false, vist: false },
				{ name: "Waiting Lobby", active: false, vist: false },
			],
		},
		{
			name: "Doctor Consultaion",
			vist: false,
			active: false,
			child: [
				{ name: "Virtual waiting room", active: true },
				{ name: "Dr. Ramesh (Receptionist)", active: false },
				{ name: "Visit Registration", active: false },
				{ name: "Mr. Ramesh (Receptionist)", active: false },
			],
			steps: [
				{ name: "Waiting Lobby", active: true, vist: false },
				// { name: "Doctor Consultation", active: false, vist: false },
				{ name: "Consultation Completed", active: false, vist: false },
			],
		},
	],
}
};

// import {LandingAVC,WaitingLobbyAVC,UserProofAVC,} from "../../../components";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "#F2F4FC",
	},
	grid: {
		backgroundColor: "#fff",
		marginBottom: "20px",
		borderRadius: "8px",
		padding: "0px",
		boxShadow: "2px 10px 22px #092F5A17",
		paddingBottom: "1px",
	},
	topProgressBar: {
		padding: "20px 32px",
		backgroundColor: "white",
		marginTop: "1.6%",
		marginBottom: "1.6%",
		borderRadius: 6,
		width: "100%",
		boxShadow: "2px 10px 22px #092F5A17",
		backgroundImage: "url(" + Avcbg + ")",
	},
}));

const MainViewAVC = (props) => {
	
	const classes = useStyles();
	const dispatch = useDispatch();
	const location = useLocation();
	const appId = location.state?.id;
	const AVCShowList = React.useContext(AVCShowContext);
	
	
	const { loading, data } = useSelector(
		(state) => state?.avcMeetApiSlice?.avc_info
	);
	const { avcList, setAVCList } = AVCShowList;
	const [sideClick, setsideClick] = React.useState(false);
	const [Call, setCall] = React.useState(false);
	const AVCMeeting = React.useContext(DrawerMeetingContext);
	const [seeMeetingDrawer, setMeeting_Drawer] = React.useState(false);
	let MeetingDrawer = !seeMeetingDrawer;

	const navigate = useNavigate();
	// const [mainObj,setMainObj] = React.useState(mainObj);
	const funSideClick = () => {
		setsideClick(!sideClick);
	};

	const [participantsList, setParticipantsList] = useState(null);

	const fetchInfo = async (id) => {
		
		const data = await dispatch(actions.AVC_LOGIN({ id: id }));
		setAVCList(list);
		getParticipantList(data?.payload?.data);
		
	};

	const getParticipantList = (data) => {
		let list = [
			{
				name: data?.patient?.name ?? "",
				participant: "You",
			},
			{
				name: data?.doctor?.name ?? "",
				participant: data?.doctor?.type ?? "doctor",
			},
		];
		setParticipantsList(list);
	};

	useEffect(() => {
		// AVC_LOGIN
		//console.log("before useeffect");
		//let id = props?.location?.state?.id;
		//console.log(" After useeffect "+appId);
		if (appId) {
			fetchInfo(appId);
			// await setAVCList({avcList: list});
			// await getParticipantList(data_info);
		} else {
			// props.history.push({
			// 	pathname: Routes.avcLogin,
			// })
			alert("data not found");
		}
	}, []);

	React.useEffect(() => {
		
		let id = appId;
		
		if (!MeetingDrawer && seeMeetingDrawer) {
			//debugger
			let uri = data;
			AVCMeeting.setMeetingDrawer({
				open: true,
				RoomName: uri?.dataJson?.avc?.name,
				jwt: uri?.dataJson?.avc?.moderatorUrl?.split("jwt=")?.[1],
				widthAdjState: "side",
				user_name: uri?.patient?.name,
				domain: uri?.dataJson?.avc?.moderatorUrl?.split("/")?.[2]
			});
		}
	}, [MeetingDrawer, seeMeetingDrawer]);

	const exitConsultation = () => {
		document.getElementById("jitsi-iframe").getElementsByTagName("iframe")[0].src="" 
		AVCMeeting.setMeetingDrawer({
			open: false,
			RoomName: null,
			jwt: null,
			widthAdjState: "side",
			user_name: null,
		});
		navigate(AppRoutes.appointmentsListingPage);
	};
	const switchScreen = () => {
		

		let index = avcList?.avcList?.roles
			?.filter((_) => _.active)?.[0]
			?.steps?.findIndex((val, i) => val?.active);
		let name = avcList?.avcList?.roles?.filter((_) => _.active)?.[0]?.name;
		let currentStep = avcList?.avcList?.roles
			?.filter((_) => _.active)?.[0]
			?.steps?.filter((val, i) => val?.active)[0].name;
		if (name === "Front Office" && currentStep === "Waiting Lobby") {
			if (MeetingDrawer && !seeMeetingDrawer) {
				MeetingDrawer = true;
				setMeeting_Drawer(true);
			}
		}
		switch (name) {
			case "Front Office":
				return frontOfficeComponents(index, name);
			case "Nurse Station":
				return nurseStationComponents(index, name);
			case "Doctor Consultaion":
				return doctorConsultaionComponents(index, name);
		}
	};

	const frontOfficeComponents = (index, name) => {
		switch (index) {
			case 0:
				return (
					<LandingAVC
						name={"Hi " + data?.patient?.name + ","}
						title="(Your joining has been notified to our receptionist)"
						desc="Front Desk Staff will join the call soon, In the meantime We request you to complete the following basic formalities"
						proceed={() =>
							proceed(
								avcList?.avcList?.roles?.filter((_) => _.active)?.[0]
									?.steps,
								avcList?.avcList?.roles?.findIndex((_) => _.active),
								index
							)
						}
					/>
				);
			case 1:
				return (
					<Acceptance
						proceed={() =>
							proceed(
								avcList?.avcList?.roles?.filter((_) => _.active)?.[0]
									?.steps,
								avcList?.avcList?.roles?.findIndex((_) => _.active),
								index
							)
						}
					/>
				);
			case 2:
				return (
					<UserProofAVC
						proceed={() =>
							proceed(
								avcList?.avcList?.roles?.filter((_) => _.active)?.[0]
									?.steps,
								avcList?.avcList?.roles?.findIndex((_) => _.active),
								index
							)
						}
					/>
				);
			case 3:
				return (
					<WaitingLobbyAVC
						name={"Hi " + data?.patient?.name + ","}
						desc="Your formalities are completed, wait for our Front Office Staff to initiate the call"
						tokenNumber={data?.dataJson?.id ?? 0}
						moveNextRole={() =>
							moveNextRole(
								avcList?.avcList?.roles?.findIndex((_) => _.active)
							)
						}
					/>
				);
		}
	};

	const nurseStationComponents = (index, name) => {
		switch (index) {
			case 0:
				return (
					<LandingAVC
						name={"Hi " + data?.patient?.name + ","}
						title="(You have been moved to nurse queue)"
						desc="We would request you to update your vitals details for the consultation, before our nurse join"
						proceed={() =>
							proceed(
								avcList?.avcList?.roles?.filter((_) => _.active)?.[0]
									?.steps,
								avcList?.avcList?.roles?.findIndex((_) => _.active),
								index
							)
						}
					/>
				);
			case 1:
				return (
					<VitalAvcPage
						updateVitals={() =>
							proceed(
								avcList?.avcList?.roles?.filter((_) => _.active)?.[0]
									?.steps,
								avcList?.avcList?.roles?.findIndex((_) => _.active),
								index
							)
						}
					/>
				);
			case 2:
				return (
					<WaitingLobbyAVC
						name={"Hi " + data?.patient?.name + ","}
						desc="Secondary Information will be displayed here, While waiting for FOS to initiate the call"
						tokenNumber={data?.dataJson?.id ?? 0}
						moveNextRole={() =>
							moveNextRole(
								avcList?.avcList?.roles?.findIndex((_) => _.active)
							)
						}
					/>
				);
		}
	};

	const doctorConsultaionComponents = (index, name) => {
		switch (index) {
			case 0:
				return (
					<WaitingLobbyAVC
						name={"Hi " + data?.patient?.name + ","}
						desc="Secondary Information will be displayed here, While waiting for Doctor to initiate the call"
						tokenNumber={data?.dataJson?.id ?? 0}
						moveNextRole={() =>
							proceed(
								avcList?.avcList?.roles?.filter((_) => _.active)?.[0]
									?.steps,
								avcList?.avcList?.roles?.findIndex((_) => _.active),
								index
							)
						}
					/>
				);
			// case 1:
			// 	return (
			// 		<VitalAvcPage
			// 			updateVitals={() =>
			// 				proceed(
			// 					avcList?.avcList?.roles?.filter((_) => _.active)?.[0]
			// 						?.steps,
			// 					avcList?.avcList?.roles?.findIndex((_) => _.active),
			// 					index
			// 				)
			// 			}
			// 		/>
			// 	);
			case 1:
				return (
					<EndConsult
						name={"Hi " + data?.patient?.name + ","}
						doctorName={data?.doctor?.name}
						exitConsultationBtn={() => exitConsultation()}
					/>
				);
		}
	};

	const proceed = (steps, parentIndex, index) => {
		steps[index].vist = true;
		steps[index].active = false;
		if (index + 1 < steps?.length) {
			steps[index + 1]["active"] = true;
		}

		setAVCList({ avcList: avcList.avcList });
	};

	const moveNextRole = (index) => {
		avcList.avcList.roles[index]["vist"] = true;
		avcList.avcList.roles[index]["active"] = false;
		if (index + 1 < avcList.avcList.roles?.length) {
			avcList.avcList.roles[index + 1]["vist"] = true;
			avcList.avcList.roles[index + 1]["active"] = true;
		}
		setAVCList({ avcList: avcList.avcList });
	};

	return (
		<div>
			{Call ? (
				<VideoCall />
			) : (
				<div className={classes.root}>
					<TopNavBarAvc support />
					<Grid container>
						<Grid item xs={sideClick ? 1 : 2}>
							<ProgressSideNav
								sidekick={sideClick}
								funSideKick={funSideClick}
							/>
						</Grid>
						<Grid item xs={sideClick ? 10 : 9}>
							<Grid item className={classes.topProgressBar}>
								<TopStepperBar
									steps={
										avcList?.avcList?.roles?.filter((_) => _.active)?.[0]
											?.steps
									}
								/>
							</Grid>
							<Grid item className={classes.grid}>
								{/* <LandingAVC /> */}
								{switchScreen()}
							</Grid>
						</Grid>
						<Grid item xs={1}>
							<ParticipateSide participants={participantsList} />
						</Grid>
					</Grid>
				</div>
			)}
		</div>
	);
};

export default withAllContexts(MainViewAVC);
