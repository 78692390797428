import React from "react";
import theme from "../../theme";
export const AllergyIcon = (props) => {
  let width = props.width ? props.width : "40.8";
  let height = props.height ? props.height : "40.8";
  let color = props.color ? props.color : theme.palette.secondary.main;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16.192 19.2"
    >
      <g id="allergy" transform="translate(-10.172 -4.9)">
        <path
          id="Path_92686"
          data-name="Path 92686"
          d="M25.476,13.055a8.418,8.418,0,0,0-1.55-5.5A7.05,7.05,0,0,0,18.308,5h-.081A7.122,7.122,0,0,0,12.59,7.552a8.418,8.418,0,0,0-1.55,5.5,2.308,2.308,0,0,0-.7,1.265,4.268,4.268,0,0,0,.141,2.024,2.763,2.763,0,0,0,1.47,1.919,10.534,10.534,0,0,0,3.826,4.956,4.385,4.385,0,0,0,4.993,0,10.534,10.534,0,0,0,3.826-4.956,2.81,2.81,0,0,0,1.47-1.919,4.256,4.256,0,0,0,.141-2.024A2.387,2.387,0,0,0,25.476,13.055Zm-.151,2.931c-.262.991-.846,1.181-1.168,1.181-.02,0-.06.021-.06.042a11.346,11.346,0,0,1-4,5.388,3.3,3.3,0,0,1-1.832.569,3.248,3.248,0,0,1-1.832-.569,11.346,11.346,0,0,1-4-5.388c0-.021-.04-.042-.06-.042-.3,0-.906-.211-1.168-1.181-.282-1.075-.262-1.9.644-2.235.02,0,.04-.042.04-.063-.1-.633-.372-3.019,6.413-3.294,6.785.274,6.434,2.64,6.333,3.294,0,.021.02.063.04.063C25.587,14.089,25.607,14.911,25.325,15.987Z"
          transform="translate(0)"
          fill="#ba637a"
          stroke="#ba637a"
          stroke-width="0.2"
        />
        <path
          id="Path_92687"
          data-name="Path 92687"
          d="M44.473,65.2a2.588,2.588,0,0,0-1.691-.6,2.474,2.474,0,0,0-1.792.711.3.3,0,0,0-.02.383l.181.273a.156.156,0,0,0,.262.027,2.009,2.009,0,0,1,1.389-.52,2.144,2.144,0,0,1,1.309.438.16.16,0,0,0,.262-.055l.161-.3A.3.3,0,0,0,44.473,65.2Z"
          transform="translate(-24.474 -47.364)"
          fill="#ba637a"
        />
        <path
          id="Path_92688"
          data-name="Path 92688"
          d="M32.34,41.554l-.161-.218a.131.131,0,0,0-.221,0,1.775,1.775,0,0,1-1.107.572,1.679,1.679,0,0,1-1.107-.218c-.081-.055-.161-.027-.2.082l-.121.273a.265.265,0,0,0,.06.3,2.137,2.137,0,0,0,1.43.327,2.25,2.25,0,0,0,1.43-.763A.368.368,0,0,0,32.34,41.554Z"
          transform="translate(-15.28 -28.625)"
          fill="#ba637a"
        />
        <path
          id="Path_92689"
          data-name="Path 92689"
          d="M58.639,41.779c-.04-.083-.141-.138-.2-.083a1.662,1.662,0,0,1-1.107.221,1.768,1.768,0,0,1-1.107-.58.13.13,0,0,0-.221,0l-.181.221a.325.325,0,0,0,0,.331,2.17,2.17,0,0,0,1.43.773,1.975,1.975,0,0,0,1.43-.331.27.27,0,0,0,.06-.3Z"
          transform="translate(-36.345 -28.625)"
          fill="#ba637a"
        />
        <path
          id="Path_92690"
          data-name="Path 92690"
          d="M29.024,56.6a.549.549,0,1,0,.523.548A.52.52,0,0,0,29.024,56.6Z"
          transform="translate(-14.561 -40.719)"
          fill="#ba637a"
        />
        <path
          id="Path_92692"
          data-name="Path 92692"
          d="M63.123,54.3a.549.549,0,1,0,.524.548A.533.533,0,0,0,63.123,54.3Z"
          transform="translate(-41.795 -38.904)"
          fill="#ba637a"
        />
        <path
          id="Path_92694"
          data-name="Path 92694"
          d="M52.824,77.2a.549.549,0,1,0,.524.548A.546.546,0,0,0,52.824,77.2Z"
          transform="translate(-33.569 -56.975)"
          fill="#ba637a"
        />
      </g>
    </svg>
  );
};
