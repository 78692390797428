import { Div, Text, Divider } from "qdm-component-library";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Instructions from "../../../../assets/img/svg/Instructions.svg";
import clsx from "clsx";
import { useState } from "react";
import { useStyles } from "./styles";
import { makeStyles, withStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

const AppointmentInstructions = (props) => {
  const { parent_id } = props;
  const classes = useStyles();

  // State
  const [state, setState] = useState({
    expanded: true,
    expanded2: true,
    expanded1: true,
  });

  const selector = useSelector(
    (state) => state?.appointment?.generalInstruction?.data?.[0] ?? {}
  );
  // const selector = {
  //   data: {
  //     orgContact: "9629453453",
  //     generalInstruction: [
  //       {
  //         fcode: "AMInstructions/10000",
  //         description:
  //           "Please bring referral letter while coming for consultation",
  //       },
  //       {
  //         fcode: "AMInstructions/10002",
  //         description: "Please fast for 8PM previous night",
  //       },
  //     ],
  //     appointmentInstruction: [
  //       {
  //         AppInstructionCriteria: "CodingMaster/10789",
  //         AppInstructions: "Please fast for 8PM previous night",
  //         Appcode: "AMInstructions/10002",
  //       },
  //     ],
  //   },
  // };
  console.log({ selector });
  // Functions
  const handleExpandClick = () => {
    setState({
      expanded1: !state.expanded1,
    });
  };
  const handleExpandClick2 = () => {
    setState({
      expanded2: !state.expanded2,
    });
  };

  return (
    <Div id="doctor_listing_BillSummary_div" style={{ marginBottom: "8px" }}>
      <div id={`${parent_id}_appointment_instruction_parent_div`}>
        <Card
          id={`${parent_id}_appointment_instruction_parent_card`}
          style={{
            border: "1.5px solid #E0E0E0",
            borderRadius: 8,
            boxShadow: "none",
          }}
        >
          <CardActions
            id={`${parent_id}_appointment_instruction_parent_cardactions`}
            onClick={handleExpandClick}
            disableSpacing
            style={{
              padding: 0,
              paddingLeft: 16,
              paddingBottom: 0,
              cursor: "pointer",
            }}
          >
            <img
              id={`${parent_id}_appointment_instruction_img`}
              alt="Instructions"
              src={Instructions}
              style={{ marginRight: 12 }}
            />
            <Text
              id={`${parent_id}_appointment_instruction_General_text`}
              key={"0"}
              name="General Instructions"
              style={{ fontSize: "16px", color: "#101010", letterSpacing: 0 }}
              className={"pc_semibold"}
            />

            <IconButton
              id={`${parent_id}_appointment_instruction_General_iconbutton`}
              className={clsx(classes.expand, {
                [classes.expandOpen]: state.expanded1,
              })}
              aria-expanded={state.expanded1}
              aria-label="show more"
            >
              <ExpandMoreIcon
                id={`${parent_id}_appointment_instruction_show_expandmoreicon`}
              />
            </IconButton>
          </CardActions>
          <Collapse
            id={`${parent_id}_appointment_instruction_collapse`}
            className={classes.body}
            in={state.expanded1}
            timeout="auto"
            unmountOnExit
          >
            <Div
              className="qdm"
              style={{ margin: "0px" }}
              id={`${parent_id}_appointment_instruction_div`}
            >
              <Divider
                id={`${parent_id}_appointment_instruction_bottom_div`}
                textWidth="50"
                textColor="#000"
                borderColor="lightgray"
                borderWidth="1"
                orientation="horizontal"
                variant="middle"
                style={{ margin: "0px", marginBottom: 12 }}
              ></Divider>
              {props?.specialInstructions?.generalinstruction?.map((d, key) => (
                <Div
                  id={`${parent_id}_${key}_appointment_instruction_text_div`}
                  key={key}
                  className="qdm"
                  style={{
                    marginBottom:
                      key < selector?.generalInstruction?.length - 1 ? 8 : 0,
                  }}
                >
                  <Text
                    id={`${parent_id}_${key}_appointment_instruction_text`}
                    inLineStyles={{ fontSize: "14px", lineHeight: 1.5 }}
                    className={"pc_regular"}
                    name={`${key + 1}. ${d?.description ?? ""}`}
                  />
                </Div>
              ))}
            </Div>
          </Collapse>
        </Card>

        <Card
          id={`${parent_id}_appointment_instruction_sub_card`}
          style={{
            marginTop: "8px",
            boxShadow: "none",
            border: "1.5px solid #E0E0E0",
            borderRadius: 8,
          }}
        >
          <CardActions
            id={`${parent_id}_appointment_instruction_sub_cardactions`}
            onClick={handleExpandClick2}
            disableSpacing
            style={{
              padding: 0,
              paddingLeft: 16,
              paddingBottom: 0,
              cursor: "pointer",
            }}
          >
            <img
              id={`${parent_id}_appointment_instruction_img`}
              alt="Instructions"
              src={Instructions}
              style={{ marginRight: 12 }}
            />
            <Text
              id={`${parent_id}_appointment_instruction_text`}
              key={"0"}
              name="Appointment Instructions"
              style={{ fontSize: "16px", color: "#101010", letterSpacing: 0 }}
              className={"pc_semibold"}
            />

            <IconButton
              id={`${parent_id}_appointment_instruction_iconbutton`}
              className={clsx(classes.expand, {
                [classes.expandOpen]: state.expanded2,
              })}
              aria-expanded={state.expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon
                id={`${parent_id}_appointment_instruction_expandmoreicon`}
              />
            </IconButton>
          </CardActions>
          <Collapse
            id={`${parent_id}_appointment_instruction_Appointment_collapse`}
            className={classes.body}
            in={state.expanded2}
            timeout="auto"
            unmountOnExit
          >
            <Div
              id={`${parent_id}_appointment_instruction_Appointment_div`}
              className="qdm"
              style={{ margin: "0px" }}
            >
              <Divider
                id={`${parent_id}_appointment_instruction_Appointment_bottom_div`}
                textWidth="50"
                textColor="#000"
                borderColor="lightgray"
                borderWidth="1"
                orientation="horizontal"
                variant="middle"
                style={{ margin: "0px", marginBottom: 12 }}
              ></Divider>
              {props.specialInstructions?.specialapptinstructions?.length > 0 &&
                props.specialInstructions?.specialapptinstructions.map((d, key) => (
                  <Div
                    id={`${parent_id}_${key}_appointment_instruction_Appointment_bottom_div`}
                    key={key}
                    className="qdm"
                    style={{
                      marginBottom:
                        key < selector?.appointmentInstruction?.length - 1
                          ? 8
                          : 0,
                    }}
                  >
                    <Text
                      id={`${parent_id}_${key}_appointment_instruction_Appointment_text`}
                      inLineStyles={{ fontSize: "14px", lineHeight: 1.5 }}
                      className={"pc_regular"}
                      name={`${key + 1}. ${d?.appinstructions || d?.description || ""}`}
                    />
                  </Div>
                ))}
            </Div>
          </Collapse>
        </Card>
      </div>
    </Div>
  );
};

export default AppointmentInstructions;
