import { Checkbox, Collapse, Grid, Typography, useTheme } from '@mui/material'
import React from 'react'
import EditIcon from '../../../assets/svgComponents/editIcon';
import LikeIcon from '../../../assets/svgComponents/likeIcon';
import LikeFilledIcon from '../../../assets/svgComponents/likeFillIcon';
import ArrowIcon from '../../../assets/svgComponents/arrow';

const SingleLineOrderCard = ({orderTitle, orderDescription,evidence,reference}) => {
    const theme = useTheme();
    const [feedback, setFeedback] = React.useState("");
    const [expand,setExpand] = React.useState(false);
    return (
        <Grid container justifyContent={"space-between"} alignItems={"flex-start"} px={2} xs={12}
            // bgcolor={expand ? theme?.palette?.differentialBackground : "transparent"}
        >
            <Grid item xs={10} >
                <Grid container alignItems={"center"} gap={1} noWrap >
                    <Grid item>
                        <Checkbox size="small" sx={{
                            '&.Mui-checked': {
                                color: theme?.palette?.secondaryButtonColor,
                            },
                        }} />
                    </Grid>
                    <Grid item >
                        <p style={{whiteSpace:"nowrap", overflow:"hidden", textOverflow:"ellipsis",maxWidth:"600px",marginBottom:"0px"}}>
                        <Typography variant="head2" color={theme?.palette?.secondaryButtonColor}  >{orderTitle}</Typography>
                        </p>
                    </Grid>
                    <Grid item>
                        <Typography variant="content1" color={theme?.palette?.secondaryText}>{orderDescription}</Typography>
                    </Grid>
                </Grid>
                <Collapse in={expand} >
                    <Grid container p={1} >
                        {evidence ? (
                            <Grid container  >
                                <Grid item container p={0.5}>
                                    <Typography variant="head2" color={theme?.palette?.tertieryText} fontWeight={600}>🔍 Evidence:</Typography>
                                </Grid>
                                <Grid item pl={2}>
                                    <Typography variant="head2" color={theme?.palette?.tertieryText} > {evidence}</Typography>
                                </Grid>
                            </Grid>
                        ) : (<> </>)}
                        {reference ? (
                            <Grid container pt={2} >
                                <Grid item container p={0.5}>
                                    <Typography variant="head2"color={theme?.palette?.tertieryText} fontWeight={600}>📒 Reference:</Typography>
                                </Grid>
                                <Grid item pl={2}>
                                    <Typography variant="head2" color={theme?.palette?.tertieryText} > {reference}</Typography>
                                </Grid>
                            </Grid>
                        ) : (<> </>)}
                    </Grid>
                </Collapse>
            </Grid>
            <Grid item  xs={2}>
                <Grid container alignItems={"center"} justifyContent={"flex-end"} gap={2} py={2}>
                    <Grid item>
                        {/* <Typography variant="content1" color={theme?.palette?.secondaryText}>On 07 Sep, 2023 - Dr. Shanthini P</Typography> */}
                    </Grid>
                    {evidence || reference ? (
                        <Grid item onClick={() => setExpand(!expand)} sx={{cursor:"pointer"}}>
                            <ArrowIcon direction={expand ? "up" : "down"}  />
                        </Grid>
                    ):(<> </>)}
                    <Grid item>
                        <EditIcon />
                    </Grid>
                    {feedback == "like" ? (
                        <Grid item sx={{cursor:"pointer"}} onClick={() => setFeedback("")}>
                            <LikeFilledIcon color={theme?.palette?.Success}   />
                        </Grid>
                    ) : (
                        <Grid item onClick={() => setFeedback("like")}  sx={{cursor:"pointer"}}  >
                            <LikeIcon/>
                        </Grid>
                    )}
                    {feedback == "dislike" ? (
                        <Grid item sx={{cursor:"pointer"}} onClick={() => setFeedback("")}>
                            <LikeFilledIcon direction="down" color={theme?.palette?.Error}   />
                        </Grid>

                    ) : (
                        <Grid item  onClick={() => setFeedback("dislike")}  sx={{cursor:"pointer"}}>
                            <LikeIcon direction="down"  />
                        </Grid>

                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SingleLineOrderCard