import * as React from "react"

function DotIcon({height,width,color,...props}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width?width:"8px"}
      height={height?height:"8px"}
      viewBox="0 0 8 9"
      fill="none"
      {...props}
    >
      <circle cx={4} cy={4.5} r={4} fill={color?color:"#FF9500"} />
    </svg>
  )
}

export default DotIcon;