import { Chip, Grid, Paper, Typography, useTheme } from "@mui/material";
import React from "react";
import ArrowIcon from "../../../assets/svgComponents/arrow";
import InfoAllergy from "./infoAllergy";
import FilterIcon from "../../../assets/svgComponents/filterIcon";
import AllergyHeaderIcon from "../../../assets/svgComponents/allergyHeaderIcon";
import DiagnosisInfoIcon from "../../../assets/svgComponents/diagnosisInfoIcon";
import InfoSingleMedication from "./infoSingelMedication";
import TabletsIcon from "../../../assets/svgComponents/tabletsIcon";


const InfoMedications = ({ data }) => {
  const theme = useTheme();
  return (
    <Paper elevation={0} sx={{ borderRadius: 3 }} variant="outlined">
      {/* -------------------------------------------------------------------------- */
      /*                                   Header                                   */
      /* -------------------------------------------------------------------------- */}
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={1}
        p={2}
        borderBottom={`1px solid ${theme?.palette?.lineBorderFill}`}
      >
        <Grid item>
          <Grid container alignItems={"center"} gap={1}>
            <TabletsIcon height={"24px"} width={"24px"} color={theme?.palette?.secondaryButtonColor} />
            <Typography variant="head2" color={theme?.palette?.primaryText}>
              Current Medications
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container alignItems={"center"} gap={1}>
            <Chip
              label={
                <Typography
                  variant="content1"
                  color={theme?.palette?.tertieryText}
                >
                  Filter
                </Typography>
              }
              sx={{ paddingX: 1 }}
              icon={<FilterIcon width={16} height={16} color={theme.palette?.secondaryText} />}
            />
            <ArrowIcon direction="down" color="black" />
          </Grid>
        </Grid>
      </Grid>
      {/* -------------------------------------------------------------------------- */
      /*                                   Header                                   */
      /* -------------------------------------------------------------------------- */}
      {data?.length !== 0 ? (
        <Grid container py={1.5}>
          {data?.map((d, index) => (
            <InfoSingleMedication data={d} />
          ))}
        </Grid>
      ) : (
        <Grid container p={2} justifyContent={"center"} alignItems={"center"}>
          {/* -------------------------------------------------------------------------- */
          /*                                   No Data                                   */
          /* -------------------------------------------------------------------------- */}
          <Typography variant="content1" color={theme?.palette?.primaryText}>
            No Medications Recorded
          </Typography>
        </Grid>
      )}

    </Paper>
  );
};

export default InfoMedications;
