import React, { useState, useEffect, useMemo, useCallback  } from "react";
import { Div } from "qdm-component-library";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch } from "react-redux";
import actions from "../../../../redux/actions/index";
import { makeStyles } from "@material-ui/core/styles";
import { useStyles } from "./style";
import MenuItem from "@material-ui/core/MenuItem";
import {
Select
} from "qdm-component-library";
function AppoinmnetAddtionalDetails(props) {
  console.log(props,"AppoinmnetAddtionalDetails");
  const dispatch = useDispatch();
  const [selectedValues, setSelectedValues] = useState({});
  const [priority, setpriority] = useState([]);
  const [priorityvalue, setpriorityvalue] = useState(null);
  const [combodetail, setComboDetail] = useState({});
  const { addtionalDetail, 
    setAppoinmantAddtionalDetails,
    onValidate,
    IsModifyUser, 
    editvalue,
    apptype,
    validateTrigger,
    AppoinmantAddtionalDetails,
    prioritytab,
    priorityvalues} = props;
  const [errors, setErrors] = useState({});
  const classes = useStyles();
  useEffect(() => {
    setSelectedValues(AppoinmantAddtionalDetails);
  }, [AppoinmantAddtionalDetails]);
  useEffect(() => {
    setpriorityvalue(prioritytab.value); 
  }, [JSON.stringify(prioritytab)]);
  
  useEffect(() => {
    if (addtionalDetail) {
      addtionalDetail.forEach(detail => {
        if (detail.resultreport === "Dropdown" || detail.resultreport === "Combobox" ) {
          fetchData(detail.resultcodeset, detail.loadtype);
        }
      });
    }
  }, [addtionalDetail]); 
  useEffect(() => {
    if (IsModifyUser && editvalue?.additionaldetail && Object.keys(combodetail).length > 0
  ) {
    onValidate(true);
      editdata();
    }
  }, [IsModifyUser, editvalue?.additionaldetail, combodetail]);
  async function fetchData(item, data) {
    if (data === "GM") {
      const GMResponse = await dispatch(actions.GET_MASTERS({ type: item }));
      const responseData = GMResponse?.payload?.data;

      const detail = responseData.map(dat =>
        dat?.[item]?.Value.map(dd => ({
          value: dd?.display,
          _id: dd?._id,
        }))
      ).flat();

      setComboDetail(prevState => ({
        ...prevState,
        [item]: detail,
      }));
    }
    if (data === "query") {
      const queryResponse = await dispatch(actions.GET_DETAILQUERY({ query_id: item }));
      const queryData = queryResponse?.payload?.data;

      const detail = queryData.map(query => ({
        value: query?.display,
        _id: query?._id,
      }));

      setComboDetail(prevState => ({
        ...prevState,
        [item]: detail, 
      }));
    }
  }
    const editdata = async() => {
    if (IsModifyUser && editvalue?.additionaldetail) {
      const initialValues = {};
      editvalue?.additionaldetail?.forEach((item) => {
        const detail = addtionalDetail.find(
          (detail) => detail.vitalcode === item.obscode
        );
  
        if (detail) {
          const isDropdownOrCombobox =
            detail.resultreport === "Dropdown"
  
          if (isDropdownOrCombobox) {
            initialValues[item.obscode] = item.obsvalue.map((obs) =>
              (combodetail[detail.resultcodeset] || []).find(
                (option) => option._id === obs
              )
            ).filter(Boolean); 
          } else {
            initialValues[item.obscode] = item.obsvalue.length === 1 ? item.obsvalue[0] : item.obsvalue;
              
          }
        }
      });
      console.log(initialValues, "Processed Initial Values");
      const vitalsPayload = Object.entries(initialValues).map(([code, values]) => ({
        vitalscode: code,
        vitalvalues: Array.isArray(values)
          ? values.map((item) => item._id || item)
          : [values],
      }));
      const PriorityResponse = await dispatch(
        actions.GET_PRIORITYCONTROL({ apptype,vitalsPayload })
      );
      let dataValue = PriorityResponse?.payload?.data;
      if (Array.isArray(dataValue) && dataValue?.length > 0) {
        const options = dataValue.map((item) => {
        
          const isMatchingPriority = editvalue?.priority === Number(item?.shortdesc);
          if (isMatchingPriority) {
            setpriorityvalue(item?.display); 
            priorityvalues(Number(item?.shortdesc));
          }
  
          return {
            value: item?.display,
            label: item?._id,
            id: item?.shortdesc,
          };
        });
  
        setpriority(options || []);

      } else {
        const PriorityDefault = await dispatch(actions.GET_PRIORITYDEFAULT());
        setpriority(PriorityDefault?.payload?.data);
        
        const options = PriorityDefault?.payload?.data?.map((item) => {
          const isMatchingPriority = editvalue?.priority === Number(item?.shortdesc);
        
          if (isMatchingPriority) {
            setpriorityvalue(item?.display);  
            priorityvalues(Number(item?.shortdesc));
          }
        
          return {
            value: item?.display,
            label: item?._id,
            id: item?.shortdesc,
          };
        });
        setpriority(options || []);
        
      }
      setSelectedValues(initialValues);
      setAppoinmantAddtionalDetails(initialValues);
    }
  }
  const handleChange = async (event, value, vitalcode, isAutocomplete = true) => {
    
    let updatedValues;
    if (isAutocomplete) {
      updatedValues = { ...selectedValues, [vitalcode]: value };
    }
    else if (event && event.value !== undefined) {
      if(vitalcode="priority"){
        setpriorityvalue(event?.value);
        priorityvalues(event);
      }else{
        updatedValues = { ...selectedValues, [vitalcode]: event.value };
      }

    }
    else if (event && event.target && event.target.value !== undefined) {
      updatedValues = { ...selectedValues, [vitalcode]: event.target.value };
    }
    if (updatedValues) {
    setSelectedValues(updatedValues);
    setAppoinmantAddtionalDetails(updatedValues);
    setErrors((prevErrors) => ({ ...prevErrors, [vitalcode]: false }));
  
    const vitalsPayload = Object.entries(updatedValues ).map(([code, values]) => ({
      vitalscode: code,
      vitalvalues: Array.isArray(values)
        ? values.map((item) => item._id || item)
        : [values],
    }));
  
    try {
      const PriorityResponse = await dispatch(
        actions.GET_PRIORITYCONTROL({ apptype,vitalsPayload })
      );
  
      let dataValue = PriorityResponse?.payload?.data;
  
      if (Array.isArray(dataValue) && dataValue?.length > 0) {
        // setpriority(dataValue); 
        const options = dataValue.map((item) => ({
          value: item?.display,
          label: item?._id,
          id:item?.shortdesc,
        }));
        setpriority(options || []);    
        if (options.length === 1) {
          const singleOption = options[0];
          setpriorityvalue(singleOption.value);
          priorityvalues(singleOption);
        }  else{
          setpriorityvalue(null);
        }
      } else {
        const PriorityDefault = await dispatch(actions.GET_PRIORITYDEFAULT());
        // setpriority(PriorityDefault?.payload?.data);
        const options = PriorityDefault?.payload?.data?.map((item) => ({
          value: item?.display,
          label: item?._id,
          id:item?.shortdesc,
        }));
        setpriority(options || []);
      }
  
    } catch (error) {
      console.error("Error in handleChange:", error);
    }
  }


  };
  

  // const validate = () => {
    
  //   let validationErrors = {};
  //   addtionalDetail.forEach((detail) => {
  //     if (detail.mandatory && !selectedValues[detail.vitalcode]) {
  //       validationErrors[detail.vitalcode] = true;
  //     }
  //   });
  //   setErrors(validationErrors);
  //   return Object.keys(validationErrors).length === 0;
  // };
  // useEffect(() => {
  //   if(validateTrigger){
  //     onValidate(validate());
  //   }
  // }, [selectedValues,validateTrigger,addtionalDetail]);

  return (
    <Div id="doctor_listing_ReasonforAppointment_div" style={{ marginBottom: "8px" }}>
      {addtionalDetail &&
        addtionalDetail
          .slice()
          .sort((a, b) => a.sequence - b.sequence)
          .map((detail, index) => (
            <Div key={index} style={{ marginBottom: "16px" }}>
              <Typography variant="body1" style={{ marginBottom: "8px" }}>
                {detail.vitalname}
                {detail.mandatory && <span style={{ color: "red" }}>*</span>}
              </Typography>
              {detail.resultreport === "Dropdown" ? (
              <Autocomplete
              multiple
              fullWidth
              size="small"
              options={combodetail[detail.resultcodeset] || []}
              value={
                Array.isArray(selectedValues[detail.vitalcode])
                  ? selectedValues[detail.vitalcode]
                  : []
              }
              getOptionLabel={(option) => option?.value || ""}
              onChange={(e, value) => handleChange(e, value, detail.vitalcode, true)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  error={!!errors[detail.vitalcode]}
                  helperText={errors[detail.vitalcode]}
                />
              )}
              style={{ padding: "0px" }}
            />
              
              ) : detail.resultreport === "Combobox" ? (
                <Select
                  showArrow
                  options={combodetail[detail.resultcodeset] || []}
                  value={selectedValues[detail.vitalcode] || null}
                  onChangeValue={(e, value) => handleChange(e, value, detail.vitalcode,"")}
                  placeholder="Select..."
                  isRequired={detail.mandatory}
                  inLineStyles={{
                    padding: "0px",
                    width: "100%", 
                  }}
                  error={errors[detail.vitalcode]}
                  helperText={errors[detail.vitalcode]}
                />

              ) 
               : detail.resultreport === "Textbox" ? (
                <TextField
                fullWidth
                size="small"
                variant="outlined"
                className={classes.textField}
                value={selectedValues[detail.vitalcode] || ""}
                onChange={(e) =>
                  handleChange(e, null, detail.vitalcode, false)
                }
                error={!!errors[detail.vitalcode]}
                helperText={errors[detail.vitalcode]}
              />
              
              ) : detail.resultreport === "Number" ? (
                <TextField
                fullWidth
                size="small"
                type="number"
                variant="outlined"
                className={classes.textField}
                value={selectedValues[detail.vitalcode] || ""}
                onChange={(e) =>
                  handleChange(e, null, detail.vitalcode, false)
                }
                error={!!errors[detail.vitalcode]}
                helperText={errors[detail.vitalcode]}
              />
              
              ) : null}
               
            </Div>
           
          ))}
          <Div style={{ marginTop: "16px" }}>
    <Typography variant="body1" style={{ marginBottom: "8px" }}>
      Priority
    </Typography>
    <Select
      showArrow
      value={priorityvalue}
      options={priority}
      onChangeValue={(e, value) => handleChange(e, value,"priority","")}
      placeholder="Select..."
      inLineStyles={{
        padding: "0px",
        width: "100%",
      }}
      // error={additionalSelectError}
      // helperText={additionalSelectError ? "This field is required" : ""}
    />
  </Div>
    </Div>
  );
}

export default AppoinmnetAddtionalDetails;
