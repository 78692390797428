import * as React from "react"

function TabletsIcon({ height, width, color, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={color ? color : "#000"}
      height={height ? height : "24px"}
      width={width ? width : "24px"}
      viewBox="0 0 225.566 225.566"
      xmlSpace="preserve"
      {...props}
    >
          <g fill={color ? color : "#000"}>
        <circle cx={41.05} cy={48.659} r={15.72} />
        <circle cx={93.65} cy={48.659} r={15.72} />
        <circle cx={41.05} cy={97.881} r={15.72} />
        <circle cx={93.65} cy={97.881} r={15.72} />
        <circle cx={41.05} cy={146.736} r={15.72} />
        <path d="M188.659 153.983h-55.063V0H1.093v195.385H74c2.718 17.063 17.414 30.181 35.228 30.181h79.432c19.756 0 35.813-16.054 35.813-35.8-.001-19.735-16.062-35.783-35.814-35.783zM7.84 6.742h119.032v147.247h-17.645c-.627 0-1.206.135-1.816.171 1.212-2.205 1.975-4.729 1.975-7.425 0-8.687-7.038-15.722-15.736-15.722-8.68 0-15.73 7.035-15.73 15.722 0 6.604 4.102 12.233 9.865 14.56-8.376 6.318-13.896 16.155-14.247 27.361H7.839V6.742h.001zm101.388 212.084c-16.029 0-29.056-13.033-29.056-29.07 0-16.011 13.027-29.043 29.056-29.043h37.159l.058 58.113h-37.217z" />
          </g>
          </svg>
  )
}

export default TabletsIcon