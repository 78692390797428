import * as React from "react"

function DiagnosisInfoIcon({ height, width, color, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width?width:"24px"}
      height={height?height:"24px"}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <g
        clipPath="url(#clip0_2355_11416)"
        stroke={color?color:"#3C9C00"}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M18.929 18.928l-3.889-3.89m-5.786 2.4A8.185 8.185 0 109.47 1.07a8.185 8.185 0 00-.216 16.368z" />
        <path d="M8.094 4.656a.714.714 0 00-.714.715v2.008H5.37a.714.714 0 00-.714.714v2.322a.714.714 0 00.714.714h2.01v2.01a.714.714 0 00.714.714h2.32a.714.714 0 00.715-.714v-2.011h2.008a.715.715 0 00.714-.715v-2.32a.714.714 0 00-.714-.714H11.13V5.371a.714.714 0 00-.714-.715H8.094z" />
      </g>
      <defs>
        <clipPath id="clip0_2355_11416">
          <path fill="#fff" d="M0 0H20V20H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default DiagnosisInfoIcon;