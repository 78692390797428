import {
  Avatar,
  Divider,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import * as colors from "@material-ui/core/colors";
import React, { Fragment } from "react";
import AddIcon from "@material-ui/icons/Add";
import VisibilityIcon from "@material-ui/icons/Visibility";

const useStyles = makeStyles(() => ({
  card: {
    height: "100%",
    width: "100%",
    borderRadius: "2px",
  },
  cardHeader: {
    fontSize: "12px",
    lineHeight: "12px",
    textAlign: "center",
    margin: "0px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontWeight: "bold",
    padding: "6px 8px",
    borderBottom: "1px dashed",
  },
  cardContent: {
    fontSize: "10px",
    lineHeight: "12px",
    margin: "0px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontWeight: "bold",
    padding: "4px 8px",
    borderBottom: "0.5px solid",
  },
  appointmentContainer: {
    borderLeft: (props) => `3px solid ${colors?.[props?.item?.color]?.[800]}`,
    paddingLeft: "10px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "3px",
    justifyContent: "flex-start",
    padding: "3px 5px",
    marginTop: "3px",
  },
  avatar: {
    border: "3px solid #DEE5EC",
    borderRadius: 8,
    height: "30px",
    width: "30px",
  },
  avatarPlaceholder: {
    backgroundColor: "#DEE5EC",
    height: "30px",
    width: "30px",
  },
  eventTitle: {
    marginLeft: "3px",
    fontSize: "14px",
    color: '#333',
    lineHeight: "20px",
    margin: "0px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontWeight: "bold",
    textTransform: "capitalize",
    fontFamily: "'Roboto', sans-serif",
  },
  detailsContainer: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
  cardText: {
    fontSize: "10px",
    color: "grey",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontWeight: "bold",
    marginLeft: "5px",
  },
  icon: {
    color: (props) => colors?.[props?.item?.color]?.[900],
    borderRadius: "100%",
  },
  divider: {
    height: "14px",
    width: "2px",
    backgroundColor: "grey",
    margin: "0 5px",
    display: "inline-block",
    verticalAlign: "middle",
  },
  minWidthContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "5px",
  },
  scrollContainer: {
    width: "100%",
    overflow: "auto",
    scrollbarWidth: "thin",
    gap: "12px",
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "var(--scrollbar-color)",
      borderRadius: "4px",
    },
  },
  availableContainer: {
    height: "100%",
    width: "100%",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const SlabCard = ({
  eventInfo,
  handleClick = () => null,
  setAvailable = () => null,
  calendarApi = {},
  transformToResources = []
}) => {
  const divRef = React.useRef(null);
  const [height, setHeight] = React.useState(0);
  const [width, setWidth] = React.useState(0);
  const [view, setView] = React.useState("day");
  const classes = useStyles();

  React.useEffect(() => {
    const handleResize = () => {
      const divElement = document.getElementById('slabcardid');
      if (divElement) {
        const rect = divElement.getBoundingClientRect();
        setWidth(rect.width);
        setHeight(rect.height);
      }
    };

    let whView = calendarApi?.view?.type.replace(/.*Grid/i, "").toLowerCase();
    setView(whView);

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [transformToResources]);

  const truncate = (value, length = 10) => {
    if (typeof value !== 'string') return value;
    return value.length > length ? `${value.substring(0, length)}...` : value;
  };
  console.log("slabwidth", width * 0.28, width);

  const scrollbarColor = colors?.[eventInfo?.event?.extendedProps?.color]?.[500];

  return (
    <div
      className={classes.card}
      style={{
        border: `1px solid ${colors?.[eventInfo?.event?.extendedProps?.color]?.[500]}`,
        backgroundColor:eventInfo?.event?.extendedProps?.state?.bgcolor || "unset"
      }}
      ref={divRef}
      id="slabcardid"
    >
      <p
        className={classes.cardHeader}
        style={{
          color: colors?.[eventInfo?.event?.extendedProps?.color]?.[900],
          borderBottomColor: colors?.[eventInfo?.event?.extendedProps?.color]?.[500],
        }}
      >
        {eventInfo?.event?.extendedProps?.slotslabind}
      </p>
      <Fragment>
        <p
          className={classes.cardContent}
          style={{
            color: colors?.[eventInfo?.event?.extendedProps?.color]?.[900],
            borderBottomColor: colors?.[eventInfo?.event?.extendedProps?.color]?.[500],
          }}
        >
          {`${eventInfo?.event?.extendedProps?.totalappointmentbooked}/${eventInfo?.event?.extendedProps?.totalappointmentallowed} - Appointments Booked`}
        </p>
        <p
          className={classes.cardContent}
          style={{
            color: colors?.[eventInfo?.event?.extendedProps?.color]?.[900],
            borderBottomColor: colors?.[eventInfo?.event?.extendedProps?.color]?.[500],
          }}
        >
          {eventInfo?.event?.extendedProps?.totalWaitList} - Waiting
        </p>
        <div
          className={classes.scrollContainer}
          style={{
            height: `${height - 70}px`,
            "--scrollbar-color": scrollbarColor,
          }}
        >
          {eventInfo?.event?.extendedProps?.appointments?.map((item, index) => (
            <div className={classes.appointmentContainer} style={{
              border: `1px solid ${colors?.[eventInfo?.event?.extendedProps?.color]?.[500]}`,

            }}
            key={index}>
              {view === "day" ? item?.photo ? (
                <div id={`_avatar_div`}>
                  <Avatar id={`_avatar_image`} src={item?.photo} alt={eventInfo?.event?.title ?? "profilePic"} className={classes.avatar} />
                </div>
              ) : (
                <div id={`_avatar_div`}>
                  <Avatar id={`_patientinfocard_avatar`} variant="rounded" className={classes.avatarPlaceholder}>
                    <Typography variant="h6" color="White">
                      {truncate(eventInfo?.event?.title?.[0])}
                    </Typography>
                  </Avatar>
                </div>
              ) : null}

              <p className={classes.eventTitle} style={{ maxWidth: width < 300 ? 0.45 * width : 0.25 * width, width: "100%" }}>
                {item?.patientname}
              </p>

              {(view === "day" && (width > 460)) ? (
                <span className={classes.detailsContainer} style={{ minWidth: "300px", width: "100%", maxWidth: width > 1000 ? 0.28 * width : "unset" }}>
                  <div className={classes.minWidthContainer}>
                    {truncate(item?.age) && (
                      <Typography className={classes.cardText} style={{ maxWidth: "42px", width: "100%", minWidth: "42px" }}>
                        {truncate(item?.age)}
                      </Typography>
                    )}
                  </div>
                  {(item?.gender || item?.mrnid || item?.state?.states) && (
                    <Divider className={classes.divider} orientation="vertical" flexItem />
                  )}
                  <div className={classes.minWidthContainer}>
                    {item?.gender && item.gender !== "" && (
                      <Typography className={classes.cardText} style={{ maxWidth: "42px", width: "100%", minWidth: "42px" }}>
                        {truncate(item?.gender)}
                      </Typography>
                    )}
                    {(item?.mrnid || item?.state?.states) && (
                      <Divider className={classes.divider} orientation="vertical" flexItem />
                    )}
                  </div>
                  <div className={classes.minWidthContainer}>
                    {item?.mrnid && item.mrnid !== "" && (
                      <Typography className={classes.cardText} style={{ maxWidth: "75px", width: "100%", minWidth: "62px" }}>
                        {truncate(item?.mrnid)}
                      </Typography>
                    )}
                    {item?.state?.states && item.state.states !== "" && (
                      <Divider className={classes.divider} orientation="vertical" flexItem />
                    )}
                  </div>
                  <div className={classes.minWidthContainer}>
                    {item?.state?.states && item.state.states !== "" && (
                      <Typography className={classes.cardText} style={{ color: item?.state?.textcolor, maxWidth: "135px", width: "100%", minWidth: "130px" }}>
                        {truncate(item?.state?.states)}
                      </Typography>
                    )}
                  </div>
                </span>
              ) : null}
              {item?.state?.states && item.state.states !== "" && (width < 460 && width > 300) && (
                <Typography className={classes.cardText} style={{ color: item?.state?.textcolor, maxWidth: "135px", width: "100%", minWidth: "130px" }}>
                  {truncate(item?.state?.states)}
                </Typography>
              )}
              <IconButton size="small" onClick={(e) => { e.stopPropagation(); handleClick(e, eventInfo); }}>
                <VisibilityIcon className={classes.icon} fontSize={width < 300 ? "small" : "medium"} />
              </IconButton>
            </div>
          ))}
          {eventInfo?.event?.extendedProps?.slotstatus === "Available" &&
            eventInfo?.event?.extendedProps?.totalappointmentbooked <=
            eventInfo?.event?.extendedProps?.totalappointmentallowed ? (
            <div className={classes.availableContainer}>
              <Tooltip
                title="click plus icon to book appointment"
                placement="top"
                arrow
              >
                <div>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setAvailable({
                        open: true,
                        data: eventInfo?.event?.extendedProps,
                        date: eventInfo?.event?.start,
                      });
                    }}
                  >
                    <AddIcon
                      className={classes.iconButton}
                      style={{
                        color: colors?.[eventInfo?.event?.extendedProps?.color]?.[900],
                      }}
                    />
                  </IconButton>
                </div>
              </Tooltip>
            </div>
          ) : (
            <></>
          )}
        </div>
      </Fragment>
    </div>
  );
};

export default SlabCard;
