/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/11/2020
 * @modify 12/11/2020
 * @desc Exporting all the components from /src/components
 */

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField, Typography, InputAdornment, Chip } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";
import { UIColor } from "../themes/theme";
import CloseIcon from '@material-ui/icons/Close';
import * as colors from "@material-ui/core/colors"; // For dynamic chip colors
import CancelIcon from '@material-ui/icons/Cancel';
const useStyles = (theme) => ({
  "@global": {
    '.MuiAutocomplete-option[data-focus="true"]': {
      color: UIColor.secondaryButtonColor.color
    },
    '.MuiAutocomplete-option[data-focus="false"]': {
      color: UIColor.secondaryColor.color
    }
  },
  selectBox: {
    width: "100%",
    height: "35px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "7px",
      '& fieldset': {
        borderColor: UIColor.lineBorderFill.color,
      },
      '&:hover fieldset': {
        borderColor: UIColor.lineBorderFill.color,
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${UIColor.lineBorderFill.color}`
      },
    },
    "& .MuiFormLabel-root.Mui-focused": {
      transform: "translate(14px, -6px) scale(0.75) !important",
    },
    "& .MuiFormLabel-root.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75) !important",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 9px) scale(1) !important",
    },
    "& .MuiAutocomplete-inputRoot": {
      height: "35px",
      "& input": {
        padding: "0.5px 4px !important",
        color: UIColor.secondaryText.color
      },
    },
    "& .MuiAutocomplete-tag": {
      margin: "3px -4px 3px 8px",
      height: "25px",
      fontSize: "0.8rem"
    }
  },
  MultiselectBox: {
    width: "100%",
    "& .MuiAutocomplete-inputRoot": {
      "& input": {
        padding: "0.5px 4px !important",
        color: UIColor.secondaryText.color
      },
      '& fieldset': {
        borderColor: UIColor.lineBorderFill.color,
      },
      '&:hover fieldset': {
        borderColor: UIColor.lineBorderFill.color,
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${UIColor.lineBorderFill.color}`
      },
    },
    "& .MuiAutocomplete-tag": {
      margin: "3px -4px 3px 8px",
      height: "25px",
      fontSize: "0.8rem"
    }
  },
  textColor: {
    color: UIColor.secondaryText.color,
  },
  chip: {
    backgroundColor: "#f1f6fc",
    color: "#000",
    fontWeight: 500,
    borderRadius: "4px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "140px", // Adjust the maximum width as needed
  }
});

class ColoredSelectBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // Correct chip color generation
  generateChipColor = (index) => {
    // List of Material-UI colors
    const colorKeys = Object.keys(colors).filter(
      (key) => key !== "common" && key !== "grey"
    ); // Filter out "common" and "grey"
    const color =colorKeys[index % colorKeys.length] // Cycle through colors
    return color || "blue"; // Ensure the color exists and return its 500 shadesss
  };
   Handlechange = (e, value) => {
    
    // Ensure `value` is an array and map through it to update the `color` key for each item
    const updatedValue = Array.isArray(value)
      ? value.map((item, i) => ({
        ...item,
        color: this.generateChipColor(i),  // Add color based on index for each item
      }))
      : [];
    this.props.onchange(e, updatedValue)
  }
  render() {
    let {
      serachIcon,
      Optionlabel = "label",
      placeholder,
      classes,
      onchange,
      classname,
      list,
      value,
      title,
      top_title,
      multi,
      disableClearable,
      requireStart,
      parent_id,
      error,
      disabled,
    } = this.props;


   
    return (
      <React.Fragment>
        {top_title && (
          <Typography
            id={`${parent_id}_select_${top_title.replace(" ", "_")}_typography`}
            variant="caption"
            className="pc_regular"
            style={{ color: UIColor.tertiaryText.color }}
          >
            {top_title}
            {requireStart && (
              <span
                id={`${parent_id}_select_${top_title}_star_span`}
                className="pc_regular"
                style={{
                  color: UIColor.error.color,
                  marginLeft: 5,
                }}
              >
                *
              </span>
            )}
          </Typography>
        )}
        {serachIcon ? (
          <Autocomplete
            id={`${parent_id}_select_autocomplete`}
            multiple={multi}
            options={list}
            className={`${classes.selectBox} ${value ? "Mui-focused" : ""} ${classname ? classname : ""}`}
            onChange={this.Handlechange}
            getOptionLabel={(option) => option[Optionlabel]}
            value={value}
            disabled={disabled}
            popupIcon={
              <InputAdornment id={`${parent_id}_select_InputAdornment`} position="end">
                <SearchIcon
                  id={`${parent_id}_select_SearchIcon`}
                  style={{ color: UIColor.secondaryText.color }}
                />
              </InputAdornment>
            }
            renderInput={(params) => (
              <TextField
                id={`${parent_id}_select_textfield`}
                style={{ height: "35px" }}
                placeholder={placeholder}
                {...params}
                label={top_title ? "" : title}
                error={error}
                variant="outlined"
              />
            )}
          />
        ) : (
          <Autocomplete
            id={`${parent_id}_select_disableClearable_autocomplete`}
            multiple={multi}
            disableClearable={disableClearable}
            options={list}
            ChipProps={{
              className: classes.chip,
              deleteIcon: <CloseIcon style={{ color: "#fff", width: "12px", height: "12px" }} />
            }}
            className={`${multi ? classes.MultiselectBox : classes.selectBox} ${value ? "Mui-focused" : ""} ${classname ? classname : ""}`}
            onChange={this.Handlechange}
            getOptionLabel={(option) => option[Optionlabel]}
            value={value}
            disabled={disabled}
            renderInput={(params) => (
              <TextField
                id={`${parent_id}_select_disableClearable_textfield`}
                style={{ height: multi ? "" : "35px" }}
                placeholder={placeholder}
                {...params}
                error={error}
                label={top_title ? "" : title}
                variant="outlined"
              />
            )}
            renderTags={(selected, getTagProps) =>
              selected.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  label={option?.[Optionlabel]}
                  style={{
                    backgroundColor: colors[option?.color]?.[700],  // Use the color from updated value
                    maxWidth: "140px",
                    color: "#fff",
                    fontWeight: "500",
                    
                  }}
                  deleteIcon={<CancelIcon style={{color: "#fff",alignContent: "flex-end"}} />}
                />
              ))
            }
          />
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(ColoredSelectBox);
