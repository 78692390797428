import React, { useContext, useEffect, useState } from "react";
import MultiBook from ".";
import { mbdata } from "./demo";
import moment from "moment";
import {
  AlertProps,
  cuurentNextTimeslot,
  startTime,
  GetQueueData,
  getGlobalValueFrom_LclStrg_And_QryStrg,
  waitListAppointmentId,
} from "../../utils";
import { AlertContext } from "../../context";
import { useDispatch } from "react-redux";
import actions from "../../redux/actions";
import { QueueManagementContext } from "../../context";
import { useLocation, useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { AppRoutes } from "../../router/routes";
import { useSelector } from "react-redux";

function MultiBookComp(props) {
  const {
    allData,
    displayData,
    previousData,
    previousQueue,
    loader,
    setAllData,
    setDisplayData,
    setPreviousData,
    setpreviousQueue,
    setloader,
  } = React.useContext(QueueManagementContext);
  const [state, setState] = useState({
    expanded: false,
    bookAppoinment: false,
    time: props?.time || "",
    info: props?.info || {},
    date: props?.date || "",
    multiApppointmentInfo: {
      appointments: {
        startsOn: {
          date: moment().format("DD-MM-YYYY"),
          slotSelect: {
            label: "",
            value: "",
          },
          milli: startTime(),
        },
        preferrence: {
          preferredDays: [],
          preferredWeeks: [],
        },
        occurence: {
          occurrenceHandle: "NoOfDays",
          occurrenceValue: 1,
        },
        reccurance: {
          reccuranceValue: 1,
          reccuranceType: {
            label: "Weeks",
            value: process.env.REACT_APP_FREQUENCY_UOM_WEEKS,
          },
        },
      },
      info: {
        dates: [],
        appointments: {},
        practData: {},
      },
      data: [],
      slotObj: {},
      filterData: {},
      uploadFiles: [],
    },
    hospitalInfo: props?.hospitalInfo,
  });
  const [loading, setLoading] = useState(false);
  const [showHide, setShowHide] = useState(0);
  const [maxWatingListCount, setMaxWatingListCount] = useState(0);
  const [selectedTabTitle, setSelectedTabTitle] = useState('DAY001'); 
  useEffect(() => {
    setShowHide(0);
  }, [props?.multiBookOpen]);
  const alert = useContext(AlertContext);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const userInfo = localStorage?.getItem("UserInfo");
  const decodedUserinfo = userInfo && jwtDecode(userInfo);
  let token = getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",isFullObject:true})
  const handleMultiAppointmentChange = (val, key1, key2, key3, key4) => {
    const statedata = { ...state };
    console.log(val, key1, key2, key3, key4, "MultiP90");
    if (key4) {
      if (key4 === "deleteAllDays") {
        statedata["multiApppointmentInfo"][key1][key2][key3] = [];
      }
      if (key4 === "s1") {
        statedata["multiApppointmentInfo"]["appointments"]["preferrence"][
          "preferredDays"
        ] = [
          {
            value: process.env.REACT_APP_FREQUENCY_UOM_WEEKS_FRIDAY,
            label: "Fri",
          },

          {
            value: process.env.REACT_APP_FREQUENCY_UOM_WEEKS_WEDNESDAY,
            label: "WED",
          },

          {
            label: "MON",
            value: process.env.REACT_APP_FREQUENCY_UOM_WEEKS_MONDAY,
          },
        ];
      }
      if (key4 === "s2") {
        statedata["multiApppointmentInfo"]["appointments"]["preferrence"][
          "preferredDays"
        ] = [
          {
            value: process.env.REACT_APP_FREQUENCY_UOM_WEEKS_SATURDAY,
            label: "Sat",
          },
          {
            value: process.env.REACT_APP_FREQUENCY_UOM_WEEKS_THURSDAY,
            label: "Thu",
          },

          {
            value: process.env.REACT_APP_FREQUENCY_UOM_WEEKS_TUESDAY,
            label: "Thu",
          },
        ];
      }
    }
    if (key2) {
      if (key3) {
        statedata["multiApppointmentInfo"][key1][key2][key3] = val;
      } else {
        statedata["multiApppointmentInfo"][key1][key2] = val;
      }
    } else {
      statedata["multiApppointmentInfo"][key1] = val;
      if (key1 === "info") {
        statedata["multiApppointmentInfo"]["slotObj"] = {};
      }
    }

    setState({
      ...statedata,
    });
  };

  const multiBooking = async () => {
    setLoading(true);
    //let { uploadFiles } = state;
    //const { info = {}, hospitalInfo = {} } = state.selectedSlot;
    //UploadedFiles = await uploadImage(uploadFiles);

    var appointMentInputArr = [];
    state?.multiApppointmentInfo?.data.forEach((singleSlot) => {
      let appointMentInput = {
        consultationMode: props?.mBData?.inputdoc?.consultationmode,
        status: true,
        cancelationReason: [],
        reasonCode: [],
        priority: 1,
        start: singleSlot.start || "",
        end: singleSlot.end || "",
        minutesDuration: 15,
        comment: "comments",
        appointmentReason: Array.isArray(
          props?.mBData?.inputdoc?.appointmentreason
        )
          ? props?.mBData?.inputdoc?.appointmentreason
          : [props?.mBData?.inputdoc?.appointmentreason],
        slotID: [singleSlot._id || 0],
        // ServiceCategoryID: [],
        // ServiceTypeID: [],
        SpecialtyID: Array.isArray(props?.mBData?.inputdoc?.specialtyid)
          ? props?.mBData?.inputdoc?.specialtyid
          : [props?.mBData?.inputdoc?.specialtyid],
        resourcetype: singleSlot?.resourceInfo?.resourcetype || "",
        resourcerole: singleSlot?.resourceInfo?.resourcerole || "",
        resourcecode: Array.isArray(singleSlot?.resourceInfo?.resourcecode?.[0])
          ? singleSlot?.resourceInfo?.resourcecode?.[0]
          : singleSlot?.resourceInfo?.resourcecode,
        orgid: token?.facilityid,
        PersonID: props?.mBData?.inputdoc?.personid,
        //props?.mBData?.inputdoc?.personId.toString(),
        appno: "",
        patient_id: props?.mBData?.inputdoc?.patientId,
        AppointmentNotification:
          props?.mBData?.inputdoc?.AppointmentNotification,
        bookedby: token?.practitionerid,
        appointmentTypeID: singleSlot?.appointmentType || [],
        facilityid: token?.facilityid,
        tenantid: token?.tenantid,
        transappid: props?.mBData?.isTransit ? props?.mBData?.appid : "",
        modeofappointment: process.env.REACT_APP_MODE_OF_APPOINTMENT_WALKIN, //FIXME -
        //          props?.mBData?.inputdoc?.appointmenttype,
      };
      appointMentInputArr.push(appointMentInput);
    });

    const appInfo = await dispatch(
      actions.APPOINTMENT_CREATE({ doc: appointMentInputArr })
    );

    let ticketId = props?.mBData?.ticketId;

    if (!appInfo?.payload?.data?.[0]?.error) {
      if (ticketId) {
        setloader(true);
        const schedule = await dispatch(
          actions.SCHEDULE_APPOINTMENT({
            ticketId: ticketId,
            resourcecode: token?.practitionerid, //userinfo
            resourcerole: token?.roleid, //roleid
            
            
          })
        );

        if (schedule?.payload?.error || schedule?.payload?.data?.error) {
          alert.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg:
              schedule?.payload?.data?.Response?.[0]?.errorMessage ||
              "something went wrong !",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
        } else {
          props?.RefreshLatestQueue()
          alert.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: "Appointment Booked successfully",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
        }
      } else if (!ticketId && appInfo?.payload?.data?.[0]?.statusCode === 201) {
        alert.setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg: appInfo?.payload?.data?.[0]?.message,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
      } else {
        alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg:
            appInfo?.payload?.data?.[0]?.response?.errorMessage ||
            appInfo?.payload?.data?.[0]?.message,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
      }
    } else {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg:
          appInfo?.payload?.data?.[0]?.response?.errorMessage ||
          appInfo?.payload?.data?.[0]?.message,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    }
    if (props?.callback) {
      props?.callback();
    } else {
      let data = await GetQueueData();
      setAllData(data.everyData);
      setDisplayData(data.getDisplayData);
      setPreviousData(data.getPreviousData);
      setpreviousQueue(props?.selectedQueueid);
    }
    setloader(false);
    setLoading(false);
    props.setMultiBookOpen(false);
  };
  function dateToEpoch(dateStr) {
    const [month, day, year] = dateStr.split('-').map(num => parseInt(num));
    const date = new Date(year, month - 1, day);
    return date.getTime() / 1000;
  }
  const getEpochTime = (date) => Math.floor(date.getTime() / 1000);
  const currentDate = new Date();
  const startDate = new Date(currentDate);
  const [appointmentdurationId, setAppointmentdurationId] = useState("");
  const [resourcetypeId, setResourcetypeId] = useState("");
  const [selectedSlotEndTiming, setSelectedSlotEndTiming] = useState("");
const watingListCount = useSelector((state) => state?.slot?.watingListAvailability);
  const PayloadGenerator = async () => {
    let date = new Date(state?.multiApppointmentInfo?.appointments?.startsOn?.milli);
    let [hours, minutes] = selectedSlotEndTiming.split(':').map(Number);
    date.setHours(hours, minutes, 0, 0);
    let preferredDate = Math.floor(date.getTime() / 1000);    
    let doc ={
        followupdate: 0,
        status: " ",
        encounterid: " ",
        practitionerid: decodedUserinfo?.practitionerid,
        orgid: decodedUserinfo?.facilityid,
        healthserviceid: " ",
        patientid:props?.mBData?.inputdoc?.patientId||location?.state?.patientId,
        specialtyid: props?.mBData?.inputdoc?.specialtyid,
        encounter_type: " ",
        orderid: "",
        followupdurval: 0,
        followupduruom: "",
        encounterdate: 0,
        appointmentid: "",
        appointmenttype:waitListAppointmentId?.waitList,
        followupreason: "",
        personid:props?.mBData?.inputdoc?.personid||location?.state?.userId,
        facilityid: decodedUserinfo?.facilityid,
        transactiondate:getEpochTime(startDate),
        preferreddate:preferredDate ,// Math.floor(state?.multiApppointmentInfo?.appointments?.startsOn?.milli/1000),
        requestuser: props?.mBData?.inputdoc?.patientId ||location?.state?.patientId,
        doctor:token?.locationid,
        daytype:appointmentdurationId,
        waitinglistseq:watingListCount?.data?.[0]?.sequence,
        status:waitListAppointmentId?.status,
        reasonCode:[],
        resourcetype:resourcetypeId,
      };
    let payload = {
      db_name: process.env.REACT_APP_DB,
      entity: "AppointmentRequest",
      is_metadata: true,
      metadata_dbname: process.env.REACT_APP_METADATA_DBNAME,
      metadataId: process.env.REACT_APP_METADATAID,
      doc: doc,
    };
    return payload;
  };
  const HandleWatingList = async () => {

    let payload = await PayloadGenerator();
    let saveWatingList = await dispatch(actions.SAVE_WAITING_LIST(payload));
    debugger
    let WaitListData = saveWatingList?.payload?.data?.response?.[0];

      if (WaitListData && !WaitListData?.error) {
          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: "The patient is scheduled on the wait list",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
            props?.setMultiBookOpens(false);
            navigate(AppRoutes.PatientDashBoard, {
              state: {
                userId: props?.mBData?.inputdoc?.personid,
                patientId:props?.mBData?.inputdoc?.patientId ||location?.state?.patientId,
              },
            });
      } else {
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: WaitListData?.message || "something went wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      }
  };
  const handleTabChange = (event) => {
    let title = '';
    switch (event) {
      case 0:
        title = 'DAY001';
        break;
      case 1:
        title = 'DAY002';
        break;
      case 2:
        title = 'DAY003';
        break;
      case 3:
        title = 'DAY004';
        break;
      default:
        title = 'DAY001';
  
        break;
    }
    
    setSelectedTabTitle(title);
    availableWatingList(title);
  };
  
  const availableWatingList = async (type,_id) =>{
    const slotInfo = await dispatch(
      actions.READ_SLOT({
        startdate:Math.floor(moment(state?.multiApppointmentInfo?.appointments?.startsOn?.date, "DD-MM-YYYY").startOf('day').valueOf()/1000) ,
        enddate:Math.floor(moment(state?.multiApppointmentInfo?.appointments?.startsOn?.date, "DD-MM-YYYY").endOf('day').valueOf()/1000),
        type: "practionerid",
        practitionerid: token?.locationid
      })
    );
    const slotInfoData = JSON.parse(JSON.stringify(slotInfo?.payload?.data));
    const matchingAppointments = slotInfoData?.appointmentdurationid
    ?.find(appointment => appointment?.daytype?.code === (type || selectedTabTitle))
    ?._id;
    const matchingSlotEndTiming = slotInfoData?.appointmentdurationid
    ?.find(appointment => appointment?.daytype?.code === (type || selectedTabTitle))
    ?.endtime;
    setSelectedSlotEndTiming(matchingSlotEndTiming)
    setAppointmentdurationId(matchingAppointments)
    setResourcetypeId(slotInfoData?.resourceInfo?.resourcetype)
    let payload = {
    practitionerid: [token?.locationid],
    daytype: type||selectedTabTitle,
    start:Math.floor(moment(state?.multiApppointmentInfo?.appointments?.startsOn?.date, "DD-MM-YYYY").startOf('day').valueOf()/1000) ,
    end:Math.floor(moment(state?.multiApppointmentInfo?.appointments?.startsOn?.date, "DD-MM-YYYY").endOf('day').valueOf()/1000) ,
    resourcetimetableid:slotInfoData?._id
  }
  const watingListCount = await dispatch(
    actions.WATING_LIST_AVAILABILITY(payload)
  );
  setMaxWatingListCount(watingListCount?.payload?.data);
  }
  return (
    <div>
      <MultiBook
        bookAppoinment={
          props?.multiBookOpen
          //|| true || state?.bookAppoinment
        }
        time={moment().format("Do MMM YYYY")}
        multiApppointmentInfo={state?.multiApppointmentInfo}
        info={mbdata.info()}
        date={cuurentNextTimeslot()}
        handleMultiAppointmentChange={handleMultiAppointmentChange}
        setMultiBookOpen={props?.setMultiBookOpen}
        multiBookOpen={props?.multiBookOpen}
        hospitalInfo={{ isClinic: true }}
        mBData={props?.mBData}
        shortcut={true}
        multiBooking={multiBooking}
        showHide={showHide}
        setShowHide={setShowHide}
        loading={loading}
        HandleWatingList={HandleWatingList}
        availableWatingList={availableWatingList}
        handleTabChange={handleTabChange}
        />
    </div>
  );
}

export default MultiBookComp;
