import React, { useContext, useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import {
  Grid,
  Typography,
  Divider,
  TextField,
  makeStyles,
  Dialog,
  useMediaQuery,
  useTheme,
  colors,
  Popover,
  Button,
  Card,
  CardContent,
  IconButton,
  Tooltip
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import actions from '../../redux/actions';
import SelectBox from '../../components/selectBox';
import ColoredSelectBox from '../../components/coloredSelectBox';
import RadioGrp from '../../components/radiogrp';
import moment from 'moment';
import {
  AlertProps, getGlobalValueFrom_LclStrg_And_QryStrg,
  getUtcTime,
  utcTOLocal,
  valitationFunc,
} from '../../utils';
import CheckboxGrp from '../../components/checkboxgrp';
import ExpandableSection from '../calenderAccordian';
import { AlertContext, BackdropContext } from "../../context/index";
import { DetailsCard } from '../../components/calender/card';
import { QueueFns } from '../Queue/src/queuefns';
import withAppBar from '../../hoc/withAppBar';
import { green } from '@material-ui/core/colors';
import AddIcon from '@material-ui/icons/Add';
import SearchForm from '../dashboard/searchPatient/components/searchForm';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../router/routes';
import { UIColor } from '../../themes/theme';
import { Div, SideMenu } from 'qdm-component-library';
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import LeaveIcon from "../../assets/img/svg/icons8-leave.svg";
import UndoIcon from "../../assets/img/svg/icons8-undo (1).svg";
import AppointmentSchedulingIcon from "../../assets/img/svg/icons8-appointment-scheduling.svg";
import ClockIcon from "../../assets/img/svg/icons8-clock-black.svg";
import StepOverIcon from "../../assets/img/svg/icons8-step-over.svg";
import ReleaseBlock from "./components/releaseBlock";
import GenerateBlock from "./components/generateBlock";
import "./schedulecalendar.css";
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceDayGridPlugin from '@fullcalendar/resource-daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import SlabCard from "../../components/slabCard";
import SlotCard from "../../components/slotCard";

var debounce;
var activeRequestId = null;
const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paperFullWidth": {
      width: "unset",
    },
    zIndex: "999 !important"
  }, margin: {
    margin: theme.spacing(1),
  },
  bottomnav: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    left: 0,
    backgroundColor: "#ffffff",
    padding: theme.spacing(2),
    boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    zIndex: 9999
  },
  centeredContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "auto",
  },
  contentWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "80%",
    maxWidth: "600px",
    height: "60px",
  },
  slot: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    fontSize: "1rem",
    fontWeight: 500,
    backgroundColor: "#f0f0f0",
    borderRadius: "12px",
    marginRight: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(1),
  },
  buttonContainer: {
    flex: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    gap: theme.spacing(2),
  },
  fixedBottomRight: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    zIndex: 9999,
  },
  fabContainer: {
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    gap: theme.spacing(1),
    zIndex: 9999,
  },
  fab: {
    boxShadow: theme.shadows[1],
  },
  fabrelease: {
    backgroundColor: "#f0f0f0",
  },
  fabgenerate: {
    backgroundColor: "#e0e0e0",
  },
})
)


const ScheduleCalendar = (props) => {
  const alertContext = useContext(AlertContext)
  const [selectedDate, setSelectedDate] = useState([new Date(), new Date(new Date().setDate(new Date().getDate() + 5))]);
  const [inputTypeState, setInputTypeState] = useState({});
  const [changedInputs, setChangedInputs] = useState({});
  const [inputOptions, setInputOptions] = useState({});
  const [inputsDataType, setInputsDataType] = useState({});
  const [appPayload, setAppPayload] = useState({});
  const [CalCardClick, setCalCardClick] = useState({ open: false, data: {} });
  const [dialogCardData, setDialogCardData] = useState({});
  const [facts, setFacts] = useState([]);
  const [events, setEvents] = useState([]);
  const [date, setDate] = useState({ start: 0, end: 0 });
  const [enter, setEnter] = useState(false);
  const [checkMultiple, setcheckMultiple] = useState();
  const [singleResourceBool, setSingleResourceBool] = useState(false);
  const [state, setState] = useState({
    enter: false,
    // generateOpen: false,
    releaseOpen: false,
    selected: false,
    generateMode: "fullAbsence",
    startOn: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
    endOn: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
    day: [],
    startTime: "12:00",
    endTime: "12:00",
    partialStartDate: new Date(),
    partialEndDate: new Date(),
    ReleaseStartDate: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
    ReleaseEndDate: null,
    generateBlock: [],
    generate_Reason: null,
    Reason: "",
    currentEvents: [],
    selectedEvents: [],
    selectedType: "",
    moreEvents: null,
    selectedDate: new Date(),
    roleType: null,
    Name: null,
    open: false,
    initialEvents: [],
    practitoners: [],
    error: {},
  });
  const [generateOpen, setGenerateOpen] = useState(false);
  const [SlotSlapCountState, setSlotSlapCountState] = useState(0)
  const {
    releaseOpen,
    selected,
    open,
    // enter,
    roleType,
    Name,
    practitoners,
  } = state;
  const [available, setAvailable] = useState({ open: false, data: {} })
  const [transformToResources, setTransformToResources] = useState([]);
  const [queueData, setQueueData] = useState({
    ticketId: [],
    TotActionData: "",
    data: [],
    queueId: ""

  })
  const  [resourceWidth, setResourceWidth] = useState(0);
  const dispatch = useDispatch();
  const calendarRef = useRef(null);
  const alert = useContext(AlertContext)
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles()
  const backdrop = useContext(BackdropContext);
  const navigate = useNavigate()
  const location = useLocation();
  const handleMouseEnter = () => setEnter(true);
  const handleMouseLeave = () => setEnter(false);
  const handleClickOpen = (type, fun) => {
    setState((prevState) => {
      const newState = { ...prevState, selected: false };
      if (type === "generate") {
        newState.generateOpen = fun === "open";
      } else if (type === "Release") {
        if (fun === "open") {
          newState.releaseOpen = true;
          newState.selectedType = "Blocked";
        } else if (fun === "openCalendar") {
          newState.releaseOpen = true;
          newState.selected = true;
        } else if (fun === "false") {
          newState.releaseOpen = false;
          newState.selectedType = "";
        } else {
          newState.releaseOpen = false;
          newState.generateBlock = [];
          newState.selectedEvents = [];
          newState.Reason = null;
        }
      }

      return newState;
    });
  };


  //handleState
  const handleFormState = async (name, value) => {

    setState((prev) => {

      let errorObj = JSON.parse(JSON.stringify(prev?.error || {}));
      errorObj[name] = false;

      let updatedState = { ...prev, [name]: value, error: errorObj };

      if (name === "ReleaseStartDate") {
        updatedState = {
          ...updatedState,
          ReleaseEndDate: "",
        };
      }

      if (name === "roleType" && value?.label === "Location") {
        updatedState = {
          ...updatedState,
          practitoners: [],
        };
      } else if (name === "roleType" && value?.label === "Device") {
        updatedState = {
          ...updatedState,
          practitoners: [],
        };
      } else if (name === "roleType" && value?.label === "Practitioner") {
        props.ALL_PRACTITIONER();
        updatedState = {
          ...updatedState,
          practitoners: props.AllPractitioner?.data ?? [],
        };
      }

      if (
        (name === "ReleaseStartDate" || name === "ReleaseEndDate") &&
        (prev?.ReleaseStartDate || prev?.ReleaseEndDate)
      ) {
        (async () => {
          let date = moment(prev?.ReleaseStartDate).unix();
          let formateDate = moment.unix(date).format("YYYY-MM-DD");
          let daystart = getUtcTime(moment(formateDate));
          let slotstart = getUtcTime(moment(prev?.ReleaseStartDate));
          let slotend = getUtcTime(moment(prev?.ReleaseEndDate));
          let id = prev?.Name?.id ? prev?.Name?.id : 0;

          let releaseslots = await dispatch(
            actions.RELEASE_SLOTS({
              dayStart: daystart,
              slotStart: slotstart,
              slotEnd: slotend,
              practitonerId: inputTypeState?.resource_id?.[0]?.value,
            })
          );

          if (releaseslots?.payload?.data?.error) {
            alertContext.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: releaseslots?.payload?.data?.message,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
            });
          } else {
            setState((prevInner) => ({
              ...prevInner,
              generateBlock: releaseslots?.payload?.data,
            }));
          }
        })();
      }

      return updatedState;
    });

    if (name === "Name") {
      let calendarApi = calendarRef.current.getApi();
      calendarApi.refetchEvents();
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }
  //block generate function
  const generateBlockFunc = async () => {
    //for full absence
    let calendarApi = calendarRef.current.getApi();

    if (state?.generateMode === "fullAbsence") {
      let startdate = getUtcTime(moment(state?.startOn)); // 1631845800;
      let enddate = getUtcTime(moment(state?.endOn)); // 1631847600;
      let id = state?.Name?.id;
      let blockReason = state?.generate_Reason?._id;

      const errorList = ["startOn", "endOn", "generate_Reason"];
      let { error, errorState } = valitationFunc(state, errorList);

      //&& startDate >= endDate;
      if (startdate <= enddate) {
        let blockScheduleData = await dispatch(
          actions.BLOCK_SCHEDULE({
            startDate: startdate,
            endDate: enddate,
            practitonerId: inputTypeState?.resource_id?.[0]?.value,
            reason: blockReason,
          })
        );
        calendarApi.refetchEvents();
        if (blockScheduleData?.payload?.data?.error) {
          alertContext.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: blockScheduleData?.payload?.data?.message,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
          return
        } else {
          alertContext.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: blockScheduleData?.payload?.data?.message,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
          getAppointments();
        }
      } else {
        alertContext.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please fill the mandatory!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        setState((prev) => ({
          ...prev,
          error: errorState
        }))
        return
      } //for partial absence
    } else if (state?.generateMode === "partialAbsence") {
      let startDate = state?.partialStartDate;
      let endDate = state?.partialEndDate;
      let startTime = state?.startTime;
      startTime = startTime.replace(": ", ":");
      let endTime = state?.endTime;
      endTime = endTime.replace(": ", ":");
      let practitonerId = this?.state?.Name?.id;
      let days = state?.day;
      let blockReason = state?.generate_Reason?._id;

      const errorList = [
        "partialStartDate",
        "partialEndDate",
        "startTime",
        "endTime",
        "day",
        "generate_Reason",
        //  "Name",
      ];
      let { error, errorState } = valitationFunc(state, errorList);

      if (startDate <= endDate) {

        let blockScheduleDataCPartial = await dispatch(
          actions.BLOCK_SCHEDULE_PARTIAL({
            startDate: formatDate(startDate),
            endDate: formatDate(endDate),
            starttime: startTime,
            endtime: endTime,
            practitonerId: inputTypeState?.resource_id?.[0]?.value,
            days: days,
            reason: blockReason,
          })
        );
        calendarApi.refetchEvents();

        if (blockScheduleDataCPartial?.payload?.data?.error) {
          alertContext.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: blockScheduleDataCPartial?.payload?.data?.message,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          }); return
        } else {
          alertContext.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: blockScheduleDataCPartial?.payload?.data?.message,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
          getAppointments();
        }
      } else {
        alertContext.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please fill the mandatory!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        setState((prev) => ({
          ...prev,
          error: errorState,
        }));

        return
      }
    }
    handleClickOpen("generate", "false")
    setState((prev) => ({
      ...prev,
      startOn: null,
      endOn: null,
      generate_Reason: null,
      partialStartDate: new Date(),
      partialEndDate: new Date(),
      startTime: "12:00",
      endTime: "12:00",
      day: [],
    }));

  };
  function BorderRemoveFromCalendar(selectedEvents) {
    selectedEvents.forEach((data) => {
      data.eventInfo.el.style.border = "";
      data.eventInfo.el.style.backgroundColor = "";
    });
    setState((prevState) => ({
      ...prevState,
      selectedEvents: [],
    }))
  }
  const handleSelected = (action, events) => {

    if (action === "Select") {
      setState((prevState) => ({
        ...prevState,
        selected: true,
        generateBlock: events,
      }));
    } else if (action === "UnSelect") {
      setState((prevState) => ({
        ...prevState,
        selected: events.length === 0 ? false : true,
        generateBlock: events,
      }));
    } else if (action === "close") {
      BorderRemoveFromCalendar(state.selectedEvents)
      setState((prevState) => ({
        ...prevState,
        selected: false,
        generateBlock: [],

      }));

    }
  };

  const SlotSlapCount = (data) => {
    let SlabCount = 0;
    let SlotCount = 0;
    data?.forEach((v) => {
      if (v.type === "Slab") {
        SlabCount = SlabCount + 1;
      } else {
        SlotCount = SlotCount + 1;
      }
    });

    setSlotSlapCountState(`${SlotCount} Slots & ${SlabCount} Slab`);
  };

  useEffect(() => {
    (async function () {
      let facts = [];
      let factid = ""
      if (localStorage.getItem("RoleName") === "Doctor") {  //REVIEW - 
        factid = process.env.REACT_APP_CALENDER_DOCTOR_FACT_ID
      }
      else if (localStorage.getItem("RoleName") === "null" && getGlobalValueFrom_LclStrg_And_QryStrg({ localStoragekeyName: "UserInfo", returnObjKeyName: "userrole" }).toLowerCase().includes("patient")) {
        factid = process.env.REACT_APP_CALENDER_PATIENT_FACT_ID
      }
      else if (localStorage.getItem("RoleName") === "Nurse") {
        factid = process.env.REACT_APP_CALENDER_NURSE_FACT_ID  //REVIEW - 
      }
      else {
        factid = process.env.REACT_APP_CALENDER_FRONTDESK_FACT_ID
      }

      let fact = await dispatch(
        actions.GET_FACT_BY_ID({ factId: factid })
      );
      fact?.payload?.data?.[0]?.facts?.forEach((v) => {
        facts.push(v);
      });
      setFacts(facts ?? []);
    })();
  }, []);
  useEffect(() => {

    const calendarApi = calendarRef.current.getApi();
    const view = calendarApi.view;
    if (view.type.replace(/.*Grid/i, "").toLowerCase() === "day") {
      setDate({
        start: moment(view.activeStart, "YYYY-MM-DD").unix(),
        currentTime: moment().unix(), // Start date of the visible range
        end: moment(view.activeEnd, "YYYY-MM-DD").unix(),     // End date of the visible range
      });
    }
    else {
      setDate({
        start: moment(view.activeStart, "YYYY-MM-DD").unix(), // Start date of the visible range
        end: moment(view.activeEnd, "YYYY-MM-DD").unix(),     // End date of the visible range
      });
    }

  }, [selectedDate])
  useEffect(() => {
    getAppointments()
  }, [date])
  const handleReactCalendarChange = (date) => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.gotoDate(date);
    setSelectedDate([
      calendarRef?.current?.calendar?.currentData?.dateProfile?.renderRange
        ?.start,
      new Date(
        new Date(
          calendarRef?.current?.calendar?.currentData?.dateProfile?.renderRange?.end
        ).getTime() -
        24 * 60 * 60 * 1000
      ),
    ]);
  };
  React.useEffect(() => {
    setSelectedDate([
      calendarRef?.current?.calendar?.currentData?.dateProfile?.renderRange
        ?.start,
      new Date(
        new Date(
          calendarRef?.current?.calendar?.currentData?.dateProfile?.renderRange?.end
        ).getTime() -
        24 * 60 * 60 * 1000
      ),
    ]);
  }, [selectedDate?.[0]])

  useEffect(() => {
    if(events?.length>0){
    updateResourceWidth()
    }
  }, [events]);
  const updateResourceWidth = () => {
    debugger
    const calendarEl =  calendarRef?.current?.el;

    if (calendarEl) {
      const resourceEl = calendarEl.querySelector(".fc-scrollgrid-sync-inner");
      if (resourceEl) {
        setResourceWidth(resourceEl.offsetWidth + "px");
      }
    }
  };  
  const handleDatesSet = (dateInfo) => {
    // const newDate = new Date(dateInfo.start);
    setSelectedDate([new Date(), new Date(new Date().setDate(new Date().getDate() + 5))]);
  };

  const inputTyperender = (data, isdep) => {
    if (!data || !data?.controltype || !data?.controltype?.code) {
      return <Typography color="error">Invalid input configuration</Typography>;
    }
    switch (data?.controltype?.code) {
      case 'RCNT004':
        return (
          <TextField
            type="date"
            fullWidth
            variant="outlined"
            size="small"
            value={
              inputTypeState[data?.fieldname]
                ? moment(inputTypeState[data?.fieldname]).format('YYYY-MM-DD')
                : ''
            }
            onChange={(e) => stateUpdate(data?.fieldname, e.target.value)}
          />
        );
      case 'RCNT001':
        return (
          <ColoredSelectBox
            list={inputOptions[data?.fieldname] || []}
            value={inputTypeState[data?.fieldname] || []}
            onchange={(e, value) => stateUpdate(data?.fieldname, value)}
            multi={true}
            disableClearable
          />
        );
      case 'RCNT003':
        return (
          <SelectBox
            list={inputOptions[data?.fieldname] || []}
            value={inputTypeState[data?.fieldname] || ''}
            onchange={(e, value) => stateUpdate(data?.fieldname, value)}
            multi={false}
          />
        );
      case 'RCNT007':
        return (<CheckboxGrp
          types={inputOptions[data?.fieldname] || []}
          //name="Select Options"
          value={inputTypeState[data?.fieldname] || []}
          valuefn={(selectedValues) => stateUpdate(data?.fieldname, selectedValues)}
        />
        );
      case 'RCNT006':
        return (<RadioGrp
          types={inputOptions[data?.fieldname] || []}
          // name="Select Options"
          value={inputTypeState[data?.fieldname] || []}
          valuefn={(selectedValues) => stateUpdate(data?.fieldname, selectedValues)}
        />
        );
      case "RCNT002":
        return <TextField
          // InputProps={{
          //   style: {
          //     borderRadius: '7px',
          //     height: '35px',
          //   },
          // }}
          type="number"
          // style={{ backgroundColor: 'white' }}
          fullWidth variant="outlined" size="small"
          placeholder="value"
          value={
            inputTypeState[data?.fieldname] || ""

          }
          onChange={(e) => stateUpdate(data?.fieldname, e.target.value)}
        />
      case "RCNT005":
        return <TextField
          // InputProps={{
          //   style: {
          //     borderRadius: '7px',
          //     height: '35px',
          //   },
          // }}
          // style={{ backgroundColor: 'white' }}
          fullWidth variant="outlined" size="small"
          placeholder="value"
          value={
            inputTypeState[data?.fieldname] || ""
          }
          onChange={(e) => stateUpdate(data?.fieldname, e.target.value)}
        />
      default:
        return (
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Enter value"
            value={inputTypeState[data?.fieldname] || ''}
            onChange={(e) => stateUpdate(data?.fieldname, e.target.value)}
          />
        );
    }
  };

  const getDebounceAppoiments = async (payload) => {

    let currentRequestId = Date.now();
    activeRequestId = currentRequestId
    let realData;
    backdrop.setBackDrop({ ...backdrop, open: true, message: "Getting Appointments..." });

    let appointments = await dispatch(actions.CALENDER_SHOW_APPOINTMENTS({ ...payload }))

    if (activeRequestId === currentRequestId) {
      if (appointments?.payload?.data?.error) {
        realData = [];
      } else {
        realData = appointments?.payload?.data?.response
        //realData =[]
      }
    }
    else {
      realData = []
    }
    setEvents(realData ?? []);
    backdrop.setBackDrop({ ...backdrop, open: false, message: "" });

  }

  const getAppointments = async (inputdata, value,filterdataType) => {
    const calendarApi = calendarRef.current.getApi();
    const viewType = calendarApi.view.type;
    filterdataType=filterdataType?filterdataType:inputsDataType;
    clearTimeout(debounce);
    if (!inputdata) {
      inputdata = inputTypeState
    }
    let payload = { ...appPayload, ...date, "patientid": getGlobalValueFrom_LclStrg_And_QryStrg({ localStoragekeyName: "UserInfo", returnObjKeyName: "patientid" }), "facilityid": getGlobalValueFrom_LclStrg_And_QryStrg({ localStoragekeyName: "UserInfo", returnObjKeyName: "facilityid" }), }
    const assignValue = (fieldname, value, defaultValue) => {
      return value ? value : defaultValue;
    };
    setAppPayload(payload)
    Object.keys(filterdataType).forEach((fieldname) => {
      switch (filterdataType[fieldname]) {
        case "arrayofobject":
          payload[fieldname] = assignValue(fieldname, inputdata[fieldname]?.map((v) => v?.value), []);
          if (Object.keys((inputdata[fieldname]?.[0] || {})).includes("color")) {
            payload["colorparams"] = assignValue(fieldname, inputdata[fieldname]?.map((v) => {
              return {
                "resourceid": v?.value, "color": v?.color
              }
            }), []);
          }
          break;
        case "date":
          payload[fieldname] = assignValue(fieldname, moment(inputdata[fieldname], "YYYY-MM-DD").unix(), 0);
          break;
        case "object":
          payload[fieldname] = assignValue(fieldname, inputdata[fieldname]?.value, "");
          break;
        case "string":  // Combined case for "object" and "string"
          payload[fieldname] = assignValue(fieldname, inputdata[fieldname], "");
          break;
        default:
          payload[fieldname] = assignValue(fieldname, value, "");
          break;
      }
    })
    payload["viewType"] = viewType.replace(/.*Grid/i, "").toLowerCase();
    // if(view.type==="timeGridDay" ){
    payload["isavailable"] = true
    //     }
    //     else if(){

    //     }
    debounce = setTimeout(async () => {
      getDebounceAppoiments(payload)
    }, 1000)

  }


  const stateUpdate = async (fieldname, value) => {
    if(fieldname == "appointmentType"){
      setcheckMultiple(value);
    }
    let inputdata = inputTypeState
    inputdata = { ...inputdata, [fieldname]: value }
    setInputTypeState((prevState) => ({ ...prevState, [fieldname]: value }));
    setChangedInputs((prevState) => ({ ...prevState, [fieldname]: value?.length == 0 ? false : true }));
    const currentFact = facts?.find(
      (fact) => fact?.fieldname === fieldname
    );
    if (currentFact?.dependent?.length > 0) {
      currentFact.dependent.forEach((dependent) => {
        const currentFact = facts?.find(
          (fact) => fact?.fieldname === dependent
        );
        fetchOptions(currentFact, inputdata)
      });
    }
    getAppointments(inputdata, value)
  };
  const setDataForCalndarState = (inputDataType, fieldparam) => {
    Object.keys(inputDataType).forEach((fieldname) => {
      switch (inputDataType[fieldname]) {
        case "arrayofobject":
          return location?.state?.calendarParams[fieldparam]?.[0];

        case "object":
          return location?.state?.calendarParams[fieldparam];

        case "string":  // Combined case for "object" and "string"
          return location?.state?.calendarParams[fieldparam];


      }
    })
  }
  const editStateUpdate = (inputDataType) => {
    let inputOption = {};
    let inputdata = inputTypeState
    inputdata = { ...inputdata, ...location.state?.calendarParams } //REVIEW - 


    setInputTypeState((prevState) => ({ ...prevState, ...inputdata }));
    //FIXME -  need to work
    //setChangedInputs((prevState) => ({ ...prevState, [fieldname]: value?.length == 0 ? false : true }));
    let appointmentType = [];
    let resourceType = "";
    let resourceId = [];
    facts?.forEach(async (fact) => {
      const resp = await fetchOptions(fact, inputdata);

      if (resp.appointmentType != undefined && resp.appointmentType != null) {
        appointmentType = inputdata.appointmentType
          .map((apptType) =>
            resp.appointmentType.find((dbAppt) => dbAppt.value === apptType)
          )
          .filter(Boolean);
      }


      if (resp.resource_type != undefined && resp.resource_type != null) {
        resourceType = resp.resource_type.find(
          (dbResource) => dbResource.value === inputdata.resource_type
        );
      }

      if (resp.resource_id != undefined && resp.resource_id != null) {
        resourceId = inputdata.resource_id
          .map((apptType) =>
            resp.resource_id.find((dbAppt) => dbAppt.value === apptType)
          )
          .filter(Boolean);
      }

      // inputOption[fact.fieldname]=resp[fact.fieldname].filter((s)=>s.value===setDataForCalndarState(inputDataType,fact.fieldname));
      if (appointmentType != undefined && appointmentType != null && resourceType != undefined && resourceType != null && resourceId != undefined && resourceId != null) {
        setInputTypeState((prevState) => ({
          ...prevState, ...{
            "appointmentType": appointmentType,
            "resource_type": resourceType,
            "resource_id": resourceId
          }
        }));


        getAppointments({
          ...location.state?.calendarParams, ...{
            "appointmentType": appointmentType,
            "resource_type": resourceType,
            "resource_id": resourceId
          }
        },undefined,inputDataType)
      }

      console.log("test");
    })



  }

  


  useEffect(() => {
    (function () {
      let inputDataType = {}
      let isEdit = (Object.keys(location.state?.calendarParams || {}).length > 0)
      facts?.forEach((fact) => {
        switch (fact?.controltype?.code) {
          case 'RCNT004': // date
            inputDataType = { ...inputDataType, [fact?.fieldname]: "date" }
            break;
          case 'RCNT001': // dropdown -> array of object
            inputDataType = { ...inputDataType, [fact?.fieldname]: "arrayofobject" }
            break;
          case 'RCNT003': // combo -> object
            inputDataType = { ...inputDataType, [fact?.fieldname]: "object" }
            break;
          case 'RCNT007': // checkbox -> array of objects
            inputDataType = { ...inputDataType, [fact?.fieldname]: "arrayofobject" }
            break;
          case 'RCNT006': // radio -> object
            inputDataType = { ...inputDataType, [fact?.fieldname]: "object" }
            break;
          case "RCNT002":
            inputDataType = { ...inputDataType, [fact?.fieldname]: "number" }
            break;
          case "RCNT005":
            inputDataType = { ...inputDataType, [fact?.fieldname]: "string" }
            break;
        }
        if (!isEdit) {
          if (fact?.queryid) {
            fetchOptions(fact);
          }
        }
      });
      if (isEdit) {
       // setAppPayload(location.state?.calendarParams)
        editStateUpdate(inputDataType)

      }

      let state = {}
      Object.keys(inputDataType).forEach((fieldname) => {
        switch (inputDataType[fieldname]) {
          case "arrayofobject":
            state[fieldname] = [];
            break;
          case "date":
            state[fieldname] = 0;
            break;
          case "object":
            state[fieldname] = {}
            break;
          case "string":  // Combined case for "object" and "string"
            state[fieldname] = ""
            break;

        }
      })
      setInputsDataType((prev) => ({ ...prev, ...inputDataType }))
      setInputTypeState((prev) => ({ ...prev, ...state }))
    })()
  }, [facts]);

  const fetchOptions = async (data = {}, inputData) => {

    if (data?.queryorgm?.label === 'query') {
      // Call QDM_CALL to fetch options from query 
      let filter = {}
      if (Array.isArray(data?.queryfilter)) {
        data?.queryfilter.forEach((v) => {
          //              // resource_id
          if (inputData?.[v?.valuefieldname]) {
            filter[v?.keyname] = inputData?.[v?.valuefieldname]?.value ? inputData[v?.valuefieldname]?.value : inputData[v?.valuefieldname]
          } else if (inputData) {
            const currentFact = facts?.find(
              (fact) => fact?.fieldname === v?.valuefieldname
            );
            alert.setSnack({
              open: true,
              severity: AlertProps.severity.success,
              msg: `please enter the ${currentFact.displayname} !`,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
              tone: true,
            });
            return
          }
        })
      }
      const qdmcalldata = await dispatch(actions.QDM_CALL({
        queryId: data?.queryid,
        tenantid: getGlobalValueFrom_LclStrg_And_QryStrg({ localStoragekeyName: "UserInfo", returnObjKeyName: "tenantid" }),
        facilityid: getGlobalValueFrom_LclStrg_And_QryStrg({ localStoragekeyName: "UserInfo", returnObjKeyName: "facilityid" }),
        filter,
      }));
      setInputOptions((prevState) => ({
        ...prevState,
        [data?.fieldname]: qdmcalldata?.payload?.data || [],
      }));
      return { [data?.fieldname]: qdmcalldata?.payload?.data };
    } else if (data?.queryorgm?.label === 'GM') {
      // Call GENERAL_MASTER to fetch GM options
      const gmdata = await dispatch(actions.GENERAL_MASTER({
        type: [data?.queryid],
        tenantid: getGlobalValueFrom_LclStrg_And_QryStrg({ localStoragekeyName: "UserInfo", returnObjKeyName: "tenantid" }),
        facilityid: getGlobalValueFrom_LclStrg_And_QryStrg({ localStoragekeyName: "UserInfo", returnObjKeyName: "facilityid" })
      }));
      setInputOptions((prevState) => ({
        ...prevState,
        [data?.fieldname]: gmdata?.payload?.data || [],
      }));
      return { [data?.fieldname]: gmdata?.payload?.data };
    }
  };

  const handleCalenderCardClick = async (e, Data) => {
    e?.stopPropagation();
    let cardData = await dispatch(actions.GET_QUEUE_DETAILS_BY_TICKETID(Data?.event?.id || Data?.id))
    
    const checkValue = checkMultiple?.map((val) => {
      return val.bookingtype; 
    });
    const isMultiple = checkValue.join(" ");
    if(isMultiple.toLowerCase() == "multiple"){
      
    const data = await dispatch(
      actions.APPOINTMENT_READ({
        appointmentid: cardData?.payload?.data?.[0]?.inputdoc?.appointmentid,
        tenantid: "",
        facilityid: "",
      })
    );
        let date = new Date(data?.payload?.data.start * 1000);
        let year = date.getFullYear();
        let month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed, so add 1
        let day = String(date.getDate()).padStart(2, "0");
        let thours = String(date.getHours()).padStart(2, "0");
        let tminutes = String(date.getMinutes()).padStart(2, "0");
        let start = `${year}-${month}-${day} ${thours}:${tminutes}`;
        date = new Date(data?.payload?.data.end * 1000);
        year = date.getFullYear();
        month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed, so add 1
        day = String(date.getDate()).padStart(2, "0");
        thours = String(date.getHours()).padStart(2, "0");
        tminutes = String(date.getMinutes()).padStart(2, "0");
        let end = `${year}-${month}-${day} ${thours}:${tminutes}`;
        
        const formattedDate = start.split(" ")[0];

      navigate(AppRoutes.multiresourcebooking, {
        state: {
          appointmentid : cardData?.payload?.data?.[0]?.inputdoc?.appointmentid,
          appointmentDetails:data?.payload?.data,
          apptypecode: appPayload?.appointmentType?.[0],
          start,
          end,
          formattedDate
        },
      });
    }
    else{
      setCalCardClick({ ...CalCardClick, open: true, data: Data })
      setDialogCardData(cardData?.payload?.data?.[0] || {})
    }
  }

  const handleDialogClose = () => {
    setCalCardClick({ ...CalCardClick, open: false })
  }
  const handleActionBtnClick = (data) => {
    setQueueData({
      ticketId: [dialogCardData?.ticketId],
      TotActionData: data,
      data: dialogCardData,
      queueId: dialogCardData?.queueid,
    });
  };

  const handleBlockEventClick = (clickInfo) => {
    debugger
    clickInfo.jsEvent.stopPropagation();
    let currentDate = utcTOLocal(moment(), "YYYY-MM-DDTHH:mm");
    let selectedStart = utcTOLocal(
      moment(clickInfo.event.startStr),
      "YYYY-MM-DDTHH:mm"
    );;

    if (selectedStart >= currentDate) {
      if (state?.selectedEvents?.length === 0) {
        setState((prev) => ({
          ...prev,
          selectedType: clickInfo?.event?.extendedProps?.slotstatus,
        }));
      }
      let currentType = clickInfo?.event?.extendedProps?.slotstatus;
      // if (state?.selectedType === currentType) {
      let obj = {
        id: clickInfo?.event?.extendedProps?._id,
        title: clickInfo?.event?.title ?? "Available Slots",
        start: clickInfo.event.startStr,
        end: clickInfo.event.endStr,
        isBlocked: clickInfo?.event?.extendedProps?.slotstatus ?? "",
        type: clickInfo?.event?.extendedProps?.slotslabind,
        appointment: clickInfo?.event?.extendedProps?.appointment ?? "",
        slab: clickInfo?.event?.extendedProps?.slab ?? "",
        overTime: clickInfo?.event?.extendedProps?.overTime ?? "",
        eventInfo: clickInfo

      };

      let dup = state?.selectedEvents?.filter(
        (v) => v?.id === clickInfo?.event?.extendedProps?._id
      );

      if (dup?.length === 0) {
        clickInfo.el.style.border = "2px dotted #fc160a";
        clickInfo.el.style.backgroundColor = "#f5f5f5 !important";

        // setState((prevState) => ({
        //   ...prevState,
        //   selectedEvents: [...prevState.selectedEvents, obj],
        // }));
        const newSelectedEvents = [...state.selectedEvents, obj];
        setState((prevState) => ({
          ...prevState,
          selectedEvents: newSelectedEvents,
        }));
        SlotSlapCount(newSelectedEvents)
        handleSelected("Select", newSelectedEvents);

      } else {
        clickInfo.el.style.border = "unset";
        clickInfo.el.style.backgroundColor = "unset";
        let filteredArray = state?.selectedEvents?.filter(
          (v) => v?.id !== clickInfo?.event?.extendedProps?._id
        );
        setState((prev) => ({
          ...prev,
          selectedEvents: filteredArray,
        }));
        SlotSlapCount(filteredArray)
        handleSelected("UnSelect", filteredArray);
      }
      // } else {
      //     alertContext.setSnack({
      //               open: true,
      //               severity: AlertProps.severity.error,
      //               msg: "Cannot Select different Status",
      //               vertical: AlertProps.vertical.top,
      //               horizontal: AlertProps.horizontal.right,
      //             });
      //           }
    } else {
      alertContext.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please select Upcoming  Date and Time!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
  }; // handleBlockEventClick

  const moreEventClick = (info) => {
    let event = info.allSegs.map((v) => {
      return {
        id: v.event.id,
        title: v.event?.title,
        start: utcTOLocal(moment(v.event?.startStr), "YYYY-MM-DDTHH:mm"),
        end: utcTOLocal(moment(v.event?.endStr), "YYYY-MM-DDTHH:mm"),
        isBlocked: v.event?.extendedProps.isBlocked,
      };
    });

    setState({
      open: true,
      moreEvents: event,
      selectedDate: info.date,
    });
  };

  const getAppModifiedData=(payload)=>{
    let appointmentType = [];
    let resourceType = "";
    let resourceId = [];
         if (inputOptions.appointmentType != undefined && inputOptions.appointmentType != null) {
            appointmentType = payload.appointmentType
              .map((apptType) =>
                inputOptions.appointmentType.find((dbAppt) => dbAppt.value === apptType)
              )
              .filter(Boolean);
          }
    
    
          if (inputOptions.resource_type != undefined && inputOptions.resource_type != null) {
            resourceType = inputOptions.resource_type.find(
              (dbResource) => dbResource.value === payload.resource_type
            );
          }
    
          if (inputOptions.resource_id != undefined && inputOptions.resource_id != null) {
            resourceId = payload.resource_id
              .map((apptType) =>
                inputOptions.resource_id.find((dbAppt) => dbAppt.value === apptType)
              )
              .filter(Boolean);
          }
    
        if (appointmentType != undefined && appointmentType != null && resourceType != undefined && resourceType != null && resourceId != undefined && resourceId != null) {
    
          getAppointments({
             ...{
              "appointmentType": appointmentType,
              "resource_type": resourceType,
              "resource_id": resourceId
            }
          })
        }
      }

  const RefreshFn = async () => {
    let cardData = await dispatch(actions.GET_QUEUE_DETAILS_BY_TICKETID(CalCardClick?.data?.event?.id))
    setDialogCardData(cardData?.payload?.data?.[0] || {})
    getAppModifiedData(appPayload);
  }

  const BookFn = async (patientData) => {
    const patientInfo = await dispatch(
      actions.PATIENT_INFO({
        patientid: patientData?.patientId,
        tenantid: "",
        facilityid: "",
        lang: "",
        personid: "",
      })
    );

    let state = {
      newPatientDetails: patientInfo?.payload?.data?.patientdetails,
      allPatientDetails: patientInfo?.payload?.data,
    };
    const checkValue = checkMultiple?.map((val) => {
      return val.bookingtype; 
    });
    const isMultiple = checkValue.join(" ");
    if(isMultiple.toLowerCase() == "multiple"){
      navigate(AppRoutes.multiresourcebooking, {
        state: {
          data: patientInfo,
            apptypecode: appPayload?.appointmentType?.[0],
           patientDetails:{
             patientId: state.allPatientDetails?._id,
             userId: state.allPatientDetails?.patientdetails?.personid,
             selectedUser: {
              name:
                state?.newPatientDetails?.name?.[0]?.fulname ||
                state?.patientDetails?.name ||
                state?.patientDetails?.allData?.name?.[0]?.fulname,
                fileid: state?.newPatientDetails?.photo?.[0]?.fileid,
                url: state?.newPatientDetails?.photo?.[0]?.url,
                age: state?.newPatientDetails?.age,
                gender: state?.newPatientDetails?.gender?.display,
                birthdate: state?.newPatientDetails?.birthdate,
                modifyUser: false,
                },
                resource_id: appPayload?.resource_id?.[0],
                selectedSlotId: available?.data?._id,
                date: available?.date  
              } ,
           appointmentDetails:appPayload
          
        },
      });
  
    }
else{
  navigate(AppRoutes.appointmentBooking, {
    state: {
      skip_dashboard_hospitalSearch_screen: true,
      apptypecode: appPayload?.appointmentType?.[0],
      patientId: state.allPatientDetails?._id,
      userId: state.allPatientDetails?.patientdetails?.personid,
      selectedUser: {
        name:
          state?.newPatientDetails?.name?.[0]?.fulname ||
          state?.patientDetails?.name ||
          state?.patientDetails?.allData?.name?.[0]?.fulname,
        fileid: state?.newPatientDetails?.photo?.[0]?.fileid,
        url: state?.newPatientDetails?.photo?.[0]?.url,
        age: state?.newPatientDetails?.age,
        gender: state?.newPatientDetails?.gender?.display,
        birthdate: state?.newPatientDetails?.birthdate,
        modifyUser: false,
      },
      resource_id: appPayload?.resource_id?.[0],
      selectedSlotId: available?.data?._id,
      date: available?.date,
        calendarParams: appPayload,

      // patientDetails: state.allPatientDetails,
    },
  });
}
 }

  useEffect(() => {
    let data = inputTypeState?.["resource_id"]?.length > 0 ? inputTypeState?.["resource_id"] : []
    setTransformToResources(() => [...data].map(item => ({
      id: item.value, // Use "value" as "id"
      title: item.label, // Use "label" as "title"
      color:  colors?.[item.color]?.[900] // Retain the "color" if necessary
    })))
    if (data?.length === 1) {
      setSingleResourceBool(true)
    }
    else {
      setSingleResourceBool(false)
    }

  }, [inputTypeState?.["resource_id"]])

  //block and generate choosen from calendar
  const HandleBlock = async () => {
    //
    let calendarApi = calendarRef.current.getApi();
    if (state?.selectedType === "Available" || state?.selectedType === "Booked") {
      backdrop.setBackDrop({
        ...backdrop,
        open: true,
        message: "Generate Block..",
      });
      let array = state?.generateBlock;
      for (let index = 0; index < array.length; index++) {
        let startdate = getUtcTime(moment(array[index].start)); // 1631845800;
        let enddate = getUtcTime(moment(array[index].end)); // 1631847600;
        let id = this?.state?.Name?.id;
        let blockReason = state.Reason?._id;
        if (blockReason) {
          let blockScheduleData = await dispatch(
            actions.BLOCK_SCHEDULE({
              startDate: startdate,
              endDate: enddate,
              practitonerId: inputTypeState?.resource_id?.[0]?.value,
              reason: blockReason,
            })
          );
          if (blockScheduleData?.payload?.data?.error) {

            alertContext.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: blockScheduleData?.payload?.data?.message,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
            });
            backdrop.setBackDrop({
              ...backdrop,
              open: false,
              message: "",
            });
            return false;
          } else {
            alertContext.setSnack({
              open: true,
              severity: AlertProps.severity.success,
              msg: blockScheduleData?.payload?.data?.message,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
            });
            handleClickOpen("Release", "false");
            getAppointments();
            setState((prev) => ({
              ...prev,
              releaseOpen: false,
              selected: false,
              generateBlock: [],
              selectedEvents: [],
              Reason: null,
            }));
          }
        } else {
          alertContext.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Some thing went worng",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "",
          });
          return false;
        }
      }
      backdrop.setBackDrop({
        ...backdrop,
        open: false,
        message: "",
      });
      handleClickOpen("Release", "false");
      getAppointments();
      setState((prev) => ({
        ...prev,
        releaseOpen: false,
        selected: false,
        generateBlock: [],
        selectedEvents: [],
        Reason: null,
      }));
    }
    calendarApi.refetchEvents();
    setState((prev) => ({
      ...prev,
      releaseOpen: false,
      selected: false,
      generateBlock: [],
      selectedEvents: [],
      Reason: null,
    }));
  };
  function getStartAndEndTimes(generateBlock) {
    const result = {
      start: generateBlock[0].start,
      end: generateBlock[generateBlock.length - 1].end
    };
    return result;
  }
  const ReleaseBlocks = async () => {

    let calendarApi = calendarRef.current.getApi();
    // let startdate = getUtcTime(moment(state?.ReleaseStartDate)); // 1631845800;
    // let enddate = getUtcTime(moment(state?.ReleaseEndDate)); // 1631847600;
    let startdate = state?.ReleaseStartDate && state?.ReleaseEndDate
      ? getUtcTime(moment(state.ReleaseStartDate))
      : getUtcTime(moment(getStartAndEndTimes(state?.generateBlock).start));

    let enddate = state?.ReleaseStartDate && state?.ReleaseEndDate
      ? getUtcTime(moment(state.ReleaseEndDate))
      : getUtcTime(moment(getStartAndEndTimes(state?.generateBlock).end));
    let id = this?.state?.Name?.id;
    let releaseReason = state.Reason?._id;
    const errorList = ["ReleaseStartDate", "ReleaseEndDate", "Reason"];
    let { error, errorState } = valitationFunc(state, errorList);
    //full release
    if (startdate <= enddate) {
      let releaseScheduleData = await dispatch(
        actions.RELEASE_SCHEDULE({
          startDate: startdate,
          endDate: enddate,
          practitonerId: inputTypeState?.resource_id?.[0]?.value,
          reason: releaseReason,
        })
      );
      calendarApi.refetchEvents();
      if (releaseScheduleData?.payload?.data?.error) {
        alertContext.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some thing went worng",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        handleClickOpen("Release", "false");
        getAppointments();
        return
      } else {
        alertContext.setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg: releaseScheduleData?.payload?.data?.message,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        setState((prev) => ({
          ...prev,
          releaseOpen: false,
          selected: false,
          generateBlock: [],
          selectedEvents: [],
          Reason: null,
        }));
        getAppointments();
        handleClickOpen("Release", "false");
      }
    } else {
      alertContext.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Some thing went worng",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      setState((prev) => ({
        ...prev,
        error: errorState,
      }));
      return
    }
    getAppointments();
    handleClickOpen("Release", "false");
    setState((prev) => ({
      ...prev,
      ReleaseStartDate: null,
      ReleaseEndDate: null,
      Reason: null,
      selectedType: "",
    }));

  };
  const parent_id = "ScheduleCalendar"
  return (
    <Grid container spacing={2} style={{ padding: "12px" }}>
      <Grid
        container
        direction="column"
        item
        xs={2}
        style={{ borderRight: "1px solid #ccc" }}
      >
        <Grid container item justifyContent="center">
          <Calendar value={selectedDate} onChange={handleReactCalendarChange} />
        </Grid>
        <Grid item>
          <Divider style={{ margin: "10px 0" }} />
          <Typography variant="body" style={{ fontWeight: "bold" }} >Appointments:</Typography>
          <div
            style={{
              height: "50vh",
              overflowY: "auto",
              marginTop: "16px",
              scrollbarWidth: "thin"
            }}
          >
            {facts?.length > 0 &&
              facts.map((fact, factIndex) => {
                return (
                  <ExpandableSection
                    key={factIndex}
                    name={fact?.displayname}
                    content={inputTyperender(fact)}
                    showBlueDot={changedInputs[fact?.fieldname]}
                  />
                );
              })}
        </div>
        </Grid>
      </Grid>
      <Grid item xs={10}>
       
        <div style={{ height: "84vh" }}>
      
          <FullCalendar
            ref={calendarRef}
            plugins={[
              //dayGridPlugin, timeGridPlugin, interactionPlugin,
              resourceTimeGridPlugin,
              resourceDayGridPlugin,
              interactionPlugin,
            ]}
            initialView="resourceTimeGridDay"
            datesSet={handleDatesSet}
            allDaySlot={false}           
            dayHeaderFormat={{
              weekday: "short",
              day: "numeric",
              month: "numeric",
              separator: " ",
            }}
            height="100%"
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              end: "resourceDayGridMonth,resourceTimeGridWeek,resourceTimeGridDay"
            }}
            views={{
              resourceDayGridMonth: { buttonText: 'Month' }, // Customize button text
              resourceTimeGridWeek: { buttonText: 'Week' },
              resourceTimeGridDay: { buttonText: 'Day' },
            }}
            nowIndicator={true}
            resources={transformToResources}
            events={events}           
            eventClick={handleBlockEventClick}
            schedulerLicenseKey={"CC-Attribution-NonCommercial-NoDerivatives"}
            resourceLabelContent={(arg) => {        
              return (
                <div
                  style={{
                    color: arg?.resource?._resource?.extendedProps?.color,                   
                      fontSize: "16px",
                      lineHeight: "20px",
                      margin: "0px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                      fontFamily: "'Roboto', sans-serif",                   
                    
                  }}
                >
                  {arg?.resource?._resource?.title}
                </div>
              );
            }}
            eventContent={(eventInfo) => (
              <>
                {eventInfo?.event?.extendedProps?.slotslabind === "Slot" ? (
                  eventInfo?.event?.extendedProps?.slotstatus === "Available" ? (
                    <Tooltip title="click plus icon to book appointment" arrow>
                      <div
                        style={{
                          border: `1px solid ${colors?.[eventInfo?.event?.extendedProps?.color]?.[400] || green[400]}`,
                          height: "100%",
                          width: "100%",
                          cursor: "pointer",
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "rgba(255, 0, 0, 0) !important"
                        }}

                      >
                        {/* <p
                          style={{
                            fontSize: "14px",
                            color: colors?.[eventInfo?.event?.extendedProps?.color]?.[900],
                            lineHeight: "14px",
                            margin: "0px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            width: "auto",
                            //fontWeight: "bold",
                            textTransform: "capitalize",
                            textAlign: "center",
                            marginRight: '5px'
                          }}
                        >
                          Slot Available
                        </p> */}
                        <IconButton size='small' onClick={(e) => {
                          e.stopPropagation();
                          setAvailable({ open: true, data: eventInfo?.event?.extendedProps, date: eventInfo?.event?.start })
                        }}>
                          <AddIcon style={{
                            // backgroundColor:"#EC6A49",
                            color: colors?.[eventInfo?.event?.extendedProps?.color]?.[900],
                            borderRadius: "100%"
                          }} />
                        </IconButton>

                      </div>
                    </Tooltip>
                  ) : eventInfo?.event?.extendedProps?.slotstatus === "Blocked" ? (
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        cursor: "pointer",
                        border: "1px solid #FF4D4A",
                      }}

                    >
                      <p
                        style={{
                          fontSize: "14px",
                          color: "#FF4D4A",
                          cursor: "pointer",
                          lineHeight: "14px",
                          margin: "0px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                          textTransform: "capitalize",
                          // backgroundColor: "#DCDCDC",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "33px",
                        }}
                      >
                        blocked
                      </p>
                    </div>
                  ) : eventInfo?.event?.extendedProps?.slotstatus === "Booked" ? (
                    <div
                      style={{
                        backgroundColor: eventInfo?.event?.extendedProps?.state?.bgcolor ||  colors?.[eventInfo?.event?.extendedProps?.color]?.[50],
                        height: "100%",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <SlotCard
                        eventInfo={eventInfo}
                        handleClick={handleCalenderCardClick}
                        calendarApi={calendarRef?.current?.getApi()}
                        resourceWidth={resourceWidth}
                      />
                    </div>
                  ) : <></>
                ) : (
                  <div
                    style={{
                      backgroundColor: "rgba(255, 0, 0, 0)",
                      height: "100%",
                      width: "100%",
                      cursor: "pointer",
                    }}
                  >
                    <SlabCard
                      eventInfo={eventInfo}
                      handleClick={handleCalenderCardClick}
                      setAvailable={setAvailable}
                      calendarApi={calendarRef?.current?.getApi()}
                      transformToResources={transformToResources}
                    />
                  </div>
                )}
              </>
            )}
            slotDuration={"00:10:00"}
          />
        </div>
      </Grid>
      <Dialog
        open={CalCardClick?.open}
        onClose={handleDialogClose}
        aria-labelledby="responsive-dialog-title"
        fullScreen={fullScreen}
        maxWidth="md"
        fullWidth
        class={classes.dialog}
      >
        <DetailsCard data={dialogCardData} handleClose={handleDialogClose} actionBtnClick={handleActionBtnClick} />
      </Dialog>
      <QueueFns queueData={queueData} RefreshLatestQueue={RefreshFn} calendarparams={appPayload} />
      {/* <SideMenu
        id="sidemenu_search_form"
        direction="right"
        width={435}
        color="default"
        open={available.open}
        onClose={() => setAvailable({ openn: false })}
      >
        <SearchForm
          handleActionClose={() => setAvailable({ openn: false })}
          parent_id={"id123"}
          noNewPatient={true}
          isCashier={false}
          isBook={true}
          isEye={false}
          bookFn={BookFn}
        />
      </SideMenu> */}

      {available.open &&
        <Div
          inLineStyles={{
            zIndex: 1,
            width: "25%",
            // right: "10px",
            // position: "absolute",
            background: UIColor.differentiationBackground.color,
            height: "calc(100vh - 9vh)",
            overflow: "auto",
            borderLeft:

              "none",
          }}
          style={{
            position: "absolute",
            bottom: "0px",
            right: "0px",
            borderRadius: "5px",
          }}
        >
          <SearchForm
            handleActionClose={() => setAvailable({ openn: false })}
            parent_id={"id123"}
            noNewPatient={true}
            isCashier={false}
            isBook={true}
            isEye={false}
            bookFn={BookFn}
            calendarparams={appPayload}
          />
        </Div>}
      {/* generate Block Dialog */}
      <GenerateBlock
        parent_id={'generateblock'}
        handleFormState={handleFormState}
        opengenerate={generateOpen}
        generateBlockFunc={generateBlockFunc}
        modalOpen={handleClickOpen}
        generateReason={props?.reason?.data ?? []}
        {...state}
      />
      {/* Release BLock Dialog.. */}
      <ReleaseBlock
        parent_id={'Releaseblock'}
        handleFormState={handleFormState}
        openrelease={releaseOpen}
        modalOpen={handleClickOpen}
        ReleaseBlock={ReleaseBlocks}
        HandleBlock={HandleBlock}
        releaseReason={props?.reason?.data ?? []}
        doctorList={inputTypeState?.resource_id?.[0]}
        {...state}
      />
      {/* Floating Buttons for Generate and Release Block */}
      {
        singleResourceBool ? (
          <>
            {enter ? (
              <div id={`${parent_id}-fab-container`} className={classes.fabContainer}>
                {/* Tooltip and Fab for Release */}
                <Tooltip
                  id={`${parent_id}-release-tooltip`}
                  title="Release Block"
                  placement="left"
                >
                  <Fab
                    id={`${parent_id}-release-fab`}
                    aria-label="Release"
                    className={`${classes.fabrelease} ${classes.fab}`}
                    variant="outlined"
                    onClick={() => handleClickOpen("Release", "open")}
                  >
                    <img id={`${parent_id}-release-img`} src={UndoIcon} alt="Release" />
                  </Fab>
                </Tooltip>

                {/* Tooltip and Fab for Generate */}
                <Tooltip
                  id={`${parent_id}-generate-tooltip`}
                  title="Generate Block"
                  placement="left"
                >
                  <Fab
                    id={`${parent_id}-generate-fab`}
                    aria-label="Generate"
                    className={`${classes.fabgenerate} ${classes.fab}`}
                    variant="outlined"
                    onClick={() => handleClickOpen("generate", "open")}
                  >
                    <img id={`${parent_id}-generate-img`} src={LeaveIcon} alt="Generate" />
                  </Fab>
                </Tooltip>
              </div>
            ) : null}

            <Zoom
              id={`${parent_id}-selected-zoom`}
              in={selected && !open && !releaseOpen}
              unmountOnExit
            >
              <div id={`${parent_id}-selected-div`} className={classes.bottomnav}>
                <div className={classes.slot}>{SlotSlapCountState} Selected</div>
                <div id={`${parent_id}-button-div`}>
                  <Button
                    id={`${parent_id}-Release-Generate-button`}
                    variant="contained"
                    onClick={() => handleClickOpen("Release", "openCalendar")}
                    color="primary"
                  >
                    {state?.selectedType === "Blocked" ? "Release Block" : "Block"}
                  </Button>
                  <IconButton
                    id={`${parent_id}-delete-closeicon`}
                    onClick={() => handleSelected("close")}
                    aria-label="delete"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
            </Zoom>
            {!selected && !open && !releaseOpen ? <Fab
              id={`${parent_id}-Add-fab`}
              onClick={() => setEnter((prev) => !prev)}
              aria-label="Add"
              className={`${classes.fab} ${classes.fixedBottomRight}`}
              color="primary"
            >
              {enter ? <CloseIcon /> : <AddIcon />}
            </Fab> : null}
          </>
        ) : null
      }
    </Grid>
  );
}
export default withAppBar(ScheduleCalendar);


