import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#01205C",
      light: "#F1F6FC"
    },
    secondary: {
      main: "#EC6A49",
      light: "#ffa091"
    },
    background: {
      default: '#f4f6f8', // Background color for the app
      paper: '#ffffff',    // Background color for paper-like components
    },

    //AINQA Colors

    primaryColor: "#01205C",
    secondaryColor: "#EC6A49",
    secondaryButtonColor: "#2A60BC",
    primaryText: "#001C3C",
    secondaryText: "#2A3C50",
    tertieryText: "#6A7888",
    greyBackground: "#F4F5F8",
    differentialBackground: "#F1F6FC",
    lineBorderFill: "#DEE5EC",
    Error: "#FF4D4A",
    Success: "#3BB213"
  },
  typography: {
    titleHead: {
      fontSize: 24,
      fontFamily: "Poppins, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 600,
      letterSpacing: "0%",
      lineHeight: "auto",
    },
    head1: {
      fontSize: 20,
      fontFamily: "Poppins, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 600,
      letterSpacing: "0%",
      lineHeight: "auto",
    },
    head2: {
      fontSize: 16,
      fontFamily: "Poppins, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 500,
      letterSpacing: "0%",
      lineHeight: "auto",
    },
    content1: {
      fontSize: 14,
      fontFamily: "Poppins, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0%",
      lineHeight: "auto",
    },
    content2: {
      fontSize: 12,
      fontFamily: "Poppins, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0%",
      lineHeight: "auto",
    },
    content3: {
      fontSize: 10,
      fontFamily: "Poppins, sans-serif",
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 400,
      letterSpacing: "0%",
      lineHeight: "auto",
    },
  },
});

export default theme;

