import React, { useContext, useEffect, useState } from "react";
import {
  actionButtonClick,
  AlertProps,
  axiosCall,
  checkWithCasbin,
  epochToDatetime,
  fetchData,
  getAge,
  getCalculatedAge,
  getFacilityId,
  getGlobalValueFrom_LclStrg_And_QryStrg,
  getImgUrl,
  getUtcTime,
  makeName,
  actionButtonClickv1,
  personRequests,
  RefundQueueRejectTabs,
  urls,
  actionButtonClickv2,
} from "../../../utils/index.js";
import moment from "moment";
import jwtDecode from "jwt-decode";
import { AlertContext, BackdropContext, drawerContext, SetPatientDetailsContext } from "../../../context/index.js";
import { useDispatch } from "react-redux";
import actions from "../../../redux/actions/index.js";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../router/routes.js";
import MultiBookComp from "../../../components/multibook/multiBookComp.js";
import { SideMenu } from "qdm-component-library";
import UploadFiles from "../../../components/queueCard/fileUpload.js";
import Commonsidebar from "../../../components/commonsidebar.js";
import SampleFormPage from "../../../components/queueCard/sampleForm.js";
import MuiDrawer from "../../../components/drawer.js";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import withAppBar from "../../../hoc/withAppBar.js";
import SearchPatient from "../../dashboard/searchPatient/searchPatient.js";
import { getLayout } from "../../dashboard/functions.js";
import NursingProcedure from "../../../components/queueCard/nursingProcedure.js";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmationPage from "../../confirmBooking/index.js";
import Modal from "../../../components/modal/index.js";

export function QueueFns({ selectedTicket = [], SelectedTicketValue = {}, RefreshLatestQueue = () => null, queueData = {
  ticketId: [],
  TotActionData: {},
  data: [],
  queueId: ""
}, calendarparams = {} }) {
  const backdrop = useContext(BackdropContext);
  const alert = useContext(AlertContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = localStorage.getItem("UserInfo");
  const decodedUserinfo = jwtDecode(userInfo);
  const setPatientDatacontext = useContext(SetPatientDetailsContext);
  // work flow states
  const [QueueTypeList_wf, setQueueTypeList_wf] = React.useState([]); //qur
  const [stateList_wf, setStateList_wf] = React.useState([]);
  const [selQueueData_wf, setSelQueueData_wf] = useState([]);
  const [tabValue_wf, setTabValue] = React.useState(0);
  const [selectedQueue_wf, setSelectedQueue_wf] = React.useState({});
  const [selCardData, setSelCardData] = useState({}); // totalData
  const [selCardActionData, setSelCardActionData] = useState({});

  // This component states
  const [currentIds, setCurrentIds] = React.useState({
    queueId: "",
    scenarioId: "",
  });

  // component render states
  // multibook state
  const [multiBookOpen, setMultiBookOpen] = React.useState(false);
  const [mBData, setMbData] = React.useState({});
  // upload files state
  const [comp_Upload_files, setComp_Upload_files] = React.useState([]);
  const [comp_Upload_State, setComp_Upload_State] = React.useState({
    openUpload: false,
    uploadData: {},
  });
  // common side bar
  const [comp_SideBar_State, setComp_SideBar_State] = React.useState({
    open: false,
    headingName: "",
    reasonType: "",
    isCancellationType: false,
  });
  //specimen component
  const [comp_specimen_state, setComp_specimen_state] = React.useState({
    open: false,
  });

  const [comp_administer_state, setComp_administer_state] = React.useState({
    open: false,
  });
  const [selectedSpeciment, setselectedSpeciment] = React.useState();
  const [selectedAdminstrator, setselectedAdminstrator] = React.useState();
  const [selectedqueuevalue, setselectedqueuevalue] = React.useState();
  const [state, setState] = useState({});

  const [refundDetailsOpen, setRefundDetailsOpen] = useState(false);
  const [refundValue, setRefundValue] = useState({});
  // view appointment
  const [viewAppoinment, setviewAppoinment] = useState({
    open: false,
    appoinmentId: ""
  });

  const RefreshFn = () => {
    if (RefreshLatestQueue) {
      RefreshLatestQueue()
    }
  }
  useEffect(() => {

    if (queueData.ticketId.length > 0 && queueData.TotActionData.action.length > 0) {
      CardBtnClick_WF(
        queueData?.ticketId,
        queueData?.TotActionData,
        queueData?.data
      )
      setCurrentIds({
        queueId: queueData?.queueId,
      })
    }
  }, [queueData])


  const alertPopup = (type, msg) => {

    alert.setSnack({
      open: true,
      severity: type,
      msg: msg,
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.center,
      tone: true,
    });
  };
  async function PerformUserAction({ ticketId = "", userAction = "" }) {
    backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." });
    try {
      let result = await dispatch(
        actions.PERFORM_USER_ACTION({
          ticketId: ticketId,
          userAction: userAction,
        })
      );

      if (result?.payload?.data?.error) {
        alert.setSnack({
          ...alert,
          open: true,
          msg:
            result?.payload?.data?.message ||
            result?.payload?.data?.Response?.[0]?.errorMessage ||
            "something went wrong",
          severity: "error",
        });
      } else {
        RefreshFn();
        alert.setSnack({
          ...alert,
          open: true,
          msg: "success !",
          severity: "success",
        });
      }
    } catch (error) {
      console.error(error?.message);
      alert.setSnack({
        ...alert,
        open: true,
        msg: error?.message,
        severity: "error",
      });
    }
    backdrop.setBackDrop({ ...backdrop, open: false, message: "" });
  }
  // component render fns
  // multibook
  const handleMultiBookData = (data) => {
    if (data) {
      setMbData(data);
    }
    setMultiBookOpen(!multiBookOpen);
  };
  // upload
  const handlesidebarCancelbooking = (id) => {
    setComp_Upload_State({ openUpload: false });
  };
  const uploadDocument = (files) => {
    setComp_Upload_files(files);
  };
  const uploadImage = async (image) => {
    const userInfo = localStorage.getItem("UserInfo");
    const decodedUserinfo = jwtDecode(userInfo);
    let JwtToken = localStorage.getItem("JwtToken");
    let header = { JwtToken: JwtToken };
    const data = {
      ReferralTrxId: selCardData?.inputdoc?.referralTrxId, //"ReferralTrx/10050",
      attachments: [
        {
          _id: "",
          fileName: image?.viewFileName,
          fileid: "",
          filetype: image?.fileType,
          base64string: image?.openFile,
        },
      ],
      ticketId: comp_Upload_State?.uploadData?.ticketId,
      practitionerId: decodedUserinfo?.practitionerid,
      practitionerRoleId: decodedUserinfo?.roleid,
    };
    const reportGenerate = await axiosCall(
      data,
      process.env.REACT_APP_SAVE_REFERRAL_ATTACHMENT,
      header
    );
    handlesidebarCancelbooking();
    if (!reportGenerate?.error) {
      setComp_Upload_State({
        ...comp_Upload_State,
        openUpload: false,
        uploadData: {},
      });
      setComp_Upload_files([]);
      RefreshFn();
    }
  };

  // sidebar
  async function Comp_SideBar_HandleSubmit(data, TotActionData) {

    setComp_SideBar_State({
      open: false,
      headingName: "",
      reasonType: "",
      isCancellationType: false,
    });
    try {
      let fetchVal = await actionButtonClickv2({
        reason: data?.reason?.value,
        reasondesc: data?.otherReason,
        selCardData,
        selCardActionData,
      }

      );
      // actionButtonClickv2(
      //   selCardActionData?.apiEndPoint || selCardActionData?.apiendpoint || TotActionData?.apiEndPoint || TotActionData?.apiendpoint,
      //   selCardActionData?.backendCall || selCardActionData?.backEndCall || TotActionData?.backendCall || TotActionData?.backEndCall,
      //   selCardData?.ticketId || data?.ticketId,
      //   data?.reason?.value, //app booking cancel reason
      //   data?.otherReason, // app booking n no show others
      //   data?.cacellationType, // app booking type
      //   selCardData?.inputdoc?.appointmentid || data?.inputdoc?.appointmentid,
      //   queueData?.TotActionData,
      //   selCardData
      // )
      if (!fetchVal.error) {
        RefreshFn(selCardActionData || TotActionData, selCardData?.queueMetaData?.queuename?.queuename || data?.queueMetaData?.queuename?.queuename);
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          msg: fetchVal?.response?.[0]?.message,
          severity: "error",
        });
      }
    } catch (error) {
      alert.setSnack({
        ...alert,
        open: true,
        msg: error?.message,
        severity: "error",
      });
    }
  }

  const NursingProcedureOpen = async (patientData, l) => {
    if (patientData) {
      setselectedAdminstrator(patientData);
      setselectedqueuevalue(l)
    }

    const userInfo = localStorage.getItem("UserInfo");
    const decodedUserinfo = jwtDecode(userInfo);
    const orgid = decodedUserinfo.facilityid;

    backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." });
    Promise.all([
      dispatch(actions.ADMINISTRATED_BY(orgid)),
      dispatch(actions.GET_ALL_MASTERS("")),

    ])
      .then((res) => {
        backdrop.setBackDrop({ ...backdrop, open: false, message: "" });
        setComp_administer_state({
          ...comp_administer_state,
          open: true,
        })
      })
      .catch((err) => {
        alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something went wrong !",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      });
  };
  const nursingProcedureClose = () => {
    setComp_administer_state({
      ...comp_administer_state,
      open: false,
    })
  };
  const sampleFormOpen = async () => {

    // setState({
    //   spicemanCollectedData: {
    //     ticketId: ticketId,
    //     queueId: queueId,
    //     personId:personId,
    //     encounterId:encounterId,
    //     patientId:patientId

    //   },
    // });
    const userInfo = localStorage.getItem("UserInfo");
    const decodedUserinfo = jwtDecode(userInfo);
    const orgid = decodedUserinfo.facilityid;
    backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." });
    Promise.all([
      dispatch(actions.REGISTERED_BY(orgid)),
      dispatch(actions.SAMPLE_COLLECTED(decodedUserinfo?.practitionerid)),
      dispatch(actions.BRANCH_CODE(orgid)),
    ])
      .then((res) => {
        backdrop.setBackDrop({ ...backdrop, open: false, message: "" });

        setComp_specimen_state({
          ...comp_specimen_state,
          open: true,
        });
      })
      .catch((err) => {
        alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something went wrong !",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      });
  };
  function ComponentRender({ totalData = {}, TotActionData = {}, type }) {
    debugger
    switch (type) {
      case "multibook":
        setMultiBookOpen(true); //FIXME -
        setMbData(totalData);
        break;
      case "upload":
        setComp_Upload_State({
          ...comp_Upload_State,
          openUpload: true,
          uploadData: {
            action: TotActionData?.action,
            apiEndPoint: TotActionData?.apiendpoint,
            backendCall: TotActionData?.backEndCall,
            ticketId: totalData?.ticketid || totalData?.ticketId,
            queue: totalData?.queue || totalData?.queueMetaData?.lastQueue?.lastQueue,
            queueId: totalData?.queueId || totalData?.queueid,
          },
        }); //FIXME -
        break;
      case "sidebar_reason_component":
        setComp_SideBar_State({
          ...comp_SideBar_State,
          open: true,
          reasonType: TotActionData?.stateactionpayload?.reasonType,
          headingName: TotActionData?.stateactionpayload?.sidebarComponentName,
          isCancellationType: TotActionData?.stateactionpayload?.isCancellationType || false
        });
        break;

      case "dialog_specimenCollected":
        setselectedSpeciment(totalData);
        sampleFormOpen()
        // setComp_specimen_state({
        //   ...comp_specimen_state,
        //   open: true,
        // });
        break;
      case "dialog_nurse_procudere_administer":
        NursingProcedureOpen(totalData, TotActionData)
        break;
      case "model_view_appoiment":
        setviewAppoinment({ ...viewAppoinment, open: true, appoinmentId: totalData?.inputdoc?.appointmentid })
        break;

      default:

      // setMultiBookOpen(true);
      // setMbData(totalData);
      // setComp_SideBar_State({
      //   ...comp_SideBar_State,
      //   open: true,
      //   reasonType: "CANCELCHECKREASON",
      //   headingName: "Cancel Check-In",
      // });
      // setComp_Upload_State({
      //   ...comp_Upload_State,
      //   openUpload: true,
      //   uploadData: {
      //     action: TotActionData?.action,
      //     apiEndPoint: TotActionData?.apiendpoint,
      //     backendCall: TotActionData?.backEndCall,
      //     ticketId: totalData?.ticketid || totalData?.ticketId,
      //     queue: totalData?.inputdoc?.queueType,
      //     queueId: totalData?.queueId,
      //   },
      // }); //FIXME -

      // setComp_SideBar_State({
      //   ...comp_SideBar_State,
      //   open: true,
      //   reasonType: "CANCELCHECKREASON",
      //   headingName: "Cancel Check-In",
      // });
      // break;
    }
  }

  function NavigateToScreen({
    ticketId = "",
    userAction = "",
    totalData = {},
    queueName = selectedQueue_wf?.value,
  }) {

    let routingPath =
      userAction?.action === "EMR"
        ? "/patient_detail"
        : userAction?.action === "CHECK-IN"
          ? "/visit-registration"
          : userAction?.action === "UPDATETREATMENTPLAN" ? "/patient_detail" : "";

    navigate(routingPath, {
      state: {
        encounter_id: totalData?.inputdoc?.encounterid,
        personId: totalData?.personid,
        from: 1,
        patientId: totalData?.inputdoc?.patientid
          ? totalData?.inputdoc?.patientid
          : totalData?.inputdoc?.patientId,

        appointmentId: totalData?.inputdoc?.appointmentid,
        facilityId: totalData?.inputdoc?.facilityid,

        fromQueue: true,
        idedit: false,
        Ispatient: true,
        isedit: false, //userAction?.action == "VISIT REGISTER" ? false : true,
        // isRevise: userAction?.action == "REVISE VISIT" ? true : false,
        patientData: totalData?.mobileNo,
        stateAction: userAction?.action,
        stateEndPoint: userAction?.apiendpoint,
        stateBackEndCall: userAction?.backEndCall,
        stateTicketid: ticketId,
        encounterId: totalData?.inputdoc?.encounterid,
        type: totalData?.inputdoc?.resource_type || totalData?.inputdoc?.resourcetype,
        pId: totalData?.inputdoc?.patientid
          ? totalData?.inputdoc?.patientid
          : totalData?.inputdoc?.patientId,
        app_id: totalData?.inputdoc?.encounterid,
        allCurrentData: totalData,
        queue: queueName,
        referralId: totalData?.inputdoc?.referralTrxId
          ? totalData?.inputdoc?.referralTrxId
          : totalData?.inputdoc?.referralid,
        mrnId: totalData?.field2,
        ticketId: ticketId,
        // nextRoute: AppRoutes.visitRegistration,
        isReferral: queueName === process.env.REACT_APP_REFERRAL_QUEUE ? true : false,
        isNephrologyOrPicQueue: (queueName === process.env.REACT_APP_NEPHROLOGIST_QUEUE || queueName === process.env.REACT_APP_PIC_REVIEW_QUEUE) ? true : false,
        // totalData: totalData,
        // TotActionData: userAction,
        selCardActionData: userAction,
        selCardData: totalData

      }
    });
  }
  // functioncaller fns
  const redirectBilling = async ({ totalData = {}, action = "", TotActionData = {} }) => {

    const facilityId = getGlobalValueFrom_LclStrg_And_QryStrg({ localStoragekeyName: "UserInfo", returnObjKeyName: "facilityid" });
    const getEpochTime = (date) => Math.floor(date.getTime() / 1000);
    const currentDate = new Date();
    const startDate = new Date(currentDate);
    startDate.setHours(0, 0, 0, 0);
    const startEpochTime = getEpochTime(startDate);
    const endDate = new Date(currentDate);
    endDate.setHours(23, 59, 59, 999);
    const endEpochTime = getEpochTime(endDate);
    const queryData = personRequests.getDetailsAgainstPatientSearchByIsCashier(
      startEpochTime,
      endEpochTime,
      "",
      totalData?.field2,
      "",
      "",
      "",
      "",
      "",
      facilityId,
      0,
      10
    );
    var lista = {};
    const SearchUserData = await fetchData(queryData, urls.readQdmQueries);
    if (!SearchUserData || SearchUserData.length === 0) {
      alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Billing data not avaliable",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    } else {
      SearchUserData?.forEach((v, i) => {
        Object.assign(lista, {
          idnumber: v?.identifier[0]?.value,
          idsourcecode: v?.identifier[0]?.code,
          address: v?.address,
          age: getCalculatedAge(v?.birthDate),
          gender: v?.gender,
          email:
            v?.telecom?.find((tel) => tel?.system === "CodingMaster/10469")
              ?.value ?? "",
          name: makeName(v?.name?.[0] || {}) || "",
          id: v?.patmrn ?? "",
          dis: `${(v?.gender + ", " ?? "") +
            (v?.birthDate ? getAge(v?.birthDate) : "")
            }
              ${v?.telecom[0]?.value ? " | " + v?.telecom[0]?.value : ""}`,
          encounter: v?.encounter,
          mobileNumber:
            v?.telecom?.find((tel) => tel?.system === "CodingMaster/10473")
              ?.value ||
            v.telecom?.find((tel) => tel?.system === "CodingMaster/10469")
              ?.value ||
            "",
          patientId: v?._id,
          img:
            getImgUrl(v?.photo?.[0]?.fileid) ||
            "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
        });
      });
      navigate(AppRoutes.billingDetails, {
        state: {
          personId: totalData?.personid,
          patientId: totalData?.inputdoc?.patientid
            ? totalData?.inputdoc?.patientid
            : totalData?.inputdoc?.patientId,
          appointmentId: totalData?.inputdoc?.appointmentid,
          encounter_id: totalData?.inputdoc?.encounterid,
          encounterId: totalData?.inputdoc?.encounterid,
          ticketId: totalData?.ticketId,
          type: totalData?.inputdoc?.resource_type,
          pId: totalData?.inputdoc?.patientid
            ? totalData?.inputdoc?.patientid
            : totalData?.inputdoc?.patientId,
          app_id: totalData?.info?._id,
          allCurrentData: totalData,
          actionName: totalData?.action || action,
          ...lista,
          selCardActionData: TotActionData,
          selCardData: totalData
        },
      });
    }
  };

  const NavigateToPatientRegistration = async ({
    totalData,
    isViewMode,
    isEditedMode,
    TotActionData = {}
  }) => {
    const patientInfo = await dispatch(
      actions.PATIENT_INFO({
        patientid: totalData?.inputdoc?.patientId,
        tenantid: "",
        facilityid: "",
        lang: "",
      })
    );
    navigate(AppRoutes.patientRegistration, {
      state: {
        isViewMode,
        isEditedMode,
        data: patientInfo?.payload?.data,
        totalData: totalData,
        selCardActionData: TotActionData,
        selCardData: totalData
      },
    });
  };
  const NavigateToPaymentCollection = async ({
    totalData,
    isRefund,
    action,
  }) => {
    navigate(AppRoutes.paymentCollections, {
      state: {
        patientid: totalData?.inputdoc?.patientid,
        _id: totalData?.inputdoc.refundid,
        ticketId: totalData?.ticketId,
        isRefund,
        action
      },
    });
  };
  //jasper_print_referral
  const printForm = async (totalData, queueId) => {

    const userInfo = localStorage.getItem("UserInfo");
    const decodedUserinfo = jwtDecode(userInfo);
    let JwtToken = localStorage.getItem("JwtToken");
    let header = { JwtToken: JwtToken };
    const data = {
      reportid: process.env.REACT_APP_PRINT_REPORTID,
      inputparams: {
        "@patientid": totalData?.inputdoc?.patientId,
        "@orgid": decodedUserinfo?.facilityid,
      },
      result: [],
    };
    const reportGenerate = await axiosCall(
      data,
      process.env.REACT_APP_GENERATE_REPORT,
      header
    );
    if (!reportGenerate?.error) {
      window.location.href = reportGenerate?.downloadUrl;
      RefreshFn();
    }
  };
  const referralPrint = async (totalData) => {
    let JwtToken = localStorage.getItem("JwtToken");
    let header = { JwtToken: JwtToken };
    const data = {
      reportid: process.env.REACT_APP_REFERRAL_PRINT_REPORTID,
      inputparams: {
        "@patientid": totalData?.inputdoc?.patientId,
      },
      result: [],
    };
    const reportGenerate = await axiosCall(
      data,
      process.env.REACT_APP_GENERATE_REPORT,
      header
    );
    if (!reportGenerate?.error) {
      window.location.href = reportGenerate?.downloadUrl;
      RefreshFn();
    }
  };
  const stringconvertedarray = (originalArray) => {
    const convertedArray = originalArray.map((element) => `'${element}'`);
    return `[${convertedArray.join(", ")}]`;
  };
  const printOrderRequestForm = async (totalData, queueId) => {
    const userInfo = localStorage.getItem("UserInfo");
    const decodedUserinfo = jwtDecode(userInfo);
    const orgid = decodedUserinfo.facilityid;
    let JwtToken = localStorage.getItem("JwtToken");
    let header = { JwtToken: JwtToken };
    let arraydata = [totalData];
    if (selectedTicket?.length > 0) {
      arraydata = SelectedTicketValue;
    }
    let payload = {
      "@patientid": "",
      "@encounterid": "",
      "@orderlineid": "",
      "@facilityid": orgid,
      "@tenantid": decodedUserinfo?.tenantid,
    };
    let orderlineid = [];
    arraydata.forEach((v) => {
      payload["@encounterid"] = v?.inputdoc?.encounterid;
      payload["@patientid"] = v?.inputdoc?.patientid;
      orderlineid.push(v?.inputdoc?.caorderlineid || "");
    });
    payload["@orderlineid"] = stringconvertedarray(orderlineid);
    const data = {
      reportid: process.env.REACT_APP_PRINT_ORDER_FORM_REPORTID,
      inputparams: payload,
      result: [],
    };
    try {
      const reportGenerate = await axiosCall(
        data,
        process.env.REACT_APP_GENERATE_REPORT,
        header
      );
      if (!reportGenerate?.error) {
        window.location.href = reportGenerate?.downloadUrl;
        RefreshFn();
      }
    } catch (error) {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: error.message,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
  };

  const NavigateToModificationAppmnt = async (id, calendarParams) => {

    // setting the flag to true for modify function in confirm booking button
    if (!id) {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Appointment id is not available !",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
      return
    }
    setPatientDatacontext((prevstate) => ({
      ...prevstate,
      bookFlag: { modifyBook: true },
    }));
    const data = await dispatch(
      actions.APPOINTMENT_READ({
        appointmentid: id,
        tenantid: "",
        facilityid: "",
      })


    );

    const appInfo = data?.payload?.data || data?.AppointmentNotification || {};
    // appInfo["HealthcareService"] = appInfo["healthservice"];
    // delete appInfo["healthservice"];
    // console.log(appInfo,"appInfo");
    navigate(AppRoutes.appointmentBooking, {
      state: {
        cityName: 0,
        location: {
          address: "Chennai, Tamil Nadu, India",
          latitude: 13.0826802,
          longitude: 80.2707184,
          city: "Chennai",
          location: "Chennai",
        },
        hospital: {
          label: "",
          value: appInfo?.orgid?.[0]?._id || 0,
          org_Id: appInfo?.orgid?.[0]?._id,
          orgType: appInfo?.orgid?.[0]?.OrgType,
        },
        specialities: [],
        symptoms: [],
        selectedUser: {
          name: appInfo?.person?.[0]?.name?.[0]?.fulname,
          gender: appInfo?.person?.[0]?.gender ?? "",
          birthDay: appInfo?.person?.[0]?.birthdate,
          fileid: appInfo?.person?.[0]?.photo?.[0]?.fileid,
        },
        symptomsSearch: [],
        slotId: appInfo?.slotID?.[0],
        modifyUser: true,
        newBook: false,
        appointMentInfo: appInfo,
        //  patientId: location?.state?.patientId,
        userId: appInfo?.PersonID,
        appointmentId: id,
        calendarParams,
        //unregister : { is_Unregister :  props.location.state.unregister}
      },
    });
  };
  function functionCaller({ totalData = {}, action = "", key = "", TotActionData = {}, }) {

    switch (key) {
      case "billing":
        redirectBilling({ totalData, action: action, TotActionData });
        break;
      case "route_view_patient_registration":
        NavigateToPatientRegistration({ totalData, isViewMode: true, TotActionData });
        break;
      case "route_edit_patient_registration":
        NavigateToPatientRegistration({
          totalData,
          isViewMode: false,
          isEditedMode: true,
          TotActionData
        });
        break;
      case "performUserAction_Refund":
        NavigateToPaymentCollection({
          totalData,
          isRefund: true,
          action: action,
          TotActionData
        });
        break;
      case "referralQ_registered_jasper_print":
        printForm(totalData, currentIds.queueId);
        break;
      case "referralQ_scheduled_jasper_printForm":
        referralPrint(totalData, currentIds.queueId);
        break;
      case "labOrderQ_specimenCollected_jasper_printOrderRequestForm":
        printOrderRequestForm(totalData, currentIds.queueId);
        break;
      case "performUserAction_approve_treatment_plan":
        Comp_SideBar_HandleSubmit(totalData, TotActionData);
        break;
      case "performUserAction_approve_verbal_treatment_plan":
        Comp_SideBar_HandleSubmit(totalData, TotActionData);
        break;
      case "performUserAction_Refund":
        Comp_SideBar_HandleSubmit(totalData, TotActionData);
        break;
      case "route_modify_apppointment":
        NavigateToModificationAppmnt(totalData?.inputdoc?.appointmentid, calendarparams);
        break;
        case "dialog_eref_Schedule":
          NavigateToAppointmentBooking(totalData,TotActionData);
          break;

      default:
        // redirectBilling({ action, totalData });
        // NavigateToModificationAppmnt(totalData?.inputdoc?.appno);
        break;
    }

  }
  const NavigateToAppointmentBooking = async (totalData,TotActionData) => {

    //console.log({ patientData });
    const patientInfo = await dispatch(
      actions.PATIENT_INFO({
        patientid: totalData?.inputdoc?.patientid,
        tenantid: "",
        facilityid: "",
        lang: "",
        personid: "",
      })
    );

    let state = {
      newPatientDetails: patientInfo?.payload?.data?.patientdetails,
      allPatientDetails: patientInfo?.payload?.data,
    };

    navigate(AppRoutes.appointmentBooking, {
      state: {
        skip_dashboard_hospitalSearch_screen: true,
        apptypecode: process.env.REACT_APP_FREQUENCY_APPMNT_TYPE_OP_FLOW,
        patientId: state.allPatientDetails?._id,
        userId: state.allPatientDetails?.patientdetails?.personid,
        selectedUser: {
          name:
            state?.newPatientDetails?.name?.[0]?.fulname ||
            state?.patientDetails?.name ||
            state?.patientDetails?.allData?.name?.[0]?.fulname,
          fileid: state?.newPatientDetails?.photo?.[0]?.fileid,
          url: state?.newPatientDetails?.photo?.[0]?.url,
          age: state?.newPatientDetails?.age,
          gender: state?.newPatientDetails?.gender?.display,
          birthdate: state?.newPatientDetails?.birthdate,
          modifyUser: false,
        },
        resource_id:  totalData?.inputdoc?.reftopractitioner,
        ActionData:TotActionData,
        ticketId:totalData.ticketId,
        selectedSlotId: undefined,//available?.data?._id,
        date: undefined//available?.date
       

        // patientDetails: state.allPatientDetails,
      },
    });
  }
  const CardBtnClick_WF = async (
    ticketId = [],
    TotActionData = {},
    data = [],
  ) => {
    debugger
    let totalData = data?.[0]?.totalData || data;
    let action = TotActionData?.action;
    setSelCardData(totalData ?? data);
    setSelCardActionData(TotActionData);
    let type = TotActionData?.rendertype || TotActionData?.choosetype;  // choosetype for new queue is changed in backoffice
    switch (type) {
      case "performUserAction":
        PerformUserAction({
          ticketId: ticketId,
          userAction: action,
        });
        break;
      case "route":
        NavigateToScreen({
          ticketId: ticketId,
          userAction: TotActionData,
          totalData: totalData ?? data,
        });
        break;
      case "component":
        ComponentRender({ totalData, TotActionData, type: TotActionData?.rendername, });
        break;
      case "function":
        functionCaller({ totalData, TotActionData, action: action, key: TotActionData?.rendername, });
        break;
      default:
        //functionCaller({ totalData: totalData, action: action, key: TotActionData?.rendername, TotActionData: TotActionData , totalData: data, });
        // PerformUserAction({
        //   ticketId: ticketId,
        //   userAction: action,
        // });
        // NavigateToScreen({
        //   ticketId: ticketId,
        //   userAction: TotActionData,
        //   totalData: totalData??data,
        // });
        ComponentRender({ totalData, TotActionData, type: TotActionData?.rendername, });
        break;
    }
  };

  const parent_id = "WorkFlowComponent";
  const { permission } = state;
  const handleRefundCloseModal = () => {
    setRefundDetailsOpen(false);
  };
  const handleRefundInfo = async (refundId, statesid) => {
    try {
      const response = await dispatch(
        actions.REFUND_GET_DETAILS({
          refundId: refundId, statesid
        })
      );
      setRefundValue({
        refundAmount: response?.payload?.data?.[0]?.refundamt,
        rejectReason: response?.payload?.data?.[0]?.reason?.ReasonDesc ?? response?.payload?.data?.[0]?.refundreason,
        reasonCmt: response?.payload?.data?.[0]?.reason?.ReasonCmt,
      });
      setRefundDetailsOpen(true)
    } catch (error) {

    }
  };
  return (
    <>


      <MultiBookComp
        multiBookOpen={multiBookOpen}
        setMultiBookOpen={handleMultiBookData}
        mBData={mBData}
        selectedQueueid={currentIds?.queueId}
        RefreshLatestQueue={RefreshFn}
      />

      <SideMenu
        id="patient_dashboard_cancel_sidemenu"
        open={comp_Upload_State?.openUpload}
        direction="right"
        width={440}
        color="default"
        onClose={() => handlesidebarCancelbooking()}
        style={{ padding: 0 }}
      >
        <UploadFiles
          id={`${parent_id}_topnavbar_upload_uploadfiles`}
          uploadDocument={uploadDocument}
          files={comp_Upload_files}
          uploadImage={uploadImage}
          uploadData={comp_Upload_State?.uploadData}
          parent_id={parent_id}
        />
      </SideMenu>

      <Dialog
        maxWidth="sm"
        fullWidth
        open={comp_specimen_state?.open}
        onClose={() =>
          setComp_specimen_state({ ...comp_specimen_state, open: false })
        }
      >
        <DialogContent>
          <SampleFormPage
            sampleFormClose={() =>
              setComp_specimen_state({ ...comp_specimen_state, open: false })
            }
            selectedSpeciment={selectedSpeciment}
            SelectedTicketValue={SelectedTicketValue}
            selectedTicket={selectedTicket}
            RefreshLatestQueue={RefreshFn}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={comp_administer_state?.open}
        onClose={() =>
          setComp_administer_state({ ...comp_administer_state, open: false })
        }
      >
        <DialogContent>
          <NursingProcedure
            nursingProcedureClose={nursingProcedureClose}
            selectedqueuevalue={selectedqueuevalue}
            selectedAdminstrator={selectedAdminstrator}
            RefreshLatestQueue={RefreshFn}
          />
        </DialogContent>
      </Dialog>

      <MuiDrawer
        open={comp_SideBar_State?.open}
        close={() => {
          setComp_SideBar_State({ ...comp_SideBar_State, open: false });
        }}
      >
        <Commonsidebar
          reasonType={comp_SideBar_State?.reasonType}
          headingName={comp_SideBar_State?.headingName}
          cancellationType={{
            isCancellationType: comp_SideBar_State?.isCancellationType,
            headingName: "Cancellation Type",
            list: [
              {
                label: "Single",
                value: process.env.REACT_APP_CANCEL_APPMNT_TYPE_SINGLE,
              },
              {
                label: "Multiple",
                value: process.env.REACT_APP_CANCEL_APPMNT_TYPE_MULTIPLE,
              },
            ],
          }}
          Comp_SideBar_HandleSubmit={Comp_SideBar_HandleSubmit}
        />
      </MuiDrawer>
      <Dialog
        open={refundDetailsOpen}
        onClose={handleRefundCloseModal}
        PaperProps={{
          style: {
            minWidth: 300,
          },
        }}
      >
        <DialogTitle>Refund details</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleRefundCloseModal}
          style={{ position: "absolute", right: 6, top: 6 }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100px',
          }}
        >
          <React.Fragment>
            <Grid>
              <div>
                <Typography
                  variant="body2"
                  style={{
                    // color: "#EC6A49",
                    fontSize: "15px",
                    margin: "5px 0",
                    fontWeight: 600,
                  }}
                >
                  {`Refund amount : ${refundValue?.refundAmount ?? ""}`}
                </Typography>
              </div>
              <div>
                <Typography
                  variant="body2"
                  style={{
                    // color: "#EC6A49",
                    fontSize: "15px",
                    margin: "10px 0",
                    fontWeight: 600,
                  }}
                >
                  {`Reason : ${refundValue?.rejectReason ?? ""
                    }`}
                </Typography>
              </div>
              {refundValue?.reasonCmt && (
                <div>
                  <Typography
                    variant="body2"
                    style={{
                      // color: "#EC6A49",
                      fontSize: "15px",
                      margin: "10px 0",
                      fontWeight: 600,
                    }}
                  >
                    {`Comments : ${refundValue?.reasonCmt ?? ""
                      }`}
                  </Typography>
                </div>
              )}
            </Grid>
          </React.Fragment>
        </DialogContent>
      </Dialog>
      <Modal
        parent_id={"patient_dashboard"}
        hiddencloseIcon={true}
        open={viewAppoinment?.open}
        onClose={() => { setviewAppoinment({ ...viewAppoinment, open: false }) }}
        childrens={
          <ConfirmationPage
            parent_id={"patient_dashboard"}
            appointmentId={viewAppoinment?.appoinmentId}
            appointmentDetails={true}
            hidden={true}
          />

        }
        width={530}
        backgroundColorNone
      />
    </>
  );
}

