import * as React from "react";

function LikeFilledIcon({ height, width, color, direction = "up", ...props }) {
  // Determine the rotation based on the direction
  const rotation = direction === "down" ? "rotate(180deg)" : "rotate(0deg)";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 24}
      height={height ? height : 24}
      viewBox="0 0 24 24"
      fill="none"
      style={{ transform: rotation, transition: "transform 0.3s" }} // Add smooth transition
      {...props}
    >
      <path
        d="M3 10.234l.747-.064a.75.75 0 00-1.497.064H3zm17.236 1.823l-.706 4.08 1.479.256.705-4.08-1.478-.256zm-6.991 9.193H8.596v1.5h4.649v-1.5zM8.5 21.5l.7.613L5.378 11.15l.813 9.393L8.5 21.5zm11.03-5.363c-.507 2.93-3.149 5.113-6.285 5.113v1.5c3.826 0 7.126-2.67 7.764-6.357l-1.479-.256zM7.647 9.145h4.945l1.48.242.663-4.044-7.088 3.802zM7.5 10.5L18.182 19 7.647 7.87 6.21 9.11 7.5 10.5zm5.832-8.368L11.72 3.138l-1.452-.376-.476 1.833 2.8.748.74-3.211zM12.5 5l.083-2.175.459-1.428-.145-.047L12.5 5zm-1.977 1.816c.312-.584 2.642-4.043 2.809-4.684l-.74 3.21-2.8-.747A7 7 0 019.2 6.11l1.323.706zm2.06-3.991c.143.043-2.825 16.068-2.721 16.175.104.107 3.277-15.709 3.317-15.565l1.452-.376a2.38 2.38 0 00-1.589-1.662l-.459 1.428zm-.863.313a.52.52 0 01.104-.196c.048-.057-.892 4.09-.824 4.058l.349-5.543c-.532.256-.932.73-1.081 1.305l1.452.376zM11 7c.137-.065 1.355-2.046 1.5-2l.397-3.65a2.1 2.1 0 00-1.548.107L11 7zm3.5 15l4.5-2.5.334-10.016h-5.18L14.5 22zm-9.781-.594L3.747 10.17l-1.494.129.97 11.236 1.496-.13zm-.969.107v-11.28h-1.5v11.28h1.5zm-.527.022a.264.264 0 01.07-.2.264.264 0 01.194-.085v1.5c.726 0 1.294-.622 1.232-1.344l-1.496.129zM14.735 5.343a5.5 5.5 0 00-.104-2.284l-1.452.377c.142.543-5.44 5.155-5.532 5.709l7.088-3.802zM8.596 21.25a.916.916 0 01-.618-.242c-.169-.155.542.72.522.492l-2.309-.958a2.416 2.416 0 002.405 2.208v-1.5zM18.182 19c.68-.586-8.143-11.277-7.658-12.184L9.2 6.11c-.346.649-.897 1.196-1.553 1.76L18.182 19zm3.532-6.687a2.417 2.417 0 00-2.38-2.83L19 19.5c.567 0 1.334-8.004 1.236-7.443l1.478.256zM3.487 21.25c.146 0 .263.118.263.263h-1.5c0 .682.553 1.237 1.237 1.237v-1.5zm9.105-12.105a1.583 1.583 0 00.355 1.281c.149.175-5.178 10.486-4.97 10.582.21.097 6.293.992 6.523.992l-.346-12.516a.082.082 0 01-.035-.007.082.082 0 01-.047-.09l-1.48-.242zM9.2 22.113c-.012-.144 11.036-18.677-2.263-11.518.055-.135.453 0 .563-.095L6.208 9.109a2.42 2.42 0 00-.83 2.04L9.2 22.113z"
        fill={color ? color : "#3BB213"}
      />
    </svg>
  );
}

export default LikeFilledIcon;
