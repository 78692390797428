export const generateJson = {
	insert_json: (data, appInfo) => {
        const dbName=process.env.REACT_APP_DB;
		return [
			{
				db_name: dbName,
				entity: "ConsentTrx",
				is_metadata: true,
				metadataId: "04ecb73d-f048-44ce-8563-c9be015812dd",
				doc: {
                    id: 0,
                    formid: data?.formId,
                    patientid: appInfo?.dataJson?.PersonID[0]?._id,
                    practitionerid: appInfo?.dataJson?.resourcecode[0]?.practitioner_id,
                    terms: true,
                    sysinfo: data?.answer,
                    appnum: appInfo?.app_id
				},
			},
		];
	},
};
