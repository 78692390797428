let eventGuid = 0;
let todayStr = new Date().toISOString().replace(/T.*$/, ""); // YYYY-MM-DD of today

export const INITIAL_EVENTS = (dt)=>
  {
    return [
  {
    id: createEventId(),
    title: "Ng Foo Cheong Appointment",
    start: dt + "T11:00:00",
    end: dt + "T13:00:00",
    resourceId: "a",
  },
  {
    id: createEventId(),
    title: "Kenneth Chen Appointment",
    start: dt + "T12:00:00",
    resourceId: "b",
  },
  {
    id: createEventId(),
    title: "Spine Surgery 3hr",
    start: dt + "T13:00:00",
    end: dt + "T16:00:00",
    resourceId: "a",
    backgroundColor: "grey",
  },
  {
    id: createEventId(),
    title: "Spine Surgery 3hr",
    start: dt + "T13:00:00",
    end: dt + "T16:00:00",
    resourceId: "b",
    backgroundColor: "grey",
  },
  {
    id: createEventId(),
    title: "Spine Surgery 3hr",
    start: dt + "T13:00:00",
    end: dt + "T16:00:00",
    resourceId: "c",
    backgroundColor: "grey",
  },
  {
    id: createEventId(),
    title: "Spine Surgery 3hr",
    start: dt + "T13:00:00",
    end: dt + "T16:00:00",
    resourceId: "d",
    backgroundColor: "grey",
  },
]};

export function createEventId() {
  return String(eventGuid++);
}
