import { Chip, Collapse, colors, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import { HeartPluseIcon } from "../../../assets/svgComponents/heartPlusIcon";
import TrendIcon from "../../../assets/svgComponents/trendIcon";
import ArrowIcon from "../../../assets/svgComponents/arrow";

const ScoreCard = ({ scoreName, score, keyFactors, icon, bgColor }) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = React.useState(false);

  const handleMouseEnter = () => {
    setIsExpanded(true);
  };

  const handleMouseLeave = () => {
    setIsExpanded(false);
  };
  return (
    <Grid
      container
      p={1}
      boxShadow={1}
      sx={{
        // border: `1px solid ${theme?.palette?.lineBorderFill}`,
        borderRadius: 1,
        background: bgColor
      }}
      // gap={1}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Grid container justifyContent={"flex-start"} alignItems={"center"} ml={1} pt={1}>
        <Grid item p={1.5} boxShadow={1} bgcolor={'white'} borderRadius={2}>
          {icon}
        </Grid>
      </Grid>
      <Grid container justifyContent={"space-between"} alignItems={"center"} ml={1}>
        <Grid item>
        <p style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "130px", marginBottom: "0px" }}>
          <Typography
            variant="head2"
            color={theme?.palette?.tertieryText}
            fontWeight={600}
          >
            {scoreName}
            </Typography>
            </p>
        </Grid>
        <Grid item>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            p={2}
            gap={2}
          >
            <Grid item>
              <p style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "50px", marginBottom: "0px" }}>
                <Typography variant="head1" fontWeight={600} color={theme?.palette?.primaryText}>
                  {score}
                </Typography>

              </p>
            </Grid>
            <Grid item>
              <ArrowIcon direction={isExpanded ? "up" : "down"} color="black" />
            </Grid>
          </Grid>

        </Grid>
      </Grid>
      <Collapse in={isExpanded} sx={{ m: 0, p: 0 }} >
        {keyFactors}
      </Collapse>
    </Grid>
  );
};

export default ScoreCard;
