import * as React from "react"

function TickIcon({ height, width, color, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 22}
      height={height ? height : 22}
      viewBox="0 0 22 22"
      fill="none"
      {...props}
    >
      <path
        d="M11 1.833c-5.042 0-9.167 4.125-9.167 9.167 0 5.041 4.125 9.166 9.167 9.166 5.041 0 9.166-4.125 9.166-9.166 0-5.042-4.125-9.167-9.166-9.167zm0 16.5c-4.043 0-7.334-3.29-7.334-7.333S6.957 3.666 11 3.666c4.042 0 7.333 3.291 7.333 7.334 0 4.042-3.29 7.333-7.333 7.333zm4.207-11.385l-6.04 6.04-2.375-2.364L5.5 11.916l3.666 3.667L16.5 8.25l-1.293-1.302z"
        fill={color ? color : "#3BB213"}
      />
    </svg>
  )
}

export default TickIcon